import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams as useRouterParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductsActions } from "store/ducks/products";
import { NcmGlobalsActions } from "store/ducks/ncmGlobals";
import { Span } from "components/core/Typography";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import ProductsEdit from "components/presentation/Products/Edit";
import { useRequest, useInfinitePagination, useParams as useParamsHooks, useDebounce } from "hooks";
import { LINKS } from "utils/constants";
import { LayoutActions } from "store/ducks/layout";
import { CstActions } from "store/ducks/cst";
import { NcmActions } from "store/ducks/ncm";
import { validationSchema } from "utils/schemas/products";

const ProductsEditWithSpinner = WithSpinner(ProductsEdit);

const ProductsEditContainer = () => {
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { emitterId, id } = useRouterParams();
  const { t: translate } = useTranslation();
  const { availableNcm, NcmRequests } = useSelector(({ ncm }) => ncm);
  const { availableCst, CstRequests } = useSelector(({ cst }) => cst);
  const { productsDetails, ProductsRequests } = useSelector(({ products }) => products);
  const { unitMeasure, NcmGlobalsRequests } = useSelector(({ ncmGlobals }) => ncmGlobals);
  const [isChangedNcm, setIsChangedNcm] = useState(false);
  const [isChangedCsosn, setIsChangedCsosn] = useState(false);
  const [newNcmArray, setNewNcmArray] = useState([]);
  const [newCsosnArray, setNewCsosnArray] = useState([]);
  const [newUnitArray, setNewUnitArray] = useState([]);

  const fetchNcm = (params) => {
    dispatch(NcmActions.fetchAvailableNcmWithParams(emitterId, params));
  };

  const { page: pageNCM, setPage: setPageNCM, handleFilter } = useParamsHooks({
    callback: fetchNcm,
    defaultOrderBy: "id",
  });

  const debounceChangeNcm = useDebounce(handleFilter, 700);

  const handleSearchNcm = ({ target: { value } }) => {
    debounceChangeNcm({ search: value });
  };

  const fetchCst = (params) => {
    dispatch(CstActions.fetchAvailableCstWithParams(emitterId, params));
  };

  const { page: pageCST, setPage: setPageCST, handleFilter: handleFilterCST } = useParamsHooks({
    callback: fetchCst,
    defaultOrderBy: "id",
  });

  const debounceChangeCST = useDebounce(handleFilterCST, 700);

  const handleSearchCst = ({ target: { value } }) => {
    debounceChangeCST({ csosn: value });
  };

  const fetchUnitMeasure = (params) => {
    dispatch(NcmGlobalsActions.getUnitMeasure(params));
  };

  const {
    page: pageMeasure,
    setPage: setPageMeasure,
    handleFilter: handleFilterMeasure,
  } = useParamsHooks({
    callback: fetchUnitMeasure,
    defaultOrderBy: "id",
  });

  const debounceChangeMeasure = useDebounce(handleFilterMeasure, 700);

  const handleSearchUnitMeasure = ({ target: { value } }) => {
    debounceChangeMeasure({ search: value });
  };

  useEffect(() => {
    dispatch(ProductsActions.fetchProductsDetails(id));
  }, [dispatch, id]);

  useEffect(() => () => dispatch(ProductsActions.cleanProducts()), [dispatch]);

  const handleSubmit = ({ idNcm, idCsosn, ...values }) => {
    const ncmData = !isChangedNcm ? productsDetails?.idNcm : idNcm;
    const csosnData = !isChangedCsosn ? productsDetails?.idCsosn : idCsosn;
    dispatch(ProductsActions.editProducts(id, { ...values, idNcm: ncmData, idCsosn: csosnData }));
  };

  useEffect(() => {
    const newAvailableNcm = availableNcm?.items
      ?.map(({ code, name, id }) => ({
        id,
        code,
        name: `${code} - ${name}`,
      }))
      .filter((x) => {
        return x.code !== undefined;
      });

    if (pageNCM === 1) {
      setNewNcmArray(newAvailableNcm);
    } else if (availableNcm?.items?.length) {
      const exist = newNcmArray.find((newNCM) =>
        newAvailableNcm.find((item) => item.code === newNCM.code),
      );
      if (!exist) setNewNcmArray((prevItems) => [...new Set([...prevItems, ...newAvailableNcm])]);
      else {
        const format = newNcmArray.map((newNCM) => {
          const x = newAvailableNcm.find((item) => item.code === newNCM.code);

          if (!x) return newNCM;
          return x;
        });

        setNewNcmArray(() => [...new Set([...format])]);
      }
    }
  }, [availableNcm?.items, pageNCM]);

  useEffect(() => {
    const csosnArray = availableCst?.items?.map(({ csosn }) => ({
      ...csosn,
    }));
    const newAvaliableCSOSN = csosnArray
      ?.map(({ code, name, id }) => ({
        id,
        code,
        name: `${code} - ${name}`,
      }))
      .filter((x) => {
        return x.code !== undefined;
      });

    if (pageCST === 1) {
      setNewCsosnArray(newAvaliableCSOSN);
    } else if (availableCst?.items?.length) {
      const exist = newCsosnArray.find((newCSO) =>
        newAvaliableCSOSN.find((item) => item.code === newCSO.code),
      );
      if (!exist)
        setNewCsosnArray((prevItems) => [...new Set([...prevItems, ...newAvaliableCSOSN])]);
      else {
        const format = newCsosnArray.map((newCSO) => {
          const x = newAvaliableCSOSN.find((item) => item.code === newCSO.code);

          if (!x) return newCSO;
          return x;
        });

        setNewCsosnArray(() => [...new Set([...format])]);
      }
    }
  }, [availableCst?.items, pageCST]);

  const lastItemRefNCM = useInfinitePagination(
    NcmRequests.FETCH_AVAILABLE_NCM_WITH_PARAMS,
    pageNCM < availableNcm?.totalPages,
    setPageNCM,
  );

  const lastItemRefCTS = useInfinitePagination(
    CstRequests.FETCH_AVAILABLE_CST_WITH_PARAMS,
    pageCST < availableCst?.totalPages,
    setPageCST,
  );

  const lastItemRefMeasure = useInfinitePagination(
    NcmGlobalsRequests.getUnitMeasure,
    pageMeasure < unitMeasure?.totalPages,
    setPageMeasure,
  );

  useEffect(() => {
    if (pageMeasure === 1) {
      setNewUnitArray(unitMeasure?.items);
    } else if (unitMeasure?.items?.length) {
      const exist = newUnitArray.find((newUnit) =>
        unitMeasure?.items?.find((item) => item.code === newUnit.code),
      );
      if (!exist)
        setNewUnitArray((prevItems) => [...new Set([...prevItems, ...unitMeasure?.items])]);
      else {
        const format = newUnitArray.map((newUnit) => {
          const x = unitMeasure?.items?.find((item) => item.code === newUnit.code);

          if (!x) return newUnit;
          return x;
        });

        setNewUnitArray(() => [...new Set([...format])]);
      }
    }
  }, [pageMeasure, unitMeasure?.items]);

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans i18nKey="productEdited" components={{ bold: <strong /> }} />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(ProductsActions.cleanProducts());
    history.push(LINKS.productsList(emitterId));
  };

  useRequest({
    request: ProductsRequests.EDIT_PRODUCTS,
    onResolved,
  });

  return (
    <>
      <NavBar title={translate("products")}>
        <Button handleClick={() => history.goBack()} variant="outline">
          <span>{translate("cancel")}</span>
        </Button>
        <Button handleClick={() => submitRef.current.click()}>
          <span>{translate("save")}</span>
        </Button>
      </NavBar>
      <ProductsEditWithSpinner
        requestStatus={ProductsRequests.FETCH_PRODUCTS_DETAILS}
        defaultValues={{
          name: productsDetails?.name,
          code: productsDetails?.code,
          unitMeasure: productsDetails?.unitMeasure?.name,
          ncm: `${productsDetails?.ncm?.code} - ${productsDetails?.ncm?.name}`,
          csosn: `${productsDetails?.csosn?.code} - ${productsDetails?.csosn?.name}`,
          idUnitMeasure: productsDetails?.idUnitMeasure,
        }}
        submitRef={submitRef}
        onSubmit={handleSubmit}
        handleSearchNcm={handleSearchNcm}
        handleSearchCst={handleSearchCst}
        ncmItems={newNcmArray || []}
        isLoadingNcmItems={isPending(NcmRequests.FETCH_AVAILABLE_NCM)}
        cstItems={newCsosnArray || []}
        isLoadingCstItems={isPending(CstRequests.FETCH_AVAILABLE_CST)}
        unitMeasure={newUnitArray}
        handleSearchUnitMeasure={handleSearchUnitMeasure}
        setIsChangedNcm={setIsChangedNcm}
        setIsChangedCsosn={setIsChangedCsosn}
        validationSchema={validationSchema}
        isLoadingUnitMeasureItems={isPending(NcmGlobalsRequests.getUnitMeasure)}
        lastItemRefNCM={lastItemRefNCM}
        lastItemRefCTS={lastItemRefCTS}
        lastItemRefMeasure={lastItemRefMeasure}
      />
    </>
  );
};

export default ProductsEditContainer;
