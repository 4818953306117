import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  TableCheckbox,
  TableHeaderItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { formatDate } from "helpers";
import { MASK_TYPES, format } from "utils/masks";
import * as S from "./styled";
import Modal from "components/core/Modal";
import InputForm from "components/core/InputForm";
import EmptyFeedback from "components/core/EmptyFeedback";
import CardInfo from "components/core/CardInfo";
import { Flex, Columns, ColumnFlex } from "components/core/Grid";
import Button from "components/core/Button";
import { Label } from "components/core/InputForm/styled";
import { Span } from "components/core/Typography";
import { Spinner } from "components/core/WithSpinner/styled";

const List = ({
  invoices,
  handleChange,
  toggleAll,
  everyoneIsChecked,
  params,
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
  handleViewDetails,
  handleViewItems,
  showDetails,
  setShowDetails,
  showItems,
  setShowItems,
  selected,
  handleClipboard,
  linkCopied,
  handleTab,
  handleProtheus,
  showConfirmProtheus,
  setShowConfirmProtheus,
  handleModalProtheus,
  invoiceItems,
}) => {
  const { t: translate } = useTranslation();
  const parseInvoiceLink = (invoiceToken) => {
    return `${window.location.origin}/invoice-payment/${invoiceToken}`;
  };
  const itemPadding = "0.75rem 1rem";
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "enterpriseName", title: `${translate("emitterNameOrStoreClient")}` },
    { slug: "planName", title: `${translate("Plan")}` },
    { slug: "dueDate", title: `${translate("Due Date")}` },
    { slug: "total", title: translate("Value") },
    { slug: "status", title: translate("Status") },
    { slug: "", title: translate("Invoice Type") },
    { slug: "protheus", title: translate("Protheus") },
    { slug: "link", title: translate("Actions") },
  ];
  const headersItems = [
    { slug: "id", title: translate("ID"), textAlign: "center" },
    { slug: "description", title: `${translate("Description")}` },
    { slug: "price", title: `${translate("Value")}` },
    { slug: "quantity", title: `${translate("Quantity")}`, textAlign: "center" },
    { slug: "total", title: `${translate("Total")}` },
  ];
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return StatusTypes.WARNING;
      case "paid":
        return StatusTypes.SUCCESS;
      case "overdue":
        return StatusTypes.ERROR;
      case "processingPayment":
        return StatusTypes.PROCESSING;
      case "canceled":
        return StatusTypes.CANCELED;
      default:
        return StatusTypes.INFO;
    }
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={everyoneIsChecked} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {invoices.map(
            ({
              id,
              enterpriseName,
              planName,
              userName,
              dueDate,
              total,
              gatewayInvoiceId,
              originalInvoiceId,
              status,
              checked,
              payerName,
              protheus,
              invoiceTypeDescription,
            }) => (
              <tr key={id}>
                <TableItem textAlign="center">
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => handleChange(id)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem>
                  <Span
                    data-cy="status"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewDetails(id)}
                  >
                    {id}
                  </Span>
                </TableItem>
                <TableItem>
                  {(enterpriseName || userName) && (
                    <Span
                      data-cy="enterpriseName"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleViewDetails(id)}
                    >
                      {enterpriseName ? enterpriseName : userName}
                    </Span>
                  )}
                  {!enterpriseName && !userName && (
                    <Span
                      data-cy="payerName"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleViewDetails(id)}
                    >
                      {payerName ? payerName : ""}
                    </Span>
                  )}
                </TableItem>
                <TableItem>
                  <Span
                    data-cy="planName"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewDetails(id)}
                  >
                    {planName ?? ""}
                  </Span>
                </TableItem>
                <TableItem>
                  <Span
                    data-cy="dueDate"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewDetails(id)}
                  >
                    {formatDate(dueDate)}
                  </Span>
                </TableItem>
                <TableItem>
                  <Span
                    data-cy="total"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewDetails(id)}
                  >
                    {format(Number(total).toFixed(2), MASK_TYPES.realWithName)}
                  </Span>
                </TableItem>
                <TableItem>
                  <Span
                    data-cy="status"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewDetails(id)}
                  >
                    <StatusDot type={getStatusColor(status)} />
                    {translate(status)}
                  </Span>
                </TableItem>
                <TableItem>
                  <Span
                    data-cy="invoiceTypeDescription"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewDetails(id)}
                  >
                    {invoiceTypeDescription}
                  </Span>
                </TableItem>
                <TableItem>
                  {status === "paid" && (
                    <Span
                      data-cy="protheus"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleModalProtheus(id)}
                    >
                      <StatusDot type={protheus ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                      {protheus ? "Reenviar" : "Enviar"}
                    </Span>
                  )}
                  {status !== "paid" && <Span></Span>}
                </TableItem>
                <TableItem>
                  {status === "pending" && !originalInvoiceId && (
                    <S.IconCopy
                      title="Copiar link"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClipboard(parseInvoiceLink(gatewayInvoiceId))}
                    />
                  )}
                  <S.IconView
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewDetails(id)}
                    title="Ver detalhes"
                  />
                  <S.IconItems
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewItems(id)}
                    title="Ver Todos Itens da Fatura"
                  />
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
      <Modal
        title={`Detalhes da Fatura ${selected?.id}`}
        confirmLabel="Fechar"
        onCloseModal={() => setShowDetails(false)}
        showModal={showDetails}
        textAlign="text-left"
        showActions={true}
        hideConfirm={true}
        hideCancel={false}
        cancelLabel="Fechar"
      >
        <Flex verticalAlign="center">
          {selected ? (
            <>
              <CardInfo
                marginLeft={0}
                marginRigth={0}
                label={translate("emitterName")}
                info={selected?.enterpriseName || selected?.userName}
              />
              <Columns columns="3">
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Due Date")}
                  info={formatDate(selected?.dueDate)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Value")}
                  info={format(Number(selected?.total).toFixed(2), MASK_TYPES.realWithName)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Status")}
                  info={translate(selected?.status)}
                />
              </Columns>
              {selected?.status === "canceled" && selected?.newInvoices?.length > 0 && (
                <Span
                  color="red"
                  style={{
                    marginBottom: "2.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  Cancelado em favor da fatura de ID {selected?.newInvoices[0].id}
                  <S.IconView
                    style={{ cursor: "pointer", marginLeft: "1rem" }}
                    onClick={() => handleViewDetails(selected?.newInvoices[0].id)}
                    title={`Ver detalhes da fatura de ID ${selected?.newInvoices[0].id}`}
                  />
                </Span>
              )}
              {selected?.status === "pending" && (
                <>
                  <InputForm
                    type="text"
                    name="invoiceLink"
                    label={translate("Payment Link")}
                    value={parseInvoiceLink(selected?.gatewayInvoiceId)}
                    readonly
                  />
                  <Columns columns="2" style={{ marginBottom: "2.4rem" }}>
                    <Button
                      handleClick={() =>
                        handleClipboard(parseInvoiceLink(selected?.gatewayInvoiceId))
                      }
                    >
                      {translate("Copy Link")}
                    </Button>
                    <Button
                      handleClick={() => handleTab(parseInvoiceLink(selected?.gatewayInvoiceId))}
                    >
                      {translate("Open in new tab")}
                    </Button>
                  </Columns>
                </>
              )}
              <Columns>
                <InputForm
                  type="text"
                  name="invoiceLinkIugu"
                  label="Link IUGU"
                  value={selected?.invoiceUrl}
                  readonly
                />
              </Columns>
              <Columns columns="2">
                <Button variant="outline" handleClick={() => handleClipboard(selected?.invoiceUrl)}>
                  {translate("Copy Link")}
                </Button>
                <Button variant="outline" handleClick={() => handleTab(selected?.invoiceUrl)}>
                  {translate("Open in new tab")}
                </Button>
              </Columns>
              <Flex horizontalAlign="center">
                {linkCopied && (
                  <Label style={{ marginTop: "2rem", fontSize: "1.5rem", color: "#0071ee" }}>
                    {translate("Payment Link Copied")}
                  </Label>
                )}
              </Flex>
            </>
          ) : (
            <EmptyFeedback />
          )}
        </Flex>
      </Modal>
      <Modal
        title={`Itens da Fatura ${selected?.id}`}
        confirmLabel="Fechar"
        onCloseModal={() => setShowItems(false)}
        showModal={showItems}
        textAlign="text-left"
        showActions={true}
        hideConfirm={true}
        hideCancel={false}
        cancelLabel="Fechar"
        width="60%"
      >
        <Flex verticalAlign="center">
          {selected ? (
            <>
              <CardInfo
                marginLeft={0}
                marginRigth={0}
                label={translate("emitterName")}
                info={selected?.enterpriseName || selected?.userName}
              />
              <Columns columns="3">
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Due Date")}
                  info={formatDate(selected?.dueDate)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Value")}
                  info={format(Number(selected?.total).toFixed(2), MASK_TYPES.realWithName)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Status")}
                  info={translate(selected?.status)}
                />
              </Columns>
              <Table>
                <TableHeader>
                  <RenderHeaders headers={headersItems} />
                </TableHeader>
                <TableBody>
                  {!invoiceItems && (
                    <tr key={"idLoading"}>
                      <TableItem padding={itemPadding} colSpan="5">
                        <ColumnFlex horizontalAlign={"center"} width="100%">
                          <Spinner width={"4rem"} height={"4rem"} />
                          <Span style={{ marginTop: "0.5rem" }}>Aguarde... Carregando items</Span>
                        </ColumnFlex>
                      </TableItem>
                    </tr>
                  )}
                  {invoiceItems &&
                    invoiceItems?.map(({ id, description, quantity, price }) => (
                      <tr key={id}>
                        <TableItem padding={itemPadding} textAlign="center">
                          <Span>{id}</Span>
                        </TableItem>
                        <TableItem padding={itemPadding}>
                          <Span>{description}</Span>
                        </TableItem>
                        <TableItem padding={itemPadding}>
                          <Span>{format(Number(price).toFixed(2), MASK_TYPES.realWithName)}</Span>
                        </TableItem>
                        <TableItem padding={itemPadding} textAlign="center">
                          <Span>{quantity}</Span>
                        </TableItem>
                        <TableItem padding={itemPadding}>
                          <Span>
                            {format(Number(price * quantity).toFixed(2), MASK_TYPES.realWithName)}
                          </Span>
                        </TableItem>
                      </tr>
                    ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <EmptyFeedback />
          )}
        </Flex>
      </Modal>
      <Modal
        title={`Fatura #${selected?.id}?`}
        confirmLabel="Sim"
        onConfirmModal={() => handleProtheus(selected?.id)}
        onCloseModal={() => setShowConfirmProtheus(false)}
        showModal={showConfirmProtheus}
        showActions={true}
        hideConfirm={!selected?.enterpriseId}
        hideCancel={false}
        cancelLabel={!selected?.enterpriseId ? "Fechar" : "Não"}
        width="50rem"
        closeOnConfirm={true}
      >
        <Flex verticalAlign="center" horizontalAlign="center">
          {selected?.protheus && (
            <Span>Fatura já enviada para o Protheus, confirma o reenvio?</Span>
          )}
          {!selected?.protheus && <Span>Confirma o envio da fatura para o Protheus?</Span>}
        </Flex>
      </Modal>
    </>
  );
};

List.propTypes = {
  handleViewDetails: PropTypes.func.isRequired,
  invoices: PropTypes.array.isRequired,
  params: PropTypes.object,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string,
  order: PropTypes.string,
};

List.defaultProps = {
  invoices: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
