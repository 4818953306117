import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useItems } from "hooks";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { urls } from "utils/constants";
import { PaymentGatewaysActions } from "store/ducks/paymentGateways";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import PaymentGatewaysList from "components/presentation/PaymentGateways/List";

const PaymentGatewaysListWithSpinner = WithSpinner(PaymentGatewaysList);

const PaymentGatewaysListContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const {
    PaymentGatewaysRequestsStatus,
    paymentGateways: { items, total, totalPages },
  } = useSelector(({ paymentGateways }) => paymentGateways);
  const fetchGateways = (newParams) => {
    dispatch(PaymentGatewaysActions.getPaymentGateways(newParams));
  };
  const { orderBy, order, page, perPage, setPage, setPerPage, setOrderBy, setOrder } = useParams({
    callback: fetchGateways,
    defaultOrderBy: "name",
  });

  return (
    <>
      <NavBar title={translate("Payment Gateways")} />
      <PaymentGatewaysListWithSpinner
        requestStatus={PaymentGatewaysRequestsStatus.GET_PAYMENT_GATEWAYS}
        paymentGateways={items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
      />
    </>
  );
};

export default PaymentGatewaysListContainer;
