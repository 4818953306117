import * as Yup from "yup";
import { parseMoneyToFloat } from "helpers/sanitize";

const validationSchema = Yup.object().shape({
  payrollPrice: Yup.string().required("Campo obrigatório"),
  additionalBilletPrice: Yup.string().required("Campo obrigatório"),
  anualDiscount: Yup.string()
    .test(
      "Valor",
      "Você não pode atribuir 100% de desconto",
      (value) => parseMoneyToFloat(value) <= 100,
    )
    .required("Campo obrigatório"),
});

const defaultValues = {
  payrollPrice: "",
  additionalBilletPrice: "",
  anualDiscount: 0,
};

export { validationSchema, defaultValues };
