import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: InvoicePaymentTypes, Creators: InvoicePaymentActions } = createActions({
  getInvoiceByToken: ["token"],
  getInvoiceByTokenSuccess: ["payload"],
  chargeInvoice: ["payload"],
  chargeInvoiceSuccess: ["payload"],
  requestFailure: ["error", "originalType"],
  cleanState: null,
});

const INITIAL_STATE = {
  invoices: {
    items: [],
  },
  invoice: {},
  invoiceCharge: null,
  requestStatus: {
    [InvoicePaymentTypes.GET_INVOICE_BY_TOKEN]: REQUEST_NOT_STARTED,
    [InvoicePaymentTypes.CHARGE_INVOICE]: REQUEST_NOT_STARTED,
  },
};

const chargeInvoice = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [InvoicePaymentTypes.CHARGE_INVOICE]: REQUEST_PENDING,
  },
});

const chargeInvoiceSuccess = (state, { payload }) => ({
  ...state,
  invoiceCharge: payload,
  requestStatus: {
    ...state.requestStatus,
    [InvoicePaymentTypes.CHARGE_INVOICE]: REQUEST_RESOLVED,
  },
});

const getInvoiceByToken = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [InvoicePaymentTypes.GET_INVOICE_BY_TOKEN]: REQUEST_PENDING,
  },
});

const getInvoiceByTokenSuccess = (state, { payload }) => ({
  ...state,
  invoice: payload,
  requestStatus: {
    ...state.requestStatus,
    [InvoicePaymentTypes.GET_INVOICE_BY_TOKEN]: REQUEST_RESOLVED,
  },
});

const requestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  requestStatus: {
    ...state.requestStatus,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [InvoicePaymentTypes.GET_INVOICE_BY_TOKEN]: getInvoiceByToken,
  [InvoicePaymentTypes.GET_INVOICE_BY_TOKEN_SUCCESS]: getInvoiceByTokenSuccess,
  [InvoicePaymentTypes.CHARGE_INVOICE]: chargeInvoice,
  [InvoicePaymentTypes.CHARGE_INVOICE_SUCCESS]: chargeInvoiceSuccess,
  [InvoicePaymentTypes.REQUEST_FAILURE]: requestFailure,
  [InvoicePaymentTypes.CLEAN_STATE]: cleanState,
});
