import { put, takeLatest, call, all } from "redux-saga/effects";
import api from "services/api";
import { EnterpriseTypes, EnterpriseActions } from "store/ducks/enterprise";
import { GET, POST } from "utils/constants/verbs";
import {
  ACTIVITIES,
  ALL_AUTH_TYPES,
  BILLING_RANGES,
  ENTERPRISE_TYPES,
  ENTERPRISE_ACTIVITIES,
  ENTERPRISE_ACTIVITIES_WITH_PAGINATION,
  SUBSCRIPTION_PLANS_ENTERPRISE,
  ENTERPRISE_STATUS,
  ENTERPRISE_SUB_STATUS,
  ENTERPRISE_SUB_STATUS_BY_STATUS,
  ENTERPRISES_REGIMES,
  ENTERPRISES_ISSUANCES,
  CREATE_ENTERPRISE,
  ENTERPRISE_XML,
} from "utils/constants/endpoints";

export function* getTypes() {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_TYPES,
    });
    yield put(EnterpriseActions.getTypesSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getTypes"));
  }
}

export function* getEnterpriseXml() {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_XML,
    });
    yield put(EnterpriseActions.getEnterpriseXmlSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.getEnterpriseXmlFailure(error, "getEnterpriseXml"));
  }
}

export function* getActivities() {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_ACTIVITIES,
    });
    yield put(EnterpriseActions.getActivitiesSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getActivities"));
  }
}

export function* getActivitiesWithPagination({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_ACTIVITIES_WITH_PAGINATION,
      params,
    });
    yield put(EnterpriseActions.getActivitiesWithPaginationSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getActivities"));
  }
}

export function* getBillingRanges({ enterpriseTypeID }) {
  try {
    const { data } = yield api({
      method: GET,
      url: BILLING_RANGES(enterpriseTypeID),
    });
    yield put(EnterpriseActions.getBillingRangesSuccess(data, enterpriseTypeID));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getBillingRanges"));
  }
}

export function* getSubscriptionPlans({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: SUBSCRIPTION_PLANS_ENTERPRISE,
      params,
    });
    yield put(EnterpriseActions.getSubscriptionPlansSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getSubscriptionPlans"));
  }
}

export function* getAllStatus() {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_STATUS,
    });
    yield put(EnterpriseActions.getAllStatusEnterprisesSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getAllStatus"));
  }
}

export function* getAllSubStatus() {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_SUB_STATUS,
    });
    yield put(EnterpriseActions.getAllSubStatusSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getAllSubStatus"));
  }
}

export function* getAllSubStatusByStatus({ statusId }) {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_SUB_STATUS_BY_STATUS(statusId),
    });
    yield put(EnterpriseActions.getAllSubStatusByStatusSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getAllSubStatusByStatus"));
  }
}

export function* getAuthTypes() {
  try {
    const { data } = yield api({
      method: GET,
      url: ALL_AUTH_TYPES,
    });
    yield put(EnterpriseActions.getAuthTypesSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getAuthTypes"));
  }
}

export function* createEnterprise({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: CREATE_ENTERPRISE,
      data: payload,
    });
    yield put(EnterpriseActions.createEnterpriseSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "createEnterprise"));
  }
}

export function* fetchRegimes({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISES_REGIMES,
      params,
    });
    yield put(EnterpriseActions.fetchRegimesSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* fetchSMU({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: ACTIVITIES,
      params,
    });
    yield put(EnterpriseActions.fetchSMUSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* getIssuances({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: ENTERPRISES_ISSUANCES,
      data: payload,
    });
    yield put(EnterpriseActions.getIssuancesSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, "getIssuances"));
  }
}

export function* fetchEmitterResources({ params, type }) {
  try {
    const [
      { data: typeData },
      { data: activitiesData },
      { data: statusData },
      { data: subStatusData },
      { data: authTypesData },
    ] = yield all([
      call(api, { url: ENTERPRISE_TYPES }),
      call(api, { url: ENTERPRISE_ACTIVITIES }),
      call(api, { url: ENTERPRISE_STATUS }),
      call(api, { url: ENTERPRISE_SUB_STATUS }),
      call(api, { url: ALL_AUTH_TYPES }),
    ]);

    yield all([
      put(EnterpriseActions.getTypesSuccess(typeData)),
      put(EnterpriseActions.getActivitiesSuccess(activitiesData)),
      put(EnterpriseActions.getAllStatusSuccess(statusData)),
      put(EnterpriseActions.getAllSubStatusSuccess(subStatusData)),
      put(EnterpriseActions.getAuthTypesSuccess(authTypesData)),
    ]);

    const { data: regimesData } = yield call(api, { url: ENTERPRISES_REGIMES, params });

    yield put(EnterpriseActions.fetchRegimesSuccess(regimesData));
    yield put(EnterpriseActions.fetchEmitterResourcesSuccess());
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* getActivityGroups({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: "admin/enterprises/getActivityGroups",
      params,
    });
    yield put(EnterpriseActions.getActivityGroupsSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* getSources({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: "admin/enterprises/getSources",
      params,
    });
    yield put(EnterpriseActions.getSourcesSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* getResponsibleSale({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: "admin/enterprises/getResponsibleSale",
      params,
    });
    yield put(EnterpriseActions.getResponsibleSaleSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* getPartnersObvia({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: "admin/enterprises/getPartnersObvia",
      params,
    });
    yield put(EnterpriseActions.getPartnersObviaSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* getFinancialStatus({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: "admin/enterprises/getFinancialStatus",
      params,
    });
    yield put(EnterpriseActions.getFinancialStatusSuccess(data));
  } catch (error) {
    yield put(EnterpriseActions.enterpriseRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(EnterpriseTypes.GET_TYPES, getTypes);
  yield takeLatest(EnterpriseTypes.GET_ACTIVITIES, getActivities);
  yield takeLatest(EnterpriseTypes.GET_ACTIVITIES_WITH_PAGINATION, getActivitiesWithPagination);
  yield takeLatest(EnterpriseTypes.GET_BILLING_RANGES, getBillingRanges);
  yield takeLatest(EnterpriseTypes.GET_SUBSCRIPTION_PLANS, getSubscriptionPlans);
  yield takeLatest(EnterpriseTypes.GET_ALL_STATUS, getAllStatus);
  yield takeLatest(EnterpriseTypes.CREATE_ENTERPRISE, createEnterprise);
  yield takeLatest(EnterpriseTypes.GET_AUTH_TYPES, getAuthTypes);
  yield takeLatest(EnterpriseTypes.FETCH_REGIMES, fetchRegimes);
  yield takeLatest(EnterpriseTypes.FETCH_SMU, fetchSMU);
  yield takeLatest(EnterpriseTypes.GET_ISSUANCES, getIssuances);
  yield takeLatest(EnterpriseTypes.FETCH_EMITTER_RESOURCES, fetchEmitterResources);
  yield takeLatest(EnterpriseTypes.GET_ENTERPRISE_XML, getEnterpriseXml);
  yield takeLatest(EnterpriseTypes.GET_ACTIVITY_GROUPS, getActivityGroups);
  yield takeLatest(EnterpriseTypes.GET_SOURCES, getSources);
  yield takeLatest(EnterpriseTypes.GET_RESPONSIBLE_SALE, getResponsibleSale);
  yield takeLatest(EnterpriseTypes.GET_PARTNERS_OBVIA, getPartnersObvia);
  yield takeLatest(EnterpriseTypes.GET_FINANCIAL_STATUS, getFinancialStatus);
}
