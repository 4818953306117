import { put, takeLatest, call, cancelled } from "redux-saga/effects";
import axios from "axios";
import { EmitterTypes, EmitterActions } from "store/ducks/emitter";
import api from "services/api";
import { GET, PATCH, POST, PUT } from "utils/constants/verbs";
import {
  ENTERPRISES_LIST,
  ENTERPRISES_LIST_BILLING,
  ENTERPRISE_BY_ID,
  ENTERPRISE_STATUS,
  ENTERPRISE_PLAN_BY_ID,
  ENTERPRISES_EXPORT,
  ENTERPRISE_AUTH_TYPES,
  ENTERPRISE_CERTIFICATES,
  STATUS_LOG,
  ENTERPRISE_SERIAL_NUMBER,
  ENTERPRISE_SERVICES,
  ENTERPRISE_PRODUCTS,
  ENTERPRISE_NCM_CATEGORIES,
  ENTERPRISE_CFOP,
  ENTERPRISE_CST,
  BATCH_CLIENT_BY_ID,
  ENTERPRISE_LOGS,
} from "utils/constants/endpoints";

const { CancelToken } = axios;

function* addServicesToEmitter({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: POST,
        url: ENTERPRISE_SERVICES(id),
        data: payload,
      });
      yield put(EmitterActions.addServicesToEmitterSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* addProductsToEmitter({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: POST,
        url: ENTERPRISE_PRODUCTS(id),
        data: payload,
      });
      yield put(EmitterActions.addProductsToEmitterSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* addNcmToEmitter({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: POST,
        url: ENTERPRISE_NCM_CATEGORIES(id),
        data: payload,
      });
      yield put(EmitterActions.addNcmToEmitterSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* addCfopToEmitter({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: POST,
        url: ENTERPRISE_CFOP(id),
        data: payload,
      });
      yield put(EmitterActions.addCfopToEmitterSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

export function* getStatusLog({ id }) {
  try {
    const { data } = yield api({
      method: GET,
      url: STATUS_LOG(id),
    });
    yield put(EmitterActions.getStatusLogSuccess(data));
  } catch (error) {
    yield put(EmitterActions.enterpriseRequestFailure(error, "getStatusLog"));
  }
}

function* addCstToEmitter({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: POST,
        url: ENTERPRISE_CST(id),
        data: payload,
      });
      yield put(EmitterActions.addCstToEmitterSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitters(params, actionOnSuccess, type) {
  const source = CancelToken.source();

  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISES_LIST,
      params,
      cancelToken: source.token,
    });
    yield put(actionOnSuccess(data));
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export function* enterpriseLogs(params, type) {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISE_LOGS,
      params: params?.params,
    });
    yield put(EmitterActions.logsEmitterSuccess(data));
  } catch (error) {
    yield put(EmitterActions.logsRequestFailure(error, type));
  }
}

function* fetchEmittersList({ params }) {
  yield fetchEmitters(params, EmitterActions.fetchEmittersSuccess);
}

function* fetchEmittersSearch({ params }) {
  yield fetchEmitters(params, EmitterActions.fetchEmittersSearchSuccess);
}

function* fetchEmittersChat({ params, type }) {
  yield fetchEmitters(params, EmitterActions.fetchEmittersChatSuccess, type);
}

export function* getEnterprisesBilling({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: ENTERPRISES_LIST_BILLING,
      params,
    });
    yield put(EmitterActions.getEnterprisesBillingSuccess(data));
  } catch (error) {
    yield call(EmitterActions.emittersRequestFailure(error, "getEnterprisesBilling"));
  }
}

function* fetchEmitterById({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_BY_ID(id),
      });
      yield put(EmitterActions.fetchEmitterByIdSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitterClientById({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: BATCH_CLIENT_BY_ID(id),
      });
      yield put(EmitterActions.fetchEmitterByIdSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitterAuthTypes({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_AUTH_TYPES(id),
      });
      yield put(EmitterActions.fetchEmitterAuthTypesSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitterServices({ id, type, queryParams }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_SERVICES(id),
        params: queryParams,
      });
      yield put(EmitterActions.fetchEmitterServicesSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitterProducts({ id, type, params }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_PRODUCTS(id),
        params,
      });
      yield put(EmitterActions.fetchEmitterProductsSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitterNcm({ id, type, params }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_NCM_CATEGORIES(id),
        params,
      });
      yield put(EmitterActions.fetchEmitterNcmSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitterCfop({ params, id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_CFOP(id),
        params,
      });
      yield put(EmitterActions.fetchEmitterCfopSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmitterCst({ id, type, params }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_CST(id),
        params,
      });
      yield put(EmitterActions.fetchEmitterCstSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

export function* editEmitter({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PUT,
        url: ENTERPRISE_BY_ID(id),
        data: payload,
      });
      yield put(EmitterActions.editEmitterSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

export function* editEmitterPlan({ id, payload }) {
  try {
    if (payload.nextPaymentDate && payload.nextPaymentDate !== "") {
      const dates = payload.nextPaymentDate.split("/");
      payload.nextPaymentDate = new Date(dates[2], parseInt(dates[1]) - 1, dates[0]);
    } else {
      delete payload.nextPaymentDate;
    }
    if (Number(id)) {
      const { data } = yield api({
        method: PATCH,
        url: ENTERPRISE_PLAN_BY_ID(id),
        data: payload,
      });
      yield put(EmitterActions.editEmitterPlanSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, "editEmitterPlan"));
  }
}

export function* editEmittersStatus({ payload }) {
  try {
    const { data } = yield api({
      method: PATCH,
      url: ENTERPRISE_STATUS,
      data: payload,
    });
    yield put(EmitterActions.editEmittersStatusSuccess(data));
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, "editStatus"));
  }
}

export function* editCertificate({ id, payload }) {
  try {
    if (Number(id)) {
      api.defaults.headers.common["Content-Type"] = "multipart/form-data";
      const { data } = yield api({
        method: PATCH,
        url: ENTERPRISE_CERTIFICATES(id),
        data: payload,
      });
      yield put(EmitterActions.editCertificateSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, "editStatus"));
  }
}

export function* editSerialNumber({ id, payload }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PATCH,
        url: ENTERPRISE_SERIAL_NUMBER(id),
        data: payload,
      });
      yield put(EmitterActions.editSerialNumberSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, "editStatus"));
  }
}

export function* exportEmitters({ enterprises, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: ENTERPRISES_EXPORT,
      data: enterprises,
    });
    yield put(EmitterActions.exportEmittersSuccess(data));
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

function* fetchEmittersToPinById({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_BY_ID(id),
      });
      yield put(EmitterActions.fetchEmittersToPinByIdSuccess(data));
    }
  } catch (error) {
    yield put(EmitterActions.emittersRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(EmitterTypes.ADD_SERVICES_TO_EMITTER, addServicesToEmitter);
  yield takeLatest(EmitterTypes.ADD_PRODUCTS_TO_EMITTER, addProductsToEmitter);

  yield takeLatest(EmitterTypes.ADD_NCM_TO_EMITTER, addNcmToEmitter);
  yield takeLatest(EmitterTypes.ADD_CFOP_TO_EMITTER, addCfopToEmitter);
  yield takeLatest(EmitterTypes.ADD_CST_TO_EMITTER, addCstToEmitter);

  yield takeLatest(EmitterTypes.FETCH_EMITTERS, fetchEmittersList);
  yield takeLatest(EmitterTypes.FETCH_EMITTERS_SEARCH, fetchEmittersSearch);
  yield takeLatest(EmitterTypes.FETCH_EMITTERS_CHAT, fetchEmittersChat);
  yield takeLatest(EmitterTypes.FETCH_EMITTER_BY_ID, fetchEmitterById);
  yield takeLatest(EmitterTypes.FETCH_EMITTER_AUTH_TYPES, fetchEmitterAuthTypes);
  yield takeLatest(EmitterTypes.FETCH_EMITTER_SERVICES, fetchEmitterServices);
  yield takeLatest(EmitterTypes.FETCH_EMITTER_PRODUCTS, fetchEmitterProducts);

  yield takeLatest(EmitterTypes.FETCH_EMITTER_NCM, fetchEmitterNcm);
  yield takeLatest(EmitterTypes.FETCH_EMITTER_CFOP, fetchEmitterCfop);
  yield takeLatest(EmitterTypes.FETCH_EMITTER_CST, fetchEmitterCst);

  yield takeLatest(EmitterTypes.EDIT_EMITTER, editEmitter);
  yield takeLatest(EmitterTypes.EDIT_EMITTER_PLAN, editEmitterPlan);
  yield takeLatest(EmitterTypes.EDIT_EMITTERS_STATUS, editEmittersStatus);
  yield takeLatest(EmitterTypes.EDIT_CERTIFICATE, editCertificate);
  yield takeLatest(EmitterTypes.EDIT_SERIAL_NUMBER, editSerialNumber);
  yield takeLatest(EmitterTypes.EXPORT_EMITTERS, exportEmitters);

  yield takeLatest(EmitterTypes.GET_ENTERPRISES_BILLING, getEnterprisesBilling);
  yield takeLatest(EmitterTypes.FETCH_EMITTER_CLIENT_BY_ID, fetchEmitterClientById);
  yield takeLatest(EmitterTypes.ENTERPRISE_LOGS, enterpriseLogs);
  yield takeLatest(EmitterTypes.FETCH_EMITTERS_TO_PIN_BY_ID, fetchEmittersToPinById);

  yield takeLatest(EmitterTypes.GET_STATUS_LOG, getStatusLog);
}
