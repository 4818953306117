import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { DocumentsActions, DocumentsTypes } from "store/ducks/documents";
import {
  DOCUMENTS,
  DOCUMENTS_TYPES,
  DOCUMENTS_EXPORT,
  DOCUMENT_BY_ID,
} from "utils/constants/endpoints";
import { GET, POST, DELETE } from "utils/constants/verbs";

function* fetchDocuments({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: DOCUMENTS,
      params,
    });
    yield put(DocumentsActions.fetchDocumentsSuccess(data));
  } catch (error) {
    yield put(DocumentsActions.documentsRequestFailure(error, type));
  }
}

function* fetchDocumentById({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: DOCUMENT_BY_ID(id),
      });
      yield put(DocumentsActions.fetchDocumentByIdSuccess(data));
    }
  } catch (error) {
    yield put(DocumentsActions.documentsRequestFailure(error, type));
  }
}

function* fetchDocumentsTypes({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: DOCUMENTS_TYPES,
      params,
    });
    yield put(DocumentsActions.fetchDocumentsTypesSuccess(data));
  } catch (error) {
    yield put(DocumentsActions.documentsRequestFailure(error, type));
  }
}

function* uploadDocument({ formData, params, type }) {
  try {
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const { data } = yield api({
      method: POST,
      url: DOCUMENTS,
      data: formData,
      params,
    });
    yield put(DocumentsActions.uploadDocumentSuccess(data));
  } catch (error) {
    const failure = {
      response: error?.response?.data ?? {
        data: {
          message: "request-entity-too-large",
        },
      },
    };
    yield put(DocumentsActions.documentsRequestFailure(failure, type));
  }
}

function* deleteDocuments({ documents, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: DOCUMENTS,
      data: documents,
    });
    yield put(DocumentsActions.deleteDocumentsSuccess(data));
  } catch (error) {
    yield put(DocumentsActions.documentsRequestFailure(error, type));
  }
}

function* exportDocuments({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: DOCUMENTS_EXPORT,
      data: payload,
    });
    yield put(DocumentsActions.exportDocumentsSuccess(data));
  } catch (error) {
    yield put(DocumentsActions.documentsRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(DocumentsTypes.FETCH_DOCUMENTS, fetchDocuments);
  yield takeLatest(DocumentsTypes.FETCH_DOCUMENT_BY_ID, fetchDocumentById);
  yield takeLatest(DocumentsTypes.FETCH_DOCUMENTS_TYPES, fetchDocumentsTypes);
  yield takeLatest(DocumentsTypes.UPLOAD_DOCUMENT, uploadDocument);
  yield takeLatest(DocumentsTypes.DELETE_DOCUMENTS, deleteDocuments);
  yield takeLatest(DocumentsTypes.EXPORT_DOCUMENTS, exportDocuments);
}
