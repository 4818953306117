export default {
  useTerms: `CONSIDERAÇÕES PRELIMINARES 

  Considerando que a legislação tributária brasileira autoriza que empreendedores tenha vários tipos jurídicos em suas empresas, bem como diferentes regimes de tributação.
    
  Considerando que a empresa (CNPJ) objeto deste contrato pode mudar de regime tributário anualmente por regra, e, excepcionalmente, de acordo com os limites e proibições de permanência nos regimes tributários MEI, Simples Nacional, Lucro Presumido, Lucro Real e Lucro Arbitrado.
    
  Considerando que os serviços prestados pela OBVIA e o presente termo de uso se aplica exclusivamente a empresas que estão nos regimes tributários do MEI, Simples Nacional, Lucro Presumido. A impossibilidade de se formalizar ou manter-se formalizado no referido regime é de responsabilidade do Usuário.
    
  Considerando que a legislação tributária confere exclusivamente a empresas que estão nos regimes tributários do MEI uma série de flexibilidades, como a dispensa de emissão de notas fiscais nas vendas a pessoas física e que não determina explicitamente a necessidade de controle de caixa, embora exija que todo MEI informe sua renda bruta mensal em relatório específico, declaração anual e armazenamento de notas de compras, além de outras obrigações, alguns dos quais constituem serviços prestados por esta empresa.
    
  Considerando que a legislação tributária confere a empresas que estão nos regimes tributários Simples Nacional, Lucro Presumido obrigação de se ter um contador e cumprir com a legislação vigente.
    
  Considerando que é possível a obtenção do CNPJ para o MEI gratuitamente através dos órgãos públicos sem o apoio de assessoria especializada, ressaltamos que os serviços prestados pela OBVIA são de caráter privado e opcional.
    
  A nossa intenção é prestar um serviço de assessoria de qualidade de forma prática e que facilite o seu dia a dia e da sua empresa. A OBVIA está aberta a opiniões de seus Usuários e quer ajudar você a crescer de forma organizada.
    
  DO ACEITE DOS TERMOS
  Ao selecionar a opção "LI E CONCORDO COM OS TERMOS DE USO" , é atestado pela pessoa, física ou jurídica, que leu, entendeu, concorda e aceita (e tem capacidade jurídica para tanto) as presentes cláusulas e condições, vinculando-se e obrigando-se ao presente termos de uso, e principalmente consentindo com o acesso, coleta, uso, armazenamento, tratamento e técnicas de proteção às informações do usuário para a integral execução das funcionalidades ofertadas pela OBVIA, sejam elas gratuitas ou pagas. Caso não concorde com os Termos de Uso bem como suas atualizações, recomendamos que deixe de utilizar os nossos serviços.
    
  DOS TERMOS GERAIS
  Tendo em mente que a internet é um sistema de rede de computadores dispersos em todo o planeta que trocam dados e mensagens utilizando um protocolo comum e está em constante mutação e evolução, a OBVIA , buscando acompanhar essas mudanças e proporcionar uma melhor experiência do usuário, se reserva no direito de, a qualquer tempo e por sua discricionariedade, aditar o presente Termo de Uso e sua Política de Privacidade, sendo o usuário notificado de quaisquer alteração e também disponível permanentemente no site oficial: www.obviacontabil.com.br.
    
  A OBVIA  se reserva no direito de fazer alterações no software, bem como em seus serviços, visando sempre a melhoria dos serviços prestados aos usuários, sem a necessidade de aviso prévio ao Usuário.
    
  Preocupados sempre com o atendimento a legislação vigente, bem como o bem comum, a OBVIA  se reserva no direito de definir critérios de acesso e elegibilidade aos serviços a qualquer pessoa de seu exclusivo critério; direito de bloquear, editar, remover ou monitorar o acesso do usuário, conteúdos e/ou contas que estejam desrespeitando o presente Termo de Uso e/ou Política de Privacidade, inclusive qualquer legislação vigente aplicável.
    
  Caso a OBVIA  ou o Usuário resolva cancelar o acesso aos Serviços, todo o conteúdo e documentos depositados na plataforma serão disponibilizados pelo prazo de 30 (trinta) dias, contados do cancelamento do acesso. Após esse período, o conteúdo deixará de ser acessível pela conta do Usuário.
    
  DOS SERVIÇOS GRATUITOS E/OU INCLUSOS NOS HONORÁRIOS
  O objeto deste termo consiste na disponibilização pelo sistema da OBVIA  ao Usuário de ferramentas e sistemas voltados para atender demandas específicas dos contratantes, tais como:
    
  1. Assessoria em todo processo de formalização, culminando na obtenção do CNPJ e demais inscrições em órgãos públicos (Junta Comercial, Prefeitura). As informações prestadas pelo Usuário são de sua inteira responsabilidade.
    
  2. Orientação de registro nos órgãos públicos para emissão de notas fiscais, caso o Usuário assim requeira por escrito.
    
  3. Disponibilização por e-mail ou software dos documentos gerados no processo de formalização.
    
  4. Disponibilização de documentos básicos da empresa, como Certificado de Condição de Microempreendedor Individual (CCMEI), Cartão CNPJ e Certidão Negativa de Débitos (CND).
    
  5. Alteração de atividades, endereços ou outras alterações cadastrais no sistema do MEI.
    
  6. Disponibilização no software da OBVIA  da possibilidade da empresa emitir suas Notas Fiscais, das guias mensais de pagamento dos impostos e relatórios oficias exigidos pelos órgãos públicos (Balanço, balancetes, relação de faturamento).
    
  7. Elaboração de todas declarações exigidas pelo governo, eventuais informações necessárias para elaboração das declarações poderão ser solicitadas aos clientes e deverão ser enviadas pelo usuário, sendo o mesmo responsável exclusivo pelas informações solicitadas.
    
  8. Atendimento das exigências legais previstas em atos normativos, fiscais e/ou tributários, bem como eventuais procedimentos de fiscalização tributária.
    
  9. Orientação e esclarecimento de dúvidas com base nas Leis da Previdência Social e Imposto de Renda referente aos rendimentos aferidos pelo Usuário MEI.
    
  10. Declaração de faturamento para fins bancários, com base em dados que comprovem oficialmente a receita obtida, como notas ficais válidas emitidas e receitas obtidas por máquinas de cartão, disponibilizando o extrato para comprovar a veracidade do rendimento.
    
  O Usuário, ao aceite do presente termo, fica ciente e concorda que a OBVIA  presta serviço de assessoria contábil, não incluindo qualquer serviço de deslocamento à órgãos públicos ou privados, bem como o pagamento de taxas e emolumentos que porventura venha a ser cobrados de tais órgãos, ficando esse tipo de serviço ou encargo às expensas do próprio Usuário.
    
  Caso haja a criação de novas obrigações acessórias e/ou tributos por parte das entidades públicas, o novo custo, se a OBVIA  julgar necessário, será calculado e repassado ao Usuário, com aviso prévio de 30 dias.
    
  O possível período de avaliação gratuita disponibilizado por liberalidade da OBVIA  não gera vínculo contratual, devendo o Usuário concordar com o contrato de prestação de serviços no ato da assinatura do plano. Porém, os serviços oferecidos durante a avaliação devem respeitar as condições e regras como se contratado fosse, para a correta fruição dos serviços disponibilizados.
    
  Os serviços disponibilizadas bem como o período da disponibilização não gera direito adquirido por parte do Usuário, podendo a OBVIA  interromper, limitar ou diminuir o período de disponibilização bem como as funcionalidades oferecidas gratuitamente.
    
  DA LICENÇA DE USO DO SOFTWARE
  O presente Termo de Uso concede ao Usuário uma licença revogável, não exclusiva e intransferível para usar o software desenvolvido pela OBVIA . Em nenhuma hipótese o Usuário terá acesso ao código fonte do Software ora licenciado, por esse se tratar de propriedade intelectual da OBVIA .
    
  Sendo assim, o Usuário não pode:
    
  1. Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o software objeto deste Termo de Uso.
    
  2. Praticar engenharia reversa, descompilação e/ou desmontagem do Software.
    
  É vedado qualquer uso dos Serviços ou seu acesso por meios automatizados ou macros (crawling, scraping, caching). É terminantemente proibido o acesso, visualização ou obtenção de informação dos serviços, dados de outros usuários ou terceiros filiados à OBVIA  e seus serviços, com exceção dos mecanismos de busca padrão ou demais tecnologias aprovadas pela OBVIA .
    
  DOS DEVERES, OBRIGAÇÕES, DECLARAÇÕES E GARANTIAS DO USUÁRIO
  O Usuário, ao aceite do presente termo, para que OBVIA  possa cumprir prazos legais e metas internas de disponibilização tempestiva de informações gerenciais úteis, claras e relevantes, assim como cumprir com as obrigações legais e fiscais, além da correta fruição dos serviços, obriga-se a:
    
  1. Prestar todas as informações necessárias para a confecção das declarações e demais obrigações legais, incluindo, mas não se limitando, a informação do faturamento total, discriminação entre vendas de produtos e serviços, disponibilização de notas fiscais e manutenção das informações cadastrais atualizadas. A fidedignidade de toda e qualquer informação prestada pelo Usuário é de sua exclusiva responsabilidade.
    
  2. Mensalmente, deve ser enviada toda informação contábil, tais como Notas Fiscais de compra, movimentações financeiras ou de departamento pessoal referente ao mês anterior, de forma digital, para que seja possível processar os informes contábeis/fiscais exigidos pela legislação tributária.
    
  3. Nenhuma responsabilização caberá à OBVIA  por consequência de envio de documentos e informações fora do prazo estabelecido no item 2.
    
  4. Manter-se treinado ou treinar terceiros para operacionalizar o software, objetivando uma melhor experiência e a consequente fruição dos benefícios proporcionados.
    
  5. Prover, sempre que ocorrer qualquer problema com o software, toda a documentação, relatórios e demais informações que relatem as circunstâncias do problema ocorrido, com o objetivo de facilitar e agilizar os trabalhos.
    
  6. Manter, às suas expensas, linha de comunicação, aparelho de celular, software de comunicação, endereço de correio eletrônico e outros meios e recursos necessários para a comunicação com a OBVIA .
    
  7. Responsabilizar-se pelas informações inseridas no software, pelo cadastramento, declarações e demais informações necessárias à realização dos trabalhos disponibilizados pela ferramenta. A OBVIA  em hipótese alguma será responsável pela integridade e fidedignidade das informações e conteúdos informados, não sendo estas informações revisadas em momento algum. A responsabilidade pelas informações inseridas, portanto, é exclusiva e limitada ao Usuário.
    
  8. Certifica-se de que não está proibido por determinação legal e/ou contratual de passar informações financeiras, pessoais, bem como qualquer outro dado à OBVIA , necessários para a execução dos serviços oferecidos por este Termo de Uso.
    
  9. Não utilizar o software de qualquer forma que possa implicar em ilícito, infração, violação de direitos e/ou danos à OBVIA  ou terceiros. Inclusive ações, omissões e adulterações que violem a legislação aplicável fiscal, tributária, civil, penal e demais esferas do nosso ordenamento jurídico vigente.
    
  10. Não publicar, enviar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de Tróia ou qualquer outro programa que possa contaminar, destruir ou interferir no correto funcionamento do software.
    
  11. Informar à OBVIA  sempre que houver qualquer alteração do conteúdo que possa impedir, limitar e/ou prejudicar o acesso ao conteúdo necessário para a execução das funcionalidades ofertadas pelo software.
    
  12. Caso o Usuário acredite que seu login e senha de acesso aos serviços tenham sido apropriados por outrem e/ou sejam de conhecimento de outras pessoas, por qualquer razão, deverá imediatamente comunicar por escrito à OBVIA , através do e-mail suporte@obviacontabil.com, sem prejuízo da alteração de senha imediata por meio do Software.
    
  O Usuário concorda e tem ciência que é o responsável exclusivo por todos os custos e despesas referentes ao seu acesso e uso dos serviços, incluindo, mas não se limitando a mensagens, conexão de internet e demais custos incorridos para a fruição ao uso dos serviços.
    
  DEVERES, OBRIGAÇÕES DECLARAÇÕES E GARANTIAS DA OBVIA 
  A OBVIA  obriga-se a:
    
  1. Fornecer, ato contínuo ao aceite deste Termo de Uso, acesso ao software pelo prazo estabelecido entre as partes.
    
  2. Desempenhará os serviços com todo zelo, diligência e honestidade, observada a legislação vigente, resguardando os interesses do Usuário, sem prejuízo e independências profissionais.
    
  3. Alterar as especificações e/ou características do software licenciados para melhoria e/ou correção de erros.
    
  4. Disponibilizar acesso aos serviços de suporte através do próprio software ou correio eletrônico (suporte@obvia.com), para esclarecimento de dúvidas de ordem não funcional diretamente relacionadas a problemas no software.
    
  5. Realizar os melhores esforços para manter as informações financeiras, de conta e pessoais do Usuário, bem como registro de acesso, em sigilo, nos termos do presente instrumento, em respeito a confiabilidade dos Usuários no uso da aplicação, bem como à legislação aplicável.
    
  DO ENVIO E ARMAZENAMENTO DE INFORMAÇÕES
  Como Usuário, quando você utiliza nossos serviços, você nos envia diversos dados, documentos, informações e conteúdo, responsabilizando-se em manter sua própria cópia de segurança (backup) bem como armazenamento dos documentos físicos, isentando a OBVIA  por qualquer responsabilidade nesse sentido. A OBVIA  não será responsável por qualquer modificação, suspensão ou descontinuação dos serviços, ou qualquer perda, ainda que parcial, do conteúdo e informações do Usuário.
    
  DA REMUNERAÇÃO, FORMA DE PAGAMENTO E CONTRATAÇÃO DO PLANO PREMIUM
  Pela prestação dos Serviços, o Usuário pagará ao OBVIA, o valor referente ao preço do plano escolhido. A OBVIA  poderá oferecer diferentes planos, inclusive planos com diferentes condições e limitações. Quaisquer termos consideravelmente diferentes dos termos constantes neste Contrato serão informados durante a inscrição ou em outros comunicados disponibilizados para você.
    
  Não integram o preço os valores referentes aos Serviços Adicionais, os quais serão cobrados a parte pela OBVIA  conforme sejam solicitados pelo Usuário e prestados os Serviços Adicionais.
    
  O preço será pago por meio de débito em cartão de crédito do Usuário, na data de contratação dos serviços ou em boleto bancário com opção de escolha da data de pagamento pelo usuário conforme opção disponível no momento da contratação do plano.
    
  Fica a OBVIA  desde já autorizado a efetuar a cobrança do preço e dos valores devidos pelos Serviços Adicionais eventualmente contratados do cartão de crédito informado pelo Usuário, ou boleto bancário, independentemente de aviso prévio ou posterior nesse sentido.
    
  O Preço poderá ser reajustado a cada 12 (doze) meses, contados da data de assinatura deste Contrato, de acordo com a variação do IPCA – Índice Nacional de Preço ao Consumidor Amplo, conforme divulgado pelo Instituto Brasileiro de Geografia e Estatística - IBGE, ou, na falta deste, por outro índice oficial que venha a substituí-lo, ficando a OBVIA  autorizada a promover a cobrança do valor corrigido.
    
  DA PUBLICIDADE
  A OBVIA  poderá divulgar anúncios de diversos anunciantes, logo, links, endereços virtuais ou demais caminhos que direcionem o Usuário para sites/serviços/aplicativos/softwares de terceiros que poderão ser disponibilizados no/por meio do Software. A OBVIA  não atua como prestador de serviços de consultoria ou ainda intermediário ou participante em nenhum negócio jurídico entre seus Usuários e seus anunciantes. Sendo assim, o software pode incluir ou direcionar o Usuário a conteúdo, ambientes virtuais, informações ou dados que não são de seu controle ou responsabilidade. Desta forma, a OBVIA  não se responsabiliza ou garante, a qualquer título e a qualquer tempo, por referidos serviços/ambientes virtuais/softwares/sites/aplicativos de terceiros ou por eles disponibilizados, sendo a responsabilidade de seu respectivo controlador.
    
  É dever do Usuário a leitura dos Termos de Uso e Política de Privacidade do site acessado e agir conforme o determinado. A OBVIA  não verifica, controla, aprova ou garante a adequação ou exatidão das informações ou dados disponibilizados em tais links, não sendo, portanto, responsável por prejuízos, perdas ou danos ocasionados por atrasos, defeitos, erros ou omissões que possam existir nos sistemas ou serviços disponibilizados pelos sites referidos acima ou ocorridos pela visita de tais sites, cabendo ao Usuário verificar a confiabilidade das informações e dados ali exibidas antes de utilizá-los.
    
  Desse modo, você deve sempre verificar a veracidade das informações obtidas pelo meio eletrônico da OBVIA  e tomar todas as medidas necessárias para se proteger de danos, fraudes ou estelionatos, inclusive online e off-line.
    
  DA INDISPONIBILIDADE DO SERVIÇO
  Apesar de todos os esforços da OBVIA  para manter os serviços em pleno funcionamento, o Usuário tem conhecimento e concorda que a internet (e, por consequência os serviços) está continuamente exposta a terceiros, usuários ou não, hackers¸ softwares mal intencionados e similares, estando o serviço sujeito a diversas brechas de segurança e que, portanto, o compartilhamento e inserção de informações e conteúdo pelo Usuário nos serviços ou por meio dos serviços podem não ser totalmente seguros, o Usuário assume o risco de utilização e acesso dos Serviços, isentando a OBVIA  de qualquer responsabilidade nesse sentido.
    
  Ainda que seja a intenção da OBVIA  que o serviço esteja disponível o máximo de tempo possível, a OBVIA  reconhece que, por questões técnicas e operacionais, terão ocasiões em que o serviço será interrompido, incluindo, mas não se limitando, a interrupções para reparos, atualizações, por motivos de falha na telecomunicação, falta de energia ou de equipamentos, não cabendo qualquer responsabilidade da OBVIA  nesse sentido. No caso de interrupções programadas, a OBVIA  notificará via email seus Usuários sobre a interrupção e seu provável tempo de duração.
    
  O Usuário fica ciente, também, que há prazos e vencimentos para o cumprimento das obrigações relacionadas à empresa, como o pagamento das guias de impostos e envio de informações solicitadas pela OBVIA, eventual interrupção dos serviços por vontade alheia da aplicação não imputa a responsabilidade dessas obrigações, como multa, acréscimos legais e moratórios e demais sanções a OBVIA , ficando o Usuário como responsável exclusivo dessas obrigações.
  
  DAS GARANTIAS LIMITADAS
  Na extensão máxima permitida pela lei em vigor, o software é fornecido "no estado em que se encontra" e "conforme a disponibilidade", com todas as falhas e sem garantia de qualquer espécie.
    
  A OBVIA  não garante que as funções contidas no software atendam a todas as suas necessidades, que a operação do software será ininterrupta e/ou livre de erros, que qualquer serviço continuará disponível, que os defeitos no software serão corrigidos ou que o software será compatível ou funcione com qualquer software, aplicações ou serviços de terceiros.
  
  A OBVIA  utilizará seus melhores esforços para corrigir eventuais falhas e erros no funcionamento do software, podendo a seu critério, substituir a cópia dos programas com falhas por cópias corrigidas. Contudo a OBVIA  não pode garantir um prazo para tais reparos. Os erros no funcionamento serão corrigidos durante o período que for necessário e a OBVIA  não se responsabiliza por danos decorrentes da não disponibilidade.
    
  Em nenhum caso a OBVIA  será responsável por danos pessoais e/ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão e/ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo e/ou perda comercial, decorrentes ou relacionados à perda de informações ou inabilidade em usar o Software, por qualquer outro motivo.
    
  A OBVIA  tampouco se responsabiliza por qualquer dano, prejuízo e/ou perda no equipamento do usuários causados por falhas no sistema, no servidor ou na conexão à Internet, ainda que decorrentes de condutas de terceiros, inclusive por ações de softwares maliciosos como vírus, cavalos de Tróia, e outros que possam, de algum modo, danificar o equipamento ou a conexão dos Usuários em decorrência do acesso, utilização e/ou navegação da OBVIA , bem como a transferência de dados, arquivos, imagens, textos, áudios e/ou vídeos contidos no mesmo.
    
  O Usuário reconhece e concorda que, para que grande parte de seus serviços sejam oferecidos, a OBVIA  considerou os riscos envolvidos e a avaliação de viabilidade econômica dos serviços. Por esse motivo, o Usuário declara, reconhece e concorda, expressamente, que o valor máximo de indenização devido pela OBVIA  e suas partes relacionadas, em decorrência de sua responsabilidade, decorrentes deste Termo de Uso, estará limitado ao valor mensal do plano escolhido pelo Usuário. Sob nenhuma circunstância a responsabilidade integral da OBVIA  com relação ao Usuário por todos os danos excederá o valor acima estipulado. Esclarece-se que esta cláusula é aplicável caso sejam por qualquer motivo reconhecidas pela justiça como inaplicáveis quaisquer outras cláusulas deste Termo de Uso.
    
  DA ISENÇÃO DE RESPONSABILIDADE DA OBVIA 
  Infelizmente, existem alguns fatores que não podem ser controlados ou evitados pela OBVIA , e que podem resultar em uma aparente falha em nossos serviços. Sendo assim, não podemos nos responsabilizar por:
    
  1. falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista culpa da OBVIA ;
    
  2. cumprimento dos prazos legais do Usuário para entrega de documentos fiscais solicitados pela OBVIA, pagamentos de impostos e/ou guias de pagamento;
    
  3. danos ou prejuízos decorrentes de decisões administrativas, gerenciais, comerciais ou judiciais tomadas com base nas informações fornecidas pelo software;
    
  4. problemas definidos como "caso fortuito" ou "força maior" contemplados pelo art. 393, do Código Civil Brasileiro;
    
  5. eventuais problemas oriundos de ações de terceiros que possam interferir na qualidade dos serviços;
    
  6. revisar as informações de conta, tampouco pelo conteúdo fornecidos pelo Usuário, sejam fiscais ou pelas informações financeiras e obtidas junto aos sites e/ou meios virtuais das instituições financeiras, seja no que tange à precisão dos dados, seja quanto à legalidade ou ameaça de violação em função do fornecimento destas informações. O acesso às informações de conta e às informações financeiras do Usuário depende de serviços prestados pelas instituições financeiras. Sendo assim, a OBVIA  não assume qualquer responsabilidade quanto à qualidade, precisão, pontualidade, entrega ou falha na obtenção das referidas informações junto aos sites e/ou meios virtuais das instituições financeiras;
    
  7. quaisquer produtos e/ou serviços oferecidos por meio dos sites e/ou meios virtuais das instituições financeiras, ainda que de maneira solidária ou subsidiária, e
    
  8. falta de envio de informações, informações incompletas, equivocadas ou omissas do usuário e de sua contabilidade que possa prejudicar a correta execução dos serviços.
    
  DO CONSENTIMENTO LIVRE, EXPRESSO E INFORMADO PARA ACESSOÀ INFORMAÇÕES CONFIDENCIAIS E DADOS PESSOAIS
  Para que a OBVIA  possa ajudar a sua empresa e prestar seu serviço de forma satisfatória, é necessária uma relação de confiança.
    
  O Usuário ao aceitar utilizar o software, além de aceitar integralmente este Termo de Uso também consente, livre e expressamente, que a OBVIA  colete, use, armazene e faça o tratamento de suas informações, incluindo seus dados pessoais, financeiros, bancários, de conta, os quais serão necessários para que os serviços ofertados sejam prestados em sua integralidade.
    
  Para tanto, o Usuário consente, livre e expressamente, em fornecer os dados que permitam o acesso a suas informações para que o Software execute todas as funções para as quais foi projetado.
    
  Ainda, o Usuário declara e reconhece que para a prestação dos serviços e funcionalidades ofertadas pelo software, a OBVIA  acessa informações financeiras diretamente no site e/ou outros meios virtuais das instituições financeiras, sem fazer qualquer emulação de medidas de segurança, utilizando-se apenas das informações pessoais, de conta, bem como outras eventualmente necessárias, fornecidas pelo Usuário, conforme autorizado.
    
  A OBVIA  declara que todas as informações de conta serão utilizadas única e exclusivamente para visualização das Informações Financeiras nos sites e/ou meios virtuais das instituições financeiras, sendo absolutamente vedado a OBVIA  a realização de quaisquer transações
    
  O Usuário consente que quando acessar o site da OBVIA , esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao Site, endereço de protocolo de Internet, páginas visitadas e tempo médio gasto no Site. Tais informações poderão ser usadas para orientar o próprio Usuário e melhorar os Serviços ofertados.
    
  O Usuário consente livre e expressamente que suas informações poderão ser transferidas a terceiros em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle da OBVIA .
    
  DA PROPRIEDADE INTELECTUAL
  A OBVIA  é proprietária ou possui a devida licença de todos os direitos, titularidade e participação pertinentes aos serviços, inclusive todos os direitos referentes à propriedade intelectual e todo e qualquer direito de propriedade, inclusive todos os requerimentos, renovações, extensões e restaurações relacionadas aos serviços, especialmente incluindo, mas não se limitando a, nomes comerciais, marcas comerciais, marcas de serviço, insígnias, conjunto imagem (trade dress), nomes de domínio, slogans, layout, logotipos, símbolos e outros sinais distintivos e elementos de identificação visual, direitos autorais, software, patentes de invenção, modelos de utilidade, desenhos industriais, know-how e segredos comerciais, quer estejam tais direitos registrados ou não, utilizados para prestar os serviços aos Usuários, observada a legislação aplicável referente aos direitos de propriedade intelectual e industrial ("Propriedade Intelectual").
    
  O Usuário não pode modificar, adaptar, traduzir, preparar trabalhos derivados, descompilar, fazer engenharia reversa, desmontar nem tentar de forma alguma alterar o código-fonte e/ou software da OBVIA  utilizado na prestação dos serviços.
    
  A reprodução, transmissão e distribuição de tais materiais, não são permitidas sem o expresso consentimento por escrito da OBVIA , sobretudo para o fim econômico ou comercial.
    
  Será caracterizada como infração da legislação vigente, o uso indevido de materiais protegidos por Propriedade Intelectual apresentados na OBVIA , sujeitando-se o Usuário infrator às medidas judiciais cabíveis à espécie, sem prejuízo de eventual indenização por perdas e danos e honorários advocatícios à OBVIA , bem como a terceiros que venham a sofrer pelos atos do Usuário.
    
  DA DECLARAÇÃO DE VONTADE
  O Usuário declara ter ciência dos direitos e obrigações decorrentes do presente Termo de Uso, constituindo este instrumento o acordo completo entre as partes. Declara, ainda, ter lido, compreendido e aceito todos os termos e condições.
    
  O Usuário declara que foi devidamente informado sobre a Política de Privacidade e ambientes de proteção de informações confidenciais, dados pessoais e registros de acesso, consentindo livre e expressamente às ações de coleta, uso, armazenamento e tratamento das referidas informações e dados.
    
  O Usuário declara ainda que está ciente de que para que usufrua de algumas das funcionalidades do Software terá que disponibilizar informações de conta e/ou certificado digital para que o software, de forma automatizada, colete informações diretamente nos sites específicos para a fruição da prestação dos serviços e/ou outros meios virtuais das instituições financeiras, sendo a OBVIA , neste caso, representante e mandatária do Usuário nestes atos.
    
  DA RESCISÃO
  O Usuário poderá rescindir o presente contrato a qualquer tempo através de função especifica do software, ou desde que comunique à OBVIA, por escrito, importando, no que se refere ao plano contratado, no pagamento, pelo mesmo, do saldo devedor, se existente.
   
  Para os serviços com pagamento antecipado, caso o Usuário decida rescindir o presente contrato antes do término do prazo contratado, será reembolsado 50% (cinquenta por cento) do saldo restante do contrato. O restante será considerado como multa para cobrir os custos operacionais pelo cancelamento antecipado.
    
  No caso de rescisão do presente contrato, os dados pessoais, bancários, financeiros e demais informações do Usuário ficarão disponíveis conforme este Termo de Uso, podendo ser excluídos permanentemente após 30 (trinta) dias da data da rescisão.
    
  DAS DISPOSIÇÕES LEGAIS FINAIS
  O Usuário não poderá prestar serviços a terceiros utilizando o software da OBVIA  sem autorização prévia e expressa da mesma.
   
  Caso o Usuário venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em parte, quer seja do dicionário de dados, quer seja do programa, será considerado como parte do software fornecido pela OBVIA , ficando, portanto, sua propriedade incorporada pela OBVIA  e seu uso condicionado a estas cláusulas contratuais.
    
  A tolerância, por qualquer das Partes, à infração das cláusulas e disposições contidas neste Termo de Uso e/ou Política de Privacidade, bem como a prática de quaisquer atos ou procedimentos não previstos de forma expressa, será considerada mera liberalidade, não configurando precedente ou novação contratual.
    
  A OBVIA  poderá, por meio de comunicação no e-mail indicado pelo Usuário em seu cadastro, ou por meio de aviso no software definir preços para oferecimento de determinados conteúdos e/ou serviços, ainda que inicialmente tenham sido ofertados de forma gratuita ou alteração dos preços, sendo a utilização dos serviços, após o referido aviso, considerada como concordância do Usuário com tais preços.
    
  Ao acessar os serviços, por qualquer forma ou meio, ou clicar "Sign In" ou "Registrar-se" ou "Logar" ou "Entrar" ou "Entre com sua conta", o Usuário concorda, consente e adere ao presente Termo de Uso e nossa Política de Privacidade que são portanto aderidos em caráter irrevogável e irretratável e obriga não somente as partes, mas também seus sucessores e cessionários, a qualquer título.
    
  DA LEI APLICÁVEL E POSSÍVEIS DEMANDAS JUDICIAIS
  Acreditamos que o bom senso prevalece e o diálogo é o principal meio de resolução de conflitos, estando sempre abertos a resolver qualquer tipo de litígio de forma administrativa, mas caso tenhamos algum problema judicial, fica eleito o foro da cidade de Contagem/MG para resolver qualquer demanda oriunda do presente Termo de Uso e/ou Política de Privacidade. O presente Termo de Uso e a Política de Privacidade e todos os aspectos da relação jurídica por eles instituídas serão regidos e interpretados pelas leis da República Federativa do Brasil.
    
  A OBVIA  se reserva o direito de assumir ou fiscalizar a defesa e o controle exclusivo de qualquer caso/procedimento (" Demanda") que eventualmente possa sujeitar a OBVIA  a indenização a que o Usuário tenha dado causa, não isentando-se de suas obrigações indenizatórias, concordando o Usuário que não realizará qualquer acordo no contexto de eventual Demanda, judicial ou não, sem o prévio consentimento da OBVIA .
   
  O Usuário, desde já, concorda em cooperar plenamente com a OBVIA , quando da solicitação da mesma, em qualquer Demanda, judicial ou não, em que a OBVIA  possa ser responsabilizada ou, de qualquer forma, prejudicado.
    
  DÚVIDAS OU SUGESTÕES?
  Caso você ainda tenha alguma dúvida ou não entendeu alguma coisa sobre nossos serviços, por favor nos contate através do e-mail contato@obviacontabil.com.
    
  Qualquer sugestão, ideia, informações ou materiais não especificamente solicitados pela OBVIA  também são bem-vindos. Não obstante, caso o Usuário envie sugestões, conteúdos ou ideias à OBVIA  o Usuário desde já concorda que a OBVIA  poderá usar referidas sugestões, conteúdos ou ideias para qualquer propósito que seja, incluindo seu desenvolvimento ou comercialização, não sendo devido em tais casos pagamento algum por parte da OBVIA  ao Usuário.
  `,
};
