import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import { Table, TableHeader, TableBody, TableItem, RenderHeaders } from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { urls } from "utils/constants";

const List = ({ paymentGateways, params, total, totalPages, page, perPage, orderBy, order }) => {
  const { t: translate } = useTranslation();
  const {
    LINKS: { paymentGatewaysDetails },
  } = urls;
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "name", title: translate("Name") },
    { slug: "active", title: `${translate("Active")}?` },
    { slug: "default", title: `${translate("Default")}?` },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {paymentGateways.map(({ name, active, isDefault, id, checked }) => (
            <tr key={id}>
              <TableItem>
                <Link data-cy="id" to={paymentGatewaysDetails(id)}>
                  {id}
                </Link>
              </TableItem>
              <TableItem>
                <Link data-cy="name" to={paymentGatewaysDetails(id)}>
                  {name}
                </Link>
              </TableItem>
              <TableItem>
                <Link data-cy="active" to={paymentGatewaysDetails(id)}>
                  <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                  {active ? "Sim" : "Não"}
                </Link>
              </TableItem>
              <TableItem>
                <Link data-cy="isDefault" to={paymentGatewaysDetails(id)}>
                  <StatusDot type={isDefault ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                  {isDefault ? "Sim" : "Não"}
                </Link>
              </TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  paymentGateways: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  paymentGateways: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
