import { put, takeLatest, cancelled } from "redux-saga/effects";
import axios from "axios";
import api from "services/api";
import { ProductsTypes, ProductsActions } from "store/ducks/products";
import {
  GET,
  PUT,
  DELETE,
  PRODUCT_BY_ID,
  PRODUCTS,
  PRODUCTS_LOCATIONS,
  PRODUCTS_AVAILABLE,
} from "utils/constants";

const { CancelToken } = axios;

function* fetchProductsDetails({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: PRODUCT_BY_ID(id),
      });
      yield put(ProductsActions.fetchProductsDetailsSuccess(data));
    }
  } catch (error) {
    yield put(ProductsActions.productsRequestFailure(error, type));
  }
}

function* fetchProductsLocations({ type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: PRODUCTS_LOCATIONS,
    });
    yield put(ProductsActions.fetchProductsLocationsSuccess(data));
  } catch (error) {
    yield put(ProductsActions.productsRequestFailure(error, type));
  }
}

function* fetchAvailableProducts({ params, type }) {
  const source = CancelToken.source();

  try {
    const { data } = yield api({
      method: GET,
      url: PRODUCTS_AVAILABLE,
      params,
      cancelToken: source.token,
    });
    yield put(ProductsActions.fetchAvailableProductsSuccess(data));
  } catch (error) {
    yield put(ProductsActions.productsRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

function* editProducts({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PUT,
        url: PRODUCT_BY_ID(id),
        data: payload,
      });
      yield put(ProductsActions.editProductsSuccess(data));
    }
  } catch (error) {
    yield put(ProductsActions.productsRequestFailure(error, type));
  }
}

function* deleteProducts({ payload, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: PRODUCTS,
      data: payload,
    });
    yield put(ProductsActions.deleteProductsSuccess(data));
  } catch (error) {
    yield put(ProductsActions.productsRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ProductsTypes.FETCH_PRODUCTS_DETAILS, fetchProductsDetails);
  yield takeLatest(ProductsTypes.FETCH_PRODUCTS_LOCATIONS, fetchProductsLocations);
  yield takeLatest(ProductsTypes.FETCH_AVAILABLE_PRODUCTS, fetchAvailableProducts);
  yield takeLatest(ProductsTypes.EDIT_PRODUCTS, editProducts);
  yield takeLatest(ProductsTypes.DELETE_PRODUCTS, deleteProducts);
}
