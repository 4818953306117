import * as Yup from "yup";

Yup.addMethod(Yup.string, "dateInFuture", function (minDate, errorMessage) {
  return this.test(`date-in-future`, errorMessage, function (value) {
    const { path, createError } = this;
    const dateParts = value.split("/");
    const dueDate = new Date(
      new Date(dateParts[2], dateParts[1] - 1, dateParts[0]).setHours(0, 0, 0, 0),
    );
    return dueDate >= minDate || createError({ path, message: errorMessage });
  });
});

export default Yup;
