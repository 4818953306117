/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { cpf } from "cpf-cnpj-validator";
import PropTypes from "prop-types";
import { PDFSURLS } from "utils/constants";
import { Flex, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import InputForm from "components/core/InputForm";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Subtitle, Span, Paragraph } from "components/core/Typography";
import { MASK_TYPES } from "utils/masks";
import Button from "components/core/Button";
import Checkbox from "components/core/Checkbox";
import { validationSchema } from "utils/schemas/initiallInfoForm";
import { sanitizeCPF } from "helpers";
import * as S from "./styled";
import { useDispatch } from "react-redux";
import { EmailActions } from "store/ducks/emails";




const InitialInfoForm = ({ onContinue, handleEmail, checkEmail, storage }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [cpfValidate, setCpfValidate] = useState("");
  const [cpfRejected, setCpfRejected] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    mode: "onBlur",
    submitFocusError: true,
    defaultValues: storage.companyRegister,
  });

  const handleCpf = ({ target: { value } }) => {
    setCpfValidate(sanitizeCPF(value));
  };

  useEffect(() => {
    if (cpfValidate) {
      const value = cpf.isValid(cpfValidate);
      if (value === false) {
        setCpfRejected(true);
      } else {
        setCpfRejected(false);
      }
    }
  }, [cpf, cpfValidate]);

  const errorCpf = (value) => {
    if (!value) return errors.cpf?.message;
    if (value) return <Trans i18nKey="required.cpfNotValid" />;
  };

  const errorEmail = (value) => {
    if (!value || errors.email?.message) return errors.email?.message;
    if (value) return <Trans i18nKey="required.emailAlreadyRegistered" />;
  };

  useEffect(() => {
    if (checkEmail === "rejected") {
      setIsRejected(true);
    } else {
      setIsRejected(false);
    }
  }, [checkEmail, setIsRejected]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const handleNextStep = (data) => {
    const result = {
      ...data,
      password: data.password.trim(),
      confirmPassword: data.confirmPassword.trim(),
      email: data.email.trim(),
    };

    if (result.password && result.confirmPassword) {
      const event = "EtapaDados";      
      dispatch(EmailActions.sendEmail(result));
      onContinue(result, event);
    }
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="16%" />
        </Container>
        <Form onSubmit={handleSubmit(handleNextStep)}>
          <Flex horizontalAlign="center">
            <Subtitle>Vamos começar?</Subtitle>
            <Span mb="24px">Insira seus dados</Span>
            <InputForm
              register={register}
              type="text"
              name="name"
              label="Seu nome"
              errorMsg={errors.name?.message}
            />
            <InputForm
              register={register}
              type="text"
              name="phoneNumber"
              label="Telefone"
              mask={MASK_TYPES.phone}
              errorMsg={errors.phoneNumber?.message}
            />
            <InputForm
              register={register}
              type="text"
              name="cpf"
              label="CPF"
              mask={MASK_TYPES.cpf}
              onBlur={handleCpf}
              errorMsg={errorCpf(cpfRejected)}
            />
            <InputForm
              register={register}
              type="email"
              name="email"
              label="Seu e-mail"
              onBlur={handleEmail}
              onChange={() => checked && setChecked(false)}
              errorMsg={errorEmail(isRejected)}
            />
            <InputForm
              register={register}
              name="password"
              type={passwordShown ? "text" : "password"}
              label="Crie uma senha"
              errorMsg={errors.password?.message}
            >
              <S.IconEyes color={passwordShown} onClick={togglePasswordVisiblity} />
            </InputForm>
            <InputForm
              register={register}
              name="confirmPassword"
              type={confirmPasswordShown ? "text" : "password"}
              label="Confirmar Senha"
              autoComplete="new-password"
              errorMsg={errors.confirmPassword?.message}
            >
              <S.IconEyes color={confirmPasswordShown} onClick={toggleConfirmPasswordVisiblity} />
            </InputForm>
            <Flex direction="row" marginLastChild="0px" marginBottom="0px">
              <Checkbox
                name="terms"
                label="Li e concordo com os"
                checked={checked}
                marginBottom="0px"
                handleChange={() => setChecked(!checked)}
              />
              <a href={PDFSURLS.useTerms} target="_blank" rel="noreferrer">
                <Paragraph
                  marginBottom="2.4rem"
                  marginLeft="0.4rem"
                  fontSize="var(--font-size-default)"
                  color="var(--color-secondary)"
                  fontWeight="bold"
                  customStyle={{ "text-decoration": "underline" }}
                >
                  Termos e condições
                </Paragraph>
              </a>
            </Flex>
            <Flex direction="row">
              <Paragraph
                marginLeft="2.6rem"
                fontSize="var(--font-size-default)"
                color="var(--color-secondary)"
              >
                e com o
              </Paragraph>
              <a href={PDFSURLS.consentTerms} target="_blank" rel="noreferrer">
                <Paragraph
                  marginLeft="0.4rem"
                  fontSize="var(--font-size-default)"
                  color="var(--color-secondary)"
                  fontWeight="bold"
                  customStyle={{ "text-decoration": "underline" }}
                >
                  Termo de Consentimento
                </Paragraph>
              </a>
            </Flex>
            <Button type="submit" disabled={!checked || isRejected || cpfRejected}>
              Continuar
            </Button>
          </Flex>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

InitialInfoForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  handleEmail: PropTypes.func.isRequired,
  checkEmail: PropTypes.string.isRequired,
  storage: PropTypes.object.isRequired,
};

export default InitialInfoForm;
