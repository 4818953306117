import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Creators as RulesActions } from "store/ducks/roles";
import { LayoutActions } from "store/ducks/layout";
import { EmitterActions } from "store/ducks/emitter";
import ShieldAction from "components/core/ShieldAction";
import Portal from "components/core/Portal";
import { Span } from "components/core/Typography";
import EmitterSidebar from "components/core/EmitterSidebar";
import useEmitter from "hooks/useEmitter";
import { LINKS, urls } from "utils/constants";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/logout.svg";
import { Creators as AuthActions } from "store/ducks/auth";
import { People as PeopleIcon, Manage as ManageIcon2, Clipboard } from "./icons";
import * as S from "./styled";

export const Menu = ({
  menu: { show, path, Icon, roles },
  menuKey,
  countNotifications,
  totalChatsUnread,
  changeLayout,
}) => {
  const { t: translate } = useTranslation();
  const counters = {
    notifications: countNotifications,
    customerService: totalChatsUnread,
  };

  if (!show || !path) return null;

  return (
    <ShieldAction roles={roles}>
      <S.MenuItem
        changeLayout={changeLayout}
        to={path}
        key={path}
        count={counters[menuKey] ?? 0}
        activeClassName="active"
      >
        <b />
        <b />
        {Icon && <Icon />}
        {translate("i18nKey")}
      </S.MenuItem>
    </ShieldAction>
  );
};

const SideBarClient = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const pinEmitter = useEmitter();
  const { t: translate } = useTranslation();
  const {
    user: { enterprise, name: userName, roles: rolesPermission },
  } = useSelector((state) => state.auth.claims);
  const { roles } = useSelector((state) => state.roles);
  const { emitterToPinDetails } = useSelector(({ emitter }) => emitter);
  const { isEmitterMenuOpen } = useSelector(({ layout }) => layout);

  useEffect(() => {
    if (!roles) {
      dispatch(RulesActions.getRolesStart());
    }
  }, [dispatch, roles]);

  const logout = () => dispatch(AuthActions.authLogout());

  useEffect(() => {
    dispatch(LayoutActions.toggleEmitterMenu(false));
  }, [location, pinEmitter, dispatch]);

  useEffect(() => {
    if (pinEmitter?.id) {
      dispatch(EmitterActions.fetchEmittersToPinById(pinEmitter.id));
    }
  }, [dispatch, pinEmitter]);

  return (
    <Portal>
      <S.Navigation>
        <S.LogoLink to={urls.ROUTES.app.path}>
          <Logo />
        </S.LogoLink>

        <S.ProfileName>
          <Span customStyle={{ "text-align": "center", "letter-spacing": "-0.01em" }}>
            {translate("hi")}, <strong>{userName}</strong>
          </Span>
          <S.Box>
            <button type="button" onClick={logout}>
              <LogoutIcon />
              <span>{translate("exit")}</span>
            </button>
          </S.Box>
        </S.ProfileName>
        <S.ItemsWrapper>
          <S.MenuItem
            withArrow
            as="button"
            onClick={() => history.push(LINKS.emitterDetails(enterprise.id))}
          >
            <PeopleIcon />
            <span>Detalhes</span>
          </S.MenuItem>
          {!!pinEmitter && (
            <S.MenuItem
              withArrow
              isOpen={isEmitterMenuOpen}
              as="button"
              onClick={() => dispatch(LayoutActions.toggleEmitterMenu(!isEmitterMenuOpen))}
            >
              <Clipboard />
              <span>{pinEmitter?.enterpriseName}</span>
              <S.Arrow />
            </S.MenuItem>
          )}
        </S.ItemsWrapper>
      </S.Navigation>
      {pinEmitter && <EmitterSidebar isOpen={isEmitterMenuOpen} emitter={emitterToPinDetails} />}
    </Portal>
  );
};

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  menuKey: PropTypes.string.isRequired,
  countNotifications: PropTypes.number,
  totalChatsUnread: PropTypes.number,
  changeLayout: PropTypes.bool,
};

Menu.defaultProps = {
  countNotifications: 0,
  totalChatsUnread: 0,
  changeLayout: false,
};

export default SideBarClient;
