import React, { useState } from "react";
import PropTypes from "prop-types";
import { RenderHeaders, Table, TableHeader, TableBody, TableItem } from "components/core/Table";
import * as S from "./styled";
import Modal from "components/core/Modal";
import { Columns, Container, RowFlex, ColumnFlex } from "components/core/Grid";
import CardInfo from "components/core/CardInfo";
import { formatDate } from "helpers";
import Button from "components/core/Button";
import { Chrevon } from "./icons";
import { Span } from "components/core/Typography";
import { Spinner } from "components/core/WithSpinner/styled";

const ChargeHistory = ({ items, translate, isLoading }) => {
  const [showHistory, setShowHistory] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [showChargeDetails, setShowChargeDetails] = useState(false);
  const itemPadding = "0.75rem 1rem";

  const headersCharges = [
    { slug: "id", title: "ID" },
    { slug: "createdAt", title: `${translate("Date")}` },
    { slug: "userName", title: `${translate("User")}` },
    { slug: "lr", title: `${translate("LR")}` },
    { slug: "status", title: `${translate("Status")}` },
    { slug: "actions", title: `${translate("Actions")}` },
  ];

  const toggleShowHistory = () => setShowHistory(!showHistory);

  const closeModalChargeDetails = () => {
    setSelectedCharge(null);
    setShowChargeDetails(false);
  };
  const handleViewDetailsChargeHistory = (chargeId) => {
    const charge = items.find((item) => item.id === chargeId);
    setSelectedCharge(charge);
    setShowChargeDetails(true);
  };

  return (
    <>
      <S.ChargeHistoryContainer>
        <Button
          variant={"text"}
          handleClick={toggleShowHistory}
          title={showHistory ? "Ocultar histórico" : "Exibir histórico"}
        >
          Histórico de Cobranças <Chrevon />
        </Button>
        {showHistory && (
          <Container
            horizontalAlign="center"
            horizontalPadding={0}
            verticalPadding={0}
            marginBottom={"1rem"}
          >
            <Table>
              <TableHeader>
                <RenderHeaders headers={headersCharges} />
              </TableHeader>
              <TableBody>
                {isLoading && (
                  <tr key={"idLoading"}>
                    <TableItem padding={itemPadding} colSpan="5">
                      <ColumnFlex horizontalAlign={"center"} width="100%">
                        <Spinner width={"4rem"} height={"4rem"} />
                        <Span style={{ marginTop: "0.5rem" }}>Aguarde... Carregando items</Span>
                      </ColumnFlex>
                    </TableItem>
                  </tr>
                )}
                {items?.map(
                  ({ id, createdAt, user: { name: userName }, status, chargeResponse: { lr } }) => (
                    <tr key={`hc${id}`}>
                      <TableItem padding={itemPadding}>{id}</TableItem>
                      <TableItem padding={itemPadding}>
                        {formatDate(createdAt, 0, false, true)}
                      </TableItem>
                      <TableItem padding={itemPadding}>{userName}</TableItem>
                      <TableItem padding={itemPadding}>{lr}</TableItem>
                      <TableItem padding={itemPadding}>{status}</TableItem>
                      <TableItem padding={itemPadding}>
                        <RowFlex>
                          <S.IconView
                            style={{ cursor: "pointer" }}
                            onClick={() => handleViewDetailsChargeHistory(id)}
                            title="Ver detalhes"
                          />
                        </RowFlex>
                      </TableItem>
                    </tr>
                  ),
                )}
              </TableBody>
            </Table>
          </Container>
        )}
      </S.ChargeHistoryContainer>

      {selectedCharge && (
        <Modal
          title={`Detalhes da cobrança #${selectedCharge?.id}`}
          onCloseModal={closeModalChargeDetails}
          showModal={showChargeDetails}
          hideConfirm={true}
          hideCancel={false}
          cancelLabel="Fechar"
          width="80rem"
          textAlign="text-left"
        >
          <Columns columns="3">
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Charged by")}
              info={selectedCharge?.user?.name}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Charged at")}
              info={formatDate(selectedCharge?.createdAt, 0, false, true)}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Installments")}
              info={`${selectedCharge?.chargeResponse?.installments}x`}
            />
          </Columns>
          <Columns columns="3">
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Brand")}
              info={selectedCharge?.chargeResponse?.brand}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Final card digits")}
              info={selectedCharge?.chargeResponse?.last4}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Bank")}
              info={selectedCharge?.chargeResponse?.issuer}
            />
          </Columns>
          <Columns>
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={"LR"}
              info={`${selectedCharge?.chargeResponse?.lr} - ${selectedCharge?.chargeResponse?.lrAttempt?.description}`}
            />
          </Columns>
          {!selectedCharge?.chargeResponse?.success && (
            <>
              {selectedCharge?.chargeResponse?.lrAttempt?.action && (
                <Columns>
                  <CardInfo
                    marginLeft={0}
                    marginRigth={0}
                    label={translate("Possible actions")}
                    info={selectedCharge?.chargeResponse?.lrAttempt?.action}
                  />
                </Columns>
              )}
              {selectedCharge?.chargeResponse?.lrAttempt?.irreversibleForBrands && (
                <Columns>
                  <CardInfo
                    marginLeft={0}
                    marginRigth={0}
                    label={translate("Irreversible status for brands")}
                    info={selectedCharge?.chargeResponse?.lrAttempt?.irreversibleForBrands}
                  />
                </Columns>
              )}
            </>
          )}
        </Modal>
      )}
    </>
  );
};

ChargeHistory.propTypes = {
  items: PropTypes.array,
};

ChargeHistory.defaultProps = {
  items: [],
};

export default ChargeHistory;
