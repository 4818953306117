import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: CouponsTypes, Creators: CouponsActions } = createActions({
  getCoupons: ["params"],
  getCouponsSuccess: ["payload"],
  getCoupon: ["params"],
  getCouponSuccess: ["payload"],
  getCouponsById: ["id"],
  getCouponsByIdSuccess: ["payload"],
  createCoupons: ["payload"],
  createCouponsSuccess: ["payload"],
  editCoupons: ["id", "payload"],
  editCouponsSuccess: ["payload"],
  deleteCoupons: ["payload"],
  deleteCouponsSuccess: ["payload"],
  disableCoupons: ["payload"],
  disableCouponsSuccess: ["payload"],
  getAllStatus: null,
  getAllStatusSuccess: ["payload"],
  getSubscriptions: null,
  getSubscriptionsSuccess: ["payload"],
  cleanState: null,
  couponsRequestFailure: ["error", "requestName"],
  removeCoupon: null,
});

const INITIAL_STATE = {
  couponsDetails: null,
  coupons: {},
  created: false,
  subscriptions: [],
  coupon: {},
  CouponsRequests: {
    getCoupons: REQUEST_NOT_STARTED,
    getCoupon: REQUEST_NOT_STARTED,
    getCouponsById: REQUEST_NOT_STARTED,
    createCoupons: REQUEST_NOT_STARTED,
    DELETE_COUPONS: REQUEST_NOT_STARTED,
    DISABLE_COUPONS: REQUEST_NOT_STARTED,
    getAllStatus: REQUEST_NOT_STARTED,
    getSubscriptions: REQUEST_NOT_STARTED,
  },
};

const getCoupons = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.GET_COUPONS]: REQUEST_PENDING,
  },
});

const getCouponsSuccess = (state, { payload }) => ({
  ...state,
  coupons: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.GET_COUPONS]: REQUEST_RESOLVED,
  },
});

const getCoupon = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    getCoupon: REQUEST_PENDING,
  },
});

const getCouponSuccess = (state, { payload }) => ({
  ...state,
  coupon: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    getCoupon: REQUEST_RESOLVED,
  },
});

const getCouponsById = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    getCouponsById: REQUEST_PENDING,
  },
});

const getCouponsByIdSuccess = (state, { payload }) => ({
  ...state,
  couponsDetails: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    getCouponsById: REQUEST_RESOLVED,
  },
});

const createCoupons = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.CREATE_COUPONS]: REQUEST_PENDING,
  },
});

const createCouponsSuccess = (state) => ({
  ...state,
  created: true,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.CREATE_COUPONS]: REQUEST_RESOLVED,
  },
});

const editCoupons = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.EDIT_COUPONS]: REQUEST_PENDING,
  },
});

const editCouponsSuccess = (state, { payload = {} }) => ({
  ...state,
  couponsDetails: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.EDIT_COUPONS]: REQUEST_RESOLVED,
  },
});

const deleteCoupons = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.DELETE_COUPONS]: REQUEST_PENDING,
  },
});

const deleteCouponsSuccess = (state, { payload = {} }) => ({
  ...state,
  couponsDetails: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.DELETE_COUPONS]: REQUEST_RESOLVED,
  },
});

const disableCoupons = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.DISABLE_COUPONS]: REQUEST_PENDING,
  },
});

const disableCouponsSuccess = (state, { payload = {} }) => ({
  ...state,
  couponsDetails: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    [CouponsTypes.DISABLE_COUPONS]: REQUEST_RESOLVED,
  },
});

const getAllStatus = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    getAllStatus: REQUEST_PENDING,
  },
});

const getAllStatusSuccess = (state, { payload }) => ({
  ...state,
  status: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    getAllStatus: REQUEST_RESOLVED,
  },
});

const getSubscriptions = (state) => ({
  ...state,
  CouponsRequests: {
    ...state.CouponsRequests,
    getSubscriptions: REQUEST_PENDING,
  },
});

const getSubscriptionsSuccess = (state, { payload }) => ({
  ...state,
  subscriptions: payload,
  CouponsRequests: {
    ...state.CouponsRequests,
    getSubscriptions: REQUEST_RESOLVED,
  },
});

const couponsRequestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  CouponsRequests: {
    ...state.CouponsRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

const removeCoupon = () => ({
  coupon: {},
  CouponsRequests: {
    getCoupon: REQUEST_NOT_STARTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [CouponsTypes.GET_COUPONS]: getCoupons,
  [CouponsTypes.GET_COUPONS_SUCCESS]: getCouponsSuccess,
  [CouponsTypes.GET_COUPON]: getCoupon,
  [CouponsTypes.GET_COUPON_SUCCESS]: getCouponSuccess,
  [CouponsTypes.GET_COUPONS_BY_ID]: getCouponsById,
  [CouponsTypes.GET_COUPONS_BY_ID_SUCCESS]: getCouponsByIdSuccess,
  [CouponsTypes.CREATE_COUPONS]: createCoupons,
  [CouponsTypes.CREATE_COUPONS_SUCCESS]: createCouponsSuccess,
  [CouponsTypes.EDIT_COUPONS]: editCoupons,
  [CouponsTypes.EDIT_COUPONS_SUCCESS]: editCouponsSuccess,
  [CouponsTypes.DELETE_COUPONS]: deleteCoupons,
  [CouponsTypes.DELETE_COUPONS_SUCCESS]: deleteCouponsSuccess,
  [CouponsTypes.DISABLE_COUPONS]: disableCoupons,
  [CouponsTypes.DISABLE_COUPONS_SUCCESS]: disableCouponsSuccess,
  [CouponsTypes.GET_ALL_STATUS]: getAllStatus,
  [CouponsTypes.GET_ALL_STATUS_SUCCESS]: getAllStatusSuccess,
  [CouponsTypes.GET_SUBSCRIPTIONS]: getSubscriptions,
  [CouponsTypes.GET_SUBSCRIPTIONS_SUCCESS]: getSubscriptionsSuccess,
  [CouponsTypes.COUPONS_REQUEST_FAILURE]: couponsRequestFailure,
  [CouponsTypes.CLEAN_STATE]: cleanState,
  [CouponsTypes.REMOVE_COUPON]: removeCoupon,
});
