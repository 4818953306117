import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { POST, PUT } from "utils/constants/verbs";
import { ParamsEmitterTypes, ParamsEmitterActions } from "store/ducks/paramsEmitter";

export function* fetchAllParams({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/params/emitter-params/get-params",
      data: payload,
    });

    yield put(ParamsEmitterActions.fetchParamsSuccess(data));
  } catch (error) {
    yield put(ParamsEmitterActions.requestFailure(error, type));
  }
}

export function* editStatusActive({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/params/emitter-params/update-active",
      data: payload,
    });

    yield put(ParamsEmitterActions.editStatusActiveSuccess(data));
  } catch (error) {
    yield put(ParamsEmitterActions.requestFailure(error, type));
  }
}

export function* registerParamEmitter({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/params/emitter-params/register",
      data: payload,
    });

    yield put(ParamsEmitterActions.registerParamEmitterSuccess(data));
  } catch (error) {
    yield put(ParamsEmitterActions.requestFailure(error, type));
  }
}

export function* editParams({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/params/emitter-params/update-params",
      data: payload,
    });

    yield put(ParamsEmitterActions.editParamsSuccess(data));
  } catch (error) {
    yield put(ParamsEmitterActions.requestFailure(error, type));
  }
}

export function* deleteParam({ payload, type }) {
  try {
    const { data } = yield api({
      method: PUT,
      url: "/params/emitter-params/delete-params",
      data: payload,
    });

    yield put(ParamsEmitterActions.deleteParamSuccess(data));
  } catch (error) {
    yield put(ParamsEmitterActions.requestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ParamsEmitterTypes.FETCH_PARAMS, fetchAllParams);
  yield takeLatest(ParamsEmitterTypes.EDIT_STATUS_ACTIVE, editStatusActive);
  yield takeLatest(ParamsEmitterTypes.REGISTER_PARAM_EMITTER, registerParamEmitter);
  yield takeLatest(ParamsEmitterTypes.EDIT_PARAMS, editParams);
  yield takeLatest(ParamsEmitterTypes.DELETE_PARAM, deleteParam);
}
