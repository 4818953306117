import React, { useEffect, useRef, useState } from "react";
import { cpf } from "cpf-cnpj-validator";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { UsersActions } from "store/ducks/users";
import { LayoutActions } from "store/ducks/layout";
import ProfileEdit from "components/presentation/Profile/Edit";
import { profileSchema } from "utils/schemas/profile";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import Modal from "components/core/Modal";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import useClaims from "hooks/useClaims";
import useRequest from "hooks/useRequest";
import { sanitizeCPF } from "helpers";
import { urls, ROUTES } from "utils/constants";

const ProfileEditWithSpinner = WithSpinner(ProfileEdit);

const ProfileEditContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();
  const submitRef = useRef(null);
  const {
    user: { id },
  } = useClaims();
  const [cpfValidate, setCpfValidate] = useState("");
  const [cpfRejected, setCpfRejected] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [valuesToUpdate, setValuesToUpdate] = useState();
  const { userDetails, UsersRequests } = useSelector(({ users }) => users);

  useEffect(() => {
    if (userDetails?.id !== Number(id)) {
      dispatch(UsersActions.getUserById(id));
    }
  }, [id, dispatch, userDetails?.id]);

  const handleSubmit = (values) => {
    setShowConfirm(true);
    setValuesToUpdate(values);
  };

  const dispatchUpdates = () => {
    const { idRole } = userDetails;
    const { oldPassword, password, confirmPassword, ...user } = valuesToUpdate;
    const userToDispatch = !oldPassword ? user : valuesToUpdate;

    dispatch(UsersActions.editUser(id, { ...userToDispatch, idRole }));
    setShowConfirm(false);
  };

  const handleCpf = ({ target: { value } }) => {
    setCpfValidate(sanitizeCPF(value));
  };

  useEffect(() => {
    if (cpfValidate) {
      const value = cpf.isValid(cpfValidate);
      if (value === false) {
        setCpfRejected(true);
      } else {
        setCpfRejected(false);
      }
    }
  }, [cpf, cpfValidate]);

  const dispatchConfirmation = (text, type, title) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
        title,
      }),
    );

  useRequest({
    request: UsersRequests.editUser,
    onResolved: () => {
      dispatchConfirmation(<Trans i18nKey="profileEdited" />, "success");
      history.push(ROUTES.profile.path);
      dispatch(UsersActions.cleanState());
    },
  });

  return (
    <>
      <NavBar title={translate("editMyProfile")}>
        <Button url={urls.LINKS.profile} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()} disabled={cpfRejected}>
          {translate("save")}
        </Button>
      </NavBar>
      <ProfileEditWithSpinner
        requestStatus={UsersRequests.getUserById}
        id={+id}
        handleEditProfile={handleSubmit}
        defaultValues={userDetails}
        validationSchema={profileSchema}
        submitRef={submitRef}
        cpfRejected={cpfRejected}
        handleCpf={handleCpf}
      />
      <Modal
        showModal={showConfirm}
        onCloseModal={() => setShowConfirm(false)}
        onConfirmModal={dispatchUpdates}
        title={translate("confirmChanges")}
        width="49rem"
      >
        <Span>
          <Trans i18nKey="confirmProfileChanges" />
        </Span>
      </Modal>
    </>
  );
};

export default ProfileEditContainer;
