import { put, takeLatest } from "redux-saga/effects";
import { AddressTypes, AddressActions } from "store/ducks/address";
import api from "services/api";
import { GET } from "utils/constants/verbs";
import { ADDRESS_BY_CEP } from "utils/constants/endpoints";

export function* getAddressByCEP({ cep }) {
  try {
    const { data } = yield api({
      method: GET,
      url: ADDRESS_BY_CEP(cep),
    });
    yield put(AddressActions.getAddressByCEPSuccess(data));
  } catch (error) {
    yield put(AddressActions.getAddressByCEPFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(AddressTypes.GET_ADDRESS_BY_CEP, getAddressByCEP);
}
