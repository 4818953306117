import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, InputHidden, InputGroup } from "components/core/Form";
import InputForm from "components/core/InputForm";
import { MASK_TYPES } from "utils/masks";
import { Container } from "components/core/Grid";
import SelectForm from "components/core/SelectForm";
import { Subtitle } from "components/core/Typography";
import { mapToSelectOptions } from "helpers";

const MunicipalServicesEdit = ({
  onSubmit,
  submitRef,
  defaultValues,
  servicesLocations,
  emitterDetailsSmu,
  emitterDetailsType,
  emitterDetails,
}) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    defaultValues,
    mode: "onBlur",
  });

  // console.log(emitterDetailsType);
  return (
    <Container maxWidth="md">
      <Subtitle>{translate("editSMU")}</Subtitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {emitterDetailsSmu && emitterDetailsType !== 3 && (
          <SelectForm
            register={register}
            name="idServiceLocation"
            label={translate("local")}
            options={mapToSelectOptions(servicesLocations, "id", "name")}
            errorMsg={errors.idServiceLocation?.message}
          />
        )}
        {emitterDetailsSmu && emitterDetailsType === 3 && (
          <>
            <InputGroup paddingLeft="0" columns="3">
              <InputForm
                register={register}
                type="text"
                name="pisAliquot"
                label="PIS"
                errorMsg={errors.pisAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
              <InputForm
                register={register}
                type="text"
                name="cofinsAliquot"
                label="COFINS"
                errorMsg={errors.cofinsAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
              <InputForm
                register={register}
                type="text"
                name="csllAliquot"
                label="CSLL"
                errorMsg={errors.csllAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
            </InputGroup>
            <InputGroup paddingLeft="0" columns="3">
              <InputForm
                register={register}
                type="text"
                name="irAliquot"
                label="IRRJ"
                errorMsg={errors.irAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
              <InputForm
                register={register}
                type="text"
                name="inssAliquot"
                label="INSS"
                errorMsg={errors.inssAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
              <SelectForm
                register={register}
                name="idServiceLocation"
                options={mapToSelectOptions(servicesLocations, "id", "name")}
                label={translate("serviceLocation")}
                errorMsg={errors.idServiceLocation?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="issAliquot"
                label="ISS"
                mask={MASK_TYPES.numberWithComma}
                content="%"
                errorMsg={errors.issAliquot?.message}
              />
            </InputGroup>
          </>
        )}
        {!emitterDetailsSmu && emitterDetailsType !== 3 && (
          <>
            <InputForm
              register={register}
              type="text"
              name="name"
              label={translate("municipalServiceName")}
            />
            {emitterDetails.cnae && (
              <InputForm register={register} type="text" name="cnae" label="CNAE" />
            )}
            {emitterDetailsType === 2 && (
              <SelectForm
                register={register}
                name="idServiceLocation"
                label={translate("local")}
                options={mapToSelectOptions(servicesLocations, "id", "name")}
                errorMsg={errors.idServiceLocation?.message}
              />
            )}

            {emitterDetails.municipalServiceCode && (
              <InputForm
                register={register}
                type="text"
                name="municipalServiceCode"
                label={translate("municipalServiceCode")}
                errorMsg={errors.municipalServiceCode?.message}
              />
            )}
            {emitterDetails.serviceListItem && (
              <InputForm
                register={register}
                type="text"
                name="serviceListItem"
                label={translate("serviceListItem")}
                errorMsg={errors.serviceListItem?.message}
              />
            )}
            {emitterDetails.serviceDescription && (
              <InputForm
                register={register}
                type="text"
                name="descriptionServiceCode"
                label={translate("description")}
                errorMsg={errors.idServiceLocation?.message}
              />
            )}
          </>
        )}
        {!emitterDetailsSmu && emitterDetailsType === 3 && (
          <>
            <InputForm
              register={register}
              type="text"
              name="name"
              label={translate("municipalServiceName")}
            />
            {emitterDetails.cnae && (
              <InputForm register={register} type="text" name="cnae" label="CNAE" />
            )}
            {emitterDetails.serviceDescription && (
              <InputForm
                register={register}
                type="text"
                name="descriptionServiceCode"
                label={translate("description")}
                errorMsg={errors.idServiceLocation?.message}
              />
            )}
            <InputGroup paddingLeft="0" columns="3">
              <InputForm
                register={register}
                type="text"
                name="pisAliquot"
                label="PIS"
                errorMsg={errors.pisAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
              <InputForm
                register={register}
                type="text"
                name="cofinsAliquot"
                label="COFINS"
                errorMsg={errors.cofinsAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
              <InputForm
                register={register}
                type="text"
                name="csllAliquot"
                label="CSLL"
                errorMsg={errors.csllAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
            </InputGroup>
            <InputGroup paddingLeft="0" columns="3">
              <InputForm
                register={register}
                type="text"
                name="irAliquot"
                label="IRRJ"
                errorMsg={errors.irAliquot?.message}
                mask={MASK_TYPES.numberWithComma}
                content="%"
              />
            </InputGroup>
          </>
        )}
        <InputGroup paddingLeft="0" columns="3">
          <InputForm
            register={register}
            type="text"
            name="inssAliquot"
            label="INSS"
            errorMsg={errors.inssAliquot?.message}
            mask={MASK_TYPES.numberWithComma}
            content="%"
          />
          <InputForm
            register={register}
            type="text"
            name="municipalServiceCode"
            label={translate("municipalServiceCode")}
            errorMsg={errors.municipalServiceCode?.message}
          />
        </InputGroup>
        <InputHidden ref={submitRef} type="submit" value="Submit" />
      </Form>
    </Container>
  );
};

MunicipalServicesEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  servicesLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  emitterDetailsSmu: PropTypes.bool.isRequired,
  emitterDetails: PropTypes.object.isRequired,
  emitterDetailsType: PropTypes.number.isRequired,
};

MunicipalServicesEdit.defaultProps = {
  servicesLocations: [],
};

export default MunicipalServicesEdit;
