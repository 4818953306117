import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Paragraph } from "components/core/Typography";
import Button from "components/core/Button";
import { ReactComponent as ErrorScreen } from "assets/images/error.svg";
import * as S from "./styled";

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  render() {
    const { hasError } = this.state;
    const { children, setShowModal } = this.props;
    const { t: translate } = this.props;

    if (hasError) {
      return (
        <S.ErrorWrapper>
          <ErrorScreen />
          <Paragraph>{translate("errorBoundary")}</Paragraph>
          {setShowModal && (
            <Button handleClick={() => setShowModal(false)}>{translate("close")}</Button>
          )}
        </S.ErrorWrapper>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  setShowModal: PropTypes.func,
  t: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  setShowModal: null,
};

export default withTranslation()(ErrorBoundary);
