import React from "react";
import styled from "styled-components";

const PreviewContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  max-height: 300px;
  overflow: auto;
`;

const HtmlPreview = ({ htmlCode }) => {
  return <PreviewContainer dangerouslySetInnerHTML={{ __html: htmlCode }} />;
};

export default HtmlPreview;
