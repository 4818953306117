import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import Pagination from "components/core/Pagination";
import {
  Table,
  TableBody,
  TableCheckbox,
  TableHeader,
  TableHeaderItem,
  TableItem,
  RenderHeaders,
} from "components/core/Table";

const CfopTableList = ({
  items,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  orderBy,
  order,
  toggleAll,
  checkedAll,
}) => {
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "cfop.code", title: translate("code") },
    { slug: "cfop.name", title: translate("cfopDescription") },
    { slug: "createdAt", title: translate("registrationDate") },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(({ cfop, createdAt, id, checked }) => (
            <tr key={id}>
              <TableItem textAlign="center">
                <TableCheckbox
                  name={`toggle_${id}`}
                  value={id}
                  handleChange={() => handleChange(id)}
                  checked={checked}
                />
              </TableItem>
              <TableItem>{cfop.code}</TableItem>
              <TableItem>{cfop.name}</TableItem>
              <TableItem>{createdAt}</TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params?.setPage}
      />
    </>
  );
};

CfopTableList.propTypes = {
  items: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
};

CfopTableList.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 0,
};

export default WithEmptyFeedback(CfopTableList);
