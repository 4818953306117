import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputForm from "components/core/InputForm";
import { InputGroup } from "components/core/Form";
import { Subtitle } from "components/core/Typography";

const UserAndPassword = ({ register, errors }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <InputForm
        register={register({
          required: { value: true, message: translate("required.username") },
        })}
        type="text"
        name="usernameEnotas"
        label={translate("username")}
        errorMsg={errors?.usernameEnotas?.message}
      />
      <InputForm
        register={register({
          required: { value: true, message: translate("required.password") },
        })}
        type="text"
        name="passwordEnotas"
        label={translate("password")}
        errorMsg={errors?.passwordEnotas?.message}
      />
    </>
  );
};

const Token = ({ register, errors }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <InputForm
        register={register({
          required: { value: true, message: translate("required.token") },
        })}
        type="text"
        name="tokenEnotas"
        label={translate("token")}
        errorMsg={errors?.tokenEnotas?.message}
      />
    </>
  );
};

const PassphraseAndPassword = ({ register, errors }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <InputForm
        register={register({
          required: { value: true, message: translate("required.secretPhrase") },
        })}
        type="text"
        name="secretPhraseEnotas"
        label={translate("secretPhrase")}
        errorMsg={errors?.secretPhraseEnotas?.message}
      />
      <InputForm
        register={register({
          required: { value: true, message: translate("required.password") },
        })}
        type="text"
        name="passwordEnotas"
        label={translate("password")}
        errorMsg={errors?.passwordEnotas?.message}
      />
    </>
  );
};

const UserAndPasswordAndToken = ({ register, errors }) => (
  <>
    <UserAndPassword register={register} errors={errors} />
    <Token register={register} errors={errors} />
  </>
);

const authTypes = Object.freeze({
  "user-and-password-required": UserAndPassword,
  "token-required": Token,
  "passphrase-and-password-required": PassphraseAndPassword,
  "user-and-password-and-token-required": UserAndPasswordAndToken,
  "certificate-required": null,
});

const AuthFormFactory = ({ authType, register, errors }) => {
  const { t: translate } = useTranslation();
  const AuthForm = authTypes[authType];

  if (!AuthForm) return null;

  return (
    <>
      <Subtitle>{translate("information")}</Subtitle>
      <InputGroup>
        <AuthForm register={register} errors={errors} />
      </InputGroup>
    </>
  );
};

UserAndPassword.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

Token.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

PassphraseAndPassword.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

UserAndPasswordAndToken.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

AuthFormFactory.propTypes = {
  authType: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default AuthFormFactory;
