import React from "react";

import { ReactComponent as Logo } from "assets/svg/logo-colored.svg";

import * as S from "./styled";

const Home = () => (
  <S.LogoWrapper>
    <Logo />
  </S.LogoWrapper>
);

export default Home;
