import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableCheckbox,
  TableBody,
  TableItem,
  RenderHeaders,
  TableIcons,
} from "components/core/TableWithIcons";
import Pagination from "components/core/Pagination";

const TextByKey = ({ id, keyString, items }) => {
  if (keyString === "id") return id;

  const keys = keyString.split(".");
  let result = items[keys[0]];

  if (keys.length > 1) {
    keys.forEach((currentKey, index) => {
      if (index >= 1) {
        result = result[currentKey];
      }
    });
  }

  return result;
};

const TableFactory = ({
  headers,
  items,
  allowedKeys,
  urlDetails,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  toggleAll,
  everyoneIsChecked,
  orderBy,
  order,
  setPage,
  setOrderBy,
  handleClickDelete,
  handleClickEdit,
}) => {
  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="4%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={everyoneIsChecked} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(({ id, checked, props, ...values }, index) => (
            <tr key={id} {...props}>
              <TableItem textAlign="center">
                <TableCheckbox
                  name={`toggle_${id}`}
                  value={id}
                  handleChange={() => handleChange(id)}
                  checked={checked}
                />
              </TableItem>
              {allowedKeys.map((key) => (
                <TableItem key={key}>
                  <Link to={urlDetails(id)}>
                    <TextByKey id={id} keyString={key} items={values} />
                  </Link>
                </TableItem>
              ))}
              <TableIcons
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit(id)}
                item={items[index]}
              />
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={setPage}
      />
    </>
  );
};

TableFactory.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      i18nKey: PropTypes.string,
    }),
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      checked: PropTypes.bool,
      props: PropTypes.object,
    }),
  ),
  allowedKeys: PropTypes.array,
  urlDetails: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  everyoneIsChecked: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  handleClickEdit: PropTypes.func.isRequired,
};

TableFactory.defaultProps = {
  items: [],
  allowedKeys: [],
  total: 0,
  totalPages: 1,
  page: 1,
  perPage: 10,
};

export default withEmptyFeedback(TableFactory);
