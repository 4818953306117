import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { IconFilter } from "components/core/Layout";

const FilterButton = ({
  counterFilters,
  handleShowFilter,
  handleClearFilters,
  cy,
  captionIndex,
}) => {
  const { t: translate } = useTranslation();

  if (counterFilters === 0) {
    return (
      <Button variant="outline" handleClick={handleShowFilter} data-cy={cy}>
        <IconFilter />
        <span>{translate(captionIndex)}</span>
      </Button>
    );
  }

  return (
    <Button data-cy={cy} variant="outline" handleClick={handleClearFilters}>
      <IconFilter count={counterFilters} />
      <span>{translate("clearFilters")}</span>
    </Button>
  );
};

FilterButton.propTypes = {
  counterFilters: PropTypes.number,
  handleShowFilter: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  cy: PropTypes.string,
  captionIndex: PropTypes.string,
};

FilterButton.defaultProps = {
  counterFilters: 0,
  cy: "",
  captionIndex: "filter",
};

export default FilterButton;
