import React from "react";
import NavBar from "components/core/NavBar";
import { Table, RenderHeaders, TableBody, TableHeader, TableItem } from "components/core/Table";
import { Span } from "components/core";
import { formatDate, formatNumberToReal } from "helpers";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { REQUEST_PENDING, REQUEST_NOT_STARTED } from "utils/constants";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import Pagination from "components/core/Pagination";

const List = ({
  reports,
  requestStatus,
  params,
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();

  const headers = [
    { slug: "id", title: "ID" },
    { slug: "empresa", title: "Empresa" },
    { slug: "usuario", title: "Usuário" },
    { slug: "cnpj", title: "CNPJ" },
    { slug: "telefone", title: "Telefone" },
    { slug: "regime", title: "Regime" },
    { slug: "tabela", title: "Tabela" },
    { slug: "substatus", title: "Sub Status" },
    { slug: "modalidade", title: "Modalidade" },
    { slug: "cadastro", title: "Cadastro" },
    { slug: "status", title: "Status" },
    { slug: "primeira_cobranca", title: "Primeira Cobrança" },
    { slug: "primeiro_valor_pago", title: "1° Valor Pago" },
    { slug: "renovacao", title: "Renovação" },
    { slug: "ultima_cobranca", title: "Ultima Cobrança" },
    { slug: "ultimo_valor_pago", title: "Ultimo Valor Pago" },
    { slug: "proxima_cobranca", title: "Próxima Cobrança" },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "Aguardando Pagamento":
        return StatusTypes.WARNING;
      case "Ativo":
        return StatusTypes.SUCCESS;
      case "Pagamento Recusado":
        return StatusTypes.ERROR;
      case "Ainda não venceu":
        return StatusTypes.PROCESSING;
      case "Inativo":
        return StatusTypes.CANCELED;
      case null:
        return null;
      default:
        return StatusTypes.INFO;
    }
  };

  const requestPending = (status) => {
    return status === REQUEST_PENDING || status === REQUEST_NOT_STARTED;
  };

  return requestPending(requestStatus) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <NavBar title={translate("reportsPayments")} />
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {reports.map(
            ({
              id,
              empresa,
              usuario,
              cadastro,
              cnpj,
              telefone,
              regime,
              tabela,
              substatus,
              modalidade,
              primeiraCobranca,
              status,
              primeiroValorPago,
              proximaCobranca,
              ultimaCobranca,
              renovacao,
              ultimoValorPago,
            }) => {
              return (
                <tr key={id}>
                  <TableItem>
                    <Span>{id}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{empresa}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{usuario}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{cnpj}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{telefone}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{regime}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{tabela}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>
                      <StatusDot type={getStatusColor(substatus)} />
                      {substatus}
                    </Span>
                  </TableItem>
                  <TableItem>
                    <Span>{modalidade}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{formatDate(cadastro)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>
                      <StatusDot type={getStatusColor(status)} />
                      {status}
                    </Span>
                  </TableItem>
                  <TableItem>
                    <Span>{formatDate(primeiraCobranca)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{formatNumberToReal(primeiroValorPago)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{formatDate(renovacao)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{ultimaCobranca ? formatDate(ultimaCobranca) : null}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{formatNumberToReal(ultimoValorPago)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{proximaCobranca ? formatDate(proximaCobranca) : null}</Span>
                  </TableItem>
                </tr>
              );
            },
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  reports: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  reports: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
