import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SignInContainer from "containers/SignIn";
import ForgotPassword from "containers/Password/ForgotPassword";
import ChangePassword from "containers/Password/ChangePassword";
import { ROUTES } from "utils/constants";

const Public = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={SignInContainer} />
      <Route exact path={ROUTES.recoverPassword.path} component={ForgotPassword} />
      <Route exact path={ROUTES.recoverPassword.children.token.path} component={ChangePassword} />
    </Switch>
  );
};

export default Public;
