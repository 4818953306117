import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from "utils/constants/request";

export const { Types: PasswordTypes, Creators: PasswordActions } = createActions({
  sendTokenByEmail: ["payload"],
  changePasswordWithToken: ["payload"],
  requestSuccess: ["response"],
  requestFailure: ["response"],
  cleanState: null,
});

const INITIAL_STATE = {
  requestStatus: REQUEST_NOT_STARTED,
  requestResponse: {},
};

const sendTokenByEmail = (state) => ({
  ...state,
  requestStatus: REQUEST_PENDING,
});

const changePasswordWithToken = (state) => ({
  ...state,
  requestStatus: REQUEST_PENDING,
});

const requestSuccess = (state, { response }) => ({
  ...state,
  requestResponse: response,
  requestStatus: REQUEST_RESOLVED,
});

const requestFailure = (state, { response }) => ({
  ...state,
  requestResponse: response,
  requestStatus: REQUEST_REJECTED,
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [PasswordTypes.SEND_TOKEN_BY_EMAIL]: sendTokenByEmail,
  [PasswordTypes.CHANGE_PASSWORD_WITH_TOKEN]: changePasswordWithToken,
  [PasswordTypes.REQUEST_SUCCESS]: requestSuccess,
  [PasswordTypes.REQUEST_FAILURE]: requestFailure,
  [PasswordTypes.CLEAN_STATE]: cleanState,
});
