import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import StatusDot from "components/core/StatusDot";
import { Subtitle, Paragraph } from "components/core/Typography";
import { Container, Columns } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import { MASK_TYPES } from "utils/masks";
import { Form, InputHidden } from "components/core/Form";
import { formatDate } from "helpers/format";
import { COUPONS_COLORS_DICTIONARY } from "utils/constants";

const CouponsEdit = ({ defaultValues, validationSchema, handleCouponsEdit, submitRef }) => {
  const { t: translate } = useTranslation();
  const startDate = formatDate(defaultValues.startDate);
  const endDate = formatDate(defaultValues.endDate);
  const { status } = defaultValues;
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues: { ...defaultValues, startDate, endDate, status },
    mode: "onBlur",
    submitFocusError: true,
  });
  return (
    <>
      <Container>
        <Subtitle>{translate("details")}</Subtitle>
        <Columns columns="4">
          <CardInfo label={translate("couponsCode")} info={defaultValues.code} />
          <CardInfo label={translate("discount")} info={`${defaultValues.discount}%`} />
          <CardInfo
            label={translate("status")}
            info={
              <>
                <StatusDot type={COUPONS_COLORS_DICTIONARY[status?.name]} />
                {status?.name}
              </>
            }
          />
        </Columns>
      </Container>
      <Container maxWidth="md">
        {defaultValues?.status?.name === "Agendado" ? (
          <Form onSubmit={handleSubmit(handleCouponsEdit)}>
            <InputForm
              register={register}
              type="text"
              name="startDate"
              label={translate("startDate")}
              errorMsg={errors.startDate?.message}
              mask={MASK_TYPES.date}
            />
            <InputForm
              register={register}
              type="text"
              name="endDate"
              label={translate("endDate")}
              mask={MASK_TYPES.date}
            />
            <InputForm
              register={register}
              type="number"
              name="amount"
              label={translate("numberOfUses")}
            />
            <InputForm
              register={register}
              type="text"
              name="description"
              label={translate("description")}
            />
            <InputHidden ref={register} name="code" value={defaultValues.code} />
            <InputHidden ref={register} name="discount" value={defaultValues.discount} />
            <InputHidden ref={submitRef} type="submit" value="Submit" />
          </Form>
        ) : (
          <Paragraph>{translate("statusCouponsActive")}</Paragraph>
        )}
      </Container>
    </>
  );
};

CouponsEdit.propTypes = {
  submitRef: PropTypes.object.isRequired,
  handleCouponsEdit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default CouponsEdit;
