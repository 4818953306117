import React, { useState, useEffect } from "react";
import { EnterpriseActions } from "store/ducks/enterprise";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CheckFieldsActions } from "store/ducks/checkFields";
import { CnpjCheckerActions } from "store/ducks/cnpjChecker";
import { REQUEST_REJECTED, REQUEST_RESOLVED, REQUEST_PENDING } from "utils/constants";
import { parseMoneyToFloat } from "helpers/sanitize";
import { useStateMachine } from "little-state-machine";
import { useUpadateAction } from "hooks";
import InitialForm from "./initialInfoForm";
import ConstEnterpriseForm from "./isConstitutedEnterprise";
import EnterpriseCnpjForm from "./enterpriseCnpj";
import ReasonSocialForm from "./socialReason";
import NameFantasyForm from "./fantasyName";
import TypeActivityForm from "./activityType";
import StateRegsForm from "./stateRegistration";
import CityRegsForm from "./cityRegistration";
import AddressForm from "./address";
import EnterpriseTypeForm from "./enterpriseType";
import BillingForm from "./billing";
import WorkersForm from "./workers";
import InvoiceTypesScreen from "./invoiceTypes";
import ConfirmDataScreen from "./confirmData";
import Plans from "./subscriptionPlans";
import PaymentForm from "./payment";
import RegisterCompleteScreen from "./registerComplete";

const NewUserForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions, state: storage } = useStateMachine({ useUpadateAction });
  const [user, setUser] = useState({});
  const [step, setStep] = useState(0);
  const [isConstituted, setIsConstituted] = useState(false);
  const [type, setType] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [primaryActivityCode, setPrimaryActivityCode] = useState(null);
  const [primaryActivityName, setPrimaryActivityName] = useState(null);
  const [secondaryActivities, setSecondaryActivities] = useState(null);
  const [enterpriseName, setEnterprisename] = useState(null);
  const [enterpriseAddress, setEnterpriseAddress] = useState({});
  const [expirationDateBillet, setExpirationDateBillet] = useState("");
  const [isSubsPlans, setIsSubsPlans] = useState(false);
  const [plansParams, setPlansParams] = useState({});
  const [emailValidation, setEmailValidation] = useState("");
  const [cnpjValidation, setCnpjValidation] = useState("");
  const [isCNPJ, setIsCNPJ] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [validateIssuaces, setValidateIssuaces] = useState(0);
  const [issuacesMessage, setIssuacesMessage] = useState("");
  const [isEnterprisePending, setIsEnterprisePending] = useState(false);
  const [isEnterpriseError, setIsEnterpriseError] = useState(false);
  const [employeesNumber, setEmployeesNumber] = useState(1);
  const [proLaboreNumber, setProLaboreNumber] = useState(0);
  const [billetCostTax, setBilletCostTax] = useState(0);

  const { checkFieldsRequests } = useSelector(({ checkFields }) => checkFields);
  const { enterpriseInfo, cnpjCheckRequestStatus } = useSelector(({ cnpjChecker }) => cnpjChecker);
  const { types, billingRanges, subscriptionPlans, EnterpriseRequests, enterpriseCreated } =
    useSelector(({ enterprise }) => enterprise);
  const { messageErrors } = useSelector(({ layout }) => layout);

  useEffect(() => {
    if (subscriptionPlans?.length) {
      setBilletCostTax(subscriptionPlans[0].billetCost);
    }
  }, [subscriptionPlans]);

  const maxSteps = 15;

  const handleEmail = ({ target: { value } }) => setEmailValidation(value);

  useEffect(() => {
    if (emailValidation.length) {
      dispatch(CheckFieldsActions.checkFields({ email: emailValidation }));
    }
  }, [emailValidation, dispatch]);

  const EmailSuccess = checkFieldsRequests.CHECK_FIELDS;

  const handleCnpj = ({ target: { value } }) => setCnpjValidation(value);

  useEffect(() => {
    if (cnpjValidation.length === 18) {
      dispatch(CheckFieldsActions.checkFields({ cnpj: cnpjValidation }));
      setCnpjValidation("");
    }
  }, [cnpjValidation, dispatch]);

  const CnpjSuccess = checkFieldsRequests.CHECK_FIELDS;

  useEffect(() => {
    let today = new Date();
    const day = String(today.getDate() + 7).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    today = `${year}-${day}-${month}`;
    setExpirationDateBillet(today);
  }, []);

  const sendData = (data) => {
    const {
      idCoupon,
      idBillingRange,
      billing,
      price,
      idPaymentMethod,
      couponDiscount,
      idEnterpriseType,
      secondaryActivities: secondaryActivitiesData,
    } = data;
    const ticket = couponDiscount !== "100" ? billetCostTax : 0;
    const newPrice = price.length && parseFloat(price) - parseFloat(price) * (couponDiscount / 100);
    const finalPrice =
      idPaymentMethod === "1" ? parseFloat(newPrice) + ticket : parseFloat(newPrice);
    const payload = {
      ...data,
      numberEmployees: employeesNumber,
      idCoupon: idCoupon.length === 0 ? undefined : idCoupon,
      idBillingRange: idEnterpriseType === "1" ? undefined : idBillingRange,
      billing: billing?.length === 0 || !billing ? undefined : parseMoneyToFloat(billing),
      price: parseFloat(finalPrice.toFixed(2)),
      secondaryActivities:
        Array.isArray(secondaryActivitiesData) && secondaryActivitiesData[0] === "00000"
          ? []
          : secondaryActivitiesData,
    };
    if (!idPaymentMethod) payload.idPaymentMethod = "2";
    dispatch(EnterpriseActions.createEnterprise(payload));
    sessionStorage.clear();
    setStep((oldStep) => oldStep + 1);
  };

  useEffect(() => {
    if (EnterpriseRequests.createEnterprise === REQUEST_PENDING) {
      setIsEnterprisePending(true);
    } else if (EnterpriseRequests.createEnterprise === REQUEST_RESOLVED) {
      if (enterpriseCreated && enterpriseCreated.paymentToken) {
        history.push(`payment/${enterpriseCreated?.paymentToken}`);
      } else {
        history.push("congratulations");
      }
    } else if (EnterpriseRequests.createEnterprise === REQUEST_REJECTED) {
      setIsEnterprisePending(false);
      setIsEnterpriseError(true);
    }
  }, [EnterpriseRequests.createEnterprise]);

  const onContinue = (data, eventData) => {
    actions.useUpadateAction(data);
    if (isConstituted && isCNPJ) {
      const { zipCode, street, number, neighborhood, complement, state, city } = enterpriseAddress;

      setUser((oldUser) => ({
        ...oldUser,
        ...data,
        newEnterprise: !isConstituted,
        secondaryActivities: secondaryActivities ?? undefined,
        enterpriseName: enterpriseName ?? data.enterpriseName,
        idEnterpriseActivity: primaryActivityCode,
        textEnterpriseActivity: primaryActivityName,
        zipCode,
        street,
        number,
        neighborhood,
        complement,
        state,
        city,
        expirationDateBillet,
        invoiceTypes: invoiceTypes ?? undefined,
      }));
    }

    setUser((oldUser) => ({
      ...oldUser,
      ...data,
      newEnterprise: !isConstituted,
      secondaryActivities: secondaryActivities ?? undefined,
      expirationDateBillet,
      invoiceTypes: invoiceTypes ?? undefined,
    }));

    if (step === maxSteps - 1) {
      sendData({ ...user, ...data });
    } else if (step === 4 && !isConstituted) {
      setStep((oldStep) => oldStep + 3);
    } else if (step === 6 && isConstituted) {
      setStep((oldStep) => oldStep + 2);
    } else if (step === 2 && isConstituted) {
      const CNPJtoParams = data.cnpj.replace(/(\.|-|\/)/g, "");
      dispatch(CnpjCheckerActions.checkCnpj({ cnpj: CNPJtoParams }));
    } else {
      setStep((oldStep) => oldStep + 1);
    }

    if (eventData) {
      window.dataLayer.push({
        event: eventData,
      });
    }
  };

  useEffect(() => {
    if (step === 1) {
      delete user.cnpj;
      delete user.city;
      delete user.cityRegistration;
      delete user.codeCoupon;
      delete user.complement;
      delete user.couponDiscount;
      delete user.enterpriseActivity;
      delete user.enterpriseName;
      delete user.expirationDateBillet;
      delete user.fantasyName;
      delete user.idAnnuityDiscount;
      delete user.idBilletCost;
      delete user.idBillingRange;
      delete user.idCalculationTaxMei;
      delete user.idEnterpriseActivity;
      delete user.idEnterpriseType;
      delete user.idOpeningTax;
      delete user.idPaymentMethod;
      delete user.idPayroll;
      delete user.idSubscriptionPlan;
      delete user.invoiceTypes;
      delete user.isEmployees;
      delete user.neighborhood;
      delete user.newEnterprise;
      delete user.number;
      delete user.numberEmployees;
      delete user.price;
      delete user.regs;
      delete user.secondaryActivities;
      delete user.state;
      delete user.stateRegistration;
      delete user.street;
      delete user.textEnterpriseActivity;
      delete user.zipCode;
    }
  }, [step]);

  const onBack = () => {
    setValidateIssuaces(4);

    if (step > 0 && step !== maxSteps) {
      if (step === 7 && !isConstituted) {
        setStep((oldStep) => oldStep - 3);
      } else if (step === 8 && isConstituted) {
        setStep((oldStep) => oldStep - 2);
      } else if (step === 5 && isCNPJ) {
        setStep((oldStep) => oldStep - 3);
      } else if (step === 2 && isConstituted) {
        setFeedback(null);
        setStep((oldStep) => oldStep - 1);
      } else setStep((oldStep) => oldStep - 1);
    }
  };

  useEffect(() => {
    if (step === 2) {
      if (cnpjCheckRequestStatus.CHECK_CNPJ === REQUEST_RESOLVED) {
        setStep((oldStep) => oldStep + 3);
        const newSecondaryActivities = enterpriseInfo?.secondaryActivity?.map(({ code }) => code);
        setSecondaryActivities(newSecondaryActivities);
        setEnterprisename(enterpriseInfo?.enterpriseName);
        setPrimaryActivityCode(enterpriseInfo?.activity[0].code);
        setPrimaryActivityName(enterpriseInfo?.activity[0].text);
        setEnterpriseAddress({
          zipCode: enterpriseInfo?.zipCode,
          street: enterpriseInfo?.street,
          number: enterpriseInfo?.number,
          neighborhood: enterpriseInfo?.neighborhood,
          complement: enterpriseInfo?.complement,
          state: enterpriseInfo?.state,
          city: enterpriseInfo?.city,
        });
        setIsCNPJ(true);
        dispatch(CnpjCheckerActions.cleanState());
      }
      if (cnpjCheckRequestStatus.CHECK_CNPJ === REQUEST_REJECTED) {
        setFeedback({
          title: "Erro!",
          isSuccess: false,
          description: "Caso não possua CNPJ, crie uma nova empresa",
        });
        setPrimaryActivityCode(user?.idEnterpriseActivity);
        setPrimaryActivityName(user?.textEnterpriseActivity);
        setSecondaryActivities(null);
        setEnterprisename(null);
        dispatch(CnpjCheckerActions.cleanState());
        setIsCNPJ(false);
      }
    }
  }, [cnpjCheckRequestStatus, enterpriseInfo, step, dispatch]);

  useEffect(() => {
    if (!types.length) {
      dispatch(EnterpriseActions.getTypes());
    }
  }, []);

  useEffect(() => {
    if (type && type !== 1) {
      dispatch(EnterpriseActions.getBillingRanges(type));
    }
  }, [dispatch, type]);

  useEffect(() => {
    if (invoiceTypes.length) {
      const params = {
        state: user?.state,
        city: user?.city,
        idEnterpriseType: Number(user?.idEnterpriseType),
        invoiceTypes,
      };
      dispatch(EnterpriseActions.getIssuances(params));
    }
  }, [invoiceTypes]);

  useEffect(() => {
    if (EnterpriseRequests.getIssuances === REQUEST_PENDING) {
      setValidateIssuaces(1);
    } else if (EnterpriseRequests.getIssuances === REQUEST_RESOLVED) {
      setValidateIssuaces(2);
    } else if (EnterpriseRequests.getIssuances === REQUEST_REJECTED) {
      setValidateIssuaces(3);
    }
  }, [EnterpriseRequests.getIssuances]);

  useEffect(() => {
    if (messageErrors.message) {
      setIssuacesMessage(messageErrors.message);
    }
  }, [messageErrors.message]);

  useEffect(() => {
    const plansData = {
      newEnterprise: !isConstituted,
      idEnterpriseType: parseInt(user?.idEnterpriseType, 10),
      numberEmployees: employeesNumber,
      idBillingRange: user?.idEnterpriseType !== "1" ? parseInt(user?.idBillingRange, 10) : null,
      billing:
        user?.billing && user?.idEnterpriseType === "1" ? parseMoneyToFloat(user?.billing) : null,
    };
    if (user?.idEnterpriseType !== 1 && invoiceTypes.includes(2) && billingRanges[type]) {
      const maxBilling = billingRanges[type].reduce((p, c) => {
        return p.price > c.price ? p : c;
      });
      if (maxBilling) {
        plansData.idBillingRange = maxBilling.id;
        user.idBillingRange = maxBilling.id;
      }
    } else {
      plansData.idBillingRange = user.oldIdBillingRange;
      user.idBillingRange = user.oldIdBillingRange;
    }
    setPlansParams(plansData);
  }, [
    isConstituted,
    user?.idEnterpriseType,
    user.numberEmployees,
    user?.idBillingRange,
    user?.billing,
    employeesNumber,
    invoiceTypes,
    billingRanges,
    type,
    setUser,
  ]);

  useEffect(() => {
    if (isSubsPlans) {
      dispatch(EnterpriseActions.getSubscriptionPlans(plansParams));
    }
  }, [isSubsPlans, plansParams, dispatch]);

  const steps = {
    0: (
      <InitialForm
        storage={storage}
        onContinue={onContinue}
        handleEmail={handleEmail}
        checkEmail={EmailSuccess}
      />
    ),
    1: (
      <ConstEnterpriseForm onBack={onBack} setIsConstituted={setIsConstituted} setStep={setStep} />
    ),
    2: isConstituted ? (
      <EnterpriseCnpjForm
        onContinue={onContinue}
        onBack={onBack}
        feedback={feedback}
        handleCnpj={handleCnpj}
        checkCnpj={CnpjSuccess}
      />
    ) : (
      <ReasonSocialForm
        storage={storage}
        onContinue={onContinue}
        onBack={onBack}
        isConstituted={isConstituted}
      />
    ),
    3: isConstituted ? (
      <ReasonSocialForm
        storage={storage}
        onContinue={onContinue}
        onBack={onBack}
        isConstituted={isConstituted}
      />
    ) : (
      <NameFantasyForm
        storage={storage}
        onContinue={onContinue}
        onBack={onBack}
        isConstituted={isConstituted}
      />
    ),
    4: isConstituted ? (
      <NameFantasyForm
        storage={storage}
        onContinue={onContinue}
        onBack={onBack}
        isConstituted={isConstituted}
      />
    ) : (
      <TypeActivityForm onContinue={onContinue} onBack={onBack} />
    ),
    5: isConstituted && <StateRegsForm onContinue={onContinue} onBack={onBack} />,
    6: isConstituted && <CityRegsForm onContinue={onContinue} onBack={onBack} />,
    7: !isConstituted && <AddressForm storage={storage} onContinue={onContinue} onBack={onBack} />,
    8: (
      <EnterpriseTypeForm onContinue={onContinue} onBack={onBack} setType={setType} types={types} />
    ),
    9: <BillingForm onContinue={onContinue} onBack={onBack} billingRanges={billingRanges[type]} />,
    10: (
      <WorkersForm
        type={type}
        onContinue={onContinue}
        onBack={onBack}
        employeesNumber={employeesNumber}
        setEmployeesNumber={setEmployeesNumber}
        proLaboreNumber={proLaboreNumber}
        setProLaboreNumber={setProLaboreNumber}
      />
    ),
    11: (
      <InvoiceTypesScreen
        type={type}
        setInvoiceTypes={setInvoiceTypes}
        onBack={onBack}
        setStep={setStep}
        isValid={validateIssuaces}
        issuacesMessage={issuacesMessage}
      />
    ),
    12: (
      <ConfirmDataScreen
        enterpriseName={user?.enterpriseName}
        textEnterpriseActivity={user?.textEnterpriseActivity?.toString()}
        city={user?.city}
        state={user?.state}
        idBillingRange={user?.idBillingRange?.toString()}
        numberEmployees={employeesNumber?.toString()}
        cnpj={user?.cnpj}
        name={user?.name}
        email={user?.email}
        phoneNumber={user?.phoneNumber}
        onBack={onBack}
        setStep={setStep}
        billingRanges={billingRanges[type]}
        billing={user?.billing}
        setIsSubsPlans={setIsSubsPlans}
        invoiceTypes={invoiceTypes}
        enterpriseType={Number(user?.idEnterpriseType)}
      />
    ),
    13: (
      <Plans
        onContinue={onContinue}
        onBack={onBack}
        subscriptionPlans={subscriptionPlans}
        setMonthly={setMonthly}
      />
    ),
    14: (
      <PaymentForm
        onBack={onBack}
        onContinue={onContinue}
        enterpriseType={Number(user?.idEnterpriseType)}
        idSubscriptionPlan={user?.idSubscriptionPlan}
        city={user?.city}
        state={user?.state}
        monthly={monthly?.toString()}
        billetCostTax={billetCostTax}
        subscriptionPlans={subscriptionPlans}
      />
    ),
    15: <RegisterCompleteScreen isLoading={isEnterprisePending} error={isEnterpriseError} />,
  };

  return steps[step];
};

export default NewUserForm;
