import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import api from "services/api";
import { POST } from "utils/constants/verbs";
import { AUTH_USER, REFRESH_TOKEN } from "utils/constants";
import { Types as AuthTypes, Creators as AuthActions } from "store/ducks/auth";
import { removeClaims, AUTH_CLAIMS } from "helpers/auth";
import { LayoutActions } from "store/ducks/layout";

function* setClaims(claims) {
  yield call([localStorage, "setItem"], AUTH_CLAIMS, JSON.stringify(claims));
}

export function* authorize({ payload }) {
  try {
    const { email, password, remember } = payload;
    const { data } = yield api({
      method: POST,
      url: AUTH_USER,
      data: {
        email,
        password,
      },
    });
    if (
      data?.auth?.user?.roles === "master" ||
      data?.auth?.user?.roles === "accountant" ||
      data?.auth?.user?.roles === "marketing" ||
      (data?.auth?.user?.roles === "client" && data?.auth?.user?.isObvia)
    ) {
      yield put(LayoutActions.pinEmitterClear());
      yield setClaims({ ...data.auth, remember });
      yield put(AuthActions.authSuccess({ ...data.auth, remember }));
    } else if (data?.auth?.user?.roles === "client") {
      yield put(LayoutActions.pinEmitterClear());
      yield put(LayoutActions.pinEmitter(data.auth.user.enterprise));
      yield setClaims({ ...data.auth, remember });
      yield put(AuthActions.authSuccess({ ...data.auth, remember }));
    } else {
      yield put(AuthActions.authFailure());
    }
  } catch (error) {
    yield put(AuthActions.authFailure(error));
  }
}

export function* logout() {
  yield call(removeClaims);
}

export function* handleUnauthorized() {
  const {
    auth: {
      claims: { token, refreshToken, remember, ...others },
    },
  } = yield select();

  if (refreshToken && remember) {
    try {
      const { data } = yield api({
        method: POST,
        url: REFRESH_TOKEN,
        data: {
          token,
          refreshToken,
        },
      });
      yield setClaims({ ...others, ...data, remember });
      yield put(AuthActions.refreshToken(data));
    } catch (error) {
      yield put(AuthActions.authLogout());
    }
  } else {
    yield put(AuthActions.authLogout());
  }
}

export function* watchSagas() {
  yield takeLatest(AuthTypes.AUTH_REQUEST, authorize);
  yield takeEvery(AuthTypes.AUTH_LOGOUT, logout);
  yield takeLatest(AuthTypes.HANDLE_UNAUTHORIZED, handleUnauthorized);
}
