import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "components/core/Button";
import InputForm from "components/core/InputForm";
import { FeedbackWithRedux } from "components/core/Feedback";
import { TertiaryTitle, List } from "components/core/Typography";
import { Flex } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import * as S from "./styled";

const ChangePassword = ({
  validationSchema,
  defaultValues,
  handleChangePassword,
  validatePassword,
  showForm,
}) => {
  const [passwordValidations, setPasswordValidations] = useState([]);
  const { register, handleSubmit, errors, watch } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });
  const watchPassword = watch("password");

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  useEffect(() => {
    validatePassword(watchPassword, (error) => setPasswordValidations(error));
  }, [watchPassword]);

  return (
    <Flex verticalAlign="center">
      <FeedbackWithRedux />
      {showForm && (
        <Form onSubmit={handleSubmit(handleChangePassword)}>
          <Flex horizontalAlign="center">
            <TertiaryTitle fontWeight="bold" marginBottom="6rem" textAlign="center">
              Digite uma nova senha.
            </TertiaryTitle>
            <InputForm
              register={register}
              name="password"
              type={values.showPassword ? "text" : "password"}
              label="Nova Senha"
              autoComplete="new-password"
              errorMsg={errors.password?.message}
            >
              {values.showPassword ? (
                <S.IconPasswordShow onClick={handleClickShowPassword} />
              ) : (
                <S.IconPasswordHide onClick={handleClickShowPassword} />
              )}
            </InputForm>
            <InputForm
              register={register}
              name="confirmPassword"
              type={values.showPassword ? "text" : "password"}
              label="Confirme sua senha"
              autoComplete="new-password"
              errorMsg={errors.confirmPassword?.message}
            >
              {values.showPassword ? (
                <S.IconPasswordShow onClick={handleClickShowPassword} />
              ) : (
                <S.IconPasswordHide onClick={handleClickShowPassword} />
              )}
            </InputForm>
            <InputHidden name="token" ref={register} />
            <List>
              {!!passwordValidations.length && (
                <>
                  <li>
                    <strong>A senha deve conter:</strong>
                  </li>
                  {passwordValidations.map((validation) => (
                    <li key={validation}>{validation}</li>
                  ))}
                </>
              )}
            </List>
            <Button type="submit">Salvar</Button>
          </Flex>
        </Form>
      )}
    </Flex>
  );
};

ChangePassword.propTypes = {
  validationSchema: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
  validatePassword: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
};

export default ChangePassword;
