import React from "react";
import styled from "styled-components";
import { ReactComponent as IconTimes } from "assets/icons/icon-close.svg";
import { InputWrapper } from "components/core/InputForm/styled";
import { scrollbarStyle } from "components/core/Layout";

export const MultiSelectWrapper = styled(InputWrapper)`
  border-bottom: 1px solid var(--color-white-3);
  margin: 0 auto 2.4rem;
  width: 51rem;
`;

export const SelectedItemsDisplay = styled.div`
  background-color: var(--color-white-2);
  border-bottom: 1px solid var(--color-secondary);
  height: 7.2rem;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  margin-bottom: 0.8rem;
  ${scrollbarStyle};
`;

export const SelectedItem = styled.div`
  background-color: var(--color-secondary);
  border-radius: 0.8rem;
  color: var(--color-white-1);
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
  max-height: 2.4rem;
  max-width: 32rem;
  width: auto;
  padding: 0.4rem 0.8rem;
  display: inline-flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1.6rem;
  }

  span {
    max-width: 26.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
`;

const CloseIcon = styled(IconTimes)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const RemoveSelectedItem = styled.button.attrs({
  children: <CloseIcon />,
})`
  appearance: none;
  border: none;
  border-radius: 0;
  border-right: 0.1rem solid var(--color-white-2);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  outline: none;
  margin-right: 0.8rem;
  width: 2rem;
`;

export const ItemsToSelectWrapper = styled.div`
  border: 1px solid var(--color-white-2);
  height: ${({ he }) => he ?? "20rem"};
  overflow-x: auto;
  margin-bottom: ${({ mb }) => mb ?? "2.4rem"};
  margin-top: -0.2rem;
  ${scrollbarStyle};
`;

export const ItemToSelect = styled.div`
  padding: 1.4rem 1.2rem;
  border-bottom: 1px solid var(--color-white-3);

  span {
    color: var(--color-black-3);
  }
`;

export const EmptyList = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.8rem;
`;
