import React from "react";
import Portal from "components/core/Portal";
import { Spinner } from "components/core/WithSpinner/styled";
import { SpinnerOverlay } from "./styled";

const SuspenseFallback = () => (
  <Portal>
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  </Portal>
);

export default SuspenseFallback;
