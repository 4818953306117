import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getRolesStart: [],
  getRolesSuccess: ["payload"],
  getRolesError: ["error"],
});

const INITIAL_STATE = {
  roles: [
    {
      id: 1,
      roles: "master",
      type: "admin",
    },
    {
      id: 2,
      roles: "accountant",
      type: "admin",
    },
    {
      id: 4,
      roles: "marketing",
      type: "admin",
    },
  ],
  error: {},
  isLoading: true,
};

const getRolesStart = () => ({ ...INITIAL_STATE });

const getRolesSuccess = (state, { payload }) => ({
  ...state,
  roles: payload,
  isLoading: false,
});

const getRolesError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_ROLES_START]: getRolesStart,
  [Types.GET_ROLES_SUCCESS]: getRolesSuccess,
  [Types.GET_ROLES_ERROR]: getRolesError,
});
