import { useEffect, useRef, useCallback, useState } from "react";
import io from "socket.io-client";
import { base, CHAT_SOCKET, REQUEST_RESOLVED, REQUEST_PENDING } from "utils/constants";
import { getAuthClaims } from "helpers";
import { isPending } from "components/core/WithSpinner";

const socketEvents = Object.freeze({
  ALLOWED: "allowed",
  ROOM: "room",
  OLD_MESSAGES: "old-messages",
  SENDS_MESSAGE: "sends-message",
  LIST_OLD_MESSAGES: "list-old-messages",
  NEW_DOCUMENT: "new_document",
  NEW_MESSAGE: "new_message",
  ERROR: "error",
  SEND_MESSAGE_SUCCESS: "new_message_sended_confirmation",
});

const useChat = (idUser, messageOnLoad, newMessageCallback) => {
  const ioRef = useRef();
  const [token, setToken] = useState();
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const loadingMsgsRequest = useRef(REQUEST_PENDING);

  const handleOldMessages = useCallback((messagesToAdd = []) => {
    setHasMore(messagesToAdd.length > 0);
    setMessages((prevMsgs) => [...prevMsgs, ...messagesToAdd]);
    setLoadingMessages(false);
  }, []);

  const removeMessageById = (messageId) =>
    setMessages((currentMessages) => currentMessages.filter(({ id }) => id !== messageId));

  const handleNewMessage = useCallback(
    (message) => {
      setMessages((prevMsgs) => [message, ...prevMsgs]);

      if (newMessageCallback) {
        newMessageCallback(message);
      }
    },
    [newMessageCallback],
  );

  const handleNewDocument = ({ fromAdmin, ...document }) =>
    setMessages((prevMsgs) => [{ ...document, received: !fromAdmin }, ...prevMsgs]);

  const sendMessage = useCallback(
    (message) => {
      ioRef.current.emit(socketEvents.SENDS_MESSAGE, { token, message });
    },
    [token],
  );

  const startChat = useCallback(() => {
    setHasMore(true);
    setLoadingMessages(true);
    ioRef.current = io.connect(base, {
      path: CHAT_SOCKET,
      query: { token: getAuthClaims().token, idUser },
      forceNew: true,
    });

    loadingMsgsRequest.current = REQUEST_PENDING;
    ioRef.current.once(socketEvents.ALLOWED, setToken);

    return () => {
      ioRef.current.off(socketEvents.ALLOWED, setToken);
      ioRef.current.disconnect();
      ioRef.current.close();
    };
  }, [idUser]);

  useEffect(() => {
    startChat();
  }, [startChat]);

  useEffect(() => {
    loadingMsgsRequest.current = REQUEST_RESOLVED;
  }, [hasMore]);

  useEffect(() => {
    if (token) {
      setMessages([]);
      ioRef.current.emit(socketEvents.ROOM, token);
      ioRef.current.emit(socketEvents.LIST_OLD_MESSAGES, { token, perPage: 20, page: 1 });
    }
  }, [token]);

  useEffect(() => {
    if (token && messageOnLoad) {
      sendMessage(messageOnLoad);
    }
  }, [messageOnLoad, sendMessage, token]);

  useEffect(() => {
    ioRef.current.on(socketEvents.NEW_MESSAGE, handleNewMessage);

    return () => ioRef.current.off(socketEvents.NEW_MESSAGE, handleNewMessage);
  }, [token, handleNewMessage]);

  useEffect(() => {
    ioRef.current.on(socketEvents.OLD_MESSAGES, handleOldMessages);

    return () => ioRef.current.off(socketEvents.OLD_MESSAGES, handleOldMessages);
  }, [handleOldMessages, token]);

  useEffect(() => {
    if (token) {
      ioRef.current.on(socketEvents.NEW_DOCUMENT, handleNewDocument);
    }

    return () => ioRef.current.off(socketEvents.NEW_DOCUMENT, handleNewDocument);
  }, [token]);

  useEffect(() => {
    if (token) {
      ioRef.current.on(socketEvents.SEND_MESSAGE_SUCCESS, handleNewMessage);
    }

    return () => ioRef.current.off(socketEvents.SEND_MESSAGE_SUCCESS, handleNewMessage);
  }, [token, handleNewMessage]);

  useEffect(() => {
    if (page > 1 && !isPending(loadingMsgsRequest)) {
      loadingMsgsRequest.current = REQUEST_PENDING;
      ioRef.current.emit(socketEvents.LIST_OLD_MESSAGES, { token, perPage: 10, page });
    }
  }, [page, token]);

  return {
    messages,
    loadingMessages,
    requestLoadingMore: loadingMsgsRequest.current,
    sendMessage,
    removeMessageById,
    setPage,
    hasMore,
  };
};

export default useChat;
