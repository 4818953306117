import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Autocomplete from "components/core/Autocomplete";
import { Container } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import { MASK_TYPES } from "utils/masks";
import { Subtitle } from "components/core/Typography";
import { Form, InputHidden } from "components/core/Form";
import { formatDate } from "helpers/format";

const NcmGlobalsEdit = ({
  defaultValues,
  validationSchema,
  handleNcmGlobalsEdit,
  submitRef,
  ncmItems,
  isLoadingNcmItems,
  handleSearch,
  UnitMeasure,
  lastItemRefMeasure,
  handleSearchUnitMeasure,
  isLoadingUnitMeasureItems,
  prevUnitName,
  lastItemRefNCM,
}) => {
  const { t: translate } = useTranslation();
  const startTerm = formatDate(defaultValues.startTerm);
  const endTerm = defaultValues.endTerm && formatDate(defaultValues.endTerm);
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema,
    defaultValues: { ...defaultValues, startTerm, endTerm },
    mode: "onBlur",
    submitFocusError: true,
  });

  const handleChange = (item) => {
    setValue("idNcmCategory", item.id);
  };

  const handleChangeUnitMeasure = (item) => {
    setValue("idUnitMeasure", item.id);
  };

  useEffect(() => {
    if (prevUnitName) {
      setValue("unitMeasure", prevUnitName);
    }
  }, [prevUnitName, setValue]);

  return (
    <Container maxWidth="md">
      <Subtitle>Edite as Informações</Subtitle>
      <Form onSubmit={handleSubmit(handleNcmGlobalsEdit)}>
        <InputForm
          register={register}
          type="text"
          name="code"
          label={`${translate("ncmCode")} *`}
          errorMsg={errors.code?.message}
        />
        <InputForm
          register={register}
          type="text"
          name="name"
          label={`${translate("description")} *`}
          errorMsg={errors.name?.message}
        />
        <Autocomplete
          register={register}
          type="text"
          nameValue="ncm"
          label={`${translate("categoryLinked")} *`}
          handleSearch={handleSearch}
          handleChange={handleChange}
          items={ncmItems}
          isLoadingItems={isLoadingNcmItems}
          setValue={setValue}
          lastItemRef={lastItemRefNCM}
        />
        <InputHidden ref={register} name="idNcmCategory" />
        <Autocomplete
          register={register}
          type="text"
          label={`${translate("unitMeasure")} *`}
          nameValue="unitMeasure"
          handleSearch={handleSearchUnitMeasure}
          handleChange={handleChangeUnitMeasure}
          isLoadingItems={isLoadingUnitMeasureItems}
          items={UnitMeasure}
          setValue={setValue}
          lastItemRef={lastItemRefMeasure}
        />
        <InputHidden ref={register} name="idUnitMeasure" />
        <SelectForm
          register={register}
          name="icms"
          label="ICMS *"
          options={[
            {
              value: "Normal",
              text: "Normal",
            },
            {
              value: "ICMSST",
              text: "ICMSST",
            },
          ]}
          errorMsg={errors.icms?.message}
        />
        <InputForm
          register={register}
          type="text"
          name="startTerm"
          label={`${translate("startTerm")} *`}
          errorMsg={errors.startTerm?.message}
          mask={MASK_TYPES.date}
        />
        <InputForm
          register={register}
          type="text"
          name="endTerm"
          label={`${translate("endTerm")} *`}
          mask={MASK_TYPES.date}
        />
        <InputHidden ref={submitRef} type="submit" value="Submit" />
      </Form>
    </Container>
  );
};

NcmGlobalsEdit.propTypes = {
  submitRef: PropTypes.object.isRequired,
  handleNcmGlobalsEdit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  ncmItems: PropTypes.array.isRequired,
  isLoadingNcmItems: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  UnitMeasure: PropTypes.array.isRequired,
  lastItemRefMeasure: PropTypes.func.isRequired,
  handleSearchUnitMeasure: PropTypes.func.isRequired,
  isLoadingUnitMeasureItems: PropTypes.func.isRequired,
  prevUnitName: PropTypes.string.isRequired,
  lastItemRefNCM: PropTypes.func.isRequired,
};

export default NcmGlobalsEdit;
