import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Columns, Container } from "components/core/Grid";
import { Subtitle, Divider } from "components/core/Typography";

const LEGAL_CLIENT_TYPE = "legal";

const ClientDetails = ({
  client: {
    clientName,
    cpf,
    email,
    phone,
    enterpriseName,
    cnpj,
    street,
    number,
    neighborhood,
    complement,
    city,
    state,
    zipCode,
    personType,
    stateRegistration,
    cityRegistration,
  },
}) => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Subtitle>{translate("contact")}</Subtitle>
      <Columns columns="2">
        <CardInfo label={translate("name")} info={clientName} />
        <CardInfo label={translate("cpf")} info={cpf} />
        <CardInfo label={translate("email")} info={email} />
        <CardInfo label={translate("phone")} info={phone} />
      </Columns>
      <Divider />
      {personType === LEGAL_CLIENT_TYPE && (
        <>
          <Subtitle>{translate("company")}</Subtitle>
          <Columns columns="2">
            <CardInfo label={translate("enterpriseName")} info={enterpriseName} />
            <CardInfo label={translate("cnpj")} info={cnpj} />
          </Columns>
          <Columns columns="2">
            <CardInfo label={translate("stateRegistration")} info={stateRegistration} />
            <CardInfo label={translate("cityRegistration")} info={cityRegistration} />
          </Columns>
          <Divider />
        </>
      )}
      <Subtitle>{translate("address")}</Subtitle>
      <Columns columns="2">
        <CardInfo label={translate("street")} info={street} />
        <CardInfo label={translate("number")} info={number} />
        <CardInfo label={translate("neighborhood")} info={neighborhood} />
        <CardInfo label={translate("complement")} info={complement} />
        <CardInfo label={translate("city")} info={`${city}/${state}`} />
        <CardInfo label={translate("zipCode")} info={zipCode} />
      </Columns>
    </Container>
  );
};

ClientDetails.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ClientDetails;
