import { createActions, createReducer } from "reduxsauce";

export const { Types: AddressTypes, Creators: AddressActions } = createActions({
  cleanAddress: null,
  getAddressByCEP: ["cep"],
  getAddressByCEPSuccess: ["payload"],
  getAddressByCEPFailure: ["error"],
});

const INITIAL_STATE = {
  address: null,
  isLoading: false,
};

const getAddressByCEP = () => ({
  ...INITIAL_STATE,
  isLoading: true,
});

const getAddressByCEPSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  address: payload,
});

const getAddressByCEPFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const cleanAddress = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [AddressTypes.GET_ADDRESS_BY_CEP]: getAddressByCEP,
  [AddressTypes.GET_ADDRESS_BY_CEP_SUCCESS]: getAddressByCEPSuccess,
  [AddressTypes.GET_ADDRESS_BY_CEP_FAILURE]: getAddressByCEPFailure,
  [AddressTypes.CLEAN_ADDRESS]: cleanAddress,
});
