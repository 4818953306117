import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "components/core/NavBar";
import { ReportsActions } from "store/ducks/reports";
import WithSpinner from "components/core/WithSpinner";
import ReportsFileList from "components/presentation/Reports/File/List";
import { MASK_TYPES } from "utils/masks";
import { Container, Row } from "components/core/Grid";
import { Form } from "components/core/Form";
import InputForm from "components/core/InputForm";
import Button from "components/core/Button";
import { ReactComponent as IconSearch } from "assets/icons/icon-search.svg";
import { useForm } from "react-hook-form";
import { ReactComponent as IconExport } from "assets/icons/icon-download.svg";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core/Typography";
import { useRequest } from "hooks";

const ReportsFileListWithSpinner = WithSpinner(ReportsFileList);

const ReportsFileLisContainer = (validationSchema, defaultValues) => {
  const { register, handleSubmit, errors, watch } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
  });

  const watchcompetencyDateEx = watch("competencyDateEx");
  let loading = false;

  useEffect(() => {
    errors.errorMsg = undefined;
    if (watchcompetencyDateEx && watchcompetencyDateEx.length === 7) {
      const month = parseInt(watchcompetencyDateEx.substring(0, 2));
      const year = parseInt(watchcompetencyDateEx.substring(3, 7));

      if (month > 12 || month < 1) {
        errors.errorMsg = "Competência inválida";
      }

      if (year < 2000 || year > 2050) {
        errors.errorMsg = "Competência inválida";
      }
    }
  }, [watchcompetencyDateEx]);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const {
    reportsRequests,
    statusOfRequests,
    exportFiles,
    reports: { items, total, totalPages },
  } = useSelector(({ reports }) => reports);

  const fetchReports = (newParams) => {
    dispatch(ReportsActions.fetchReports(newParams));
  };

  const handleSearch = () => {
    if (!errors?.errorMsg) {
      try {
        fetchReports(watchcompetencyDateEx);
      } catch (error) {}
    }
  };

  const handleExportReports = () => {
    dispatch(ReportsActions.exportFiles({ reports: items }));
  };

  const onResolved = () =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{translate(exportFiles.message)}</Span>,
        type: "success",
      }),
    );

  useRequest({
    request: statusOfRequests.EXPORT_FILES_SUCCESS,
    onResolved,
  });

  return (
    <>
      <NavBar title={translate("ReportsSendFiles")} />
      <Form onSubmit={handleSubmit(handleSearch)}>
        <Row>
          <Container maxWidth="20%" verticalPadding="0">
            <InputForm
              register={register}
              type="text"
              name="competencyDateEx"
              label={translate("competencyDateEx")}
              maxWidth="sm"
              errorMsg={errors?.errorMsg}
              mask={MASK_TYPES.dateMonth}
            />
          </Container>
          <Container maxWidth="15%" verticalPadding="0" horizontalPadding="5px">
            <Button variant="outline" handleClick={handleSearch}>
              <IconSearch />
              &nbsp;{translate("search")}
            </Button>
          </Container>
          <Container maxWidth="15%" verticalPadding="0" horizontalPadding="5px">
            <Button variant="outline" handleClick={handleExportReports}>
              <IconExport />
              <span>{translate("export")}</span>
            </Button>
          </Container>
        </Row>
      </Form>
      <ReportsFileListWithSpinner
        requestStatus={reportsRequests.FETCH_REPORTS}
        reports={items}
        total={total}
        totalPages={totalPages}
        page={1}
        perPage={1}
        params={{}}
        order={{}}
        orderBy={{}}
      />
    </>
  );
};

/*
 */
export default ReportsFileLisContainer;
