export default {
  activate: "Ativar",
  activities: "Atividades",
  activityType: "Tipo de Atividade",
  address: "Endereço",
  addressInformation: "Informações de Endereço",
  attention: "Atenção",
  back: "Voltar",
  billing: "Faturamento",
  billingGreaterThan: "Faturamento acima de (mensal)",
  cancel: "Cancelar",
  category: "Categoria",
  categories: "Categorias",
  categoryLinked: "Categoria a ser Vinculada",
  certificatePassword: "Senha do Certificado",
  changeStatus: "Alterar Status",
  changesWillAffectAllPlans: "As alterações nas taxas serão aplicadas à todos os planos.",
  checking: "Verificando",
  chooseOptionAdd: "O que gostaria de adicionar?",
  city: "Cidade",
  cityRegistration: "Inscrição Municipal",
  clearAll: "Limpar Tudo",
  clearFilters: "Limpar Filtro",
  client: "Tomador",
  clientDetails: "Detalhes do Tomador",
  clients: "Tomadores",
  close: "Fechar",
  cnpj: "CNPJ",
  company: "Empresa",
  companyInformation: "Informações da Empresa",
  complement: "Complemento",
  contact: "Contato",
  contactInformation: "Informações de Contato",
  comments: "Observações",
  cpf: "CPF",
  cpfCnpj: "CPF/CNPJ",
  date: "Data",
  delete: "Excluir",
  description: "Descrição",
  details: "Detalhes",
  disable: "Desativar",
  documentType: "Tipo do Documento",
  download: "Download",
  edit: "Editar",
  editPlans: "Editar Planos",
  editTaxes: "Editar Taxas",
  email: "E-mail",
  billingEmail: "E-mail de Cobrança",
  emitter: "Emissor",
  emitters: "Emissores",
  emitterStatus: "Status do Emissor",
  employeesNumber: "Quantidade de Funcionários",
  proLaboreNumber: "Quantidade de pró-labores",
  endDate: "Data de término",
  endTerm: "Fim da Vigência",
  enterpriseName: "Razão Social",
  enterpriseType: "Tipo da Empresa",
  export: "Exportar",
  exportWithType: "Exportar como <bold>{{type}}</bold>",
  fantasyName: "Nome Fantasia",
  operationsNature: "Natureza da Operação",
  file: "Arquivo",
  fileName: "Nome do Arquivo",
  filter: "Filtrar",
  finish: "Finalizar",
  from: "De:",
  local: "Local",
  makeDownload: "Fazer Download",
  manage: "Gerenciar",
  message: "Mensagem",
  name: "Nome",
  nameOrCNPJ: "Nome/CNPJ",
  neighborhood: "Bairro",
  newUpload: "Novo Upload",
  noResultsFound: "Nenhum resultado encontrado",
  notification: "Notificações",
  number: "Número",
  openingTax: "Taxa de Abertura de Empresa",
  password: "Senha",
  period: "Período",
  periodEnd: "Data de Período Final",
  periodStart: "Data de Período Inicial",
  phone: "Telefone",
  billingPhone: "Telefone de Cobrança",
  pinEmitter: "Fixar Emissor",
  plan: "Plano",
  planValueMonthly: "Valor do Plano (mensal)",
  planValueYearly: "Valor do Plano (anual)",
  planValueBiennial: "Valor do Plano (bienal)",
  primaryActivity: "Atividade Primária",
  range: "Faixa",
  recipient: "Destinatário",
  referenceMonth: "Mês de Referência",
  referenceYear: "Ano de Referência",
  registrationDate: "Data de Cadastro",
  registrationDateEnd: "Data de Registro Final",
  registrationDateStart: "Data de Registro Inicial",
  save: "Salvar",
  search: "Pesquisar",
  searchEmitter: "Procure um Emissor",
  secondaryActivity: "Atividade Secundária",
  secondaryActivity_plural: "{{count}} Atividades Secundárias",
  select: "Selecione",
  selectAct: "Selecionar",
  selected_female: "Selecionada",
  selected_female_plural: "{{count}} Selecionadas",
  selected_male: "Selecionado",
  selected_male_plural: "{{count}} Selecionados",
  selectedActivities: "{{count}} Atividade Selecionada",
  selectedActivities_plural: "{{count}} Atividades Selecionadas",
  send: "Enviar",
  sendDate: "Data de Envio",
  smuSelected: "1 Serviço Selecionado",
  smuSelected_plural: "{{count}} Serviços Selecionados",
  statusLog: "Log de alteração de status",
  startDate: "Data de início",
  startTerm: "Início da Vigência",
  state: "Estado",
  stateRegistration: "Inscrição Estadual",
  status: "Status",
  street: "Logradouro",
  to: "Até:",
  typeActivityName: "Digite o nome da atividade",
  typeCityRegistration: "Digite a inscrição municipal (se houver)",
  typeCNPJ: "Digite o CNPJ",
  typeComplement: "Digite um complemento (se houver)",
  typeEnterpriseName: "Digite o nome da empresa",
  typeFantasyName: "Digite o nome fantasia",
  typeOperationsNature: "Digite a natureza da operação (se houver)",
  typeNameOrCNPJ: "Digite um nome ou CNPJ",
  typeNeighborhood: "Digite o bairro",
  typeNumber: "Digite o número",
  typeNumberNFe: "Digite o número da Nota Fiscal",
  typeStateRegistration: "Digite a inscrição estadual (se houver)",
  typeStreet: "Digite o logradouro",
  typeZipCode: "Digite o CEP",
  typeInvoiceValue: "Digite o valor da nota",
  updateSaveWithSuccess: "Alterações salvas com sucesso",
  username: "Nome de usuário",
  zipCode: "CEP",
  restart: "Reemitir",
  reportsPayments: "Relatório Pagamentos",
  params: "Parâmetros",
  paramsEmitters: "Parâmetros Emissores",
  chooseOptionvView: "O que gostaria de visualizar?",
  activityGroup: "Grupos de Atividades",
  activityGroupSingular: "Grupo de Atividade",
  sources: "Fontes",
  source: "Fonte",
  responsibleSale: "Responsáveis Venda",
  responsibleForSale: "Responsável pela Venda",
  register: "Cadastrar Novo",
  constitution: "Constituição",
  priority: "Prioridade",
  partners: "Parceiros",
  partnersObvia: "Parceiro Obvia",
  dateOfBirth: "Data de Nascimento",
  financialStatus: "Status Financeiro",
  accountantCertificates: "Certificados Contadores",
  markAsUnRead: "Marcar como não lido",
  sendBy: "Enviado por",
};
