import React, { useEffect, useCallback, useRef, useState } from "react";
import { useParams as urlParams, useHistory } from "react-router-dom";
import { useParams, useFinancialSubscription, useRequest } from "hooks";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner, { isNotInit } from "components/core/WithSpinner";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import SubscriptionDetails from "components/presentation/Financial/Subscriptions/Details";
import ChargeInvoice from "containers/Financial/Payment/Charge";
import { LINKS, REQUEST_PENDING, REQUEST_RESOLVED } from "utils/constants";
import { ColumnFlex } from "components/core/Grid";
import { Span } from "components/core/Typography";

const SubscriptionDetailsWithSpinner = WithSpinner(SubscriptionDetails);

const SubscriptionDetailsContainer = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();
  const submitItemsRef = useRef(null);
  const { id } = urlParams();
  const {
    availableBillingPeriods,
    billingsFrom,
    cleanState,
    deleteSubscriptionItem,
    deleteSubscriptionItems,
    getBillingsFrom,
    getSubscription,
    getSubscriptionInvoice,
    getSubscriptionInvoices,
    getSubscriptionItems,
    getSubscriptionItemsByCycles,
    handleOnRejected,
    handleOnResolved,
    requestStatus: statusRequests,
    saveSubscriptionItem,
    simulatedSubscription,
    simulateSubscription,
    subscription,
    subscriptionItems,
    subscriptionInvoice,
    subscriptionInvoices,
    subscriptionInvoicesUseInvoices,
    subscriptionItemsUseItems,
    subscriptionPrice,
    billSubscription,
    sendInvoiceMail,
    resetChargeStates,
    toggleChargeModal,
    validationErrors,
    resetState,
    error,
  } = useFinancialSubscription();
  const [requestStatusItems, setRequestStatusItems] = useState(null);
  const [requestStatusInvoices, setRequestStatusInvoices] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [showConfirmBillSubscription, setShowConfirmBillSubscription] = useState(false);

  const title = `Assinatura #${id}`;

  useEffect(() => {
    if (subscriptionPrice) {
      subscription.price = subscriptionPrice;
    }
  }, [subscriptionPrice]);

  useEffect(() => {
    if (isNotInit(statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES)) {
      setRequestStatusItems(statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS);
    }
  }, [
    statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS,
    statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES,
  ]);

  useEffect(() => {
    setRequestStatusInvoices(statusRequests.GET_FINANCIAL_SUBSCRIPTION_INVOICES);
  }, [statusRequests.GET_FINANCIAL_SUBSCRIPTION_INVOICES]);

  useEffect(() => {
    setRequestStatusItems(statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEM);
  }, [statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEM]);

  useEffect(() => {
    setRequestStatusItems(statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS);
  }, [statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS]);

  useEffect(() => {
    setRequestStatusItems(statusRequests.SAVE_FINANCIAL_SUBSCRIPTION_ITEM);
  }, [statusRequests.SAVE_FINANCIAL_SUBSCRIPTION_ITEM]);

  useEffect(() => {
    refreshItems();
  }, [statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES]);

  useEffect(() => {
    if (
      statusRequests.CANCEL_INVOICE === REQUEST_RESOLVED ||
      statusRequests.BILL_SUBSCRIPTION === REQUEST_RESOLVED
    )
      refreshInvoices();
  }, [statusRequests.CANCEL_INVOICE, statusRequests.BILL_SUBSCRIPTION]);

  const fetchSubscription = useCallback(() => getSubscription(id), [id]);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  const handleBillingsFrom = () => {
    getBillingsFrom(id);
  };

  const fetchSubscriptionItems = (params) => {
    getSubscriptionItems({
      ...params,
      id,
      attributes: [
        "id",
        "description",
        "quantity",
        "price",
        "recurrent",
        "paymentGatewayClientSubscriptionId",
        "type",
        "discountType",
        "discountValue",
        "customItem",
        "planCode",
        "billingFrom",
      ],
    });
  };

  const fetchSubscriptionInvoices = (params) => {
    getSubscriptionInvoices({
      ...params,
      id,
      attributes: [
        "id",
        "total",
        "dueDate",
        "status",
        "paymentExpiresAt",
        "paymentCycledAt",
        "paymentPaidAmount",
        "paymentMethod",
        "gatewayFriendlyName",
        "paymentDate",
        "gatewayInvoiceId",
        "originalInvoiceId",
        "emailSent",
        "billingPeriod",
        "subscriptionExpiresAt",
      ],
    });
  };

  const subscriptionItemsParams = {
    sort: "customItem_desc.type.discountType_desc.description",
    perPage: 50,
  };

  const subscriptionInvoicesParams = {
    sort: "id_desc",
    perPage: 10,
  };

  let itemsParams = useParams({
    callback: fetchSubscriptionItems,
    ...subscriptionItemsParams,
  });

  let invoicesParams = useParams({
    callback: fetchSubscriptionInvoices,
    ...subscriptionInvoicesParams,
  });

  const refreshAll = () => {
    fetchSubscription();
    refreshItems();
    refreshInvoices();
  };

  const refresh = ({ resourceParams, resourceObj, resourceSelectedId, factor, fetchCallback }) => {
    let params = resourceParams.params;
    if (factor === 0) {
      const page = resourceSelectedId > 0 ? params.page : 1;
      resourceParams.setPage(page);
      fetchCallback({ ...params, page });
    } else {
      const total = resourceObj.total + 1 * factor;
      const pages = total / resourceObj.limit;
      params.page = Math.ceil(pages);
      resourceParams.setPage(params.page);
      fetchCallback(params);
    }
  };

  const refreshItems = (factor = 0) => {
    refresh({
      resourceParams: itemsParams,
      resourceObj: subscriptionItems,
      resourceSelectedId: selectedItemId,
      factor,
      fetchCallback: fetchSubscriptionItems,
    });
  };

  const refreshInvoices = (factor = 0) => {
    refresh({
      resourceParams: invoicesParams,
      resourceObj: subscriptionInvoices,
      resourceSelectedId: selectedInvoiceId,
      factor,
      fetchCallback: fetchSubscriptionInvoices,
    });
  };

  const handleDeleteSubscriptionItem = (itemId) => {
    deleteSubscriptionItem({ itemId, subscriptionId: id });
  };

  const handleDeleteSubscriptionItems = () => {
    const selectedItems = subscriptionItemsUseItems.selectedItems;
    const ids = selectedItems.map((item) => item.id);
    deleteSubscriptionItems({ ids, subscriptionId: id });
  };

  const handleSubmitItems = (data) => {
    if (data.id) setSelectedItemId(data.id);
    saveSubscriptionItem({ subscriptionId: id, ...data });
  };

  const handleBillSubscription = () => {
    resetState({ stateName: "simulatedSubscription", newValue: null });
    setShowConfirmBillSubscription(true);
  };

  useRequest({
    request: statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEM,
    onResolved: () =>
      handleOnResolved("removed", () => {
        resetState({ stateName: "DELETE_FINANCIAL_SUBSCRIPTION_ITEM", isRequestState: true });
        refreshItems(-1);
      }),
    onRejected: () =>
      handleOnRejected("removeError", validationErrors(), () => {
        resetState({ stateName: "DELETE_FINANCIAL_SUBSCRIPTION_ITEM", isRequestState: true });
        refreshItems();
      }),
  });

  useRequest({
    request: statusRequests.SAVE_FINANCIAL_SUBSCRIPTION_ITEM,
    onResolved: () =>
      handleOnResolved("saved", () => {
        resetState({ stateName: "SAVE_FINANCIAL_SUBSCRIPTION_ITEM", isRequestState: true });
        refreshItems(selectedItemId > 0 ? 0 : 1);
      }),
    onRejected: () =>
      handleOnRejected("saveError", validationErrors(), () => {
        resetState({ stateName: "SAVE_FINANCIAL_SUBSCRIPTION_ITEM", isRequestState: true });
        refreshItems();
      }),
  });

  useRequest({
    request: statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS,
    onResolved: () =>
      handleOnResolved("removed", () => {
        resetState({ stateName: "DELETE_FINANCIAL_SUBSCRIPTION_ITEMS", isRequestState: true });
        refreshAll();
      }),
    onRejected: () =>
      handleOnRejected("removeError", validationErrors(), () => {
        resetState({ stateName: "DELETE_FINANCIAL_SUBSCRIPTION_ITEMS", isRequestState: true });
        refreshItems();
      }),
  });

  useRequest({
    request: statusRequests.BILL_SUBSCRIPTION,
    onResolved: () =>
      handleOnResolved("subscriptionBilled", () => {
        resetState({ stateName: "BILL_SUBSCRIPTION", isRequestState: true });
        refreshAll();
      }),
    onRejected: () =>
      handleOnRejected("subscriptionBilledError", validationErrors(), () => {
        resetState({ stateName: "BILL_SUBSCRIPTION", isRequestState: true });
      }),
  });

  useRequest({
    request: statusRequests.CANCEL_INVOICE,
    onResolved: () =>
      handleOnResolved("invoiceCanceled", () => {
        resetState({ stateName: "CANCEL_INVOICE", isRequestState: true });
        refreshAll();
      }),
    onRejected: () =>
      handleOnRejected("invoiceCanceledError", validationErrors(), () => {
        resetState({ stateName: "CANCEL_INVOICE", isRequestState: true });
      }),
  });

  const handleBack = () => {
    history.push(LINKS.financial.subscriptions.root);
    cleanState();
  };

  const processItemsByCycles = (cycles) => {
    getSubscriptionItemsByCycles({ id, cycles });
  };

  const handleCharge = () => {
    resetChargeStates();
    toggleChargeModal();
  };

  return (
    <>
      <NavBar title={title} route={null}>
        <Button handleClick={handleBack} variant="outline">
          {translate("back")}
        </Button>
        <Button handleClick={handleCharge} disabled={!subscription?.hasPaymentForms}>
          {translate("Charge")}
        </Button>
        <Button handleClick={refreshAll}>{translate("Refresh")}</Button>
        <Button url={LINKS.financial.subscriptions.edit(id)}>{translate("edit")}</Button>
        <Button
          handleClick={handleBillSubscription}
          disabled={
            subscription?.billed ||
            !subscription?.active ||
            statusRequests.BILL_SUBSCRIPTION === REQUEST_PENDING
          }
        >
          {translate("Bill")}
        </Button>
      </NavBar>
      {statusRequests.BILL_SUBSCRIPTION === REQUEST_PENDING ? (
        <SpinnerOverlay>
          <ColumnFlex>
            <Spinner />
            <Span style={{ marginTop: "0.5rem" }}>Aguarde... Faturando assinatura</Span>
          </ColumnFlex>
        </SpinnerOverlay>
      ) : (
        <SubscriptionDetailsWithSpinner
          requestStatus={statusRequests.GET_FINANCIAL_SUBSCRIPTION}
          translate={translate}
          subscription={subscription}
          subscriptionItems={subscriptionItems}
          subscriptionInvoices={subscriptionInvoices}
          requestStatusItems={requestStatusItems}
          requestStatusInvoices={requestStatusInvoices}
          requestStatusInvoice={statusRequests.GET_FINANCIAL_SUBSCRIPTION_INVOICE}
          itemsParams={itemsParams}
          invoicesParams={invoicesParams}
          deleteSubscriptionItem={handleDeleteSubscriptionItem}
          refreshItems={refreshItems}
          refreshInvoices={refreshInvoices}
          onSubmitItems={handleSubmitItems}
          submitItemsRef={submitItemsRef}
          subscriptionItemsUseItems={subscriptionItemsUseItems}
          subscriptionInvoicesUseInvoices={subscriptionInvoicesUseInvoices}
          handleRemoveItems={handleDeleteSubscriptionItems}
          getSubscriptionInvoice={getSubscriptionInvoice}
          subscriptionInvoice={subscriptionInvoice}
          billSubscription={billSubscription}
          showConfirmBillSubscription={showConfirmBillSubscription}
          setShowConfirmBillSubscription={setShowConfirmBillSubscription}
          processItemsByCycles={processItemsByCycles}
          statusRequests={statusRequests}
          sendInvoiceMail={sendInvoiceMail}
          handleBillingsFrom={handleBillingsFrom}
          billingsFrom={billingsFrom}
          availableBillingPeriods={availableBillingPeriods}
          simulatedSubscription={simulatedSubscription}
          simulateSubscription={simulateSubscription}
          resetState={resetState}
          error={error}
        />
      )}
      <ChargeInvoice />
    </>
  );
};

export default SubscriptionDetailsContainer;
