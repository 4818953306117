import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { InvoiceActions, InvoiceTypes } from "store/ducks/invoice";
import {
  INVOICE_ISSUE,
  INVOICES_BY_ENTERPRISE,
  INVOICE_DETAILS,
  INVOICE_EXPORT,
  INVOICE_TYPES,
} from "utils/constants/endpoints";
import { GET, POST } from "utils/constants/verbs";

export function* invoiceIssue({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: INVOICE_ISSUE,
      data: payload,
    });
    yield put(InvoiceActions.invoiceIssueSuccess(data));
  } catch (error) {
    yield put(InvoiceActions.invoiceIssueFailure(error, type));
  }
}

function* fetchInvoices({ id, params, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: INVOICES_BY_ENTERPRISE(id),
        params,
      });
      yield put(InvoiceActions.fetchInvoicesSuccess(data));
    }
  } catch (error) {
    yield put(InvoiceActions.invoiceRequestFailure(error, type));
  }
}

function* fetchInvoiceDetails({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: INVOICE_DETAILS(id),
      });
      yield put(InvoiceActions.fetchInvoiceDetailsSuccess(data));
    }
  } catch (error) {
    yield put(InvoiceActions.invoiceRequestFailure(error, type));
  }
}

function* exportInvoices({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: INVOICE_EXPORT,
      data: payload,
    });
    const httpsUrl = {
      link: data.link ? data.link.replace("http:", "https:") : "",
    };
    yield put(InvoiceActions.exportInvoicesSuccess(httpsUrl));
  } catch (error) {
    yield put(InvoiceActions.invoiceRequestFailure(error, type));
  }
}

export function* getAllInvoicesTypes() {
  try {
    const { data } = yield api({
      method: GET,
      url: INVOICE_TYPES,
    });
    yield put(InvoiceActions.getAllInvoicesTypesSuccess(data));
  } catch (error) {
    yield put(InvoiceActions.enterpriseRequestFailure(error, "getAllInvoicesTypes"));
  }
}

export function* watchSagas() {
  yield takeLatest(InvoiceTypes.INVOICE_ISSUE, invoiceIssue);
  yield takeLatest(InvoiceTypes.FETCH_INVOICES, fetchInvoices);
  yield takeLatest(InvoiceTypes.FETCH_INVOICE_DETAILS, fetchInvoiceDetails);
  yield takeLatest(InvoiceTypes.EXPORT_INVOICES, exportInvoices);
  yield takeLatest(InvoiceTypes.GET_ALL_INVOICES_TYPES, getAllInvoicesTypes);
}
