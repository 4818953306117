import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Columns, Container } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import { Form, InputHidden } from "components/core/Form";
import { PaymentGatewaysActions } from "store/ducks/paymentGateways";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { REQUEST_PENDING, REQUEST_NOT_STARTED } from "utils/constants/request";

const PaymentGatewayEdit = ({
  defaultValues,
  validationSchema,
  handlePaymentGatewayEdit,
  submitRef,
}) => {
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });
  const selectOptions = (selectedValue) => {
    return [
      { text: translate("Yes"), value: true, selected: selectedValue === true },
      { text: translate("No"), value: false, selected: selectedValue === false },
    ];
  };
  const { paymentGateway, PaymentGatewaysRequestsStatus } = useSelector(
    ({ paymentGateways }) => paymentGateways,
  );

  useEffect(() => {
    dispatch(PaymentGatewaysActions.getPaymentGatewayById(id));
  }, [id, dispatch]);

  const requestPending = (status) => {
    return status === REQUEST_PENDING || status === REQUEST_NOT_STARTED;
  };

  return requestPending(PaymentGatewaysRequestsStatus.GET_PAYMENT_GATEWAY_BY_ID) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <Container maxWidth="md">
      <Form onSubmit={handleSubmit(handlePaymentGatewayEdit)}>
        <InputForm
          register={register}
          type="text"
          name="name"
          label={translate("Name")}
          errorMsg={errors.name?.message}
          value={paymentGateway.name}
        />
        <Columns columns="2">
          <div style={{ marginRight: "1rem" }}>
            <SelectForm
              register={register}
              name="active"
              label={`${translate("Active")}?`}
              options={selectOptions(paymentGateway.active)}
              errorMsg={errors.active?.message}
            />
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <SelectForm
              register={register}
              name="isDefault"
              label={`${translate("Default")}?`}
              options={selectOptions(paymentGateway.isDefault)}
              errorMsg={errors.isDefault?.message}
            />
          </div>
        </Columns>
        <InputHidden ref={submitRef} type="submit" value="Submit" />
      </Form>
    </Container>
  );
};

PaymentGatewayEdit.propTypes = {
  submitRef: PropTypes.object.isRequired,
  handlePaymentGatewayEdit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default PaymentGatewayEdit;
