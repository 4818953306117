import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LayoutActions } from "store/ducks/layout";
import { NotificationActions } from "store/ducks/notification";
import NavBar from "components/core/NavBar";
import WithSpinner, { isInProgress } from "components/core/WithSpinner";
import Presentation from "components/presentation/Notifications";
import useRequest from "hooks/useRequest";
import useParams from "hooks/useParams";
import useInfinitePagination from "hooks/useInfinitePagination";
import { routeByNotificationKey } from "utils/constants";

const PresentationWithSpinner = WithSpinner(Presentation);

const NotificationsContainer = () => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    notifications: { items, totalPages },
    lastCallByService,
    types,
    NotificationRequests,
  } = useSelector(({ notification }) => notification);
  const [notifications, setNotifications] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [routeToRedirect, setRouteToRedirect] = useState(null);
  const { page, setPage, dispatchWithParams } = useParams({
    defaultPage: 1,
    callback: (params) => {
      if (params.page === 1 && lastCallByService) return;

      dispatch(NotificationActions.fetchNotifications(params, false));
      setIsLoadingMore(params.page > 1);
    },
  });
  const lastItemRef = useInfinitePagination(
    NotificationRequests.FETCH_NOTIFICATION,
    hasMore,
    setPage,
  );

  useEffect(
    () => () => {
      dispatch(NotificationActions.clearState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (page === 1) {
      setNotifications(items);
    } else if (items?.length) {
      setNotifications((prevItems) => [...new Set([...prevItems, ...items])]);
    }
  }, [items, page]);

  useEffect(() => {
    setHasMore(page < totalPages);
  }, [page, totalPages]);

  const getTranslationKey = (idNotificationType) =>
    types?.find(({ id }) => id === idNotificationType)?.name ?? "";

  const handleDelete = (notificationId) => {
    dispatch(NotificationActions.deleteNotification(notificationId));
    setNotifications((prevItems) => prevItems.filter(({ id }) => id !== notificationId));
  };

  useRequest({
    request: NotificationRequests.DELETE_ALL_NOTIFICATIONS,
    onResolved: dispatchWithParams,
  });

  const handleMarkAsRead = (notificationId) => {
    dispatch(NotificationActions.updateNotification(notificationId));
  };

  const handlePinEmitter = ({ userId, idEnterprise: id, ...othersEntries }) => {
    const emitter = {
      ...othersEntries,
      user: { id: userId },
      id,
    };
    dispatch(LayoutActions.pinEmitter(emitter));
  };

  const handleClick = (notificationId, notificationKey, emitter) => {
    const newRouteToRedirect = routeByNotificationKey(notificationKey, emitter);

    handleMarkAsRead(notificationId);
    setRouteToRedirect(newRouteToRedirect);
  };

  const routeToRedirectCallback = useCallback(() => {
    if (routeToRedirect) {
      history.push(routeToRedirect);
    }
  }, [history, routeToRedirect]);

  useEffect(() => {
    routeToRedirectCallback();
  }, [routeToRedirectCallback]);

  useRequest({
    request: NotificationRequests.FETCH_NOTIFICATIONS,
    onResolved: () => setIsLoadingMore(false),
  });

  return (
    <>
      <NavBar title={translation("notification")} />
      <PresentationWithSpinner
        isLoading={isInProgress(NotificationRequests.FETCH_NOTIFICATIONS) && !isLoadingMore}
        notifications={notifications}
        getTranslationKey={getTranslationKey}
        handleDelete={handleDelete}
        handleClick={handleClick}
        handlePinEmitter={handlePinEmitter}
        lastNotificationRef={lastItemRef}
        isLoadingMore={isLoadingMore}
      />
    </>
  );
};

export default NotificationsContainer;
