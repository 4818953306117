import React from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";
import Button from "components/core/Button";
import { formatDate } from "helpers";
import { ReactComponent as IconPin } from "assets/icons/icon-pin.svg";
import * as S from "./styled";

const StatusColors = {
  read: "var(--color-gray-6)",
  unread: "var(--color-alert)",
  lead: "var(--color-primary)",
};

const getStatusColor = (read, translationKey) => {
  if (read) return StatusColors.read;

  return translationKey === "newLead" ? StatusColors.lead : StatusColors.unread;
};

const Notifications = ({
  notifications,
  getTranslationKey,
  handleDelete,
  handleClick,
  handlePinEmitter,
  lastNotificationRef,
  isLoadingMore,
}) => {
  const { t: translate } = useTranslation();

  return (
    <S.NotificationsWrapper>
      {notifications.map(
        (
          {
            id,
            read,
            payload: { enterpriseName, idEnterprise, cnpj, ...rest },
            idNotificationType,
            createdAt,
            sentBy,
          },
          index,
        ) => (
          <S.Notifications
            key={id}
            ref={notifications.length === index + 1 ? lastNotificationRef : null}
          >
            <S.Status color={getStatusColor(read, getTranslationKey(idNotificationType))} />
            <S.NotificationContent marginBottom="0">
              <S.Notification
                as="button"
                onClick={() =>
                  handleClick(id, getTranslationKey(idNotificationType), {
                    userId: sentBy,
                    idEnterprise,
                    enterpriseName,
                    cnpj,
                    ...rest,
                  })
                }
                title={translate("seeEmitter")}
              >
                <Trans
                  i18nKey={getTranslationKey(idNotificationType)}
                  values={{ name: enterpriseName }}
                  components={{ bold: <strong /> }}
                />
              </S.Notification>
              <span>{formatDate(createdAt, 0, true, true)}</span>
              <Button
                variant="text"
                size="small"
                handleClick={() =>
                  handlePinEmitter({
                    userId: sentBy,
                    idEnterprise,
                    enterpriseName,
                    cnpj,
                  })
                }
              >
                <IconPin />
                <span>{translate("pinEmitter")}</span>
              </Button>
            </S.NotificationContent>
            <S.DeleteNotification onClick={() => handleDelete(id)} title={translate("delete")} />
          </S.Notifications>
        ),
      )}
      <S.LoadingMore isLoading={isLoadingMore} />
    </S.NotificationsWrapper>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  getTranslationKey: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handlePinEmitter: PropTypes.func.isRequired,
  lastNotificationRef: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool,
};

Notifications.defaultProps = {
  notifications: [],
  isLoadingMore: false,
};

export default Notifications;
