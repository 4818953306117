import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`;

export const BoxText = styled.div`
  max-width: 500px;
  margin: 35px 0 24px;
`;
