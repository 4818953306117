import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";
import { useForm } from "react-hook-form";
import InputForm from "components/core/InputForm";
import { Subtitle, List, Span } from "components/core/Typography";
import { Container, FlexColumn } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import { validatePassword } from "utils/schemas/password";
import { MASK_TYPES } from "utils/masks";
import SelectForm from "components/core/SelectForm";
import { getStates, getCitiesByState } from "utils/mocks/brazilCitiesStates";
import * as S from "./styled";

const ProfileForm = ({
  validationSchema,
  defaultValues,
  handleEditProfile,
  submitRef,
  handleCpf,
  cpfRejected,
}) => {
  const { t: translate } = useTranslation();
  const [passwordValidations, setPasswordValidations] = useState([]);
  const [cities, setCities] = useState([]);
  const { register, handleSubmit, errors, watch } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });
  const watchPassword = watch("password");
  const watchState = watch("state");
  const states = getStates();

  useEffect(() => {
    setCities(getCitiesByState(watchState));
  }, [watchState]);

  useEffect(() => {
    validatePassword(watchPassword, (error) => setPasswordValidations(error));
  }, [watchPassword]);

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const errorCpf = () => {
    if (!cpfRejected) return errors.cpf?.message;
    if (cpfRejected) return <Trans i18nKey="required.cpfNotValid" />;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleEditProfile)}>
        <FlexColumn horizontalAlign="flex-start">
          <InputForm
            register={register}
            type="text"
            name="name"
            label="Nome"
            errorMsg={errors.name?.message}
          />
          <InputForm
            register={register}
            type="email"
            name="email"
            label="E-mail"
            errorMsg={errors.email?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="cpf"
            label="CPF"
            errorMsg={errorCpf()}
            onBlur={handleCpf}
            mask={MASK_TYPES.cpf}
          />
          <InputForm
            register={register}
            type="text"
            name="phoneNumber"
            label="Telefone"
            errorMsg={errors.phoneNumber?.message}
            mask={MASK_TYPES.phone}
          />
          <SelectForm
            register={register}
            name="state"
            label={translate("state")}
            options={states.map((state) => ({ text: state, value: state }))}
            errorMsg={errors.state?.message}
          />
          <SelectForm
            register={register}
            name="city"
            label={translate("city")}
            options={cities.map((city) => ({ text: city, value: city }))}
            errorMsg={errors.city?.message}
          />

          {defaultValues?.idRole === 2 && (
            <InputForm
              register={register}
              type="text"
              name="crc"
              label="CRC"
              errorMsg={errors.crc?.message}
            />
          )}
          <Subtitle>
            Alterar Senha
            <Span>(opcional)</Span>
          </Subtitle>
          <InputForm
            register={register}
            name="oldPassword"
            type={values.showPassword ? "text" : "password"}
            label="Senha Atual"
            autoComplete="current-password"
            errorMsg={errors.oldPassword?.message}
          >
            {values.showPassword ? (
              <S.IconPasswordShow onClick={handleClickShowPassword} />
            ) : (
              <S.IconPasswordHide onClick={handleClickShowPassword} />
            )}
          </InputForm>
          <InputForm
            register={register}
            name="password"
            type={values.showPassword ? "text" : "password"}
            label="Nova Senha"
            autoComplete="new-password"
            errorMsg={errors.password?.message}
          >
            {values.showPassword ? (
              <S.IconPasswordShow onClick={handleClickShowPassword} />
            ) : (
              <S.IconPasswordHide onClick={handleClickShowPassword} />
            )}
          </InputForm>
          <InputForm
            register={register}
            name="confirmPassword"
            type={values.showPassword ? "text" : "password"}
            label="Confirmar Senha"
            autoComplete="new-password"
            errorMsg={errors.confirmPassword?.message}
          >
            {values.showPassword ? (
              <S.IconPasswordShow onClick={handleClickShowPassword} />
            ) : (
              <S.IconPasswordHide onClick={handleClickShowPassword} />
            )}
          </InputForm>
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </FlexColumn>
      </Form>
      {!!passwordValidations.length && (
        <List>
          <li>
            <strong>A senha deve conter:</strong>
          </li>
          {passwordValidations.map((validation) => (
            <li key={validation}>{validation}</li>
          ))}
        </List>
      )}
    </>
  );
};

const ProfileEdit = ({
  handleEditProfile,
  defaultValues,
  validationSchema,
  submitRef,
  handleCpf,
  cpfRejected,
}) => (
  <Container maxWidth="md">
    <Subtitle>Edite as Informações</Subtitle>
    <ProfileForm
      defaultValues={{ ...defaultValues, password: "" }}
      validationSchema={validationSchema}
      handleEditProfile={handleEditProfile}
      submitRef={submitRef}
      handleCpf={handleCpf}
      cpfRejected={cpfRejected}
    />
  </Container>
);

ProfileForm.propTypes = {
  handleEditProfile: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  submitRef: PropTypes.object.isRequired,
  handleCpf: PropTypes.func.isRequired,
  cpfRejected: PropTypes.bool.isRequired,
};

ProfileEdit.propTypes = {
  handleEditProfile: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  submitRef: PropTypes.object.isRequired,
  handleCpf: PropTypes.func.isRequired,
  cpfRejected: PropTypes.bool.isRequired,
};

export default ProfileEdit;
