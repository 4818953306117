import React from "react";
import PropTypes from "prop-types";
import * as S from "./styled";

const Label = ({ errorMsg, label, name, labelDark, marginBottom, color }) => (
  <S.Label
    htmlFor={name}
    hasError={!!errorMsg}
    labelDark={labelDark}
    marginBottom={marginBottom}
    color={color}
  >
    {label}
  </S.Label>
);

Label.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  labelDark: PropTypes.bool,
  marginBottom: PropTypes.string,
};

Label.defaultProps = {
  errorMsg: null,
  labelDark: false,
};

export default Label;
