import React from "react";
import PropTypes from "prop-types";
import Button from "components/core/Button";
import { Flex, FlexColumn, ColumnFlex } from "components/core/Grid";
import { Subtitle, Span, TertiaryTitle } from "components/core/Typography";
import CardInfo from "components/core/CardInfo";
import Feedback from "components/core/Feedback";
import RadioButton from "components/core/RadioButton";
import { Table, TableBody, TableItem } from "components/core/Table";
import Label from "components/core/Label";
import { Spinner } from "components/core/WithSpinner/styled";

const Payment = ({
  copied,
  enablePayButton,
  feedback,
  enterprise,
  handleBack,
  handleClick,
  handleClickData,
  handleClipboard,
  handleDownload,
  paymentForm,
  paymentMethod,
  setPaymentMethod,
  showBilletForm,
  showConfirmData,
  showFeedback,
  showPaymentForm,
  showPixForm,
  isDownloading,
}) => (
  <>
    {showFeedback && (
      <Feedback
        title={feedback?.title}
        description={feedback?.description}
        isSuccess={feedback?.isSuccess}
        btnText={feedback?.btnText}
        emphasis={feedback?.emphasis}
        paymentFlag={feedback?.paymentFlag}
        handleClick={feedback?.handleClick}
        buttons={feedback?.buttons}
      />
    )}
    {showBilletForm && (
      <>
        <FlexColumn horizontalAlign="center" verticalAlign="center">
          <Subtitle>Pagamento via Boleto Bancário</Subtitle>
          <Span>
            Leia o código de barras usando seu aplicativo de banco ou conta digital, ou copie o
            código da linha digitável abaixo e cole em seu aplicativo de banco ou conta digital,
            para efetuar o pagamento.
          </Span>
          <img
            src={paymentForm?.barcode}
            alt="invoice barcode"
            style={{ marginTop: "3rem", marginBottom: "3rem" }}
          />
          <div
            style={{
              border: "solid 1px #CCCCCC",
              borderRadius: "0.5rem",
              margin: "1rem 0 1rem 0",
              padding: "1rem",
              width: "100%",
            }}
          >
            <div>
              <Span
                style={{ fontSize: "12px" }}
                onClick={() => handleClipboard(paymentForm?.digitableLine)}
              >
                {paymentForm?.digitableLine}
              </Span>
            </div>
            <div>
              <Button
                handleClick={() => handleClipboard(paymentForm?.digitableLine)}
                disabled={!paymentForm}
                style={{ marginTop: "2rem", width: "100%" }}
              >
                Copiar linha digitável
              </Button>
            </div>
            {copied && (
              <div
                style={{
                  marginTop: "0.5rem",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Label label="Código copiado com sucesso!" color="#0071ee" />
              </div>
            )}
          </div>
          <ColumnFlex style={{ marginTop: "4rem" }}>
            <Button handleClick={handleDownload} style={{ marginRight: "0", width: "100%" }}>
              {isDownloading && <Spinner height="3rem" width="3rem" marginRight="1rem" />}
              Baixar fatura
            </Button>
            {isDownloading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "0.5rem",
                }}
              >
                <Label label="Aguarde! Iniciando download do arquivo" color="#0071ee" />
              </div>
            )}
            <Button
              handleClick={() => window.open(paymentForm?.invoiceUrl)}
              style={{ marginTop: "0.5rem", marginRight: "0", width: "100%" }}
            >
              Visualizar fatura
            </Button>
            <Button
              variant="outline"
              handleClick={handleBack}
              style={{ marginTop: "2rem", marginRight: "0", width: "100%" }}
            >
              Voltar
            </Button>
          </ColumnFlex>
        </FlexColumn>
      </>
    )}
    {showPixForm && (
      <>
        <FlexColumn horizontalAlign="center" marginTop="0">
          <Subtitle>Pagamento via Pix</Subtitle>
          <Span>
            Procure em seu aplicativo de banco ou conta digital a funcionalidade e escaneie o QR
            Code abaixo ou copie o código usando o Pix Copia e Cola para efetuar o pagamento.
          </Span>
          <img
            src={paymentForm?.qrcode}
            alt="invoice qrcode"
            width={225}
            style={{ marginTop: "1rem" }}
          />
          <div
            style={{
              border: "solid 1px #CCCCCC",
              borderRadius: "0.5rem",
              margin: "1rem 1rem 2rem 1rem",
              padding: "1rem",
            }}
          >
            <div>
              <Span
                style={{ fontSize: "12px" }}
                onClick={() => handleClipboard(paymentForm?.qrcodeText)}
              >
                {paymentForm?.qrcodeText}
              </Span>
            </div>
            <div>
              <Button
                handleClick={() => handleClipboard(paymentForm?.qrcodeText)}
                disabled={!paymentForm}
                style={{ marginTop: "2rem", width: "100%" }}
              >
                Pix Copia e Cola - Copiar código
              </Button>
            </div>
            {copied && (
              <div
                style={{
                  marginTop: "0.5rem",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Label label="Código copiado com sucesso!" color="#0071ee" />
              </div>
            )}
          </div>
        </FlexColumn>
        <Flex horizontalAlign="center">
          <Button handleClick={handleBack}>Voltar</Button>
        </Flex>
      </>
    )}
    {showConfirmData && (
      <>
        <Flex marginBottom="2.8rem">
          <Subtitle>Confira seus dados</Subtitle>
          <CardInfo label="Nome da Empresa" info={enterprise?.enterpriseName} />
          <CardInfo label="CNPJ" info={enterprise?.cnpj} />
          <CardInfo label="E-mail" info={enterprise?.user?.email} />
          <CardInfo label="Telefone" info={enterprise?.user?.phoneNumber} />
        </Flex>
        <Flex horizontalAlign="center">
          <Button handleClick={handleClickData} disabled={!enablePayButton} width="100%">
            Confirmar dados
          </Button>
        </Flex>
      </>
    )}
    {showPaymentForm && (
      <>
        <Flex>
          <Subtitle mb="2.5rem">Forma de pagamento</Subtitle>
          <Span style={{ marginBottom: "4rem" }}>
            O seu plano só será ativado após a confirmação do pagamento.
          </Span>
          <Table>
            <TableBody>
              {enterprise?.subscription[0]?.coupon?.couponType !== "P" && (
                <>
                  <tr>
                    <TableItem textAlign="center">
                      <RadioButton
                        name="idPaymentMethod"
                        label="Pix"
                        id="pix"
                        value={3}
                        checked={paymentMethod === "3"}
                        onClick={(el) => setPaymentMethod(el.target.value)}
                        onChange={(el) => setPaymentMethod(el.target.value)}
                      />
                    </TableItem>
                  </tr>
                  <tr>
                    <TableItem textAlign="center">
                      <RadioButton
                        name="idPaymentMethod"
                        label="Boleto Bancário"
                        id="bankSlip"
                        value={1}
                        checked={paymentMethod === "1"}
                        onClick={(el) => setPaymentMethod(el.target.value)}
                        onChange={(el) => setPaymentMethod(el.target.value)}
                      />
                    </TableItem>
                  </tr>
                </>
              )}
              <tr>
                <TableItem textAlign="center">
                  <RadioButton
                    name="idPaymentMethod"
                    label="Cartão de Crédito"
                    id="creditCard"
                    value={2}
                    checked={paymentMethod === "2"}
                    onClick={(el) => setPaymentMethod(el.target.value)}
                    onChange={(el) => setPaymentMethod(el.target.value)}
                  />
                </TableItem>
              </tr>
            </TableBody>
          </Table>
          <>
            <TertiaryTitle marginTop="3rem" marginBottom="1rem">
              Valor do plano = R$&nbsp;
              {String(
                parseFloat(
                  parseFloat(enterprise?.subscription[0]?.price) -
                    (enterprise?.subscription[0]?.paymentMethod.idPaymentMethod === 1
                      ? parseFloat(enterprise?.billetCostTax.price)
                      : 0),
                ).toFixed(2),
              ).replace(".", ",")}
            </TertiaryTitle>
            {paymentMethod === "1" && (
              <>
                <TertiaryTitle marginBottom="1rem">
                  Acréscimo boleto = R$&nbsp;
                  {String(parseFloat(enterprise?.billetCostTax.price).toFixed(2)).replace(".", ",")}
                </TertiaryTitle>
                <Span>Valor será acrescido em cada parcela</Span>
              </>
            )}
            <TertiaryTitle marginTop="3rem">
              Valor Total = R$&nbsp;
              {String(
                (
                  parseFloat(
                    parseFloat(enterprise?.subscription[0]?.price) -
                      (enterprise?.subscription[0]?.paymentMethod.idPaymentMethod === 1
                        ? parseFloat(enterprise?.billetCostTax.price)
                        : 0),
                  ) + (paymentMethod === "1" ? parseFloat(enterprise?.billetCostTax.price) : 0)
                ).toFixed(2),
              ).replace(".", ",")}
            </TertiaryTitle>
          </>
          <Button
            handleClick={handleClick}
            disabled={!paymentMethod}
            width="100%"
            style={{ marginTop: "2rem" }}
          >
            Efetuar Pagamento
          </Button>
        </Flex>
      </>
    )}
  </>
);

Payment.propTypes = {
  enterprise: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClickData: PropTypes.func.isRequired,
  enablePayButton: PropTypes.bool.isRequired,
  showPaymentForm: PropTypes.bool.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  feedback: PropTypes.object,
};

Payment.defaultProps = {
  feedback: null,
};

export default Payment;
