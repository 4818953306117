import React from "react";
import PropTypes from "prop-types";
import { Label } from "components/core/InputForm/styled";
import * as S from "./styled";

const SelectedItemsDisplay = ({
  label,
  selectedItems,
  prevSelected,
  keyValue,
  keyText,
  handleChange,
}) => {
  return (
    <>
      <Label as="span">{label}</Label>
      <S.SelectedItemsDisplay>
        {selectedItems?.map((item) => (
          <S.SelectedItem key={item[keyValue]}>
            <S.RemoveSelectedItem onClick={() => handleChange(item)} />
            <span>{item[keyText]}</span>
          </S.SelectedItem>
        ))}
      </S.SelectedItemsDisplay>
    </>
  );
};

SelectedItemsDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  keyValue: PropTypes.string,
  keyText: PropTypes.string,
};

SelectedItemsDisplay.defaultProps = {
  keyValue: "id",
  keyText: "name",
};

export default SelectedItemsDisplay;
