import styled, { css } from "styled-components";
import { TertiaryTitle } from "components/core/Typography";
import { scrollbarStyle } from "components/core/Layout";
import { InputSearch } from "components/core/EmitterSearchSidebar/styled";

export const ConversationsList = styled.div`
  border-right: 1px solid var(--color-white-2);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${scrollbarStyle}
`;

const activeStyle = css`
  background-color: var(--color-white-7);
  border-left-color: var(--color-secondary);
`;

export const Conversation = styled.div`
  border-bottom: 1px solid var(--color-white-2);
  border-left: 2px solid var(--color-white-1);
  cursor: pointer;
  padding: 0.8rem 1.2rem;
  display: grid;
  align-items: center;
  grid-template-columns: 0.8rem 1fr 4rem;
  grid-template-areas:
    "status username date"
    "last last count";
  gap: 0.8rem;
  height: 7.5rem;
  transition: all 0.3s linear;

  &:hover {
    ${activeStyle}
  }

  ${({ active }) => active && activeStyle}

  &:first-child {
    border-top: 1px solid var(--color-gray-6);
  }
`;

export const Status = styled.span`
  grid-area: status;
  background-color: ${({ color }) => color ?? "var(--color-gray-1)"};
  border-radius: 50%;
  height: 0.8rem;
  width: 0.8rem;
`;

export const Username = styled(TertiaryTitle)`
  grid-area: username;
  font-size: var(--font-size-default);
  font-weight: 600;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Date = styled.span`
  grid-area: date;
  color: var(--color-gray-6);
  text-align: center;
`;

export const LastMessage = styled.span`
  grid-area: last;
  color: var(--color-black-4);
  font-size: 1.2rem;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
`;

export const Count = styled.div`
  grid-area: count;
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;

  & > strong {
    background-color: red;
    border-radius: 3.2rem;
    color: var(--color-white-1);
    font-size: 1.2rem;
    font-weight: 600;
    height: 1.6rem;
    min-width: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SearchChat = styled(InputSearch)`
  border-bottom: 1px solid var(--color-gray-5);
`;

export const IconEmptyWrapper = styled(ConversationsList)`
  border-right: none;
  height: 30%;
  min-height: 30rem;
  justify-content: center;
  align-items: center;

  & > svg {
    margin-bottom: 2.4rem;
  }
`;

export const ChatNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: none;
  border-right: 1px solid var(--color-white-2);
  left: 30rem;
  width: 30rem;
  padding: 2.4rem 1.4rem 1.3rem;
  flex-wrap: wrap;

  & > h1 {
    margin-left: 2rem;
  }
`;

export const SearchWrapper = styled.div`
  margin-top: 1.6rem;
  width: 100%;
`;

export const ChatsWrapper = styled.div`
  display: grid;
  grid-template-rows: 13rem 1fr;
  height: 100vh;
`;
