import { createActions, createReducer } from "reduxsauce";
import { isAuthenticated, getAuthClaims } from "helpers/auth";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types, Creators } = createActions({
  authRequest: ["payload"],
  authSuccess: ["payload"],
  authFailure: ["error"],
  authLogout: [],
  handleUnauthorized: [],
  refreshToken: ["payload"],
});

const INITIAL_STATE = {
  isAuthenticated: isAuthenticated(),
  claims: getAuthClaims(),
  authRequest: REQUEST_NOT_STARTED,
};

const authRequest = () => ({ ...INITIAL_STATE, authRequest: REQUEST_PENDING });

const authSuccess = (state, { payload }) => ({
  ...state,
  claims: payload,
  isAuthenticated: !!payload,
  authRequest: REQUEST_RESOLVED,
});

const authFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  authRequest: REQUEST_REJECTED,
});

const authLogout = () => ({
  ...INITIAL_STATE,
  claims: null,
  isAuthenticated: false,
});

const handleUnauthorized = (state) => ({ ...state });

const refreshToken = (state, { payload }) => ({
  ...state,
  claims: {
    ...state.claims,
    ...payload,
  },
  isAuthenticated: true,
});

export default createReducer(INITIAL_STATE, {
  [Types.AUTH_REQUEST]: authRequest,
  [Types.AUTH_SUCCESS]: authSuccess,
  [Types.AUTH_FAILURE]: authFailure,
  [Types.AUTH_FAILURE]: authFailure,
  [Types.AUTH_LOGOUT]: authLogout,
  [Types.HANDLE_UNAUTHORIZED]: handleUnauthorized,
  [Types.REFRESH_TOKEN]: refreshToken,
});
