import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UsersActions } from "store/ducks/users";
import { Creators as AuthActions } from "store/ducks/auth";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import MyProfile from "components/presentation/Profile/MyProfile";
import useClaims from "hooks/useClaims";
import { urls } from "utils/constants";

const MyProfileWithSpinner = WithSpinner(MyProfile);

const MyProfileContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const {
    user: { id },
  } = useClaims();
  const { userDetails, UsersRequests } = useSelector(({ users }) => users);

  const logout = () => dispatch(AuthActions.authLogout());

  useEffect(() => {
    dispatch(UsersActions.getUserById(id));
  }, [id]);

  return (
    <>
      <NavBar title={translate("myProfile")}>
        <Button handleClick={logout} variant="outline">
          {translate("exit")}
        </Button>
        <Button url={urls.LINKS.profileEdit}>{translate("edit")}</Button>
      </NavBar>
      <MyProfileWithSpinner
        requestStatus={UsersRequests.getUserById}
        user={userDetails}
        handleLogout={logout}
      />
    </>
  );
};

export default MyProfileContainer;
