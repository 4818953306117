import { put, takeLatest, call } from "redux-saga/effects";
import api from "services/api";
import { GET, POST, PATCH, PUT } from "utils/constants/verbs";
import { USERS, USER_BY_ID, USERS_STATUS } from "utils/constants/endpoints";
import { UsersTypes, UsersActions } from "store/ducks/users";

export function* usersRequestFailure(error, requestName) {
  yield put(UsersActions.usersRequestFailure(error, requestName));
}

export function* getUsers({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: USERS,
      params,
    });
    yield put(UsersActions.getUsersSuccess(data));
  } catch (error) {
    yield call(usersRequestFailure, error, "getUsers");
  }
}

export function* getUserById({ id }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: USER_BY_ID(id),
      });
      yield put(UsersActions.getUserByIdSuccess(data));
    }
  } catch (error) {
    yield call(usersRequestFailure, error, "getUserById");
  }
}

export function* createUser({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: USERS,
      data: payload,
    });
    yield put(UsersActions.createUserSuccess(data));
  } catch (error) {
    yield call(usersRequestFailure, error, "createUser");
  }
}

export function* editUser({ id, payload }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PUT,
        url: USER_BY_ID(id),
        data: payload,
      });

      yield put(UsersActions.editUserSuccess(data));
    }
  } catch (error) {
    yield call(usersRequestFailure, error, "editUser");
  }
}

export function* editUsersStatus({ payload }) {
  try {
    const { data } = yield api({
      method: PATCH,
      url: USERS_STATUS,
      data: payload,
    });

    yield put(UsersActions.editUsersStatusSuccess(data));
  } catch (error) {
    yield call(usersRequestFailure, error, "editUsersStatus");
  }
}

export function* watchSagas() {
  yield takeLatest(UsersTypes.GET_USERS, getUsers);
  yield takeLatest(UsersTypes.GET_USER_BY_ID, getUserById);
  yield takeLatest(UsersTypes.CREATE_USER, createUser);
  yield takeLatest(UsersTypes.EDIT_USER, editUser);
  yield takeLatest(UsersTypes.EDIT_USERS_STATUS, editUsersStatus);
}
