import React from "react";
import * as yup from "yup";
import { Trans } from "react-i18next";

export const validationSchema = yup.object().shape({
  name: yup.string().required(<Trans i18nKey="required.name" />),
  phoneNumber: yup.string().required(<Trans i18nKey="required.phone" />),
  cpf: yup
    .string()
    .min(14, <Trans i18nKey="short.cpf" />)
    .required(<Trans i18nKey="required.cpf" />),
  email: yup
    .string()
    .email(<Trans i18nKey="invalid.email" />)
    .required(<Trans i18nKey="required.email" />),
  password: yup
    .string()
    .min(6, <Trans i18nKey="invalid.password" />)
    .required(<Trans i18nKey="required.password" />),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], <Trans i18nKey="invalid.confirmPassword" />),
});
