import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";
import { transformEmptyToUndefined } from "helpers";

export const userSchema = Yup.object().shape({
  name: Yup.string()
    .min(6, <Trans i18nKey="short.name" />)
    .required(<Trans i18nKey="required.name" />),
  cpf: Yup.string()
    .min(14, <Trans i18nKey="short.cpf" />)
    .required(<Trans i18nKey="required.cpf" />),
  email: Yup.string()
    .email(<Trans i18nKey="invalid.email" />)
    .required(<Trans i18nKey="required.email" />),
  phoneNumber: Yup.string()
    .min(14, <Trans i18nKey="short.phone" />)
    .required(<Trans i18nKey="required.phone" />),
  idRole: Yup.string().required(<Trans i18nKey="required.profileType" />),
  active: Yup.bool().required(<Trans i18nKey="required.userStatus" />),
  state: Yup.string().required(<Trans i18nKey="required.state" />),
  city: Yup.string().required(<Trans i18nKey="required.city" />),
  crc: Yup.string()
    .transform(transformEmptyToUndefined)
    .when("idRole", {
      is: (value) => Number(value) === 2,
      then: Yup.string()
        .min(6, <Trans i18nKey="short.crc" />)
        .required(<Trans i18nKey="required.crc" />),
    }),
});

export const userInitialValues = {
  name: "",
  email: "",
  cpf: "",
  phoneNumber: "",
  state: "AC",
  city: "",
  idRole: 1,
  crc: null,
  active: true,
  password: "",
};
