import { put, takeLatest, call } from "redux-saga/effects";
import api from "services/api";
import { BatchesTypes, BatchesActions } from "store/ducks/batches";
import {
  GET,
  DELETE,
  POST,
  PATCH,
  BATCHES,
  BATCHES_DETAILS,
  BATCHES_ENTERPRISES,
  BATCHES_REISSUE,
  BATCHES_EXPORTS,
  BATCHES_CANCEL,
  INVOICE_ISSUE,
} from "utils/constants";

export function* batchesRequestFailure(error, requestName) {
  yield put(BatchesActions.batchesRequestFailure(error, requestName));
}

export function* getBatches({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: BATCHES,
      params,
    });
    yield put(BatchesActions.getBatchesSuccess(data));
  } catch (error) {
    yield call(batchesRequestFailure, error, "getBatches");
  }
}

export function* getBatchesCompany({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: INVOICE_ISSUE,
      params,
    });
    yield put(BatchesActions.getBatchesCompanySuccess(data));
  } catch (error) {
    yield call(batchesRequestFailure, error, "getBatchesCompany");
  }
}

export function* cancelBatches({ params }) {
  try {
    yield api({
      method: PATCH,
      url: BATCHES_CANCEL(params),
    });
    yield put(BatchesActions.cancelBatchesSuccess());
  } catch (error) {
    yield call(batchesRequestFailure, error, "cancelBatches");
  }
}

export function* getBatchesDetails({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: BATCHES_DETAILS(params),
    });
    yield put(BatchesActions.getBatchesSuccess(data));
  } catch (error) {
    yield call(batchesRequestFailure, error, "getBatchesDetails");
  }
}

export function* getBatchesEnterprises({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: BATCHES_ENTERPRISES(params),
    });
    yield put(BatchesActions.getBatchesEnterprisesSuccess(data));
  } catch (error) {
    yield call(batchesRequestFailure, error, "getBatchesEnterprises");
  }
}

export function* patchBatchesReissue({ params, payload }) {
  try {
    const { data } = yield api({
      method: PATCH,
      url: BATCHES_REISSUE(params),
      data: payload,
    });
    yield put(BatchesActions.getBatchesReissueSuccess(data));
  } catch (error) {
    yield call(batchesRequestFailure, error, "patchBatchesReissue");
  }
}

export function* addBatches({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: BATCHES,
      data: payload,
    });
    yield put(BatchesActions.addBatchesSuccess(data));
  } catch (error) {
    yield call(batchesRequestFailure, error, "addBatches");
  }
}

export function* exportBatches({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: BATCHES_EXPORTS,
      data: payload,
    });
    yield put(BatchesActions.exportBatchSuccess(data?.link));
  } catch (error) {
    yield call(batchesRequestFailure, error, "exportBatches");
  }
}

function* deleteBatches({ payload, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: BATCHES,
      data: payload,
    });
    yield put(BatchesActions.deleteBatchesSuccess(data));
  } catch (error) {
    yield put(BatchesActions.batchesRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(BatchesTypes.GET_BATCHES, getBatches);
  yield takeLatest(BatchesTypes.ADD_BATCHES, addBatches);
  yield takeLatest(BatchesTypes.DELETE_BATCHES, deleteBatches);
  yield takeLatest(BatchesTypes.GET_BATCHES_COMPANY, getBatchesCompany);
  yield takeLatest(BatchesTypes.GET_BATCHES_DETAILS, getBatchesDetails);
  yield takeLatest(BatchesTypes.GET_BATCHES_ENTERPRISES, getBatchesEnterprises);
  yield takeLatest(BatchesTypes.PATCH_BATCHES_REISSUE, patchBatchesReissue);
  yield takeLatest(BatchesTypes.EXPORT_BATCHES, exportBatches);
  yield takeLatest(BatchesTypes.CANCEL_BATCHES, cancelBatches);
}
