import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { CnpjCheckerTypes, CnpjCheckerActions } from "store/ducks/cnpjChecker";
import { GET } from "utils/constants";
import { CNPJ_CHECKER } from "utils/constants/endpoints";

function* getCnpjInfo({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: CNPJ_CHECKER(params.cnpj),
    });
    yield put(CnpjCheckerActions.checkCnpjSuccess(data));
  } catch (error) {
    yield put(CnpjCheckerActions.checkCnpjFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(CnpjCheckerTypes.CHECK_CNPJ, getCnpjInfo);
}
