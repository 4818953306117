import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Subtitle, Paragraph } from "components/core/Typography";
import { Container, Flex, Row, IconWrapper } from "components/core/Grid";
import Button from "components/core/Button";
import { ReactComponent as IconUser } from "assets/icons/icon-user.svg";
import { ReactComponent as IconBriefcase } from "assets/icons/icon-briefcase.svg";
import { ReactComponent as IconCnpj } from "assets/icons/icon-cnpj.svg";
import { ReactComponent as IconCoin } from "assets/icons/icon-coin.svg";
import { ReactComponent as IconCompass } from "assets/icons/icon-compass.svg";
import { ReactComponent as IconUsers } from "assets/icons/icon-users.svg";
import { formatNumberToReal } from "helpers/format";
import * as S from "./styled";

export const ConfirmData = ({
  enterpriseName,
  textEnterpriseActivity,
  city,
  state,
  idBillingRange,
  numberEmployees,
  cnpj,
  name,
  email,
  phoneNumber,
  onBack,
  setStep,
  billingRanges,
  billing,
  setIsSubsPlans,
  invoiceTypes,
  enterpriseType,
}) => {
  const [billingRange, setBillingRange] = useState([]);
  useEffect(() => {
    const id = parseInt(idBillingRange, 10); // mudar de idRange para id
    setBillingRange(billingRanges?.filter((billingParams) => billingParams.id === id));
  }, [billingRanges?.filter, idBillingRange, invoiceTypes, enterpriseType]);

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="82%" />
        </Container>
        <Subtitle mb="64px">Confirme seus dados</Subtitle>
        <Flex verticalAlign="flex-start">
          <Row>
            <IconWrapper>
              <IconUser />
            </IconWrapper>
            <Paragraph>{enterpriseName || "Não informado"}</Paragraph>
          </Row>
          <Row>
            <IconWrapper>
              <IconBriefcase />
            </IconWrapper>
            <Paragraph>{textEnterpriseActivity || "Não informado"}</Paragraph>
          </Row>
          <Row>
            <IconCompass />
            <Paragraph>{`${city}, ${state}`}</Paragraph>
          </Row>
          <Row>
            <IconWrapper>
              <IconCoin />
            </IconWrapper>
            {billingRanges.length !== 0 ? (
              <Paragraph>
                {idBillingRange !== "0"
                  ? `Faturamento: ${formatNumberToReal(
                      billingRange[0]?.billingMin,
                    )} à ${formatNumberToReal(billingRange[0]?.billingMax)}`
                  : "Não Sei"}
              </Paragraph>
            ) : (
              <Paragraph>
                {billing && billing !== "0" ? `Faturamento: R$${billing}` : "Não Sei"}
              </Paragraph>
            )}
          </Row>
          <Row>
            <IconWrapper>
              <IconUsers />
            </IconWrapper>
            <Paragraph>{`${
              numberEmployees !== null ? numberEmployees : ""
            } Funcionário(s)`}</Paragraph>
          </Row>
          {cnpj !== "0" && (
            <Row>
              <IconWrapper>
                <IconCnpj />
              </IconWrapper>
              <Paragraph>{cnpj}</Paragraph>
            </Row>
          )}
        </Flex>
        <Flex>
          <Paragraph>
            <strong>{name}</strong>
          </Paragraph>
          <Paragraph>{email}</Paragraph>
          <Paragraph>{phoneNumber}</Paragraph>
        </Flex>
        <Row horizontalAlign="center">
          <Button handleClick={onBack} variant="outline">
            Voltar
          </Button>
          <Button
            handleClick={() => {
              setStep((oldStep) => oldStep + 1);
              setIsSubsPlans(true);
            }}
          >
            Continuar
          </Button>
        </Row>
      </Flex>
    </S.RegisterContainer>
  );
};

ConfirmData.propTypes = {
  enterpriseName: PropTypes.string,
  textEnterpriseActivity: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  idBillingRange: PropTypes.string,
  numberEmployees: PropTypes.string,
  cnpj: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  billingRanges: PropTypes.array,
  billing: PropTypes.string,
  setIsSubsPlans: PropTypes.func.isRequired,
};

ConfirmData.defaultProps = {
  enterpriseName: "",
  textEnterpriseActivity: "",
  city: "",
  state: "",
  idBillingRange: "0",
  numberEmployees: "0",
  cnpj: "0",
  name: "",
  email: "",
  phoneNumber: "",
  billing: "",
  billingRanges: [],
};

export default ConfirmData;
