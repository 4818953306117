import { useEffect, useState, memo } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AddressActions } from "store/ducks/address";

const FormCEPObserver = ({ zipCode, newZipCode }) => {
  const dispatch = useDispatch();
  const [lastCEP, setLastCEP] = useState(zipCode);
  const { setValue } = useFormContext();
  const { address } = useSelector((state) => state.address);

  useEffect(() => {
    if (address) {
      const { city: newCity, state, street, neighborhood } = address;

      setValue("city", newCity);
      setValue("state", state);
      setValue("street", street);
      setValue("neighborhood", neighborhood);
    }

    return () => {
      dispatch(AddressActions.cleanAddress());
    };
  }, [address, dispatch, setValue]);

  useEffect(() => {
    if (newZipCode?.length === 10 && newZipCode !== lastCEP) {
      dispatch(AddressActions.getAddressByCEP(newZipCode.replace(".", "")));
      setLastCEP(newZipCode);
    }
  }, [dispatch, lastCEP, setLastCEP, newZipCode]);

  return null;
};

export default memo(FormCEPObserver);
