import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "components/core/Button";
import Portal from "components/core/Portal";
import * as S from "./styled";

const Modal = ({
  children,
  title,
  showModal,
  onCloseModal,
  onConfirmModal,
  confirmLabel,
  cancelLabel,
  hideConfirm,
  hideCancel,
  width,
  textAlign,
  showActions,
  showTitle,
  isSmallerModal,
  disableConfirm,
  closeOnConfirm,
  zIndex,
  minHeight,
  padding,
}) => {
  const [active, setActive] = useState(showModal);

  useEffect(() => {
    setActive(showModal);
  }, [showModal]);

  const handleCancel = () => {
    setActive(false);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const handleConfirm = () => {
    if (closeOnConfirm) {
      setActive(false);
      if (onCloseModal) {
        onCloseModal();
      }
    }
    if (onConfirmModal) {
      onConfirmModal();
    }
  };

  return (
    <Portal>
      <S.ModalWrapper active={active} zIndex={zIndex}>
        <S.Modal
          width={width}
          minHeight={minHeight}
          padding={padding}
          isSmallerModal={isSmallerModal}
        >
          {showTitle && <S.ModalTitle>{title}</S.ModalTitle>}
          <S.ModalContent textAlign={textAlign}>{children}</S.ModalContent>
          {showActions && (
            <S.ModalActions>
              {!hideCancel && (
                <Button variant="outline" handleClick={handleCancel}>
                  {cancelLabel}
                </Button>
              )}
              {!hideConfirm && (
                <Button handleClick={handleConfirm} disabled={disableConfirm}>
                  {confirmLabel}
                </Button>
              )}
            </S.ModalActions>
          )}
        </S.Modal>
      </S.ModalWrapper>
    </Portal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  isSmallerModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onConfirmModal: PropTypes.func,
  confirmLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelLabel: PropTypes.string,
  disableConfirm: PropTypes.bool,
  hideConfirm: PropTypes.bool,
  hideCancel: PropTypes.bool,
  width: PropTypes.string,
  textAlign: PropTypes.string,
  showActions: PropTypes.bool,
  showTitle: PropTypes.bool,
  closeOnConfirm: PropTypes.bool,
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  onConfirmModal: null,
  confirmLabel: "Confirmar",
  cancelLabel: "Cancelar",
  onCloseModal: null,
  disableConfirm: false,
  hideConfirm: false,
  hideCancel: false,
  width: null,
  textAlign: null,
  showActions: true,
  showTitle: true,
  closeOnConfirm: true,
  isSmallerModal: false,
  zIndex: 999,
};

export { Modal };
export default Modal;
