import Cards from "react-credit-cards";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "components/core/Button";
import Portal from "components/core/Portal";
import SelectForm from "components/core/SelectForm";
import { Columns, RowFlex } from "components/core/Grid";
import { MASK_TYPES, format } from "utils/masks";
import {
  formatCardNumber,
  formatExpiryDate,
  getBrandByNumber,
  getOnlyNumbers,
} from "utils/creditCard";
import { Form } from "../Form";
import InputForm from "../InputForm";
import * as S from "./styled";
import "react-credit-cards/es/styles-compiled.css";

const ModalCreditCard = ({
  showModal,
  onCloseModal,
  onConfirmModal,
  confirmLabel,
  cancelLabel,
  hideConfirm,
  hideCancel,
  width,
  textAlign,
  isSmallerModal,
  enterprise,
  resetForm,
  openingTax,
  paymentMethod,
  maxInstallments,
  totalPrice,
  installments,
  openTaxInstallments,
  selectedNewPlan,
}) => {
  const [active, setActive] = useState(showModal);
  const [ccNumber, setCCNumber] = useState("");
  const [ccFullName, setCCFullName] = useState("");
  const [ccCvc, setCCCvc] = useState("");
  const [ccExpiration, setCCExpiration] = useState("");
  const [ccFocus, setCCFocus] = useState("");
  const [ccBrand, setCCBrand] = useState("");
  const [hasOpeningTax, setHasOpeningTax] = useState(false);
  const [subscriptionInstallmentsOptions, setSubscriptionInstallmentsOptions] = useState([]);
  const [subscriptionInstallments, setSubscriptionInstallments] = useState(1);
  const [openingTaxInstallments, setOpeningTaxInstallments] = useState(1);
  const [openTaxInstallmentOptions, setOpenTaxInstallmentOptions] = useState([]);
  const [installmentSetting, setInstallmentSetting] = useState(null);
  const [showInterest, setShowInterest] = useState(null);
  const [btnLabel, setBtnLabel] = useState(null);

  useEffect(() => {
    setBtnLabel(confirmLabel);
    setActive(showModal);

    if (resetForm) {
      setCCNumber("");
      setCCFullName("");
      setCCCvc("");
      setCCExpiration("");
      setCCFocus("");
      setCCBrand("");
    }
    if (
      enterprise?.enterpriseInstallmentSetting &&
      [false, "false"].includes(enterprise?.enterpriseInstallmentSetting?.inheritSettings)
    ) {
      setInstallmentSetting(enterprise.enterpriseInstallmentSetting);
    } else if (
      (enterprise?.enterpriseInstallmentSetting &&
        [true, "true"].includes(enterprise?.enterpriseInstallmentSetting?.inheritSetting)) ||
      enterprise?.globalInstallmentSetting
    ) {
      setInstallmentSetting(enterprise.globalInstallmentSetting);
    }
    let openTaxPrice = 0;
    if (openTaxInstallments && openTaxInstallments?.length > 0) {
      const openTaxInstallment = openTaxInstallments?.find((i) => i.installmentNumber === 1);
      openTaxPrice = openTaxInstallment.installmentTotalValue;
    }
    const subsInstallment = installments?.find(
      (i) => i.installmentNumber === subscriptionInstallments,
    );
    let subsPrice =
      subsInstallment?.installmentTotalValue - (installments?.length < 2 ? openTaxPrice : 0);
    const totalText = `${format(
      Number(subsInstallment?.installmentTotalValue).toFixed(2),
      MASK_TYPES.realWithName,
    )}`;
    setBtnLabel(`Pagar ${totalText}`);
    if (installments && installments?.length > 0) {
      const options = [];
      for (const { installmentNumber, installmentValue, installmentTotalValue } of installments) {
        const text = `${installmentNumber} x ${format(
          Number(installmentValue - (installments?.length < 2 ? openTaxPrice : 0)).toFixed(2),
          MASK_TYPES.realWithName,
        )}`;
        const totalText =
          installmentNumber === 1
            ? ""
            : `(${format(Number(installmentTotalValue).toFixed(2), MASK_TYPES.realWithName)})`;
        options.push({
          value: installmentNumber,
          text: `${text} ${totalText}`,
        });
      }
      setSubscriptionInstallmentsOptions(options);
    }
    if (openTaxInstallments && openTaxInstallments?.length > 0) {
      const options = [];
      for (const {
        installmentNumber,
        installmentValue,
        installmentTotalValue,
      } of openTaxInstallments) {
        const text = `${installmentNumber} x ${format(
          Number(installmentValue).toFixed(2),
          MASK_TYPES.realWithName,
        )}`;
        const totalText =
          installmentNumber === 1
            ? ""
            : `(${format(Number(installmentTotalValue).toFixed(2), MASK_TYPES.realWithName)})`;
        options.push({
          value: installmentNumber,
          text: `${text} ${totalText}`,
        });
      }
      setOpenTaxInstallmentOptions(options);
      setHasOpeningTax(true);
      if (hasOpeningTax && installments?.length < 2) {
        const taxInstallment = openTaxInstallments?.find(
          (i) => i.installmentNumber === openingTaxInstallments,
        );
        const totalText = `${format(
          Number(subsPrice + taxInstallment.installmentTotalValue).toFixed(2),
          MASK_TYPES.realWithName,
        )}`;
        setBtnLabel(`Pagar ${totalText}`);
      }
    }
    setShowInterest(installmentSetting && installmentSetting?.showInterest);
  }, [
    showModal,
    resetForm,
    openingTax,
    enterprise,
    paymentMethod,
    installmentSetting,
    confirmLabel,
    subscriptionInstallments,
    maxInstallments,
    totalPrice,
    installments,
    openTaxInstallments,
    openingTaxInstallments,
    hasOpeningTax,
  ]);

  const handleCancel = () => {
    setSubscriptionInstallmentsOptions([]);
    setSubscriptionInstallments(1);
    setActive(false);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const handleInputFocus = (e) => setCCFocus(e.target.name);

  const allowedKeys = [
    8,
    37,
    39,
    46,
    "Tab",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Home",
    "End",
  ];

  const allowOnlyNumberKeys = (e) => {
    if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)) return true;
    if (e.keyCode === 0 || e.keyCode === 229) {
      const keyCode = getKeyCode(e.target.value);
      if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) return true;
    }
  };
  const getKeyCode = (str) => str.charCodeAt(str.length - 1);
  const alphaOnly = (e) => (e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode === 8;
  const elseAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.value = getOnlyNumbers(e.target.value.trim());
    return false;
  };

  const handleCCFullName = (e) => {
    if (!alphaOnly(e)) {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = e.target.value.replace(/\d/g, "");
      setCCFullName(e.target.value);
      return false;
    }
    return true;
  };

  const handleCCExpiry = (e) => {
    if (allowedKeys.includes(e.keyCode) || allowedKeys.includes(e.key)) {
      return;
    }
    if (allowOnlyNumberKeys(e)) {
      e.target.value = formatExpiryDate(e.target.value).trim();
      return;
    }
    return elseAction(e);
  };

  const handleCCNumber = (e) => {
    if (allowedKeys.includes(e.keyCode) || allowedKeys.includes(e.key)) {
      return;
    }
    if (allowOnlyNumberKeys(e)) {
      const brand = getBrandByNumber(e.target.value);
      if (brand) {
        setCCBrand(brand);
        e.target.value = formatCardNumber(e.target.value, brand).trim();
      }
      return;
    }
    return elseAction(e);
  };

  const handleSubmit = (el) => {
    el.preventDefault();
    if (onConfirmModal) {
      onConfirmModal({
        form: el.target,
        selectedNewPlan,
        ccNumber,
        subscriptionInstallments,
        openingTaxInstallments,
      });
    }
  };

  const checkForm = () => {
    const names = ccFullName.trim().split(" ");
    return (
      !ccFullName ||
      !ccNumber ||
      ccNumber.replace(/\D/g).trim()?.length < ccBrand.length ||
      !ccCvc ||
      !ccExpiration ||
      ccExpiration.trim()?.length < 5 ||
      names?.length < 2
    );
  };
  const firstLetterUpperCase = (str) => {
    const strs = str.toLowerCase().split(" ");
    const newStrs = strs.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
    return newStrs.join(" ");
  };

  return (
    <Portal>
      <S.ModalWrapper active={active}>
        <S.Modal width={width} isSmallerModal={isSmallerModal}>
          <S.ModalContent textAlign={textAlign}>
            <>
              <S.CardContainer>
                <Cards
                  cvc={ccCvc}
                  expiry={ccExpiration}
                  focused={ccFocus}
                  name={ccFullName}
                  number={ccNumber}
                />
              </S.CardContainer>
              <S.CardFormContainer>
                {hasOpeningTax &&
                installmentSetting &&
                subscriptionInstallmentsOptions.length < 2 ? (
                  <Columns columns="2">
                    <div style={{ marginRight: "1rem" }}>
                      <SelectForm
                        name="subscriptionInstallments"
                        label="Valor do Plano"
                        options={subscriptionInstallmentsOptions}
                        value={subscriptionInstallments}
                        handleChange={(el) => setSubscriptionInstallments(Number(el.target.value))}
                      />
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                      <SelectForm
                        name="openingTax"
                        label={`Taxa de abertura ${
                          showInterest &&
                          openTaxInstallments?.length > 1 &&
                          openTaxInstallments?.[1].monthlyInterest > 0
                            ? "(Taxa: " + openTaxInstallments?.[1].monthlyInterest + "% a.m.)"
                            : ""
                        }`}
                        options={openTaxInstallmentOptions}
                        value={openingTaxInstallments}
                        handleChange={(el) => setOpeningTaxInstallments(Number(el.target.value))}
                      />
                    </div>
                  </Columns>
                ) : (
                  <>
                    {subscriptionInstallmentsOptions &&
                      subscriptionInstallmentsOptions.length > 0 && (
                        <Columns columns="1">
                          <SelectForm
                            name="subscriptionInstallments"
                            label={`Opções de parcelamento ${
                              showInterest &&
                              installments?.length > 1 &&
                              installments?.[1].monthlyInterest > 0
                                ? "(Taxa: " + installments?.[1].monthlyInterest + "% a.m.)"
                                : ""
                            }`}
                            options={subscriptionInstallmentsOptions}
                            value={subscriptionInstallments}
                            handleChange={(el) =>
                              setSubscriptionInstallments(Number(el.target.value))
                            }
                          />
                        </Columns>
                      )}
                  </>
                )}
                <Form onSubmit={handleSubmit}>
                  <InputForm
                    name="ccFullName"
                    type="text"
                    label="Informe o nome completo"
                    onChange={(el) => setCCFullName(el.target.value)}
                    onKeyUp={(el) => handleCCFullName(el)}
                    onBlur={(el) => setCCFullName(firstLetterUpperCase(el.target.value))}
                    onFocus={handleInputFocus}
                    value={ccFullName}
                    data-iugu="full_name"
                  />
                  <InputForm
                    name="ccNumber"
                    type="text"
                    label="Número do cartão"
                    onChange={(el) => setCCNumber(el.target.value)}
                    onKeyUp={(el) => handleCCNumber(el)}
                    onFocus={handleInputFocus}
                    value={ccNumber}
                    data-iugu="number"
                    maxLength={ccBrand.maskedLength}
                  />
                  <Columns columns="2">
                    <div style={{ marginRight: "1rem" }}>
                      <InputForm
                        name="ccExpiration"
                        type="text"
                        label="Data de validade"
                        onChange={(el) => setCCExpiration(el.target.value)}
                        onKeyUp={(el) => handleCCExpiry(el)}
                        onFocus={handleInputFocus}
                        value={ccExpiration}
                        data-iugu="expiration"
                        maxLength="5"
                      />
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                      <InputForm
                        name="ccCvc"
                        type="text"
                        label="CVV"
                        onChange={(el) => setCCCvc(el.target.value)}
                        onFocus={handleInputFocus}
                        value={ccCvc}
                        data-iugu="verification_value"
                      />
                    </div>
                  </Columns>
                  <RowFlex horizontalAlign="space-between">
                    {!hideCancel && (
                      <Button variant="outline" handleClick={handleCancel}>
                        {cancelLabel}
                      </Button>
                    )}
                    {!hideConfirm && (
                      <Button disabled={checkForm()} type="submit">
                        {btnLabel}
                      </Button>
                    )}
                  </RowFlex>
                </Form>
              </S.CardFormContainer>
            </>
          </S.ModalContent>
        </S.Modal>
      </S.ModalWrapper>
    </Portal>
  );
};

ModalCreditCard.propTypes = {
  showModal: PropTypes.bool.isRequired,
  isSmallerModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onConfirmModal: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  hideConfirm: PropTypes.bool,
  hideCancel: PropTypes.bool,
  width: PropTypes.string,
  textAlign: PropTypes.string,
  resetForm: PropTypes.bool,
  paymentMethod: PropTypes.string,
};

ModalCreditCard.defaultProps = {
  onConfirmModal: null,
  confirmLabel: "Confirmar",
  cancelLabel: "Cancelar",
  onCloseModal: null,
  hideConfirm: false,
  hideCancel: false,
  width: null,
  textAlign: null,
  isSmallerModal: false,
  resetForm: false,
};

export { ModalCreditCard };
export default ModalCreditCard;
