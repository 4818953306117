import styled from "styled-components";
import { Paragraph } from "components/core/Typography";

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${Paragraph} {
    text-align: center;
    max-width: 48rem;
    margin-top: var(--space-md);
    margin-bottom: var(--space-lg);
  }
`;
