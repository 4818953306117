import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams as useRouterParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ServicesActions } from "store/ducks/services";
import { EmitterActions } from "store/ducks/emitter";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import MunicipalServicesDetails from "components/presentation/MunicipalServices/Details";
import WithSpinner from "components/core/WithSpinner";
import { LINKS } from "utils/constants";

const MunicipalServicesDetailsWithSpinner = WithSpinner(MunicipalServicesDetails);

const MunicipalServicesContainer = () => {
  const dispatch = useDispatch();
  const { emitterId, id } = useRouterParams();
  const { t: translate } = useTranslation();
  const { servicesDetails, ServicesRequests } = useSelector(({ services }) => services);
  const { emitterDetails } = useSelector(({ emitter }) => emitter);

  useEffect(() => {
    dispatch(ServicesActions.fetchServicesDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (emitterDetails?.id !== Number(emitterId)) {
      dispatch(EmitterActions.fetchEmitterById(emitterId));
    }
  }, [dispatch, emitterDetails?.id, emitterId]);

  return (
    <>
      <NavBar title={translate("municipalServices")}>
        <Button
          url={
            emitterDetails?.unifiedMunicipalService && emitterDetails?.idEnterpriseType === 1
              ? LINKS.municipalServicesEdit(emitterId, id)
              : LINKS.municipalServicesEdit(emitterId, id)
          }
          variant="outline"
          disabled
        >
          {translate("edit")}
        </Button>
      </NavBar>
      <MunicipalServicesDetailsWithSpinner
        requestStatus={ServicesRequests.FETCH_SERVICES_DETAILS}
        emitterDetails={emitterDetails}
        servicesDetails={servicesDetails}
      />
    </>
  );
};

export default MunicipalServicesContainer;
