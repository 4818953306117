import styled from "styled-components";
import { ReactComponent as ArrowSVG } from "assets/icons/icon-chrevon-white.svg";
import { MenuItem } from "components/core/SideBar/styled";

export const Arrow = styled(ArrowSVG)`
  transform-origin: center;
  transition: transform 0.3s linear;
  height: 1.3rem !important;
  width: auto !important;
  fill: none !important;
  margin: 0 var(--space-xs) 0 auto !important;
  transform: rotate(90deg);
`;

export const MenuAccordion = styled.ul`
  background: var(--color-white-1);

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  & > li > button {
    background-color: var(--color-white-1);
    border-bottom-right-radius: 1.6rem;
    position: relative;
  }
`;

export const ItemMenuAccordion = styled.li`
  button > svg.arrowAcc {
    transition: transform 0.3s linear;
    transform: ${({ active }) => (active ? "rotate(-90deg)" : "rotate(90deg)")};
  }

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  ul {
    overflow: hidden;
    background: var(--color-white-2);
    width: 100%;
    margin-top: -1.6rem;
    padding-top: 1.6rem;
    display: ${({ active }) => (active ? "block" : "none")};
  }
`;

export const BtAccordion = styled.button`
  color: var(--color-black-2);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: var(--font-size-default);
  font-weight: 600;
  height: 4.8rem;
  width: 100%;

  outline: none;

  & > svg {
    height: 3.2rem;
    width: 3.2rem;
    min-width: 3.2rem;
    margin: var(--space-xs) 1.5rem var(--space-xs) 2rem;
    stroke: var(--color-black-2);
  }
`;

export const CustomMenu = styled(MenuItem)`
  color: var(--color-black-2);
  padding: 0 2rem;
`;
