import React, { useState } from "react";
import NavBar from "components/core/NavBar";
import Button from "components/core/Button";
import { ReactComponent as IconAdd } from "assets/icons/icon-plus.svg";
import { useTranslation } from "react-i18next";
import WithSpinner from "components/core/WithSpinner";
import { useDispatch, useSelector } from "react-redux";
import { ParamsActions } from "store/ducks/params";
import { useParams, useRequest } from "hooks";
import WithSuspense from "components/core/WithSuspense";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core";
import AccountantCertificatesList from "../../../components/presentation/Params/AccountantCertificates/List";

const Register = React.lazy(() => import("containers/Params/AccountantCertificates/Register"));

const AccountantCertificatesListWithSpinner = WithSpinner(AccountantCertificatesList);
const RegisterWithSuspense = WithSuspense(Register);

const AccountantCertificates = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const {
    ParamsRequests,
    certificates: { items, total, totalPages },
  } = useSelector(({ params }) => params);

  const fetchAllCertificates = (newParams) => {
    dispatch(ParamsActions.fetchCertificates(newParams));
  };

  const dispatchResult = (text, type) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
      }),
    );

  useRequest({
    request: ParamsRequests.DELETE_CERTIFICATE,
    onResolved: () => {
      window.location.reload();
    },
    onRejected: () => {
      dispatchResult("Erro ao excluir certificado!", "error");
    },
  });

  useRequest({
    request: ParamsRequests.UPDATE_DEFAULT_CERTIFICATE,
    onResolved: () => {
      dispatchResult("Certificado Padrão alterado com sucesso!", "success");
      setTimeout(() => {
        window.location.reload();
      }, 700);
    },
    onRejected: () => {
      dispatchResult("Erro ao alterar certificado padrão!", "error");
    },
  });

  const deleteCertificate = (params) => {
    dispatch(ParamsActions.deleteCertificate(params));
  };

  const updateDefaultCertificate = (params) => {
    dispatch(ParamsActions.updateDefaultCertificate(params));
  };

  const { orderBy, order, page, perPage, setPage, setOrderBy, setOrder } = useParams({
    callback: fetchAllCertificates,
    defaultOrderBy: "id",
    defaultOrder: "DESC",
  });
  return (
    <>
      <NavBar title={translate("accountantCertificates")} route={null}>
        <Button handleClick={() => setShowModal(true)}>
          <IconAdd />
          <span>{translate("register")}</span>
        </Button>
      </NavBar>
      <AccountantCertificatesListWithSpinner
        items={items}
        requestStatus={ParamsRequests.FETCH_CERTIFICATES}
        statusUpdate={ParamsRequests.UPDATE_DEFAULT_CERTIFICATE}
        deleteCertificate={deleteCertificate}
        updateDefaultCertificate={updateDefaultCertificate}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
      />

      <RegisterWithSuspense
        loadComponent={showModal}
        showForm={showModal}
        setShowForm={setShowModal}
      />
    </>
  );
};

export default AccountantCertificates;
