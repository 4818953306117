import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import { Container, Columns } from "components/core/Grid";
import { Subtitle } from "components/core/Typography";

const MunicipalServicesDetails = ({ emitterDetails, servicesDetails }) => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      {emitterDetails?.unifiedMunicipalService ? (
        <>
          <Subtitle>{translate("unifiedMunicipalService")}</Subtitle>

          {servicesDetails?.code !== null && (
            <CardInfo label={translate("code")} info={servicesDetails?.code || ""} />
          )}
          {servicesDetails?.name !== null && (
            <CardInfo label={translate("description")} info={servicesDetails?.name || ""} />
          )}

          {emitterDetails?.idEnterpriseType === 2 && (
            <CardInfo
              label={translate("serviceLocation")}
              info={servicesDetails?.serviceLocation?.name || ""}
            />
          )}
          {emitterDetails?.idEnterpriseType === 3 && (
            <>
              <Subtitle>{translate("aliquots")}</Subtitle>
              <Columns columns="3">
                {servicesDetails?.pisAliquot !== null && (
                  <CardInfo
                    label={translate("PIS")}
                    info={`${servicesDetails?.pisAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                {servicesDetails?.cofinsAliquot !== null && (
                  <CardInfo
                    label={translate("COFINS")}
                    info={`${servicesDetails?.cofinsAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                {servicesDetails?.csllAliquot !== null && (
                  <CardInfo
                    label={translate("CSSL")}
                    info={`${servicesDetails?.csllAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
              </Columns>
              <Columns columns="3">
                {servicesDetails?.irAliquot !== null && (
                  <CardInfo
                    label={translate("IRRJ")}
                    info={`${servicesDetails?.irAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                {servicesDetails?.inssAliquot !== null && (
                  <CardInfo
                    label={translate("INSS")}
                    info={`${servicesDetails?.inssAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                {servicesDetails?.issAliquot !== null && (
                  <CardInfo
                    label={translate("ISS")}
                    info={`${servicesDetails?.issAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                <CardInfo
                  label={translate("serviceLocation")}
                  info={servicesDetails?.serviceLocation?.name || ""}
                />
              </Columns>
            </>
          )}
        </>
      ) : (
        <>
          <Subtitle>{translate("unifiedMunicipalService")}</Subtitle>
          {servicesDetails?.name !== null && (
            <CardInfo label={translate("name")} info={servicesDetails?.name || ""} />
          )}

          {emitterDetails?.idEnterpriseType === 2 && (
            <CardInfo
              label={translate("serviceLocation")}
              info={servicesDetails?.serviceLocation?.name || ""}
            />
          )}
          {emitterDetails?.cnae && (
            <CardInfo label={translate("CNAE")} info={servicesDetails?.cnae || ""} />
          )}
          {emitterDetails?.municipalServiceCode && (
            <CardInfo
              label={translate("municipalServiceCode")}
              info={servicesDetails?.municipalServiceCode || ""}
            />
          )}
          {emitterDetails?.serviceListItem && (
            <CardInfo
              label={translate("serviceListItem")}
              info={servicesDetails?.serviceListItem || ""}
            />
          )}
          {emitterDetails?.serviceDescription && (
            <CardInfo
              label={translate("serviceDescription")}
              info={servicesDetails?.descriptionServiceCode || ""}
            />
          )}
          {emitterDetails?.idEnterpriseType === 3 && (
            <>
              <Subtitle>{translate("aliquots")}</Subtitle>
              <Columns columns="3">
                {servicesDetails?.pisAliquot !== null && (
                  <CardInfo
                    label={translate("PIS")}
                    info={`${servicesDetails?.pisAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                {servicesDetails?.cofinsAliquot !== null && (
                  <CardInfo
                    label={translate("COFINS")}
                    info={`${servicesDetails?.cofinsAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                {servicesDetails?.csllAliquot !== null && (
                  <CardInfo
                    label={translate("CSSL")}
                    info={`${servicesDetails?.csllAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
              </Columns>
              <Columns columns="3">
                {servicesDetails?.irAliquot !== null && (
                  <CardInfo
                    label={translate("IRRJ")}
                    info={`${servicesDetails?.irAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
                {servicesDetails?.inssAliquot !== null && (
                  <CardInfo
                    label={translate("INSS")}
                    info={`${servicesDetails?.inssAliquot?.toString().replace(".", ",")}%` || ""}
                  />
                )}
              </Columns>
            </>
          )}
        </>
      )}
    </Container>
  );
};

MunicipalServicesDetails.propTypes = {
  emitterDetails: PropTypes.object.isRequired,
  servicesDetails: PropTypes.object.isRequired,
};

export default MunicipalServicesDetails;
