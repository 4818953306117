import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import * as S from "./styled";

const EMAIL_REGEX = /\b[\w.-]+@[\w.-]+(\.[\da-z]+)+/i;
const LINK_REGEX = /https?:\/\/(www\.)?[\w#%+.:=@~-]{1,256}\.[\d()A-Za-z]{1,6}\b([\w#%&()+./:=?@~-]*)/;

const messageParser = (message) => {
  const checkLink = (value) => {
    const exp = new RegExp(LINK_REGEX);
    if (exp.test(value)) {
      return true;
    }
    return false;
  };

  const checkEmail = (value) => {
    const exp = new RegExp(EMAIL_REGEX);
    if (exp.test(value)) {
      return true;
    }
    return false;
  };

  let outputMessage = message;
  const sanitizedMessage = message
    .replace(/[^\u0000-\u007F]/g, " ")
    .replace(/[\t\n\r]/g, " ")
    .split(",")
    .join("")
    .split(String.fromCharCode(160))
    .join(" ")
    .trim()
    .replace(/ +(?= )/g, "");

  if (!checkEmail(sanitizedMessage) && !checkLink(sanitizedMessage)) {
    return outputMessage;
  }

  const listLink = [];
  const listEmail = [];

  const sentences = sanitizedMessage.split(" ");

  sentences.forEach((element) => {
    if (checkEmail(element)) {
      listEmail.push(element);
    } else if (checkLink(element)) {
      listLink.push(element);
    }
  });

  const linkList = [...new Set(listLink)];
  const emailList = [...new Set(listEmail)];

  linkList.forEach((item) => {
    const element = `<a href="${item}" target="blank">${item}</a>`;
    outputMessage = outputMessage.split(item).join(element);
  });
  emailList.forEach((item) => {
    const element = `<a href="mailto:${item}">${item}</a>`;
    outputMessage = outputMessage.split(item).join(element);
  });

  return outputMessage;
};

const MessageParser = ({ message }) => {
  return <S.Message>{ReactHtmlParser(messageParser(message))}</S.Message>;
};

MessageParser.propTypes = {
  message: PropTypes.string.isRequired,
};

export default MessageParser;
