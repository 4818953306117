import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: BatchesTypes, Creators: BatchesActions } = createActions({
  getBatches: ["params"],
  getBatchesCompany: ["id", "params"],
  cancelBatches: ["params"],
  getBatchesEnterprises: ["params"],
  getBatchesDetails: ["params"],
  patchBatchesReissue: ["params", "payload"],
  getBatchesSuccess: ["payload"],
  getBatchesCompanySuccess: ["payload"],
  getBatchesReissueSuccess: ["payload"],
  getBatchesEnterprisesSuccess: ["payload"],
  confirmBatches: ["payload"],
  addBatches: ["payload"],
  exportBatches: ["payload"],
  addBatchesSuccess: ["payload"],
  exportBatchSuccess: ["payload"],
  deleteBatches: ["payload"],
  deleteBatchesSuccess: ["payload"],
  batchesRequestFailure: ["error", "originalType"],
  cleanState: null,
  cleanDownloadLink: null,
  cancelBatchesSuccess: null,
  cleanCancelBatches: null,
  cleanReissueBatches: null,
});

const INITIAL_STATE = {
  batchesDetails: null,
  batches: {},
  batchesCompany: {},
  enterprises: [],
  selectEnterprises: {},
  createdBatch: null,
  downloadLink: null,
  canceledRequest: false,
  reissueRequest: false,
  BatchesRequests: {
    getBatchesDetails: REQUEST_NOT_STARTED,
    getBatchesCompany: REQUEST_NOT_STARTED,
    getBatchesEnterprises: REQUEST_NOT_STARTED,
    getBatches: REQUEST_NOT_STARTED,
    addBatches: REQUEST_NOT_STARTED,
    confirmBatches: REQUEST_NOT_STARTED,
    exportBatches: REQUEST_NOT_STARTED,
    exportBatchSuccess: REQUEST_NOT_STARTED,
    DELETE_BATCHES: REQUEST_NOT_STARTED,
    patchBatchesReissue: REQUEST_NOT_STARTED,
    cancelBatches: REQUEST_NOT_STARTED,
    cancelBatchesSuccess: REQUEST_NOT_STARTED,
    getBatchesReissueSuccess: REQUEST_NOT_STARTED,
  },
};

const getBatches = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    getBatches: REQUEST_PENDING,
  },
});

const getBatchesCompany = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    getBatchesCompany: REQUEST_PENDING,
  },
});

const cancelBatches = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.CANCEL_BATCHES]: REQUEST_PENDING,
  },
});

const getBatchesEnterprises = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.GET_BATCHES_ENTERPRISES]: REQUEST_PENDING,
  },
});

const getBatchesDetails = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.GET_BATCHES_DETAILS]: REQUEST_PENDING,
  },
});

const patchBatchesReissue = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.PATCH_BATCHES_REISSUE]: REQUEST_PENDING,
  },
});

const getBatchesSuccess = (state, { payload }) => ({
  ...state,
  batches: payload,
  BatchesRequests: {
    ...state.BatchesRequests,
    getBatches: REQUEST_RESOLVED,
  },
});

const getBatchesCompanySuccess = (state, { payload = {} }) => ({
  ...state,
  batchesCompany: payload,
  BatchesRequests: {
    ...state.BatchesRequests,
    getBatchesCompany: REQUEST_RESOLVED,
  },
});

const getBatchesReissueSuccess = (state, { payload }) => ({
  ...state,
  batches: payload,
  reissueRequest: true,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.GET_BATCHES]: REQUEST_RESOLVED,
  },
});

const getBatchesEnterprisesSuccess = (state, { payload }) => ({
  ...state,
  enterprises: payload,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.GET_BATCHES_ENTERPRISES]: REQUEST_RESOLVED,
  },
});

const confirmBatches = (state, { payload }) => ({
  ...state,
  selectEnterprises: payload,
  BatchesRequests: {
    ...state.BatchesRequests,
    confirmBatches: REQUEST_RESOLVED,
  },
});

const addBatches = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    addBatches: REQUEST_PENDING,
  },
});

const exportBatches = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    exportBatches: REQUEST_PENDING,
  },
});

const addBatchesSuccess = (state) => ({
  ...state,
  createdBatch: state,
  BatchesRequests: {
    ...state.BatchesRequests,
    addBatches: REQUEST_RESOLVED,
  },
});

const exportBatchSuccess = (state, { payload }) => ({
  ...state,
  downloadLink: payload,
  BatchesRequests: {
    ...state.BatchesRequests,
    exportBatches: REQUEST_RESOLVED,
  },
});

const deleteBatches = (state) => ({
  ...state,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.DELETE_BATCHES]: REQUEST_PENDING,
  },
});

const deleteBatchesSuccess = (state, { payload = {} }) => ({
  ...state,
  batchesDetails: payload,
  BatchesRequests: {
    ...state.BatchesRequests,
    [BatchesTypes.DELETE_BATCHES]: REQUEST_RESOLVED,
  },
});

const batchesRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  BatchesRequests: {
    ...state.BatchesRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

const cleanDownloadLink = (state) => ({
  ...state,
  downloadLink: null,
});

const cancelBatchesSuccess = (state) => ({
  ...state,
  canceledRequest: true,
  BatchesRequests: {
    ...state.BatchesRequests,
    cancelBatches: REQUEST_RESOLVED,
  },
});

const cleanCancelBatches = (state) => ({
  ...state,
  canceledRequest: false,
});

const cleanReissueBatches = (state) => ({
  ...state,
  reissueRequest: false,
});

export default createReducer(INITIAL_STATE, {
  [BatchesTypes.GET_BATCHES]: getBatches,
  [BatchesTypes.GET_BATCHES_SUCCESS]: getBatchesSuccess,
  [BatchesTypes.CONFIRM_BATCHES]: confirmBatches,
  [BatchesTypes.ADD_BATCHES]: addBatches,
  [BatchesTypes.ADD_BATCHES_SUCCESS]: addBatchesSuccess,
  [BatchesTypes.DELETE_BATCHES]: deleteBatches,
  [BatchesTypes.DELETE_BATCHES_SUCCESS]: deleteBatchesSuccess,
  [BatchesTypes.BATCHES_REQUEST_FAILURE]: batchesRequestFailure,
  [BatchesTypes.CLEAN_STATE]: cleanState,
  [BatchesTypes.GET_BATCHES_COMPANY]: getBatchesCompany,
  [BatchesTypes.GET_BATCHES_COMPANY_SUCCESS]: getBatchesCompanySuccess,
  [BatchesTypes.GET_BATCHES_DETAILS]: getBatchesDetails,
  [BatchesTypes.GET_BATCHES_ENTERPRISES]: getBatchesEnterprises,
  [BatchesTypes.GET_BATCHES_ENTERPRISES_SUCCESS]: getBatchesEnterprisesSuccess,
  [BatchesTypes.PATCH_BATCHES_REISSUE]: patchBatchesReissue,
  [BatchesTypes.EXPORT_BATCHES]: exportBatches,
  [BatchesTypes.EXPORT_BATCH_SUCCESS]: exportBatchSuccess,
  [BatchesTypes.CLEAN_DOWNLOAD_LINK]: cleanDownloadLink,
  [BatchesTypes.CANCEL_BATCHES]: cancelBatches,
  [BatchesTypes.CANCEL_BATCHES_SUCCESS]: cancelBatchesSuccess,
  [BatchesTypes.CLEAN_CANCEL_BATCHES]: cleanCancelBatches,
  [BatchesTypes.GET_BATCHES_REISSUE_SUCCESS]: getBatchesReissueSuccess,
  [BatchesTypes.CLEAN_REISSUE_BATCHES]: cleanReissueBatches,
});
