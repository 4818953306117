import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { CouponsTypes, CouponsActions } from "store/ducks/coupons";
import {
  GET,
  POST,
  PATCH,
  DELETE,
  COUPOM_BY_ID,
  COUPONS,
  COUPONS_STATUS,
  SUBSCRIPTION_PLANS,
  GET_COUPON,
} from "utils/constants";

export function* getCoupons({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: COUPONS,
      params,
    });
    yield put(CouponsActions.getCouponsSuccess(data));
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, "getCoupons"));
  }
}

export function* getCoupon({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: GET_COUPON,
      params,
    });
    yield put(CouponsActions.getCouponSuccess(data));
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, "getCoupon"));
  }
}

export function* getCouponsById({ id }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: COUPOM_BY_ID(id),
      });
      yield put(CouponsActions.getCouponsByIdSuccess(data));
    }
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, "getCouponsById"));
  }
}

export function* createCoupons({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: COUPONS,
      data: payload,
    });
    yield put(CouponsActions.createCouponsSuccess(data));
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, "CREATE_COUPONS"));
  }
}

function* editCoupons({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PATCH,
        url: COUPOM_BY_ID(id),
        data: payload,
      });
      yield put(CouponsActions.editCouponsSuccess(data));
    }
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, type));
  }
}

function* deleteCoupons({ payload, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: COUPONS,
      data: payload,
    });
    yield put(CouponsActions.deleteCouponsSuccess(data));
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, type));
  }
}

function* disableCoupons({ payload, type }) {
  try {
    const { data } = yield api({
      method: PATCH,
      url: COUPONS,
      data: payload,
    });
    yield put(CouponsActions.disableCouponsSuccess(data));
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, type));
  }
}

export function* getAllStatus() {
  try {
    const { data } = yield api({
      method: GET,
      url: COUPONS_STATUS,
    });
    yield put(CouponsActions.getAllStatusSuccess(data));
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, "getAllStatus"));
  }
}

export function* getSubscriptions() {
  try {
    const { data } = yield api({
      method: GET,
      url: SUBSCRIPTION_PLANS,
    });
    yield put(CouponsActions.getSubscriptionsSuccess(data));
  } catch (error) {
    yield put(CouponsActions.couponsRequestFailure(error, "getSubscriptions"));
  }
}

export function* watchSagas() {
  yield takeLatest(CouponsTypes.GET_COUPONS, getCoupons);
  yield takeLatest(CouponsTypes.GET_COUPON, getCoupon);
  yield takeLatest(CouponsTypes.GET_COUPONS_BY_ID, getCouponsById);
  yield takeLatest(CouponsTypes.CREATE_COUPONS, createCoupons);
  yield takeLatest(CouponsTypes.EDIT_COUPONS, editCoupons);
  yield takeLatest(CouponsTypes.DELETE_COUPONS, deleteCoupons);
  yield takeLatest(CouponsTypes.DISABLE_COUPONS, disableCoupons);
  yield takeLatest(CouponsTypes.GET_ALL_STATUS, getAllStatus);
  yield takeLatest(CouponsTypes.GET_SUBSCRIPTIONS, getSubscriptions);
}
