import React from "react";
import PropTypes from "prop-types";
import * as S from "./styled";

const getColor = (disabled) => (disabled ? "var(--color-gray-6)" : "var(--color-secondary)");

const Checkbox = ({
  name,
  id,
  label,
  handleChange,
  register,
  marginBottom,
  disabled,
  ...otherProps
}) => (
  <S.CheckboxWrapper marginBottom={marginBottom}>
    <S.Input
      ref={register}
      type="checkbox"
      id={id ?? name}
      name={name}
      onChange={handleChange}
      disabled={disabled}
      color={getColor(disabled)}
      {...otherProps}
    />
    <S.Label htmlFor={name} color={getColor(disabled)}>
      <S.Checkbox color={getColor(disabled)} />
      {label && <span>{label}</span>}
    </S.Label>
  </S.CheckboxWrapper>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  register: PropTypes.func,
};

Checkbox.defaultProps = {
  label: null,
  handleChange: null,
  register: null,
  id: null,
};

export default Checkbox;
