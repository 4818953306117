import React from "react";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Columns } from "components/core/Grid";
import { Subtitle } from "components/core/Typography";
import { formatNumberToReal } from "helpers/format";
import { Divider } from "components/core/Typography";
import { InputGroup } from "components/core/Form";

const MEI = ({
  openingTax,
  maximumBilling,
  minimumMonthly,
  taxCalc,
  annualPrice,
  biennialPrice,
  applyInterest,
  allowInstallments,
  minInstallmentAmount,
  maxInstallments,
}) => (
  <>
    <Subtitle>Valores</Subtitle>
    <Columns columns="2">
      <CardInfo label="Taxa de Abertura de Empresa" info={formatNumberToReal(openingTax)} />
      <CardInfo label="Parcelamento?" info={allowInstallments ? "Sim" : "Não"} />
    </Columns>
    <Columns columns="3">
      <CardInfo
        label="Valor Mínimo Parcela"
        info={formatNumberToReal(minInstallmentAmount)}
        marginBottom={0}
      />
      <CardInfo label="Nº de parcelas" info={maxInstallments} />
      <CardInfo label="Aplicar Juros?" info={applyInterest ? "Sim" : "Não"} marginBottom="0.5rem" />
    </Columns>
    <Divider />
    <CardInfo label="Faturamento Máximo (Mensal)" info={formatNumberToReal(maximumBilling)} />
    <InputGroup paddingLeft="0" columns="2">
      <CardInfo label="Mensalidade Mínima" info={formatNumberToReal(minimumMonthly)} />
      <CardInfo label="Mensalidade Anual" info={formatNumberToReal(annualPrice)} />
    </InputGroup>
    <InputGroup paddingLeft="0" columns="2">
      <CardInfo label="Mensalidade Bienal" info={formatNumberToReal(biennialPrice)} />
      <CardInfo label="Valor para Cálculo de Planos" info={`${(taxCalc * 100).toFixed(2)}%`} />
    </InputGroup>
  </>
);

MEI.propTypes = {
  openingTax: PropTypes.string.isRequired,
  maximumBilling: PropTypes.string.isRequired,
  minimumMonthly: PropTypes.string.isRequired,
  taxCalc: PropTypes.number.isRequired,
  annualPrice: PropTypes.string.isRequired,
};

export default MEI;
