import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
`;

export const Header = styled.div`
  width: 100%;
  padding: var(--space-sm) var(--space);
  border-bottom: 1px solid var(--color-white-3);
  display: flex;
  ${media.lessThan("small")`
    justify-content: center;
  `}

  svg {
    height: var(--space);
    width: auto;
  }
`;

export const RegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${media.lessThan("large")`
    max-width: 100%;
    width: 100%;
  `}
`;
