import { createActions, createReducer } from "reduxsauce";
import {
    REQUEST_NOT_STARTED,
    REQUEST_PENDING,
    REQUEST_RESOLVED,
    REQUEST_REJECTED,
  } from "utils/constants/request";

export const { Types: EmailTypes, Creators: EmailActions } = createActions({
  cleanEmail: null,
  sendEmail: ["payload"],
  sendEmailSuccess: ["payload"],
  sendEmailFailure: ["error"],
});

const INITIAL_STATE = {
  payload: null,
  isLoading: false,
  EmailRequests: {
    sendEmail: REQUEST_NOT_STARTED,
  },
};

const sendEmail = (state) => ({
    ...state,
    EmailRequests: {
      ...state.EmailRequests,
      sendEmail: REQUEST_PENDING,
    },
});  

const sendEmailSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  payload: payload,
});

const sendEmailFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const cleanEmail = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [EmailTypes.SEND_EMAIL]: sendEmail,
  [EmailTypes.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
  [EmailTypes.SEND_EMAIL_FAILURE]: sendEmailFailure,
  [EmailTypes.CLEAN_EMAIL]: cleanEmail,
});
