import React, { useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams as useRouterParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InvoiceActions } from "store/ducks/invoice";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import List from "components/presentation/Invoice/List";
import useItems from "hooks/useItems";
import useParams from "hooks/useParams";
import useEmitter from "hooks/useEmitter";
import { ReactComponent as IconExport } from "assets/icons/icon-export.svg";
import { LINKS } from "utils/constants";

const InvoiceFilter = lazy(() => import("containers/Invoice/List/Filter"));
const InvoiceExport = lazy(() => import("containers/Invoice/List/Export"));

const ListWithSpinner = WithSpinner(List);
const InvoiceFilterWithSuspense = WithSuspense(InvoiceFilter);
const InvoiceExportWithSuspense = WithSuspense(InvoiceExport);

const ListContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { emitterId } = useRouterParams();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const emitter = useEmitter();

  const fetchInvoices = (params) =>
    dispatch(InvoiceActions.fetchInvoices(emitterId ?? emitter?.id, params));

  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    dispatchWithParams,
    SmartFilterButton,
  } = useParams({ defaultOrderBy: "dateIssue", defaultOrder: "desc", callback: fetchInvoices });
  const {
    InvoicesRequests,
    invoices: { items, total, totalPages },
  } = useSelector(({ invoice }) => invoice);
  const { selectedItems, handleChange, toggleAll, listChecked, hasSelectedItems } = useItems(items);

  const toggleShowExports = () => setShowExportModal(!showExportModal);

  const dispatchExportInvoices = (fileType) => {
    const payload = {
      fileType,
      invoices: selectedItems.map(({ id }) => id),
    };
    dispatch(InvoiceActions.exportInvoices(payload));
  };

  const verifyItems = () => selectedItems.find((item) => item.status === "Negada");

  return (
    <>
      <NavBar title={translate("invoices")}>
        <Button url={LINKS.invoiceRPS(emitterId)} variant="outline">
          {translate("editRPS")}
        </Button>
        {hasSelectedItems ? (
          <Button handleClick={toggleShowExports} disabled={verifyItems()}>
            <IconExport />
            <span>{translate("export")}</span>
          </Button>
        ) : (
          <SmartFilterButton handleShowFilter={() => setShowFilterModal(true)} />
        )}
      </NavBar>
      <ListWithSpinner
        requestStatus={InvoicesRequests.FETCH_INVOICES}
        items={listChecked ?? items}
        emitterId={emitterId}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        handleChange={handleChange}
        toggleAll={toggleAll}
        checkedAll={selectedItems?.length === listChecked?.length}
      />
      <InvoiceFilterWithSuspense
        loadComponent={showFilterModal}
        showFilter={showFilterModal}
        setShowFilter={setShowFilterModal}
        dispatchWithParams={dispatchWithParams}
      />
      <InvoiceExportWithSuspense
        loadComponent={showExportModal}
        showExport={showExportModal}
        setShowExport={setShowExportModal}
        dispatchExportInvoices={dispatchExportInvoices}
        count={selectedItems.length}
      />
    </>
  );
};

export default ListContainer;
