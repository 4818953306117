import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { LINKS } from "utils/constants";
import { useHistory } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import { LayoutActions } from "store/ducks/layout";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import { SidebarContainer, Sidebar } from "components/core/EmitterSidebar/styled";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import { ReactComponent as IconPin } from "assets/icons/icon-pin.svg";
import { REQUEST_PENDING } from "utils/constants/request";
import { useDebounce } from "hooks";
import * as S from "./styled";

const ItemsWrapper = ({ items, handlePinEmitter }) => (
  <S.ItemsWrapper>
    {items?.map(({ enterpriseName, cnpj, id, user }) => (
      <S.Card key={id}>
        <S.CardHeader>
          <Span>
            <strong>{enterpriseName}</strong>
          </Span>
          <Span>{cnpj}</Span>
        </S.CardHeader>
        <S.CardActions>
          <Button
            variant="outline"
            handleClick={() =>
              handlePinEmitter({
                id,
                enterpriseName,
                cnpj,
                user,
              })
            }
          >
            <IconPin />
            Fixar Emissor
          </Button>
        </S.CardActions>
      </S.Card>
    ))}
  </S.ItemsWrapper>
);

const ItemsWrapperWithSpinner = WithSpinner(ItemsWrapper);

const InputSearch = ({ inputSearchRef, handleChange }) => (
  <S.InputSearch
    ref={inputSearchRef}
    type="search"
    placeholder="Procure um Emissor"
    onChange={handleChange}
    autoFocus
  />
);

const EmitterSearchSidebar = () => {
  const history = useHistory();
  const inputSearchRef = useRef(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [newEmitter, setNewEmitter] = useState(null);
  const { isEmitterSearchOpen } = useSelector(({ layout }) => layout);
  const {
    fetchingStatus,
    emittersSearch: { items },
  } = useSelector(({ emitter }) => emitter);
  const { emitterSelected } = useSelector(({ emitter }) => emitter);
  const dispatch = useDispatch();

  const pinEmitter = (emitter) => {
    dispatch(LayoutActions.pinEmitter(emitter));
  };

  const handlePinEmitter = (emitter) => {
    if (!emitterSelected) {
      pinEmitter(emitter);
      history.push(LINKS.emitterDetails(emitter?.id));
      return;
    }

    setShowConfirmModal(true);
    setNewEmitter(emitter);
  };

  const debounceChange = useDebounce(setSearchValue, 700);

  const handleSearchChange = ({ target: { value } }) => {
    debounceChange(value);
  };

  useEffect(() => {
    if (isEmitterSearchOpen) {
      inputSearchRef.current.focus();
    }
  }, [isEmitterSearchOpen]);

  useEffect(() => {
    if (searchValue?.length >= 2) {
      dispatch(EmitterActions.fetchEmittersSearch({ name: searchValue }));
    }
  }, [dispatch, searchValue]);

  return (
    <>
      <SidebarContainer isOpen={isEmitterSearchOpen}>
        <Sidebar>
          <S.SearchHeader>
            <InputSearch inputSearchRef={inputSearchRef} handleChange={handleSearchChange} />
          </S.SearchHeader>
          <ItemsWrapperWithSpinner
            isLoading={fetchingStatus === REQUEST_PENDING}
            items={items}
            handlePinEmitter={handlePinEmitter}
          />
        </Sidebar>
      </SidebarContainer>
      <Modal
        title="Confirmar seleção"
        confirmLabel="Confirmar"
        onConfirmModal={() => pinEmitter(newEmitter)}
        onCloseModal={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
        width="49rem"
      >
        <Span>
          <strong>{emitterSelected?.name}</strong> será substituido por{" "}
          <strong>{newEmitter?.name}</strong> no menu lateral.
        </Span>
      </Modal>
    </>
  );
};

ItemsWrapper.propTypes = {
  items: PropTypes.array.isRequired,
  handlePinEmitter: PropTypes.func.isRequired,
};

InputSearch.propTypes = {
  inputSearchRef: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EmitterSearchSidebar;
