import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as AuthActions } from "store/ducks/auth";
import SignIn from "components/presentation/SignIn";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import { useRequest } from "hooks";

const SignInWithSpinner = WithSpinner(SignIn);

const SignInContainer = () => {
  const dispatch = useDispatch();
  const [errorKey, setErrorKey] = useState(null);
  const { authRequest, error } = useSelector(({ auth }) => auth);

  const handleSignIn = (values) => {
    const data = {
      ...values,
      email: values.email.trim(),
      password: values.password.trim(),
    };

    dispatch(AuthActions.authRequest(data));
  };

  useRequest({
    request: authRequest,
    onRejected: useCallback(() => {
      setErrorKey(error?.data?.message ?? "something-went-wrong");
    }, [error?.data?.message]),
  });

  return (
    <SignInWithSpinner
      isLoading={isPending(authRequest)}
      handleSignIn={handleSignIn}
      errorKey={errorKey}
    />
  );
};

export default SignInContainer;
