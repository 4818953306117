import styled from "styled-components";

import iconCheck from "assets/icons/icon-check.svg";

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: ${({ marginBottom }) => {
      return marginBottom >= 0 ? marginBottom : "3rem";
    }};
  }
  height: ${({ height }) => height};
`;

export const Label = styled.label.attrs(({ smallLabel, color }) => ({
  fontSize: smallLabel ? "var(--font-size-smaller)" : "var(--font-size-default)",
  color: color || "var(--color-secondary)",
}))`
  color: ${({ color }) => color};
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize};
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.span.attrs(({ color }) => ({
  color: color || "var(--color-secondary)",
}))`
  display: inline-block;
  border-radius: 4px;
  border: 2px solid ${({ color }) => color};
  height: 1.8rem;
  width: 1.8rem;
  min-width: 1.8rem;
  position: relative;
  transition: background-color 0.3s linear;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }

  &::after {
    content: "";
    background: url(${iconCheck}) center center no-repeat;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Input = styled.input.attrs(({ color }) => ({
  color: color || "var(--color-secondary)",
}))`
  display: none;

  &:checked + ${/* sc-sel */ Label} > ${/* sc-sel */ Checkbox} {
    background-color: ${({ color }) => color};

    &::after {
      opacity: 1;
    }
  }
`;
