import React from "react";
import PropTypes from "prop-types";
import Button from "components/core/Button";
import { Columns, Flex, FlexColumn, ColumnFlex } from "components/core/Grid";
import { Subtitle, Span } from "components/core/Typography";
import CardInfo from "components/core/CardInfo";
import Feedback from "components/core/Feedback";
import RadioButton from "components/core/RadioButton";
import { Table, TableBody, TableItem } from "components/core/Table";
import { formatNumberToReal } from "helpers/format";
import Label from "components/core/Label";
import { Spinner } from "components/core/WithSpinner/styled";
import Checkbox from "components/core/Checkbox";

const InvoicePayment = ({
  enterprise,
  invoice,
  handleClick,
  feedback,
  paymentMethod,
  setPaymentMethod,
  selectedNewPlan,
  handleChangePlan,
  scriptLoaded,
  formatDate,
  hasItemBillet,
  ableRenovation,
  showPixForm,
  setShowPixForm,
  showBilletForm,
  setShowBilletForm,
  handleClipboard,
  handleDownload,
  isDownloading,
  copied,
  isMobile,
  planInvoice,
}) => (
  <>
    {feedback ? (
      <Feedback
        title={feedback?.title}
        description={feedback?.description}
        isSuccess={feedback?.isSuccess}
        btnText={feedback?.btnText}
        emphasis={feedback?.emphasis}
        paymentFlag={feedback?.paymentFlag}
        handleClick={feedback?.handleClick}
        buttons={feedback?.buttons}
      />
    ) : (
      <>
        {invoice?.id && ableRenovation && !showPixForm && !showBilletForm && (
          <>
            <Flex marginBottom="2.8rem">
              <Subtitle>Confira os dados da fatura</Subtitle>
              <CardInfo label="Nome da Empresa" info={enterprise?.enterpriseName} />
              <Columns columns={2}>
                <CardInfo label="CNPJ" info={enterprise?.cnpj} />
                {invoice?.subscription && (
                  <CardInfo label="Plano Atual" info={invoice?.subscription?.currentPlanName} />
                )}
              </Columns>
              <Columns columns={2}>
                <CardInfo
                  label="Data de Vencimento"
                  info={invoice ? formatDate(invoice?.dueDate) : null}
                />
                <CardInfo
                  label="Valor da Fatura"
                  info={
                    invoice
                      ? formatNumberToReal(
                          hasItemBillet
                            ? Number(invoice?.total) - Number(invoice?.billetCostTax?.price)
                            : Number(invoice?.total),
                        )
                      : null
                  }
                />
              </Columns>
              <Columns columns={2}>
                {paymentMethod === "1" && (
                  <>
                    <CardInfo
                      label="Acréscimo boleto"
                      info={formatNumberToReal(Number(invoice?.billetCostTax?.price).toFixed(2))}
                    ></CardInfo>
                  </>
                )}
                <CardInfo
                  label="Total"
                  info={formatNumberToReal(
                    Number(
                      Number(
                        Number(invoice?.total) -
                          (hasItemBillet ? parseFloat(invoice?.billetCostTax?.price) : 0),
                      ) + (paymentMethod === "1" ? Number(invoice?.billetCostTax?.price) : 0),
                    ).toFixed(2),
                  )}
                />
              </Columns>
              {invoice?.subscription && invoice?.subscription?.allowedPlans?.length > 1 && (
                <CardInfo
                  label="Outras opções de pagamento"
                  infoContainer={
                    <Columns columns={2}>
                      {invoice?.subscription?.allowedPlans
                        .filter((plan) => plan !== invoice?.subscription?.currentPlan)
                        .map((plan) => (
                          <CardInfo
                            key={plan}
                            label={invoice?.planValues?.[plan].name}
                            infoContainer={
                              <Checkbox
                                key={plan}
                                name={`new_plan_${plan}`}
                                label={formatNumberToReal(
                                  Number(invoice?.planValues?.[plan].price).toFixed(2),
                                )}
                                value={plan}
                                marginBottom={0}
                                checked={selectedNewPlan === plan}
                                handleChange={handleChangePlan}
                              />
                            }
                          />
                        ))}
                    </Columns>
                  }
                />
              )}
              {invoice?.status === "pending" && (
                <>
                  <Subtitle mb="0.5rem">Forma de pagamento</Subtitle>
                  <Table>
                    <TableBody>
                      <tr>
                        {invoice.payableWith !== "credit_card" && (
                          <TableItem textAlign="center" padding="1rem">
                            <RadioButton
                              name="idPaymentMethod"
                              label={`Boleto Bancário (Acréscimo de ${formatNumberToReal(
                                Number(invoice?.billetCostTax?.price).toFixed(2),
                              )})`}
                              id="bankSlip"
                              value={1}
                              checked={paymentMethod === "1"}
                              onClick={(el) => setPaymentMethod(el.target.value)}
                              onChange={(el) => setPaymentMethod(el.target.value)}
                            />
                          </TableItem>
                        )}
                      </tr>
                      <tr>
                        <TableItem textAlign="center">
                          <RadioButton
                            name="idPaymentMethod"
                            label="Cartão de Crédito"
                            id="creditCard"
                            value={2}
                            checked={paymentMethod === "2"}
                            disabled={!scriptLoaded}
                            onClick={(el) => setPaymentMethod(el.target.value)}
                            onChange={(el) => setPaymentMethod(el.target.value)}
                          />
                        </TableItem>
                      </tr>
                      <tr>
                        <TableItem textAlign="center">
                          <RadioButton
                            name="idPaymentMethod"
                            label="Pix"
                            id="pix"
                            value={3}
                            checked={paymentMethod === "3"}
                            disabled={!scriptLoaded}
                            onClick={(el) => setPaymentMethod(el.target.value)}
                            onChange={(el) => setPaymentMethod(el.target.value)}
                          />
                        </TableItem>
                      </tr>
                    </TableBody>
                  </Table>
                </>
              )}
            </Flex>
            <Flex horizontalAlign="center">
              {invoice?.status === "pending" && (
                <Button handleClick={handleClick} disabled={!paymentMethod} width="100%">
                  Continuar
                </Button>
              )}
            </Flex>
          </>
        )}

        {selectedNewPlan && planInvoice && showBilletForm && (
          <FlexColumn horizontalAlign="center" verticalAlign="center">
            <Subtitle>Pagamento via Boleto Bancário</Subtitle>
            {isMobile && (
              <Span>
                Copie o código da linha digitável abaixo e cole em seu aplicativo de banco ou conta
                digital, para efetuar o pagamento.
              </Span>
            )}
            {!isMobile && (
              <Span>
                Leia o código de barras usando seu aplicativo de banco ou conta digital, ou copie o
                código da linha digitável abaixo e cole em seu aplicativo de banco ou conta digital,
                para efetuar o pagamento.
              </Span>
            )}
            {!isMobile && (
              <img
                src={planInvoice?.barcode}
                alt="invoice barcode"
                style={{ marginTop: "3rem", marginBottom: "3rem" }}
              />
            )}
            <div
              style={{
                border: "solid 1px #CCCCCC",
                borderRadius: "0.5rem",
                margin: "1rem 0 1rem 0",
                padding: "1rem",
              }}
            >
              <div>
                <Span
                  style={{ fontSize: "12px" }}
                  onClick={() => handleClipboard(planInvoice?.digitableLine)}
                >
                  {planInvoice?.digitableLine}
                </Span>
              </div>
              <div>
                <Button
                  handleClick={() => handleClipboard(planInvoice?.digitableLine)}
                  disabled={!planInvoice?.digitableLine}
                  style={{ marginTop: "2rem", width: "100%" }}
                >
                  Copiar linha digitável
                </Button>
              </div>
              {copied && (
                <div
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Label label="Código copiado com sucesso!" color="#0071ee" />
                </div>
              )}
            </div>
            <ColumnFlex style={{ marginTop: "4rem" }}>
              <Button
                handleClick={handleDownload}
                style={{ marginRight: "0", width: "100%" }}
                disabled={!planInvoice?.barcode}
              >
                {isDownloading && <Spinner height="3rem" width="3rem" marginRight="1rem" />}
                Baixar fatura
              </Button>
              {isDownloading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Label label="Aguarde! Iniciando download do arquivo" color="#0071ee" />
                </div>
              )}
              <Button
                handleClick={() => window.open(planInvoice?.invoiceUrl)}
                style={{ marginTop: "0.5rem", marginRight: "0", width: "100%" }}
                disabled={!planInvoice?.barcode}
              >
                Visualizar fatura
              </Button>
              <Button
                variant="outline"
                handleClick={() => setShowBilletForm(false)}
                style={{ marginTop: "2rem", marginRight: "0", width: "100%" }}
              >
                Voltar
              </Button>
            </ColumnFlex>
          </FlexColumn>
        )}
        {invoice?.id && !selectedNewPlan && showPixForm && (
          <>
            <FlexColumn horizontalAlign="center" marginTop="0">
              <Subtitle>Pagamento via Pix</Subtitle>
              <Span>
                Procure em seu aplicativo de banco ou conta digital a funcionalidade e escaneie o QR
                Code abaixo ou copie o código usando o Pix Copia e Cola para efetuar o pagamento.
              </Span>
              <img
                src={invoice?.paymentData?.pix?.qrcode}
                alt="invoice qrcode"
                width={300}
                style={{ marginTop: "1rem" }}
              />
              <div
                style={{
                  border: "solid 1px #CCCCCC",
                  borderRadius: "0.5rem",
                  margin: "1rem 1rem 2rem 1rem",
                  padding: "1rem",
                }}
              >
                {/* <div>
                  <Span
                    style={{ fontSize: "12px" }}
                    onClick={() => handleClipboard(invoice?.paymentData?.pix?.qrcodeText)}
                  >
                    {invoice?.paymentData?.pix?.qrcodeText}
                  </Span>
                </div> */}
                <div>
                  <Button
                    handleClick={() => handleClipboard(invoice?.paymentData?.pix?.qrcodeText)}
                    disabled={!invoice}
                    style={{ marginTop: "2rem", width: "100%" }}
                  >
                    Pix Copia e Cola - Copiar código
                  </Button>
                </div>
                {copied && (
                  <div
                    style={{
                      marginTop: "0.5rem",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Label label="Código copiado com sucesso!" color="#0071ee" />
                  </div>
                )}
              </div>
            </FlexColumn>
            <Flex horizontalAlign="center">
              <Button handleClick={() => setShowPixForm(false)}>Voltar</Button>
            </Flex>
          </>
        )}
        {selectedNewPlan && planInvoice && showPixForm && (
          <>
            <FlexColumn
              horizontalAlign="center"
              marginTop="0"
              style={{
                maxWidth: "100%",
              }}
            >
              <Subtitle>Pagamento via Pix</Subtitle>
              <Span>
                Procure em seu aplicativo de banco ou conta digital a funcionalidade e escaneie o QR
                Code abaixo ou copie o código usando o Pix Copia e Cola para efetuar o pagamento.
              </Span>
              <img
                src={planInvoice?.qrcode}
                alt="invoice qrcode"
                width={300}
                style={{ marginTop: "1rem" }}
              />
              <div
                style={{
                  border: "solid 1px #CCCCCC",
                  borderRadius: "0.5rem",
                  margin: "1rem 1rem 2rem 1rem",
                  padding: "1rem",
                }}
              >
                {/* <div>
                  <Span
                    style={{ fontSize: "12px" }}
                    onClick={() => handleClipboard(planInvoice?.qrcodeText)}
                  >
                    {planInvoice?.qrcodeText}
                  </Span>
                </div> */}
                <div>
                  <Button
                    handleClick={() => handleClipboard(planInvoice?.qrcodeText)}
                    disabled={!invoice}
                    style={{ marginTop: "2rem", width: "100%" }}
                  >
                    Pix Copia e Cola - Copiar código
                  </Button>
                </div>
                {copied && (
                  <div
                    style={{
                      marginTop: "0.5rem",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Label label="Código copiado com sucesso!" color="#0071ee" />
                  </div>
                )}
              </div>
            </FlexColumn>
            <Flex horizontalAlign="center">
              <Button handleClick={() => setShowPixForm(false)}>Voltar</Button>
            </Flex>
          </>
        )}
      </>
    )}
  </>
);

InvoicePayment.propTypes = {
  enterprise: PropTypes.object,
  invoice: PropTypes.object,
  handleClick: PropTypes.func.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  feedback: PropTypes.object,
  selectedNewPlan: PropTypes.string,
  handleChangePlan: PropTypes.func,
};

InvoicePayment.defaultProps = {
  feedback: null,
};

export default InvoicePayment;
