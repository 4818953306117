import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
} from "utils/constants/request";

export const { Types: TaxesTypes, Creators: TaxesActions } = createActions({
  fetchTaxes: null,
  fetchTaxesSuccess: ["payload"],
  editGeneralTaxes: ["payload"],
  editGeneralTaxesSuccess: ["payload"],
  editMEITaxes: ["payload"],
  editMEITaxesSuccess: ["payload"],
  getMEITaxes: ["payload"],
  getMEITaxesSuccess: ["payload"],
  editOtherTypeTaxes: ["payload"],
  editOtherTypeTaxesSuccess: ["payload"],
  taxesRequestFailure: ["error", "requestName"],
  cleanState: null,
});

const INITIAL_STATE = {
  generalTaxes: [],
  meiTaxes: {},
  TaxesRequests: {
    fetchTaxes: REQUEST_NOT_STARTED,
    editGeneralTaxes: REQUEST_NOT_STARTED,
    editMEITaxes: REQUEST_NOT_STARTED,
    getMEITaxes: REQUEST_NOT_STARTED,
    editOtherTypeTaxes: REQUEST_NOT_STARTED,
  },
};

const fetchTaxes = (state) => ({
  ...state,
  TaxesRequests: {
    ...state.TaxesRequests,
    fetchTaxes: REQUEST_PENDING,
  },
});

const fetchTaxesSuccess = (state, { payload }) => ({
  ...state,
  generalTaxes: payload,
  TaxesRequests: {
    ...state.TaxesRequests,
    fetchTaxes: REQUEST_RESOLVED,
  },
});

const editGeneralTaxes = (state) => ({
  ...state,
  TaxesRequests: {
    ...state.TaxesRequests,
    editGeneralTaxes: REQUEST_PENDING,
  },
});

const editGeneralTaxesSuccess = (state, { payload }) => ({
  ...state,
  generalTaxes: payload,
  TaxesRequests: {
    ...state.TaxesRequests,
    editGeneralTaxes: REQUEST_RESOLVED,
  },
});

const editMEITaxes = (state) => ({
  ...state,
  TaxesRequests: {
    ...state.TaxesRequests,
    editMEITaxes: REQUEST_PENDING,
  },
});

const editMEITaxesSuccess = (state, { payload }) => ({
  ...state,
  meiTaxes: payload,
  TaxesRequests: {
    ...state.TaxesRequests,
    editMEITaxes: REQUEST_RESOLVED,
  },
});

const getMEITaxes = (state) => ({
  ...state,
  TaxesRequests: {
    ...state.TaxesRequests,
    getMEITaxes: REQUEST_PENDING,
  },
});

const getMEITaxesSuccess = (state, { payload }) => ({
  ...state,
  meiTaxes: payload,
  TaxesRequests: {
    ...state.TaxesRequests,
    getMEITaxes: REQUEST_RESOLVED,
  },
});

const editOtherTypeTaxes = (state) => ({
  ...state,
  TaxesRequests: {
    ...state.TaxesRequests,
    editOtherTypeTaxes: REQUEST_PENDING,
  },
});

const editOtherTypeTaxesSuccess = (state, { payload }) => ({
  ...state,
  meiTaxes: payload,
  TaxesRequests: {
    ...state.TaxesRequests,
    editOtherTypeTaxes: REQUEST_RESOLVED,
  },
});

const taxesRequestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  TaxesRequests: {
    ...state.TaxesRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [TaxesTypes.FETCH_TAXES]: fetchTaxes,
  [TaxesTypes.FETCH_TAXES_SUCCESS]: fetchTaxesSuccess,
  [TaxesTypes.EDIT_GENERAL_TAXES]: editGeneralTaxes,
  [TaxesTypes.EDIT_GENERAL_TAXES_SUCCESS]: editGeneralTaxesSuccess,
  [TaxesTypes.EDIT_MEI_TAXES]: editMEITaxes,
  [TaxesTypes.EDIT_MEI_TAXES_SUCCESS]: editMEITaxesSuccess,
  [TaxesTypes.GET_MEI_TAXES]: getMEITaxes,
  [TaxesTypes.GET_MEI_TAXES_SUCCESS]: getMEITaxesSuccess,
  [TaxesTypes.EDIT_OTHER_TYPE_TAXES]: editOtherTypeTaxes,
  [TaxesTypes.EDIT_OTHER_TYPE_TAXES_SUCCESS]: editOtherTypeTaxesSuccess,
  [TaxesTypes.TAXES_REQUEST_FAILURE]: taxesRequestFailure,
  [TaxesTypes.CLEAN_STATE]: cleanState,
});
