import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_REJECTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
} from "utils/constants/request";

export const { Types, Creators: Actions } = createActions({
  availablePaymentForms: ["params"],
  billSubscription: ["params"],
  billSubscriptionSuccess: ["payload"],
  cancelInvoice: ["params"],
  cancelInvoiceSuccess: ["payload"],
  cleanState: null,
  chargeFinancialSubscriptionInvoice: ["params"],
  chargeFinancialSubscriptionInvoiceSuccess: ["payload"],
  createFinancialSubscription: ["params"],
  createFinancialSubscriptionSuccess: ["payload"],
  deleteFinancialSubscriptionItem: ["params"],
  deleteFinancialSubscriptionItemSuccess: ["payload"],
  deleteFinancialSubscriptionItems: ["params"],
  deleteFinancialSubscriptionItemsSuccess: ["payload"],
  exportSubscriptions: ["params"],
  exportSubscriptionsSuccess: ["payload"],
  getCouponForSubscription: ["couponCode"],
  getCouponForSubscriptionSuccess: ["payload"],
  getFinancialEnterpriseSubscriptions: ["enterpriseId"],
  getFinancialEnterpriseSubscriptionsSuccess: ["payload"],
  getFinancialSubscriptionAvailableBillingPeriods: ["id"],
  getFinancialSubscriptionAvailableBillingPeriodsSuccess: ["payload"],
  getFinancialSubscriptionBillingsFrom: ["id"],
  getFinancialSubscriptionBillingsFromSuccess: ["payload"],
  getFinancialSubscriptions: ["params"],
  getFinancialSubscriptionsSuccess: ["payload"],
  getFinancialNextSubscriptionBillings: ["params"],
  getFinancialNextSubscriptionBillingsSuccess: ["payload"],
  getFinancialSubscription: ["id"],
  getFinancialSubscriptionSuccess: ["payload"],
  getFinancialSubscriptionInvoicesToCharge: ["id"],
  getFinancialSubscriptionInvoicesToChargeSuccess: ["payload"],
  getFinancialSubscriptionInvoice: ["params"],
  getFinancialSubscriptionInvoiceSuccess: ["payload"],
  getFinancialSubscriptionInvoiceItems: ["params"],
  getFinancialSubscriptionInvoiceItemsSuccess: ["payload"],
  getSubscriptionInvoiceItems: ["params"],
  getSubscriptionInvoiceItemsSuccess: ["payload"],
  getFinancialSubscriptionInvoices: ["params"],
  getFinancialSubscriptionInvoicesSuccess: ["payload"],
  getFinancialSubscriptionItems: ["params"],
  getFinancialSubscriptionItemsSuccess: ["payload"],
  getFinancialSubscriptionItemsByCycles: ["params"],
  getFinancialSubscriptionItemsByCyclesSuccess: ["payload"],
  getFinancialSubscriptionPaymentForms: ["params"],
  getFinancialSubscriptionPaymentFormsSuccess: ["payload"],
  getNewFinancialSubscriptionParams: ["emitterIdOrCNPJ"],
  getNewFinancialSubscriptionParamsSuccess: ["payload"],
  resetState: ["params"],
  resetChargeStates: null,
  resetRequestState: ["requestName"],
  requestFailure: ["error", "originalType"],
  saveFinancialSubscription: ["params"],
  saveFinancialSubscriptionSuccess: ["payload"],
  saveFinancialSubscriptionItem: ["params"],
  saveFinancialSubscriptionItemSuccess: ["payload"],
  sendFinancialInvoiceMail: ["params"],
  sendFinancialInvoiceMailSuccess: ["payload"],
  sendInvoiceToProtheus: ["params"],
  sendInvoiceToProtheusSuccess: ["payload"],
  toggleChargeModalState: null,
  simulateFinancialSubscription: ["params"],
  simulateFinancialSubscriptionSuccess: ["payload"],
  resetRequestState: ["requestName"],
  requestFailure: ["error", "originalType"],
});

const paramsList = {
  items: [],
};

const INITIAL_STATE = {
  availablePaymentForms: null,
  chargeInvoiceResponse: null,
  availableBillingPeriods: null,
  billingsFrom: null,
  exportFile: {},
  invoiceProtheus: null,
  invoicesToCharge: null,
  newSubscriptionParams: null,
  showChargeModal: false,
  simulatedSubscription: null,
  subscriptionCoupon: null,
  subscription: {},
  subscriptionPrice: null,
  subscriptionInvoice: {},
  subscriptionInvoiceItems: paramsList,
  subscriptionInvoices: paramsList,
  subscriptionItem: {},
  subscriptionItems: paramsList,
  subscriptions: paramsList,
  nextSubscriptionBillings: paramsList,
  enterpriseSubscriptions: null,
  requestStatus: {
    [Types.BILL_SUBSCRIPTION]: REQUEST_NOT_STARTED,
    [Types.CANCEL_INVOICE]: REQUEST_NOT_STARTED,
    [Types.CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE]: REQUEST_NOT_STARTED,
    [Types.CREATE_FINANCIAL_SUBSCRIPTION]: REQUEST_NOT_STARTED,
    [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEM]: REQUEST_NOT_STARTED,
    [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS]: REQUEST_NOT_STARTED,
    [Types.DOWNLOAD_FILE]: REQUEST_NOT_STARTED,
    [Types.EXPORT_SUBSCRIPTIONS]: REQUEST_NOT_STARTED,
    [Types.GET_COUPON_FOR_SUBSCRIPTION]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_ENTERPRISE_SUBSCRIPTIONS]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_ITEMS]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_AVAILABLE_BILLING_PERIODS]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_BILLINGS_FROM]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTIONS]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS]: REQUEST_NOT_STARTED,
    [Types.GET_NEW_FINANCIAL_SUBSCRIPTION_PARAMS]: REQUEST_NOT_STARTED,
    [Types.SAVE_FINANCIAL_SUBSCRIPTION]: REQUEST_NOT_STARTED,
    [Types.SAVE_FINANCIAL_SUBSCRIPTION_ITEM]: REQUEST_NOT_STARTED,
    [Types.SEND_FINANCIAL_INVOICE_MAIL]: REQUEST_NOT_STARTED,
    [Types.SEND_INVOICE_TO_PROTHEUS]: REQUEST_NOT_STARTED,
    [Types.SIMULATE_FINANCIAL_SUBSCRIPTION]: REQUEST_NOT_STARTED,
  },
};

const cleanState = () => INITIAL_STATE;

const billSubscription = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.BILL_SUBSCRIPTION]: REQUEST_PENDING,
  },
});

const billSubscriptionSuccess = (state, { payload }) => ({
  ...state,
  subscription: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.BILL_SUBSCRIPTION]: REQUEST_RESOLVED,
  },
});

const cancelInvoice = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.CANCEL_INVOICE]: REQUEST_PENDING,
  },
});

const cancelInvoiceSuccess = (state, { payload }) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.CANCEL_INVOICE]: REQUEST_RESOLVED,
  },
});

const chargeFinancialSubscriptionInvoice = (state) => ({
  ...state,
  chargeInvoiceResponse: null,
  requestStatus: {
    ...state.requestStatus,
    [Types.CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE]: REQUEST_PENDING,
  },
});

const chargeFinancialSubscriptionInvoiceSuccess = (state, { payload }) => ({
  ...state,
  chargeInvoiceResponse: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE]: REQUEST_RESOLVED,
  },
});

const createFinancialSubscription = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.CREATE_FINANCIAL_SUBSCRIPTION]: REQUEST_PENDING,
  },
});

const createFinancialSubscriptionSuccess = (state, { payload }) => ({
  ...state,
  subscription: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.CREATE_FINANCIAL_SUBSCRIPTION]: REQUEST_RESOLVED,
  },
});

const deleteFinancialSubscriptionItem = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEM]: REQUEST_PENDING,
  },
});

const deleteFinancialSubscriptionItemSuccess = (state, { payload }) => ({
  ...state,
  subscriptionPrice: payload.subscriptionPrice,
  requestStatus: {
    ...state.requestStatus,
    [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEM]: REQUEST_RESOLVED,
  },
});

const deleteFinancialSubscriptionItems = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS]: REQUEST_PENDING,
  },
});

const deleteFinancialSubscriptionItemsSuccess = (state, { payload }) => ({
  ...state,
  subscriptionPrice: payload.subscriptionPrice,
  requestStatus: {
    ...state.requestStatus,
    [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS]: REQUEST_RESOLVED,
  },
});

const exportSubscriptions = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.EXPORT_SUBSCRIPTIONS]: REQUEST_PENDING,
  },
});

const exportSubscriptionsSuccess = (state, { payload }) => ({
  ...state,
  exportFile: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.EXPORT_SUBSCRIPTIONS]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptionPaymentForms = (state) => ({
  ...state,
  availablePaymentForms: null,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionPaymentFormsSuccess = (state, { payload }) => ({
  ...state,
  availablePaymentForms: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS]: REQUEST_RESOLVED,
  },
});

const getCouponForSubscription = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_COUPON_FOR_SUBSCRIPTION]: REQUEST_PENDING,
  },
});

const getCouponForSubscriptionSuccess = (state, { payload }) => ({
  ...state,
  subscriptionCoupon: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_COUPON_FOR_SUBSCRIPTION]: REQUEST_RESOLVED,
  },
});

const getFinancialEnterpriseSubscriptions = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_ENTERPRISE_SUBSCRIPTIONS]: REQUEST_PENDING,
  },
});

const getFinancialEnterpriseSubscriptionsSuccess = (state, { payload }) => ({
  ...state,
  enterpriseSubscriptions: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_ENTERPRISE_SUBSCRIPTIONS]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscription = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionSuccess = (state, { payload }) => ({
  ...state,
  subscription: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptionInvoicesToCharge = (state) => ({
  ...state,
  invoicesToCharge: null,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionInvoicesToChargeSuccess = (state, { payload }) => ({
  ...state,
  invoicesToCharge: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptionInvoice = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionInvoiceSuccess = (state, { payload }) => ({
  ...state,
  subscriptionInvoice: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptionInvoiceItems = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_ITEMS]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionInvoiceItemsSuccess = (state, { payload }) => ({
  ...state,
  subscriptionInvoiceItems: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_ITEMS]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptionInvoices = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionInvoicesSuccess = (
  state,
  { payload: { subscription: _subscription, ...data } },
) => {
  const stateData = {
    ...state,
    subscriptionInvoices: data,
    requestStatus: {
      ...state.requestStatus,
      [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES]: REQUEST_RESOLVED,
    },
  };
  if (_subscription) {
    stateData.subscription = _subscription;
  }
  return stateData;
};

const getFinancialSubscriptionItems = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionItemsSuccess = (
  state,
  { payload: { subscription: _subscription, ...data } },
) => {
  const stateData = {
    ...state,
    subscriptionItems: data,
    requestStatus: {
      ...state.requestStatus,
      [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS]: REQUEST_RESOLVED,
    },
  };
  if (_subscription) {
    stateData.subscription = _subscription;
  }
  return stateData;
};

const getFinancialSubscriptionItemsByCycles = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionItemsByCyclesSuccess = (state, { payload }) => ({
  ...state,
  subscriptionItems: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptionBillingsFrom = (state) => ({
  ...state,
  billingsFrom: null,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_BILLINGS_FROM]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionBillingsFromSuccess = (state, { payload }) => ({
  ...state,
  billingsFrom: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_BILLINGS_FROM]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptionAvailableBillingPeriods = (state) => ({
  ...state,
  availableBillingPeriods: null,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_AVAILABLE_BILLING_PERIODS]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionAvailableBillingPeriodsSuccess = (state, { payload }) => ({
  ...state,
  availableBillingPeriods: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_AVAILABLE_BILLING_PERIODS]: REQUEST_RESOLVED,
  },
});

const getFinancialSubscriptions = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTIONS]: REQUEST_PENDING,
  },
});

const getFinancialSubscriptionsSuccess = (state, { payload }) => ({
  ...state,
  subscriptions: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTIONS]: REQUEST_RESOLVED,
  },
});

const getFinancialNextSubscriptionBillings = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS]: REQUEST_PENDING,
  },
});

const getFinancialNextSubscriptionBillingsSuccess = (state, { payload }) => ({
  ...state,
  nextSubscriptionBillings: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS]: REQUEST_RESOLVED,
  },
});

const getNewFinancialSubscriptionParams = (state) => ({
  ...state,
  newSubscriptionParams: null,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_NEW_FINANCIAL_SUBSCRIPTION_PARAMS]: REQUEST_PENDING,
  },
});

const getNewFinancialSubscriptionParamsSuccess = (state, { payload }) => ({
  ...state,
  newSubscriptionParams: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_NEW_FINANCIAL_SUBSCRIPTION_PARAMS]: REQUEST_RESOLVED,
  },
});

const resetChargeStates = (state) => ({
  ...state,
  showChargeModal: INITIAL_STATE.showChargeModal,
  availablePaymentForms: INITIAL_STATE.availablePaymentForms,
  chargeInvoiceResponse: INITIAL_STATE.chargeInvoiceResponse,
  invoicesToCharge: INITIAL_STATE.invoicesToCharge,
  subscriptionInvoiceItems: INITIAL_STATE.subscriptionInvoiceItems,
  requestStatus: {
    ...state.requestStatus,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS]: REQUEST_NOT_STARTED,
    [Types.CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE]: REQUEST_NOT_STARTED,
    [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_ITEMS]: REQUEST_NOT_STARTED,
  },
});

const resetState = (state, { params }) => {
  let newState = { ...state };
  const states = Array.isArray(params) ? params : [params];
  states.forEach(({ stateName, newValue, isRequestState }) => {
    if (isRequestState && state.requestStatus[stateName] !== undefined) {
      newState = {
        ...newState,
        requestStatus: {
          ...state.requestStatus,
          [Types[stateName]]: REQUEST_NOT_STARTED,
        },
      };
    } else {
      newState = {
        ...newState,
        [stateName]: newValue || INITIAL_STATE[stateName],
      };
    }
  });
  return newState;
};

const resetRequestState = (state, { requestName }) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types[requestName]]: REQUEST_NOT_STARTED,
  },
});

const requestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  requestStatus: {
    ...state.requestStatus,
    [originalType]: REQUEST_REJECTED,
  },
});

const saveFinancialSubscription = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.SAVE_FINANCIAL_SUBSCRIPTION]: REQUEST_PENDING,
  },
});

const saveFinancialSubscriptionSuccess = (state, { payload }) => ({
  ...state,
  subscription: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.SAVE_FINANCIAL_SUBSCRIPTION]: REQUEST_RESOLVED,
  },
});

const saveFinancialSubscriptionItem = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.SAVE_FINANCIAL_SUBSCRIPTION_ITEM]: REQUEST_PENDING,
  },
});

const saveFinancialSubscriptionItemSuccess = (state, { payload }) => ({
  ...state,
  subscriptionItem: payload.item,
  subscriptionPrice: payload.subscriptionPrice,
  requestStatus: {
    ...state.requestStatus,
    [Types.SAVE_FINANCIAL_SUBSCRIPTION_ITEM]: REQUEST_RESOLVED,
  },
});

const sendFinancialInvoiceMail = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.SEND_FINANCIAL_INVOICE_MAIL]: REQUEST_PENDING,
  },
});

const sendFinancialInvoiceMailSuccess = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.SEND_FINANCIAL_INVOICE_MAIL]: REQUEST_RESOLVED,
  },
});

const sendInvoiceToProtheus = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [Types.SEND_INVOICE_TO_PROTHEUS]: REQUEST_PENDING,
  },
});

const sendInvoiceToProtheusSuccess = (state, { payload }) => ({
  ...state,
  invoiceProtheus: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.SEND_INVOICE_TO_PROTHEUS]: REQUEST_RESOLVED,
  },
});

const simulateFinancialSubscription = (state) => ({
  ...state,
  simulatedSubscription: null,
  requestStatus: {
    ...state.requestStatus,
    [Types.SIMULATE_FINANCIAL_SUBSCRIPTION]: REQUEST_PENDING,
  },
});

const simulateFinancialSubscriptionSuccess = (state, { payload }) => ({
  ...state,
  simulatedSubscription: payload,
  requestStatus: {
    ...state.requestStatus,
    [Types.SIMULATE_FINANCIAL_SUBSCRIPTION]: REQUEST_RESOLVED,
  },
});

const toggleChargeModalState = (state) => ({
  ...state,
  showChargeModal: !state.showChargeModal,
});

export default createReducer(INITIAL_STATE, {
  [Types.CLEAN_STATE]: cleanState,
  [Types.BILL_SUBSCRIPTION]: billSubscription,
  [Types.BILL_SUBSCRIPTION_SUCCESS]: billSubscriptionSuccess,
  [Types.CANCEL_INVOICE]: cancelInvoice,
  [Types.CANCEL_INVOICE_SUCCESS]: cancelInvoiceSuccess,
  [Types.CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE]: chargeFinancialSubscriptionInvoice,
  [Types.CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE_SUCCESS]: chargeFinancialSubscriptionInvoiceSuccess,
  [Types.CREATE_FINANCIAL_SUBSCRIPTION]: createFinancialSubscription,
  [Types.CREATE_FINANCIAL_SUBSCRIPTION_SUCCESS]: createFinancialSubscriptionSuccess,
  [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEM]: deleteFinancialSubscriptionItem,
  [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEM_SUCCESS]: deleteFinancialSubscriptionItemSuccess,
  [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS]: deleteFinancialSubscriptionItems,
  [Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS_SUCCESS]: deleteFinancialSubscriptionItemsSuccess,
  [Types.EXPORT_SUBSCRIPTIONS]: exportSubscriptions,
  [Types.EXPORT_SUBSCRIPTIONS_SUCCESS]: exportSubscriptionsSuccess,
  [Types.GET_COUPON_FOR_SUBSCRIPTION]: getCouponForSubscription,
  [Types.GET_COUPON_FOR_SUBSCRIPTION_SUCCESS]: getCouponForSubscriptionSuccess,
  [Types.GET_FINANCIAL_ENTERPRISE_SUBSCRIPTIONS]: getFinancialEnterpriseSubscriptions,
  [Types.GET_FINANCIAL_ENTERPRISE_SUBSCRIPTIONS_SUCCESS]:
    getFinancialEnterpriseSubscriptionsSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION]: getFinancialSubscription,
  [Types.GET_FINANCIAL_SUBSCRIPTION_SUCCESS]: getFinancialSubscriptionSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE]: getFinancialSubscriptionInvoicesToCharge,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE_SUCCESS]:
    getFinancialSubscriptionInvoicesToChargeSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE]: getFinancialSubscriptionInvoice,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_SUCCESS]: getFinancialSubscriptionInvoiceSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_ITEMS]: getFinancialSubscriptionInvoiceItems,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_ITEMS_SUCCESS]:
    getFinancialSubscriptionInvoiceItemsSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES]: getFinancialSubscriptionInvoices,
  [Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_SUCCESS]: getFinancialSubscriptionInvoicesSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS]: getFinancialSubscriptionItems,
  [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS_SUCCESS]: getFinancialSubscriptionItemsSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_AVAILABLE_BILLING_PERIODS]:
    getFinancialSubscriptionAvailableBillingPeriods,
  [Types.GET_FINANCIAL_SUBSCRIPTION_AVAILABLE_BILLING_PERIODS_SUCCESS]:
    getFinancialSubscriptionAvailableBillingPeriodsSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_BILLINGS_FROM]: getFinancialSubscriptionBillingsFrom,
  [Types.GET_FINANCIAL_SUBSCRIPTION_BILLINGS_FROM_SUCCESS]:
    getFinancialSubscriptionBillingsFromSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES]: getFinancialSubscriptionItemsByCycles,
  [Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES_SUCCESS]:
    getFinancialSubscriptionItemsByCyclesSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS]: getFinancialSubscriptionPaymentForms,
  [Types.GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS_SUCCESS]:
    getFinancialSubscriptionPaymentFormsSuccess,
  [Types.GET_FINANCIAL_SUBSCRIPTIONS]: getFinancialSubscriptions,
  [Types.GET_FINANCIAL_SUBSCRIPTIONS_SUCCESS]: getFinancialSubscriptionsSuccess,
  [Types.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS]: getFinancialNextSubscriptionBillings,
  [Types.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS_SUCCESS]:
    getFinancialNextSubscriptionBillingsSuccess,
  [Types.GET_NEW_FINANCIAL_SUBSCRIPTION_PARAMS]: getNewFinancialSubscriptionParams,
  [Types.GET_NEW_FINANCIAL_SUBSCRIPTION_PARAMS_SUCCESS]: getNewFinancialSubscriptionParamsSuccess,
  [Types.REQUEST_FAILURE]: requestFailure,
  [Types.RESET_CHARGE_STATES]: resetChargeStates,
  [Types.RESET_STATE]: resetState,
  [Types.RESET_REQUEST_STATE]: resetRequestState,
  [Types.SAVE_FINANCIAL_SUBSCRIPTION]: saveFinancialSubscription,
  [Types.SAVE_FINANCIAL_SUBSCRIPTION_SUCCESS]: saveFinancialSubscriptionSuccess,
  [Types.SAVE_FINANCIAL_SUBSCRIPTION_ITEM]: saveFinancialSubscriptionItem,
  [Types.SAVE_FINANCIAL_SUBSCRIPTION_ITEM_SUCCESS]: saveFinancialSubscriptionItemSuccess,
  [Types.SEND_FINANCIAL_INVOICE_MAIL]: sendFinancialInvoiceMail,
  [Types.SEND_FINANCIAL_INVOICE_MAIL_SUCCESS]: sendFinancialInvoiceMailSuccess,
  [Types.SEND_INVOICE_TO_PROTHEUS]: sendInvoiceToProtheus,
  [Types.SEND_INVOICE_TO_PROTHEUS_SUCCESS]: sendInvoiceToProtheusSuccess,
  [Types.TOGGLE_CHARGE_MODAL_STATE]: toggleChargeModalState,
  [Types.SIMULATE_FINANCIAL_SUBSCRIPTION]: simulateFinancialSubscription,
  [Types.SIMULATE_FINANCIAL_SUBSCRIPTION_SUCCESS]: simulateFinancialSubscriptionSuccess,
});
