import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import InputForm from "components/core/InputForm";
import { Subtitle } from "components/core/Typography";
import { Container } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import { taxesEnum, getTaxByEnum } from "utils/constants/enums";
import { validationSchema } from "utils/schemas/generalTaxes";
import { MASK_TYPES } from "utils/masks";
import { formatNumberToReal } from "helpers/format";

const GeneralTaxEdit = ({ onSubmit, submitRef }) => {
  const { generalTaxes } = useSelector(({ taxes }) => taxes);
  const getTax = getTaxByEnum(generalTaxes);
  const { price: additionalBilletPrice } = getTax(taxesEnum.additionalBilletPrice);
  const { value: anualDiscount } = getTax(taxesEnum.anualDiscount);
  const { price: payrollPrice } = getTax(taxesEnum.payrollPrice);

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues: {
      additionalBilletPrice: formatNumberToReal(parseFloat(additionalBilletPrice)),
      payrollPrice: formatNumberToReal(parseFloat(payrollPrice)),
      anualDiscount: Math.round((1 - anualDiscount) * 100),
    },
    mode: "onBlur",
    submitFocusError: true,
  });

  return (
    <Container maxWidth="md">
      <Subtitle>Editar Informações</Subtitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputForm
          register={register}
          type="text"
          name="additionalBilletPrice"
          label="Acréscimo por pagamento via boleto"
          mask={MASK_TYPES.realWithName}
          errorMsg={errors.additionalBilletPrice?.message}
        />
        <InputForm
          register={register}
          type="text"
          name="payrollPrice"
          label="Valor por Folha de pagamento"
          mask={MASK_TYPES.realWithName}
          errorMsg={errors.payrollPrice?.message}
        />
        <InputForm
          register={register}
          type="text"
          name="anualDiscount"
          label="Valor de desconto (plano anual)"
          errorMsg={errors.anualDiscount?.message}
          mask={MASK_TYPES.numberWithComma}
          content="%"
        />
        <InputHidden ref={submitRef} type="submit" value="Submit" />
      </Form>
    </Container>
  );
};

GeneralTaxEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
};

export default GeneralTaxEdit;
