import { useSelector } from "react-redux";

import { getLabelHelper } from "helpers/labels";

const getRoleLabel = (idRole, roles) => {
  const key = roles.find(({ id }) => id === idRole).roles;

  return getLabelHelper(key);
};

const useRoleLabel = () => {
  const { roles } = useSelector((state) => state.roles);

  return [(id) => getRoleLabel(id, roles), roles];
};

export default useRoleLabel;
