import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthorizationContainer from "containers/Authorization";
import { ROUTES } from "utils/constants";

const Authorization = () => (
  <Switch>
    <Route exact path={ROUTES.congratulations.path} component={AuthorizationContainer} />
  </Switch>
);

export default Authorization;
