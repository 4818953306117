import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import { Subtitle } from "components/core/Typography";
import { Container, Columns } from "components/core/Grid";

const ColumnsFactory = ({ cols, colsSize }) => {
  const { t: translate } = useTranslation();

  return (
    <Columns columns={colsSize || cols.length}>
      {cols.map(({ label, info, ...props }) => (
        <CardInfo key={label} label={translate(label)} info={info} {...props} />
      ))}
    </Columns>
  );
};

const DetailsFactory = ({ data }) => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      {data.map(({ titleKey, infos }) => (
        <React.Fragment key={titleKey}>
          <Subtitle>{translate(titleKey)}</Subtitle>
          {infos?.map(({ label, info, cols, colsSize, ...props }) =>
            cols?.length ? (
              <ColumnsFactory cols={cols} colsSize={colsSize} />
            ) : (
              <CardInfo key={label} label={translate(label)} info={info} {...props} />
            ),
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

ColumnsFactory.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      info: PropTypes.string,
    }),
  ).isRequired,
  colsSize: PropTypes.number.isRequired,
};

DetailsFactory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      titleKey: PropTypes.string,
      label: PropTypes.string,
      info: PropTypes.string,
      cols: PropTypes.object,
    }),
  ),
};

DetailsFactory.defaultProps = {
  data: [],
};

export default DetailsFactory;
