import React from "react";
import { Switch, Route } from "react-router-dom";
import newUserContainer from "containers/NewUserForm";
import useTerms from "containers/UseTerms";
import consentTerms from "containers/ConsentTerms";
import { ROUTES } from "utils/constants";

const NewUser = () => (
  <Switch>
    <Route exact path={ROUTES.newUser.path} component={newUserContainer} />
    <Route path={ROUTES.newUser.children.useTerms.path} component={useTerms} />
    <Route path={ROUTES.newUser.children.consentTerms.path} component={consentTerms} />
  </Switch>
);

export default NewUser;
