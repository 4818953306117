import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  RenderHeaders,
  TableCheckbox,
  TableBody,
  TableItem,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot from "components/core/StatusDot";
import { urls, INVOICE_COLORS } from "utils/constants";
import { formatNumberToReal, formatDate } from "helpers";

const List = ({
  items,
  emitterId,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  orderBy,
  order,
  toggleAll,
  checkedAll,
}) => {
  const {
    LINKS: { invoiceDetails },
  } = urls;
  const { t: translate } = useTranslation();

  const linkToDetails = (invoiceId) => invoiceDetails(emitterId, invoiceId);

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={[
              { slug: "invoiceNumber", title: translate("number") },
              { slug: "client.clientName", title: translate("recipient") },
              { slug: "invoiceType", title: translate("type") },
              { slug: "price", title: translate("invoiceValue") },
              { slug: "status", title: translate("status") },
              { slug: "dateIssue", title: translate("dateIssue"), textAlign: "center" },
            ]}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items?.map(
            ({ id, invoiceNumber, invoiceType, dateIssue, price, client, status, checked }) => (
              <tr key={id}>
                <TableItem textAlign="center">
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => handleChange(id)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem>
                  <Link to={linkToDetails(id)}>{invoiceNumber}</Link>
                </TableItem>
                <TableItem>
                  <Link to={linkToDetails(id)}>{client.clientName ?? client.enterpriseName}</Link>
                </TableItem>
                <TableItem>
                  <Link to={linkToDetails(id)}>{invoiceType.name}</Link>
                </TableItem>
                <TableItem>
                  <Link to={linkToDetails(id)}>{formatNumberToReal(price)}</Link>
                </TableItem>
                <TableItem>
                  <Link to={linkToDetails(id)}>
                    <StatusDot type={INVOICE_COLORS[status]} />
                    {status}
                  </Link>
                </TableItem>
                <TableItem textAlign="center">
                  <Link to={linkToDetails(id)}>{formatDate(dateIssue)}</Link>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  items: PropTypes.array,
  emitterId: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.number.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
