import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { POST, GET } from "utils/constants/verbs";
import { REPORTSFILES, REPORTS_EXPORT } from "utils/constants/endpoints";
import { ReportsTypes, ReportsActions } from "store/ducks/reports";

export function* fetchReports(payload) {
  try {
    const { data } = yield api({
      method: POST,
      url: REPORTSFILES,
      data: payload,
    });

    yield put(ReportsActions.fetchReportsSuccess(data));
  } catch (error) {
    yield put(ReportsActions.reportsRequestFailure(error, ReportsTypes.FETCH_SUBSCRIPTIONS));
  }
}
export function* fetchReportsPayments({ type, payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/subscription-report",
      data: payload,
    });

    yield put(ReportsActions.fetchReportsPaymentsSuccess(data));
  } catch (error) {
    yield put(ReportsActions.reportsRequestFailure(error, type));
  }
}

export function* exportFiles({ reports, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: REPORTS_EXPORT,
      data: reports,
    });
    yield put(ReportsActions.exportFilesSuccess(data));
  } catch (error) {
    yield put(ReportsActions.reportsRequestFailure(error, type));
  }
}
export function* exportFilesPayments({ reports, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/subscription-report/exportfiles/payments",
      data: reports,
    });
    yield put(ReportsActions.exportFilesPaymentsSuccess(data));
  } catch (error) {
    yield put(ReportsActions.reportsRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ReportsTypes.FETCH_REPORTS, fetchReports);
  yield takeLatest(ReportsTypes.EXPORT_FILES, exportFiles);
  yield takeLatest(ReportsTypes.FETCH_REPORTS_PAYMENTS, fetchReportsPayments);
  yield takeLatest(ReportsTypes.EXPORT_FILES_PAYMENTS, exportFilesPayments);
}
