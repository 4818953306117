import styled from "styled-components";
import media from "styled-media-query";
import { ReactComponent as LogoComponent } from "assets/svg/logo.svg";
import bgPicture from "assets/images/bg-picture.jpg";

export const Container = styled.div`
  background: linear-gradient(156deg, rgba(0, 113, 238, 0.9) 0%, rgba(4, 174, 246, 0.9) 100%),
    url(${bgPicture});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr max-content;
  height: 100%;
  min-height: 100vh;
  min-width: 440px;

  ${media.lessThan("large")`
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
  `};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.lessThan("large")`
    padding: 4rem 0;
  `}

  ${media.lessThan("small")`
    padding: 0;
  `}
`;

export const Logo = styled(LogoComponent)`
  margin: auto 2rem;
  width: 100%;

  ${media.lessThan("medium")`
    width: 60%;
    height: auto;
  `}

  ${media.lessThan("small")`
    margin: 2rem auto;
    max-width: 20rem;
  `}
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-white-1);
  border-top-left-radius: 3.2rem;
  height: 100%;
  padding: 5rem 2rem;
  width: 54rem;

  ${media.lessThan("large")`
    max-width: 100%;
    width: 100%;
  `}

  ${media.lessThan("small")`
    padding: 3.2rem 2.4rem;
  `}
`;
