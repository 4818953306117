import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LayoutActions } from "store/ducks/layout";
import { Subtitle } from "components/core/Typography";
import { ROUTES } from "utils/constants";
import AccordionMenu from "components/core/AccordionMenu";
import * as S from "./styled";

const Menu = ({ menu: { path, i18nKey, Icon }, handleClick, ...others }) => {
  const { t: translate } = useTranslation();

  if (!path) return null;

  const correctPath = path;

  return (
    <S.CustomMenu to={correctPath} key={path} onClick={handleClick} {...others}>
      {Icon && <Icon />}
      {translate(i18nKey)}
    </S.CustomMenu>
  );
};

const ManageSidebar = ({ isOpen }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = () => dispatch(LayoutActions.toggleManageMenu(!isOpen));
  const manageMenuKeys = Object.keys(ROUTES).filter((key) => ROUTES[key].manageMenu);

  return (
    <S.SidebarContainer isOpen={isOpen}>
      <S.Sidebar>
        <S.Header>
          <Subtitle mb="0">{translate("manage")}</Subtitle>
        </S.Header>
        <S.ItemsWrapper>
          {manageMenuKeys.map((key) =>
            ROUTES[key].path ? (
              <Menu key={key} menu={ROUTES[key]} handleClick={handleClick} />
            ) : ROUTES[key].accordionMenu ? (
              <AccordionMenu key={key} i18nKey={ROUTES[key].i18nKey} Icon={ROUTES[key].Icon}>
                {Object.keys(ROUTES[key].children).map((childrenKey) => (
                  <>
                    {!ROUTES[key].children[childrenKey].emitterMenu && (
                      <Menu
                        key={childrenKey}
                        menu={ROUTES[key].children[childrenKey]}
                        handleClick={handleClick}
                        style={{ marginLeft: "1rem" }}
                      />
                    )}
                  </>
                ))}
              </AccordionMenu>
            ) : (
              Object.keys(ROUTES[key].children)?.map((childrenKey) => (
                <Menu
                  key={childrenKey}
                  menu={ROUTES[key].children[childrenKey]}
                  handleClick={handleClick}
                />
              ))
            ),
          )}
        </S.ItemsWrapper>
      </S.Sidebar>
    </S.SidebarContainer>
  );
};

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

ManageSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ManageSidebar;
