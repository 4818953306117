import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import { Table, TableHeader, TableBody, TableItem, RenderHeaders } from "components/core/Table";
import Pagination from "components/core/Pagination";

const CitiesList = ({
  cities,
  setPage,
  total,
  totalPages,
  page,
  perPage,
  setOrderBy,
  order,
  orderBy,
}) => {
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "uf", title: translate("uf") },
    { slug: "city", title: translate("city") },
    { slug: "issueWithCertificate", title: translate("issueWithCertificate") },
    { slug: "issueWithoutCertificate", title: translate("issueWithoutCertificate") },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {cities &&
            cities?.map(({ id, city, uf, issueWithCertificate, issueWithoutCertificate }) => (
              <tr key={id}>
                <TableItem>{uf}</TableItem>
                <TableItem>{city}</TableItem>

                <TableItem>{issueWithCertificate ? "Emite" : "Não emite"}</TableItem>
                <TableItem>{issueWithoutCertificate ? "Emite" : "Não emite"}</TableItem>
              </tr>
            ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={setPage}
      />
    </>
  );
};

CitiesList.propTypes = {
  cities: PropTypes.array,
  setPage: PropTypes.func.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  setOrderBy: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

CitiesList.defaultProps = {
  cities: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(CitiesList);
