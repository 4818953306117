import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
} from "utils/constants/request";

export const { Types: PaymentTypes, Creators: PaymentActions } = createActions({
  fetchEnterpriseByToken: ["token"],
  fetchEnterpriseByTokenSuccess: ["payload"],
  fetchEnterpriseByTokenFailure: ["error"],
  downloadGatewayInvoice: ["params"],
  downloadGatewayInvoiceSuccess: ["payload"],
  updatePaymentToken: ["subscriptionToken", "tokenPagarMe"],
  updatePaymentTokenSuccess: ["payload"],
  updatePaymentTokenFailure: ["error"],
  fetchPaymentMethods: null,
  fetchPaymentMethodsSuccess: ["payload"],
  requestFailure: ["error", "requestName"],
  requestStatusFailure: ["error", "originalType"],
  paymentByBillet: ["payload"],
  paymentByBilletSuccess: ["payload"],
  paymentByBilletFailure: ["error"],
  cleanState: null,
});

const INITIAL_STATE = {
  enterprise: {},
  paymentMethods: [],
  isFetchingEnterprise: true,
  fetchingStatus: REQUEST_NOT_STARTED,
  gatewayInvoice: {},
  PaymentRequests: {
    fetchPaymentMethods: REQUEST_NOT_STARTED,
  },
  paymentByBillet: {
    status: null,
  },
  requestStatus: {
    [PaymentTypes.DOWNLOAD_GATEWAY_INVOICE]: REQUEST_NOT_STARTED,
  },
};

const fetchEnterpriseByToken = (state) => ({
  ...state,
  isFetchingEnterprise: true,
  isSendingUpdate: false,
  fetchingStatus: REQUEST_PENDING,
});

const fetchEnterpriseByTokenSuccess = (state, { payload }) => ({
  ...state,
  enterprise: payload,
  isFetchingEnterprise: false,
  fetchingStatus: REQUEST_RESOLVED,
});

const fetchEnterpriseByTokenFailure = (state, { error }) => ({
  ...state,
  error: error.response,
  isFetchingEnterprise: false,
  fetchingStatus: REQUEST_REJECTED,
});

const updatePaymentToken = (state) => ({
  ...state,
  isSendingUpdate: true,
});

const updatePaymentTokenSuccess = (state) => ({
  ...state,
  isSendingUpdate: false,
});

const updatePaymentTokenFailure = (state, { error }) => ({
  ...state,
  error,
  isSendingUpdate: false,
});

const fetchPaymentMethods = (state) => ({
  ...state,
  PaymentRequests: {
    ...state.PaymentRequests,
    fetchPaymentMethods: REQUEST_PENDING,
  },
});

const fetchPaymentMethodsSuccess = (state, { payload }) => ({
  ...state,
  paymentMethods: payload,
  PaymentRequests: {
    ...state.PaymentRequests,
    fetchPaymentMethods: REQUEST_RESOLVED,
  },
});

const downloadGatewayInvoice = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [PaymentTypes.DOWNLOAD_GATEWAY_INVOICE]: REQUEST_PENDING,
  },
});

const downloadGatewayInvoiceSuccess = (state, { payload }) => ({
  ...state,
  gatewayInvoice: payload,
  requestStatus: {
    ...state.requestStatus,
    [PaymentTypes.DOWNLOAD_GATEWAY_INVOICE]: REQUEST_RESOLVED,
  },
});

const requestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  PaymentRequests: {
    ...state.PaymentRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const requestStatusFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  requestStatus: {
    ...state.requestStatus,
    [originalType]: REQUEST_REJECTED,
  },
});

const paymentByBillet = (state) => ({
  ...state,
  paymentByBillet: { status: REQUEST_PENDING },
});

const paymentByBilletSuccess = (state, { payload }) => ({
  ...state,
  paymentByBillet: { ...payload, status: REQUEST_RESOLVED },
});

const paymentByBilletFailure = (state, { error }) => ({
  ...state,
  paymentByBillet: { error, status: REQUEST_REJECTED },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [PaymentTypes.FETCH_ENTERPRISE_BY_TOKEN]: fetchEnterpriseByToken,
  [PaymentTypes.FETCH_ENTERPRISE_BY_TOKEN_SUCCESS]: fetchEnterpriseByTokenSuccess,
  [PaymentTypes.FETCH_ENTERPRISE_BY_TOKEN_FAILURE]: fetchEnterpriseByTokenFailure,
  [PaymentTypes.UPDATE_PAYMENT_TOKEN]: updatePaymentToken,
  [PaymentTypes.UPDATE_PAYMENT_TOKEN_SUCCESS]: updatePaymentTokenSuccess,
  [PaymentTypes.UPDATE_PAYMENT_TOKEN_FAILURE]: updatePaymentTokenFailure,
  [PaymentTypes.FETCH_PAYMENT_METHODS]: fetchPaymentMethods,
  [PaymentTypes.FETCH_PAYMENT_METHODS_SUCCESS]: fetchPaymentMethodsSuccess,
  [PaymentTypes.DOWNLOAD_GATEWAY_INVOICE]: downloadGatewayInvoice,
  [PaymentTypes.DOWNLOAD_GATEWAY_INVOICE_SUCCESS]: downloadGatewayInvoiceSuccess,
  [PaymentTypes.REQUEST_FAILURE]: requestFailure,
  [PaymentTypes.REQUEST_STATUS_FAILURE]: requestStatusFailure,
  [PaymentTypes.PAYMENT_BY_BILLET]: paymentByBillet,
  [PaymentTypes.PAYMENT_BY_BILLET_SUCCESS]: paymentByBilletSuccess,
  [PaymentTypes.PAYMENT_BY_BILLET_FAILURE]: paymentByBilletFailure,
  [PaymentTypes.CLEAN_STATE]: cleanState,
});
