import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Flex } from "components/core/Grid";
import { Subtitle, Paragraph } from "components/core/Typography";
import Button from "components/core/Button";
import { Spinner } from "components/core/WithSpinner/styled";
import { ROUTES } from "utils/constants";
import { Link } from "react-router-dom";
import * as S from "./styled";

const RegisterComplete = ({ isLoading, error }) => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  return (
    <S.BgRegisterComplete>
      <S.RegisterComplete>
        <Flex verticalAlign="center" horizontalAlign="center">
          {isLoading && !error ? (
            <Spinner />
          ) : (
            <>
              <Flex horizontalAlign="center">
                <Subtitle>Erro, falha no cadastro!</Subtitle>
              </Flex>
              <Flex horizontalAlign="center">
                <S.IconCheck />
              </Flex>
              <Paragraph marginBottom="2rem"> Tente novamente.</Paragraph>
              <Link to={ROUTES.landingNewUser.path}>
                <Button type="submit">Voltar para o início</Button>
              </Link>
            </>
          )}
        </Flex>
      </S.RegisterComplete>
    </S.BgRegisterComplete>
  );
};

RegisterComplete.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default RegisterComplete;
