import React from "react";
import PropTypes from "prop-types";
import { Paragraph } from "components/core/Typography";
import Button from "components/core/Button";
import { ReactComponent as ExportLogo } from "assets/svg/export-file-logo.svg";
import * as S from "./styles";

const Xml = ({ handleXmlExport }) => {
  return (
    <S.Wrapper>
      <ExportLogo />
      <S.BoxText>
        <Paragraph textAlign="center">
          Clique no botão abaixo para exportar os arquivos mais recentes dos clientes da OBVIA
          Contábil.
        </Paragraph>
      </S.BoxText>
      <Button handleClick={handleXmlExport}>Exporta XML</Button>
    </S.Wrapper>
  );
};

Xml.propTypes = {
  handleXmlExport: PropTypes.func.isRequired,
};

export default Xml;
