export const ROLE_MASTER = "master";
export const ROLE_ACCOUNTANT = "accountant";
export const ROLE_CLIENT_OBVIA = "client";
export const ROLE_MARKETING = "marketing";

export const idRoles = {
  [ROLE_MASTER]: 1,
  [ROLE_ACCOUNTANT]: 2,
  [ROLE_CLIENT_OBVIA]: 3,
  [ROLE_MARKETING]: 4,
};

export default [ROLE_MASTER, ROLE_ACCOUNTANT, ROLE_CLIENT_OBVIA];
