import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableCheckbox,
  TableBody,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot from "components/core/StatusDot";
import { urls, EMITTER_COLORS_DICTIONARY } from "utils/constants";
import { formatDate } from "helpers";

const EmitterList = ({
  emitters,
  handleChange,
  params,
  total,
  totalPages,
  page,
  perPage,
  order,
  orderBy,
  toggleAll,
  everyoneIsChecked,
  handleChangePaginate,
  view,
}) => {
  const {
    LINKS: { emitterDetails },
  } = urls;
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "enterpriseName", title: translate("enterpriseName") },
    { slug: "cnpj", title: translate("cnpj") },
    { slug: "city", title: translate("city") },
    {
      slug: "idEnterpriseStatus",
      title: translate("status"),
      width: "20%",
      textAlign: "center",
    },
    { slug: "createdAt", title: translate("registrationDate") },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={everyoneIsChecked} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {emitters.map(
            ({
              id,
              enterpriseName,
              cnpj,
              city,
              enterpriseType,
              type: { name: typeName, id: idEnterpriseType },
              status: { name: statusName, id: statusId },
              createdAt,
              checked,
            }) => (
              <tr key={id}>
                <TableItem textAlign="center">
                  {statusId !== 8 ? (
                    <TableCheckbox
                      name={`toggle_${id}`}
                      value={id}
                      handleChange={() => handleChange(id)}
                      checked={checked}
                    />
                  ) : (
                    <div />
                  )}
                </TableItem>
                <TableItem>
                  <Link data-cy="id" to={emitterDetails(id)}>
                    {id}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="enterpriseName" to={emitterDetails(id)}>
                    {enterpriseName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="cnpj" to={emitterDetails(id)}>
                    {cnpj}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="city" to={emitterDetails(id)}>
                    {city}
                  </Link>
                </TableItem>
                {idEnterpriseType === 4 ? (
                  <TableItem width="20%" textAlign="center" style={{ backgroundColor: "#90ee90" }}>
                    <Link data-cy="statusName" to={emitterDetails(id)}>
                      <StatusDot type={EMITTER_COLORS_DICTIONARY[statusName]} />
                      {statusName}
                    </Link>
                  </TableItem>
                ) : (
                  <TableItem width="20%" textAlign="center">
                    <Link data-cy="statusName" to={emitterDetails(id)}>
                      <StatusDot type={EMITTER_COLORS_DICTIONARY[statusName]} />
                      {statusName}
                    </Link>
                  </TableItem>
                )}
                <TableItem textAlign="center">
                  <Link data-cy="createdAt" to={emitterDetails(id)}>
                    {!!createdAt && formatDate(createdAt)}
                  </Link>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
        handleChange={handleChangePaginate}
        views={view}
      />
    </>
  );
};

EmitterList.propTypes = {
  emitters: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  toggleAll: PropTypes.func.isRequired,
  everyoneIsChecked: PropTypes.bool.isRequired,
  handleChangePaginate: PropTypes.func.isRequired,
  view: PropTypes.bool.isRequired,
};

EmitterList.defaultProps = {
  emitters: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 0,
};

export default WithEmptyFeedback(EmitterList);
