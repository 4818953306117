import moment from "moment";
import { isNumber } from "./strings";

export const todayIsTheDay = (date) => new Date(date).toDateString() === new Date().toDateString();

export const formatDate = (
  dateToFormat,
  yearStart = 0,
  todayAsHour = true,
  showHour = false,
  planEndDate = null,
  format = null,
) => {
  if (!dateToFormat) return "";
  if (todayIsTheDay(dateToFormat) && todayAsHour) {
    return new Date(new Date(dateToFormat)).toTimeString().substring(0, 5);
  }

  const date = new Date(new Date(dateToFormat).toUTCString().substr(0, 25));
  let formatedDate;
  if (planEndDate === null) {
    const day = `${date.getDate()}`.padStart(2, "0");
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const year = date.getFullYear().toString().substring(yearStart);
    formatedDate = `${day}/${month}/${year}`;
  } else if (planEndDate === 1) {
    const day = `${date.getDate()}`.padStart(2, "0");
    const month = `${date.getMonth() + 2}`.padStart(2, "0");
    const year = date.getFullYear().toString().substring(yearStart);
    formatedDate = `${day}/${month}/${year}`;
  } else if (planEndDate === 2) {
    const day = `${date.getDate()}`.padStart(2, "0");
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const year = `${date.getFullYear() + 1}`.toString().substring(yearStart);
    formatedDate = `${day}/${month}/${year}`;
  }

  if (showHour) {
    const hour = `${date.getHours() - 3}`.padStart(2, "0");
    const minutes = `${date.getMinutes()}`.padStart(2, "0");
    formatedDate += ` • ${hour}:${minutes}`;
  }

  if (format) {
    formatedDate = moment(date).format(format);
  }

  return formatedDate;
};

export const formatNumberToReal = (money) => {
  const value = isNumber(money)
    ? parseFloat(money).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      })
    : "-";
  return value;
};

export const formatRealToNumber = (money) => {
  const deletdot = money.split(".").join("");
  const value = deletdot.split(",").join(".");
  return value;
};

export const formatDateToParam = (date, hour = "00:00", iso = false) => {
  if (!date) {
    return "";
  }
  const splitDate = date.split("/");
  const splitHour = hour.split(":");
  const dateTime = new Date(
    splitDate[2],
    splitDate[1] - 1,
    splitDate[0],
    splitHour[0],
    splitHour[1],
    splitHour[2],
    0,
  );

  if (iso) {
    const dateFormat = dateTime.toISOString();
    return dateFormat;
  }
  return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
};

export const formatNumberToParams = (value) => {
  return value?.replace(",", ".");
};
