import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NcmGlobalsActions } from "store/ducks/ncmGlobals";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import NcmGlobalsList from "components/presentation/NcmGlobals/List";
import { useItems, useParams } from "hooks";
import { urls } from "utils/constants";
import { ReactComponent as IconAddUser } from "assets/icons/icon-add-user.svg";

const SMUSelect = React.lazy(() => import("containers/NcmGlobals/Register"));
const Filter = React.lazy(() => import("containers/NcmGlobals/List/Filter"));

const NcmGlobalsListWithSpinner = WithSpinner(NcmGlobalsList);
const SMUSelectWithSuspense = WithSuspense(SMUSelect);
const FilterWithSuspense = WithSuspense(Filter);

const NcmGlobalsListContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const fetchNcmGlobals = (newParams) => {
    dispatch(NcmGlobalsActions.getNcmGlobals(newParams));
  };
  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({ callback: fetchNcmGlobals });
  const {
    NcmGlobalsRequests,
    ncmGlobals: { items, total, totalPages },
  } = useSelector(({ ncmGlobals }) => ncmGlobals);
  const {
    handleChange,
    hasOneItemSelected,
    hasSelectedItems,
    listChecked,
    selectedItems,
    toggleAll,
  } = useItems(items);

  const handleCloseShowModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <NavBar title={translate("ncmGlobals")}>
        {hasSelectedItems && (
          <>
            <Span>{translate("ncmGlobalsSelected", { count: selectedItems.length })}</Span>
          </>
        )}
        {hasSelectedItems && (
          <Button
            variant="outline"
            url={hasOneItemSelected && urls.LINKS.ncmGlobalsEdit(selectedItems[0]?.id)}
            disabled={!hasOneItemSelected}
          >
            {translate("edit")}
          </Button>
        )}
        {!hasOneItemSelected && !hasSelectedItems && (
          <>
            <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
            <Button handleClick={() => setShowModal(true)}>
              <IconAddUser />
              <span>{translate("addNcm")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <NcmGlobalsListWithSpinner
        requestStatus={NcmGlobalsRequests.getNcmGlobals}
        ncmGlobals={listChecked ?? items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        handleChange={handleChange}
        toggleAll={toggleAll}
        checkedAll={selectedItems?.length === listChecked?.length}
      />
      <SMUSelectWithSuspense
        loadComponent={showModal}
        showModal={showModal}
        setShowModal={setShowModal}
        handleClose={handleCloseShowModal}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
    </>
  );
};

export default NcmGlobalsListContainer;
