import * as Yup from "yup";

export const profileSchema = Yup.object().shape({
  name: Yup.string().min(6, "O nome é muito curto").required("O nome é necessário"),
  cpf: Yup.string().min(14, "O CPF é muito curto").required("O CPF é necessário"),
  email: Yup.string().email("O email precisa ser válido").required("O email é necessário"),
  phoneNumber: Yup.string().min(14, "O telefone é muito curto").required("O telefone é necessário"),
  state: Yup.string().required("O Estado é necessária"),
  city: Yup.string().required("A Cidade é necessária"),
});
