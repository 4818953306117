import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";
import TagManager from "react-gtm-module";

import GlobalStyle from "globalStyle";

const tagManagerArgs = {
  gtmId: "GTM-MDQZCBB",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  useEffect(() => {
    const askUserPermission = async () => {
      return Notification.requestPermission();
    };
    askUserPermission();
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <Routes />
    </Router>
  );
};

export default App;
