import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: EnterpriseTypes, Creators: EnterpriseActions } = createActions({
  getTypes: null,
  getTypesSuccess: ["payload"],
  getActivities: null,
  getActivitiesSuccess: ["payload"],
  getActivitiesWithPagination: ["params"],
  getActivitiesWithPaginationSuccess: ["payload"],
  getBillingRanges: ["enterpriseTypeID"],
  getBillingRangesSuccess: ["payload", "enterpriseTypeID"],
  getSubscriptionPlans: ["params"],
  getSubscriptionPlansSuccess: ["payload"],
  getAllStatus: null,
  getAllStatusSuccess: ["payload"],
  getAllStatusEnterprisesSuccess: ["payload"],
  getAllSubStatus: null,
  getAllSubStatusSuccess: ["payload"],
  getAllSubStatusByStatus: ["statusId"],
  getAllSubStatusByStatusSuccess: ["payload"],
  getAuthTypes: null,
  getAuthTypesSuccess: ["payload"],
  createEnterprise: ["payload"],
  createEnterpriseSuccess: ["payload"],
  fetchRegimes: ["null"],
  fetchRegimesSuccess: ["payload"],
  fetchSMU: ["params"],
  fetchSMUSuccess: ["payload"],
  getIssuances: ["payload"],
  getIssuancesSuccess: ["payload"],
  fetchEmitterResources: ["params"],
  fetchEmitterResourcesSuccess: ["payload"],
  enterpriseRequestFailure: ["error", "requestName"],
  createEnterpriseInitial: null,
  getEnterpriseXml: null,
  getEnterpriseXmlFailure: ["error", "requestName"],
  getEnterpriseXmlSuccess: ["payload"],
  getActivityGroups: ["payload"],
  getActivityGroupsSuccess: ["payload"],
  getSources: ["payload"],
  getSourcesSuccess: ["payload"],
  getResponsibleSale: ["payload"],
  getResponsibleSaleSuccess: ["payload"],
  getPartnersObvia: ["payload"],
  getPartnersObviaSuccess: ["payload"],
  getFinancialStatus: ["payload"],
  getFinancialStatusSuccess: ["payload"],
  resetFiles: null,
});

export const INITIAL_STATE = {
  types: [],
  activities: [],
  billingRanges: {},
  subscriptionPlans: [],
  status: [],
  statusEnterprises: [],
  subStatus: [],
  subStatusByStatus: [],
  authTypes: [],
  activityGroups: [],
  sources: [],
  responsibleSale: [],
  regimes: [],
  smu: [],
  enterpriseCreated: "",
  issuances: "",
  loadingXml: false,
  files: null,
  EnterpriseRequests: {
    getTypes: REQUEST_NOT_STARTED,
    getActivities: REQUEST_NOT_STARTED,
    getBillingRanges: REQUEST_NOT_STARTED,
    getSubscriptionPlans: REQUEST_NOT_STARTED,
    createEnterprise: REQUEST_NOT_STARTED,
    getAllStatus: REQUEST_NOT_STARTED,
    getAllSubStatus: REQUEST_NOT_STARTED,
    getAllSubStatusByStatus: REQUEST_NOT_STARTED,
    getAuthTypes: REQUEST_NOT_STARTED,
    getIssuances: REQUEST_NOT_STARTED,
    getEnterpriseXml: REQUEST_NOT_STARTED,
    getActivityGroups: REQUEST_NOT_STARTED,
    getSources: REQUEST_NOT_STARTED,
    getResponsibleSale: REQUEST_NOT_STARTED,
    getPartnersObvia: REQUEST_NOT_STARTED,
    [EnterpriseTypes.FETCH_SMU]: REQUEST_NOT_STARTED,
    [EnterpriseTypes.FETCH_EMITTER_RESOURCES]: REQUEST_NOT_STARTED,
  },
};

const getTypes = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getTypes: REQUEST_PENDING,
  },
});

const getEnterpriseXml = (state) => ({
  ...state,
  loadingXml: true,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getEnterpriseXml: REQUEST_PENDING,
  },
});

const getEnterpriseXmlSuccess = (state, { payload }) => ({
  ...state,
  loadingXml: false,
  files: payload?.url,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getEnterpriseXml: REQUEST_PENDING,
  },
});

const resetFiles = (state) => ({
  ...state,
  files: null,
});

const getEnterpriseXmlFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  loadingXml: false,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const getTypesSuccess = (state, { payload }) => ({
  ...state,
  types: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getTypes: REQUEST_RESOLVED,
  },
});

const getActivities = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getActivities: REQUEST_PENDING,
  },
});

const getActivitiesSuccess = (state, { payload }) => ({
  ...state,
  activities: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getActivities: REQUEST_RESOLVED,
  },
});

const getActivitiesWithPagination = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getActivities: REQUEST_PENDING,
  },
});

const getActivitiesWithPaginationSuccess = (state, { payload }) => ({
  ...state,
  activities: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getActivities: REQUEST_RESOLVED,
  },
});

const getBillingRanges = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getBillingRanges: REQUEST_PENDING,
  },
});

const getBillingRangesSuccess = (state, { payload, enterpriseTypeID }) => ({
  ...state,
  billingRanges: {
    [enterpriseTypeID]: payload,
  },
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getBillingRanges: REQUEST_RESOLVED,
  },
});

const getSubscriptionPlans = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getSubscriptionPlans: REQUEST_PENDING,
  },
});

const getSubscriptionPlansSuccess = (state, { payload }) => ({
  ...state,
  subscriptionPlans: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getSubscriptionPlans: REQUEST_RESOLVED,
  },
});

const getAllStatus = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllStatus: REQUEST_PENDING,
  },
});

const getAllStatusSuccess = (state, { payload }) => ({
  ...state,
  status: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllStatus: REQUEST_RESOLVED,
  },
});

const getAllStatusEnterprisesSuccess = (state, { payload }) => ({
  ...state,
  statusEnterprises: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllStatus: REQUEST_RESOLVED,
  },
});

const getAllSubStatus = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllSubStatus: REQUEST_PENDING,
  },
});

const getAllSubStatusSuccess = (state, { payload }) => ({
  ...state,
  subStatus: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllSubStatus: REQUEST_RESOLVED,
  },
});

const getAllSubStatusByStatus = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllSubStatusByStatus: REQUEST_PENDING,
  },
});

const getAllSubStatusByStatusSuccess = (state, { payload }) => ({
  ...state,
  subStatusByStatus: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllSubStatusByStatus: REQUEST_RESOLVED,
  },
});

const getAuthTypes = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAuthTypes: REQUEST_PENDING,
  },
});

const getAuthTypesSuccess = (state, { payload }) => ({
  ...state,
  authTypes: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAuthTypes: REQUEST_RESOLVED,
  },
});

const createEnterprise = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    createEnterprise: REQUEST_PENDING,
  },
});

const createEnterpriseSuccess = (state, { payload }) => ({
  ...state,
  enterpriseCreated: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    createEnterprise: REQUEST_RESOLVED,
  },
});

const createEnterpriseInitial = () => ({
  EnterpriseRequests: {
    createEnterprise: REQUEST_NOT_STARTED,
  },
});

const fetchRegimes = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [EnterpriseTypes.FETCH_REGIMES]: REQUEST_PENDING,
  },
});

const fetchRegimesSuccess = (state, { payload }) => ({
  ...state,
  regimes: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [EnterpriseTypes.FETCH_REGIMES]: REQUEST_RESOLVED,
  },
});

const fetchSMU = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [EnterpriseTypes.FETCH_SMU]: REQUEST_PENDING,
  },
});

const fetchSMUSuccess = (state, { payload }) => ({
  ...state,
  smu: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [EnterpriseTypes.FETCH_SMU]: REQUEST_RESOLVED,
  },
});

const fetchEmitterResources = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [EnterpriseTypes.FETCH_EMITTER_RESOURCES]: REQUEST_PENDING,
  },
});

const fetchEmitterResourcesSuccess = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [EnterpriseTypes.FETCH_EMITTER_RESOURCES]: REQUEST_RESOLVED,
  },
});

const getIssuances = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getIssuances: REQUEST_PENDING,
  },
});

const getIssuancesSuccess = (state, { payload }) => ({
  ...state,
  issuances: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getIssuances: REQUEST_RESOLVED,
  },
});

const getActivityGroups = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getActivityGroups: REQUEST_PENDING,
  },
});

const getActivityGroupsSuccess = (state, { payload }) => ({
  ...state,
  activityGroups: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getActivityGroups: REQUEST_RESOLVED,
  },
});

const getSources = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getSources: REQUEST_PENDING,
  },
});

const getSourcesSuccess = (state, { payload }) => ({
  ...state,
  sources: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getSources: REQUEST_RESOLVED,
  },
});

const getResponsibleSale = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getResponsibleSale: REQUEST_PENDING,
  },
});

const getResponsibleSaleSuccess = (state, { payload }) => ({
  ...state,
  responsibleSale: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getResponsibleSale: REQUEST_RESOLVED,
  },
});

const getPartnersObvia = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getPartnersObvia: REQUEST_PENDING,
  },
});

const getPartnersObviaSuccess = (state, { payload }) => ({
  ...state,
  partnersObvia: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getPartnersObvia: REQUEST_RESOLVED,
  },
});

const getFinancialStatus = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getFinancialStatus: REQUEST_PENDING,
  },
});

const getFinancialStatusSuccess = (state, { payload }) => ({
  ...state,
  financialStatus: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getFinancialStatus: REQUEST_RESOLVED,
  },
});

const enterpriseRequestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [EnterpriseTypes.GET_TYPES]: getTypes,
  [EnterpriseTypes.GET_TYPES_SUCCESS]: getTypesSuccess,
  [EnterpriseTypes.GET_ACTIVITIES]: getActivities,
  [EnterpriseTypes.GET_ACTIVITIES_SUCCESS]: getActivitiesSuccess,
  [EnterpriseTypes.GET_ACTIVITIES_WITH_PAGINATION]: getActivitiesWithPagination,
  [EnterpriseTypes.GET_ACTIVITIES_WITH_PAGINATION_SUCCESS]: getActivitiesWithPaginationSuccess,
  [EnterpriseTypes.GET_BILLING_RANGES]: getBillingRanges,
  [EnterpriseTypes.GET_BILLING_RANGES_SUCCESS]: getBillingRangesSuccess,
  [EnterpriseTypes.GET_SUBSCRIPTION_PLANS]: getSubscriptionPlans,
  [EnterpriseTypes.GET_SUBSCRIPTION_PLANS_SUCCESS]: getSubscriptionPlansSuccess,
  [EnterpriseTypes.GET_ALL_STATUS]: getAllStatus,
  [EnterpriseTypes.GET_ALL_STATUS_SUCCESS]: getAllStatusSuccess,
  [EnterpriseTypes.GET_ALL_SUB_STATUS]: getAllSubStatus,
  [EnterpriseTypes.GET_ALL_SUB_STATUS_SUCCESS]: getAllSubStatusSuccess,
  [EnterpriseTypes.GET_ALL_SUB_STATUS_BY_STATUS]: getAllSubStatusByStatus,
  [EnterpriseTypes.GET_ALL_SUB_STATUS_BY_STATUS_SUCCESS]: getAllSubStatusByStatusSuccess,
  [EnterpriseTypes.GET_AUTH_TYPES]: getAuthTypes,
  [EnterpriseTypes.GET_AUTH_TYPES_SUCCESS]: getAuthTypesSuccess,
  [EnterpriseTypes.CREATE_ENTERPRISE]: createEnterprise,
  [EnterpriseTypes.CREATE_ENTERPRISE_SUCCESS]: createEnterpriseSuccess,
  [EnterpriseTypes.FETCH_REGIMES]: fetchRegimes,
  [EnterpriseTypes.FETCH_REGIMES_SUCCESS]: fetchRegimesSuccess,
  [EnterpriseTypes.FETCH_SMU]: fetchSMU,
  [EnterpriseTypes.FETCH_SMU_SUCCESS]: fetchSMUSuccess,
  [EnterpriseTypes.FETCH_EMITTER_RESOURCES]: fetchEmitterResources,
  [EnterpriseTypes.FETCH_EMITTER_RESOURCES_SUCCESS]: fetchEmitterResourcesSuccess,
  [EnterpriseTypes.GET_ISSUANCES]: getIssuances,
  [EnterpriseTypes.GET_ISSUANCES_SUCCESS]: getIssuancesSuccess,
  [EnterpriseTypes.ENTERPRISE_REQUEST_FAILURE]: enterpriseRequestFailure,
  [EnterpriseTypes.CREATE_ENTERPRISE_INITIAL]: createEnterpriseInitial,
  [EnterpriseTypes.GET_ALL_STATUS_ENTERPRISES_SUCCESS]: getAllStatusEnterprisesSuccess,
  [EnterpriseTypes.GET_ENTERPRISE_XML]: getEnterpriseXml,
  [EnterpriseTypes.GET_ENTERPRISE_XML_FAILURE]: getEnterpriseXmlFailure,
  [EnterpriseTypes.GET_ENTERPRISE_XML_SUCCESS]: getEnterpriseXmlSuccess,
  [EnterpriseTypes.GET_ACTIVITY_GROUPS]: getActivityGroups,
  [EnterpriseTypes.GET_ACTIVITY_GROUPS_SUCCESS]: getActivityGroupsSuccess,
  [EnterpriseTypes.GET_SOURCES]: getSources,
  [EnterpriseTypes.GET_SOURCES_SUCCESS]: getSourcesSuccess,
  [EnterpriseTypes.GET_RESPONSIBLE_SALE]: getResponsibleSale,
  [EnterpriseTypes.GET_RESPONSIBLE_SALE_SUCCESS]: getResponsibleSaleSuccess,
  [EnterpriseTypes.GET_PARTNERS_OBVIA]: getPartnersObvia,
  [EnterpriseTypes.GET_PARTNERS_OBVIA_SUCCESS]: getPartnersObviaSuccess,
  [EnterpriseTypes.GET_FINANCIAL_STATUS]: getFinancialStatus,
  [EnterpriseTypes.GET_FINANCIAL_STATUS_SUCCESS]: getFinancialStatusSuccess,
  [EnterpriseTypes.RESET_FILES]: resetFiles,
});
