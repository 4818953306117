import React, { useState, useEffect, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useParams as useRouterParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Span } from "components/core/Typography";
import { EmitterActions } from "store/ducks/emitter";
import { ServicesActions } from "store/ducks/services";
import Button from "components/core/Button";
import DetailsFactory from "components/core/DetailsFactory";
import NavBar from "components/core/NavBar";
import { LayoutActions } from "store/ducks/layout";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import MunicipalServicesEdit from "components/presentation/MunicipalServices/Edit";
import { useRequest } from "hooks";
import { LINKS } from "utils/constants";
import { formatNumberToParams } from "helpers/format";

const MunicipalServicesDetailsWithSpinner = WithSpinner(DetailsFactory);
const MunicipalServicesEditWithSpinner = WithSpinner(MunicipalServicesEdit);

const MunicipalServicesEditContainer = () => {
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { emitterId, id } = useRouterParams();
  const { t: translate } = useTranslation();
  const [emitterDetailsSmu, setEmitterDetailsSmu] = useState("");
  const [emitterDetailsType, setEmitterDetailsType] = useState("");
  const { emitterDetails } = useSelector(({ emitter }) => emitter);
  const { servicesDetails, servicesLocations, ServicesRequests } = useSelector(
    ({ services }) => services,
  );

  useEffect(() => {
    if (emitterDetails?.id !== Number(emitterId)) {
      dispatch(EmitterActions.fetchEmitterById(emitterId));
    }
  }, [dispatch, emitterDetails?.id, emitterId]);

  useEffect(() => {
    if (emitterDetails.id) {
      setEmitterDetailsSmu(emitterDetails.unifiedMunicipalService);
      setEmitterDetailsType(emitterDetails.type.id);
    }
  }, [emitterDetails?.id, emitterDetails?.type?.id, emitterDetails?.unifiedMunicipalService]);

  useEffect(() => {
    dispatch(ServicesActions.fetchServicesDetails(id));
    dispatch(ServicesActions.fetchServicesLocations());
  }, [dispatch, id]);

  useEffect(() => () => dispatch(ServicesActions.cleanServices()), [dispatch]);

  const handleSubmit = (values) => {
    const cofinsAliquot = formatNumberToParams(values?.cofinsAliquot || "");
    const csllAliquot = formatNumberToParams(values?.csllAliquot || "");
    const irAliquot = formatNumberToParams(values?.irAliquot || "");
    const inssAliquot = formatNumberToParams(values?.inssAliquot || "");
    const pisAliquot = formatNumberToParams(values?.pisAliquot || "");
    const issAliquot = formatNumberToParams(values?.issAliquot || "");

    dispatch(
      ServicesActions.editServices(
        id,
        emitterDetailsType === 3
          ? {
              ...values,
              cofinsAliquot,
              csllAliquot,
              irAliquot,
              inssAliquot,
              pisAliquot,
              issAliquot,
            }
          : values,
      ),
    );
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans i18nKey="serviceEdited" components={{ bold: <strong /> }} />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(ServicesActions.cleanServices());
    history.push(LINKS.municipalServicesDetails(emitterId, id));
  };

  useRequest({
    request: ServicesRequests.EDIT_SERVICES,
    onResolved,
  });
  const {
    pisAliquot,
    irAliquot,
    inssAliquot,
    csllAliquot,
    cofinsAliquot,
    issAliquot,
    municipalServiceCode,
    serviceListItem,
    descriptionServiceCode,
    cnae,
    name,
  } = servicesDetails;

  return (
    <>
      <NavBar title={translate("municipalServices")}>
        <Button url={LINKS.municipalServicesDetails(emitterId, id)} variant="outline">
          <span>{translate("cancel")}</span>
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("save")}</Button>
      </NavBar>
      {emitterDetails.unifiedMunicipalService && (
        <MunicipalServicesDetailsWithSpinner
          requestStatus={ServicesRequests.FETCH_SERVICES_DETAILS}
          data={[
            {
              titleKey: "unifiedMunicipalServices",
              infos: [
                {
                  label: "code",
                  info: servicesDetails.code,
                },
                {
                  label: "description",
                  info: servicesDetails.name,
                },
              ],
            },
          ]}
        />
      )}
      <MunicipalServicesEditWithSpinner
        isLoading={isPending(ServicesRequests.FETCH_SERVICES_LOCATIONS)}
        requestStatus={ServicesRequests.FETCH_SERVICES_DETAILS}
        defaultValues={{
          idServiceLocation: servicesDetails.serviceLocation?.id,
          pisAliquot: pisAliquot?.toString().replace(".", ","),
          irAliquot: irAliquot?.toString().replace(".", ","),
          inssAliquot: inssAliquot?.toString().replace(".", ","),
          csllAliquot: csllAliquot?.toString().replace(".", ","),
          cofinsAliquot: cofinsAliquot?.toString().replace(".", ","),
          issAliquot: issAliquot?.toString().replace(".", ","),
          municipalServiceCode,
          serviceListItem,
          descriptionServiceCode,
          cnae,
          name,
        }}
        servicesLocations={servicesLocations}
        emitterDetailsSmu={emitterDetailsSmu}
        emitterDetailsType={emitterDetailsType}
        emitterDetails={emitterDetails}
        submitRef={submitRef}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default MunicipalServicesEditContainer;
