import styled from "styled-components";
import { Modal } from "components/core/Modal/styled";

export const ConfirmationCard = styled(Modal)`
  position: relative;
  text-align: center;
  justify-content: space-between;
  padding: 6rem 6rem 4rem;
  min-height: 29rem;
  width: 49rem;
  z-index: 999;
`;

export const Icon = styled.span`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  color: var(--color-white-1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6.5rem;
  width: 6.5rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;
