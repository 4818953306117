import styled from "styled-components";

export const Dot = styled.i`
  background: ${({ type }) => type};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.8rem;
  height: 1rem;
  width: 1rem;
`;
