import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LayoutActions } from "store/ducks/layout";
import InputForm from "components/core/InputForm";
import { Field, InputWrapper, Label } from "components/core/InputForm/styled";
import { Container, Columns, Flex, FlexColumn } from "components/core/Grid";
import { Form, InputHidden, InputGroup } from "components/core/Form";
import SelectForm from "components/core/SelectForm";
import RadioButton from "components/core/RadioButton";
import Checkbox from "components/core/Checkbox";
import SelectedItemsDisplay from "components/core/SelectedItemsDisplay";
import { Divider } from "components/core/Typography";
import { getStates } from "utils/mocks/brazilCitiesStates";
import { mapToSelectOptions } from "helpers";
import { getOnlyIDs } from "utils/transform/emitter";
import { MASK_TYPES } from "utils/masks";

const CouponsRegister = ({
  handleConfirm,
  validationSchema,
  submitRef,
  subscriptions,
  avalableEnterpriseTypes,
  setEnterpriseTypesModal,
  setStatesModal,
  setCitiesModal,
  handleChangeStateToselectCities,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { multiSelectItems } = useSelector(({ layout }) => layout);
  const [allPlan, setAllPlan] = useState(false);
  const [planValue, setPlanValue] = useState(1);
  const [allTypes, setAllTypes] = useState(false);
  const [allStates, setAllStates] = useState(false);
  const [selectCities, setSelectCities] = useState(false);
  const [programming, setProgramming] = useState(1);
  const listStates = getStates();
  const [isCouponValue, setIsCouponValue] = useState(true);
  const [listSelectStates, setListSelectStates] = useState(listStates);
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema,
  });
  const [EnterpriseTypes, setEnterpriseTypes] = useState(0);
  const [States, setStates] = useState(0);
  const [Cities, setCities] = useState(0);

  const handleChangeselectCities = () => setSelectCities(!selectCities);

  const itensComma = (key, i) => {
    const string = i > 0 ? `, ${key.name}` : key.name;
    return string;
  };

  const changeSubscription = ({ target: { value } }) => setPlanValue(value);

  const watchCouponType = watch("couponType");
  const watchIntervalType = watch("intervalType");

  useEffect(() => {
    setValue("discount", null);
    if (watchCouponType === "V") {
      setIsCouponValue(true);
    }
    if (watchCouponType === "P") {
      setIsCouponValue(false);
      setValue("discount", 0);
    }
  }, [watchCouponType]);

  useEffect(() => {
    if (allPlan) {
      const newPlans = getOnlyIDs(subscriptions);
      setValue("subscriptionPlans", JSON.stringify(newPlans), true);
    } else {
      const newPlans = [planValue];
      setValue("subscriptionPlans", JSON.stringify(newPlans), true);
    }
  }, [allPlan, planValue, setValue]);

  useEffect(() => {
    const { enterpriseTypes } = multiSelectItems;
    if (allTypes) {
      const newEnterpriseTypes = getOnlyIDs(avalableEnterpriseTypes);
      setValue("enterpriseTypes", JSON.stringify(newEnterpriseTypes), true);
    } else if (enterpriseTypes.length > 0) {
      const newEnterpriseTypes = getOnlyIDs(enterpriseTypes);
      setValue("enterpriseTypes", JSON.stringify(newEnterpriseTypes), true);
      const enterpriseTypesName = enterpriseTypes.map((item, i) => itensComma(item, i));
      setEnterpriseTypes(enterpriseTypesName);
    } else {
      setValue("enterpriseTypes", "[]");
      setEnterpriseTypes(0);
    }
  }, [multiSelectItems, avalableEnterpriseTypes, allTypes, setValue]);

  useEffect(() => {
    if (allStates) {
      const newListStates = listStates.map((item) => item);
      setValue("states", JSON.stringify(newListStates), true);
      setValue("cities", "[]");
    }
  }, [listStates, allStates, setValue]);

  useEffect(() => {
    const { states } = multiSelectItems;
    if (states.length > 0) {
      const newStates = states.map(({ name }) => name);
      setValue("states", JSON.stringify(newStates), true);
      const statesName = states.map((item, i) => itensComma(item, i));
      setStates(statesName);
      const filteredStates = listStates.filter((item) => !newStates.includes(item));
      setListSelectStates(filteredStates);
    } else {
      setValue("states", "[]");
      setStates(0);
      setListSelectStates(listStates);
    }
  }, [multiSelectItems, setValue]);

  const removeStateItem = (item) => {
    const { states } = multiSelectItems;
    const itemSelectedIndex = states.findIndex((selectedItem) => selectedItem.id === item.id);
    const newSelectedItems =
      itemSelectedIndex === -1
        ? [...states, item]
        : states.filter((_, index) => itemSelectedIndex !== index);

    const newMultiselectItems = { ...multiSelectItems, states: newSelectedItems };
    dispatch(LayoutActions.changeMultiSelectItems(newMultiselectItems));
  };

  useEffect(() => {
    const { cities } = multiSelectItems;
    if (cities.length > 0) {
      const newCities = cities.map((item) => {
        return {
          state: item.name.slice(-2),
          name: item.name.slice(0, -5),
        };
      });
      setValue("cities", JSON.stringify(newCities), true);
      const citiesName = cities.map((item, i) => itensComma(item, i));
      setCities(citiesName);
    } else {
      setValue("cities", "[]");
      setCities(0);
    }
  }, [multiSelectItems, setValue]);

  const removeCityItem = (item) => {
    const { cities } = multiSelectItems;
    const itemSelectedIndex = cities.findIndex((selectedItem) => selectedItem.id === item.id);
    const newSelectedItems =
      itemSelectedIndex === -1
        ? [...cities, item]
        : cities.filter((_, index) => itemSelectedIndex !== index);

    const newMultiselectItems = { ...multiSelectItems, cities: newSelectedItems };
    dispatch(LayoutActions.changeMultiSelectItems(newMultiselectItems));
  };

  return (
    <Container maxWidth="md">
      <Form onSubmit={handleSubmit(handleConfirm)}>
        <Columns columns="2">
          <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
            <InputForm
              register={register}
              type="text"
              name="code"
              label={translate("code")}
              errorMsg={errors.code?.message}
            />
          </FlexColumn>
          <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
            <SelectForm
              register={register}
              name="couponType"
              label="Tipo de cupom"
              options={[
                { text: "Valor", value: "V" },
                { text: "Período de Testes", value: "P" },
              ]}
            />
          </FlexColumn>
        </Columns>
        {!isCouponValue && (
          <Columns columns="2">
            <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
              <SelectForm
                register={register}
                name="intervalType"
                label="Tipo do Período"
                options={[
                  { text: "", value: "" },
                  { text: "Data Específica", value: "date" },
                  { text: "Dias", value: "days" },
                  { text: "Semanas", value: "weeks" },
                  { text: "Meses", value: "months" },
                ]}
              />
            </FlexColumn>
            <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
              {watchIntervalType === "date" && (
                <InputForm
                  register={register}
                  type="text"
                  name="interval"
                  label="Data Fim do Período"
                  mask={MASK_TYPES.date}
                  errorMsg={errors.interval?.message}
                />
              )}

              {["days", "weeks", "months"].includes(watchIntervalType) && (
                <InputForm
                  register={register}
                  type="number"
                  name="interval"
                  label="Intervalo"
                  errorMsg={errors.interval?.message}
                />
              )}
            </FlexColumn>
          </Columns>
        )}
        <Columns columns="2">
          <FlexColumn style={{ paddingRight: "1rem", marginBottom: "0" }}>
            <InputForm
              register={register}
              type="number"
              min={watchCouponType === "V" ? 1 : 0}
              max="100"
              name="discount"
              label={translate("discount")}
              mask={MASK_TYPES.oneHundredPercent}
              errorMsg={errors.discount?.message}
              disabled={watchCouponType === "P"}
              content="%"
            />
          </FlexColumn>
          <FlexColumn style={{ paddingLeft: "1rem", marginBottom: "0" }}>
            <InputForm
              register={register}
              type="text"
              name="startDate"
              label={translate("startDate")}
              mask={MASK_TYPES.date}
              errorMsg={errors.startDate?.message}
            />
          </FlexColumn>
        </Columns>
        <Divider />
        <Label>{translate("coveragePlans")}</Label>
        <Columns columns="2">
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="plans"
              id="choosePlan"
              label={translate("choosePlans")}
              value="1"
              handleChange={() => setAllPlan(false)}
              checked={!allPlan}
            />
          </Flex>
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="plans"
              id="allPlans"
              label={translate("allPlans")}
              value="2"
              handleChange={() => setAllPlan(true)}
              checked={allPlan}
            />
          </Flex>
        </Columns>
        {!allPlan && (
          <SelectForm
            name="choosePlans"
            label={translate("choosePlans")}
            options={mapToSelectOptions(subscriptions, "id", "name")}
            handleChange={changeSubscription}
          />
        )}
        <InputHidden ref={register} name="subscriptionPlans" />
        <Divider />
        <Label>{translate("coverageEnterprises")}</Label>
        <Columns columns="2">
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="types"
              id="chooseTypes"
              label={translate("chooseCategory")}
              value="1"
              handleChange={() => setAllTypes(false)}
              checked={!allTypes}
            />
          </Flex>
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="types"
              id="allTypes"
              label={translate("allCategory")}
              value="2"
              handleChange={() => setAllTypes(true)}
              checked={allTypes}
            />
          </Flex>
        </Columns>
        <InputHidden ref={register} name="enterpriseTypes" />
        {!allTypes && (
          <InputWrapper>
            <Label>{translate("coverageEnterprises")}</Label>
            <Field type="button" as="button" onClick={() => setEnterpriseTypesModal(true)}>
              {EnterpriseTypes || translate("select")}
            </Field>
          </InputWrapper>
        )}
        <Divider />
        <Label>{translate("coverageStates")}</Label>
        <Columns columns="2">
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="states"
              id="chooseStates"
              label={translate("chooseStates")}
              value="1"
              handleChange={() => setAllStates(false)}
              checked={!allStates}
            />
          </Flex>
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="states"
              id="allStates"
              label={translate("allStates")}
              value="2"
              handleChange={() => setAllStates(true)}
              checked={allStates}
            />
          </Flex>
        </Columns>
        <InputHidden ref={register} name="states" />
        <InputHidden ref={register} name="cities" />
        {!allStates && (
          <>
            <InputWrapper>
              <Label>{translate("state")}</Label>
              <Field type="button" as="button" onClick={() => setStatesModal(true)}>
                {States || translate("select")}
              </Field>
            </InputWrapper>
            <SelectedItemsDisplay
              label={translate("selectedStates")}
              selectedItems={multiSelectItems.states}
              handleChange={removeStateItem}
            />
            <Checkbox
              name="selectCities"
              id="selectCities"
              label={translate("selectSpecificCities")}
              value="2"
              handleChange={handleChangeselectCities}
              checked={selectCities}
            />
            {selectCities && (
              <>
                <SelectForm
                  name="stateToselectCities"
                  label={translate("chooseState")}
                  options={mapToSelectOptions(listSelectStates, "id", "name")}
                  handleChange={handleChangeStateToselectCities}
                />
                <InputWrapper>
                  <Label>{translate("city")}</Label>
                  <Field type="button" as="button" onClick={() => setCitiesModal(true)}>
                    {Cities || translate("select")}
                  </Field>
                </InputWrapper>
                <SelectedItemsDisplay
                  label={translate("selectedCities")}
                  selectedItems={multiSelectItems.cities}
                  handleChange={removeCityItem}
                />
              </>
            )}
          </>
        )}
        <Divider />
        <Label>{translate("programming")}</Label>
        <InputGroup paddingLeft="0" columns="3">
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="programming"
              id="byPeriod"
              label={translate("byPeriod")}
              value="1"
              handleChange={() => setProgramming(1)}
              checked={programming === 1}
            />
          </Flex>
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="programming"
              id="numberOfUses"
              label={translate("numberOfUses")}
              value="2"
              handleChange={() => setProgramming(2)}
              checked={programming === 2}
            />
          </Flex>
          <Flex marginBottom="2.4rem">
            <RadioButton
              name="programming"
              id="both"
              label={translate("both")}
              value="2"
              handleChange={() => setProgramming(3)}
              checked={programming === 3}
            />
          </Flex>
        </InputGroup>
        <InputGroup paddingLeft="0" columns="3">
          {programming !== 2 && (
            <InputForm
              register={register}
              type="text"
              name="endDate"
              label={translate("endDate")}
              mask={MASK_TYPES.date}
            />
          )}
        </InputGroup>
        {programming !== 1 && (
          <InputForm
            register={register}
            type="number"
            name="amount"
            label={translate("numberOfUses")}
          />
        )}
        <Divider />
        <InputForm
          register={register}
          type="text"
          name="description"
          label={translate("description")}
        />
        <InputHidden ref={submitRef} type="submit" value="Submit" />
      </Form>
    </Container>
  );
};

CouponsRegister.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  submitRef: PropTypes.object.isRequired,
  subscriptions: PropTypes.array.isRequired,
  avalableEnterpriseTypes: PropTypes.array.isRequired,
  setEnterpriseTypesModal: PropTypes.func.isRequired,
  setStatesModal: PropTypes.func.isRequired,
  setCitiesModal: PropTypes.func.isRequired,
  handleChangeStateToselectCities: PropTypes.func.isRequired,
};

export default CouponsRegister;
