import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Subtitle, Span } from "components/core/Typography";
import { Flex, Row, Columns, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import { useForm } from "react-hook-form";
import RadioButton from "components/core/RadioButton";
import InputForm from "components/core/InputForm";
import Button from "components/core/Button";
import { Trans } from "react-i18next";
import * as yup from "yup";
import * as S from "./styled";

export const CityRegistration = ({ onBack, onContinue }) => {
  const [isStateTrue, setIsStateTrue] = useState(true);

  const validation = yup.object().shape({
    cityRegistration: yup.string().when("regs", {
      is: "1",
      then: yup.string().required(<Trans i18nKey="required.cityRegistration" />),
    }),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: validation,
    mode: "onBlur",
    submitFocusError: true,
  });

  const handleNextStep = (data) => {
    const event = "EtapaInscricaoMunicipal";
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="46%" />
        </Container>
        <Subtitle mb="64px">Sua empresa possui Inscrição Municipal?</Subtitle>
        <Form onSubmit={handleSubmit(handleNextStep)}>
          <Flex marginBottom="4rem">
            <Columns>
              <RadioButton
                register={register}
                name="regs"
                label="Sim"
                id="yes"
                value="1"
                onClick={() => setIsStateTrue(true)}
                checked={isStateTrue}
                handleChange={() => setIsStateTrue(true)}
              />
              <InputForm
                register={register}
                type="text"
                name="cityRegistration"
                label="Inscrição Municipal"
                errorMsg={errors.cityRegistration?.message}
              />
              <RadioButton
                register={register}
                name="regs"
                id="no"
                label="Não(Isento)"
                value="2"
                onClick={() => setIsStateTrue(false)}
                checked={isStateTrue === false}
                handleChange={() => setIsStateTrue(false)}
              />
            </Columns>
          </Flex>
          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
            <Button type="submit">Continuar</Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

CityRegistration.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default CityRegistration;
