import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/core";
import Button from "components/core/Button";
import { urls } from "utils/constants";

const EmitterParams = () => {
  const { t: translate } = useTranslation();
  const showModal = true;

  return (
    <>
      <Modal title={translate("chooseOptionvView")} showModal={showModal} showActions={false}>
        <Button url={urls.LINKS.paramsEmiiterActivityGroups} variant="outline" large="true">
          {translate("activityGroup")}
        </Button>
        <Button url={urls.LINKS.paramsEmiiterSources} variant="outline" large="true">
          {translate("sources")}
        </Button>
        <Button url={urls.LINKS.paramsEmiiterResponsibleSale} variant="outline" large="true">
          {translate("responsibleSale")}
        </Button>
        <Button url={urls.LINKS.paramsEmiiterPartner} variant="outline" large="true">
          {translate("partners")}
        </Button>
      </Modal>
    </>
  );
};

export default EmitterParams;
