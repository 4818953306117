import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div.attrs(
  ({ maxWidth, verticalPadding, margin, minWidth, verticalAlign, horizontalPadding }) => ({
    maxWidth: maxWidth === "md" ? "60rem" : maxWidth ?? "100%",
    verticalPadding: verticalPadding ?? "3.2rem",
    margin: margin ?? null,
    minWidth: minWidth ?? "unset",
    verticalAlign: verticalAlign ?? "unset",
    horizontalPadding: horizontalPadding ?? "3.2rem",
  }),
)`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: ${({ verticalPadding }) => verticalPadding}
    ${({ horizontalPadding }) => horizontalPadding}; */
  align-items: ${({ verticalAlign }) => verticalAlign};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  margin: ${({ margin }) => margin};
  ${({ horizontalPadding, verticalPadding }) => `padding: ${verticalPadding} ${horizontalPadding}`};
`;

export const Row = styled.div.attrs(
  ({ horizontalAlign, verticalAlign, marginBottom, padding }) => ({
    horizontalAlign: horizontalAlign ?? "unset",
    verticalAlign: verticalAlign ?? "unset",
    marginBottom: marginBottom ?? "3.2rem",
    padding: padding ?? "unset",
  }),
)`
  display: flex;
  align-items: center;
  justify-content: ${({ horizontalAlign }) => horizontalAlign};
  align-items: ${({ verticalAlign }) => verticalAlign};
  width: 100%;
  padding: ${({ padding }) => padding};

  &:not(:last-child) {
    margin-bottom: ${({ marginBottom }) => marginBottom};
  }

  & > svg {
    margin-right: 1rem;
  }
`;

export const RowFlex = styled.div.attrs(
  ({ direction, horizontalAlign, verticalAlign, marginBottom, padding, width }) => ({
    horizontalAlign: horizontalAlign ?? "unset",
    verticalAlign: verticalAlign ?? "unset",
    direction: direction ?? "row",
    marginBottom: marginBottom ?? "3.2rem",
    padding: padding ?? "unset",
    width: width ?? "100%",
  }),
)`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ verticalAlign }) => verticalAlign};
  justify-content: ${({ horizontalAlign }) => horizontalAlign};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};

  &:not(:last-child) {
    margin-bottom: ${({ marginBottom }) => marginBottom};
  }

  & > svg {
    margin-right: 1rem;
  }

  @media only screen and (max-width: 460px) {
    flex-direction: column-reverse;
    & Button {
      width: 100%;
      margin-bottom: 1rem;
      margin-right: 0;
    }
    & Button:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const Columns = styled.div.attrs((props) => ({
  columns: props.columns ?? "1",
  marginBottom: props.marginBottom ?? "0",
}))`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-template-rows: repeat(auto-fit, 1fr);
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const Flex = styled.div.attrs(
  ({ verticalAlign, horizontalAlign, direction, marginBottom, padding }) => ({
    verticalAlign: verticalAlign ?? "flex-start",
    horizontalAlign: horizontalAlign ?? "flex-start",
    direction: direction ?? "column",
    marginBottom: marginBottom ?? "unset",
    marginLastChild: marginBottom ?? "2.4rem",
    padding: padding ?? "unset",
  }),
)`
  display: flex;
  justify-content: ${({ verticalAlign }) => verticalAlign};
  align-items: ${({ horizontalAlign }) => horizontalAlign};
  flex-direction: ${({ direction }) => direction};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  height: 100%;
  max-height: 100%;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${({ marginLastChild }) => marginLastChild};
  }

  & > svg {
    margin-right: var(--space-xxs);
  }

  ${media.lessThan("large")`
    justify-content: flex-start;
  `}
`;

export const FlexButtons = styled.div.attrs(
  ({ verticalAlign, horizontalAlign, direction, marginBottom, padding }) => ({
    verticalAlign: verticalAlign ?? "flex-start",
    horizontalAlign: horizontalAlign ?? "center",
    direction: direction ?? "column",
    marginBottom: marginBottom ?? "unset",
    marginLastChild: marginBottom ?? "2.4rem",
    padding: padding ?? "unset",
  }),
)`
  display: flex;
  justify-content: ${({ verticalAlign }) => verticalAlign};
  align-items: ${({ horizontalAlign }) => horizontalAlign};
  flex-direction: ${({ direction }) => direction};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding: ${({ padding }) => padding};
  height: 100%;
  max-height: 100%;

  &:not(:last-child) {
    margin-bottom: ${({ marginLastChild }) => marginLastChild};
  }

  & > svg {
    margin-right: var(--space-xxs);
  }

  ${media.lessThan("large")`
    justify-content: flex-start;
  `}

  ${media.lessThan("515px")`
    flex-direction: column;
    align-items: center;
    & Button:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  `}
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const Grid = styled.div.attrs(({ cols }) => ({
  columns: cols ?? 1,
}))`
  display: grid;
  grid-template-columns: 30rem 1fr;
  height: 100%;
  width: 100%;
`;

export const IconWrapper = styled.div.attrs(({ marginRight }) => ({
  marginRight: marginRight ?? "1rem",
}))`
  margin-right: ${({ marginRight }) => marginRight};
`;

export const ColumnFlex = styled.div.attrs(({ horizontalAlign, verticalAlign }) => ({
  horizontalAlign: horizontalAlign ?? "center",
  verticalAlign: verticalAlign ?? "start",
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({ horizontalAlign }) => horizontalAlign};
`;
