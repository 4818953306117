import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string().email("O email não é válido").required("O email é necessário"),
  password: Yup.string().min(6, "A senha é muito curta").required("A senha é necessária"),
  remember: Yup.boolean(),
});

export const defaultValues = {
  email: "",
  password: "",
  remember: false,
};
