import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: CnpjCheckerTypes, Creators: CnpjCheckerActions } = createActions({
  checkCnpj: ["params"],
  checkCnpjSuccess: ["payload"],
  checkCnpjFailure: ["error"],
  cleanState: null,
});

const INITIAL_STATE = {
  enterpriseInfo: {},
  cnpjCheckRequestStatus: {
    CHECK_CNPJ: REQUEST_NOT_STARTED,
  },
};

const checkCnpj = (state) => ({
  ...state,
  cnpjCheckRequestStatus: {
    ...state.cnpjCheckRequestStatus,
    CHECK_CNPJ: REQUEST_PENDING,
  },
});

const checkCnpjSuccess = (state, { payload }) => ({
  ...state,
  enterpriseInfo: payload,
  cnpjCheckRequestStatus: {
    ...state.cnpjCheckRequestStatus,
    CHECK_CNPJ: REQUEST_RESOLVED,
  },
});

const checkCnpjFailure = (state, { error }) => ({
  ...state,
  error,
  cnpjCheckRequestStatus: {
    ...state.cnpjCheckRequestStatus,
    CHECK_CNPJ: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [CnpjCheckerTypes.CHECK_CNPJ]: checkCnpj,
  [CnpjCheckerTypes.CHECK_CNPJ_SUCCESS]: checkCnpjSuccess,
  [CnpjCheckerTypes.CHECK_CNPJ_FAILURE]: checkCnpjFailure,
  [CnpjCheckerTypes.CLEAN_STATE]: cleanState,
});
