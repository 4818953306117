export const sanitizePhone = (phone) => {
  let newPhone = phone.replace(/ *\(/gm, "");
  newPhone = newPhone.replace(/ *\)/gm, "");
  newPhone = newPhone.replace("-", "");
  newPhone = newPhone.replace(" ", "");

  return newPhone;
};

export const sanitizeCPF = (cpf) => {
  let newCPF = cpf.replace(/ *\(/gm, "");
  newCPF = newCPF.replace(".", "");
  newCPF = newCPF.replace(".", "");
  newCPF = newCPF.replace("-", "");

  return newCPF;
};

export const parseMoneyToFloat = (money) =>
  parseFloat(
    money
      // eslint-disable-next-line
      .replace(/[^0-9\.,]/gm, "")
      .replace(/\./g, "")
      .replace(/,/g, "."),
  );

export const transformEmptyToUndefined = (currentValue, originalValue) =>
  originalValue === "" ? undefined : currentValue;
