import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ShieldAction = ({ children, roles }) => {
  const userRole = useSelector(({ auth }) => auth.claims.user.roles);
  const hasPermission = roles ? roles.includes(userRole) : true;

  return <>{hasPermission ? children : null}</>;
};

ShieldAction.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

ShieldAction.defaultProps = {
  roles: null,
};

export default ShieldAction;
