import { Span } from "components/core";
import { RenderHeaders, Table, TableBody, TableHeader, TableItem } from "components/core/Table";
import React from "react";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import PropTypes from "prop-types";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import Pagination from "components/core/Pagination";
import { REQUEST_NOT_STARTED, REQUEST_PENDING } from "utils/constants";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { useSelector } from "react-redux";
import * as S from "./styled";

const ParamsEmitterLists = ({
  items,
  appearActive,
  deleteActivity,
  appearDelete,
  updateActive,
  setShowModalUpdate,
  setDataParam,
  params,
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
}) => {
  const headers = [
    { slug: "id", title: "ID" },
    { slug: "name", title: "Nome" },
    { slug: "active", title: "Ativo?" },
    { slug: "", title: "Ações" },
  ];
  const headersNotAppear = [
    { slug: "id", title: "ID" },
    { slug: "name", title: "Nome" },
    { slug: "", title: "Ações" },
  ];

  const { ParamsEmitterRequests } = useSelector(({ paramsEmiiter }) => paramsEmiiter);

  const requestPending = (status) => {
    return status === REQUEST_PENDING || status === REQUEST_NOT_STARTED;
  };

  return requestPending(ParamsEmitterRequests.FETCH_PARAMS) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={appearActive ? headers : headersNotAppear}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(({ id, name, active }) => {
            return (
              <tr key={id}>
                <TableItem>
                  <Span>{id}</Span>
                </TableItem>
                <TableItem>
                  <Span>{name}</Span>
                </TableItem>
                {appearActive && (
                  <TableItem>
                    {active ? (
                      <Span>
                        <StatusDot type={StatusTypes.SUCCESS} />
                        Sim
                      </Span>
                    ) : (
                      <Span>
                        <StatusDot type={StatusTypes.ERROR} />
                        Não
                      </Span>
                    )}
                  </TableItem>
                )}
                <TableItem>
                  <S.ActionDiv>
                    {appearActive &&
                      (active ? (
                        <S.IconCancel
                          title="Desativar"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            updateActive({ id, name, active });
                          }}
                        />
                      ) : (
                        <S.IconCheckout
                          title="Ativar"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            updateActive({ id, name, active });
                          }}
                        />
                      ))}
                    <S.IconEdit
                      title="Editar"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDataParam({ id, name });
                        setShowModalUpdate(true);
                      }}
                    />
                    {appearDelete && (
                      <S.IconDelete
                        title="Deletar"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deleteActivity({ id });
                        }}
                      />
                    )}
                  </S.ActionDiv>
                </TableItem>
              </tr>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

ParamsEmitterLists.propTypes = {
  items: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

ParamsEmitterLists.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(ParamsEmitterLists);
