import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Checkbox from "components/core/Checkbox";
import * as S from "./styled";

export const RenderHeaders = ({ headers, setOrderBy, orderBy, order }) => {
  const { t: translate } = useTranslation();

  return headers.map(({ slug, title, i18nKey, width, textAlign }) => (
    <TableHeaderButton
      key={slug}
      handleClick={() => setOrderBy(slug)}
      activeOrder={orderBy === slug}
      order={order}
      width={width}
      textAlign={textAlign}
    >
      {translate(i18nKey ?? title)}
    </TableHeaderButton>
  ));
};

const TableCheckbox = ({ name, handleChange, checked }) => (
  <S.CheckboxTableWrapper>
    <Checkbox name={name} handleChange={handleChange} checked={checked} />
  </S.CheckboxTableWrapper>
);
const TableCheckboxAll = ({ selectedLength }) => {
  const handleChange = () => {};

  return (
    <S.CheckboxTableWrapper>
      <Checkbox
        name="all"
        handleChange={handleChange}
        primary={selectedLength === 10}
        secondary={selectedLength < 10}
      />
    </S.CheckboxTableWrapper>
  );
};

const TableItem = ({ children, textAlign, handleClick }) => (
  <S.TableItem textAlign={textAlign} hasAction={!!handleClick} onClick={handleClick}>
    {children}
  </S.TableItem>
);

const TableIcons = ({ handleClickEdit, handleClickDelete, item }) => (
  <S.IconsWrapper>
    <S.Edit to={handleClickEdit} />
    <S.Delete onClick={() => handleClickDelete(item)} />
  </S.IconsWrapper>
);

const TableBody = ({ children }) => <tbody>{children}</tbody>;

const TableHeaderItem = ({ textAlign, children, width, order, activeOrder }) => (
  <S.TableHeaderItem textAlign={textAlign} width={width} order={order} activeOrder={activeOrder}>
    {children}
  </S.TableHeaderItem>
);

const TableHeaderButton = ({ textAlign, children, handleClick, width, order, activeOrder }) => (
  <TableHeaderItem textAlign={textAlign} width={width} order={order} activeOrder={activeOrder}>
    <button type="button" onClick={handleClick}>
      {children}
    </button>
  </TableHeaderItem>
);

const TableHeader = ({ children }) => (
  <S.TableHeader>
    <tr>{children}</tr>
  </S.TableHeader>
);

const Table = ({ children }) => (
  <S.TableContainer>
    <S.Table>{children}</S.Table>
  </S.TableContainer>
);

TableCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

TableCheckbox.defaultProps = {
  checked: false,
};

TableCheckboxAll.propTypes = {
  selectedLength: PropTypes.number.isRequired,
};

TableItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  textAlign: PropTypes.string,
  handleClick: PropTypes.func,
};

TableItem.defaultProps = {
  textAlign: null,
  handleClick: null,
};

TableIcons.propTypes = {
  handleClickEdit: PropTypes.string.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  item: PropTypes.object,
};

TableBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

TableHeaderItem.propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.string,
  width: PropTypes.string,
  order: PropTypes.string,
  activeOrder: PropTypes.bool,
};

TableHeaderButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  textAlign: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  width: PropTypes.string,
  order: PropTypes.string,
  activeOrder: PropTypes.bool,
};

TableHeaderItem.defaultProps = {
  textAlign: null,
  width: "0",
  order: null,
  activeOrder: false,
};

TableHeaderButton.defaultProps = {
  textAlign: null,
  width: "0",
  order: null,
  activeOrder: false,
};

TableHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export {
  Table,
  TableHeader,
  TableHeaderItem,
  TableHeaderButton,
  TableCheckbox,
  TableCheckboxAll,
  TableBody,
  TableItem,
  TableIcons,
};
