import { useCallback, useRef } from "react";
import { isPending } from "components/core/WithSpinner";

const useInfinitePagination = (requestStatus, hasMore, setPage) => {
  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (isPending(requestStatus)) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 0.5 },
      );

      if (node) {
        observer.current.observe(node);
      }
    },
    [requestStatus, hasMore, setPage],
  );

  return lastItemRef;
};

export default useInfinitePagination;
