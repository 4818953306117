import styled from "styled-components";
import { ReactComponent as CheckoutIcon } from "assets/icons/icon-check-blue.svg";
import { ReactComponent as CancelIcon } from "assets/icons/icon-not-check.svg";
import { ReactComponent as EditIcon } from "assets/icons/icon-edit.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";

export const IconCheckout = styled(CheckoutIcon)`
  height: 22px;
  width: 22px;
`;
export const IconCancel = styled(CancelIcon)`
  height: 22px;
  width: 22px;
`;
export const IconEdit = styled(EditIcon)`
  height: 22px;
  width: 22px;
`;
export const IconDelete = styled(DeleteIcon)`
  height: 22px;
  width: 22px;
`;
export const ActionDiv = styled.div`
  display: flex;
  gap: 3px;
`;
