import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PasswordActions } from "store/ducks/password";
import ForgotPassword from "components/presentation/Password/ForgotPassword";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import useFeedback from "hooks/useFeedback";
import {
  REQUEST_PENDING,
  REQUEST_NOT_STARTED,
  NOT_FOUND,
  INTERNAL_ERROR,
} from "utils/constants/request";
import { FEEDBACK_FORGOT_PASSWORD as feedbackDictionary } from "utils/constants/dictionaries";

const ForgotPasswordWithSpinner = WithSpinner(ForgotPassword);

const ForgotPasswordContainer = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [feedback, setFeedback] = useFeedback();
  const dispatch = useDispatch();
  const {
    requestResponse: { status: statusCode },
    requestStatus,
  } = useSelector(({ password }) => password);

  useEffect(() => {
    if (requestStatus !== REQUEST_PENDING && requestStatus !== REQUEST_NOT_STARTED) {
      if (statusCode === NOT_FOUND) {
        setErrorMsg("E-mail não cadastrado.");
      } else {
        const newFeedback = feedbackDictionary[statusCode] ?? feedbackDictionary[INTERNAL_ERROR];
        setFeedback({
          ...newFeedback,
        });
      }
    }

    return () => setFeedback({});
  }, [statusCode, requestStatus]);

  const handleSubmit = (values) => dispatch(PasswordActions.sendTokenByEmail(values));

  return (
    <ForgotPasswordWithSpinner
      isLoading={isPending(requestStatus)}
      onSubmit={handleSubmit}
      showForm={!feedback?.show}
      errorMsg={errorMsg}
    />
  );
};

export default ForgotPasswordContainer;
