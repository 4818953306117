import styled from "styled-components";
import { View as ViewIcon } from "./icons";

export const IconView = styled(ViewIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const ChargeHistoryContainer = styled.div.attrs((props) => ({
  marginTop: props.marginTop ?? "0",
  marginRight: props.marginRight ?? "0",
  marginBottom: props.marginBottom ?? "2.4rem",
  marginLeft: props.marginLeft ?? "0",
}))`
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`};
`;
