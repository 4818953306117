import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Creators as RulesActions } from "store/ducks/roles";
import { LayoutActions } from "store/ducks/layout";
import { EmitterActions } from "store/ducks/emitter";
import ShieldAction from "components/core/ShieldAction";
import Portal from "components/core/Portal";
import { Span } from "components/core/Typography";
import ManageSidebar from "components/core/ManageSidebar";
import EmitterSidebar from "components/core/EmitterSidebar";
import EmitterSearchSidebar from "components/core/EmitterSearchSidebar";
import useEmitter from "hooks/useEmitter";
import useRequest from "hooks/useRequest";
import { urls } from "utils/constants";
import { ROLE_MASTER } from "utils/constants/roles";
import { ReactComponent as IconSearch } from "assets/icons/icon-search.svg";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/logout.svg";
import { Creators as AuthActions } from "store/ducks/auth";
import { People as PeopleIcon, Manage as ManageIcon } from "./icons";
import * as S from "./styled";

export const Menu = ({
  menu: { show, path, i18nKey, Icon, roles },
  menuKey,
  countNotifications,
  totalChatsUnread,
  changeLayout,
}) => {
  const { t: translate } = useTranslation();
  const counters = {
    notifications: countNotifications,
    customerService: totalChatsUnread,
  };

  if (!show || !path) return null;

  return (
    <ShieldAction roles={roles}>
      <S.MenuItem
        changeLayout={changeLayout}
        to={path}
        key={path}
        count={counters[menuKey] ?? 0}
        activeClassName="active"
      >
        <b />
        <b />
        {Icon && <Icon />}
        {translate(i18nKey)}
      </S.MenuItem>
    </ShieldAction>
  );
};

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pinEmitter = useEmitter();
  const { t: translate } = useTranslation();
  const {
    user: { name: userName, roles: rolesPermission },
  } = useSelector((state) => state.auth.claims);
  const { roles } = useSelector((state) => state.roles);
  const { totalChatsUnread } = useSelector(({ chat }) => chat);
  const { emitterToPinDetails } = useSelector(({ emitter }) => emitter);
  const { isManageMenuOpen, isEmitterMenuOpen, isEmitterSearchOpen } = useSelector(
    ({ layout }) => layout,
  );
  const {
    notifications: { unreadNotifications },
    NotificationRequests,
  } = useSelector(({ notification }) => notification);
  const [unreadCount, setUnreadCount] = useState(unreadNotifications);

  useEffect(() => {
    if (!roles) {
      dispatch(RulesActions.getRolesStart());
    }
  }, [dispatch, roles]);

  useEffect(() => {
    setUnreadCount(unreadNotifications);
  }, [unreadNotifications, setUnreadCount]);

  useEffect(() => {
    dispatch(LayoutActions.toggleEmitterMenu(false));
  }, [location, pinEmitter, dispatch]);

  useRequest({
    request: NotificationRequests.DELETE_NOTIFICATION,
  });

  const logout = () => dispatch(AuthActions.authLogout());

  useEffect(() => {
    if (pinEmitter?.id) {
      dispatch(EmitterActions.fetchEmittersToPinById(pinEmitter.id));
    }
  }, [dispatch, pinEmitter]);

  useEffect(() => {
    if (rolesPermission === "client") {
      dispatch(LayoutActions.pinEmitterClear());
    }
  }, [dispatch, rolesPermission]);

  return (
    <Portal>
      <S.Navigation
        changeLayout={
          (rolesPermission === "client" ||
            rolesPermission === "accountant" ||
            rolesPermission === "marketing") &&
          true
        }
      >
        <S.LogoLink to={urls.ROUTES.app.path}>
          <Logo />
        </S.LogoLink>
        {(rolesPermission === "master" || rolesPermission === "accountant") && (
          <S.ButtonSearch
            isOpen={isEmitterSearchOpen}
            onClick={() => dispatch(LayoutActions.toggleEmitterSearch())}
          >
            <IconSearch />
            {translate("searchEmitter")}
          </S.ButtonSearch>
        )}

        <S.ProfileName>
          {rolesPermission === "client" ||
          rolesPermission === "accountant" ||
          rolesPermission === "marketing" ? (
            <Span customStyle={{ "text-align": "center", "letter-spacing": "-0.01em" }}>
              {translate("hi")}, <strong>{userName}</strong>
            </Span>
          ) : (
            <Span>
              {translate("hi")}, <strong>{userName}</strong>
            </Span>
          )}
          {rolesPermission === "master" && (
            <Link to={`${urls.ROUTES.profile.path}`}>
              <Span>{translate("seeProfile")}</Span>
            </Link>
          )}
          {(rolesPermission === "client" ||
            rolesPermission === "accountant" ||
            rolesPermission === "marketing") && (
            <S.Box>
              <button type="button" onClick={logout}>
                <LogoutIcon />
                <span>{translate("exit")}</span>
              </button>
            </S.Box>
          )}
        </S.ProfileName>
        <S.ItemsWrapper>
          {Object.keys(urls.ROUTES).map((key) => (
            <Menu
              key={key}
              menuKey={key}
              menu={urls.ROUTES[key]}
              isEmitterActive={!!pinEmitter}
              countNotifications={unreadCount}
              totalChatsUnread={totalChatsUnread}
              changeLayout={
                (rolesPermission === "client" ||
                  rolesPermission === "accountant" ||
                  rolesPermission === "marketing") &&
                true
              }
            />
          ))}

          <ShieldAction roles={[ROLE_MASTER]}>
            <S.MenuItem
              withArrow
              isOpen={isManageMenuOpen}
              as="button"
              onClick={() => dispatch(LayoutActions.toggleManageMenu(!isManageMenuOpen))}
            >
              <ManageIcon />
              <span>{translate("manage")}</span>
              <S.Arrow />
            </S.MenuItem>
          </ShieldAction>
          {(rolesPermission === "master" || rolesPermission === "accountant") && (
            <>
              {!!pinEmitter && (
                <S.MenuItem
                  withArrow
                  isOpen={isEmitterMenuOpen}
                  as="button"
                  onClick={() => dispatch(LayoutActions.toggleEmitterMenu(!isEmitterMenuOpen))}
                >
                  <PeopleIcon />
                  <span>{pinEmitter?.enterpriseName}</span>
                  <S.Arrow />
                </S.MenuItem>
              )}
            </>
          )}
        </S.ItemsWrapper>
      </S.Navigation>
      <ManageSidebar isOpen={isManageMenuOpen} />
      {pinEmitter && <EmitterSidebar isOpen={isEmitterMenuOpen} emitter={emitterToPinDetails} />}
      <EmitterSearchSidebar />
    </Portal>
  );
};

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  menuKey: PropTypes.string.isRequired,
  countNotifications: PropTypes.number,
  totalChatsUnread: PropTypes.number,
  changeLayout: PropTypes.bool,
};

Menu.defaultProps = {
  countNotifications: 0,
  totalChatsUnread: 0,
  changeLayout: false,
};

export default SideBar;
