import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Campo código obrigatório"),
  name: Yup.string().required("Campo descrição obrigatório"),
  csosn: Yup.string().required("Campo CSOSN obrigatório"),
  ncm: Yup.string().required("Campo NCM obrigatório"),
});

const defaultValues = {
  code: 0,
  name: "",
  csosn: "",
  ncm: "",
};

export { validationSchema, defaultValues };
