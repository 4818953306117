import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: NotificationTypes, Creators: NotificationActions } = createActions({
  fetchNotifications: ["params", "callByService"],
  fetchNotificationsSuccess: ["payload", "lastCallByService"],
  addNotification: ["notification"],
  fetchTypes: ["params"],
  fetchTypesSuccess: ["payload"],
  fetchTotalNotificationsUnread: null,
  fetchTotalNotificationsUnreadSuccess: ["total"],
  updateNotification: ["id"],
  updateNotificationSuccess: ["payload"],
  deleteNotification: ["id"],
  deleteNotificationSuccess: ["id", "payload"],
  deleteAllNotifications: null,
  deleteAllNotificationsSuccess: ["payload"],
  notificationRequestFailure: ["error", "originalType"],
  clearState: null,
});

export const INITIAL_STATE = {
  notifications: {
    items: [],
  },
  lastCallByService: false,
  types: [],
  deletedNotificationId: 0,
  NotificationRequests: {
    [NotificationTypes.FETCH_NOTIFICATIONS]: REQUEST_NOT_STARTED,
    [NotificationTypes.FETCH_TYPES]: REQUEST_NOT_STARTED,
    [NotificationTypes.UPDATE_NOTIFICATION]: REQUEST_NOT_STARTED,
    [NotificationTypes.DELETE_NOTIFICATION]: REQUEST_NOT_STARTED,
    [NotificationTypes.DELETE_ALL_NOTIFICATIONS]: REQUEST_NOT_STARTED,
  },
};

const fetchNotifications = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.FETCH_NOTIFICATIONS]: REQUEST_PENDING,
  },
});

const fetchNotificationsSuccess = (state, { payload, lastCallByService }) => ({
  ...state,
  notifications: payload || { items: [], unreadNotifications: 0 },
  lastCallByService,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.FETCH_NOTIFICATIONS]: REQUEST_RESOLVED,
  },
});

const addNotification = (state, { notification }) => ({
  ...state,
  notifications: {
    unreadNotifications: state.notifications.unreadNotifications + 1,
    items: [notification, ...state.notifications.items],
  },
});

const fetchTypes = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.FETCH_TYPES]: REQUEST_PENDING,
  },
});

const fetchTypesSuccess = (state, { payload }) => ({
  ...state,
  types: payload,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.FETCH_TYPES]: REQUEST_RESOLVED,
  },
});

const fetchTotalNotificationsUnread = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.FETCH_TYPES]: REQUEST_PENDING,
  },
});

const fetchTotalNotificationsUnreadSuccess = (state, { total }) => ({
  ...state,
  notifications: {
    unreadNotifications: total,
    items: state.notifications.items,
  },
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.FETCH_TYPES]: REQUEST_RESOLVED,
  },
});

const updateNotification = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.UPDATE_NOTIFICATION]: REQUEST_PENDING,
  },
});

const updateNotificationSuccess = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.UPDATE_NOTIFICATION]: REQUEST_RESOLVED,
  },
});

const deleteNotification = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.DELETE_NOTIFICATION]: REQUEST_PENDING,
  },
});

const deleteNotificationSuccess = (state, { id }) => ({
  ...state,
  deletedNotificationId: id,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.DELETE_NOTIFICATION]: REQUEST_RESOLVED,
  },
});

const deleteAllNotifications = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.DELETE_ALL_NOTIFICATIONS]: REQUEST_PENDING,
  },
});

const deleteAllNotificationsSuccess = (state) => ({
  ...state,
  NotificationRequests: {
    ...state.NotificationRequests,
    [NotificationTypes.DELETE_ALL_NOTIFICATIONS]: REQUEST_RESOLVED,
  },
});

const notificationRequestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  NotificationRequests: {
    ...state.NotificationRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const clearState = (state) => ({
  ...INITIAL_STATE,
  notifications: {
    ...INITIAL_STATE.notifications,
    items: [],
    unreadNotifications: state.notifications.unreadNotifications ?? 0,
  },
});

export default createReducer(INITIAL_STATE, {
  [NotificationTypes.FETCH_NOTIFICATIONS]: fetchNotifications,
  [NotificationTypes.FETCH_NOTIFICATIONS_SUCCESS]: fetchNotificationsSuccess,
  [NotificationTypes.FETCH_TOTAL_NOTIFICATIONS_UNREAD]: fetchTotalNotificationsUnread,
  [NotificationTypes.FETCH_TOTAL_NOTIFICATIONS_UNREAD_SUCCESS]: fetchTotalNotificationsUnreadSuccess,
  [NotificationTypes.ADD_NOTIFICATION]: addNotification,
  [NotificationTypes.FETCH_TYPES]: fetchTypes,
  [NotificationTypes.FETCH_TYPES_SUCCESS]: fetchTypesSuccess,
  [NotificationTypes.UPDATE_NOTIFICATION]: updateNotification,
  [NotificationTypes.UPDATE_NOTIFICATION_SUCCESS]: updateNotificationSuccess,
  [NotificationTypes.DELETE_NOTIFICATION]: deleteNotification,
  [NotificationTypes.DELETE_NOTIFICATION_SUCCESS]: deleteNotificationSuccess,
  [NotificationTypes.DELETE_ALL_NOTIFICATIONS]: deleteAllNotifications,
  [NotificationTypes.DELETE_ALL_NOTIFICATIONS_SUCCESS]: deleteAllNotificationsSuccess,
  [NotificationTypes.NOTIFICATION_REQUEST_FAILURE]: notificationRequestFailure,
  [NotificationTypes.CLEAR_STATE]: clearState,
});
