import { put, takeLatest, cancelled } from "redux-saga/effects";
import axios from "axios";
import api from "services/api";
import { CstTypes, CstActions } from "store/ducks/cst";
import { GET, PATCH, DELETE, CST_BY_ID, CST, ENTERPRISE_CST } from "utils/constants";

const { CancelToken } = axios;

function* fetchCstDetails({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: CST_BY_ID(id),
      });
      yield put(CstActions.fetchCstDetailsSuccess(data));
    }
  } catch (error) {
    yield put(CstActions.cstRequestFailure(error, type));
  }
}

function* fetchAvailableCst({ params, type }) {
  const source = CancelToken.source();

  try {
    const { data } = yield api({
      method: GET,
      url: CST,
      params,
      cancelToken: source.token,
    });
    yield put(CstActions.fetchAvailableCstSuccess(data));
  } catch (error) {
    yield put(CstActions.cstRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}
function* fetchAvailableCstWithParams({ id, params, type }) {
  const source = CancelToken.source();

  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_CST(id),
        params,
        cancelToken: source.token,
      });
      yield put(CstActions.fetchAvailableCstWithParamsSuccess(data));
    }
  } catch (error) {
    yield put(CstActions.cstRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

function* editCst({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PATCH,
        url: CST_BY_ID(id),
        data: payload,
      });
      yield put(CstActions.editCstSuccess(data));
    }
  } catch (error) {
    yield put(CstActions.cstRequestFailure(error, type));
  }
}

function* deleteCst({ payload, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: CST,
      data: payload,
    });
    yield put(CstActions.deleteCstSuccess(data));
  } catch (error) {
    yield put(CstActions.cstRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(CstTypes.FETCH_CST_DETAILS, fetchCstDetails);
  yield takeLatest(CstTypes.FETCH_AVAILABLE_CST, fetchAvailableCst);
  yield takeLatest(CstTypes.FETCH_AVAILABLE_CST_WITH_PARAMS, fetchAvailableCstWithParams);
  yield takeLatest(CstTypes.EDIT_CST, editCst);
  yield takeLatest(CstTypes.DELETE_CST, deleteCst);
}
