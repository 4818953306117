import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "components/core/Button";
import { Container, RowFlex } from "components/core/Grid";
import Modal from "components/core/Modal";
import { ModalActions } from "components/core/Modal/styled";
import { Span } from "components/core/Typography";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import { Spinner } from "components/core/WithSpinner/styled";
import Charge from "components/presentation/Financial/Payment/Charge";
import { useFinancialSubscription, useRequest } from "hooks";

const ChargeWithSpinner = WithSpinner(Charge);

const ChargeContainer = () => {
  const {
    chargeInvoiceResponse,
    getAvailableInvoicesToCharge,
    handleOnRejected,
    handleOnResolved,
    resetChargeStates,
    resetState,
    showChargeModal,
    subscription: { id },
    toggleChargeModal,
    validationErrors,
    requestStatus: { CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE: chargeStatus },
  } = useFinancialSubscription();

  const [responseInfo, setResponseInfo] = useState("");

  useEffect(() => {
    if (showChargeModal && id) getAvailableInvoicesToCharge(id);
  }, [id, showChargeModal]);

  useRequest({
    request: chargeStatus,
    onResolved: () => processResponse(),
    onRejected: () => handleOnRejected("chargeError", validationErrors()),
  });

  const clearStates = () => {
    resetState([
      { stateName: "availablePaymentForms" },
      { stateName: "invoicesToCharge" },
      { stateName: "CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE", isRequestState: true },
      { stateName: "GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE", isRequestState: true },
      { stateName: "GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS", isRequestState: true },
    ]);
  };

  const closeModal = () => {
    resetChargeStates();
  };

  const processResponse = () => {
    if (chargeInvoiceResponse && chargeInvoiceResponse.success === true) {
      const info = [
        `<br/><strong>Informações da cobrança:</strong><br/>`,
        `<strong>${chargeInvoiceResponse.message} - ${chargeInvoiceResponse.installments}x</strong>`,
        `Cartão: <strong>${chargeInvoiceResponse?.brand ?? ""}</strong> - Final: <strong>${
          chargeInvoiceResponse?.last4 ?? ""
        }</strong> - Banco: <strong>${chargeInvoiceResponse?.issuer ?? ""}</strong>`,
      ];
      setResponseInfo(info.join("<br/>"));
      handleOnResolved("charged", clearStates);
    } else {
      const info = [
        `<br/><strong>Informações da cobrança:</strong><br/>`,
        `<strong>${chargeInvoiceResponse.message}</strong>`,
        `LR: <strong>${chargeInvoiceResponse?.lrAttempt?.lr ?? ""} - ${
          chargeInvoiceResponse?.lrAttempt?.description ?? ""
        }</strong>`,
        `Ações possíveis: <strong>${chargeInvoiceResponse?.lrAttempt?.action ?? ""}</strong>`,
        `Situação irreversível para as bandeiras: <strong>${
          chargeInvoiceResponse?.lrAttempt?.irreversibleForBrands ?? ""
        }</strong>`,
      ];
      setResponseInfo(info.join("<br/>"));
      handleOnRejected("chargeError", chargeInvoiceResponse?.message, clearStates);
    }
  };

  return (
    <Modal
      title={`Cobrança de Faturas`}
      showModal={showChargeModal}
      onCloseModal={toggleChargeModal}
      showActions={false}
      width="70rem"
      minHeight="unset"
      padding="2rem 4rem"
      zIndex={990}
    >
      {isPending(chargeStatus) && (
        <RowFlex direction={"column"} horizontalAlign={"center"} verticalAlign={"center"}>
          <Spinner />
          <Span>Aguarde...</Span>
        </RowFlex>
      )}
      {!isPending(chargeStatus) && !chargeInvoiceResponse && (
        <Container horizontalAlign="center" horizontalPadding={0} verticalPadding={0}>
          <ChargeWithSpinner />
        </Container>
      )}
      {!isPending(chargeStatus) && chargeInvoiceResponse && (
        <>
          {responseInfo && <PreviewContainer dangerouslySetInnerHTML={{ __html: responseInfo }} />}
          <ModalActions>
            <Button type="button" variant="outline" handleClick={closeModal}>
              Fechar
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};

const PreviewContainer = styled.div`
  border: none;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  overflow: none;
  font-size: 1.8rem;
`;

export default ChargeContainer;
