import styled from "styled-components";
import { InputWrapper, Field } from "components/core/InputForm/styled";
import iconChrevon from "assets/icons/icon-chrevon.svg";

export const FieldSelect = styled(Field)`
  background: url(${iconChrevon});
  background-color: var(--color-white-2);
  background-repeat: no-repeat;
  background-position: 99% center;
  padding-right: 2rem;
  position: relative;
`;

export const SelectWithDot = styled(InputWrapper)`
  margin-bottom: ${({ mb }) => mb ?? 0};

  &::before {
    background-color: ${({ dotColor }) => dotColor ?? "#dadada"};
    border-radius: 50%;
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    top: 3.2rem;
    left: 10px;
    transition: background-color 0.1s;
  }

  ${FieldSelect} {
    padding-left: 3rem;
  }
`;
