import { BAD_REQUEST, INTERNAL_ERROR, NO_CONTENT } from "utils/constants/request";

export const EMITTER_COLORS_DICTIONARY = {
  "Aguardando Pagamento": "var(--color-purple-1)",
  "Aguardando Liberação do Contador": "var(--color-alert)",
  "Pagamento em Processamento": "var(--color-purple-1)",
  "Pagamento Recusado": "var(--color-danger)",
  "Lead Lucro Presumido": "var(--color-primary)",
  "Aguardando Cliente": "var(--color-alert)",
  "Aguardando Documentação": "var(--color-alert)",
  "Aguardando Orgãos Públicos": "var(--color-alert)",
  Lead: "var(--color-primary)",
  Ativo: "var(--color-success)",
  Inativo: "var(--color-danger)",
  Inadimplente: "var(--color-alert)",
};

export const COUPONS_COLORS_DICTIONARY = {
  Ativo: "var(--color-success)",
  Agendado: "var(--color-alert)",
  Concluído: "var(--color-primary)",
  Desativado: "var(--color-danger)",
  Expirado: "var(--color-gray-6)",
};

export const INVOICE_COLORS = {
  Autorizada: "var(--color-success)",
  Cancelada: "var(--color-danger)",
  Negada: "var(--color-danger)",
  "Em Processamento": "var(--color-alert)",
};

export const INVOICE_COLORS_BATCH = {
  Autorizada: "var(--color-success)",
  Cancelada: "var(--color-alert)",
  Negada: "var(--color-danger)",
  "Em Processamento": "var(--color-purple-1)",
  CancelamentoNegado: "var(--color-alert)",
};

export const FEEDBACK_CHANGE_PASSWORD = {
  [NO_CONTENT]: {
    title: "Tudo certo!",
    description: "Você pode voltar a utilizar a plataforma.",
    btnText: "Fazer Login",
    show: true,
    isSuccess: true,
  },
  [BAD_REQUEST]: {
    description:
      "Não tem problema.<br />Clique no botão e será direcionado para tela de recuperação de senha.",
    title: "Esse link expirou!",
    btnText: "Enviar",
    show: true,
    isSuccess: false,
  },
  [INTERNAL_ERROR]: {
    description: "Ops, algo deu errado.<br />Aguarde alguns instantes e tente novamente.",
    title: "Erro!",
    btnText: "Tentar Novamente",
    show: true,
    isSuccess: false,
  },
};

export const FEEDBACK_FORGOT_PASSWORD = {
  [NO_CONTENT]: {
    title: "Tudo certo!",
    description: "Enviamos um e-mail de recuperação de senha. Verifique sua caixa de entrada.",
    show: true,
    isSuccess: true,
  },
  [INTERNAL_ERROR]: {
    description: "Ops, algo deu errado.<br />Aguarde alguns instantes e tente novamente.",
    title: "Erro!",
    btnText: "Tentar Novamente",
    show: true,
    isSuccess: false,
  },
};

export const localsServiceProvision = [
  { value: "emitter", text: "Emissor" },
  { value: "taker", text: "Tomador" },
];

export const eventKeys = Object.freeze({
  enter: "Enter",
  shift: "Shift",
});
