import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { Flex } from "components/core/Grid";
import { Span } from "components/core/Typography";
import { ModalWrapper, ModalTitle, ModalContent, ModalActions } from "components/core/Modal/styled";
import Portal from "components/core/Portal";
import { ReactComponent as IconCheck } from "assets/icons/icon-check-lg.svg";
import { ReactComponent as IconTimes } from "assets/icons/icon-times.svg";
import * as S from "./styled";

export const CONFIRMATIONS = {
  success: {
    title: "Tudo certo!",
    icon: IconCheck,
    color: "var(--color-success)",
  },
  error: {
    title: "Ops, algo deu errado!",
    icon: IconTimes,
    color: "var(--color-danger)",
  },
};

const ConfirmationIcon = ({ icon: Icon, color }) => (
  <S.Icon color={color}>{Icon && <Icon />}</S.Icon>
);

const ConfirmationTitle = ({ title }) => <ModalTitle>{title}</ModalTitle>;

const ValidationsMessage = ({ validations }) => {
  const { t: translate } = useTranslation();
  if (!validations) return null;

  const validationsMap = Object.keys(validations).map((key) => (
    <Span key={key}>{translate(validations[key])}</Span>
  ));

  return <Flex horizontalAlign="center">{validationsMap}</Flex>;
};

const ConfirmationCard = ({
  children,
  showModal,
  closeLabel,
  confirmation,
  useTranslate,
  onClose,
  validationsFail,
}) => {
  const [active, setActive] = useState(showModal);
  const { t: translate } = useTranslation();

  useEffect(() => {
    setActive(showModal);
  }, [showModal]);

  const handleClick = () => {
    setActive(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Portal>
      <ModalWrapper active={active}>
        <S.ConfirmationCard>
          <ConfirmationIcon icon={confirmation?.icon} color={confirmation?.color} />
          <ConfirmationTitle title={confirmation?.title} />
          <ModalContent>
            {useTranslate ? <Span>{translate(children)}</Span> : children}
            <br />
            <ValidationsMessage validations={validationsFail} />
          </ModalContent>
          <ModalActions>
            <Button type="button" handleClick={handleClick}>
              {closeLabel}
            </Button>
          </ModalActions>
        </S.ConfirmationCard>
      </ModalWrapper>
    </Portal>
  );
};

ValidationsMessage.propTypes = {
  validations: PropTypes.array,
};

ValidationsMessage.defaultProps = {
  validations: null,
};

ConfirmationIcon.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

ConfirmationTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

ConfirmationCard.propTypes = {
  children: PropTypes.node.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeLabel: PropTypes.string,
  confirmation: PropTypes.object,
  useTranslate: PropTypes.bool,
  onClose: PropTypes.func,
  validationsFail: PropTypes.object,
};

ConfirmationCard.defaultProps = {
  closeLabel: "Ok",
  confirmation: CONFIRMATIONS.success,
  useTranslate: false,
  onClose: null,
  validationsFail: null,
};

export default ConfirmationCard;
