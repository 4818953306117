import styled, { css } from "styled-components";
import { scrollbarStyle } from "components/core/Layout";

const asButtonStyle = css`
  display: flex;
  justify-content: space-between;
`;

const withContentStyle = (content) => css`
  position: relative;

  &::after {
    content: "${content}";
    top: 50%;
    right: 1rem;
    position: absolute;
    font-size: var(--font-size-default);
  }
`;

const loadingStyle = css`
  position: relative;

  &::after {
    background: var(--color-white-1);
    border: 3px solid var(--color-secondary);
    border-radius: 50%;
    border-top-color: var(--color-white-1);
    content: "";
    top: 50%;
    right: 5px;
    position: absolute;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Label = styled.label`
  color: ${({ hasError }) => (hasError ? "var(--color-danger)" : "var(--color-black-2)")};
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
  margin-right: 0.4rem;
  text-transform: uppercase;
  transition: all 0.3s linear;
`;

export const Field = styled.input.attrs(({ as, hasError, disabled, isLoading }) => ({
  asButton: as === "button" && asButtonStyle,
  borderBottomColor: hasError ? "var(--color-danger)" : "var(--color-black-2)",
  disabled: disabled || isLoading,
}))`
  appearance: none;
  background-color: var(--color-white-2);
  border: none;
  border-bottom: 1px solid;
  border-bottom-color: ${({ borderBottomColor }) => borderBottomColor};
  border-radius: 0.8rem 0.8rem 0 0;
  color: var(--color-back-2);
  padding: 0.8rem 1.2rem;
  font-family: inherit;
  font-size: var(--font-size-default);
  font-weight: 300;
  height: 4rem;
  width: 100%;
  overflow: hidden;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }

  &:disabled {
    background-color: var(--color-white-4);
  }

  ${({ asButton }) => asButton};

  &:focus {
    border-bottom-color: var(--color-secondary);
  }
`;

const inputWrapperRowStyle = css`
  flex-wrap: wrap;

  ${Field} {
    flex: 1 0 80%;
  }
`;

export const InputWrapper = styled.div.attrs(
  ({ direction, horizontalAlign, verticalAlign, flexWidth, marginBottom }) => ({
    flexDirection: direction ?? "column",
    justifyContent: horizontalAlign ?? "flex-end",
    alignItems: verticalAlign ?? "unset",
    flexWidth: flexWidth ?? "100%",
    marginBottom: marginBottom ?? "2.4rem",
  }),
)`
  position: relative;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ verticalAlign }) => verticalAlign};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 100%;
  flex: ${({ flexWidth }) => flexWidth} !important;
  text-align: left;

  ${({ flexDirection }) => flexDirection === "row" && inputWrapperRowStyle}
  ${({ content }) => content && withContentStyle(content)}
  ${({ isLoading }) =>
    isLoading && loadingStyle}

  &:focus-within {
    ${Label} {
      color: var(--color-secondary);
    }
  }
`;

export const ItemToSelectWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: 9;
  background-color: var(--color-white-1);
  width: 100%;
  display: ${({ open }) => (open ? "block" : "none")};
  overflow-x: auto;
  margin-bottom: ${({ mb }) => mb ?? "2.4rem"};
  margin-top: -0.2rem;
  height: ${({ he }) => he ?? "20rem"};
  ${scrollbarStyle};
`;

export const ItemList = styled.span`
  display: flex;
  font-size: var(--font-size-default);
  background-color: var(--color-white-2);
  padding: var(--space-xxs);
  border-bottom: 1px solid var(--color-white-3);
  cursor: pointer;
  &:hover {
    background-color: var(--color-white-1);
    border-bottom: 1px solid var(--color-secondary);
  }
`;

export const EmptyList = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-default);
  background-color: var(--color-white-2);
  padding: var(--space-xxs);
  color: var(--color-white-3);
  border-bottom: 1px solid var(--color-black-4);
`;

export const ErrorMsg = styled.span`
  color: var(--color-danger);
  font-size: 1.6rem;
  line-height: 2rem;
  margin-top: 0.45rem;
  flex: 1;
`;
