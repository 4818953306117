import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: CheckFieldsTypes, Creators: CheckFieldsActions } = createActions({
  checkFields: ["params"],
  checkFieldsSuccess: ["payload"],
  checkFieldsFailure: ["error"],
  cleanState: null,
});

const INITIAL_STATE = {
  email: "",
  cnpj: 0,
  checkFieldsRequests: {
    CHECK_FIELDS: REQUEST_NOT_STARTED,
  },
};

const checkFields = (state) => ({
  ...state,
  checkFieldsRequests: {
    ...state.checkFieldsRequest,
    CHECK_FIELDS: REQUEST_PENDING,
  },
});

const checkFieldsSuccess = (state, { payload }) => {
  return {
    ...state,
    email: payload.email,
    cnpj: payload.cnpj,
    checkFieldsRequests: {
      ...state.checkFieldsRequest,
      CHECK_FIELDS: REQUEST_RESOLVED,
    },
  };
};

const checkFieldsFailure = (state, { error }) => ({
  ...state,
  error,
  checkFieldsRequests: {
    ...state.checkFieldsRequests,
    CHECK_FIELDS: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [CheckFieldsTypes.CHECK_FIELDS]: checkFields,
  [CheckFieldsTypes.CHECK_FIELDS_SUCCESS]: checkFieldsSuccess,
  [CheckFieldsTypes.CHECK_FIELDS_FAILURE]: checkFieldsFailure,
  [CheckFieldsTypes.CLEAN_STATE]: cleanState,
});
