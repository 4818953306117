import React, { useEffect } from "react";
import Yup from "utils/yup";
import PropTypes from "prop-types";
import { Container, Columns } from "components/core/Grid";
import { Form, InputGroup, InputHidden, InputGroupFlex } from "components/core/Form";
import { FormContext, useForm } from "react-hook-form";
import CardInfo from "components/core/CardInfo";
import SelectForm from "components/core/SelectForm";
import InputForm from "components/core/InputForm";
import { MASK_TYPES } from "utils/masks";
import { TertiaryTitle } from "components/core/Typography";
import { mapToSelectOptions, formatDate } from "helpers";
import { Label } from "components/core/InputForm/styled";
import Checkbox from "components/core/Checkbox";
import moment from "moment";

const parseBool = (val) =>
  [true, "true"].includes(val) ? true : [false, "false"].includes(val) ? false : null;
const parseNumber = (val) => (!Number.isNaN(Number(val)) ? Number(val) : null);

const SubscriptionDetails = ({
  translate,
  submitRef,
  onSubmit,
  subscription: {
    enterpriseName,
    userName,
    cnpj,
    active,
    planCode,
    billingDays,
    billingDay,
    expiresAt,
    billed,
    installments,
    installmentSettings,
  },
}) => {
  const tomorrow = new Date(new Date().setHours(0, 0, 0, 0));
  tomorrow.setDate(tomorrow.getDate() + 1);

  const optionsYesNo = [
    { text: translate("Yes"), value: true },
    { text: translate("No"), value: false },
  ];

  const optionsInterest = [
    { text: translate("Monthly"), value: 1 },
    { text: translate("Annual"), value: 2 },
  ];

  const optionsDiscount = [
    { text: translate("Fixed Value"), value: "V" },
    { text: translate("Percent"), value: "P" },
  ];

  const optionsNumber = (min, max) => {
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push({ text: i, value: i });
    }
    return options;
  };

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    validationSchema: Yup.object().shape({
      expiresAt: Yup.string(),
      // .required(translate("Due Date is required"))
      // .dateInFuture(
      //   tomorrow,
      //   <Trans i18nKey="dateInFuture" values={{ date: formatDate(tomorrow) }} />,
      // ),
      active: Yup.boolean(),
      billingDays: Yup.number(),
      billingDay: Yup.number().nullable().optional(),
      billed: Yup.boolean(),
      installments: Yup.number(),
      inheritSettings: Yup.bool().transform(parseBool).nullable(),
      allowInstallments: Yup.bool().transform(parseBool).nullable(),
      allowedInstallmentsPlanMonthly: Yup.bool().transform(parseBool).nullable(),
      allowedInstallmentsPlanAnnual: Yup.bool().transform(parseBool).nullable(),
      allowedInstallmentsPlanBiennial: Yup.bool().transform(parseBool).nullable(),
      maxInstallmentsNumber: Yup.number().transform(parseNumber).nullable(),
      interestType: Yup.number().transform(parseNumber).nullable(),
      interestValue: Yup.string().nullable(),
    }),
    defaultValues: {
      enterpriseName,
      cnpj,
      active,
      planCode,
      billingDays,
      billingDay,
      installments,
      billed: !billed,
      expiresAt: formatDate(expiresAt, false, false),
    },
    submitFocusError: true,
  });

  const expiresAtWatch = watch("expiresAt");

  const changeExpiresAt = ({ target: { value: billingDay } }) => {
    if (expiresAtWatch.length === 10) {
      const expiresAtParts = expiresAtWatch.split("/");
      const daysInMonth = moment(
        `${expiresAtParts[2]}-${expiresAtParts[1]}-${expiresAtParts[0]}`,
      ).daysInMonth();
      const formattedBillingDay = (billingDay - 1).toString().padStart(2, "0");
      let expiresAt = null;
      if (billingDay > daysInMonth) {
        expiresAt = moment(`${expiresAtParts[2]}-${expiresAtParts[1]}-${daysInMonth}`);
      } else {
        expiresAt = moment(`${expiresAtParts[2]}-${expiresAtParts[1]}-${formattedBillingDay}`);
      }
      setValue("expiresAt", formatDate(expiresAt.toDate(), false, false));
    }
  };

  const changeBillingDay = ({ target: { value: expiresAt } }) => {
    if (expiresAt.length === 10) {
      const expiresAtParts = expiresAtWatch.split("/");
      const daysInMonth = moment(
        `${expiresAtParts[2]}-${expiresAtParts[1]}-${expiresAtParts[0]}`,
      ).daysInMonth();
      let expiresAtDay = Number(expiresAtParts[0]) + 1;
      if (expiresAtDay > daysInMonth) {
        expiresAtDay = 1;
      }
      setValue("billingDay", expiresAtDay);
    }
  };

  useEffect(() => {
    if (installmentSettings) {
      setValue("allowInstallments", null);
      setValue("maxInstallmentsNumber", 1);
      setValue("interestType", 1);
      setValue("interestValue", null);
      setValue("allowedInstallmentsPlanMonthly", null);
      setValue("allowedInstallmentsPlanAnnual", null);
      setValue("inheritSettings", installmentSettings?.inheritSettings);
      setValue("allowInstallments", installmentSettings?.allowInstallments);
      setValue("maxInstallmentsNumber", installmentSettings?.maxInstallmentsNumber);
      setValue("interestType", installmentSettings?.interestType);
      setValue(
        "interestValue",
        Number(installmentSettings?.interestType) === 1
          ? installmentSettings?.monthlyInterest
          : installmentSettings?.annualInterest,
      );
      setValue(
        "allowedInstallmentsPlanMonthly",
        installmentSettings?.allowedInstallmentsPlans?.includes(1),
      );
      setValue(
        "allowedInstallmentsPlanAnnual",
        installmentSettings?.allowedInstallmentsPlans?.includes(2),
      );
      setValue(
        "allowedInstallmentsPlanBiennial",
        installmentSettings?.allowedInstallmentsPlans?.includes(3),
      );
    }
  }, [installmentSettings]);

  return (
    <>
      <Container maxWidth="md">
        <CardInfo label={translate("emitter")} info={enterpriseName || userName} />
        <FormContext watch={watch} setValue={setValue}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup paddingLeft={0}>
              <InputGroupFlex columns="2">
                <SelectForm
                  register={register}
                  name="active"
                  label={translate("Active")}
                  options={[
                    { value: true, text: "Sim" },
                    { value: false, text: "Não" },
                  ]}
                />
                <SelectForm
                  register={register}
                  name="billed"
                  label={translate("Allow Billing")}
                  options={[
                    { value: true, text: "Sim" },
                    { value: false, text: "Não" },
                  ]}
                />
              </InputGroupFlex>
              <InputGroupFlex columns="2">
                <InputForm
                  register={register}
                  type="text"
                  name="billingDays"
                  label={`${translate("billingDays")}`}
                  errorMsg={errors.billingDays?.message}
                />
                <SelectForm
                  register={register}
                  name="billingDay"
                  label={translate("Due Day")}
                  options={optionsNumber(1, 31)}
                  handleChange={changeExpiresAt}
                />
              </InputGroupFlex>
              <InputGroupFlex columns="2">
                <InputForm
                  register={register}
                  type="text"
                  name="expiresAt"
                  label={translate("Subscription Due Date")}
                  mask={MASK_TYPES.date}
                  errorMsg={errors.expiresAt?.message}
                  onKeyUp={changeBillingDay}
                />
                <InputForm
                  register={register}
                  type="text"
                  name="installments"
                  label={`${translate("Installments Number")}`}
                  errorMsg={errors.installments?.message}
                />
              </InputGroupFlex>
              <InputHidden ref={submitRef} type="submit" value="Submit" />
            </InputGroup>
            <TertiaryTitle>{translate("Installments Settings")}</TertiaryTitle>
            <InputGroup paddingLeft={0}>
              <InputGroupFlex columns="2">
                <SelectForm
                  register={register}
                  name="inheritSettings"
                  label={`${translate("Inherit Settings")}?`}
                  options={mapToSelectOptions(optionsYesNo, "value", "text")}
                />
                <SelectForm
                  register={register}
                  name="allowInstallments"
                  label={`${translate("Allow Installments")}?`}
                  options={mapToSelectOptions(optionsYesNo, "value", "text")}
                  disabled={watch("inheritSettings") == "true"}
                />
              </InputGroupFlex>
              <InputGroupFlex columns={2}>
                <Columns style={{ marginRight: "2.1rem" }}>
                  <Label>{translate("Allowed Plans")}</Label>
                  <InputGroupFlex columns={2}>
                    <Checkbox
                      register={register}
                      name="allowedInstallmentsPlanMonthly"
                      label={`${translate("Monthly")}`}
                      disabled={watch("inheritSettings") === "true"}
                      marginBottom={0}
                    />
                    <Checkbox
                      register={register}
                      name="allowedInstallmentsPlanAnnual"
                      label={`${translate("Annual")}`}
                      disabled={watch("inheritSettings") === "true"}
                      marginBottom={0}
                    />
                    <Checkbox
                      register={register}
                      name="allowedInstallmentsPlanBiennial"
                      label={`${translate("bienal")}`}
                      disabled={watch("inheritSettings") === "true"}
                      marginBottom={0}
                    />
                  </InputGroupFlex>
                </Columns>
                <SelectForm
                  register={register}
                  name="maxInstallmentsNumber"
                  label={`${translate("Maximum Installments Number")}?`}
                  options={mapToSelectOptions(optionsNumber(1, 12), "value", "text")}
                  disabled={watch("inheritSettings") === "true"}
                />
              </InputGroupFlex>
              <InputGroupFlex columns={2}>
                <SelectForm
                  register={register}
                  name="interestType"
                  label={`${translate("Interest Type")}?`}
                  options={mapToSelectOptions(optionsInterest, "value", "text")}
                  disabled={watch("inheritSettings") === "true"}
                />
                <InputForm
                  register={register}
                  type="text"
                  name="interestValue"
                  label={translate("Interest Value")}
                  disabled={watch("inheritSettings") === "true"}
                  mask={MASK_TYPES.percentage}
                />
              </InputGroupFlex>
            </InputGroup>
          </Form>
        </FormContext>
      </Container>
    </>
  );
};

SubscriptionDetails.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default SubscriptionDetails;
