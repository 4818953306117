import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import FilterButton from "components/core/FilterButton";

const ODER_DESC = "desc";
const ORDER_ASC = "asc";

const useParams = ({
  callback,
  defaultPage = 1,
  defaultPerPage = 10,
  defaultOrderBy = "name",
  defaultOrder = ORDER_ASC,
  ...otherParams
}) => {
  const callbackRef = useRef(callback);
  const otherParamsRef = useRef(otherParams);
  const [page, setPage] = useState(defaultPage);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [filterParams, setFilterParams] = useState({});
  const [counterFilters, setCountFilters] = useState(0);
  const [customParams, setCustomParams] = useState(otherParamsRef.current);
  const [params, setParams] = useState(null);

  useEffect(() => {
    if (params && callbackRef.current) {
      callbackRef.current(params);
    }
  }, [params]);

  useEffect(() => {
    setParams({
      sort: orderBy,
      order,
      perPage,
      page,
      ...customParams,
      ...filterParams,
    });
  }, [perPage, page, orderBy, order, customParams, filterParams]);

  useEffect(() => {
    setCountFilters(Object.keys(filterParams).filter((key) => !!filterParams[key]).length);
  }, [filterParams]);

  const dispatchWithParams = useCallback(
    (optionalParams = {}, ...args) =>
      callback(
        {
          ...params,
          ...optionalParams,
        },
        ...args,
      ),
    [callback, params],
  );

  const handleFilter = (filterArgs) => {
    setFilterParams(filterArgs);
    setPage(1);
  };

  const handleClearFilters = () => {
    setFilterParams({});
    setPage(1);
  };

  const toggleOrder = () =>
    setOrder((actualValue) => (actualValue === ODER_DESC ? ORDER_ASC : ODER_DESC));

  const handleOrderBy = (slug) => {
    if (!slug) return;
    if (slug === orderBy) {
      toggleOrder();
    } else {
      setOrderBy(slug);
      setOrder(ODER_DESC);
    }
  };

  const SmartFilterButton = ({ handleShowFilter, cy, captionIndex }) => (
    <FilterButton
      counterFilters={counterFilters}
      handleShowFilter={handleShowFilter}
      handleClearFilters={handleClearFilters}
      captionIndex={captionIndex}
      cy={cy}
    />
  );

  SmartFilterButton.propTypes = {
    handleShowFilter: PropTypes.func.isRequired,
    cy: PropTypes.string,
    captionIndex: PropTypes.string,
  };

  SmartFilterButton.defaultProps = {
    cy: "",
    captionIndex: "filter",
  };

  return {
    page,
    perPage,
    order,
    orderBy,
    setPage,
    setPerPage,
    setOrderBy: handleOrderBy,
    setOrder,
    setFilterParams,
    params,
    dispatchWithParams,
    setCustomParams,
    counterFilters,
    handleFilter,
    handleClearFilters,
    SmartFilterButton,
  };
};

export default useParams;
