import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, InputHidden } from "components/core/Form";
import { Container } from "components/core/Grid";
import { Subtitle } from "components/core/Typography";
import InputForm from "components/core/InputForm";
import Autocomplete from "components/core/Autocomplete";

const ProductsEdit = ({
  onSubmit,
  submitRef,
  defaultValues,
  handleSearchNcm,
  ncmItems,
  isLoadingNcmItems,
  handleSearchCst,
  cstItems,
  isLoadingCstItems,
  setIsChangedCsosn,
  setIsChangedNcm,
  unitMeasure,
  validationSchema,
  handleSearchUnitMeasure,
  isLoadingUnitMeasureItems,
  lastItemRefNCM,
  lastItemRefCTS,
  lastItemRefMeasure,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
  });
  const { t: translate } = useTranslation();

  const handleChangeNcm = (item) => {
    setIsChangedNcm(true);
    setValue("idNcm", item.id);
  };

  const handleChangeCst = (item) => {
    setIsChangedCsosn(true);
    setValue("idCsosn", item.id);
  };

  const handleChangeUnitMeasure = (item) => {
    setValue("idUnitMeasure", item.id);
  };
  return (
    <Container maxWidth="md">
      <Subtitle>Edite as informações</Subtitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputForm
          register={register}
          type="number"
          name="code"
          label="Código do produto"
          errorMsg={errors.code?.message}
        />
        <InputForm
          register={register}
          type="text"
          name="name"
          label="Descrição"
          errorMsg={errors.name?.message}
        />
        <Autocomplete
          register={register}
          type="text"
          label={translate("unitMeasure")}
          nameValue="unitMeasure"
          handleSearch={handleSearchUnitMeasure}
          handleChange={handleChangeUnitMeasure}
          isLoadingItems={isLoadingUnitMeasureItems}
          items={unitMeasure}
          setValue={setValue}
          errorMsg={errors.unitMeasure?.message}
          lastItemRef={lastItemRefMeasure}
        />
        <InputHidden ref={register} name="idUnitMeasure" />

        <InputHidden ref={submitRef} type="submit" value="Submit" />
        <Autocomplete
          register={register}
          type="text"
          label={translate("ncm")}
          nameValue="ncm"
          handleSearch={handleSearchNcm}
          handleChange={handleChangeNcm}
          items={ncmItems}
          isLoadingItems={isLoadingNcmItems}
          setValue={setValue}
          errorMsg={errors.ncm?.message}
          lastItemRef={lastItemRefNCM}
        />
        <InputHidden ref={register} name="idNcm" />

        <Autocomplete
          register={register}
          type="text"
          label={translate("csosn")}
          nameValue="csosn"
          handleSearch={handleSearchCst}
          handleChange={handleChangeCst}
          items={cstItems}
          isLoadingItems={isLoadingCstItems}
          setValue={setValue}
          errorMsg={errors.csosn?.message}
          lastItemRef={lastItemRefCTS}
        />
        <InputHidden ref={register} name="idCsosn" />
      </Form>
    </Container>
  );
};

ProductsEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  handleSearchNcm: PropTypes.func.isRequired,
  ncmItems: PropTypes.array,
  unitMeasure: PropTypes.array,
  isLoadingNcmItems: PropTypes.bool,
  handleSearchCst: PropTypes.func.isRequired,
  cstItems: PropTypes.array,
  isLoadingCstItems: PropTypes.bool,
  setIsChangedCsosn: PropTypes.func.isRequired,
  setIsChangedNcm: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  handleSearchUnitMeasure: PropTypes.func.isRequired,
  isLoadingUnitMeasureItems: PropTypes.bool,
  lastItemRefNCM: PropTypes.object,
  lastItemRefCTS: PropTypes.object,
  lastItemRefMeasure: PropTypes.object,
};

ProductsEdit.defaultProps = {
  ncmItems: [],
  cstItems: [],
  isLoadingNcmItems: true,
  isLoadingCstItems: true,
  isLoadingUnitMeasureItems: true,
  unitMeasure: [],
  lastItemRefNCM: null,
  lastItemRefCTS: null,
  lastItemRefMeasure: null,
};

export default ProductsEdit;
