import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableCheckbox,
  TableBody,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import { formatDate } from "helpers";
import { Link } from "react-router-dom";
import { ROUTES, INVOICE_COLORS_BATCH } from "utils/constants";
import StatusDot from "components/core/StatusDot";

import * as S from "./styles";

const Company = ({
  batches,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  toggleAll,
  checkedAll,
  orderBy,
  order,
  batchIdentify,
}) => {
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "invoiceNumber", title: translate("invoiceNumber") },
    { slug: "clientName", title: translate("recipient") },
    { slug: "price", title: translate("invoiceValue") },
    { slug: "status", title: translate("status") },
    { slug: "dateIssue", title: translate("dateIssue") },
  ];

  return (
    <>
      <Table customStyle={{ marginTop: "6px" }}>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {batches.map(({ id, invoiceNumber, client, price, status, dateIssue, checked }) => (
            <tr key={id}>
              <TableItem textAlign="center">
                <TableCheckbox
                  name={`toggle_${id}`}
                  value={id}
                  handleChange={() => handleChange(id)}
                  checked={checked}
                />
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/details/${batchIdentify}/${id}`}>
                  {invoiceNumber || " - "}
                </Link>
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/details/${batchIdentify}/${id}`}>
                  {client?.clientName || " - "}
                </Link>
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/details/${batchIdentify}/${id}`}>
                  {(price &&
                    Number(price).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })) ||
                    " - "}
                </Link>
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/details/${batchIdentify}/${id}`}>
                  <S.StatusBox>
                    {status && <StatusDot type={INVOICE_COLORS_BATCH[status]} />}
                    {status || " - "}
                  </S.StatusBox>
                </Link>
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/details/${batchIdentify}/${id}`}>
                  {(dateIssue && formatDate(dateIssue, false, false)) || " - "}
                </Link>
              </TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

Company.propTypes = {
  batches: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  batchIdentify: PropTypes.string.isRequired,
};

Company.defaultProps = {
  batches: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(Company);
