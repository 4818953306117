import styled from "styled-components";
import { ReactComponent as ScreenConversation } from "assets/images/conversation.svg";

export const EmptyChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 6rem;
  width: 100%;
`;

export const NewConversation = styled(ScreenConversation)`
  margin-bottom: 3.2rem;
`;
