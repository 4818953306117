import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { GET, PUT, POST } from "utils/constants/verbs";
import {
  PAYMENT_GATEWAYS,
  PAYMENT_GATEWAY_BY_ID,
  PROCESS_PAYMENT,
  CHANGE_PAYMENT_METHOD,
} from "utils/constants/endpoints";
import { PaymentGatewaysTypes, PaymentGatewaysActions } from "store/ducks/paymentGateways";

export function* getPaymentGateways({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: PAYMENT_GATEWAYS,
      params,
    });
    yield put(PaymentGatewaysActions.getPaymentGatewaysSuccess(data));
  } catch (error) {
    yield put(PaymentGatewaysActions.paymentGatewaysRequestFailure(error, type));
  }
}

export function* getPaymentGatewayById({ params: id }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: PAYMENT_GATEWAY_BY_ID(id),
      });
      yield put(PaymentGatewaysActions.getPaymentGatewayByIdSuccess(data));
    }
  } catch (error) {
    yield put(PaymentGatewaysActions.paymentGatewaysRequestFailure(error));
  }
}

export function* editPaymentGateway({ id, payload }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PUT,
        url: PAYMENT_GATEWAY_BY_ID(id),
        data: payload,
      });
      yield put(PaymentGatewaysActions.editPaymentGatewaySuccess(data));
    }
  } catch (error) {
    yield put(PaymentGatewaysActions.paymentGatewaysRequestFailure(error));
  }
}

export function* processPayment({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: PROCESS_PAYMENT,
      data: payload,
    });
    yield put(PaymentGatewaysActions.processPaymentSuccess(data));
  } catch (error) {
    yield put(
      PaymentGatewaysActions.paymentGatewaysRequestFailure(
        { data: error.response.data, status: error.response.status },
        PaymentGatewaysTypes.PROCESS_PAYMENT,
      ),
    );
  }
}

export function* changePaymentMethod({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: CHANGE_PAYMENT_METHOD,
      data: payload,
    });
    yield put(PaymentGatewaysActions.changePaymentMethodSuccess(data));
  } catch (error) {
    yield put(
      PaymentGatewaysActions.changePaymentMethodFailure(
        {
          data: error.response.data,
          status: error.response.status,
        },
        PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD,
      ),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(PaymentGatewaysTypes.GET_PAYMENT_GATEWAYS, getPaymentGateways);
  yield takeLatest(PaymentGatewaysTypes.GET_PAYMENT_GATEWAY_BY_ID, getPaymentGatewayById);
  yield takeLatest(PaymentGatewaysTypes.EDIT_PAYMENT_GATEWAY, editPaymentGateway);
  yield takeLatest(PaymentGatewaysTypes.PROCESS_PAYMENT, processPayment);
  yield takeLatest(PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD, changePaymentMethod);
}
