import { all } from "redux-saga/effects";
import * as addressSagas from "./address";
import * as authSagas from "./auth";
import * as clientSagas from "./client";
import * as chatSagas from "./chat";
import * as documentsSagas from "./documents";
import * as emitterSagas from "./emitter";
import * as enterpriseSagas from "./enterprise";
import * as globalSagas from "./global";
import * as invoiceSagas from "./invoice";
import * as notificationSagas from "./notification";
import * as passwordSagas from "./password";
import * as paymentSagas from "./payment";
import * as paymentGatewaysSagas from "./paymentGateways";
import * as rolesSagas from "./roles";
import * as servicesSagas from "./services";
import * as taxesSagas from "./taxes";
import * as productsSagas from "./products";
import * as cstsSagas from "./cst";
import * as ncmsSagas from "./ncm";
import * as usersSagas from "./users";
import * as cfopSagas from "./cfop";
import * as ncmsGlobalsSagas from "./ncmGlobals";
import * as citiesSagas from "./cities";
import * as couponsSagas from "./coupons";
import * as checkFieldsSaga from "./checkFields";
import * as cnpjChecker from "./cnpjChecker";
import * as batchesSagas from "./batches";
import * as subscriptionsSagas from "./subscriptions";
import * as emailSagas from "./email";
import * as reportsSagas from "./reports";
import * as invoicePaymentSagas from "./invoicePayment";
import * as financialSagas from "./financial";
import * as financialSubscriptionsSagas from "./financialSubscriptions";
import * as interBank from "./interBank";
import * as paramsEmiter from "./paramsEmitter";
import * as params from "./params";

function* sagas() {
  yield all([
    addressSagas.watchSagas(),
    authSagas.watchSagas(),
    clientSagas.watchSagas(),
    chatSagas.watchSagas(),
    checkFieldsSaga.watchSagas(),
    cnpjChecker.watchSagas(),
    documentsSagas.watchSagas(),
    emitterSagas.watchSagas(),
    enterpriseSagas.watchSagas(),
    globalSagas.watchSagas(),
    invoiceSagas.watchSagas(),
    notificationSagas.watchSagas(),
    passwordSagas.watchSagas(),
    paymentSagas.watchSagas(),
    paymentGatewaysSagas.watchSagas(),
    rolesSagas.watchSagas(),
    servicesSagas.watchSagas(),
    taxesSagas.watchSagas(),
    usersSagas.watchSagas(),
    productsSagas.watchSagas(),
    cstsSagas.watchSagas(),
    ncmsSagas.watchSagas(),
    cfopSagas.watchSagas(),
    ncmsGlobalsSagas.watchSagas(),
    citiesSagas.watchSagas(),
    couponsSagas.watchSagas(),
    batchesSagas.watchSagas(),
    subscriptionsSagas.watchSagas(),
    emailSagas.watchSagas(),
    reportsSagas.watchSagas(),
    invoicePaymentSagas.watchSagas(),
    financialSagas.watchSagas(),
    financialSubscriptionsSagas.watchSagas(),
    interBank.watchSagas(),
    paramsEmiter.watchSagas(),
    params.watchSagas(),
  ]);
}

export default sagas;
