import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableCheckbox,
  TableBody,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import useRoleLabel from "hooks/useRoleLabel";
import { urls } from "utils/constants";

const List = ({
  users,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  toggleAll,
  checkedAll,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();
  const [getRoleLabel] = useRoleLabel();
  const {
    LINKS: { userDetails },
  } = urls;
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "name", title: translate("Nome") },
    { slug: "email", title: translate("E-mail") },
    { slug: "role", title: translate("Tipo de Perfil") },
    { slug: "status", title: translate("Status") },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {users.map(({ name, email, idRole, active, id, checked }) => (
            <tr key={id}>
              <TableItem textAlign="center">
                <TableCheckbox
                  name={`toggle_${id}`}
                  value={id}
                  handleChange={() => handleChange(id)}
                  checked={checked}
                />
              </TableItem>
              <TableItem>
                <Link data-cy="id" to={userDetails(id)}>
                  {id}
                </Link>
              </TableItem>
              <TableItem>
                <Link data-cy="name" to={userDetails(id)}>
                  {name}
                </Link>
              </TableItem>
              <TableItem>
                <Link data-cy="email" to={userDetails(id)}>
                  {email}
                </Link>
              </TableItem>
              <TableItem>
                <Link data-cy="idRole" to={userDetails(id)}>
                  {getRoleLabel(idRole)}
                </Link>
              </TableItem>
              <TableItem>
                <Link data-cy="active" to={userDetails(id)}>
                  <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                  {active ? "Ativo" : "Inativo"}
                </Link>
              </TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  users: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  users: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
