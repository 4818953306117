import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { GET, POST, PUT } from "utils/constants/verbs";
import { ParamsActions, ParamsTypes } from "store/ducks/params";

export function* fetchAllCertificates({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/params/certificates",
      data: payload,
    });

    yield put(ParamsActions.fetchCertificatesSuccess(data));
  } catch (error) {
    yield put(ParamsActions.requestFailure(error, type));
  }
}

export function* getCertificates({ type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: "/params/certificates",
    });

    yield put(ParamsActions.getCertificatesSuccess(data));
  } catch (error) {
    yield put(ParamsActions.requestFailure(error, type));
  }
}

export function* registerCertificate({ payload, type }) {
  try {
    const formData = new FormData();
    formData.append("certificate", payload.certificate[0]);

    const { data } = yield api({
      method: POST,
      headers: {
        certificatePassword: payload.certificatePassword,
        nameAccountant: payload.nameAccountant,
        crc: payload.crc,
      },
      url: "/params/certificates/register",
      data: formData,
    });

    yield put(ParamsActions.registerCertificateSuccess(data));
  } catch (error) {
    yield put(ParamsActions.requestFailure(error, type));
  }
}

export function* deleteCertificate({ payload, type }) {
  try {
    const { data } = yield api({
      method: PUT,
      url: "/params/certificates/delete",
      data: payload,
    });

    yield put(ParamsActions.deleteCertificateSuccess(data));
  } catch (error) {
    yield put(ParamsActions.requestFailure(error, type));
  }
}

export function* updateDefaultCertificate({ payload, type }) {
  try {
    const { data } = yield api({
      method: PUT,
      url: "/params/certificates/update-default",
      data: payload,
    });

    yield put(ParamsActions.updateDefaultCertificateSuccess(data));
  } catch (error) {
    yield put(ParamsActions.requestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ParamsTypes.FETCH_CERTIFICATES, fetchAllCertificates);
  yield takeLatest(ParamsTypes.GET_CERTIFICATES, getCertificates);
  yield takeLatest(ParamsTypes.REGISTER_CERTIFICATE, registerCertificate);
  yield takeLatest(ParamsTypes.DELETE_CERTIFICATE, deleteCertificate);
  yield takeLatest(ParamsTypes.UPDATE_DEFAULT_CERTIFICATE, updateDefaultCertificate);
}
