import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import { Subtitle, Divider } from "components/core/Typography";
import { Container, Columns } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import { MASK_TYPES } from "utils/masks";
import { Form, InputHidden } from "components/core/Form";

const EditEnterprise = ({
  emitterDetails,
  defaultValues,
  description,
  validationSchema,
  handleEditEnterprise,
  submitRef,
}) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
  });

  const getCouponsInfo = (emitterToTransform) => {
    if (Object.keys(emitterToTransform).length > 0) {
      const { subscription } = emitterToTransform;
      const coupon = subscription[0]?.coupon;
      return {
        id: coupon?.id,
        code: coupon?.code,
        discount: `${coupon?.discount}%`,
      };
    }
    return {
      id: `...${translate("checking")}`,
      code: `...${translate("checking")}`,
      discount: `...${translate("checking")}`,
    };
  };
  // esse codigo sera usado futuramente
  // const couponsInfo = getCouponsInfo(emitterDetails);

  return (
    <>
      <Container>
        <Subtitle>{translate("details")}</Subtitle>
        <CardInfo label={translate("recipientName")} info={emitterDetails?.enterpriseName} />
        <Columns columns="2">
          <CardInfo label={translate("cnpj")} info={emitterDetails?.cnpj} />
          <CardInfo
            label={translate("status")}
            info={<>{emitterDetails?.subscription?.lastBilling?.status}</>}
          />
        </Columns>
        <Divider />
        <CardInfo
          label={translate("local")}
          info={`${emitterDetails?.city} - ${emitterDetails?.state}`}
        />
        {emitterDetails?.lastInvoice != null && (
          <CardInfo label={translate("lastInvoice")} info={emitterDetails?.lastInvoice} />
        )}
        <Divider />
        {/* esse código sera usado futuramente {couponsInfo?.id != null && (
          <Columns columns="2">
            <CardInfo label={translate("coupon")} info={couponsInfo?.code} />
            <CardInfo label={translate("discountReceived")} info={couponsInfo?.discount} />
          </Columns>
        )} */}
        {description != null && <CardInfo label={translate("description")} info={description} />}
      </Container>
      <Container maxWidth="md">
        <Form onSubmit={handleSubmit(handleEditEnterprise)}>
          <InputHidden ref={register} name="id" value={defaultValues?.id} />
          <InputHidden ref={register} name="enterpriseName" value={defaultValues?.enterpriseName} />
          <InputHidden ref={register} name="cnpj" value={defaultValues?.cnpj} />
          <InputHidden ref={register} name="plan" value={defaultValues?.plan} />
          <InputForm
            register={register}
            type="text"
            name="price"
            label={translate("cost")}
            mask={MASK_TYPES.realWithName}
          />
          <InputForm
            register={register}
            type="text"
            name="justification"
            label={translate("justification")}
            errorMsg={errors.justification?.message}
          />
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
      </Container>
    </>
  );
};

EditEnterprise.propTypes = {
  emitterDetails: PropTypes.object.isRequired,
  submitRef: PropTypes.object.isRequired,
  handleEditEnterprise: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  description: PropTypes.string,
};

EditEnterprise.defaultProps = {
  description: "Não informado",
};

export default EditEnterprise;
