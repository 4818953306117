import styled from "styled-components";

export const ViewBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewText = styled.p`
  font-family: "Work Sans";
  font-size: 14px;
  color: #0c0d0d;
  margin-right: 10px;
`;

export const ViewSelect = styled.select`
  border: 1px solid #0071ee;
  border-radius: 8px;
  padding: 6px 9px;
  background-color: #fff;
  height: 32px;
  cursor: pointer;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.6rem 3.2rem;
  width: 100%;
`;

export const PaginationButton = styled.button.attrs((props) => ({
  fontWeight: props.fontWeight || "normal",
}))`
  color: var(--color-black-3);
  font-size: 1.4rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: 0 0.3rem;
  cursor: pointer;
  &:disabled {
    color: var(--color-white-3);
  }
`;
