import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UsersActions } from "store/ducks/users";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import UserList from "components/presentation/User/List";
import { useItems, useParams } from "hooks";
import { urls } from "utils/constants";
import { ReactComponent as IconAddUser } from "assets/icons/icon-add-user.svg";

const EditStatus = React.lazy(() => import("containers/User/EditStatus"));
const Filter = React.lazy(() => import("containers/User/List/Filter"));

const UserListWithSpinner = WithSpinner(UserList);
const EditStatusWithSuspense = WithSuspense(EditStatus);
const FilterWithSuspense = WithSuspense(Filter);

const UsersListContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showModalEditStatus, setShowModalEditStatus] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const fetchUsers = (newParams) => {
    dispatch(UsersActions.getUsers(newParams));
  };
  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({ callback: fetchUsers });
  const {
    UsersRequests,
    users: { items, total, totalPages },
  } = useSelector(({ users }) => users);
  const {
    handleChange,
    hasOneItemSelected,
    hasSelectedItems,
    listChecked,
    removeItemById,
    selectedItems,
    setSelectedItems,
    toggleAll,
  } = useItems(items);

  return (
    <>
      <NavBar title={translate("users")}>
        {hasSelectedItems && (
          <>
            <Span>{translate("usersSelected", { count: selectedItems.length })}</Span>
            <Button variant="outline" handleClick={() => setShowModalEditStatus(true)}>
              {translate("changeStatus")}
            </Button>
          </>
        )}
        {hasOneItemSelected && (
          <Button variant="outline" url={urls.LINKS.userEdit(selectedItems[0]?.id)}>
            {translate("edit")}
          </Button>
        )}
        {!hasOneItemSelected && !hasSelectedItems && (
          <>
            <SmartFilterButton cy="user-filter" handleShowFilter={() => setShowFilters(true)} />
            <Button url={urls.LINKS.userRegister}>
              <IconAddUser />
              <span>{translate("addUser")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <UserListWithSpinner
        requestStatus={UsersRequests.getUsers}
        users={listChecked ?? items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        handleChange={handleChange}
        toggleAll={toggleAll}
        checkedAll={selectedItems?.length === listChecked?.length}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
      <EditStatusWithSuspense
        loadComponent={showModalEditStatus}
        openModal={showModalEditStatus}
        setOpenModal={setShowModalEditStatus}
        selectedUsers={selectedItems}
        setSelectedUsers={setSelectedItems}
        removeUserById={removeItemById}
      />
    </>
  );
};

export default UsersListContainer;
