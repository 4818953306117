import * as Yup from "yup";

export const ncmGlobalsSchema = Yup.object().shape({
  name: Yup.string().required("Descrição obrigatório"),
  code: Yup.string().required("Código obrigatório"),
  icms: Yup.string().required("Icms obrigatório"),
  startTerm: Yup.string().required("Data de início obrigatório"),
});

export const ncmGlobalsInitialValues = {
  name: "",
  code: "",
  icms: "",
  startTerm: "",
};
