import styled from "styled-components";
import { Span } from "components/core/Typography";

export const Nav = styled.nav`
  background-color: var(--color-white-1);
  border-bottom: 1px solid var(--color-primary);
  border-top-left-radius: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space) var(--space-md);
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100vw - var(--sidebar-width));
  z-index: 100;

  ${Span} {
    margin-right: var(--space);
  }
  ${({ activeBlur }) => activeBlur && "filter: brightness(0.65)"}
`;

export const BoxTitle = styled.div``;

export const Title = styled.h1`
  color: var(--color-black-3);
  font-size: var(--font-size-title);
`;

export const Description = styled.p`
  color: var(--color-black-3);
  font-size: 14px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
