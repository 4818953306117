import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PaymentActions } from "store/ducks/payment";
import { PaymentGatewaysActions } from "store/ducks/paymentGateways";
import useScript from "hooks/useScript";
import WithSpinner from "components/core/WithSpinner";
import Payment from "components/presentation/Payment";
import { getPaymentMethod } from "helpers/labels";
import { sanitizePhone } from "helpers/sanitize";
import { POSTBACK_URL } from "utils/constants/endpoints";
import { REQUEST_PENDING, REQUEST_REJECTED, REQUEST_RESOLVED } from "utils/constants/request";
import { MASK_TYPES, removeMask, format } from "utils/masks";
import { ModalCreditCard, Span } from "components/core";
import { Modal } from "components/core/Modal";
import { useRequest } from "hooks";
import { useTranslation } from "react-i18next";

const PaymentWithSpinner = WithSpinner(Payment);

const PaymentContainer = () => {
  const { t: translate } = useTranslation();
  const [buttons, setButtons] = useState([]);
  const [copied, setCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [maxInstallments, setMaxInstallments] = useState(null);
  const [openingTax, setOpeningTax] = useState(null);
  const [resetCreditCardData, setResetCreditCardData] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showBilletForm, setShowBilletForm] = useState(false);
  const [showConfirmData, setShowConfirmData] = useState(true);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showModalCard, setShowModalCard] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showPixForm, setShowPixForm] = useState(false);
  const { enterprise, fetchingStatus, error, paymentByBillet, requestStatus } = useSelector(
    ({ payment }) => payment,
  );
  const { paymentForm, PaymentGatewaysRequestsStatus, paymentError } = useSelector(
    ({ paymentGateways }) => paymentGateways,
  );
  const dispatch = useDispatch();
  const { token } = useParams();
  const [scriptLoaded] = useScript(enterprise?.defaultPaymentGateway?.urlCheckout);
  const [confirmPaymentCaption, setConfirmPaymentCaption] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    dispatch(PaymentActions.fetchEnterpriseByToken(token));
  }, [token]);

  const paymentMethodError = () => {
    dispatch(PaymentGatewaysActions.cleanState());
    setFeedback({
      title: "",
      isSuccess: false,
      description: `Não foi possível processar sua solicitação.<br/>Atualize a página e tente novamente!`,
      showDeeplink: true,
      paymentFlag: false,
      buttons: [
        {
          text: "Tentar novamente",
          handleClick: () => {
            setShowFeedback(false);
            setFeedback(null);
          },
        },
      ],
    });
  };

  const paymentMethodSuccess = () => {
    openCheckout();
  };

  useEffect(() => {
    setIsDownloading(requestStatus.DOWNLOAD_GATEWAY_INVOICE === REQUEST_PENDING);
  }, [requestStatus]);

  useEffect(() => {
    setLoading(
      fetchingStatus === REQUEST_PENDING ||
        PaymentGatewaysRequestsStatus.PROCESS_PAYMENT === REQUEST_PENDING ||
        PaymentGatewaysRequestsStatus.PROCESS_PAYMENT_METHOD === REQUEST_PENDING,
    );
    if (PaymentGatewaysRequestsStatus.PROCESS_PAYMENT_METHOD === REQUEST_REJECTED) {
      paymentMethodError();
    }
    if (PaymentGatewaysRequestsStatus.PROCESS_PAYMENT_METHOD === REQUEST_RESOLVED) {
      paymentMethodSuccess();
    }
    if (PaymentGatewaysRequestsStatus.PROCESS_PAYMENT === REQUEST_RESOLVED) {
      setResetCreditCardData(true);
      setShowPaymentForm(false);
      setShowPixForm(false);
      setShowBilletForm(false);
      setShowConfirmData(false);
      setShowFeedback(true);
      setFeedback({
        title: "",
        isSuccess: true,
        description:
          paymentMethod === "1"
            ? "Aguarde... Carregando boleto."
            : "Aguarde... Validando informações.",
        buttons: [
          {
            text: "Nada aconteceu. Tentar novamente!",
            handleClick: () => {
              setShowFeedback(false);
              setFeedback(null);
              setShowPaymentForm(true);
            },
          },
        ],
      });
    }
  }, [fetchingStatus, PaymentGatewaysRequestsStatus]);

  useEffect(() => {
    const { subscription, accountInfo } = enterprise;
    setMaxInstallments(accountInfo?.data?.configuration?.credit_card?.max_installments || 1);
    if (subscription) {
      const { price, SubscriptionTaxes } = subscription[0];
      const subsOpenTax = SubscriptionTaxes.find((tax) => tax.Tax.name.indexOf("Abertura") > -1);
      if (subsOpenTax) setOpeningTax(subsOpenTax.Tax);
      const newPrice =
        Number(price) -
        (Number(paymentMethod) === 2 && subscription[0].paymentMethod.idPaymentMethod === 1
          ? enterprise.billetCostTax.price
          : 0);
      setConfirmPaymentCaption(
        `Pagar ${format(Number(newPrice).toFixed(2), MASK_TYPES.realWithName)}`,
      );
    } else setConfirmPaymentCaption("Pagar");
    setButtons([
      {
        text: "Pagar com boleto",
        handleClick: () => {
          dispatch(PaymentGatewaysActions.cleanState());
          setShowModalCard(false);
          setShowFeedback(false);
          setFeedback(null);
          processPaymentWithBillet();
        },
      },
      {
        text: "Pagar com outro cartão",
        handleClick: () => {
          dispatch(PaymentGatewaysActions.cleanState());
          setResetCreditCardData(true);
          setShowFeedback(false);
          setFeedback(null);
          setShowModalCard(true);
        },
      },
    ]);
    if (enterprise?.subscription && enterprise?.subscription[0]?.coupon?.couponType === "P")
      setPaymentMethod("2");
  }, [enterprise, paymentForm, paymentMethod]);

  useEffect(() => {
    if (fetchingStatus === REQUEST_REJECTED || paymentByBillet.status === REQUEST_REJECTED) {
      setLoading(false);

      let errorMsg = "Ops, algo deu errado. <br />Aguarde alguns instantes e tente novamente.";
      const btnText = "Voltar para home";

      if (error?.status === 404) {
        errorMsg =
          "Ops, algo deu errado. <br />Não encontramos as informações da empresa para pagamento.";
      }
      setResetCreditCardData(true);
      setShowPaymentForm(false);
      setShowPixForm(false);
      setShowBilletForm(false);
      setShowConfirmData(false);
      setShowFeedback(true);
      setFeedback({
        title: "Erro!",
        isSuccess: false,
        paymentFlag: true,
        description: errorMsg,
        btnText,
      });
    }
    if (PaymentGatewaysRequestsStatus.PROCESS_PAYMENT === REQUEST_PENDING) {
      setShowModalCard(false);
    }
    if (paymentByBillet.status === REQUEST_PENDING) {
      setLoading(true);
    }
    if (paymentByBillet.status === REQUEST_RESOLVED) {
      window.open(paymentByBillet.invoiceUrl, "_self");
      setLoading(false);
    }
  }, [error, fetchingStatus, PaymentGatewaysRequestsStatus, paymentByBillet]);

  const handleSuccess = ({ token: tokenPagarMe }) => {
    dispatch(PaymentActions.updatePaymentToken(token, tokenPagarMe));
    window.dataLayer.push({
      event: "CadastroFinalizado",
    });
    setFeedback({
      title: "Assinatura finalizada!",
      isSuccess: true,
      description: "Parabéns, você finalizou seu cadastro na Obvia.",
      showDeeplink: true,
      btnText: "Voltar para home",
      emphasis: "Fale com um contador",
      paymentFlag: true,
    });
  };

  const handleClipboard = (text) => {
    if (!text) return;
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleDownload = () => {
    dispatch(
      PaymentActions.downloadGatewayInvoice({
        enterpriseId: paymentForm?.enterpriseId,
        invoiceId: paymentForm?.invoiceId,
      }),
    );
  };

  const handleBack = () => {
    setShowPaymentForm(false);
    setShowPixForm(false);
    setShowBilletForm(false);
    setShowConfirmData(true);
    setResetCreditCardData(true);
    setFeedback(null);
    setPaymentMethod(null);
    dispatch(PaymentGatewaysActions.cleanState());
    dispatch(PaymentActions.fetchEnterpriseByToken(token));
  };

  const onResolved = () => {
    setShowModalCard(false);
    window.dataLayer.push({
      event: "CadastroFinalizado",
    });
    if (Number(paymentMethod) === 1) {
      // if (!paymentForm.success) {
      //   setFeedbackPayment(paymentForm?.error?.message || "");
      // } else {
      //   if (paymentForm.invoiceUrl && paymentForm.invoiceStatus === "pending")
      //     window.location.replace(paymentForm.invoiceUrl, "_blank");
      // }
      setShowPixForm(false);
      setShowConfirmData(false);
      setShowPaymentForm(false);
      if (!paymentForm.success) {
        setShowBilletForm(false);
        setFeedbackPayment(paymentForm?.error?.message || "");
        setShowFeedback(true);
      } else {
        setShowFeedback(false);
        setFeedback(null);
        setShowBilletForm(true);
      }
    } else if (Number(paymentMethod) === 3) {
      setShowBilletForm(false);
      setShowConfirmData(false);
      setShowPaymentForm(false);
      if (!paymentForm.success) {
        setShowPixForm(false);
        setFeedbackPayment(paymentForm?.error?.message || "");
        setShowFeedback(true);
      } else {
        setShowFeedback(false);
        setFeedback(null);
        setShowPixForm(true);
      }
    } else if (
      (paymentForm.invoiceUrl && paymentForm.invoiceStatus === "pending") ||
      paymentForm.invoiceStatus !== "paid"
    ) {
      dispatch(PaymentGatewaysActions.cleanState());
      dispatch(PaymentActions.fetchEnterpriseByToken(token));
      setShowBilletForm(false);
      setShowPaymentForm(false);
      setShowPixForm(false);
      setShowConfirmData(false);
      setResetCreditCardData(true);
      setFeedbackPayment(paymentForm?.error?.message || "");
      setShowFeedback(true);
    } else {
      let msg = "";
      if (
        paymentForm?.isTrial &&
        paymentForm?.invoiceStatus === "paid" &&
        paymentForm?.chargeDate
      ) {
        msg = `A cobrança da sua assinatura será realizada em <strong>${paymentForm?.chargeDate}</strong>`;
      }
      setResetCreditCardData(true);
      setShowPaymentForm(false);
      setShowPixForm(false);
      setShowBilletForm(false);
      setShowConfirmData(false);
      setFeedback({
        title: "Assinatura finalizada!",
        isSuccess: true,
        description: `Parabéns, você finalizou seu cadastro na Obvia. <br />${msg}`,
        showDeeplink: true,
        btnText: "Voltar para home",
        emphasis: "Fale com um contador",
        paymentFlag: true,
      });
      setShowFeedback(true);
      dispatch(PaymentGatewaysActions.cleanState());
    }
  };

  const setFeedbackPayment = (msg = "") => {
    setFeedback({
      title: "Falha ao processar o pagamento!",
      isSuccess: false,
      description: `Não foi possível processar o seu pagamento.<br/><br/>${msg}`,
      showDeeplink: true,
      paymentFlag: false,
      buttons: [
        {
          text: "Tentar novamente",
          handleClick: () => {
            setShowFeedback(false);
            setFeedback(null);
            setShowPaymentForm(true);
          },
        },
      ],
    });
  };

  const onRejected = () => {
    setShowModalCard(false);
    setShowPaymentForm(false);
    setShowPixForm(false);
    const msg = translate(paymentError?.data?.message);
    window.dataLayer.push({
      event: "ErroProcessamentoPagamento",
    });
    setResetCreditCardData(true);
    let buttons = [];
    if (paymentError?.data?.message === "subscription-already-paid") {
      buttons = [];
      buttons.push({
        text: "Voltar",
        handleClick: () => {
          setResetCreditCardData(true);
          setShowFeedback(false);
          setFeedback(null);
          window.location = "https://obviacontabilidade.com.br/";
        },
      });
    } else {
      buttons = [];
      buttons.push({
        text: "Tentar novamente",
        handleClick: () => {
          setFeedback(null);
          setShowFeedback(false);
          setShowModalCard(true);
        },
      });
    }
    setFeedback({
      title: "Falha ao processar o pagamento!",
      isSuccess: false,
      description: `Não foi possível processar o seu pagamento.<br/><br/>${msg}`,
      showDeeplink: true,
      paymentFlag: false,
      buttons,
    });
    setShowFeedback(true);
  };

  useRequest({
    request: PaymentGatewaysRequestsStatus.PROCESS_PAYMENT,
    onResolved,
    onRejected,
  });

  const confirmData = () => {
    setShowPixForm(false);
    setShowBilletForm(false);
    setShowConfirmData(false);
    setShowPaymentForm(true);
    setFeedback(null);
    setShowFeedback(false);
  };

  const openCheckout = () => {
    const { user, subscription, defaultPaymentGateway } = enterprise;
    if (!defaultPaymentGateway) {
      setFeedback({
        title: "Falha ao processar o pagamento!",
        isSuccess: false,
        description:
          "Não foi possível processar o seu pagamento. Parâmetros inválidos. Tente novamente mais tarde ou entre em contato com o suporte.",
        showDeeplink: true,
        btnText: "Voltar para home",
        paymentFlag: true,
        emphasis: "Fale com um contador",
      });
      dispatch(PaymentGatewaysActions.cleanState());
      return;
    }
    if (defaultPaymentGateway.code === "pagarme") {
      processCheckoutWithPagarme(user, subscription);
    }
    if (defaultPaymentGateway.code === "iugu") {
      processCheckoutWithIugu(subscription[0]);
    }
  };

  const processCheckoutWithIugu = (subscription) => {
    if (Number(paymentMethod) === 1) {
      dispatch(
        PaymentGatewaysActions.processPayment({
          paymentGatewayId: enterprise.defaultPaymentGateway.id,
          userId: enterprise.user.id,
          paymentToken: token,
          paymentMethodId: Number(paymentMethod),
        }),
      );
    } else if (Number(paymentMethod) === 3) {
      dispatch(
        PaymentGatewaysActions.processPayment({
          paymentGatewayId: enterprise.defaultPaymentGateway.id,
          userId: enterprise.user.id,
          paymentToken: token,
          paymentMethodId: 3,
        }),
      );
    } else setShowModalCard(true);
  };

  const handleSubmit = ({ form, ccNumber, subscriptionInstallments, openingTaxInstallments }) => {
    setPaymentMethod(2);
    window.Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID);
    if (process.env.REACT_APP_IUGU_TESTE_MODE == "true") {
      window.Iugu.setTestMode(true);
    }
    window.Iugu.createPaymentToken(form, function (response) {
      if (response.errors) {
        if (response.errors.adblock) {
          setErrorMessage(response.errors.adblock);
        }
        setShowMessageModal(true);
      } else {
        const brand = window.Iugu.utils.getBrandByCreditCardNumber(ccNumber);
        const paymentFormIdentifier = ccNumber.slice(-4);
        dispatch(
          PaymentGatewaysActions.processPayment({
            paymentFormIdentifier: `${brand} - ${paymentFormIdentifier}`,
            paymentGatewayId: enterprise.defaultPaymentGateway.id,
            userId: enterprise.user.id,
            paymentToken: token,
            token: response.id,
            subscriptionInstallments,
            openingTaxInstallments,
            paymentMethodId: 2,
          }),
        );
      }
    });
  };

  const processCheckoutWithPagarme = (user, subscription) => {
    const checkout = new window.PagarMeCheckout.Checkout({
      encryption_key: process.env.REACT_APP_ENCRYPTION_KEY,
      success: handleSuccess,
      error({ responseJSON }) {
        setFeedback({
          title: "Erro!",
          isSuccess: false,
          description: `Ops, algo deu errado. <br />Entre em contato com a Óbvia e informe o seguinte erro: ${
            responseJSON?.errors[0]?.message ||
            responseJSON?.errors?.message ||
            "Não foi possível identificar o erro"
          }.`,
        });
      },
    });

    const { id, price, paymentMethod, subscriptionPlan } = subscription[0];

    checkout.open({
      amount: Math.round(price * 100),
      buttonText: "Pagar",
      buttonClass: "botao-pagamento",
      customerData: "false",
      createToken: "true",
      paymentMethods: getPaymentMethod(paymentMethod.name),
      postbackUrl: POSTBACK_URL(id),
      maxInstallments: subscriptionPlan.numberMonths,
      customer: {
        external_id: enterprise?.id,
        name: user?.name,
        type: "individual",
        country: "br",
        email: user?.email,
        documents: [
          {
            type: "cpf",
            number: user?.cpf,
          },
        ],
        phone_numbers: [`+${sanitizePhone(user?.phoneNumber)}`],
      },
      billing: {
        name: user?.name,
        address: {
          street: enterprise.street,
          street_number: enterprise.number,
          zipcode: removeMask(enterprise.zipCode),
          country: "br",
          state: enterprise.state,
          city: enterprise.city,
          neighborhood: enterprise.neighborhood,
        },
      },
      items: [
        {
          id,
          title: subscriptionPlan.name,
          unit_price: Math.round(price * 100),
          quantity: 1,
          tangible: false,
        },
      ],
    });
  };

  const processPaymentWithBillet = () => {
    let invoice = null;
    if (enterprise.pendingInvoices && enterprise.pendingInvoices.length > 0) {
      invoice = enterprise.pendingInvoices.filter((i) => i.payableWith === "bank_slip");
    }
    if (invoice && invoice.length > 0) {
      window.open(invoice[0].invoiceUrl, "_self");
    } else {
      dispatch(
        PaymentActions.paymentByBillet({
          enterpriseId: enterprise.id,
          paymentToken: token,
          invoiceId: enterprise.pendingInvoices.length
            ? enterprise.pendingInvoices[0].paymentGatewaySubscriptionInvoiceId
            : null,
          paymentGatewayId: enterprise.defaultPaymentGateway.id,
        }),
      );
    }
  };

  return (
    <>
      <PaymentWithSpinner
        copied={copied}
        enablePayButton={scriptLoaded && !!enterprise}
        enterprise={enterprise}
        feedback={feedback}
        isLoading={loading}
        handleBack={handleBack}
        handleClick={openCheckout}
        handleClickData={confirmData}
        handleClipboard={handleClipboard}
        handleDownload={handleDownload}
        paymentForm={paymentForm}
        paymentMethod={paymentMethod?.toString()}
        setPaymentMethod={setPaymentMethod}
        showBilletForm={showBilletForm}
        showConfirmData={showConfirmData}
        showFeedback={showFeedback}
        showPaymentForm={showPaymentForm}
        showPixForm={showPixForm}
        isDownloading={isDownloading}
        message="Aguarde! Processando..."
      />
      <ModalCreditCard
        confirmLabel={confirmPaymentCaption}
        onConfirmModal={handleSubmit}
        onCloseModal={() => setShowModalCard(false)}
        showModal={showModalCard}
        width="43.5rem"
        enterprise={enterprise}
        openingTax={
          enterprise?.subscription &&
          enterprise?.subscription[0]?.subscriptionPlan?.period.toLowerCase() === "mensalidade"
            ? openingTax
            : null
        }
        resetForm={resetCreditCardData}
        paymentMethod={paymentMethod?.toString()}
        maxInstallments={maxInstallments}
        installments={enterprise.installments}
        openTaxInstallments={enterprise.openTaxInstallments}
      />
      <Modal
        title="Falha na validação do cartão"
        hideConfirm
        cancelLabel="Fechar"
        width="43.5rem"
        onCloseModal={() => setShowMessageModal(false)}
        showModal={showMessageModal}
      >
        {errorMessage ? (
          <Span>{errorMessage}</Span>
        ) : (
          <Span>
            Não foi possável validar o seu cartão de crédito. <br />
            Por favor, verifique os dados digitados ou tente novamente com um novo cartão de
            crédito.
          </Span>
        )}
      </Modal>
    </>
  );
};

export default PaymentContainer;
