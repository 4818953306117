import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { ClientActions, ClientTypes } from "store/ducks/client";
import { CLIENTS, CLIENTS_LIST, CLIENT_BY_ID } from "utils/constants/endpoints";
import { GET } from "utils/constants/verbs";

function* fetchClients({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: CLIENTS,
      params,
    });
    yield put(ClientActions.fetchClientsSuccess(data));
  } catch (error) {
    yield put(ClientActions.clientRequestFailure(error, type));
  }
}

function* fetchClientsList({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: CLIENTS_LIST,
      params,
    });
    yield put(ClientActions.fetchClientsListSuccess(data));
  } catch (error) {
    yield put(ClientActions.clientRequestFailure(error, type));
  }
}

function* fetchClientById({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: CLIENT_BY_ID(id),
      });
      yield put(ClientActions.fetchClientByIdSuccess(data));
    }
  } catch (error) {
    yield put(ClientActions.clientRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ClientTypes.FETCH_CLIENTS, fetchClients);
  yield takeLatest(ClientTypes.FETCH_CLIENTS_LIST, fetchClientsList);
  yield takeLatest(ClientTypes.FETCH_CLIENT_BY_ID, fetchClientById);
}
