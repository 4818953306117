import styled, { css } from "styled-components";

const outlineButton = css`
  background: transparent;
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);

  &:disabled {
    border-color: var(--color-gray-5);
    background-color: var(--color-white-1);
    color: var(--color-gray-5);
  }
`;

const outlineButtonWhite = css`
  background: transparent;
  border: 2px solid var(--color-white-1);
  color: var(--color-white-1);

  &:disabled {
    border-color: var(--color-gray-5);
    background-color: var(--color-white-1);
    color: var(--color-gray-5);
  }
`;

const contexButtonWhite = css`
  background: var(--color-white-1);
  border: 2px solid var(--color-white-1);
  color: var(--color-primary);
`;

const textButton = css`
  background: var(--color-white-1);
  border: none;
  color: var(--color-secondary);
  padding: 0 0.5rem;
  min-width: unset;
  height: unset;
  min-height: unset;

  &:disabled {
    background-color: var(--color-white-1);
    color: var(--color-gray-5);
  }
`;

const dangerStyle = css`
  border-color: var(--color-danger);
  color: var(--color-danger);

  ${({ variant }) =>
    variant === "primary" &&
    css`
      color: var(--color-white-1);
      background-image: linear-gradient(
        to right,
        var(--color-danger-2) 0%,
        var(--color-danger) 50%,
        var(--color-danger-2) 60%
      );
    `}
`;

const smallSizeStyle = css`
  font-size: 1.4rem;

  & > svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

const largeButtonStyle = css`
  border: none;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 1rem rgba(57, 57, 57, 0.15);
  font-size: 1.4rem;
  font-weight: 500;
  justify-content: space-between;
  height: 6.4rem;
  min-width: 34rem;
  padding: 0 1.6rem;

  &:hover {
    box-shadow: 0px 0px 2rem rgba(57, 57, 57, 0.4);
  }

  & > svg {
    margin-left: auto;
  }

  &:not(:last-child) {
    margin-right: 0;
    margin-bottom: var(--space);
  }
`;

const circleStyle = css`
  border-radius: 50%;
  height: 4.6rem;
  padding: 0;
  min-height: unset;
  min-width: unset;
  width: 4.6rem;

  & > svg,
  & > svg:first-child {
    margin: 0;
  }
`;

export const Button = styled.button.attrs(({ width, height, marginTop, marginRight }) => ({
  width: width ?? "unset",
  height: height ?? "4rem",
  marginTop: marginTop ?? "0",
  marginRight: marginRight ?? "2.4rem",
}))`
  background-image: linear-gradient(
    to right,
    var(--color-secondary) 0%,
    var(--color-primary) 50%,
    var(--color-secondary) 100%
  );
  background-position: left center;
  background-size: 200% 100%;
  border-radius: 2.4rem;
  color: var(--color-white-1);
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 4rem;
  min-height: 4rem;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin-top: ${({ marginTop }) => marginTop};
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;

  &:focus,
  &:hover {
    background-position: right center;
  }

  & > svg:first-child {
    &:not(:last-child) {
      margin-right: 1.3rem;
    }
  }

  &:not(:last-child) {
    margin-right: ${({ marginRight }) => marginRight};
  }

  &:disabled {
    background: var(--color-gray-5);
    cursor: not-allowed;
  }

  ${({ variant }) => variant === "outline" && outlineButton}
  ${({ variant }) => variant === "outline-white" && outlineButtonWhite}
  ${({ variant }) => variant === "context-white" && contexButtonWhite}
  ${({ variant }) => variant === "text" && textButton}
  ${({ large }) => large && largeButtonStyle}
  ${({ size }) => size === "small" && smallSizeStyle}
  ${({ color }) => color === "danger" && dangerStyle}
  ${({ shape }) => shape === "circle" && circleStyle}

  ${({ customStyle }) => customStyle}
`;

export const ButtonLink = styled.button`
  color: var(--color-secondary);
  cursor: pointer;
  font-size: var(--font-size-default);
`;

Button.displayName = "Button";
