import React from "react";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Container, Columns } from "components/core/Grid";
import StatusDot, { StatusTypes } from "components/core/StatusDot";

const GlobalSettingDetails = ({
  globalSetting: {
    id,
    active,
    version,
    allowInstallments,
    allowInstallmentsFirstSubscription,
    maxInstallmentsNumber,
    interestType,
    monthlyInterest,
    annualInterest,
    enterpriseType: { name },
  },
  plans,
  translate,
}) => {
  return (
    <Container>
      <Columns columns="3">
        <CardInfo label={translate("Version")} info={version} />
        <CardInfo label={translate("Enterprise Type")} info={name} />
        <CardInfo
          label={`${translate("Active")}?`}
          info={
            <>
              <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
              {active ? "Sim" : "Não"}
            </>
          }
        />
        <CardInfo
          label={translate("Allow Installments")}
          info={
            <>
              <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
              {allowInstallments ? "Sim" : "Não"}
            </>
          }
        />
        <CardInfo
          label={translate("Allow Installments First Subscription")}
          info={
            <>
              <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
              {allowInstallmentsFirstSubscription ? "Sim" : "Não"}
            </>
          }
        />
        <CardInfo label={translate("Maximum Installments Number")} info={maxInstallmentsNumber} />

        <CardInfo
          label={translate("Interest")}
          info={
            <>
              {Number(interestType) === 1 ? `${monthlyInterest}% a.m.` : `${annualInterest}% a.a.`}
            </>
          }
        />
        <CardInfo label={translate("Allowed Plans")} info={plans} />
      </Columns>
    </Container>
  );
};

GlobalSettingDetails.propTypes = {
  globalSetting: PropTypes.object.isRequired,
};

export default GlobalSettingDetails;
