import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  TableCheckbox,
  TableHeaderItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { urls } from "utils/constants";

const List = ({
  globalSettings,
  handleChange,
  toggleAll,
  everyoneIsChecked,
  params,
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();
  const {
    LINKS: {
      financial: {
        installments: { details },
      },
    },
  } = urls;
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "enterpriseTypeId", title: `${translate("Enterprise Type")}` },
    { slug: "active", title: `${translate("Active")}?` },
    { slug: "version", title: translate("Version") },
    { slug: "allowInstallments", title: `${translate("Allow Installments")}?` },
    { slug: "maxInstallmenstNumber", title: `${translate("Maximum Installments")}` },
    { slug: "monthlyInterest", title: `${translate("Parcel Interest")}` },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={everyoneIsChecked} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {globalSettings.map(
            ({
              id,
              active,
              version,
              allowInstallments,
              maxInstallmentsNumber,
              enterpriseType: { name },
              monthlyInterest,
              annualInterest,
              interestType,
              checked,
            }) => (
              <tr key={id}>
                <TableItem textAlign="center">
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => handleChange(id)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem>
                  <Link data-cy="id" to={details(id)}>
                    {id}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="enterpriseTypeId" to={details(id)}>
                    {name}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="active" to={details(id)}>
                    <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                    {active ? "Sim" : "Não"}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="version" to={details(id)}>
                    {version}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="allowInstallments" to={details(id)}>
                    <StatusDot type={allowInstallments ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                    {allowInstallments ? "Sim" : "Não"}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="maxInstallmentsNumber" to={details(id)}>
                    {maxInstallmentsNumber}
                  </Link>
                </TableItem>

                <TableItem>
                  <Link data-cy="monthlyInterest" to={details(id)}>
                    {Number(interestType) === 1 ? monthlyInterest : annualInterest}%{" "}
                    {Number(interestType) === 1 ? "a.m." : "a.a."}
                  </Link>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  globalSettings: PropTypes.array,
  params: PropTypes.object,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string,
  order: PropTypes.string,
};

List.defaultProps = {
  globalSettings: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
