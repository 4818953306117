import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { EmitterActions } from "store/ducks/emitter";
import { LayoutActions } from "store/ducks/layout";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import EmitterDetails from "components/presentation/Emitter/Details";
import { urls } from "utils/constants";
import { ReactComponent as IconPin } from "assets/icons/icon-pin.svg";
import { useFinancialSubscription } from "hooks";
import ReportBillingModal from "components/core/ReportBillingModal";
import WithSuspense from "components/core/WithSuspense";

const EmitterDetailsWithSpinner = WithSpinner(EmitterDetails);
const BillingReportModal = WithSuspense(ReportBillingModal);

const EmitterDetailsContainer = () => {
  const { id } = useParams();
  const { t: translate } = useTranslation();
  const { statusOfRequests, emitterDetails, statusLog } = useSelector(({ emitter }) => emitter);
  const { user } = useSelector((state) => state.auth.claims);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [plans, setPlans] = useState(null);
  const { getEnterpriseSubscriptions, enterpriseSubscriptions } = useFinancialSubscription();

  useEffect(() => {
    getEnterpriseSubscriptions(id);
  }, [id]);

  useEffect(() => {
    const plans = [];
    if (emitterDetails?.enterpriseInstallmentSetting) {
      if (emitterDetails?.enterpriseInstallmentSetting?.allowedInstallmentsPlans.includes(1)) {
        plans.push("Mensal");
      }
      if (emitterDetails?.enterpriseInstallmentSetting?.allowedInstallmentsPlans.includes(2)) {
        plans.push("Anual");
      }
      setPlans(plans.join(", "));
    }
    // const subscription = emitterDetails?.activePaymentGatewayClientSubscription;
  }, [emitterDetails]);

  useEffect(() => {
    if (emitterDetails?.id !== Number(id)) {
      dispatch(EmitterActions.fetchEmitterById(id));
      dispatch(EmitterActions.getStatusLog(id));
    }
  }, [dispatch, emitterDetails?.id, id]);

  const pinEmitter = () => {
    dispatch(LayoutActions.pinEmitter(emitterDetails));
    dispatch(EmitterActions.cleanState());
  };

  return (
    <>
      <NavBar
        title={
          user.roles === "client"
            ? `${user.enterprise.enterpriseName} - ${user.enterprise.cnpj}`
            : "Emissor"
        }
      >
        {user.roles === "client" ? null : (
          <>
            <Button handleClick={() => setShowModal(true)} variant="outline">
              {translate("Relátorio Faturamento")}
            </Button>
            <Button url={urls.LINKS.emitterSubscriptionEdit(id)} variant="outline">
              {translate("changePlan")}
            </Button>
            {/* <Button url="" variant="outline">
         {translate("plans")}
       </Button> */}
            <Button url={urls.LINKS.emitterEdit(id)} variant="outline">
              {translate("edit")}
            </Button>
            <Button handleClick={pinEmitter} disabled={isPending(statusOfRequests.details)}>
              <IconPin />
              <span>{translate("pinEmitter")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <EmitterDetailsWithSpinner
        requestStatus={statusOfRequests.details}
        emitter={emitterDetails}
        pinEmitter={pinEmitter}
        plans={plans}
        statusLog={statusLog}
        subscriptions={enterpriseSubscriptions}
        user={user}
      />
      <BillingReportModal
        loadComponent={showModal}
        data={emitterDetails}
        nameEnterprise={emitterDetails.enterpriseName}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default EmitterDetailsContainer;
