import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PasswordActions } from "store/ducks/password";
import ChangePassword from "components/presentation/Password/ChangePassword";
import WithSpinner from "components/core/WithSpinner";
import useFeedback from "hooks/useFeedback";
import { newPasswordSchema, initialValues, validatePassword } from "utils/schemas/password";
import {
  urls,
  REQUEST_PENDING,
  REQUEST_NOT_STARTED,
  INTERNAL_ERROR,
  BAD_REQUEST,
  NO_CONTENT,
  FEEDBACK_CHANGE_PASSWORD as feedbackDictionary,
} from "utils/constants";

const ChangePasswordWithSpinner = WithSpinner(ChangePassword);

const ChangePasswordContainer = () => {
  const history = useHistory();
  const [feedback, setFeedback] = useFeedback();
  const { token } = useParams();
  const {
    requestResponse: { status: statusCode },
    requestStatus,
  } = useSelector(({ password }) => password);
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestStatus !== REQUEST_PENDING && requestStatus !== REQUEST_NOT_STARTED) {
      const newFeedback = feedbackDictionary[statusCode] ?? feedbackDictionary[INTERNAL_ERROR];
      const handleClickFactory = {
        [BAD_REQUEST]: () => {
          dispatch(PasswordActions.cleanState());
          history.push(urls.ROUTES.recoverPassword.path);
        },
        [INTERNAL_ERROR]: () => setFeedback({}),
        [NO_CONTENT]: () => history.push(urls.ROUTES.login.path),
      };

      const handleClick = handleClickFactory[statusCode];

      setFeedback({
        ...newFeedback,
        handleClick,
      });
    }

    return () => setFeedback({});
  }, [statusCode, requestStatus]);

  const handleSubmit = (values) => dispatch(PasswordActions.changePasswordWithToken(values));

  return (
    <ChangePasswordWithSpinner
      isLoading={requestStatus === REQUEST_PENDING}
      validationSchema={newPasswordSchema}
      defaultValues={{ token, ...initialValues }}
      validatePassword={validatePassword}
      handleChangePassword={handleSubmit}
      showForm={!feedback?.show}
    />
  );
};

export default ChangePasswordContainer;
