import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Logo } from "assets/svg/logo-colored.svg";
import * as S from "./styled";

const Register = ({ routes: Routes }) => {
  window.dataLayer.push({
    event: "register-in-Action",
  });
  return (
    <S.Container>
      <S.Header>
        <Logo />
      </S.Header>
      <S.RegisterWrapper>
        <Routes />
      </S.RegisterWrapper>
    </S.Container>
  );
};

Register.propTypes = {
  routes: PropTypes.func.isRequired,
};

export default Register;
