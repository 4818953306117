import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: ReportsTypes, Creators: ReportsActions } = createActions({
  fetchReports: ["payload"],
  fetchReportsSuccess: ["payload"],
  fetchReportsPayments: ["payload"],
  fetchReportsPaymentsSuccess: ["payload"],
  reportsRequestFailure: ["error", "requestName"],
  cleanReportsState: null,
  exportFiles: ["reports"],
  exportFilesPayments: ["reports"],
  exportFilesPaymentsSuccess: ["payload"],
  exportFilesSuccess: ["payload"],
});

const INITIAL_STATE = {
  payload: null,
  exportEmitters: null,
  reports: {
    items: [],
  },
  reportsPayments: {
    items: [],
  },
  reportsRequests: {
    [ReportsTypes.REQUEST_NOT_STARTED]: REQUEST_NOT_STARTED,
    [ReportsTypes.EXPORT_EMITTERS]: REQUEST_NOT_STARTED,
    [ReportsTypes.FETCH_REPORTS_PAYMENTS]: REQUEST_NOT_STARTED,
  },
  statusOfRequests: {
    [ReportsTypes.EXPORT_FILES]: REQUEST_NOT_STARTED,
    [ReportsTypes.EXPORT_FILES_SUCCESS]: REQUEST_NOT_STARTED,
    [ReportsTypes.EXPORT_FILES_PAYMENTS]: REQUEST_NOT_STARTED,
    [ReportsTypes.EXPORT_FILES_PAYMENTS_SUCCESS]: REQUEST_NOT_STARTED,
  },
};

const fetchReports = (state) => ({
  ...state,
  reportsRequests: {
    ...state.reportsRequests,
    [ReportsTypes.FETCH_REPORTS]: REQUEST_PENDING,
  },
});

const fetchReportsSuccess = (state, { payload }) => ({
  ...state,
  reports: payload,
  reportsRequests: {
    ...state.reportsRequests,
    [ReportsTypes.FETCH_REPORTS]: REQUEST_RESOLVED,
  },
});
const fetchReportsPayments = (state, { payload }) => ({
  ...state,
  payload,
  reportsRequests: {
    ...state.reportsRequests,
    [ReportsTypes.FETCH_REPORTS_PAYMENTS]: REQUEST_PENDING,
  },
});

const fetchReportsPaymentsSuccess = (state, { payload }) => ({
  ...state,
  reportsPayments: payload,
  reportsRequests: {
    ...state.reportsRequests,
    [ReportsTypes.FETCH_REPORTS_PAYMENTS]: REQUEST_RESOLVED,
  },
});

const reportsRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  reportsRequests: {
    ...state.reportsRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanReportsState = () => ({ ...INITIAL_STATE });

const exportFiles = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [ReportsTypes.EXPORT_FILES]: REQUEST_PENDING,
  },
});

const exportFilesPayments = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [ReportsTypes.EXPORT_FILES_PAYMENTS]: REQUEST_PENDING,
  },
});

const exportFilesPaymentsSuccess = (state, { payload }) => ({
  ...state,
  exportFiles: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [ReportsTypes.EXPORT_FILES_PAYMENTS_SUCCESS]: REQUEST_RESOLVED,
  },
});

const exportFilesSuccess = (state, { payload }) => ({
  ...state,
  exportFiles: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [ReportsTypes.EXPORT_FILES_SUCCESS]: REQUEST_RESOLVED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ReportsTypes.FETCH_REPORTS]: fetchReports,
  [ReportsTypes.FETCH_REPORTS_SUCCESS]: fetchReportsSuccess,
  [ReportsTypes.FETCH_REPORTS_PAYMENTS]: fetchReportsPayments,
  [ReportsTypes.FETCH_REPORTS_PAYMENTS_SUCCESS]: fetchReportsPaymentsSuccess,
  [ReportsTypes.REPORTS_REQUEST_FAILURE]: reportsRequestFailure,
  [ReportsTypes.CLEAN_REPORTS_STATE]: cleanReportsState,
  [ReportsTypes.EXPORT_FILES]: exportFiles,
  [ReportsTypes.EXPORT_FILES_SUCCESS]: exportFilesSuccess,
  [ReportsTypes.EXPORT_FILES_PAYMENTS]: exportFilesPayments,
  [ReportsTypes.EXPORT_FILES_PAYMENTS_SUCCESS]: exportFilesPaymentsSuccess,
});
