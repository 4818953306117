import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  RenderHeaders,
  TableCheckbox,
  TableHeaderItem,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import { urls } from "utils/constants";
import { MASK_TYPES, format } from "utils/masks";
import { REQUEST_PENDING, REQUEST_NOT_STARTED } from "utils/constants/request";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";

const List = ({
  handleChange,
  toggleAll,
  everyoneIsChecked,
  subscriptions,
  requestStatus,
  params: { planCode, billingDate, setOrderBy, setFilterParams, setPage },
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();
  const {
    LINKS: {
      financial: {
        subscriptions: { details },
      },
    },
  } = urls;
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "enterpriseName", title: translate("emitter") },
    { slug: "enterpriseTypeName", title: translate("Emitter Type") },
    { slug: "planName", title: `${translate("Plan Type")}` },
    { slug: "price", title: `${translate("Value")}` },
    { slug: "competence", title: `Competência` },
    { slug: "nextPaymentDate", title: `Vencimento da Fatura` },
    { title: `Data do Faturamento` },
  ];

  const requestPending = (status) => {
    return status === REQUEST_PENDING || status === REQUEST_NOT_STARTED;
  };

  const handleSetPage = (page) => {
    if (planCode === "all") {
      setFilterParams({ billingDate });
    } else {
      setFilterParams({ planCode, billingDate });
    }
    setPage(page);
  };

  return requestPending(requestStatus) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <Table customStyle={{ marginTop: "1.75rem" }}>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={everyoneIsChecked} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {subscriptions.map(
            ({
              enterpriseName,
              userName,
              subscriptionId,
              price,
              planName,
              invoiceDueDate,
              billingExecutionDate,
              enterpriseTypeName,
              competence,
              checked,
            }) => (
              <tr key={subscriptionId}>
                <TableItem>
                  <TableCheckbox
                    name={`toggle_${subscriptionId}`}
                    value={subscriptionId}
                    handleChange={() => handleChange(subscriptionId)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="id">
                    {subscriptionId}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="enterpriseName">
                    {enterpriseName || userName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="enterpriseTypeName">
                    {enterpriseTypeName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="planName">
                    {planName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="price">
                    {format(Number(price).toFixed(2), MASK_TYPES.realWithName)}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="competence">
                    {competence}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="invoiceDueDate">
                    {invoiceDueDate}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(subscriptionId)} data-cy="billingExecutionDate">
                    {billingExecutionDate}
                  </Link>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={handleSetPage}
      />
    </>
  );
};

List.propTypes = {
  subscriptions: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  subscriptions: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
