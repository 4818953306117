export default {
  "amount-or-end-date-must-be-informed": "Quantidade de uso ou data final deve ser informado.",
  "aliquots-required-to-enterprise-of-type-presumed-profit":
    "Alíquotas são necessárias para empresas do tipo lucro presumido.",
  "billing-range-not-found": "Faixa de faturamento não encontrada.",
  "billing-is-a-required-field": "O campo de faturamento é necessário.",
  "billingMin-cannot-be-greater-than-billing-max":
    "Faturamento mínimo não pode ser maior que faturamento máximo.",
  "cannot-change-status-for-enterprise-who-never-paid":
    "Não é possível alterar o status de um emissor que não efetuou pagamento.",
  "cannot-disable-coupon-with-status-other-than-active":
    "Só é possível desativar um cupom com status ativo.",
  "cannot-change-coupon-with-this-status": "Não é possivel alterar esse cupom com esse status.",
  "cep-invalid": "CEP inválido.",
  "cep-not-found": "CEP não encontrado.",
  "cfops-already-registered": "CFOP já registrado.",
  "city-is-a-required-field": "$t(required.city).",
  "city-without-smu": "A cidade escolhida não tem SMU disponível.",
  "client-not-found": "Cliente não encontrado.",
  "code-ncm-already-registered": "Código do NCM ja registrado.",
  "connection-error": "Ocorreu um erro interno. Tente novamente em instantes.",
  "coupon-already-registered": "O código do cupom já existe.",
  "coupon-is-not-valid-for-this-location": "Cupom inválido para essa área.",
  "coupon-not-found": "Cupom não encontrado.",
  "coupon-has-already-been-used": "Cupom ja foi utilizado.",
  "csosns-already-registered": "CSOSN já registrado.",
  "cpf-is-a-required-field": "$t(required.cpf).",
  "cpf-already-registered": "CPF já registrado.",
  "cnpj-already-registered": "CNPJ já registrado.",
  "cnpj-not-yet-informed": "CNPJ não foi informado.",
  "cnae-already-registered": "CNAE ja está registrado.",
  "cnae-is-a-required-field": "CNAE é um campo necessário.",
  "crc-is-a-required-field": "É necessário informar o CRC.",
  "culturalPromoter-is-a-required-field": "$t(required.culturalPromoter).",
  "descriptionServiceCode-already-registered": "Descrição do serviço ja registrada.",
  "descriptionServiceCode-is-a-required-field": "Descrição é necessária.",
  "document-not-found": "Documento não encontrado.",
  "email-already-registered": "E-mail já cadastrado.",
  "email-is-a-required-field": "$t(required.email).",
  "email-must-be-a-valid-email": "Formato de e-mail inválido.",
  "enterprise-is-not-active": "A empresa não está ativa.",
  "enterprise-not-found": "Emissor não encontrado.",
  "enterpriseTypes-is-a-required-field": "$t(required.enterpriseTypes).",
  "files-being-compressed": "O arquivo está sendo processado e será enviado para o seu email.",
  "idBillingRange-is-a-required-field": "Faixa de cobrança é um campo obrigatório.",
  "idEnterpriseActivity-is-a-required-field": "$t(required.activity).",
  "idEnterpriseStatus-is-a-required-field": "$t(required.emitterStatus).",
  "idRole-is-required": "O tipo do perfil do contador é obrigatório.",
  "idTypeDocument-is-required": "Tipo de Documento é obrigatório.",
  "invalid-cnpj": "CNPJ inválido.",
  "invalid-file-extension": "Extensão de certificado digital inválida.",
  "invalid-password": "Senha incorreta.",
  "invalid-authorization-format": "Autorização inválida para a ação.",
  "invalid-price": "Preço inválido.",
  "invoiceTypes-is-a-required-field": "O tipo de nota é necessario.",
  "invalid-billing": "Campo de faturamento inválido.",
  "invalid-file-type": "Tipo do arquivo inválido.",
  "invoice-not-found": "Nota Fiscal não encontrada.",
  "it-should-not-be-changed-to-active-without-registering-the-serial-number":
    "Você não pode alterar o status do emissor antes de preencher o último número da nota fiscal emitida por ele.",
  "it-is-not-possible-to-select-product-and-consumer-invoices-for-this-state":
    "Não é possível selecionar faturas de produtos e consumidores para o Estado selecionado",
  "it-is-not-possible-to-issue-consumer-invoices-for-this-state":
    "Não é possível emitir faturas de consumidor para o estado selecionado",
  "it-is-not-possible-to-issue-service-invoices-for-this-city":
    "Não é possível emitir nota fiscal de serviço para a cidade selecionada",
  "jwt-expired": "Sua sessão expirou. Será necessário efetuar o login novamente.",
  "missing-authorization": "Ação não permitida.",
  "municipalServiceCode-already-registered": "Código do serviço municipal ja registrado.",
  "name-is-a-required-field": "$t(required.name).",
  "ncmsCategories-already-registered": "Categoria de ncm já registrada.",
  "ncm-category-not-found": "Categoria de ncm não encontrada.",
  "ncm-not-found": "NCM não encontrado.",
  "neighborhood-is-a-required-field": "$t(required.neighborhood).",
  "notification-not-found": "Notificação não encontrada.",
  "number-is-a-required-field": "$t(required.number).",
  "phoneNumber-is-a-required-field": "$t(required.phone).",
  "presumed-profit-does-not-issue-product-invoice": "Lucro presumido não emite nota de produto.",
  "product-not-found": "Produto não encontrado",
  "request-entity-too-large": "O tamanho do arquivo maior que o permitido.",
  "serviceListItem-already-registered": "Item da lista de serviço ja registrado.",
  "something-went-wrong": "Ocorreu um erro interno. Tente novamente em instantes.",
  "startDate-cannot-be-before-today": "A data de início não pode ser antes de hoje.",
  "state-is-a-required-field": "$t(required.state).",
  "states-or-cities-must-be-informed": "Estados ou cidades devem ser informados.",
  "street-is-a-required-field": "$t(required.street).",
  "subscription-not-found": "Informações de plano da empresa não encontrado.",
  "subscription-already-paid": "Pagamento já realizado.",
  "token-not-found": "Sua sessão expirou. Será necessário efetuar o login novamente.",
  "the-enterprise-cannot-become-active-without-smu": "A empresa não pode ficar ativa sem o SMU.",
  "type-enterprise-not-found": "Tipo da empresa não encontrado.",
  "unifiedMunicipalServices-is-a-required-field": "O SMU é necessário.",
  "user-not-found": "Usuário não encontrado.",
  "user-without-permission": "Você não tem permissão para acessar esse recurso.",
  "validation-fails": "Ocorreu um erro na validação dos parâmetros.",
  "wrong-format-for-authentication":
    "Sua sessão expirou. Será necessário efetuar o login novamente.",
  "zipCode-is-a-required-field": "$t(required.zipCode).",
  "invoiceSeries-is-a-required-field": "Você precisa informar a série.",
  "cnpj-has-not-yet-been-informed": "O Cnpj ainda não foi cadastrado.",
  "invoice-type-not-found": "Tipo de nota não encontrado.",
  "enterprise-name-has-not-yet-been-informed": "Arazão social ainda não foi informada.",
  "the-enterprise-cannot-become-active-without-csosn":
    "A empresa não pode se tornar ativa sem CSOSN",
  "invalid-credit-card": "Cartão de crédito inválido.",
};
