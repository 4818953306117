import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Subtitle } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import { useForm } from "react-hook-form";
import InputForm from "components/core/InputForm";
import Button from "components/core/Button";
import { Trans } from "react-i18next";
import * as yup from "yup";
import Feedback from "components/core/Feedback";
import { MASK_TYPES } from "utils/masks";
import * as S from "./styled";

export const EnterpriseCNPJ = ({ onBack, onContinue, feedback, handleCnpj, checkCnpj }) => {
  const [isRejected, setIsRejected] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const validation = yup.object().shape({
    cnpj: yup.string().required(<Trans i18nKey="required.cnpj" />),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: validation,
    mode: "onBlur",
    submitFocusError: true,
  });

  const errorCnpj = (value) => {
    if (!value) return errors.cnpj?.message;
    if (value) return <Trans i18nKey="required.cnpjAlreadyRegistered" />;
  };

  useEffect(() => {
    if (checkCnpj === "rejected") {
      setIsRejected(true);
    } else {
      setIsRejected(false);
    }
  }, [checkCnpj, isRejected, setIsRejected]);

  useEffect(() => {
    const button = document.getElementById("ButtonSubmit");
    if (button) {
      button.addEventListener("click", () => {
        setButtonClick(true);
      });
    }

    return () => {
      if (button) {
        button.removeEventListener("click", () => {
          setButtonClick(true);
        });
      }
    };
  }, []);

  useEffect(() => {
    if (checkCnpj === "resolved" && buttonClick) {
      const button = document.getElementById("ButtonSubmit");
      button.click();
      setButtonClick(false);
    }
  }, [buttonClick, checkCnpj]);

  const blockCnpj = () => {};

  const handleNextStep = (data) => {
    const event = "EtapaCNPJ";
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="28%" />
        </Container>
        <Subtitle mb="64px">
          {feedback ? "CNPJ Não encontrado!" : " Qual o CNPJ de sua empresa?"}
        </Subtitle>
        {feedback ? (
          <>
            <Feedback
              title={feedback?.title}
              description={feedback?.description}
              isSuccess={feedback?.isSuccess}
            />
            <Row horizontalAlign="center">
              <Button handleClick={onBack} variant="outline">
                Voltar
              </Button>
            </Row>
          </>
        ) : (
          <Form onSubmit={handleSubmit(checkCnpj === "resolved" ? handleNextStep : blockCnpj)}>
            <Row>
              <InputForm
                register={register}
                name="cnpj"
                type="text"
                label="CNPJ"
                mask={MASK_TYPES.cnpj}
                onBlur={handleCnpj}
                errorMsg={errorCnpj(isRejected)}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Row>
            <Row horizontalAlign="center">
              <Button handleClick={onBack} variant="outline">
                Voltar
              </Button>
              <Button id="ButtonSubmit" type="submit">
                Continuar
              </Button>
            </Row>
          </Form>
        )}
      </Flex>
    </S.RegisterContainer>
  );
};

EnterpriseCNPJ.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  handleCnpj: PropTypes.func.isRequired,
  checkCnpj: PropTypes.string.isRequired,
  feedback: PropTypes.object,
};

EnterpriseCNPJ.defaultProps = {
  feedback: null,
};

export default EnterpriseCNPJ;
