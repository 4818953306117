import React, { lazy, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BatchesActions } from "store/ducks/batches";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Paragraph } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import BatchesList from "components/presentation/Batches/List";
import { ReactComponent as EmptyScreen } from "assets/images/empty-batch.svg";
import { ErrorWrapper } from "components/core/ErrorBoundary/styled";
import { useItems, useParams } from "hooks";
import { ReactComponent as IconAdd } from "assets/icons/icon-plus.svg";
import { REQUEST_RESOLVED } from "utils/constants/request";

const NewBatchModal = lazy(() => import("containers/Batches/Register"));

const NewBatchModalWithSuspense = WithSuspense(NewBatchModal);
const BatchesListWithSpinner = WithSpinner(BatchesList);

const BatchesListContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [clientErrorList, setClientErrorList] = useState([]);

  const {
    BatchesRequests,
    batches: { items, total, totalPages },
  } = useSelector(({ batches }) => batches);

  const { newListClient } = useSelector(({ invoice }) => invoice);

  const { clientList } = useSelector(({ client }) => client);

  const fetchBatches = (newParams) => {
    dispatch(BatchesActions.getBatches(newParams));
  };

  const compareAll = (obj1, obj2) => {
    if (obj1.id === obj2.id) {
      return obj1;
    }
  };

  useEffect(() => {
    if (newListClient.length && clientList.length) {
      const error = newListClient.map((item) => {
        const clientFound = clientList.filter((a) => compareAll(a, item));
        const data = clientFound.find((x) => x);

        return data;
      });

      setClientErrorList(error);
      setShowModal(true);
    }
  }, [clientList, newListClient, newListClient.length]);

  const { handleChange, listChecked: itemsChecked, selectedItems, toggleAll } = useItems(items);
  const {
    params,
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
  } = useParams({
    callback: fetchBatches,
    defaultOrderBy: "batchDate",
    defaultOrder: "desc",
  });

  const newItemsChecked = itemsChecked ? itemsChecked.map((item) => ({ ...item })) : [];

  return (
    <>
      <NavBar title={translate("batchesList")}>
        <Button handleClick={() => setShowModal(true)}>
          <IconAdd />
          <span>{translate("addBatches")}</span>
        </Button>
      </NavBar>
      {BatchesRequests.getBatches === REQUEST_RESOLVED && items?.length === 0 ? (
        <ErrorWrapper>
          <EmptyScreen />
          <Paragraph>{translate("multipleInvoicesEmptyError")}</Paragraph>
          <Button variant="text" handleClick={() => setShowModal(true)}>
            <IconAdd />
            <span>{translate("addBatches")}</span>
          </Button>
        </ErrorWrapper>
      ) : (
        <BatchesListWithSpinner
          requestStatus={BatchesRequests.getBatches}
          batches={newItemsChecked}
          total={total}
          totalPages={totalPages}
          page={page}
          perPage={perPage}
          params={{
            setPerPage,
            setPage,
            setOrderBy,
            setOrder,
          }}
          orderBy={orderBy}
          order={order}
          handleChange={handleChange}
          toggleAll={toggleAll}
          checkedAll={selectedItems?.length === itemsChecked?.length}
        />
      )}

      <NewBatchModalWithSuspense
        loadComponent={showModal}
        showModal={showModal}
        setShowModal={setShowModal}
        clientErrorList={clientErrorList}
        setClientErrorList={setClientErrorList}
      />
    </>
  );
};

export default BatchesListContainer;
