export const getOnlyIDs = (items) => items.map(({ id }) => id);

export const destructionEmitterFromAPI = ({
  user,
  activity: { id: idEnterpriseActivity },
  status: { id: idEnterpriseStatus },
  culturalPromoter,
  idSpecialTaxRegime,
  secondaryActivities,
  enterpriseSubStatusId,
  invoiceTypes,
  smu,
  ...rest
}) => ({
  idEnterpriseStatus,
  idEnterpriseActivity,
  enterpriseSubStatusId,
  culturalPromoter: !!culturalPromoter,
  idSpecialTaxRegime: idSpecialTaxRegime ?? "0",
  specialTaxationRegime: false,
  secondaryActivities: secondaryActivities?.length
    ? JSON.stringify(getOnlyIDs(secondaryActivities))
    : "",
  invoiceTypes: invoiceTypes?.length ? JSON.stringify(getOnlyIDs(invoiceTypes)) : "",
  ...user,
  ...rest,
});
