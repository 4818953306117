import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Subtitle, Paragraph } from "components/core/Typography";
import { Field, InputWrapper } from "components/core/InputForm/styled";
import { Flex, Row, Columns, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import { useForm } from "react-hook-form";
import RadioButton from "components/core/RadioButton";
import { ReactComponent as IconPlus } from "assets/icons/icon-plus.svg";
import { ReactComponent as IconMinus } from "assets/icons/icon-minus.svg";
import Button from "components/core/Button";
import * as S from "./styled";

export const Workers = ({
  onBack,
  onContinue,
  type,
  setEmployeesNumber,
  employeesNumber,
  proLaboreNumber,
  setProLaboreNumber,
}) => {
  const [isChecked, setIsChecked] = useState(0);

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    submitFocusError: true,
  });

  useEffect(() => {
    if (isChecked !== 0) {
      setEmployeesNumber(0);
    } else {
      setEmployeesNumber(1);
    }
  }, [isChecked]);

  useEffect(() => {
    setProLaboreNumber(0);
  }, []);

  const handleNextStep = (data) => {
    const event = "EtapaFuncionarios";
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="70%" />
        </Container>
        <Subtitle mb="64px">Você possui funcionários na sua empresa?</Subtitle>
        <Form onSubmit={handleSubmit(handleNextStep)}>
          <Flex marginBottom="4rem">
            <Columns>
              <RadioButton
                register={register}
                name="isEmployees"
                label="Sim - Quantos?"
                id="yes"
                value="1"
                onClick={() => setIsChecked(0)}
                checked={isChecked === 0}
                handleChange={() => setIsChecked(0)}
              />

              <Row>
                <Button
                  shape="circle"
                  onClick={() => setEmployeesNumber(employeesNumber - 1)}
                  disabled={employeesNumber === 1 || isChecked !== 0}
                >
                  <IconMinus />
                </Button>
                <InputWrapper flexWidth="0" marginRight="2.4rem">
                  <Field
                    ref={register}
                    type="button"
                    as="button"
                    name="numberEmployees"
                    id="numberEmployees"
                    value={employeesNumber}
                  >
                    {employeesNumber}
                  </Field>
                </InputWrapper>
                <Button
                  shape="circle"
                  onClick={() => setEmployeesNumber(employeesNumber + 1)}
                  disabled={
                    type !== 1
                      ? employeesNumber === 10 || isChecked !== 0
                      : employeesNumber === 1 || isChecked !== 0
                  }
                >
                  <IconPlus />
                </Button>
              </Row>

              <RadioButton
                register={register}
                name="numberEmployees"
                id="no"
                label="Não"
                value="0"
                onClick={() => {
                  setIsChecked(1);
                }}
                checked={isChecked === 1}
                handleChange={() => setIsChecked(1)}
              />
              {/* {type !== 1 && (
                <>
                  <Paragraph color="#0071ee">Pro-labore</Paragraph>
                  <Row>
                    <Button
                      shape="circle"
                      onClick={() => setProLaboreNumber(proLaboreNumber - 1)}
                      disabled={proLaboreNumber === 0}
                    >
                      <IconMinus />
                    </Button>
                    <InputWrapper flexWidth="0" marginRight="2.4rem">
                      <Field
                        ref={register}
                        type="button"
                        as="button"
                        name="numberOwners"
                        id="numberOwners"
                        value={proLaboreNumber}
                      >
                        {proLaboreNumber}
                      </Field>
                    </InputWrapper>
                    <Button
                      shape="circle"
                      onClick={() => setProLaboreNumber(proLaboreNumber + 1)}
                      disabled={type !== 1 ? proLaboreNumber === 50 : proLaboreNumber === 0}
                    >
                      <IconPlus />
                    </Button>
                  </Row>
                </>
              )} */}
            </Columns>
          </Flex>
          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
            <Button type="submit">Continuar</Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

Workers.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  type: PropTypes.number,
  setEmployeesNumber: PropTypes.func.isRequired,
  employeesNumber: PropTypes.number.isRequired,
  proLaboreNumber: PropTypes.number.isRequired,
  setProLaboreNumber: PropTypes.func.isRequired,
};

Workers.defaultProps = {
  type: 0,
};
export default Workers;
