import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import TaxesDetails from "components/presentation/Taxes/Details";
import { TaxesActions } from "store/ducks/taxes";
import { EnterpriseActions } from "store/ducks/enterprise";
import { taxesEnum, ROUTES, LINKS } from "utils/constants";
import useTaxes from "hooks/useTaxes";

const TaxesDetailsWithSpinner = WithSpinner(TaxesDetails);

const TaxesDetailsContainer = () => {
  const dispatch = useDispatch();
  const { TaxesRequests } = useSelector(({ taxes }) => taxes);
  const [showTaxes, setShowTaxes] = useState(false);
  const [enterpriseKey, setEnterpriseKey] = useState(0);
  const [isMEI, setIsMEI] = useState(false);
  const { types, billingRanges, EnterpriseRequests } = useSelector(({ enterprise }) => enterprise);
  const [hasTaxes, getTax] = useTaxes();
  const history = useHistory();

  useEffect(() => {
    if (!types.length) {
      dispatch(EnterpriseActions.getTypes());
    }
  }, [dispatch]);

  const handleChangeEnterprise = ({ target: { value: idEnterpriseType } }) => {
    const newIdEnterprise = Number(idEnterpriseType);
    setShowTaxes(!!idEnterpriseType);
    setEnterpriseKey(newIdEnterprise);
    setIsMEI(newIdEnterprise === taxesEnum.meiId);

    if (newIdEnterprise !== taxesEnum.meiId) {
      dispatch(EnterpriseActions.getBillingRanges(newIdEnterprise));
    }
  };

  useEffect(() => {
    dispatch(TaxesActions.fetchTaxes());
  }, [dispatch]);

  const handleClick = () => {
    const pathToRedirect = isMEI
      ? ROUTES.taxes.children.mei.path
      : LINKS.editOtherTaxes(enterpriseKey);

    history.push(pathToRedirect);
  };

  return (
    <>
      <NavBar title="Planos" />
      <TaxesDetailsWithSpinner
        requestStatus={TaxesRequests.fetchTaxes}
        showTaxes={showTaxes}
        billings={billingRanges[enterpriseKey]}
        hasTaxes={hasTaxes}
        getTax={getTax}
        handleChangeEnterprise={handleChangeEnterprise}
        isMEI={isMEI}
        requestBillingRanges={EnterpriseRequests.getBillingRanges}
        types={types}
        handleClick={handleClick}
        idEnterpriseType={enterpriseKey}
      />
    </>
  );
};

export default TaxesDetailsContainer;
