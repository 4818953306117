import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: NcmTypes, Creators: NcmActions } = createActions({
  fetchNcmDetails: ["id"],
  fetchNcmDetailsSuccess: ["payload"],
  fetchAvailableNcm: ["params"],
  fetchAvailableNcmSuccess: ["payload"],
  fetchAvailableNcmWithParams: ["id", "params"],
  fetchAvailableNcmWithParamsSuccess: ["payload"],
  editNcm: ["id", "payload"],
  editNcmSuccess: ["payload"],
  deleteNcm: ["payload"],
  deleteNcmSuccess: ["payload"],
  ncmRequestFailure: ["error", "originalType"],
  cleanNcm: null,
});

const INITIAL_STATE = {
  ncmDetails: {},
  availableNcm: [],
  NcmRequests: {
    FETCH_NCM_DETAILS: REQUEST_NOT_STARTED,
    FETCH_NCM_LOCATIONS: REQUEST_NOT_STARTED,
    FETCH_AVAILABLE_NCM: REQUEST_NOT_STARTED,
    ADD_NCM_TO_EMITTER: REQUEST_NOT_STARTED,
    DELETE_NCM: REQUEST_NOT_STARTED,
    FETCH_AVAILABLE_NCM_WITH_PARAMS: REQUEST_NOT_STARTED,
  },
};

const fetchNcmDetails = (state) => ({
  ...state,
  NcmRequests: {
    ...state.NcmRequests,
    FETCH_NCM_DETAILS: REQUEST_PENDING,
  },
});

const fetchNcmDetailsSuccess = (state, { payload = {} }) => ({
  ...state,
  ncmDetails: payload,
  NcmRequests: {
    ...state.NcmRequests,
    FETCH_NCM_DETAILS: REQUEST_RESOLVED,
  },
});

const fetchAvailableNcm = (state) => ({
  ...state,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.FETCH_AVAILABLE_NCM]: REQUEST_PENDING,
  },
});

const fetchAvailableNcmSuccess = (state, { payload = [] }) => ({
  ...state,
  availableNcm: payload,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.FETCH_AVAILABLE_NCM]: REQUEST_RESOLVED,
  },
});

const fetchAvailableNcmWithParams = (state) => ({
  ...state,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.FETCH_AVAILABLE_NCM_WITH_PARAMS]: REQUEST_PENDING,
  },
});

const fetchAvailableNcmWithParamsSuccess = (state, { payload = {} }) => ({
  ...state,
  availableNcm: payload,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.FETCH_AVAILABLE_NCM_WITH_PARAMS]: REQUEST_RESOLVED,
  },
});

const editNcm = (state) => ({
  ...state,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.EDIT_NCM]: REQUEST_PENDING,
  },
});

const editNcmSuccess = (state, { payload = {} }) => ({
  ...state,
  ncmDetails: payload,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.EDIT_NCM]: REQUEST_RESOLVED,
  },
});

const deleteNcm = (state) => ({
  ...state,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.DELETE_NCM]: REQUEST_PENDING,
  },
});

const deleteNcmSuccess = (state, { payload = {} }) => ({
  ...state,
  ncmDetails: payload,
  NcmRequests: {
    ...state.NcmRequests,
    [NcmTypes.DELETE_NCM]: REQUEST_RESOLVED,
  },
});

const ncmRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  NcmRequests: {
    ...state.NcmRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanNcm = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [NcmTypes.FETCH_NCM_DETAILS]: fetchNcmDetails,
  [NcmTypes.FETCH_NCM_DETAILS_SUCCESS]: fetchNcmDetailsSuccess,
  [NcmTypes.FETCH_AVAILABLE_NCM]: fetchAvailableNcm,
  [NcmTypes.FETCH_AVAILABLE_NCM_SUCCESS]: fetchAvailableNcmSuccess,
  [NcmTypes.FETCH_AVAILABLE_NCM_WITH_PARAMS]: fetchAvailableNcmWithParams,
  [NcmTypes.FETCH_AVAILABLE_NCM_WITH_PARAMS_SUCCESS]: fetchAvailableNcmWithParamsSuccess,
  [NcmTypes.EDIT_NCM]: editNcm,
  [NcmTypes.EDIT_NCM_SUCCESS]: editNcmSuccess,
  [NcmTypes.DELETE_NCM]: deleteNcm,
  [NcmTypes.DELETE_NCM_SUCCESS]: deleteNcmSuccess,
  [NcmTypes.NCM_REQUEST_FAILURE]: ncmRequestFailure,
  [NcmTypes.CLEAN_NCM]: cleanNcm,
});
