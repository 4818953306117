import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Subtitle, Span } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import { useForm } from "react-hook-form";
import InputForm from "components/core/InputForm";
import Button from "components/core/Button";
import { Trans } from "react-i18next";
import * as S from "./styled";

export const FantasyName = ({ onBack, onContinue, isConstituted, storage }) => {
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    submitFocusError: true,
    defaultValues: storage.companyRegister,
  });

  const handleNextStep = (data) => {
    const event = "EtapaNomeFantasia";
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="34%" />
        </Container>
        <Subtitle mb="64px">Qual seria o nome fantasia?</Subtitle>
        <Form onSubmit={handleSubmit(handleNextStep)}>
          <Row>
            <InputForm
              register={register}
              name="fantasyName"
              type="text"
              label="Nome Fantasia"
              marginBottom="-1rem"
            />
          </Row>

          {!isConstituted && (
            <Row>
              <Span mb="4rem">
                Nome fantasia, é o nome da marca pelo qual a sua empresa será conhecida pelos seus
                clientes e público em geral.
              </Span>
            </Row>
          )}

          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
            <Button type="submit">Continuar</Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

FantasyName.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  isConstituted: PropTypes.bool.isRequired,
  storage: PropTypes.object.isRequired,
};

export default FantasyName;
