import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";

export const paymentGatewaySchema = Yup.object().shape({
  name: Yup.string().required(<Trans i18nKey="required.name" />),
  active: Yup.boolean().required(<Trans i18nKey="required.active" />),
  isDefault: Yup.boolean().required(<Trans i18nKey="required.isDefault" />),
});

export const paymentGatewayInitialValues = {
  name: "",
  active: true,
  isDefault: false,
};
