import { useSelector, useDispatch } from "react-redux";
import { FinancialActions } from "../store/ducks/financial";

const useFinancial = () => {
  const dispatch = useDispatch();

  const {
    downloadData,
    globalSettings,
    globalSetting,
    invoices,
    invoicesToCharge,
    invoiceItems,
    invoiceStatuses,
    paymentGatewayInfo,
    requestStatus,
    renewedInvoice,
    planInvoice,
    invoiceCharges,
  } = useSelector(({ financial }) => financial);

  const cleanState = () => dispatch(FinancialActions.cleanState());
  const getGlobalSetting = (id) => dispatch(FinancialActions.getGlobalSetting(id));
  const getGlobalSettings = (params) => dispatch(FinancialActions.getGlobalSettings(params));
  const getInvoices = (params) => dispatch(FinancialActions.getInvoices(params));
  const getFinancialInvoices = (params) => dispatch(FinancialActions.getFinancialInvoices(params));
  const getFinancialInvoicesToCharge = (params) =>
    dispatch(FinancialActions.getFinancialInvoicesToCharge(params));
  const getFinancialInvoiceItems = (invoiceId) =>
    dispatch(FinancialActions.getFinancialInvoiceItems(invoiceId));
  const getInvoiceStatuses = () => dispatch(FinancialActions.getInvoiceStatuses());
  const getPaymentGatewayInfo = (id, infoType) =>
    dispatch(FinancialActions.getPaymentGatewayInfo(id, infoType));
  const prepareInvoicesDownload = (params) =>
    dispatch(FinancialActions.prepareInvoicesDownload(params));
  const renewInvoice = (enterpriseId, invoiceId, token) =>
    dispatch(FinancialActions.renewInvoice(enterpriseId, invoiceId, token));
  const getPlanInvoice = (params) => dispatch(FinancialActions.getPlanInvoice(params));
  const setGlobalSetting = (payload) => dispatch(FinancialActions.setGlobalSetting(payload));
  const getFinancialInvoiceCharges = (invoiceId) =>
    dispatch(FinancialActions.getFinancialInvoiceCharges(invoiceId));

  return {
    cleanState,
    dispatch,
    downloadData,
    getGlobalSetting,
    getGlobalSettings,
    getInvoices,
    getFinancialInvoiceItems,
    getFinancialInvoices,
    getInvoiceStatuses,
    getPaymentGatewayInfo,
    globalSetting,
    globalSettings,
    invoices,
    invoiceItems,
    invoiceStatuses,
    paymentGatewayInfo,
    prepareInvoicesDownload,
    renewedInvoice,
    renewInvoice,
    requestStatus,
    setGlobalSetting,
    planInvoice,
    getPlanInvoice,
    getFinancialInvoicesToCharge,
    invoicesToCharge,
    invoiceCharges,
    getFinancialInvoiceCharges,
  };
};

export default useFinancial;
