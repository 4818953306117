import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { useRequest } from "hooks";
import { UsersActions } from "store/ducks/users";
import { LayoutActions } from "store/ducks/layout";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import UserRegister from "components/presentation/User/Register";
import { Span } from "components/core/Typography";
import { userSchema, userInitialValues } from "utils/schemas/user";
import { LINKS } from "utils/constants";

const UserRegisterWithSpinner = WithSpinner(UserRegister);

const UserRegisterContainer = () => {
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [createdUsername, setCreatedUsername] = useState();
  const { UsersRequests } = useSelector(({ users }) => users);
  const { roles } = useSelector(({ roles: rolesState }) => rolesState);

  const handleSubmit = ({ name, ...rest }) => {
    dispatch(UsersActions.createUser({ name, ...rest }));
    setCreatedUsername(name);
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans
              i18nKey="userCreated"
              values={{ name: createdUsername }}
              components={{ bold: <strong /> }}
            />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(UsersActions.cleanState());

    history.push(LINKS.userList);
  };

  useRequest({
    request: UsersRequests.createUser,
    onResolved,
  });

  return (
    <UserRegisterWithSpinner
      isLoading={isPending(UsersRequests.createUser)}
      handleClick={() => submitRef.current.click()}
      handleUserRegister={handleSubmit}
      defaultValues={userInitialValues}
      validationSchema={userSchema}
      roles={roles}
      submitRef={submitRef}
    />
  );
};

export default UserRegisterContainer;
