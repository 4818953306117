import React, { useState } from "react";
import PropTypes from "prop-types";
import { Subtitle, Span } from "components/core/Typography";
import { Flex, Row, Columns, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import { useForm } from "react-hook-form";
import RadioButton from "components/core/RadioButton";
import Button from "components/core/Button";
import * as S from "./styled";

export const EnterpriseType = ({ onBack, onContinue, setType, types }) => {
  const [isMei, setIsMei] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSimples, setIsSimples] = useState(false);
  const [isLucroPresumido, setIsLucroPresumido] = useState(false);
  const [isMeiSemPgto, setIsMeiSemPgto] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    submitFocusError: true,
  });

  const handleNextStep = (data) => {
    const event = "EtapaTipoEmpresa";
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="58%" />
        </Container>
        <Subtitle mb="64px">Qual tipo de empresa?</Subtitle>
        <Form onSubmit={handleSubmit(handleNextStep)}>
          <Flex marginBottom="4rem">
            <Columns>
              <RadioButton
                register={register}
                name="idEnterpriseType"
                label="MEI"
                id="mei"
                value={types[0]?.id}
                onClick={() => {
                  setIsMei(true);
                  setIsSimples(false);
                  setIsLucroPresumido(false);
                  setIsMeiSemPgto(false);
                  setType(1);
                }}
                checked={isMei}
                handleChange={() => setIsChecked(true)}
              />
              {isMei && <Span mb="4rem">{types[0]?.description}</Span>}
              <RadioButton
                register={register}
                name="idEnterpriseType"
                label="Simples"
                id="simples"
                value={types[1]?.id}
                onClick={() => {
                  setIsMei(false);
                  setIsSimples(true);
                  setIsLucroPresumido(false);
                  setIsMeiSemPgto(false);
                  setType(2);
                }}
                checked={isSimples}
                handleChange={() => setIsChecked(true)}
              />
              {isSimples && <Span mb="4rem">{types[1]?.description}</Span>}
              <RadioButton
                register={register}
                name="idEnterpriseType"
                label="Lucro Presumido"
                id="lucro"
                value={types[2]?.id}
                onClick={() => {
                  setIsMei(false);
                  setIsSimples(false);
                  setIsLucroPresumido(true);
                  setIsMeiSemPgto(false);
                  setType(3);
                }}
                checked={isLucroPresumido}
                handleChange={() => setIsChecked(true)}
              />
              {isLucroPresumido && <Span mb="4rem">{types[2]?.description}</Span>}
              <RadioButton
                register={register}
                name="idEnterpriseType"
                label="MEI sem Emissão NFe"
                id="meisempgto"
                value={types[3]?.id}
                onClick={() => {
                  setIsMei(false);
                  setIsSimples(false);
                  setIsLucroPresumido(false);
                  setIsMeiSemPgto(true);
                  setType(4);
                }}
                checked={isMeiSemPgto}
                handleChange={() => setIsChecked(true)}
              />
              {isMeiSemPgto && <Span mb="4rem">{types[3]?.description}</Span>}
            </Columns>
          </Flex>
          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
            <Button type="submit" disabled={!isChecked}>
              Continuar
            </Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

EnterpriseType.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
};

export default EnterpriseType;
