import { call, put, takeLatest } from "redux-saga/effects";
import { NotificationTypes, NotificationActions } from "store/ducks/notification";
import api from "services/api";
import { GET, PUT, DELETE } from "utils/constants/verbs";
import { NOTIFICATIONS, NOTIFICATIONS_TYPES, NOTIFICATION_BY_ID } from "utils/constants/endpoints";

export function* notificationRequestFailure(error, originalType) {
  yield put(NotificationActions.notificationRequestFailure(error, originalType));
}

function* fetchNotifications({ params, callByService, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: NOTIFICATIONS,
      params,
    });
    yield put(NotificationActions.fetchNotificationsSuccess(data, callByService));
  } catch (error) {
    yield call(notificationRequestFailure, error, type);
  }
}

function* fetchTypes({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: NOTIFICATIONS_TYPES,
      params,
    });
    yield put(NotificationActions.fetchTypesSuccess(data));
  } catch (error) {
    yield call(notificationRequestFailure, error, type);
  }
}

function* updateNotification({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PUT,
        url: NOTIFICATION_BY_ID(id),
      });
      yield put(NotificationActions.updateNotificationSuccess(data));
    }
  } catch (error) {
    yield call(notificationRequestFailure, error, type);
  }
}

function* deleteNotification({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: DELETE,
        url: NOTIFICATION_BY_ID(id),
      });
      yield put(NotificationActions.deleteNotificationSuccess(id, data));
    }
  } catch (error) {
    yield call(notificationRequestFailure, error, type);
  }
}

function* deleteAllNotifications({ type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: NOTIFICATIONS,
    });
    yield put(NotificationActions.deleteAllNotificationsSuccess(data));
  } catch (error) {
    yield call(notificationRequestFailure, error, type);
  }
}

export function* watchSagas() {
  yield takeLatest(NotificationTypes.FETCH_NOTIFICATIONS, fetchNotifications);
  yield takeLatest(NotificationTypes.FETCH_TYPES, fetchTypes);
  yield takeLatest(NotificationTypes.UPDATE_NOTIFICATION, updateNotification);
  yield takeLatest(NotificationTypes.DELETE_NOTIFICATION, deleteNotification);
  yield takeLatest(NotificationTypes.DELETE_ALL_NOTIFICATIONS, deleteAllNotifications);
}
