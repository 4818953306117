import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { useRequest } from "hooks";
import { UsersActions } from "store/ducks/users";
import { LayoutActions } from "store/ducks/layout";

import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import UserEdit from "components/presentation/User/Edit";

import { urls, LINKS } from "utils/constants";
import { userSchema } from "utils/schemas/user";

const UserEditWithSpinner = WithSpinner(UserEdit);

const roles = [
  {
    idRole: 1,
    label: "Administrador",
  },
  {
    idRole: 2,
    label: "Contador",
  },
  {
    idRole: 4,
    label: "Marketing",
  },
];

const UserEditContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const submitRef = useRef(null);
  const { id } = useParams();
  const { userDetails, UsersRequests } = useSelector(({ users }) => users);

  useEffect(() => {
    if (userDetails?.id !== Number(id)) {
      dispatch(UsersActions.getUserById(id));
    }
  }, [id, userDetails?.id, dispatch]);

  const handleSubmit = (values) => {
    const { idRole, ...user } = values;
    user.idRole = +idRole;

    dispatch(UsersActions.editUser(id, user));
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{translate("userEdited")}</Span>,
        type: "success",
      }),
    );
    dispatch(UsersActions.cleanState());

    history.push(LINKS.userList);
  };

  useRequest({
    request: UsersRequests.editUser,
    onResolved,
  });

  return (
    <>
      <NavBar title={translate("editUser")}>
        <Button url={urls.LINKS.userDetails(id)} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("save")}</Button>
      </NavBar>
      <UserEditWithSpinner
        requestStatus={UsersRequests.getUserById}
        id={+id}
        handleUserEdit={handleSubmit}
        defaultValues={userDetails}
        validationSchema={userSchema}
        roles={roles}
        submitRef={submitRef}
      />
    </>
  );
};

export default UserEditContainer;
