import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { getStates, getCitiesByState } from "utils/mocks/brazilCitiesStates";

import { Container } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import { Subtitle } from "components/core/Typography";
import { Form, InputHidden } from "components/core/Form";

const UserEdit = ({ defaultValues, validationSchema, handleUserEdit, roles, submitRef }) => {
  const { t: translate } = useTranslation();
  const [cities, setCities] = useState([]);
  const [getRole, setGetRole] = useState(0);
  const { register, handleSubmit, errors, watch } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });
  const watchState = watch("state");
  const watchRole = watch("idRole");

  const states = getStates();

  useEffect(() => {
    setCities(getCitiesByState(watchState));
  }, [watchState]);

  useEffect(() => {
    setGetRole(Number(watchRole));
  }, [watchRole]);

  return (
    <Container maxWidth="md">
      <Subtitle>Edite as Informações</Subtitle>
      {cities.length > 0 && (
        <Form onSubmit={handleSubmit(handleUserEdit)}>
          <InputForm
            register={register}
            type="text"
            name="name"
            label={translate("name")}
            errorMsg={errors.name?.message}
          />
          <InputForm
            register={register}
            type="email"
            name="email"
            label={translate("email")}
            errorMsg={errors.email?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="cpf"
            label={translate("cpf")}
            mask="cpf"
            errorMsg={errors.cpf?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="phoneNumber"
            label={translate("phone")}
            mask="phone"
            errorMsg={errors.phoneNumber?.message}
          />
          <SelectForm
            register={register}
            name="state"
            label={translate("state")}
            options={states.map((state) => ({ text: state, value: state }))}
            errorMsg={errors.state?.message}
          />
          <SelectForm
            register={register}
            name="city"
            label={translate("city")}
            options={cities.map((city) => ({ text: city, value: city }))}
            errorMsg={errors.city?.message}
          />
          <SelectForm
            register={register}
            name="idRole"
            label={translate("profileType")}
            options={roles.map(({ idRole, label }) => ({ text: label, value: +idRole }))}
            errorMsg={errors.idRole?.message}
          />
          {getRole !== 4 && getRole !== 1 && (
            <InputForm
              register={register}
              type="text"
              name="crc"
              label={translate("crc")}
              errorMsg={errors.crc?.message}
            />
          )}
          <SelectForm
            register={register}
            name="active"
            label="Status"
            options={[
              { text: "Ativo", value: true },
              { text: "Inativo", value: false },
            ]}
            errorMsg={errors.active?.message}
          />
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
      )}
    </Container>
  );
};

UserEdit.propTypes = {
  roles: PropTypes.array.isRequired,
  submitRef: PropTypes.object.isRequired,
  handleUserEdit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default UserEdit;
