import React from "react";
import EmptyFeedback from "components/core/EmptyFeedback";

const WithEmptyFeedback =
  (WrappedComponent) =>
  ({ total, feedbackTextOnly, ...otherProps }) => {
    if (!total) {
      return <EmptyFeedback feedbackTextOnly={feedbackTextOnly} />;
    }
    return <WrappedComponent total={total} {...otherProps} />;
  };

export default WithEmptyFeedback;
