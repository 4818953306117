import React from "react";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Subtitle } from "components/core/Typography";
import { Container } from "components/core/Grid";
import useRoleLabel from "hooks/useRoleLabel";

const MyProfile = ({ user: { name, cpf, email, idRole, phoneNumber, city, crc } }) => {
  const [getRoleLabel] = useRoleLabel();

  return (
    <Container>
      <Subtitle>Perfil</Subtitle>
      <CardInfo label="Nome" info={!!name && name} />
      <CardInfo label="CPF" info={!!cpf && cpf} />
      <CardInfo label="E-mail" info={!!email && email} />
      <CardInfo label="Perfil" info={!!idRole && getRoleLabel(idRole)} />
      <CardInfo label="Telefone" info={!!phoneNumber && phoneNumber} />
      <CardInfo label="Cidade" info={!!city && city} />
      {idRole !== 1 && idRole !== 4 && <CardInfo label="CRC" info={!!crc && crc} />}
    </Container>
  );
};

MyProfile.propTypes = {
  user: PropTypes.object.isRequired,
};

export default MyProfile;
