import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Title = styled.p`
  color: var(--color-gray-6);
  font-size: 2rem;
  font-weight: 500;
  margin-right: 0.8rem;
`;
