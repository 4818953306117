import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import { Container, Columns } from "components/core/Grid";
import { Divider } from "components/core/Typography";
import StatusDot from "components/core/StatusDot";
import { formatDate } from "helpers/format";
import { INVOICE_COLORS } from "utils/constants/dictionaries";

const InvoiceDetails = ({ invoice }) => {
  const {
    client,
    cityServiceProvision,
    dateIssue,
    invoiceType,
    description,
    enterprise,
    stateServiceProvision,
    dateCompetence,
    status,
    invoiceNumber,
    reasonStatus,
  } = invoice;
  const { t: translate } = useTranslation();
  const statusInfo = (
    <>
      <StatusDot type={INVOICE_COLORS[status]} /> {status}
    </>
  );
  return (
    <Container>
      <Columns columns="2">
        <CardInfo label={translate("emitterName")} info={enterprise.enterpriseName} lg />
        <CardInfo label={translate("recipientName")} info={client.clientName} lg />
      </Columns>
      <Columns columns="2">
        <CardInfo label={translate("cnpj")} info={enterprise.cnpj} />
        <CardInfo label={translate("cpfCnpj")} info={client.cnpj ?? client.cpf} />
      </Columns>
      <Divider />
      <Columns columns="2">
        <CardInfo label={translate("invoiceNumber")} info={invoiceNumber} />
        <CardInfo label={translate("status")} info={statusInfo} />
      </Columns>
      <Divider />
      <Columns columns="2">
        {cityServiceProvision && stateServiceProvision && (
          <CardInfo
            label={translate("local")}
            info={`${cityServiceProvision}/${stateServiceProvision}`}
          />
        )}
        <CardInfo label={translate("type")} info={invoiceType.name} />
      </Columns>
      <Columns columns="2">
        <CardInfo
          label={translate("dateIssue")}
          info={formatDate(DateTime.fromISO(dateIssue).toFormat("yyyy-MM-dd"))}
        />
        <CardInfo
          label={translate("competencyDate")}
          info={formatDate(DateTime.fromISO(dateCompetence).toFormat("yyyy-MM-dd"))}
        />
      </Columns>
      <Divider />
      <Columns columns="2">
        <CardInfo label={translate("description")} info={description} />
        {reasonStatus && <CardInfo label={translate("reasonStatus")} info={reasonStatus} />}
      </Columns>
    </Container>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default InvoiceDetails;
