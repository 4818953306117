import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InvoicePaymentActions } from "store/ducks/invoicePayment";
import { PaymentActions } from "store/ducks/payment";
import useScript from "hooks/useScript";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import InvoicePayment from "components/presentation/InvoicePayment";
import { MASK_TYPES, format } from "utils/masks";
import { ModalCreditCard, Span } from "components/core";
import { Modal } from "components/core/Modal";
import { DateTime } from "luxon";
import { useFinancial } from "hooks";
import { REQUEST_PENDING, REQUEST_RESOLVED, REQUEST_REJECTED } from "utils/constants/request";

const InvoicePaymentWithSpinner = WithSpinner(InvoicePayment);

const InvoicePaymentContainer = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const {
    invoicePayment: {
      invoice: { enterprise, globalInstallmentSetting, accountInfo, installments, ...invoice },
      invoiceCharge,
      requestStatus,
    },
  } = useSelector((invoicePayment) => invoicePayment);
  const [scriptLoaded] = useScript(invoice?.paymentGateway?.urlCheckout);
  const { getPlanInvoice, planInvoice, requestStatus: financialRequestStatus } = useFinancial();
  const [feedback, setFeedback] = useState(null);
  const [showModalCard, setShowModalCard] = useState(false);
  const [resetCreditCardData, setResetCreditCardData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showPixForm, setShowPixForm] = useState(false);
  const [selectedNewPlan, setSelectedNewPlan] = useState(null);
  const [showBilletForm, setShowBilletForm] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmPaymentCaption, setConfirmPaymentCaption] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [hasItemBillet, setHasItemBillet] = useState(null);
  const [maxInstallments, setMaxInstallments] = useState(null);

  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

  const checkOverDueDate = (dueDate) => {
    const date = DateTime.fromISO(dueDate);
    const today = DateTime.now();
    const diffDays = today.diff(date, "days").toObject().days;
    return diffDays <= 7;
  };

  const ableRenovation = checkOverDueDate(invoice.dueDate);

  if (financialRequestStatus.RENEW_INVOICE === REQUEST_REJECTED) {
    setFeedback({
      title: "",
      isSuccess: false,
      description:
        "Não foi possível renovar a fatura! Caso o problema persista, entre em contato com o suporte.",
      showDeeplink: true,
      emphasis: "",
      paymentFlag: false,
      buttons: [
        {
          text: "Voltar",
          handleClick: () => {
            window.reload();
          },
        },
      ],
    });
  }

  useEffect(() => {
    dispatch(InvoicePaymentActions.getInvoiceByToken(token));
  }, [token, dispatch]);

  useEffect(() => {
    setLoading(
      isPending(requestStatus.GET_INVOICE_BY_TOKEN) ||
        isPending(requestStatus.CHARGE_INVOICE) ||
        isPending(financialRequestStatus.RENEW_INVOICE) ||
        isPending(financialRequestStatus.GET_PLAN_INVOICE),
    );
    setMaxInstallments(accountInfo?.data?.configuration?.credit_card?.max_installments || 1);
    if (requestStatus.GET_INVOICE_BY_TOKEN === REQUEST_REJECTED) {
      dispatch(InvoicePaymentActions.cleanState());
      setFeedback({
        title: "",
        isSuccess: false,
        description: "Não encontramos as informações da fatura!",
        showDeeplink: true,
        emphasis: "",
        paymentFlag: false,
      });
    }
    if (requestStatus.CHARGE_INVOICE === REQUEST_PENDING) {
      setShowModalCard(false);
    }
    if (invoice.payableWith === "credit_card") {
      setPaymentMethod("2");
    }
    const hasBillet = invoice?.invoiceItems?.find(
      (item) => item?.description.indexOf("Boleto") !== -1,
    );
    if (hasBillet) {
      setHasItemBillet(hasBillet);
    }
  }, [
    setLoading,
    setFeedback,
    requestStatus,
    setPaymentMethod,
    invoice,
    setShowModalCard,
    financialRequestStatus,
    accountInfo,
  ]);

  useEffect(() => {
    setLoading(isPending(financialRequestStatus.GET_PLAN_INVOICE));
    if (financialRequestStatus.GET_PLAN_INVOICE === REQUEST_RESOLVED) {
      if (paymentMethod === "1") setShowBilletForm(true);
      if (paymentMethod === "3") setShowPixForm(true);
    }
  }, [financialRequestStatus]);

  useEffect(() => {
    if (invoiceCharge) {
      setResetCreditCardData(true);
      dispatch(InvoicePaymentActions.cleanState());
      if (invoiceCharge.success) {
        setFeedback({
          title: "",
          isSuccess: true,
          description: `Seu pagamento está sendo processado, e assim que houver a compensação bancária, constará no aplicativo!`,
          showDeeplink: false,
          paymentFlag: true,
          buttons:
            process.env.REACT_APP_URL_APPS && process.env.REACT_APP_ALLOW_OPEN_MOBILE == "true"
              ? [
                  {
                    text: "Voltar",
                    handleClick: () => {
                      window.open(process.env.REACT_APP_URL_APPS, "_self");
                    },
                  },
                ]
              : [],
        });
        setShowModalCard(false);
      } else {
        setFeedback({
          title: "Falha ao processar o pagamento!",
          isSuccess: false,
          description: invoiceCharge.errors,
          showDeeplink: false,
          paymentFlag: true,
          buttons: [
            {
              text: "Tentar novamente",
              handleClick: () => {
                // setShowModalCard(true);
                window.location.reload();
              },
            },
          ],
        });
      }
    }
  }, [invoiceCharge, setFeedback, setShowModalCard, setResetCreditCardData]);

  const handleClick = () => {
    if (paymentMethod === "1") {
      if (!selectedNewPlan) {
        setShowBilletForm(true);
      } else {
        getPlanInvoice({
          newPlan: {
            code: selectedNewPlan,
            ...invoice?.planValues?.[selectedNewPlan],
          },
          currentPlan: invoice?.subscription?.currentPlan,
          enterpriseId: invoice?.enterpriseId,
          invoiceId: invoice?.id,
          subscriptionId: invoice?.subscription?.id,
          paymentMethodId: Number(paymentMethod),
        });
      }
    } else if (paymentMethod === "3") {
      if (!selectedNewPlan) {
        setShowPixForm(true);
      } else {
        getPlanInvoice({
          newPlan: {
            code: selectedNewPlan,
            ...invoice?.planValues?.[selectedNewPlan],
          },
          currentPlan: invoice?.subscription?.currentPlan,
          enterpriseId: invoice?.enterpriseId,
          invoiceId: invoice?.id,
          subscriptionId: invoice?.subscription?.id,
          paymentMethodId: Number(paymentMethod),
        });
      }
    } else {
      if (!selectedNewPlan) {
        const price = hasItemBillet
          ? Number(invoice?.total) - Number(invoice?.billetCostTax?.price)
          : Number(invoice?.total);
        setConfirmPaymentCaption(`Pagar ${format(price.toFixed(2), MASK_TYPES.realWithName)}`);
        setShowModalCard(true);
      } else {
        const price = totalPrice();
        setConfirmPaymentCaption(`Pagar ${format(price.toFixed(2), MASK_TYPES.realWithName)}`);
        setShowModalCard(true);
      }
    }
  };

  const handlePayment = async ({
    form,
    ccNumber,
    subscriptionInstallments,
    openingTaxInstallments,
  }) => {
    window.Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID);
    if (process.env.REACT_APP_IUGU_TESTE_MODE == "true") {
      window.Iugu.setTestMode(true);
    }
    window.Iugu.createPaymentToken(form, function (response) {
      if (response.errors) {
        if (response.errors.adblock) {
          setErrorMessage(response.errors.adblock);
        }
        setShowMessageModal(true);
      } else {
        const brand = window.Iugu.utils.getBrandByCreditCardNumber(ccNumber);
        const paymentFormIdentifier = ccNumber.slice(-4);
        const data = {
          paymentFormIdentifier: `${brand} - ${paymentFormIdentifier}`,
          paymentToken: token,
          token: response.id,
          paymentMethodId: Number(paymentMethod),
          invoiceInstallments: subscriptionInstallments,
          openingTaxInvoiceInstallments: openingTaxInstallments,
          currentPlan: invoice?.subscription?.currentPlan,
          newPlan: null,
        };
        if (selectedNewPlan) {
          data.newPlan = {
            code: selectedNewPlan,
            ...invoice?.planValues?.[selectedNewPlan],
          };
        }
        dispatch(InvoicePaymentActions.chargeInvoice(data));
      }
    });
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleClipboard = (text) => {
    if (!text) return;
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleDownload = () => {
    dispatch(
      PaymentActions.downloadGatewayInvoice({
        enterpriseId: invoice?.enterpriseId,
        invoiceId: invoice?.paymentGatewaySubscriptionInvoiceId,
      }),
    );
  };

  const handleChangePlan = ({ target: { name, value } }) => {
    setSelectedNewPlan(value === selectedNewPlan ? null : value);
  };

  const totalPrice = () => {
    if (selectedNewPlan) {
      return invoice?.planValues[selectedNewPlan]?.price;
    } else {
      return hasItemBillet ? invoice?.total - hasItemBillet?.price : invoice?.total;
    }
  };

  return (
    <>
      <InvoicePaymentWithSpinner
        isLoading={loading}
        enterprise={enterprise}
        invoice={invoice}
        handleClick={handleClick}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        feedback={feedback}
        handleChangePlan={handleChangePlan}
        scriptLoaded={scriptLoaded}
        formatDate={formatDate}
        hasItemBillet={hasItemBillet}
        ableRenovation={ableRenovation}
        showPixForm={showPixForm}
        setShowPixForm={setShowPixForm}
        handleClipboard={handleClipboard}
        copied={copied}
        showBilletForm={showBilletForm}
        setShowBilletForm={setShowBilletForm}
        handleDownload={handleDownload}
        isDownloading={isDownloading}
        isMobile={isMobile}
        selectedNewPlan={selectedNewPlan}
        setSelectedNewPlan={setSelectedNewPlan}
        planInvoice={planInvoice}
      />
      <ModalCreditCard
        confirmLabel={confirmPaymentCaption}
        onConfirmModal={handlePayment}
        onCloseModal={() => setShowModalCard(false)}
        showModal={showModalCard && scriptLoaded}
        width="43.5rem"
        enterprise={enterprise}
        resetForm={resetCreditCardData}
        paymentMethod={paymentMethod}
        maxInstallments={maxInstallments}
        totalPrice={totalPrice}
        selectedNewPlan={selectedNewPlan}
        installments={
          selectedNewPlan ? invoice?.planValues?.[selectedNewPlan]?.installments : installments
        }
      />
      <Modal
        title="Falha na validação do cartão"
        hideConfirm
        cancelLabel="Fechar"
        width="43.5rem"
        onCloseModal={() => setShowMessageModal(false)}
        showModal={showMessageModal}
      >
        {errorMessage ? (
          <Span>{errorMessage}</Span>
        ) : (
          <Span>
            Não foi possável validar o seu cartão de crédito. <br />
            Por favor, verifique os dados digitados ou tente novamente com um novo cartão de
            crédito.
          </Span>
        )}
      </Modal>
    </>
  );
};

export default InvoicePaymentContainer;
