export default {
  newLead: "Temos uma nova empresa: <bold>{{name}}</bold>",
  newEmitter: "Temos um novo Emissor: <bold>{{name}}</bold>",
  sendCertificate: "<bold>{{name}}</bold> enviou os dados de seu certificado digital",
  newInvoice: "<bold>{{name}}</bold> emitiu uma nova NFe",
  newMessage: "<bold>{{name}}</bold> enviou uma mensagem",
  uploadByClient: "<bold>{{name}}</bold> enviou um novo documento",
  paymentStatusChange: "Alteração no pagamento de <bold>{{name}}</bold>",
  certificateStatus: "Alteração no status do certificado de <bold>{{name}}</bold>",
  uploadByAccountant: "<bold>{{name}}</bold> enviou um novo documento",
  updateCertificate: "<bold>{{name}}</bold> efetuou uma alteração no certificado",
  statusCouponsActive: "Este Cupom esta ativo.",
};
