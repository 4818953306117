import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import { Table, TableHeader, TableBody, TableItem, RenderHeaders } from "components/core/Table";
import Pagination from "components/core/Pagination";
import { REQUEST_PENDING, REQUEST_NOT_STARTED } from "utils/constants/request";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";

const List = ({
  reports,
  requestStatus,
  params,
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();

  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "enterprise_name", title: translate("emitter") },
    { slug: "cnpj", title: translate("cnpj") },
    { slug: "enterprise_type", title: translate("Emitter Type") },
    { slug: "guia", title: `${translate("Guia")}` },
    { slug: "guida_date", title: `${translate("Data")}` },
    { slug: "guia_downloadDate", title: `${translate("Download")}` },
    { slug: "balancete", title: `${translate("Balancete")}` },
    { slug: "balancete_date", title: `${translate("Data")}` },
    { slug: "balancete_downloadDate", title: `${translate("Download")}` },
  ];

  const requestPending = (status) => {
    return status === REQUEST_PENDING || status === REQUEST_NOT_STARTED;
  };
  // {guiaDate ? formatDate(guiaDate) : '-'}
  return requestPending(requestStatus) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {reports.map(
            ({
              id,
              enterpriseName,
              cnpj,
              enterpriseType,
              guia,
              guiaDate,
              guiaDownloaddate,
              guiaUrl,
              balancete,
              balanceteDate,
              balanceteUrl,
              balanceteDownloaddate,
            }) => (
              <tr key={id}>
                <TableItem>
                  <Link data-cy="id">{id}</Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="enterpriseName">{enterpriseName}</Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="cnpj">{cnpj}</Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="enterpriseType">{enterpriseType}</Link>
                </TableItem>
                <TableItem>
                  <a href={guiaUrl || ""}>{guia}</a>
                </TableItem>
                <TableItem>
                  <Link data-cy="guiaDate">{guiaDate || "-"}</Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="guiaDownloaddate">{guiaDownloaddate || "-"}</Link>
                </TableItem>
                <TableItem>
                  <a href={balanceteUrl || ""}>{balancete}</a>
                </TableItem>
                <TableItem>
                  <Link data-cy="balanceteDate">{balanceteDate || "-"}</Link>
                </TableItem>
                <TableItem>
                  <Link data-cy="balanceteDownloaddate">{balanceteDownloaddate || "-"}</Link>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  reports: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
};

List.defaultProps = {
  reports: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
