import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  RenderHeaders,
  TableCheckbox,
  TableHeaderItem,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import { MASK_TYPES, format } from "utils/masks";
import { REQUEST_PENDING, REQUEST_NOT_STARTED } from "utils/constants/request";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { formatDate } from "helpers";
import { Span } from "components/core/Typography";
import { Row, Flex, Columns } from "components/core/Grid";
import Modal from "components/core/Modal";
import EmptyFeedback from "components/core/EmptyFeedback";
import CardInfo from "components/core/CardInfo";
import ChargeHistory from "./../../Payment/ChargeHistory";
import InvoiceItems from "./../List/Items";

const List = ({
  handleChange,
  setSelectedItems,
  invoices,
  requestStatus,
  params: { chargeDateBegin, chargeDateEnd, setOrderBy, setFilterParams, setPage },
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
  handleDetails,
  invoiceItems,
  showModalDetails,
  setShowModalDetails,
  selected,
  setSelected,
  invoiceCharges,
  invoiceChargesLoading,
  invoiceItemsLoading,
}) => {
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "enterpriseName", title: translate("emitter") },
    { slug: "enterpriseTypeName", title: translate("Emitter Type") },
    { slug: "planName", title: `${translate("Plan Type")}` },
    { slug: "status", title: `${translate("Status")}` },
    { slug: "total", title: `${translate("Value")}` },
    { slug: "billingPeriod", title: `Competência` },
    { slug: "dueDate", title: `Vencimento` },
  ];
  const requestPending = (status) => {
    return status === REQUEST_PENDING || status === REQUEST_NOT_STARTED;
  };
  const handleSetPage = (page) => {
    setFilterParams({
      chargeDateBegin,
      chargeDateEnd,
    });
    setPage(page);
  };

  const closeModalDetails = () => {
    setSelected(null);
    setShowModalDetails(false);
  };

  return requestPending(requestStatus) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <Table customStyle={{ marginTop: "1.75rem" }}>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={() => {}} checked={false} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {invoices.map(
            ({
              enterpriseName,
              userName,
              id,
              total,
              planName,
              dueDate,
              billingPeriod,
              enterpriseTypeName,
              checked,
              status,
              installments,
            }) => (
              <tr key={id}>
                <TableItem>
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => {
                      setSelectedItems([]);
                      handleChange(id);
                    }}
                    checked={checked}
                  />
                </TableItem>
                <TableItem handleClick={() => handleDetails(id)}>{id}</TableItem>
                <TableItem handleClick={() => handleDetails(id)}>
                  {enterpriseName || userName}
                </TableItem>
                <TableItem handleClick={() => handleDetails(id)}>{enterpriseTypeName}</TableItem>
                <TableItem handleClick={() => handleDetails(id)}>{planName}</TableItem>
                <TableItem handleClick={() => handleDetails(id)}>{translate(status)}</TableItem>
                <TableItem handleClick={() => handleDetails(id)}>
                  <Row padding={0} marginBottom={0}>
                    {format(Number(total).toFixed(2), MASK_TYPES.realWithName)}
                  </Row>
                  <Row padding={0} marginBottom={0}>
                    ({installments?.installmentNumber}
                    {" x "}
                    {format(
                      Number(installments?.installmentValue).toFixed(2),
                      MASK_TYPES.realWithName,
                    )}
                    {installments?.monthlyInterest > 0 && installments?.installmentNumber > 1 && (
                      <Span>{installments?.monthlyInterest}% a.a</Span>
                    )}
                    )
                  </Row>
                </TableItem>
                <TableItem handleClick={() => handleDetails(id)}>
                  {formatDate(billingPeriod, false, false, false, null, "MM/YYYY")}
                </TableItem>
                <TableItem handleClick={() => handleDetails(id)}>
                  {formatDate(dueDate, false, false)}
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={handleSetPage}
      />
      <Modal
        title={`Detalhes da Fatura ${selected?.id}`}
        onCloseModal={closeModalDetails}
        showModal={showModalDetails}
        textAlign="text-left"
        showActions={true}
        hideConfirm={true}
        hideCancel={false}
        cancelLabel="Fechar"
        width="90rem"
      >
        <Flex verticalAlign="center">
          {selected ? (
            <>
              <CardInfo
                marginLeft={0}
                marginRigth={0}
                label={translate("emitterName")}
                info={selected?.enterpriseName || selected?.userName}
              />
              <Columns columns="3">
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Due Date")}
                  info={formatDate(selected?.dueDate)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Value")}
                  info={format(Number(selected?.total).toFixed(2), MASK_TYPES.realWithName)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Status")}
                  info={translate(selected?.status)}
                />
              </Columns>
              <InvoiceItems
                items={invoiceItems}
                translate={translate}
                isLoading={invoiceItemsLoading}
              />
              <ChargeHistory
                items={invoiceCharges}
                translate={translate}
                isLoading={invoiceChargesLoading}
              />
            </>
          ) : (
            <EmptyFeedback />
          )}
        </Flex>
      </Modal>
    </>
  );
};

List.propTypes = {
  subscriptions: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  subscriptions: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
