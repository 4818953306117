const masks = {
  char(value) {
    return value.replace(/\d/g, "");
  },
  number(value) {
    return value.replace(/\D/g, "");
  },
  numberWithComma(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{1,2})(\d{1,2})/, "$1,$2")
      .replace(/(\d{2})\d+?$/, "$1");
  },
  numberWithDot(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{1,2})(\d)/, "$1.$2")
      .replace(/(\d{2})\d+?$/, "$1");
  },
  cep(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");
  },
  cpf(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  },
  cnpj(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  },
  date(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\/\d{4})\d+?$/, "$1");
  },
  dateWithLine(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{6})(\d)/, "$1-$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  },
  dateMonth(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\/\d{4})\d+?$/, "$1");
  },
  phone(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
      .replace(/(-\d{4})\d+?$/, "$1");
  },
  phoneNoDDD(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
      .replace(/(-\d{4})\d+?$/, "$1");
  },
  real(value) {
    return value
      .toString()
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})/, "$1,$2")
      .replace(/(\d),(\d)(\d{2})/, "$1$2,$3")
      .replace(/(\d{2}),(\d)(\d{2})/, "$1$2,$3")
      .replace(/(\d)(\d{2}),(\d)(\d{2})/, "$1.$2$3,$4")
      .replace(/(\d).(\d)(\d{2}),(\d)(\d{2})/, "$1$2.$3$4,$5")
      .replace(/(\d{2}).(\d)(\d{2}),(\d)(\d{2})/, "$1$2.$3$4,$5")
      .replace(/(\d)(\d{2}).(\d)(\d{2}),(\d)(\d{2})/, "$1.$2$3.$4$5,$6")
      .replace(/(\d{2}.\d{3}.\d{3},\d{2})\d+?$/, "$1");
  },
  negativeReal(value) {
    const newValue = masks.real(value);
    return `-${newValue}`;
  },
  realWithName(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d)/, "R$ $1")
      .replace(/(\d)(\d{2})/, "$1,$2")
      .replace(/(\d),(\d)(\d{2})/, "$1$2,$3")
      .replace(/(\d{2}),(\d)(\d{2})/, "$1$2,$3")
      .replace(/(\d)(\d{2}),(\d)(\d{2})/, "$1.$2$3,$4")
      .replace(/(\d).(\d)(\d{2}),(\d)(\d{2})/, "$1$2.$3$4,$5")
      .replace(/(\d{2}).(\d)(\d{2}),(\d)(\d{2})/, "$1$2.$3$4,$5")
      .replace(/(\d)(\d{2}).(\d)(\d{2}),(\d)(\d{2})/, "$1.$2$3.$4$5,$6")
      .replace(/(\d{2}.\d{3}.\d{3},\d{2})\d+?$/, "$1");
  },
  negativeRealWithName(value) {
    const newValue = masks.real(value);
    return `R$ -${newValue}`;
  },
  percentage(value) {
    let newValue = value;
    if (typeof value === "number") {
      newValue = value.toFixed(2).toString().replace(/\D/g, "");
    } else {
      newValue = value.toString().replace(/\D/g, "").slice(0, 4);
    }
    return newValue
      .replace(/(\d)/, "$1")
      .replace(/(\d)(\d{1})/, "$1,$2")
      .replace(/(\d),(\d)(\d{2})/, "$1$2,$3");
  },
  negativePercentage(value) {
    const newValue = masks.percentage(value);
    return `-${newValue}`;
  },
  percentageWithSymbol(value) {
    const newValue = masks.percentage(value);
    return `${newValue}%`;
  },
  negativePercentageWithSymbol(value) {
    const newValue = masks.percentageWithSymbol(value);
    return `-${newValue}`;
  },
};

const getMask = (key) => masks[key] || ((value) => value);

const handleInputMask = (event, mask) => {
  const { target } = event;

  if (mask) {
    target.value = getMask(mask)(target.value);
  }
};

export const format = (value, mask) => {
  if (mask) {
    return getMask(mask)(value);
  }
  return value;
};

export const MASK_TYPES = {
  char: "char",
  number: "number",
  cep: "cep",
  cpf: "cpf",
  cnpj: "cnpj",
  date: "date",
  dateWithLine: "dateWithLine",
  dateMonth: "dateMonth",
  phone: "phone",
  phoneNoDDD: "phoneNoDDD",
  real: "real",
  negativeReal: "negativeReal",
  realWithName: "realWithName",
  negativeRealWithName: "negativeRealWithName",
  numberWithComma: "numberWithComma",
  numberWithDot: "numberWithDot",
  percentage: "percentage",
  negativePercentage: "negativePercentage",
  percentageWithSymbol: "percentageWithSymbol",
  negativePercentageWithSymbol: "negativePercentageWithSymbol",
};

export const removeMask = (maskedText) => maskedText.replace(/(\.|-|(|)|\/)/g, "");

export const realToNumber = (maskedText) =>
  Number(maskedText.replace(/\./g, "").replace(/\,/g, "."));

export const realWithNameToNumber = (maskedText) =>
  realToNumber(format(maskedText, MASK_TYPES.real));

export default handleInputMask;
