import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { EmitterActions } from "store/ducks/emitter";
import { LayoutActions } from "store/ducks/layout";
import EmitterList from "components/presentation/Emitter/List";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import { useItems, useParams, useRequest } from "hooks";
import { ReactComponent as IconPin } from "assets/icons/icon-pin.svg";
import { ReactComponent as IconExport } from "assets/icons/icon-download.svg";

const EditStatus = React.lazy(() => import("containers/Emitter/Edit/Status"));
const Filter = React.lazy(() => import("containers/Emitter/Filter"));

const EmitterListWithSpinner = WithSpinner(EmitterList);
const EditStatusWithSuspense = WithSuspense(EditStatus);
const FilterWithSuspense = WithSuspense(Filter);

const EmittersListContainer = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const {
    statusOfRequests,
    emitters: { items, total, totalPages },
    exportEmitters,
  } = useSelector(({ emitter }) => emitter);
  const {
    everyoneIsChecked,
    selectedItems,
    setSelectedItems,
    handleChange,
    toggleAll,
    listChecked,
    hasOneItemSelected,
    hasSelectedItems,
    removeItemById,
  } = useItems(items);

  const fetchEmitters = (params) => dispatch(EmitterActions.fetchEmitters(params));

  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    dispatchWithParams,
    handleFilter,
    SmartFilterButton,
  } = useParams({ callback: fetchEmitters, defaultOrderBy: "createdAt", defaultOrder: "desc" });

  const handleChangePaginate = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage],
  );

  const pinEmitter = (emitter) => dispatch(LayoutActions.pinEmitter(emitter));

  const handleExportEmitters = () => {
    dispatch(EmitterActions.exportEmitters({ enterprises: selectedItems.map(({ id }) => id) }));
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{translate(exportEmitters.message)}</Span>,
        type: "success",
      }),
    );
    dispatch(EmitterActions.resetExportEmitters());
  };

  useRequest({
    request: statusOfRequests.EXPORT_EMITTERS,
    onResolved,
  });

  return (
    <>
      <NavBar title={translate("emitters")}>
        {(hasOneItemSelected || hasSelectedItems) && (
          <>
            <Button variant="outline" handleClick={handleExportEmitters}>
              <IconExport />
              <span>{translate("export")}</span>
            </Button>
            <Button variant="outline" handleClick={() => setShowEditStatus(true)}>
              {translate("changeStatus")}
            </Button>
          </>
        )}
        {hasOneItemSelected && (
          <>
            <Button handleClick={() => pinEmitter(selectedItems[0])}>
              <IconPin />
              <span>{translate("pinEmitter")}</span>
            </Button>
          </>
        )}
        {!hasOneItemSelected && !hasSelectedItems && (
          <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
        )}
      </NavBar>
      <EmitterListWithSpinner
        requestStatus={statusOfRequests.fetch}
        emitters={listChecked ?? items}
        selectedEmitters={selectedItems}
        handleChange={handleChange}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        toggleAll={toggleAll}
        everyoneIsChecked={everyoneIsChecked}
        handleChangePaginate={handleChangePaginate}
        view
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
      <EditStatusWithSuspense
        loadComponent={showEditStatus}
        openModal={showEditStatus}
        setOpenModal={setShowEditStatus}
        activeItems={selectedItems.filter((item) => item.status.id !== 8)}
        setActiveItems={setSelectedItems}
        removeItemById={removeItemById}
        fetchEmitters={dispatchWithParams}
      />
    </>
  );
};

export default EmittersListContainer;
