import React from "react";
import PropTypes from "prop-types";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import { formatDate } from "helpers";
import { Divider } from "components/core/Typography";
import { Container, Columns } from "components/core/Grid";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import StatusDot from "components/core/StatusDot";
import { INVOICE_COLORS_BATCH } from "utils/constants";

const Details = ({ batche }) => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Columns columns="2">
        <CardInfo
          label={translate("emitterName")}
          info={batche?.enterprise?.enterpriseName || "Não informado"}
        />
        <CardInfo label={translate("cnpj")} info={batche?.enterprise?.cnpj || "Não informado"} />
      </Columns>
      <Columns columns="2">
        <CardInfo
          label={translate("recipientName")}
          info={batche?.client?.clientName || "Não informado"}
        />
        <CardInfo
          label={translate("cpfCnpj")}
          info={batche?.client?.cpf || batche?.client?.cnpj || "Não informado"}
        />
      </Columns>
      <Divider />
      <Columns columns="2">
        <CardInfo
          label={translate("invoiceNumber")}
          info={batche?.invoiceNumber || "Não informado"}
        />
        <CardInfo
          label={translate("status")}
          info={
            <>
              {batche?.status && (
                <>
                  <StatusDot type={INVOICE_COLORS_BATCH[batche?.status]} />
                  {batche?.status}
                </>
              )}
            </>
          }
        />
      </Columns>
      <Divider />
      <Columns columns="2">
        <CardInfo label={translate("locality")} info={batche?.ibptCity || "Não informado"} />
        <CardInfo
          label={translate("competencyDate")}
          info={
            (batche?.dateCompetence && formatDate(batche?.dateCompetence, false, false)) ||
            "Não informado"
          }
        />
      </Columns>
      <CardInfo
        label={translate("dateIssue")}
        info={(batche?.dateIssue && formatDate(batche?.dateIssue, false, false)) || "Não informado"}
      />
      <Divider />
      <CardInfo
        label={translate("justification")}
        info={(!!batche?.reasonStatus && batche?.reasonStatus) || "-"}
      />
      <Divider />
      <CardInfo label={translate("update")} info={batche?.observation || "Não informado"} />
    </Container>
  );
};

export default WithEmptyFeedback(Details);

Details.propTypes = {
  batche: PropTypes.object,
};

Details.defaultProps = {
  batche: {},
};
