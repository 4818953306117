import core from "./core";
import errors from "./errors";
import screens from "./screens";
import notifications from "./notifications";
import messages from "./messages";
import months from "./months";
import useTerms from "./useTerms";

export default {
  ...core,
  ...errors,
  ...screens,
  ...notifications,
  ...messages,
  ...months,
  ...useTerms,
};
