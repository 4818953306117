import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CardInfo from "components/core/CardInfo";
import { Divider } from "components/core/Typography";
import { Container, Columns } from "components/core/Grid";
import StatusDot from "components/core/StatusDot";
import { COUPONS_COLORS_DICTIONARY } from "utils/constants";
import { formatDate } from "helpers";

const CouponsDetails = ({
  coupons: {
    code,
    discount,
    amount,
    amountUsed,
    startDate,
    endDate,
    location,
    status: { name: statusName },
    couponSubscriptionPlan,
    couponEnterpriseType,
    description,
  },
}) => {
  const { t: translate } = useTranslation();
  const itensComma = (key, i) => {
    const string = i > 0 ? `, ${key.name}` : key.name;
    return string;
  };
  const subscriptionPlans = couponSubscriptionPlan.map(({ subscriptionPlan }, i) => {
    return itensComma(subscriptionPlan, i);
  });
  const enterpriseTypes = couponEnterpriseType.map(({ enterpriseType }, i) => {
    return itensComma(enterpriseType, i);
  });
  const locations = location.map(({ city, state }, i) => {
    const locale =
      city == null ? itensComma(state, i) : `${itensComma(city, i)} - ${city.state.uf}`;
    return locale;
  });
  const programming =
    amount && endDate
      ? translate("both")
      : amount && !endDate
      ? translate("numberOfUses")
      : !amount && endDate
      ? translate("byPeriod")
      : translate("notFound");

  return (
    <Container>
      <Columns columns="3">
        <CardInfo label={translate("couponsCode")} info={code} />
        <CardInfo label={translate("discount")} info={`${discount}%`} />
      </Columns>
      <CardInfo
        label={translate("status")}
        info={
          <>
            <StatusDot type={COUPONS_COLORS_DICTIONARY[statusName]} />
            {statusName}
          </>
        }
      />
      <Divider />
      <Columns columns="2">
        <CardInfo label={translate("plansContemplated")} info={subscriptionPlans} />
        <CardInfo label={translate("enterpriseTypes")} info={enterpriseTypes} />
      </Columns>
      <CardInfo label={translate("locality")} info={locations} />
      <Divider />
      <CardInfo label={translate("programming")} info={programming} />
      <Columns columns="2">
        <CardInfo label={translate("startDate")} info={formatDate(startDate)} />
        {endDate && <CardInfo label={translate("endDate")} info={formatDate(endDate)} />}
      </Columns>
      <Columns columns="2">
        {amount !== 0 && <CardInfo label={translate("numberOfUses")} info={amount} />}
        {amountUsed !== 0 && <CardInfo label={translate("couponsUsed")} info={amountUsed} />}
      </Columns>
      {description && <CardInfo label={translate("description")} info={description} />}
    </Container>
  );
};

CouponsDetails.propTypes = {
  coupons: PropTypes.object.isRequired,
};

export default CouponsDetails;
