import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import InputForm from "components/core/InputForm";
import NavBar from "components/core/NavBar";
import { Subtitle } from "components/core/Typography";
import { Container } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import { LINKS } from "utils/constants";

const NcmCategoryRegister = ({
  handleClick,
  handleNcmCategoryRegister,
  defaultValues,
  validationSchema,
  submitRef,
}) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues: { ...defaultValues },
    mode: "onBlur",
    submitFocusError: true,
  });

  return (
    <>
      <NavBar title={translate("newNcmCategory")}>
        <Button url={`${LINKS.ncmGlobalsList}`} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={handleClick}>{translate("save")}</Button>
      </NavBar>
      <Container maxWidth="md">
        <Subtitle>{translate("addInformations")}</Subtitle>
        <Form onSubmit={handleSubmit(handleNcmCategoryRegister)}>
          <InputForm
            register={register}
            type="text"
            name="code"
            label={translate("ncmCode")}
            errorMsg={errors.code?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="name"
            label={translate("description")}
            errorMsg={errors.name?.message}
          />
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
      </Container>
    </>
  );
};

NcmCategoryRegister.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleNcmCategoryRegister: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  submitRef: PropTypes.object.isRequired,
};

export default NcmCategoryRegister;
