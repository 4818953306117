import styled from "styled-components";
import { MenuItem } from "components/core/SideBar/styled";
import { Header } from "components/core/EmitterSidebar/styled";

export const CustomMenu = styled(MenuItem)`
  color: var(--color-black-2);
  padding: 0 2rem;
`;

export const ItemsWrapper = styled.div`
  padding: 1.2rem;
  overflow-x: auto;
`;

export const SearchHeader = styled(Header)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2.6rem 1.2rem 1.2rem;
  margin: 0;

  &::after {
    background-color: var(--color-secondary);
    opacity: 1;
  }
`;

export const InputSearch = styled.input`
  border: none;
  color: var(--color-black-5);
  font-size: var(--font-size-default);
  font-family: var(--font-family-default);
  outline: none;
  padding: 1.2rem;
  transition: all 0.2 linear;
  width: 100%;

  ::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }
`;

export const Card = styled.div`
  box-shadow: 0.2rem 0.2rem 1rem rgba(57, 57, 57, 0.1);
  transition: box-shadow 0.2s;
  width: 100%;

  &:hover {
    box-shadow: 0.2rem 0.2rem 1rem rgba(57, 57, 57, 0.4);
  }

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
`;

export const CardActions = styled.div`
  padding: 1.6rem;
  display: flex;
  justify-content: center;
`;
