import styled from "styled-components";
import media from "styled-media-query";
import { ReactComponent as grafism } from "assets/svg/grafism.svg";
import homem from "assets/images/homem.png";

export const LandingContainer = styled.div.attrs(({ large }) => ({
  widthHuge: large ? "106.6rem" : "48rem",
  widthLarge: large ? "72.0rem" : "48rem",
  maxWidth: large ? "unset" : "48rem",
}))`
  width: ${({ widthHuge }) => widthHuge};
  max-width: ${({ maxWidth }) => maxWidth};
  padding: var(--space-xlg) var(--space-lg) var(--space-lg) var(--space-lg);
  margin: var(--space-xlg) auto;
  border-radius: var(--space-xs);
  ${media.lessThan("large")`
    width: ${({ widthLarge }) => widthLarge};
    max-width: ${({ maxWidth }) => maxWidth};
    padding: var(--space);
  `}
  ${media.lessThan("medium")`
    width: auto;
    max-width: 48rem;
  `}
  ${media.lessThan("small")`
    margin: 0;
    border: none;
  `}
`;

export const Grafismo = styled(grafism)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 80%;
`;

export const Men = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(${homem});
  width: 50%;
  height: 75%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
