import React from "react";
import PropTypes from "prop-types";

import * as S from "./styled";

const CardInfo = ({
  label,
  info,
  infoContainer,
  lg,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  children,
}) => (
  <S.InfoContainer
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
    marginTop={marginTop}
  >
    <S.Label data-testid="label">{label}</S.Label>
    {((info && !infoContainer) || (!info && !infoContainer)) && (
      <S.Info lg={lg} data-testid="info">
        {info}
      </S.Info>
    )}
    {!info && infoContainer && (
      <S.InfoChildContainer lg={lg} data-testid="infoContainer">
        {infoContainer}
      </S.InfoChildContainer>
    )}
    <S.Content data-testid="content">{children}</S.Content>
  </S.InfoContainer>
);

CardInfo.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  infoContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  lg: PropTypes.bool,
  children: PropTypes.node,
};

CardInfo.defaultProps = {
  info: null,
  infoContainer: null,
  lg: false,
  children: null,
};

export default CardInfo;
