import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: CitiesTypes, Creators: CitiesActions } = createActions({
  getCities: ["params"],
  getCitiesSuccess: ["payload"],
  uploadCities: ["formData"],
  uploadCitiesSuccess: null,
  citiesRequestFailure: ["error", "originalType"],
  cleanState: null,
});

const INITIAL_STATE = {
  citiesList: {},
  CitiesRequestsStatus: {
    [CitiesTypes.GET_CITIES]: REQUEST_NOT_STARTED,
    [CitiesTypes.UPLOAD_CITIES]: REQUEST_NOT_STARTED,
  },
};

const getCities = (state) => ({
  ...state,
  CitiesRequestsStatus: {
    ...state.CitiesRequestsStatus,
    [CitiesTypes.GET_CITIES]: REQUEST_PENDING,
  },
});

const getCitiesSuccess = (state, { payload }) => ({
  ...state,
  citiesList: payload,
  CitiesRequestsStatus: {
    ...state.CitiesRequestsStatus,
    [CitiesTypes.GET_CITIES]: REQUEST_RESOLVED,
  },
});

const uploadCities = (state) => ({
  ...state,
  CitiesRequestsStatus: {
    ...state.CitiesRequestsStatus,
    [CitiesTypes.UPLOAD_CITIES]: REQUEST_PENDING,
  },
});

const uploadCitiesSuccess = (state) => ({
  ...state,
  CitiesRequestsStatus: {
    ...state.CitiesRequestsStatus,
    [CitiesTypes.UPLOAD_CITIES]: REQUEST_RESOLVED,
  },
});

const citiesRequestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  CitiesRequestsStatus: {
    ...state.CitiesRequestsStatus,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [CitiesTypes.GET_CITIES]: getCities,
  [CitiesTypes.GET_CITIES_SUCCESS]: getCitiesSuccess,
  [CitiesTypes.UPLOAD_CITIES]: uploadCities,
  [CitiesTypes.UPLOAD_CITIES_SUCCESS]: uploadCitiesSuccess,
  [CitiesTypes.CITIES_REQUEST_FAILURE]: citiesRequestFailure,
  [CitiesTypes.CLEAN_STATE]: cleanState,
});
