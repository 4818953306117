import React, { lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams as useRouterParams } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import { CstActions } from "store/ducks/cst";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import CstTableList from "components/presentation/Cst/List";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import { useItems, useParams, useRequest } from "hooks";
import { formatDate } from "helpers";

const SMUSelect = lazy(() => import("containers/Cst/Register"));
const DeleteCstConfirm = lazy(() => import("components/presentation/Documents/Delete"));

const CstTableListWithSpinner = WithSpinner(CstTableList);

const SMUSelectWithSuspense = WithSuspense(SMUSelect);
const DeleteCstConfirmWithSuspense = WithSuspense(DeleteCstConfirm);

const CstList = () => {
  const dispatch = useDispatch();
  const { emitterId } = useRouterParams();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const {
    emitterCst: { items, total, totalPages },
    statusOfRequests,
  } = useSelector(({ emitter }) => emitter);
  const { CstRequests } = useSelector(({ cst }) => cst);

  const {
    handleChange,
    toggleAll,
    listChecked: itemsChecked,
    selectedItems,
    hasSelectedItems,
  } = useItems(items);

  const fetchCst = (params) => dispatch(EmitterActions.fetchEmitterCst(emitterId, params));

  const {
    params,
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
  } = useParams({
    callback: fetchCst,
    // eslint-disable-next-line
    defaultOrderBy: "createdAt",
    defaultOrder: "desc",
  });

  const selectItems = () => selectedItems?.map(({ id }) => id);

  const dispatchDelete = () => {
    dispatch(CstActions.deleteCst({ ids: selectItems() }));
    setPage(1);
    setShowDeleteConfirm(false);
  };

  useRequest({
    request: CstRequests.DELETE_CST,
    onResolved: () => fetchCst(params),
  });

  const newItemsChecked = [];

  itemsChecked &&
    itemsChecked.map((item) => {
      newItemsChecked.push({ ...item, createdAt: formatDate(item.createdAt) });
    });

  return (
    <>
      <NavBar title={translate("csosn")}>
        <Button
          variant="outline"
          color="danger"
          handleClick={() => setShowDeleteConfirm(true)}
          disabled={!hasSelectedItems}
        >
          {translate("delete")}
        </Button>
        <Button handleClick={() => setShowModal(true)}>
          <span>{translate("addCsosn")}</span>
        </Button>
      </NavBar>
      <CstTableListWithSpinner
        requestStatus={statusOfRequests.FETCH_EMITTER_CST}
        handleChange={handleChange}
        items={newItemsChecked}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        toggleAll={toggleAll}
        checkedAll={selectedItems?.length === itemsChecked?.length}
      />
      <SMUSelectWithSuspense
        loadComponent={showModal}
        showModal={showModal}
        setShowModal={setShowModal}
        setPageList={setPage}
      />
      <DeleteCstConfirmWithSuspense
        loadComponent={showDeleteConfirm}
        showConfirm={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={dispatchDelete}
        titleTranslateKey="deleteCsosn"
        messageTranslateKey="deleteCsosnConfirm"
      />
    </>
  );
};

export default CstList;
