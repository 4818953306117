import styled from "styled-components";

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;

  svg {
    margin: 0 2rem 8.8rem;
  }
`;
