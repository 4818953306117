import React from "react";
import styled from "styled-components";
import { Button } from "components/core/Button/styled";
import { Flex } from "components/core/Grid";
import { Paragraph } from "components/core/Typography";
import { ReactComponent as IconTimes } from "assets/icons/icon-times.svg";
import { SpinnerOverlay, Spinner } from "components/core/WithSpinner/styled";

export const NotificationsWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const DeleteNotification = styled.button.attrs({
  type: "button",
  children: <IconTimes />,
})`
  border-radius: 50%;
  background-color: var(--color-gray-5);
  color: var(--color-white-1);
  cursor: pointer;
  line-height: 2.3;
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 2.5rem;
  right: 2.5rem;
  opacity: 0;
  transition: all 0.3s linear;

  & > svg {
    height: 1rem;
    width: 1rem;
  }
`;

export const NotificationContent = styled(Flex)`
  span {
    font-size: 1.2rem;
  }

  ${Button} {
    opacity: 0;
    margin: 0.8rem 0;
    transition: opacity 0.1s linear;
  }

  &:hover {
    ${Button} {
      opacity: 1;
    }
  }
`;

export const Notifications = styled.div`
  border-bottom: 1px solid var(--color-white-3);
  display: flex;
  padding: 1.4rem 4.6rem;
  width: 100%;
  position: relative;

  &:hover {
    ${/* sc-sel */ DeleteNotification} {
      opacity: 1;
    }
  }
`;

export const Notification = styled(Paragraph)`
  cursor: pointer;
  margin-bottom: var(--space-xs);
`;

export const Status = styled.span`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 4.6rem;
  margin-top: 0.5rem;
`;

export const LoadingMore = styled(SpinnerOverlay).attrs(({ isLoading }) => ({
  children: isLoading && <Spinner />,
}))`
  display: none;

  &:not(:empty) {
    display: flex;
    height: 20rem;
  }
`;
