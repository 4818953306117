import * as Yup from "yup";
import { formatNumberToReal } from "helpers/format";

const parseBool = (val) =>
  [true, "true"].includes(val) ? true : [false, "false"].includes(val) ? false : null;
const parseNumber = (val) => (!Number.isNaN(Number(val)) ? Number(val) : null);

const subscriptionSchema = Yup.object().shape({
  newEnterprise: Yup.bool(),
  idEnterpriseType: Yup.string(),
  numberEmployees: Yup.string(),
  idBillingRange: Yup.string(),
  billing: Yup.string(),
  idSubscriptionPlan: Yup.number(),
  idPayroll: Yup.number(),
  idBilletCost: Yup.number(),
  idAnnuityDiscount: Yup.number(),
  idOpeningTax: Yup.number(),
  idPaymentMethod: Yup.number(),
  idCalculationTaxMei: Yup.number(),
  price: Yup.number(),
  inheritSettings: Yup.bool().transform(parseBool).nullable(),
  allowInstallments: Yup.bool().transform(parseBool).nullable(),
  allowedInstallmentsPlanMonthly: Yup.bool().transform(parseBool).nullable(),
  allowedInstallmentsPlanAnnual: Yup.bool().transform(parseBool).nullable(),
  maxInstallmentsNumber: Yup.number().transform(parseNumber).nullable(),
  interestType: Yup.number().transform(parseNumber).nullable(),
  interestValue: Yup.string().nullable(),
  discountType: Yup.string().nullable(),
  discountValue: Yup.string().nullable(),
  discountDescription: Yup.string().nullable().max(50, "O campo deve ter no máximo 50 caracteres"),
  paymentPlanType: Yup.string().nullable().optional(),
});

export const validateMaxBilling = (billing, maxBilling, callback) => {
  Yup.number()
    .lessThan(
      maxBilling + 0.01,
      `O valor máximo para faturamento do MEI é de ${formatNumberToReal(maxBilling)}`,
    )
    .validate(billing, { abortEarly: false })
    .then(() => callback([]))
    .catch(({ errors }) => callback(errors));
};

export { subscriptionSchema };
