import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import CardInfo from "components/core/CardInfo";
import { Container } from "components/core/Grid";
import SelectForm from "components/core/SelectForm";
import { Divider, Subtitle } from "components/core/Typography";
import MEITaxes from "containers/Taxes/Details/MEI";
import { formatNumberToReal, mapToSelectOptions } from "helpers";
import { taxesEnum, urls } from "utils/constants";
import OtherTypesTaxes from "containers/Taxes/Details/OtherTypesTaxes";
import WithSpinner from "components/core/WithSpinner";

const OtherTypesTaxesWithSpinner = WithSpinner(OtherTypesTaxes);

const TaxesDetails = ({
  showTaxes,
  billings,
  hasTaxes,
  getTax,
  handleChangeEnterprise,
  isMEI,
  requestBillingRanges,
  types,
  handleClick,
  idEnterpriseType,
}) => {
  const { t: translate } = useTranslation();
  const [isSimple, setIsSimple] = useState(false);

  useEffect(() => {
    setIsSimple(Number(idEnterpriseType) === taxesEnum.simpleId);
  }, [idEnterpriseType]);
  return (
    <>
      <Container>
        <Subtitle>
          {translate("generalTaxes")}
          <Button variant="outline" url={urls.LINKS.generalTax}>
            {translate("editTaxes")}
          </Button>
        </Subtitle>
        {hasTaxes && (
          <>
            <CardInfo
              label={translate("additionalBilletPrice")}
              info={formatNumberToReal(getTax(taxesEnum.additionalBilletPrice).price)}
            />
            <CardInfo
              label={translate("payrollPrice")}
              info={formatNumberToReal(getTax(taxesEnum.payrollPrice).price)}
            />
            <CardInfo
              label={translate("anualDiscount")}
              info={`${((1 - getTax(taxesEnum.anualDiscount).value) * 100).toFixed(2)}%`}
            />
          </>
        )}
        <Divider />
      </Container>
      <Container verticalPadding="0">
        <Subtitle>
          {translate("plan")}
          {showTaxes && (
            <Button variant="outline" handleClick={handleClick}>
              {translate("editPlans")}
            </Button>
          )}
        </Subtitle>
      </Container>
      <Container maxWidth="md" verticalPadding="2.4rem">
        <SelectForm
          name="idEnterpriseType"
          label={translate("enterpriseType")}
          handleChange={handleChangeEnterprise}
          options={[
            {
              value: "",
              text: translate("select"),
              disabled: true,
              selected: true,
            },
            ...mapToSelectOptions(types, "id", "name"),
          ]}
        />
        {showTaxes &&
          (isMEI ? (
            <MEITaxes
              openingTax={getTax(taxesEnum.meiOpeningTax).price}
              maximumBilling={getTax(taxesEnum.meiMaximumMonthBilling).price}
              minimumMonthly={getTax(taxesEnum.meiMinimumCalcPrice).price}
              taxCalc={getTax(taxesEnum.meiTaxCalc).value}
              annualPrice={getTax(taxesEnum.meiMinimumCalcPrice).annualPrice}
              biennialPrice={getTax(taxesEnum.meiMinimumCalcPrice).biennialPrice}
              allowInstallments={getTax(taxesEnum.meiOpeningTax).allowInstallments}
              minInstallmentAmount={getTax(taxesEnum.meiOpeningTax).minInstallmentAmount}
              maxInstallments={getTax(taxesEnum.meiOpeningTax).maxInstallments}
              applyInterest={getTax(taxesEnum.meiOpeningTax).applyInterest}
            />
          ) : (
            <OtherTypesTaxesWithSpinner
              requestStatus={requestBillingRanges}
              openingTax={
                getTax(isSimple ? taxesEnum.openingTaxSimple : taxesEnum.openingTaxPresumed).price
              }
              billings={billings}
              allowInstallments={
                getTax(isSimple ? taxesEnum.openingTaxSimple : taxesEnum.openingTaxPresumed)
                  .allowInstallments
              }
              minInstallmentAmount={
                getTax(isSimple ? taxesEnum.openingTaxSimple : taxesEnum.openingTaxPresumed)
                  .minInstallmentAmount
              }
              maxInstallments={
                getTax(isSimple ? taxesEnum.openingTaxSimple : taxesEnum.openingTaxPresumed)
                  .maxInstallments
              }
            />
          ))}
      </Container>
    </>
  );
};

TaxesDetails.propTypes = {
  showTaxes: PropTypes.bool.isRequired,
  billings: PropTypes.array,
  hasTaxes: PropTypes.bool.isRequired,
  getTax: PropTypes.func.isRequired,
  handleChangeEnterprise: PropTypes.func.isRequired,
  isMEI: PropTypes.bool.isRequired,
  requestBillingRanges: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  idEnterpriseType: PropTypes.number.isRequired,
};

TaxesDetails.defaultProps = {
  billings: [],
};

export default TaxesDetails;
