import styled from "styled-components";
import media from "styled-media-query";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => (marginBottom !== undefined ? marginBottom : "2.4rem")};
  margin-left: ${({ marginLeft }) => (marginLeft !== undefined ? marginLeft : "2.4rem")};
  margin-right: ${({ marginRight }) => (marginRight !== undefined ? marginRight : "2.4rem")};
  margin-top: ${({ marginTop }) => (marginTop !== undefined ? marginTop : "0")};
  ${media.lessThan("medium")`
    margin-left: 0;
  `}
`;

export const Label = styled.span`
  color: var(--color-gray-1);
  font-size: var(--font-size-default);
  font-weight: 600;
  margin-bottom: 0.4rem;
`;

export const Info = styled.p`
  color: var(--color-black-3);
  font-size: ${({ lg }) => (lg ? "2.6rem" : "2rem")};
  font-weight: ${({ lg }) => (lg ? "600" : "normal")};
  padding-left: 0.8rem;

  ${media.lessThan("medium")`
    padding-left: 0;
  `}

  &:empty {
    background: var(--color-black-2);
    display: block;
    margin: 1rem 0;
    width: 0.8rem;
    height: 0.2rem;
    margin-left: 0.8rem;
  }
`;

export const InfoChildContainer = styled.div`
  color: var(--color-black-3);
  font-size: ${({ lg }) => (lg ? "2.6rem" : "2rem")};
  font-weight: ${({ lg }) => (lg ? "600" : "normal")};
  padding-left: 0.8rem;

  ${media.lessThan("medium")`
    padding-left: 0;
  `}

  &:empty {
    background: var(--color-black-2);
    display: block;
    margin: 1rem 0;
    width: 0.8rem;
    height: 0.2rem;
    margin-left: 0.8rem;
  }
`;

export const Content = styled.div`
  padding-left: 0.8rem;
`;
