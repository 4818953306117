import styled, { css } from "styled-components";
import { Title, Span } from "components/core/Typography";
import { Button } from "components/core/Button/styled";

const activeModal = css`
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s linear, visibility 0s 0s;
`;

export const ModalWrapper = styled.div.attrs(({ zIndex }) => ({
  zIndex: zIndex ?? "999",
}))`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ zIndex }) => zIndex};
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s linear, visibility 0s 0.2s;
  ${({ active }) => active && activeModal}
`;

export const Modal = styled.div.attrs(({ width, minHeight, padding, verticalAlign }) => ({
  justifyContent: verticalAlign ?? "space-between",
  maxWidth: width ?? "70rem",
  minHeight: minHeight ?? "26rem",
  padding: padding ?? "4rem 4rem",
}))`
  background-color: var(--color-white-1);
  border-radius: 0.6rem;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 80%;
  height: auto;
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  position: relative;
`;

export const ModalTitle = styled(Title)`
  color: var(--color-gray-4);
  text-align: center;
  margin-bottom: 3.2rem;
`;

export const ModalContent = styled.div.attrs(({ textAlign }) => ({
  textAlign: textAlign ?? "center",
}))`
  height: 100%;
  width: 100%;
  text-align: ${({ textAlign }) => textAlign};

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  & > ${/* sc-sel */ Span}:not(:last-child) {
    display: inline-block;
    margin-bottom: var(--space-sm);
  }
`;

export const ModalPreviewContent = styled.div.attrs((props) => ({
  textAlign: props.textAlign ?? "center",
  height: props.height ?? "auto",
}))`
  height: ${({ height }) => height};
  width: 100%;
  text-align: ${({ textAlign }) => textAlign};

  /* &:not(:last-child) {
    margin-bottom: 2.4rem;
  } */

  & > ${/* sc-sel */ Span}:not(:last-child) {
    display: inline-block;
    margin-bottom: var(--space-sm);
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${/* sc-sel */ Button}:not(:last-child) {
    margin-right: 5rem;
  }
`;
