import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_REJECTED, REQUEST_RESOLVED, REQUEST_PENDING } from "utils/constants";
import { CouponsActions } from "store/ducks/coupons";
import { Subtitle, Small, Span, TertiaryTitle } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import { useForm } from "react-hook-form";
// import RadioButton from "components/core/RadioButton";
import InputForm from "components/core/InputForm";
import Button from "components/core/Button";
// import { Table, TableBody, TableItem } from "components/core/Table";
import { Trans } from "react-i18next";
import * as S from "./styled";

export const Payment = ({
  onBack,
  onContinue,
  enterpriseType,
  idSubscriptionPlan,
  city,
  state,
  monthly,
  billetCostTax,
  subscriptionPlans,
}) => {
  const dispatch = useDispatch();
  const [isCreditCard, setIsCreditCard] = useState(true);
  const [couponStatus, setCouponStatus] = useState(2);
  const [newMonthly, setNewMonthly] = useState("");
  const [verifyCupon, setVerifyCupon] = useState("");
  const {
    coupon: { discount, code, id },
    CouponsRequests,
  } = useSelector(({ coupons }) => coupons);

  const { register, handleSubmit, setValue } = useForm({
    mode: "onBlur",
    submitFocusError: true,
  });

  useEffect(() => {
    setValue("idPaymentMethod", 2);
  }, [setValue]);

  const handleCoupom = ({ target: { value } }) => {
    if (value.length > 0) {
      setVerifyCupon(value);
    } else if (value.length === 0) {
      setCouponStatus(3);
    }
  };

  const handleSubmitCupon = () => {
    if (verifyCupon.length > 0) {
      dispatch(
        CouponsActions.getCoupon({
          code: verifyCupon,
          state,
          city,
          idEnterpriseType: enterpriseType,
          idSubscriptionPlan: Number(idSubscriptionPlan),
        }),
      );
    }
  };

  const handleRemoveCupon = () => {
    setCouponStatus(3);
    dispatch(CouponsActions.removeCoupon());
    setValue("codeCoupon", null);
  };

  const errorCoupon = (value) => {
    if (value === 1) return <Trans i18nKey="invalid.coupon" />;
    return null;
  };

  useEffect(() => {
    if (CouponsRequests.getCoupon === REQUEST_PENDING) {
      setCouponStatus(0);
    } else if (CouponsRequests.getCoupon === REQUEST_REJECTED) {
      setCouponStatus(1);
    } else if (CouponsRequests.getCoupon === REQUEST_RESOLVED) {
      setCouponStatus(2);
    }
  }, [CouponsRequests.getCoupon, setCouponStatus]);

  useEffect(() => {
    if ((couponStatus === 2 && id) || discount) {
      setValue("idCoupon", id);
      setValue("couponDiscount", discount);
    } else {
      setValue("idCoupon", null);
      setValue("couponDiscount", "0");
    }
  }, [id, discount, couponStatus, setValue]);

  useEffect(() => {
    const ticket = discount !== "100" ? billetCostTax : 0;
    if (isCreditCard === false) {
      setNewMonthly(
        (
          parseFloat(Number(idSubscriptionPlan) === 1 ? monthly : subscriptionPlans[1]?.total) +
          ticket
        ).toFixed(2),
      );
    } else {
      setNewMonthly(
        parseFloat(
          Number(idSubscriptionPlan) === 1 ? monthly : subscriptionPlans[1]?.total,
        ).toFixed(2),
      );
    }
  }, [
    monthly,
    isCreditCard,
    setNewMonthly,
    discount,
    billetCostTax,
    idSubscriptionPlan,
    subscriptionPlans,
  ]);

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="94%" />
        </Container>
        <Subtitle mb="64px">Possui cupom de desconto?</Subtitle>
        {/* <Subtitle mb="64px">Forma de pagamento</Subtitle> */}
        <Form onSubmit={handleSubmit(onContinue)}>
          <InputHidden ref={register} name="idPaymentMethod" />
          {/* <Span>O seu plano só será ativado após a confirmação do pagamento.</Span>
          <Flex>
            <Table>
              <TableBody>
                <tr>
                  <TableItem textAlign="center">
                    <RadioButton
                      register={register}
                      name="idPaymentMethod"
                      label="Boleto Bancário"
                      id="bankSlip"
                      value="1"
                      onClick={() => setIsCreditCard(false)}
                      checked={!isCreditCard}
                      onChange={() => setIsCreditCard(false)}
                    />
                  </TableItem>
                </tr>
              </TableBody>
            </Table>
            <Table>
              <TableBody>
                <tr>
                  <TableItem textAlign="center">
                    <RadioButton
                      register={register}
                      name="idPaymentMethod"
                      label="Cartão de Crédito"
                      id="creditCard"
                      value="2"
                      onClick={() => setIsCreditCard(true)}
                      checked={isCreditCard}
                      onChange={() => setIsCreditCard(true)}
                    />
                  </TableItem>
                </tr>
              </TableBody>
            </Table>
          </Flex>
          <Span mb="6rem">
            <strong>Possui cupom de desconto?</strong>
          </Span> */}
          <Container verticalPadding="2rem" horizontalPadding="1rem">
            <Flex horizontalAlign="center" marginBottom="3rem">
              <InputForm
                register={register}
                name="codeCoupon"
                type="text"
                label="Insira seu código de cupom:"
                borderRadius="50px"
                border="1px solid"
                onBlur={handleCoupom}
                errorMsg={errorCoupon(couponStatus)}
                isLoading={couponStatus === 0}
              />
              <Button width="100%" onClick={handleSubmitCupon}>
                Resgatar
              </Button>
            </Flex>
          </Container>
          <InputHidden ref={register} name="idCoupon" />
          <InputHidden ref={register} name="couponDiscount" />
          {discount && couponStatus === 2 && (
            <Flex>
              <Span mb="2rem">Cupom</Span>
              <Flex>
                <TertiaryTitle marginBottom="1px">
                  {code}{" "}
                  <S.ButtonRemoveCupon onClick={handleRemoveCupon}>remover</S.ButtonRemoveCupon>
                </TertiaryTitle>
              </Flex>
            </Flex>
          )}
          <Flex>
            <Span mb="2rem">Valores</Span>

            <Flex>
              <TertiaryTitle marginBottom="1px">{`${
                Number(idSubscriptionPlan) === 1 ? "Mensalidade" : "Anual"
              } R$${String(
                Number(idSubscriptionPlan) === 1
                  ? monthly
                    ? Number(monthly).toFixed(2)
                    : (0).toFixed(2)
                  : subscriptionPlans[1]?.total.toFixed(2),
              ).replace(".", ",")}`}</TertiaryTitle>
              {discount && <Small>(Sem desconto)</Small>}
            </Flex>

            {discount && couponStatus === 2 ? (
              <>
                {couponStatus !== 3 && (
                  <Flex>
                    <TertiaryTitle marginBottom="1px">{`Cupom de desconto -${discount}%`}</TertiaryTitle>
                    <Small>({`${code}%`})</Small>
                  </Flex>
                )}
                {!isCreditCard ? (
                  <>
                    {discount !== "100" && (
                      <Flex>
                        <TertiaryTitle marginBottom="1px">
                          Acréscimo boleto = R$
                          {String(parseFloat(billetCostTax).toFixed(2)).replace(".", ",")}
                        </TertiaryTitle>
                      </Flex>
                    )}
                    <Subtitle>{`${
                      Number(idSubscriptionPlan) === 1 ? "Mensalidade" : "Anual"
                    } R$${String(
                      (
                        newMonthly -
                        (Number(idSubscriptionPlan) === 1 ? monthly : subscriptionPlans[1]?.total) *
                          (discount / 100)
                      ).toFixed(2),
                    ).replace(".", ",")}`}</Subtitle>
                  </>
                ) : (
                  <>
                    <Subtitle>{`${
                      Number(idSubscriptionPlan) === 1 ? "Mensalidade" : "Anual"
                    } R$${String(
                      (
                        newMonthly -
                        (Number(idSubscriptionPlan) === 1 ? monthly : subscriptionPlans[1]?.total) *
                          (discount / 100)
                      ).toFixed(2),
                    ).replace(".", ",")}`}</Subtitle>
                  </>
                )}
              </>
            ) : (
              <>
                {!isCreditCard && (
                  <>
                    {discount !== "100" && (
                      <Flex>
                        <TertiaryTitle marginBottom="1px">
                          Acréscimo boleto = R$
                          {String(parseFloat(billetCostTax).toFixed(2)).replace(".", ",")}
                        </TertiaryTitle>
                      </Flex>
                    )}
                    <Subtitle>{`${
                      Number(idSubscriptionPlan) === 1 ? "Mensalidade" : "Anual"
                    } R$${String(newMonthly).replace(".", ",")}`}</Subtitle>
                  </>
                )}
              </>
            )}
            <Small>
              {!isCreditCard && discount !== "100" && (
                <>
                  * Acrécimo de R${String(parseFloat(billetCostTax).toFixed(2)).replace(".", ",")}{" "}
                  por {`${Number(idSubscriptionPlan) === 1 ? "mensalidade" : "Anuidade"}`}.
                  <br />
                  ** Seu boleto será enviado no e-mail cadastrado. (
                  {`${Number(idSubscriptionPlan) === 1 ? "Mensalmente" : "Anualmente"}`})
                  <br />
                </>
              )}
              {discount !== "100" && (
                <>
                  *** O cancelamento da assinatura poderá ser feita através dos canais de
                  atendimento do App Obvia.
                </>
              )}
            </Small>
          </Flex>

          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
            <Button type="submit">Continuar</Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

Payment.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  enterpriseType: PropTypes.number || PropTypes.string,
  idSubscriptionPlan: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  monthly: PropTypes.string,
  billetCostTax: PropTypes.number.isRequired,
  subscriptionPlans: PropTypes.array.isRequired,
};

Payment.defaultProps = {
  enterpriseType: 0,
  idSubscriptionPlan: "",
  city: "",
  state: "",
  monthly: "",
};

export default Payment;
