import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: ParamsTypes, Creators: ParamsActions } = createActions({
  fetchCertificates: ["payload"],
  fetchCertificatesSuccess: ["payload"],
  getCertificates: ["payload"],
  getCertificatesSuccess: ["payload"],
  registerCertificate: ["payload"],
  registerCertificateSuccess: ["payload"],
  deleteCertificate: ["payload"],
  deleteCertificateSuccess: ["payload"],
  updateDefaultCertificate: ["payload"],
  updateDefaultCertificateSuccess: ["payload"],
  cleanState: null,
  requestFailure: ["error", "originalType"],
});

const INITIAL_STATE = {
  payload: null,
  error: null,
  certificates: {
    items: [],
  },
  certificatesOpt: [],
  ParamsRequests: {
    [ParamsTypes.FETCH_CERTIFICATES]: REQUEST_NOT_STARTED,
    [ParamsTypes.GET_CERTIFICATES]: REQUEST_NOT_STARTED,
    [ParamsTypes.REGISTER_CERTIFICATE]: REQUEST_NOT_STARTED,
    [ParamsTypes.DELETE_CERTIFICATE]: REQUEST_NOT_STARTED,
    [ParamsTypes.UPDATE_DEFAULT_CERTIFICATE]: REQUEST_NOT_STARTED,
  },
};

const fetchCertificates = (state) => ({
  ...state,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.FETCH_CERTIFICATES]: REQUEST_PENDING,
  },
});

const fetchCertificatesSuccess = (state, { payload }) => ({
  ...state,
  certificates: payload,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.FETCH_CERTIFICATES]: REQUEST_RESOLVED,
  },
});

const getCertificates = (state) => ({
  ...state,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.GET_CERTIFICATES]: REQUEST_PENDING,
  },
});

const getCertificatesSuccess = (state, { payload }) => ({
  ...state,
  certificatesOpt: payload,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.GET_CERTIFICATES]: REQUEST_RESOLVED,
  },
});

const registerCertificate = (state) => ({
  ...state,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.REGISTER_CERTIFICATE]: REQUEST_PENDING,
  },
});

const registerCertificateSuccess = (state, { payload }) => ({
  ...state,
  certificates: payload,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.REGISTER_CERTIFICATE]: REQUEST_RESOLVED,
  },
});

const deleteCertificate = (state) => ({
  ...state,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.DELETE_CERTIFICATE]: REQUEST_PENDING,
  },
});

const deleteCertificateSuccess = (state) => ({
  ...state,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.DELETE_CERTIFICATE]: REQUEST_RESOLVED,
  },
});

const updateDefaultCertificate = (state) => ({
  ...state,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.UPDATE_DEFAULT_CERTIFICATE]: REQUEST_PENDING,
  },
});

const updateDefaultCertificateSuccess = (state) => ({
  ...state,
  ParamsRequests: {
    ...state.ParamsRequests,
    [ParamsTypes.UPDATE_DEFAULT_CERTIFICATE]: REQUEST_RESOLVED,
  },
});

const requestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  ParamsRequests: {
    ...state.ParamsRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [ParamsTypes.FETCH_CERTIFICATES]: fetchCertificates,
  [ParamsTypes.FETCH_CERTIFICATES_SUCCESS]: fetchCertificatesSuccess,
  [ParamsTypes.GET_CERTIFICATES]: getCertificates,
  [ParamsTypes.GET_CERTIFICATES_SUCCESS]: getCertificatesSuccess,
  [ParamsTypes.REGISTER_CERTIFICATE]: registerCertificate,
  [ParamsTypes.REGISTER_CERTIFICATE_SUCCESS]: registerCertificateSuccess,
  [ParamsTypes.DELETE_CERTIFICATE]: deleteCertificate,
  [ParamsTypes.DELETE_CERTIFICATE_SUCCESS]: deleteCertificateSuccess,
  [ParamsTypes.UPDATE_DEFAULT_CERTIFICATE]: updateDefaultCertificate,
  [ParamsTypes.UPDATE_DEFAULT_CERTIFICATE_SUCCESS]: updateDefaultCertificateSuccess,
  [ParamsTypes.CLEAN_STATE]: cleanState,
  [ParamsTypes.REQUEST_FAILURE]: requestFailure,
});
