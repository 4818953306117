import styled, { css } from "styled-components";
import { MenuItem } from "components/core/SideBar/styled";

const sidebarOpenStyle = css`
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s;
`;

export const SidebarContainer = styled.div`
  position: fixed;
  height: 100%;
  min-height: 100vh;
  width: calc(100% - var(--sidebar-width));
  top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;

  left: calc(var(--sidebar-width) - 2px);
  ${({ isOpen }) => isOpen && sidebarOpenStyle}
`;

export const Sidebar = styled.div`
  background: var(--color-white-1);
  border-right: 1px solid var(--color-white-5);
  height: 100%;
  width: var(--sidebar-width);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 3.2rem 0;
  position: relative;
  margin: 0 1.6rem 1.6rem;

  &::after {
    background-color: #1d2328;
    content: "";
    display: block;
    opacity: 0.3;
    bottom: 1.6rem;
    left: 3.5%;
    position: absolute;
    height: 1px;
    margin: 0 auto;
    width: 93%;
  }
`;

export const CustomMenu = styled(MenuItem)`
  color: var(--color-black-2);
  padding: 0 2rem;
`;

export const ItemsWrapper = styled.div`
  overflow-y: auto;

  & svg {
    color: var(--color-secondary);
  }
`;
