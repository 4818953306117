import React, { useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { BatchesActions } from "store/ducks/batches";
import { EmitterActions } from "store/ducks/emitter";
import EditEnterprise from "components/presentation/Batches/Confirm/EditEnterprise";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import Button from "components/core/Button";
import { LINKS } from "utils/constants";
import { batchesSchema } from "utils/schemas/batches";

const EditEnterpriseWithSpinner = WithSpinner(EditEnterprise);

const BatchesRegisterContainer = () => {
  const { id } = useParams();
  const submitRef = useRef(null);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { statusOfRequests, emitterDetails } = useSelector(({ emitter }) => emitter);
  const { selectEnterprises } = useSelector(({ batches }) => batches);
  const { items } = selectEnterprises;
  const enterpriseToEdit = items?.find((item) => item.id === Number(id));

  useEffect(() => {
    if (enterpriseToEdit === undefined) {
      history.push(LINKS.batches);
    }
  }, [enterpriseToEdit]);

  useEffect(() => {
    if (emitterDetails?.id !== Number(id)) {
      dispatch(EmitterActions.fetchEmitterClientById(id));
    }
  }, [dispatch, emitterDetails?.id, id]);

  const handleConfirm = ({ id, enterpriseName, cnpj, plan, justification, price }) => {
    const editItem = {
      id: Number(id),
      enterpriseName,
      cnpj,
      plan,
      justification,
      price,
    };
    const itemSelectedIndex = items.findIndex((selectedItem) => selectedItem.id === Number(id));
    const deletedItem =
      itemSelectedIndex === -1
        ? [...items]
        : items.filter((_, index) => itemSelectedIndex !== index);

    const newItem = [editItem, ...deletedItem];
    const newSelectEnterprises = { ...selectEnterprises, items: newItem };
    dispatch(BatchesActions.confirmBatches(newSelectEnterprises));
    history.push(LINKS.batchesConfirm);
  };

  return (
    <>
      <NavBar title={translate("editDetailsEmitter")}>
        <Button url={`${LINKS.batchesConfirm}`} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("edit")}</Button>
      </NavBar>
      <EditEnterpriseWithSpinner
        requestStatus={statusOfRequests.details}
        emitterDetails={emitterDetails}
        defaultValues={enterpriseToEdit}
        description={selectEnterprises?.description}
        validationSchema={batchesSchema}
        handleEditEnterprise={handleConfirm}
        submitRef={submitRef}
      />
    </>
  );
};

export default BatchesRegisterContainer;
