import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: DocumentsTypes, Creators: DocumentsActions } = createActions({
  fetchDocuments: ["params"],
  fetchDocumentsSuccess: ["payload"],
  fetchDocumentById: ["id"],
  fetchDocumentByIdSuccess: ["document"],
  fetchDocumentsTypes: null,
  fetchDocumentsTypesSuccess: ["payload"],
  uploadDocument: ["formData", "params"],
  uploadDocumentSuccess: null,
  deleteDocuments: ["documents"],
  deleteDocumentsSuccess: ["payload"],
  exportDocuments: ["payload"],
  exportDocumentsSuccess: ["payload"],
  documentsRequestFailure: ["error", "originalType"],
  cleanExportDocument: [],
  cleanUploadDocument: [],
  cleanState: [],
});

const INITIAL_STATE = {
  document: {},
  documents: {},
  documentsTypes: [],
  DocumentsRequests: {
    [DocumentsTypes.FETCH_DOCUMENTS]: REQUEST_NOT_STARTED,
    [DocumentsTypes.FETCH_DOCUMENT_BY_ID]: REQUEST_NOT_STARTED,
    [DocumentsTypes.FETCH_DOCUMENTS_TYPES]: REQUEST_NOT_STARTED,
    [DocumentsTypes.UPLOAD_DOCUMENT]: REQUEST_NOT_STARTED,
    [DocumentsTypes.EXPORT_DOCUMENTS]: REQUEST_NOT_STARTED,
    [DocumentsTypes.DELETE_DOCUMENTS]: REQUEST_NOT_STARTED,
  },
};

const fetchDocuments = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.FETCH_DOCUMENTS]: REQUEST_PENDING,
  },
});

const fetchDocumentsSuccess = (state, { payload }) => ({
  ...state,
  documents: payload,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.FETCH_DOCUMENTS]: REQUEST_RESOLVED,
  },
});

const fetchDocumentById = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.FETCH_DOCUMENT_BY_ID]: REQUEST_PENDING,
  },
});

const fetchDocumentByIdSuccess = (state, { document }) => ({
  ...state,
  document,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.FETCH_DOCUMENT_BY_ID]: REQUEST_RESOLVED,
  },
});

const fetchDocumentsTypes = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.FETCH_DOCUMENTS_TYPES]: REQUEST_PENDING,
  },
});

const fetchDocumentsTypesSuccess = (state, { payload }) => ({
  ...state,
  documentsTypes: payload,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.FETCH_DOCUMENTS_TYPES]: REQUEST_RESOLVED,
  },
});

const uploadDocument = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.UPLOAD_DOCUMENT]: REQUEST_PENDING,
  },
});

const uploadDocumentSuccess = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.UPLOAD_DOCUMENT]: REQUEST_RESOLVED,
  },
});

const deleteDocuments = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.DELETE_DOCUMENTS]: REQUEST_PENDING,
  },
});

const deleteDocumentsSuccess = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.DELETE_DOCUMENTS]: REQUEST_RESOLVED,
  },
});

const exportDocuments = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.EXPORT_DOCUMENTS]: REQUEST_PENDING,
  },
});

const exportDocumentsSuccess = (state, { payload }) => ({
  ...state,
  exportDocuments: payload,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.EXPORT_DOCUMENTS]: REQUEST_RESOLVED,
  },
});

const documentsRequestFailure = (state, { error: { response }, originalType }) => ({
  ...state,
  error: response,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanExportDocument = (state) => ({
  ...state,
  exportDocuments: null,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.EXPORT_DOCUMENTS]: REQUEST_NOT_STARTED,
  },
});

const cleanUploadDocument = (state) => ({
  ...state,
  DocumentsRequests: {
    ...state.DocumentsRequests,
    [DocumentsTypes.UPLOAD_DOCUMENT]: REQUEST_NOT_STARTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [DocumentsTypes.FETCH_DOCUMENTS]: fetchDocuments,
  [DocumentsTypes.FETCH_DOCUMENTS_SUCCESS]: fetchDocumentsSuccess,
  [DocumentsTypes.FETCH_DOCUMENT_BY_ID]: fetchDocumentById,
  [DocumentsTypes.FETCH_DOCUMENT_BY_ID_SUCCESS]: fetchDocumentByIdSuccess,
  [DocumentsTypes.FETCH_DOCUMENTS_TYPES]: fetchDocumentsTypes,
  [DocumentsTypes.FETCH_DOCUMENTS_TYPES_SUCCESS]: fetchDocumentsTypesSuccess,
  [DocumentsTypes.UPLOAD_DOCUMENT]: uploadDocument,
  [DocumentsTypes.UPLOAD_DOCUMENT_SUCCESS]: uploadDocumentSuccess,
  [DocumentsTypes.DELETE_DOCUMENTS]: deleteDocuments,
  [DocumentsTypes.DELETE_DOCUMENTS_SUCCESS]: deleteDocumentsSuccess,
  [DocumentsTypes.EXPORT_DOCUMENTS]: exportDocuments,
  [DocumentsTypes.EXPORT_DOCUMENTS_SUCCESS]: exportDocumentsSuccess,
  [DocumentsTypes.DOCUMENTS_REQUEST_FAILURE]: documentsRequestFailure,
  [DocumentsTypes.CLEAN_EXPORT_DOCUMENT]: cleanExportDocument,
  [DocumentsTypes.CLEAN_UPLOAD_DOCUMENT]: cleanUploadDocument,
  [DocumentsTypes.CLEAN_STATE]: cleanState,
});
