import React, { useEffect } from "react";
import { EnterpriseActions } from "store/ducks/enterprise";
import { LargeTitle, TertiaryTitle, Title } from "components/core/Typography";
import { Flex, Columns } from "components/core/Grid";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { useHistory } from "react-router-dom";
import { useDimensions } from "hooks";
import { useDispatch } from "react-redux";
import * as S from "./styled";

const UseTerms = () => {
  const { t: translate } = useTranslation();
  const plainText = translate("useTerms");
  const { width } = useDimensions();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(EnterpriseActions.createEnterpriseInitial());
  }, [dispatch]);

  const handleClick = () => {
    history.push("/cadastro");
    history.go(0);
  };

  return (
    <S.LandingContainer large>
      <Columns columns={width > 1024 ? "2" : "1"}>
        <Flex>
          {width > 1024 ? (
            <LargeTitle color="var(--color-white-1)" marginBottom="var(--space-lg)">
              Sua contabilidade na palma da mão
            </LargeTitle>
          ) : (
            <Title customStyle={{ color: "#FFF", marginTop: "100px", marginBottom: "20px" }}>
              Sua contabilidade na palma da mão
            </Title>
          )}
          <TertiaryTitle color="var(--color-white-1)" marginBottom="var(--space-lg)">
            Faça já seu cadastro e entre para a equipe vai revolucionar sua empresa
          </TertiaryTitle>
          <Button
            handleClick={handleClick}
            customStyle={{ padding: "0 3rem" }}
            variant="context-white"
          >
            Quero me cadastrar
          </Button>
        </Flex>
      </Columns>
      {width > 1024 && (
        <>
          <S.Grafismo />
          <S.Men />
        </>
      )}
    </S.LandingContainer>
  );
};

export default UseTerms;
