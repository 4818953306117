import { BRAND_NUMBERS } from "./constants/creditCard";

export const getOnlyNumbers = (value) => value.replace(/\D/g, "");

export const getBrandByNumber = (number) => {
  if (!number || number.length <= 0) return;
  const onlyNumbers = getOnlyNumbers(number);
  const firstTwoDigits = onlyNumbers.slice(0, 2);
  const brand = BRAND_NUMBERS[firstTwoDigits];
  if (!brand) {
    return BRAND_NUMBERS[firstTwoDigits.slice(0, 1)];
  }
  return brand;
};

export const formatCardNumber = (number, brand) => {
  if (!brand) return number;
  const onlyNumbers = getOnlyNumbers(number);
  const formatted = onlyNumbers.replace(brand.maskRegex, brand.valueRegex);
  return formatted.slice(0, brand.maskedLength);
};

export const formatExpiryDate = (number) => {
  const onlyNumbers = getOnlyNumbers(number);
  const formatted = onlyNumbers.replace(/(.{2})/g, "$1/");
  const sliced = formatted.slice(0, 5);
  return sliced;
};
