import { put, takeLatest } from "redux-saga/effects";
import { TaxesTypes, TaxesActions } from "store/ducks/taxes";
import {
  TAXES,
  GENERAL_TAXES,
  MEI_TAXES,
  SIMPLE_OR_PRESUMED,
  GET_MEI_TAXES,
} from "utils/constants/endpoints";
import { GET, POST } from "utils/constants/verbs";
import api from "services/api";

function* fetchTaxes() {
  try {
    const { data } = yield api({
      method: GET,
      url: TAXES,
    });
    yield put(TaxesActions.fetchTaxesSuccess(data));
  } catch (error) {
    yield put(TaxesActions.taxesRequestFailure(error, "fetchTaxes"));
  }
}

function* editGeneralTaxes({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: GENERAL_TAXES,
      data: payload,
    });
    yield put(TaxesActions.editGeneralTaxesSuccess(data));
  } catch (error) {
    yield put(TaxesActions.taxesRequestFailure(error, "editGeneralTaxes"));
  }
}

function* editMEITaxes({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: MEI_TAXES,
      data: payload,
    });
    yield put(TaxesActions.editMEITaxesSuccess(data));
  } catch (error) {
    yield put(TaxesActions.taxesRequestFailure(error, "editMEITaxes"));
  }
}

function* getMEITaxes() {
  try {
    const { data } = yield api({
      method: GET,
      url: GET_MEI_TAXES,
    });
    yield put(TaxesActions.getMEITaxesSuccess(data));
  } catch (error) {
    yield put(TaxesActions.taxesRequestFailure(error, "getMEITaxes"));
  }
}

function* editOtherTypeTaxes({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: SIMPLE_OR_PRESUMED,
      data: payload,
    });
    yield put(TaxesActions.editOtherTypeTaxesSuccess(data));
  } catch (error) {
    yield put(TaxesActions.taxesRequestFailure(error, "editOtherTypeTaxes"));
  }
}

export function* watchSagas() {
  yield takeLatest(TaxesTypes.FETCH_TAXES, fetchTaxes);
  yield takeLatest(TaxesTypes.EDIT_GENERAL_TAXES, editGeneralTaxes);
  yield takeLatest(TaxesTypes.EDIT_MEI_TAXES, editMEITaxes);
  yield takeLatest(TaxesTypes.GET_MEI_TAXES, getMEITaxes);
  yield takeLatest(TaxesTypes.EDIT_OTHER_TYPE_TAXES, editOtherTypeTaxes);
}
