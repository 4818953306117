import React from "react";
import PropTypes from "prop-types";
import { CheckboxWrapper, Label } from "components/core/Checkbox/styled";
import * as S from "./styled";

const RadioButton = ({
  id,
  name,
  label,
  handleChange,
  register,
  smallLabel,
  marginBottom,
  ...otherProps
}) => (
  <CheckboxWrapper marginBottom={marginBottom}>
    <S.Input
      ref={register}
      type="radio"
      id={id}
      name={name}
      onChange={handleChange}
      marginBottom={marginBottom}
      {...otherProps}
    />
    <Label htmlFor={id} smallLabel={smallLabel}>
      <S.RadioButton />
      {label && <span>{label}</span>}
    </Label>
  </CheckboxWrapper>
);

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  register: PropTypes.func,
  smallLabel: PropTypes.bool,
  styleHeight: PropTypes.string,
};

RadioButton.defaultProps = {
  label: null,
  handleChange: null,
  register: null,
  smallLabel: false,
  styleHeight: null,
};

export default RadioButton;
