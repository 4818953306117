import React from "react";
import PropTypes from "prop-types";
import Feedback from "components/core/Feedback";

const Authorization = ({ feedback }) => {
  return (
    <>
      {feedback && (
        <Feedback
          title={feedback?.title}
          description={feedback?.description}
          isSuccess={feedback?.isSuccess}
          btnText={feedback?.btnText}
          emphasis={feedback?.emphasis}
          paymentFlag={feedback?.paymentFlag}
        />
      )}
    </>
  );
};

Authorization.propTypes = {
  feedback: PropTypes.object.isRequired,
};

export default Authorization;
