import { useEffect, useState, memo } from "react";
import { useFormContext } from "react-hook-form";
import { EnterpriseActions } from "store/ducks/enterprise";
import { useDispatch } from "react-redux";

const FormEntepriseTypeObserver = ({ types, callback, idEnterpriseType }) => {
  const [lastTypeID, setLastTypeID] = useState(0);
  const { setValue } = useFormContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (idEnterpriseType && idEnterpriseType !== lastTypeID) {
      const selectedType = types.find(({ id }) => id === Number(idEnterpriseType));
      const typeSelectedIsMEI = selectedType?.name.toUpperCase() === "MEI";

      if (typeSelectedIsMEI) {
        setLastTypeID(Number(idEnterpriseType));
        setValue("numberEmployees", 1);
      } else {
        dispatch(EnterpriseActions.getBillingRanges(Number(idEnterpriseType)));
      }

      callback(typeSelectedIsMEI);
    }
  }, [idEnterpriseType, types, lastTypeID, callback, setValue, dispatch]);

  return null;
};

export default memo(FormEntepriseTypeObserver);
