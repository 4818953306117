import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableCheckbox,
  TableBody,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot from "components/core/StatusDot";
import { urls, COUPONS_COLORS_DICTIONARY } from "utils/constants";
import { formatDate } from "helpers";

const List = ({
  coupons,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  toggleAll,
  checkedAll,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();
  const {
    LINKS: { couponsDetails },
  } = urls;
  const headers = [
    { slug: "code", title: translate("code") },
    { slug: "discount", title: translate("discount") },
    { slug: "status", title: translate("status") },
    { slug: "startDate", title: translate("startDate") },
    { slug: "endDate", title: translate("endDate") },
    { slug: "amountUsed", title: translate("amountUsed") },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {coupons.map(
            ({
              id,
              code,
              discount,
              status: { name: statusName },
              startDate,
              endDate,
              amount,
              amountUsed,
              checked,
            }) => (
              <tr key={id}>
                <TableItem textAlign="center">
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => handleChange(id)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem>
                  <Link to={couponsDetails(id)}>{code}</Link>
                </TableItem>
                <TableItem>
                  <Link to={couponsDetails(id)}>{`${discount}%`}</Link>
                </TableItem>
                <TableItem>
                  <Link to={couponsDetails(id)}>
                    <StatusDot type={COUPONS_COLORS_DICTIONARY[statusName]} />
                    {statusName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={couponsDetails(id)}>{formatDate(startDate, false, false)}</Link>
                </TableItem>
                <TableItem>
                  <Link to={couponsDetails(id)}>
                    {endDate ? formatDate(endDate, false, false) : " - "}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={couponsDetails(id)}>
                    {amountUsed}
                    {amount ? `/${amount}` : ""}
                  </Link>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  coupons: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  coupons: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
