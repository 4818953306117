import styled from "styled-components";

export const Label = styled.label.attrs(({ marginBottom, color }) => ({
  marginBottom: marginBottom ?? "0.4rem",
  color: color ?? "var(--color-black-2)",
}))`
  color: ${({ hasError, color }) => (hasError ? "var(--color-danger)" : color)};
  display: block;
  font-size: 1.2rem;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-right: 0.4rem;
  text-transform: uppercase;
  transition: all 0.3s linear;
`;
