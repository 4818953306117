import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Form, InputHidden } from "components/core/Form";
import { useForm } from "react-hook-form";
import { Subtitle, Span, Paragraph, PriceTag } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import { ReactComponent as IconCheck } from "assets/icons/icon-check-blue.svg";
import { ReactComponent as IconNotCheck } from "assets/icons/icon-not-check.svg";
import Button from "components/core/Button";
import * as S from "./styled";

const Card = ({
  monthly,
  openingTax,
  idSubscriptionPlan,
  idPayroll,
  idBilletCost,
  idAnnuityDiscount,
  idOpeningTax,
  total,
  planId,
  setMonthly,
  idCalculationTaxMei,
  anualDiscount,
}) => {
  const totalAnnual = total / 12;
  return (
    <S.CardPlans>
      <Flex>
        {idSubscriptionPlan === 2 && (
          <S.DiscountRange>
            {" "}
            Economize {anualDiscount
              ? String(100 - anualDiscount * 100).replace(".", ",")
              : ""}%{" "}
          </S.DiscountRange>
        )}
        {idSubscriptionPlan === 1 ? (
          <Subtitle>Plano Mensal</Subtitle>
        ) : (
          <Subtitle>Plano Anual</Subtitle>
        )}
        <Row horizontalAlign="flex-start" marginBottom="1.6rem" padding="0 3.2rem 0 0">
          <IconCheck />
          <Span>Atendimento exclusivo e personalizado</Span>
        </Row>
        <Row horizontalAlign="flex-start" marginBottom="1.6rem">
          <IconCheck />
          <Span>Emissão rápida e segura de NF-e</Span>
        </Row>
        <Row horizontalAlign="flex-start" marginBottom="1.6rem">
          {idSubscriptionPlan === 1 ? (
            <>
              <IconNotCheck />
              <Span>
                {" "}
                Você deixa de economizar{" "}
                {anualDiscount ? String(100 - anualDiscount * 100).replace(".", ",") : ""}%
              </Span>
            </>
          ) : (
            <>
              <IconCheck />
              <Span>
                Você economiza{" "}
                {anualDiscount ? String(100 - anualDiscount * 100).replace(".", ",") : ""}%
              </Span>
            </>
          )}
        </Row>
        <Row horizontalAlign="flex-start" marginBottom="1.6rem">
          {openingTax ? (
            <Paragraph color="var(--color-blue-1)">
              R$<PriceTag> {String(openingTax.toFixed(2)).replace(".", ",")} </PriceTag>taxa de
              abertura
            </Paragraph>
          ) : (
            <>
              <IconCheck />
              <Span>Isenção na taxa de abertura</Span>
            </>
          )}
        </Row>
        <Flex horizontalAlign="center" verticalAlign="center">
          {idSubscriptionPlan === 1 ? (
            <Paragraph color="var(--color-blue-1)" textAlign="center" fontSize="24px">
              R$
              <PriceTag fontSize="38px">
                {" "}
                {String(monthly.toFixed(2)).replace(".", ",")}{" "}
              </PriceTag>{" "}
              /mês
            </Paragraph>
          ) : (
            <>
              <Paragraph
                customStyle={{ "align-self": "baseline" }}
                color="var(--color-blue-1)"
                fontSize="16px"
                fontWeight={500}
              >
                Equivalente a
              </Paragraph>
              <Paragraph color="var(--color-blue-1)" textAlign="center" fontSize="24px">
                R$
                <PriceTag fontSize="38px">
                  {" "}
                  {String(totalAnnual.toFixed(2)).replace(".", ",")}{" "}
                </PriceTag>{" "}
                /mês
              </Paragraph>
            </>
          )}
        </Flex>
        {idSubscriptionPlan === 1 ? (
          <Flex verticalAlign="center" horizontalAlign="center">
            <Paragraph fontSize="14px">{`1°Parcela: R$ ${String(
              (monthly + openingTax).toFixed(2),
            ).replace(".", ",")}`}</Paragraph>
            <Paragraph fontSize="14px">{`Demais parcelas: R$ ${String(monthly.toFixed(2)).replace(
              ".",
              ",",
            )}`}</Paragraph>
          </Flex>
        ) : (
          <Flex verticalAlign="center" horizontalAlign="center">
            <Paragraph fontSize="14px">{`Cobrança anual de R$ ${String(total.toFixed(2)).replace(
              ".",
              ",",
            )}`}</Paragraph>
          </Flex>
        )}
      </Flex>
      <Button
        onClick={() => {
          planId(
            idSubscriptionPlan,
            idPayroll,
            idBilletCost,
            idAnnuityDiscount,
            idOpeningTax,
            idCalculationTaxMei,
            total,
          );
          setMonthly(monthly === 0 ? (total / 12).toFixed(2) : monthly);
        }}
      >
        Assinar
      </Button>
    </S.CardPlans>
  );
};

const SubscriptionPlans = ({ onBack, onContinue, subscriptionPlans, setMonthly }) => {
  const submitRef = useRef(null);
  const { register, handleSubmit, setValue } = useForm({
    mode: "onBlur",
    submitFocusError: true,
  });
  const handlePlan = (
    idSubscriptionPlan,
    idPayroll,
    idBilletCost,
    idAnnuityDiscount,
    idOpeningTax,
    idCalculationTaxMei,
    price,
  ) => {
    setValue("idSubscriptionPlan", idSubscriptionPlan);
    setValue("idPayroll", idPayroll);
    setValue("idBilletCost", idBilletCost);
    setValue("idAnnuityDiscount", idAnnuityDiscount);
    setValue("idOpeningTax", idOpeningTax);
    setValue("idCalculationTaxMei", idCalculationTaxMei);
    setValue("price", price);
    submitRef.current.click();
  };

  return (
    <S.RegisterContainer large>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="88%" />
        </Container>
        <Subtitle mb="0">Escolha seu Plano</Subtitle>
        <S.RegisterRow>
          {subscriptionPlans &&
            subscriptionPlans?.map(
              ({
                monthly,
                openingTax,
                annuity,
                idSubscriptionPlan,
                idPayroll,
                idBilletCost,
                idAnnuityDiscount,
                idOpeningTax,
                idCalculationTaxMei,
                total,
                anualDiscount,
              }) => (
                <Card
                  key={idSubscriptionPlan}
                  idSubscriptionPlan={idSubscriptionPlan}
                  idPayroll={idPayroll}
                  idBilletCost={idBilletCost}
                  idAnnuityDiscount={idAnnuityDiscount}
                  idCalculationTaxMei={idCalculationTaxMei}
                  idOpeningTax={idOpeningTax}
                  monthly={monthly}
                  openingTax={openingTax}
                  annuity={annuity}
                  total={total}
                  planId={handlePlan}
                  setMonthly={setMonthly}
                  anualDiscount={Number(anualDiscount)}
                />
              ),
            )}
        </S.RegisterRow>
        <Form onSubmit={handleSubmit(onContinue)}>
          <InputHidden ref={register} name="idSubscriptionPlan" />
          <InputHidden ref={register} name="idPayroll" />
          <InputHidden ref={register} name="idBilletCost" />
          <InputHidden ref={register} name="idAnnuityDiscount" />
          <InputHidden ref={register} name="idOpeningTax" />
          <InputHidden ref={register} name="idCalculationTaxMei" />
          <InputHidden ref={register} name="price" />
          <InputHidden ref={register} name="expirationDateBillet" />
          <InputHidden ref={submitRef} type="submit" value="Submit" />
          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

SubscriptionPlans.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array,
  setMonthly: PropTypes.func.isRequired,
};

Card.propTypes = {
  monthly: PropTypes.number.isRequired,
  openingTax: PropTypes.number.isRequired,
  idSubscriptionPlan: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  planId: PropTypes.func.isRequired,
  setMonthly: PropTypes.func.isRequired,
  idPayroll: PropTypes.number.isRequired,
  idBilletCost: PropTypes.number.isRequired,
  idAnnuityDiscount: PropTypes.number.isRequired,
  idOpeningTax: PropTypes.number.isRequired,
  idCalculationTaxMei: PropTypes.number.isRequired,
  anualDiscount: PropTypes.number.isRequired,
};

SubscriptionPlans.defaultProps = {
  subscriptionPlans: [],
};
export default SubscriptionPlans;
