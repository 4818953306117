import styled, { css } from "styled-components";
import { CheckboxWrapper } from "components/core/Checkbox/styled";
import iconOrder from "assets/icons/icon-order.svg";

const orderByItemStyle = css`
  & > button {
    cursor: pointer;
    position: relative;

    &::after {
      background: url(${iconOrder}) center center no-repeat;
      content: "";
      display: block;
      width: 0.8rem;
      height: 0.5rem;
      position: absolute;
      top: 40%;
      right: -1.4rem;
      transition: transform 0.3s linear;
      transform: rotate(${({ order }) => (order === "desc" ? "0deg" : "180deg")});
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  ${({ customStyle }) => customStyle}
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-bottom: 0.5px solid rgba(1, 117, 239, 0.3);
  table-layout: auto;
  width: 100%;
`;

export const TableHeader = styled.thead`
  border: 0.5px solid rgba(1, 117, 239, 0.3);
  border-left: 0;
  border-right: 0;
`;

export const TableHeaderItem = styled.th.attrs(({ textAlign }) => ({
  textAlign: textAlign || "left",
}))`
  color: var(--color-secondary);
  font-size: 1.4rem;
  font-weight: 500;
  text-align: ${({ textAlign }) => textAlign};
  padding: 1rem 1rem;

  ${({ activeOrder }) => activeOrder && orderByItemStyle}

  &:last-child {
    padding-right: 2rem;
  }
`;

export const TableItem = styled.td.attrs(({ textAlign, hasAction, padding }) => ({
  textAlign: textAlign ?? "left",
  cursor: hasAction ? "pointer" : "default",
  padding: padding ?? "2rem 1rem",
}))`
  color: black;
  cursor: ${({ cursor }) => cursor};
  font-size: var(--font-size-default);
  line-height: 2rem;
  text-align: ${({ textAlign }) => textAlign};
  padding: ${({ padding }) => padding};
  &,
  & > * {
    &:empty {
      background: var(--color-black-2);
      display: block;
      width: 7px;
      height: 2px;
      text-align: center !important;
    }
  }

  &:last-child {
    padding-right: 2rem;
  }
`;

export const CheckboxTableWrapper = styled(CheckboxWrapper)`
  justify-content: center;
`;

export const Input = styled.input`
  display: none;

  &:checked + label > span::after {
    opacity: 1;
  }
`;
