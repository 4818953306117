import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import GlobalSettingEdit from "components/presentation/Financial/GlobalSettings/Edit";
import { useRequest, useFinancial } from "hooks";
import { LayoutActions } from "store/ducks/layout";
import { LINKS, REQUEST_RESOLVED } from "utils/constants";
import { globalSettingSchema } from "utils/schemas/globalSetting";

const GlobalSettingEditWithSpinner = WithSpinner(GlobalSettingEdit);

const GlobalSettingEditContainer = () => {
  const history = useHistory();
  const submitRef = useRef(null);
  const { cleanState, dispatch, getGlobalSetting, globalSetting, requestStatus, setGlobalSetting } =
    useFinancial();
  const { t: translate } = useTranslation();
  const [errorPlans, setErrorPlans] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const dispatchGetGlobalSetting = useCallback(() => getGlobalSetting(id), [id]);
  useEffect(() => {
    dispatchGetGlobalSetting();
  }, [dispatchGetGlobalSetting]);
  useEffect(() => {
    setIsLoading(
      isPending(requestStatus.SET_GLOBAL_SETTING) || isPending(requestStatus.GET_GLOBAL_SETTING),
    );
    if (requestStatus.GET_GLOBAL_SETTING === REQUEST_RESOLVED) {
      setDefaultValues({
        ...globalSetting,
        allowedInstallmentsPlanMonthly: globalSetting?.allowedInstallmentsPlans?.includes(1),
        allowedInstallmentsPlanAnnual: globalSetting?.allowedInstallmentsPlans?.includes(2),
        allowedInstallmentsPlanBiennial: globalSetting?.allowedInstallmentsPlans?.includes(3),
        interestValue:
          Number(globalSetting.interestType) === 1
            ? globalSetting.monthlyInterest
            : globalSetting.annualInterest,
      });
    }
  }, [globalSetting, requestStatus]);
  const dispatchConfirmation = (text, type, title) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
        title,
      }),
    );
  useRequest({
    request: requestStatus.SET_GLOBAL_SETTING,
    onResolved: () => {
      dispatchConfirmation(
        <Trans
          i18nKey="Global Setting edited successfully"
          values={{ name: globalSetting?.enterpriseType?.name }}
          components={{ bold: <strong /> }}
        />,
        "success",
      );
      history.push(LINKS.financial.installments.root);
      cleanState();
    },
  });
  const handleSubmit = (values) => {
    const data = { ...values };
    setErrorPlans(
      data.allowedInstallmentsPlanMonthly === false &&
        data.allowedInstallmentsPlanAnnual === false &&
        data.allowedInstallmentsPlanBiennial === false,
    );
    if (!errorPlans) {
      setGlobalSetting(data);
    }
  };

  return (
    <>
      <NavBar title={translate("Global Installments Settings")} route={null}>
        <Button url={LINKS.financial.installments.root} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("save")}</Button>
      </NavBar>
      <GlobalSettingEditWithSpinner
        isLoading={isLoading || !defaultValues}
        submitRef={submitRef}
        handleSubmitForm={handleSubmit}
        defaultValues={defaultValues}
        validationSchema={globalSettingSchema}
        errorPlans={errorPlans}
      />
    </>
  );
};

export default GlobalSettingEditContainer;
