import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import { Label } from "components/core/InputForm/styled";
import { Subtitle, TertiaryTitle } from "components/core/Typography";
import { Columns, Container } from "components/core/Grid";
import { Form, InputHidden, InputGroup } from "components/core/Form";
import { MASK_TYPES } from "utils/masks";
import { parseMoneyToFloat } from "helpers/sanitize";

const OtherTypesTaxes = ({ onSubmit, defaultValues, submitRef, isSimple }) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues,
    submitFocusError: true,
    mode: "onBlur",
  });
  const { fields } = useFieldArray({ control, name: "ranges" });
  const watchRanges = watch("ranges");
  const watchMinInstallmentAmount = watch("minInstallmentAmount");
  const watchOpeningTax = watch("openingTax");
  const [optionsAllowInstallments, setOptionsAllowInstallments] = useState();
  const [optionsMaxInstallments, setOptionsMaxInstallments] = useState();
  useEffect(() => {
    const options = [
      { text: translate("Yes"), value: true, selected: defaultValues.allowInstallments === true },
      {
        text: translate("No"),
        value: false,
        selected: defaultValues.allowInstallments === false,
      },
    ];
    setOptionsAllowInstallments(options);
  }, [defaultValues]);
  useEffect(() => {
    const newMinInstallmentAmount = parseMoneyToFloat(watchMinInstallmentAmount);
    const openingTax = parseMoneyToFloat(watchOpeningTax);
    let options = [];
    if (!Number.isNaN(newMinInstallmentAmount) && !Number.isNaN(openingTax)) {
      for (let i = 1; i <= 12; i++) {
        const price = openingTax / i;
        if (price < newMinInstallmentAmount) break;
        options.push({ text: i, value: i });
      }
      if (options.length === 0) {
        options.push({ text: 1, value: 1, selected: true });
      } else {
        options = options.map((option) => {
          option.selected = false;
          if (option.value === options.length) {
            option.selected = true;
          }
          return option;
        });
      }
    }
    setOptionsMaxInstallments(options);
  }, [watchMinInstallmentAmount, watchOpeningTax]);
  const validateNewBillingMin = (newBillingMin, index) => {
    const prevRange = watchRanges[index - 1];
    let isValid = true;

    if (prevRange?.billingMax) {
      const { billingMax: prevBillingMax } = prevRange;
      const nextBillingMinRequired = parseMoneyToFloat(prevBillingMax) + 1.0;

      isValid = nextBillingMinRequired === parseMoneyToFloat(newBillingMin);
    }

    return isValid;
  };

  return (
    <Container maxWidth="md">
      <Subtitle>
        {translate("edit")} {isSimple ? translate("simple") : translate("presumed")}
      </Subtitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup marginBottom="2rem">
          <InputForm
            register={register({
              required: translate("taxRequired"),
            })}
            type="text"
            name="openingTax"
            label={translate("openingTax")}
            mask={MASK_TYPES.realWithName}
            errorMsg={errors.openingTax?.message}
          />
          <Columns columns="3">
            <div style={{ marginRight: "2rem" }}>
              <SelectForm
                register={register}
                name="allowInstallments"
                label="Parcelamento?"
                options={optionsAllowInstallments}
              />
            </div>
            <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>
              <InputForm
                register={register}
                type="text"
                name="minInstallmentAmount"
                label="Valor Mínimo Parcela"
                errorMsg={errors.minInstallmentAmount?.message}
                mask={MASK_TYPES.realWithName}
              />
            </div>
            <div style={{ marginLeft: "2rem" }}>
              <SelectForm
                register={register}
                name="maxInstallments"
                label="Nº de parcelas"
                options={optionsMaxInstallments}
              />
            </div>
          </Columns>
        </InputGroup>
        {fields?.map((field, index) =>
          index < fields.length - 1 || isSimple ? (
            <InputGroup key={field.id}>
              <TertiaryTitle>
                {translate("range")} {index + 1}
              </TertiaryTitle>
              <Label>{translate("billing")}</Label>
              <InputHidden ref={register()} name={`ranges[${index}].idRange`} />
              <InputGroup paddingLeft="0" columns="2">
                <InputForm
                  register={register({
                    required: translate("minValueRequired"),
                    validate: {
                      incorretValue: (value) =>
                        validateNewBillingMin(value, index) || translate("incorrectValue"),
                    },
                  })}
                  type="text"
                  name={`ranges[${index}].billingMin`}
                  label={translate("from")}
                  direction="row"
                  horizontalAlign="flex-start"
                  verticalAlign="center"
                  labelDark
                  errorMsg={errors.ranges && errors.ranges[index]?.billingMin?.message}
                  mask={MASK_TYPES.realWithName}
                />
                <InputForm
                  register={register({ required: translate("maxValueRequired") })}
                  type="text"
                  name={`ranges[${index}].billingMax`}
                  label={translate("to")}
                  direction="row"
                  horizontalAlign="flex-start"
                  verticalAlign="center"
                  labelDark
                  errorMsg={errors.ranges && errors.ranges[index]?.billingMax?.message}
                  mask={MASK_TYPES.realWithName}
                />
              </InputGroup>
              <InputGroup paddingLeft="0" columns="2">
                <InputForm
                  register={register({ required: translate("planValueRequired") })}
                  type="text"
                  name={`ranges[${index}].price`}
                  label={translate("planValueMonthly")}
                  errorMsg={errors.ranges && errors.ranges[index]?.price?.message}
                  mask={MASK_TYPES.realWithName}
                />
                <InputForm
                  register={register({ required: translate("planValueRequired") })}
                  type="text"
                  name={`ranges[${index}].annualPrice`}
                  label={translate("planValueYearly")}
                  errorMsg={errors.ranges && errors.ranges[index]?.annualPrice?.message}
                  mask={MASK_TYPES.realWithName}
                />
              </InputGroup>
              <InputGroup paddingLeft="0" columns="2">
                <InputForm
                  register={register({ required: translate("planValueRequired") })}
                  type="text"
                  name={`ranges[${index}].biennialPrice`}
                  label={translate("planValueBiennial")}
                  errorMsg={errors.ranges && errors.ranges[index]?.biennialPrice?.message}
                  mask={MASK_TYPES.realWithName}
                />
              </InputGroup>
            </InputGroup>
          ) : (
            <InputGroup key={field.id}>
              <TertiaryTitle>
                {translate("range")} {index + 1}
              </TertiaryTitle>
              <InputForm
                register={register({ required: translate("billingRequired") })}
                type="text"
                name={`ranges[${index}].billingMin`}
                label={translate("billingGreaterThan")}
                errorMsg={errors.billingMin?.message}
                mask={MASK_TYPES.realWithName}
              />
              <InputHidden ref={register()} name={`ranges[${index}].idRange`} />
              <InputHidden ref={register()} name={`ranges[${index}].billingMax`} />
              <InputGroup paddingLeft="0" columns="2">
                <InputForm
                  register={register({ required: translate("planValueRequired") })}
                  type="text"
                  name={`ranges[${index}].price`}
                  label={translate("planValueMonthly")}
                  errorMsg={errors.price?.message}
                  mask={MASK_TYPES.realWithName}
                />
                <InputForm
                  register={register({ required: translate("planValueRequired") })}
                  type="text"
                  name={`ranges[${index}].annualPrice`}
                  label={translate("planValueYearly")}
                  errorMsg={errors.annualPrice?.message}
                  mask={MASK_TYPES.realWithName}
                />
              </InputGroup>
              <InputGroup paddingLeft="0" columns="2">
                <InputForm
                  register={register({ required: translate("planValueRequired") })}
                  type="text"
                  name={`ranges[${index}].biennialPrice`}
                  label={translate("planValueBiennial")}
                  errorMsg={errors.ranges && errors.ranges[index]?.biennialPrice?.message}
                  mask={MASK_TYPES.realWithName}
                />
              </InputGroup>
            </InputGroup>
          ),
        )}
        <InputHidden ref={register} name="idTypeEnterprise" />
        <InputHidden ref={submitRef} type="submit" value="Submit" />
      </Form>
    </Container>
  );
};

OtherTypesTaxes.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  isSimple: PropTypes.bool.isRequired,
};

export default OtherTypesTaxes;
