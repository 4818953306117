import React from "react";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Subtitle } from "components/core/Typography";
import { Container, Columns } from "components/core/Grid";
import StatusDot, { StatusTypes } from "components/core/StatusDot";

const PaymentGatewayDetails = ({ paymentGateway: { name, active, isDefault } }) => {
  return (
    <Container>
      <CardInfo label="Nome" info={name} />
      <Columns columns="2">
        <CardInfo
          label="Ativo?"
          info={
            <>
              <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
              {active ? "Sim" : "Não"}
            </>
          }
        />
        <CardInfo
          label="Padrão?"
          info={
            <>
              <StatusDot type={isDefault ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
              {isDefault ? "Sim" : "Não"}
            </>
          }
        />
      </Columns>
    </Container>
  );
};

PaymentGatewayDetails.propTypes = {
  paymentGateway: PropTypes.object.isRequired,
};

export default PaymentGatewayDetails;
