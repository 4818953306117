import React, { useState } from "react";
import PropTypes from "prop-types";
import { RenderHeaders, Table, TableHeader, TableBody, TableItem } from "components/core/Table";
import * as S from "./styled";
import { Spinner } from "components/core/WithSpinner/styled";
import { MASK_TYPES, format } from "utils/masks";
import Modal from "components/core/Modal";
import { Span } from "components/core/Typography";
import { Columns, Container, ColumnFlex } from "components/core/Grid";
import CardInfo from "components/core/CardInfo";
import { formatDate } from "helpers";
import Button from "components/core/Button";
import { Chrevon } from "./icons";

const InvoiceItems = ({ items, translate }) => {
  const [showItems, setShowItems] = useState(false);
  const [showChargeDetails, setShowChargeDetails] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const itemPadding = "0.75rem 1rem";
  const headers = [
    { slug: "id", title: translate("ID"), textAlign: "center" },
    { slug: "description", title: `${translate("Description")}` },
    { slug: "price", title: `${translate("Value")}` },
    { slug: "quantity", title: `${translate("Quantity")}`, textAlign: "center" },
    { slug: "total", title: `${translate("Total")}` },
  ];

  const toggleShowItems = () => setShowItems(!showItems);

  const closeModalChargeDetails = () => {
    setSelectedCharge(null);
    setShowChargeDetails(false);
  };

  return (
    <>
      <S.InvoiceItemsContainer>
        <Button
          variant={"text"}
          handleClick={toggleShowItems}
          title={showItems ? "Ocultar items" : "Exibir items"}
        >
          Itens da Fatura <Chrevon />
        </Button>
        {showItems && (
          <Container
            horizontalAlign="center"
            horizontalPadding={0}
            verticalPadding={0}
            marginBottom={"1rem"}
          >
            <Table>
              <TableHeader>
                <RenderHeaders headers={headers} />
              </TableHeader>
              <TableBody>
                {!items && (
                  <tr key={"idLoading"}>
                    <TableItem padding={itemPadding} colSpan="5">
                      <ColumnFlex horizontalAlign={"center"} width="100%">
                        <Spinner width={"4rem"} height={"4rem"} />
                        <Span style={{ marginTop: "0.5rem" }}>Aguarde... Carregando items</Span>
                      </ColumnFlex>
                    </TableItem>
                  </tr>
                )}
                {items &&
                  items?.map(({ id, description, quantity, price }) => (
                    <tr key={id}>
                      <TableItem padding={itemPadding} textAlign="center">
                        <Span>{id}</Span>
                      </TableItem>
                      <TableItem padding={itemPadding}>
                        <Span>{description}</Span>
                      </TableItem>
                      <TableItem padding={itemPadding}>
                        <Span>{format(Number(price).toFixed(2), MASK_TYPES.realWithName)}</Span>
                      </TableItem>
                      <TableItem padding={itemPadding} textAlign="center">
                        <Span>{quantity}</Span>
                      </TableItem>
                      <TableItem padding={itemPadding}>
                        <Span>
                          {format(Number(price * quantity).toFixed(2), MASK_TYPES.realWithName)}
                        </Span>
                      </TableItem>
                    </tr>
                  ))}
              </TableBody>
            </Table>
          </Container>
        )}
      </S.InvoiceItemsContainer>

      {selectedCharge && (
        <Modal
          title={`Detalhes da cobrança #${selectedCharge?.id}`}
          onCloseModal={closeModalChargeDetails}
          showModal={showChargeDetails}
          hideConfirm={true}
          hideCancel={false}
          cancelLabel="Fechar"
          width="80rem"
          textAlign="text-left"
        >
          <Columns columns="3">
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Charged by")}
              info={selectedCharge?.user?.name}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Charged at")}
              info={formatDate(selectedCharge?.createdAt, 0, false, true)}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Installments")}
              info={`${selectedCharge?.chargeResponse?.installments}x`}
            />
          </Columns>
          <Columns columns="3">
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Brand")}
              info={selectedCharge?.selectedCharge?.chargeResponse?.brand}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Final card digits")}
              info={selectedCharge?.selectedCharge?.chargeResponse?.last4}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Bank")}
              info={selectedCharge?.selectedCharge?.chargeResponse?.issuer}
            />
          </Columns>
          <Columns>
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={"LR"}
              info={`${selectedCharge?.chargeResponse?.lr} - ${selectedCharge?.chargeResponse?.lrAttempt?.description}`}
            />
          </Columns>
          {!selectedCharge?.chargeResponse?.success && (
            <>
              {selectedCharge?.chargeResponse?.lrAttempt?.action && (
                <Columns>
                  <CardInfo
                    marginLeft={0}
                    marginRigth={0}
                    label={translate("Possible actions")}
                    info={selectedCharge?.chargeResponse?.lrAttempt?.action}
                  />
                </Columns>
              )}
              {selectedCharge?.chargeResponse?.lrAttempt?.irreversibleForBrands && (
                <Columns>
                  <CardInfo
                    marginLeft={0}
                    marginRigth={0}
                    label={translate("Irreversible status for brands")}
                    info={selectedCharge?.chargeResponse?.lrAttempt?.irreversibleForBrands}
                  />
                </Columns>
              )}
            </>
          )}
        </Modal>
      )}
    </>
  );
};

InvoiceItems.propTypes = {
  items: PropTypes.array,
  translate: PropTypes.func.isRequired,
};

InvoiceItems.defaultProps = {
  items: [],
};

export default InvoiceItems;
