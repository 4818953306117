import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: ServicesTypes, Creators: ServicesActions } = createActions({
  fetchServicesDetails: ["id"],
  fetchServicesDetailsSuccess: ["payload"],
  fetchServicesLocations: null,
  fetchServicesLocationsSuccess: ["payload"],
  fetchAvailableServices: ["params"],
  fetchAvailableServicesSuccess: ["payload"],
  editServices: ["id", "payload"],
  editServicesSuccess: ["payload"],
  deleteServices: ["payload"],
  deleteServicesSuccess: ["payload"],
  servicesRequestFailure: ["error", "originalType"],
  cleanServices: null,
});

const INITIAL_STATE = {
  servicesDetails: {},
  servicesLocations: [],
  availableServices: [],
  ServicesRequests: {
    FETCH_SERVICES_DETAILS: REQUEST_NOT_STARTED,
    FETCH_SERVICES_LOCATIONS: REQUEST_NOT_STARTED,
    FETCH_AVAILABLE_SERVICES: REQUEST_NOT_STARTED,
    ADD_SERVICES_TO_EMITTER: REQUEST_NOT_STARTED,
    DELETE_SERVICES: REQUEST_NOT_STARTED,
  },
};

const fetchServicesDetails = (state) => ({
  ...state,
  ServicesRequests: {
    ...state.ServicesRequests,
    FETCH_SERVICES_DETAILS: REQUEST_PENDING,
  },
});

const fetchServicesDetailsSuccess = (state, { payload = {} }) => ({
  ...state,
  servicesDetails: payload,
  ServicesRequests: {
    ...state.ServicesRequests,
    FETCH_SERVICES_DETAILS: REQUEST_RESOLVED,
  },
});

const fetchServicesLocations = (state) => ({
  ...state,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.FETCH_SERVICES_LOCATIONS]: REQUEST_PENDING,
  },
});

const fetchServicesLocationsSuccess = (state, { payload = {} }) => ({
  ...state,
  servicesLocations: payload,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.FETCH_SERVICES_LOCATIONS]: REQUEST_RESOLVED,
  },
});

const fetchAvailableServices = (state) => ({
  ...state,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.FETCH_AVAILABLE_SERVICES]: REQUEST_PENDING,
  },
});

const fetchAvailableServicesSuccess = (state, { payload = [] }) => ({
  ...state,
  availableServices: payload,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.FETCH_AVAILABLE_SERVICES]: REQUEST_RESOLVED,
  },
});

const editServices = (state) => ({
  ...state,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.EDIT_SERVICES]: REQUEST_PENDING,
  },
});

const editServicesSuccess = (state, { payload = {} }) => ({
  ...state,
  servicesDetails: payload,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.EDIT_SERVICES]: REQUEST_RESOLVED,
  },
});

const deleteServices = (state) => ({
  ...state,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.DELETE_SERVICES]: REQUEST_PENDING,
  },
});

const deleteServicesSuccess = (state, { payload = {} }) => ({
  ...state,
  servicesDetails: payload,
  ServicesRequests: {
    ...state.ServicesRequests,
    [ServicesTypes.DELETE_SERVICES]: REQUEST_RESOLVED,
  },
});

const servicesRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  ServicesRequests: {
    ...state.ServicesRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanServices = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [ServicesTypes.FETCH_SERVICES_DETAILS]: fetchServicesDetails,
  [ServicesTypes.FETCH_SERVICES_DETAILS_SUCCESS]: fetchServicesDetailsSuccess,
  [ServicesTypes.FETCH_SERVICES_LOCATIONS]: fetchServicesLocations,
  [ServicesTypes.FETCH_SERVICES_LOCATIONS_SUCCESS]: fetchServicesLocationsSuccess,
  [ServicesTypes.FETCH_AVAILABLE_SERVICES]: fetchAvailableServices,
  [ServicesTypes.FETCH_AVAILABLE_SERVICES_SUCCESS]: fetchAvailableServicesSuccess,
  [ServicesTypes.EDIT_SERVICES]: editServices,
  [ServicesTypes.EDIT_SERVICES_SUCCESS]: editServicesSuccess,
  [ServicesTypes.DELETE_SERVICES]: deleteServices,
  [ServicesTypes.DELETE_SERVICES_SUCCESS]: deleteServicesSuccess,
  [ServicesTypes.SERVICES_REQUEST_FAILURE]: servicesRequestFailure,
  [ServicesTypes.CLEAN_SERVICES]: cleanServices,
});
