import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams as useRouterParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductsActions } from "store/ducks/products";
import Button from "components/core/Button";
import DetailsFactory from "components/core/DetailsFactory";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import { LINKS } from "utils/constants";

const DetailsFactoryWithSpinner = WithSpinner(DetailsFactory);

const ProductsDetails = () => {
  const dispatch = useDispatch();
  const { emitterId, id } = useRouterParams();
  const { t: translate } = useTranslation();
  const { productsDetails, ProductsRequests } = useSelector(({ products }) => products);

  useEffect(() => {
    dispatch(ProductsActions.fetchProductsDetails(id));
  }, [dispatch, id]);
  return (
    <>
      <NavBar title={translate("products")}>
        <Button url={LINKS.productsEdit(emitterId, id)} variant="outline">
          <span>{translate("editProduct")}</span>
        </Button>
      </NavBar>
      <DetailsFactoryWithSpinner
        requestStatus={ProductsRequests.FETCH_PRODUCTS_DETAILS}
        data={[
          {
            titleKey: "productDetails",
            infos: [
              {
                label: "productCode",
                info: productsDetails.code || "",
              },
              {
                label: "description",
                info: productsDetails.name || "",
              },
              {
                label: "unitMeasure",
                info: `${productsDetails?.unitMeasure?.code || ""} -${
                  productsDetails?.unitMeasure?.name || ""
                }`,
              },
              {
                label: "ncm",
                info: `${productsDetails?.ncm?.code || ""} -${productsDetails?.ncm?.name || ""}`,
              },
              {
                label: "csosn",
                info: `${productsDetails?.csosn?.code || ""} -${
                  productsDetails?.csosn?.name || ""
                }`,
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default ProductsDetails;
