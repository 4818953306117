import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";

export const ncmCategorySchema = Yup.object().shape({
  code: Yup.string()
    .min(6, <Trans i18nKey="short.code" />)
    .required(<Trans i18nKey="required.code" />),

  name: Yup.string()
    .min(6, <Trans i18nKey="short.name" />)
    .required(<Trans i18nKey="required.name" />),
});

export const ncmCategoryInitialValues = {
  name: "",
  code: "",
};
