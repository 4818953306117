import styled, { css } from "styled-components";
import { Title, Span } from "components/core/Typography";
import { Button } from "components/core/Button/styled";

const activeModal = css`
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s linear, visibility 0s 0s;
`;

export const ModalWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s linear, visibility 0s 0.2s;

  ${({ active }) => active && activeModal}
`;

export const Modal = styled.div.attrs(({ width, verticalAlign }) => ({
  justifyContent: verticalAlign ?? "space-between",
  maxWidth: width ?? "70rem",
}))`
  background-color: var(--color-white-1);
  border-radius: 0.6rem;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 80%;
  height: auto;
  min-height: 26rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  position: relative;
`;

export const ModalTitle = styled(Title)`
  color: var(--color-gray-4);
  text-align: center;
  margin-bottom: 3.2rem;
`;

export const CardContainer = styled.div`
  margin-bottom: 2rem;
`;

export const CardFormContainer = styled.div`
  margin: 1rem;
`;

export const ModalContent = styled.div.attrs(({ textAlign }) => ({
  textAlign: textAlign ?? "center",
}))`
  height: 100%;
  width: 100%;
  text-align: ${({ textAlign }) => textAlign};

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  & > ${/* sc-sel */ Span}:not(:last-child) {
    display: inline-block;
    margin-bottom: var(--space-sm);
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${/* sc-sel */ Button}:not(:last-child) {
    margin-right: 5rem;
  }
`;
