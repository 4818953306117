import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useFinancial, useItems, useFinancialSubscription, useRequest } from "hooks";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import InvoicesList from "components/presentation/Financial/Invoices/List";
import Button from "components/core/Button";
import { REQUEST_NOT_STARTED, REQUEST_PENDING, REQUEST_RESOLVED, baseURL } from "utils/constants";
import { ReactComponent as IconExport } from "assets/icons/icon-download.svg";

const Filter = React.lazy(() => import("containers/Financial/Invoices/Filter"));

const InvoiceListWithSpinner = WithSpinner(InvoicesList);
const FilterWithSuspense = WithSuspense(Filter);

const InvoicesListContainer = () => {
  const [showConfirmProtheus, setShowConfirmProtheus] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [selected, setSelected] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const {
    cleanState,
    downloadData,
    getFinancialInvoices,
    invoices: { items, total, totalPages },
    prepareInvoicesDownload,
    requestStatus,
    getFinancialInvoiceItems,
    invoiceItems,
  } = useFinancial();
  const {
    sendInvoiceToProtheus,
    requestStatus: statusOfRequest,
    handleOnRejected,
    handleOnResolved,
    validationErrors,
  } = useFinancialSubscription();
  const { everyoneIsChecked, selectedItems, handleChange, toggleAll, listChecked } =
    useItems(items);
  const [isLoading, setIsLoading] = useState(REQUEST_NOT_STARTED);
  const { t: translate } = useTranslation();
  useEffect(() => {
    if (
      requestStatus.GET_FINANCIAL_INVOICES === REQUEST_PENDING ||
      requestStatus.PREPARE_INVOICES_DOWNLOAD === REQUEST_PENDING
    ) {
      setIsLoading(REQUEST_PENDING);
    }
    if (
      (requestStatus.GET_FINANCIAL_INVOICES === REQUEST_RESOLVED &&
        requestStatus.PREPARE_INVOICES_DOWNLOAD !== REQUEST_PENDING) ||
      (requestStatus.GET_FINANCIAL_INVOICES !== REQUEST_PENDING &&
        requestStatus.PREPARE_INVOICES_DOWNLOAD === REQUEST_RESOLVED)
    ) {
      setIsLoading(REQUEST_RESOLVED);
    }
  }, [requestStatus]);
  useEffect(() => {
    setIsLoading(statusOfRequest.SEND_INVOICE_TO_PROTHEUS);
  }, [statusOfRequest.SEND_INVOICE_TO_PROTHEUS]);
  useEffect(() => {
    if (requestStatus.PREPARE_INVOICES_DOWNLOAD === REQUEST_RESOLVED) {
      const { filename } = downloadData;
      const url = `${baseURL}financial/invoices/download/${filename}`;
      window.open(url, "_blank");
      cleanState();
      handleRefresh();
    }
  }, [requestStatus, downloadData]);
  const fetchInvoices = (params) => {
    getFinancialInvoices({
      ...params,
      attributes: [
        "id",
        "gatewayInvoiceId",
        "status",
        "invoiceUrl",
        "dueDate",
        "total",
        "enterpriseName",
        "fantasyName",
        "userName",
        "planName",
        "invoiceTypeDescription",
        "payerName",
        "protheus",
        "originalInvoiceId",
        "enterpriseId",
      ],
    });
  };
  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchInvoices,
    defaultOrderBy: "createdAt",
    defaultOrder: "desc",
  });
  const getInvoice = (invoiceId) => {
    return items.find((item) => item.id === invoiceId);
  };
  const handleRefresh = () => {
    fetchInvoices();
  };
  const handleViewItems = (invoiceId) => {
    const selected = getInvoice(invoiceId);
    getFinancialInvoiceItems(invoiceId);
    setSelected(selected);
    setShowItems(true);
  };
  const handleViewDetails = (invoiceId) => {
    const selected = getInvoice(invoiceId);
    setSelected(selected);
    setShowDetails(true);
  };
  const handleClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };
  const handleTab = (url) => {
    window.open(url, "_blank");
  };
  const handleDownload = () => {
    prepareInvoicesDownload();
  };
  const handleModalProtheus = (invoiceId) => {
    const selected = getInvoice(invoiceId);
    setSelected(selected);
    setShowConfirmProtheus(true);
  };
  const handleProtheus = (invoiceId) => {
    const selected = getInvoice(invoiceId);
    const { enterpriseId, gatewayInvoiceId } = selected;
    const params = {
      enterpriseId,
      invoiceId: selected.id,
      invoiceCode: gatewayInvoiceId,
    };
    sendInvoiceToProtheus(params);
    setShowConfirmProtheus(false);
  };
  useRequest({
    request: statusOfRequest.SEND_INVOICE_TO_PROTHEUS,
    onResolved: () => handleOnResolved("sendedToProtheus"),
    onRejected: () => handleOnRejected("sendToProtheusError", validationErrors()),
  });

  return (
    <>
      <NavBar title={translate("Invoices")} route={null}>
        <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
        {items && (
          <Button variant="outline" handleClick={handleDownload}>
            <IconExport />
            <span>{translate("Download to excel")}</span>
          </Button>
        )}
        <Button handleClick={handleRefresh}>{translate("Refresh")}</Button>
      </NavBar>
      <InvoiceListWithSpinner
        requestStatus={isLoading}
        invoices={listChecked ?? items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
        everyoneIsChecked={everyoneIsChecked}
        toggleAll={toggleAll}
        handleChange={handleChange}
        selectedInvoices={selectedItems}
        handleViewDetails={handleViewDetails}
        handleViewItems={handleViewItems}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        showItems={showItems}
        setShowItems={setShowItems}
        selected={selected}
        handleClipboard={handleClipboard}
        linkCopied={linkCopied}
        handleTab={handleTab}
        handleProtheus={handleProtheus}
        showConfirmProtheus={showConfirmProtheus}
        setShowConfirmProtheus={setShowConfirmProtheus}
        handleModalProtheus={handleModalProtheus}
        invoiceItems={invoiceItems}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
    </>
  );
};

export default InvoicesListContainer;
