import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormContext, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { Flex, Row, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import InputForm from "components/core/InputForm";
import { mapToSelectOptions } from "helpers";
import FormCEPObserver from "components/core/Form/FormCEPObserver";
import { Subtitle } from "components/core/Typography";
import { getStates } from "utils/mocks/brazilCitiesStates";
import SelectForm from "components/core/SelectForm";
import { MASK_TYPES } from "utils/masks";
import Button from "components/core/Button";
import * as S from "./styled";

const Address = ({ onContinue, onBack, storage }) => {
  const states = getStates();
  const { t: translate } = useTranslation();
  const [newZipCode, setNewZipCode] = useState("");
  const { isLoading: isLoadingCEP } = useSelector(({ address }) => address);

  const validation = yup.object().shape({
    zipCode: yup
      .string()
      .min(10, <Trans i18nKey="short.zipCode" />)
      .required(<Trans i18nKey="required.zipCode" />),
    city: yup.string().required(<Trans i18nKey="required.city" />),
    state: yup.string().required(<Trans i18nKey="required.state" />),
    neighborhood: yup.string().required(<Trans i18nKey="required.neighborhood" />),
    street: yup.string().required(<Trans i18nKey="required.street" />),
    number: yup.string().required(<Trans i18nKey="required.number" />),
    complement: yup.string(),
  });

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    validationSchema: validation,
    mode: "onBlur",
    submitFocusError: true,
    defaultValues: storage.companyRegister,
  });

  const watchZipCode = watch("zipCode");

  useEffect(() => {
    if (watchZipCode?.length === 10) {
      setNewZipCode(watchZipCode);
    }
  }, [watchZipCode]);

  const handleNextStep = (data) => {
    const event = "EtapaEndereco";
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="52%" />
        </Container>
        <FormContext watch={watch} setValue={setValue}>
          <Form onSubmit={handleSubmit(handleNextStep)}>
            <Flex horizontalAlign="center">
              <Subtitle>Qual o endereço?</Subtitle>
              <InputForm
                register={register}
                type="text"
                name="zipCode"
                label={translate("zipCode")}
                placeholder={translate("typeZipCode")}
                errorMsg={errors.cep?.message}
                mask={MASK_TYPES.cep}
                isLoading={isLoadingCEP}
              />
              <SelectForm
                register={register}
                name="state"
                label={translate("state")}
                disabled
                options={mapToSelectOptions(states)}
                errorMsg={errors.state?.message}
              />
              <InputForm
                register={register}
                name="city"
                label={translate("city")}
                disabled
                errorMsg={errors.city?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="neighborhood"
                label={translate("neighborhood")}
                placeholder={translate("typeNeighborhood")}
                errorMsg={errors.neighborhood?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="street"
                label={translate("street")}
                placeholder={translate("typeStreet")}
                errorMsg={errors.address?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="number"
                label={translate("number")}
                placeholder={translate("typeNumber")}
                errorMsg={errors.number?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="complement"
                label={translate("complement")}
                placeholder={translate("typeComplement")}
                errorMsg={errors.complement?.message}
              />
              <Row horizontalAlign="center">
                <Button handleClick={onBack} variant="outline">
                  Voltar
                </Button>
                <Button type="submit">Continuar</Button>
              </Row>
            </Flex>
          </Form>
          <FormCEPObserver zipCode={newZipCode} newZipCode={newZipCode} />
        </FormContext>
      </Flex>
    </S.RegisterContainer>
  );
};

Address.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  storage: PropTypes.object.isRequired,
};

export default Address;
