import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: InterTypes, Creators: InterActions } = createActions({
  fetchLeads: ["payload"],
  fetchLeadsSuccess: ["payload"],
  sendEmailLead: ["params"],
  sendEmailLeadSuccess: ["payload"],
  requestCheckoutInter: ["payload"],
  requestCheckoutInterSuccess: ["payload"],
  editLead: ["payload"],
  editLeadSuccess: ["payload"],
  cleanState: null,
  requestFailure: ["error", "originalType"],
});

const INITIAL_STATE = {
  payload: null,
  error: null,
  interLeads: {
    items: [],
  },
  interCheckout: {
    items: [],
  },
  responseEmail: {
    response: [],
  },
  interRequests: {
    [InterTypes.FETCH_LEADS]: REQUEST_NOT_STARTED,
    [InterTypes.REQUEST_CHECKOUT_INTER]: REQUEST_NOT_STARTED,
    [InterTypes.SEND_EMAIL_LEAD]: REQUEST_NOT_STARTED,
    [InterTypes.EDIT_LEAD]: REQUEST_NOT_STARTED,
  },
};

const fetchLeads = (state) => ({
  ...state,
  interRequests: {
    ...state.interRequests,
    [InterTypes.FETCH_LEADS]: REQUEST_PENDING,
  },
});

const fetchLeadsSuccess = (state, { payload }) => ({
  ...state,
  interLeads: payload,
  interRequests: {
    ...state.interRequests,
    [InterTypes.FETCH_LEADS]: REQUEST_RESOLVED,
  },
});

const sendEmailLead = (state, { params }) => ({
  ...state,
  params,
  interRequests: {
    ...state.interRequests,
    [InterTypes.SEND_EMAIL_LEAD]: REQUEST_PENDING,
  },
});
const sendEmailLeadSuccess = (state, { payload }) => ({
  ...state,
  responseEmail: payload,
  interRequests: {
    ...state.interRequests,
    [InterTypes.SEND_EMAIL_LEAD]: REQUEST_RESOLVED,
  },
});

const requestCheckoutInter = (state) => ({
  ...state,
  interRequests: {
    ...state.interRequests,
    [InterTypes.REQUEST_CHECKOUT_INTER]: REQUEST_PENDING,
  },
});
const requestCheckoutInterSuccess = (state, { payload }) => ({
  ...state,
  interCheckout: payload,
  interRequests: {
    ...state.interRequests,
    [InterTypes.REQUEST_CHECKOUT_INTER]: REQUEST_RESOLVED,
  },
});

const editLead = (state) => ({
  ...state,
  interRequests: {
    ...state.interRequests,
    [InterTypes.EDIT_LEAD]: REQUEST_PENDING,
  },
});

const editLeadSuccess = (state, { payload }) => ({
  ...state,
  interLeads: payload,
  interRequests: {
    ...state.interRequests,
    [InterTypes.EDIT_LEAD]: REQUEST_RESOLVED,
  },
});

const requestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  interRequests: {
    ...state.interRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [InterTypes.FETCH_LEADS]: fetchLeads,
  [InterTypes.FETCH_LEADS_SUCCESS]: fetchLeadsSuccess,
  [InterTypes.SEND_EMAIL_LEAD]: sendEmailLead,
  [InterTypes.SEND_EMAIL_LEAD_SUCCESS]: sendEmailLeadSuccess,
  [InterTypes.REQUEST_CHECKOUT_INTER]: requestCheckoutInter,
  [InterTypes.REQUEST_CHECKOUT_INTER_SUCCESS]: requestCheckoutInterSuccess,
  [InterTypes.EDIT_LEAD]: editLead,
  [InterTypes.EDIT_LEAD_SUCCESS]: editLeadSuccess,
  [InterTypes.CLEAN_STATE]: cleanState,
  [InterTypes.REQUEST_FAILURE]: requestFailure,
});
