import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationPT } from "locales";

const { language } = navigator;
const resources = {
  "pt-BR": {
    translation: translationPT,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: "pt-BR",
  interpolation: {
    escapeValue: false,
  },
});
