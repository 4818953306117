const ROLES = {
  master: "Administrador",
  accountant: "Contador",
  marketing: "Marketing",
};

const PAYMENT_TYPES = {
  "Cartão de Crédito": "credit_card",
  Boleto: "boleto",
};

export const getLabelHelper = (key) => ROLES[key] || key;

export const getPaymentMethod = (paymentName) => PAYMENT_TYPES[paymentName];
