import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CitiesActions } from "store/ducks/cities";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import CitiesList from "components/presentation/Cities/List";
import { useParams, useRequest } from "hooks";
import Button from "components/core/Button";
import { ReactComponent as IconUpload } from "assets/icons/icon-upload.svg";
import { formatDate } from "helpers/format";
import { Span } from "components/core/Typography";

const Filter = React.lazy(() => import("containers/Cities/Filter"));
const UploadFileModal = React.lazy(() => import("containers/Cities/List/UploadFile"));

const UploadModalWithSuspense = WithSuspense(UploadFileModal);
const FilterWithSuspense = WithSuspense(Filter);
const CitiesListWithSpinner = WithSpinner(CitiesList);

const CitiesListContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [date, setDate] = useState("");

  const {
    citiesList: { items, total, totalPages, updatedAt },
    CitiesRequestsStatus,
  } = useSelector(({ cities }) => cities);

  const fetchCities = (params) => {
    dispatch(CitiesActions.getCities(params));
    setShowUploadModal(false);
  };

  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    dispatchWithParams,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchCities,
    defaultOrderBy: "uf",
  });

  useRequest({
    request: CitiesRequestsStatus.UPLOAD_CITIES,
    onResolved: () => {
      dispatch(CitiesActions.cleanState());
      setShowUploadModal(false);
    },
  });
  useEffect(() => () => CitiesActions.cleanState(), []);

  useEffect(() => {
    if (updatedAt) {
      setDate(formatDate(updatedAt));
    }
  }, [updatedAt]);

  return (
    <>
      <NavBar title={translate("cities")}>
        <Span>Ultima atualização - {date}</Span>
        <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
        <Button handleClick={() => setShowUploadModal(true)}>
          <IconUpload />
          <span>{translate("importSpread")}</span>
        </Button>
      </NavBar>
      <CitiesListWithSpinner
        requestStatus={CitiesRequestsStatus.GET_CITIES}
        cities={items}
        setPage={setPage}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        setOrderBy={setOrderBy}
        setPerPage={setPerPage}
        setOrder={setOrder}
        order={order}
        orderBy={orderBy}
      />

      <UploadModalWithSuspense
        loadComponent={showUploadModal}
        showModal={showUploadModal}
        setShowModal={setShowUploadModal}
        fetchCities={dispatchWithParams}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
    </>
  );
};

export default CitiesListContainer;
