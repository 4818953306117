import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { LayoutActions } from "store/ducks/layout";
import NotificationsService from "services/Notifications";
import ConfirmationCard from "components/core/ConfirmationCard";
import SideBar from "components/core/SideBar";
import PrivateRoutes from "routes/private";
import PrivateBatchesRoutes from "routes/privateBatches";
import { ROUTES } from "utils/constants";
import SideBarClient from "components/core/SidebarClient";
import * as S from "./styled";

const Private = () => {
  const dispatch = useDispatch();
  const { isEmitterMenuOpen, isEmitterSearchOpen, isManageMenuOpen, confirmationCard } =
    useSelector(({ layout }) => layout);
  const {
    user: { roles: rolesPermission },
  } = useSelector((state) => state.auth.claims);
  const {
    show: showConfirmation,
    confirmation,
    content,
    useTranslate,
    validationsFail,
  } = confirmationCard;
  const { pathname } = useLocation();
  const clearConfirmation = () => dispatch(LayoutActions.clearConfirmation());

  return (
    <>
      <S.Container>
        {rolesPermission === "client" ? <SideBarClient /> : <SideBar />}
        <S.PortalWrapper
          activeBlur={isEmitterMenuOpen || isEmitterSearchOpen || isManageMenuOpen}
          isChatPage={pathname === ROUTES.customerService.path}
        >
          {rolesPermission === "client" ? <PrivateBatchesRoutes /> : <PrivateRoutes />}
        </S.PortalWrapper>
        <ConfirmationCard
          showModal={showConfirmation}
          confirmation={confirmation}
          useTranslate={useTranslate}
          onClose={clearConfirmation}
          validationsFail={validationsFail}
        >
          {content}
        </ConfirmationCard>
        {rolesPermission !== "client" && <NotificationsService />}
      </S.Container>
    </>
  );
};

export default Private;
