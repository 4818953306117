import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "components/core/Button";
import InputForm from "components/core/InputForm";
import { TertiaryTitle, Paragraph } from "components/core/Typography";
import { Flex, Row } from "components/core/Grid";
import { Form } from "components/core/Form";
import { FeedbackWithRedux } from "components/core/Feedback";
import { validationSchema, defaultValues } from "utils/schemas/forgotPassword";

const ForgotPassword = ({ onSubmit, showForm, errorMsg }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });

  return (
    <Flex verticalAlign="center">
      <FeedbackWithRedux />
      {showForm && (
        <Form onSubmit={handleSubmit(onSubmit)} data-testid="forgotPasswordForm">
          <Flex horizontalAlign="center">
            <Row>
              <Paragraph fontWeight="600">Esqueceu a senha?</Paragraph>
            </Row>
            <Row>
              <TertiaryTitle textAlign="center">
                Não tem problema, <br /> informe seu e-mail de cadastro.
              </TertiaryTitle>
            </Row>
            <Row>
              <InputForm
                register={register}
                type="email"
                name="email"
                label="E-mail"
                placeholder="E-mail"
                autoComplete="username"
                errorMsg={errors?.email?.message ?? errorMsg}
              />
            </Row>
            <Button type="submit">Entrar</Button>
          </Flex>
        </Form>
      )}
    </Flex>
  );
};

ForgotPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showForm: PropTypes.bool,
  errorMsg: PropTypes.string,
};

ForgotPassword.defaultProps = {
  showForm: true,
  errorMsg: null,
};

export default ForgotPassword;
