/* eslint-disable func-names */
import React from "react";
import axios from "axios";
import { isAuthenticated, getAuthClaims } from "helpers/auth";
import { baseURL } from "utils/constants/endpoints";
import { ROUTES } from "utils/constants";
import Modal from "components/core/Modal";
import { Span } from "components/core/Typography";
import { Trans } from "react-i18next";

const api = axios.create({ baseURL, headers: { "Content-Type": "application/json" } });

api.interceptors.request.use(async (initialConfig) => {
  const config = initialConfig;

  if (isAuthenticated()) {
    const { token } = getAuthClaims();
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (
      error.response.status === 504 ||
      error.response.status === 408 ||
      error.response.status === "ECONNABORTED"
    ) {
      return (
        <Modal
          showModal
          hideCancel
          onConfirmModal={window.location.reload(ROUTES.app)}
          title="Erro"
          confirmLabel="Ok"
          width="49rem"
        >
          <Span>
            <Trans i18nKey="errorBoundary" />
          </Span>
        </Modal>
      );
    }
    return Promise.reject(error);
  },
);

export default api;
