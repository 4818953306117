import React from "react";
import { Switch, Route } from "react-router-dom";
import PaymentContainer from "containers/Payment";
import InvoicePaymentContainer from "containers/Financial/Payment";
import InvoicePaymentContainerOld from "containers/InvoicePayment";
import OpenApp from "containers/OpenApp";

import { ROUTES } from "utils/constants";

const Payment = () => (
  <Switch>
    <Route exact path={ROUTES.payment.path} component={PaymentContainer} />
    <Route exact path={ROUTES.invoicePayment.path} component={InvoicePaymentContainer} />
    <Route exact path={ROUTES.invoicePaymentOld.path} component={InvoicePaymentContainerOld} />
    <Route exact path={ROUTES.openApp.path} component={OpenApp} />
  </Switch>
);

export default Payment;
