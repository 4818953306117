import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Paragraph } from "components/core/Typography";
import { ErrorWrapper as Wrapper } from "components/core/ErrorBoundary/styled";
import { ReactComponent as ImageSuccess } from "assets/images/success.svg";

const SuccessScreen = ({ i18nKey }) => {
  const { t: translate } = useTranslation();

  return (
    <Wrapper>
      <ImageSuccess />
      <Paragraph>{translate(i18nKey)}</Paragraph>
    </Wrapper>
  );
};

SuccessScreen.propTypes = {
  i18nKey: PropTypes.string.isRequired,
};

export default SuccessScreen;
