import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: CstTypes, Creators: CstActions } = createActions({
  fetchCstDetails: ["id"],
  fetchCstDetailsSuccess: ["payload"],
  fetchAvailableCst: ["params"],
  fetchAvailableCstSuccess: ["payload"],
  fetchAvailableCstWithParams: ["id", "params"],
  fetchAvailableCstWithParamsSuccess: ["payload"],
  editCst: ["id", "payload"],
  editCstSuccess: ["payload"],
  deleteCst: ["payload"],
  deleteCstSuccess: ["payload"],
  cstRequestFailure: ["error", "originalType"],
  cleanCst: null,
});

const INITIAL_STATE = {
  cstDetails: {},
  availableCst: [],
  CstRequests: {
    FETCH_CST_DETAILS: REQUEST_NOT_STARTED,
    FETCH_AVAILABLE_CST: REQUEST_NOT_STARTED,
    ADD_CST_TO_EMITTER: REQUEST_NOT_STARTED,
    DELETE_CST: REQUEST_NOT_STARTED,
    FETCH_AVAILABLE_CST_WITH_PARAMS: REQUEST_NOT_STARTED,
  },
};

const fetchCstDetails = (state) => ({
  ...state,
  CstRequests: {
    ...state.CstRequests,
    FETCH_CST_DETAILS: REQUEST_PENDING,
  },
});

const fetchCstDetailsSuccess = (state, { payload = {} }) => ({
  ...state,
  cstDetails: payload,
  CstRequests: {
    ...state.CstRequests,
    FETCH_CST_DETAILS: REQUEST_RESOLVED,
  },
});

const fetchAvailableCst = (state) => ({
  ...state,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.FETCH_AVAILABLE_CST]: REQUEST_PENDING,
  },
});

const fetchAvailableCstSuccess = (state, { payload = [] }) => ({
  ...state,
  availableCst: payload,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.FETCH_AVAILABLE_CST]: REQUEST_RESOLVED,
  },
});

const fetchAvailableCstWithParams = (state) => ({
  ...state,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.FETCH_AVAILABLE_CST_WITH_PARAMS]: REQUEST_PENDING,
  },
});

const fetchAvailableCstWithParamsSuccess = (state, { payload = {} }) => ({
  ...state,
  availableCst: payload,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.FETCH_AVAILABLE_CST_WITH_PARAMS]: REQUEST_RESOLVED,
  },
});

const editCst = (state) => ({
  ...state,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.EDIT_CST]: REQUEST_PENDING,
  },
});

const editCstSuccess = (state, { payload = {} }) => ({
  ...state,
  cstDetails: payload,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.EDIT_CST]: REQUEST_RESOLVED,
  },
});

const deleteCst = (state) => ({
  ...state,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.DELETE_CST]: REQUEST_PENDING,
  },
});

const deleteCstSuccess = (state, { payload = {} }) => ({
  ...state,
  cstDetails: payload,
  CstRequests: {
    ...state.CstRequests,
    [CstTypes.DELETE_CST]: REQUEST_RESOLVED,
  },
});

const cstRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  CstRequests: {
    ...state.CstRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanCst = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [CstTypes.FETCH_CST_DETAILS]: fetchCstDetails,
  [CstTypes.FETCH_CST_DETAILS_SUCCESS]: fetchCstDetailsSuccess,
  [CstTypes.FETCH_AVAILABLE_CST]: fetchAvailableCst,
  [CstTypes.FETCH_AVAILABLE_CST_SUCCESS]: fetchAvailableCstSuccess,
  [CstTypes.FETCH_AVAILABLE_CST_WITH_PARAMS]: fetchAvailableCstWithParams,
  [CstTypes.FETCH_AVAILABLE_CST_WITH_PARAMS_SUCCESS]: fetchAvailableCstWithParamsSuccess,
  [CstTypes.EDIT_CST]: editCst,
  [CstTypes.EDIT_CST_SUCCESS]: editCstSuccess,
  [CstTypes.DELETE_CST]: deleteCst,
  [CstTypes.DELETE_CST_SUCCESS]: deleteCstSuccess,
  [CstTypes.CST_REQUEST_FAILURE]: cstRequestFailure,
  [CstTypes.CLEAN_CST]: cleanCst,
});
