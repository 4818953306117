import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: SubscriptionsTypes, Creators: SubscriptionsActions } = createActions({
  fetchSubscriptions: ["emitterId", "params"],
  fetchSubscriptionsSuccess: ["payload"],
  subscriptionsRequestFailure: ["error", "requestName"],
  cleanSubscriptionsState: null,
});

const INITIAL_STATE = {
  subscriptions: {
    items: [],
  },
  subscriptionsRequests: {
    [SubscriptionsTypes.FETCH_SUBSCRIPTIONS]: REQUEST_NOT_STARTED,
  },
};

const fetchSubscriptions = (state) => ({
  ...state,
  subscriptionsRequests: {
    ...state.subscriptionsRequests,
    [SubscriptionsTypes.FETCH_SUBSCRIPTIONS]: REQUEST_PENDING,
  },
});

const fetchSubscriptionsSuccess = (state, { payload }) => ({
  ...state,
  subscriptions: payload,
  subscriptionsRequests: {
    ...state.subscriptionsRequests,
    [SubscriptionsTypes.FETCH_SUBSCRIPTIONS]: REQUEST_RESOLVED,
  },
});

const subscriptionsRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  subscriptionsRequests: {
    ...state.subscriptionsRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanSubscriptionsState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [SubscriptionsTypes.FETCH_SUBSCRIPTIONS]: fetchSubscriptions,
  [SubscriptionsTypes.FETCH_SUBSCRIPTIONS_SUCCESS]: fetchSubscriptionsSuccess,
  [SubscriptionsTypes.SUBSCRIPTIONS_REQUEST_FAILURE]: subscriptionsRequestFailure,
  [SubscriptionsTypes.CLEAN_SUBSCRIPTIONS_STATE]: cleanSubscriptionsState,
});
