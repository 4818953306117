import { useSelector, useDispatch } from "react-redux";
import { LayoutActions } from "store/ducks/layout";

const useFeedback = () => {
  const { feedback } = useSelector(({ layout }) => layout);
  const dispatch = useDispatch();

  const setFeedback = (newFeedback) => dispatch(LayoutActions.setFeedback(newFeedback));

  return [feedback, setFeedback];
};

export default useFeedback;
