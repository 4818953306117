import React from "react";
import PropTypes from "prop-types";
import FeedbackIcon from "components/core/FeedbackIcon";
import Button from "components/core/Button";
import { Flex, FlexButtons } from "components/core/Grid";
import { Paragraph, TertiaryTitle, Small } from "components/core/Typography";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

const Feedback = ({
  title,
  description,
  isSuccess,
  btnText,
  buttons,
  handleClick,
  emphasis,
  paymentFlag,
}) => (
  <Flex horizontalAlign="center" verticalAlign="center">
    <TertiaryTitle>
      <strong>{title}</strong>
    </TertiaryTitle>
    <FeedbackIcon isSuccess={isSuccess} />
    <Paragraph
      textAlign="center"
      fontWeight="500"
      marginBottom="2rem"
      dangerouslySetInnerHTML={{ __html: description }}
    />
    {btnText && paymentFlag && (
      <Link to={ROUTES.landingNewUser.path}>
        <Button type="button" handleClick={handleClick}>
          {btnText}
        </Button>
      </Link>
    )}
    {btnText && paymentFlag === false && (
      <Button type="button" handleClick={handleClick}>
        {btnText}
      </Button>
    )}
    {buttons && (
      <FlexButtons direction="row" horizontalAlign="center" verticalAlign="center">
        {buttons.map((button) => (
          <Button key={button.text} type="button" handleClick={button.handleClick}>
            {button.text}
          </Button>
        ))}
      </FlexButtons>
    )}
    <Small
      customStyle={{ "margin-top": "20px" }}
      textAlign="center"
      dangerouslySetInnerHTML={{ __html: emphasis }}
    />
  </Flex>
);

export const FeedbackWithRedux = () => {
  const { show, title, description, isSuccess, btnText, handleClick } = useSelector(
    ({ layout }) => layout.feedback,
  );

  if (show) {
    return (
      <Feedback
        title={title}
        description={description}
        isSuccess={isSuccess}
        btnText={btnText}
        handleClick={handleClick}
      />
    );
  }

  return null;
};

Feedback.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
  handleClick: PropTypes.func,
  isSuccess: PropTypes.bool,
  emphasis: PropTypes.string,
  paymentFlag: PropTypes.bool,
};

Feedback.defaultProps = {
  isSuccess: true,
  paymentFlag: false,
};

export default Feedback;
