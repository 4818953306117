import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";

export const couponsSchema = Yup.object().shape({
  code: Yup.string().required(<Trans i18nKey="required.code" />),
  discount: Yup.string().required(<Trans i18nKey="required.discount" />),
  startDate: Yup.string().required(<Trans i18nKey="required.startDate" />),
  couponType: Yup.string().required(<Trans i18nKey="required.counponType" />),
});

export const couponsInitialValues = {
  code: " ",
  discount: " ",
  startDate: " ",
  couponType: "V",
};
