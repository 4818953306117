import React, { useRef, useEffect, useState } from "react";
import ProductRegister from "components/presentation/Products/register";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "components/core/NavBar";
import Button from "components/core/Button";
import { useRequest, useInfinitePagination, useParams as useParamsHooks, useDebounce } from "hooks";
import { Span } from "components/core/Typography";
import { useParams, useHistory } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import { NcmActions } from "store/ducks/ncm";
import { CstActions } from "store/ducks/cst";
import { NcmGlobalsActions } from "store/ducks/ncmGlobals";
import { LayoutActions } from "store/ducks/layout";
import { useDispatch, useSelector } from "react-redux";
import { LINKS } from "utils/constants";
import { validationSchema } from "utils/schemas/products";

const ProductRegisterWithSpinner = WithSpinner(ProductRegister);

const ProductRegisterContainer = () => {
  const { t: translate } = useTranslation();
  const { emitterId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const submitRef = useRef(null);
  const [createdProduct, setCreatedProduct] = useState("");
  const [newNcmArray, setNewNcmArray] = useState([]);
  const [newCsosnArray, setNewCsosnArray] = useState([]);
  const [newUnitArray, setNewUnitArray] = useState([]);

  const { statusOfRequests } = useSelector(({ emitter }) => emitter);
  const { availableNcm, NcmRequests } = useSelector(({ ncm }) => ncm);
  const { availableCst, CstRequests } = useSelector(({ cst }) => cst);
  const { unitMeasure, NcmGlobalsRequests } = useSelector(({ ncmGlobals }) => ncmGlobals);

  const fetchNcm = (params) => {
    dispatch(NcmActions.fetchAvailableNcmWithParams(emitterId, params));
  };

  const { page: pageNCM, setPage: setPageNCM, handleFilter } = useParamsHooks({
    callback: fetchNcm,
    defaultOrderBy: "id",
  });

  const debounceChangeNcm = useDebounce(handleFilter, 700);

  const handleSearchNcm = ({ target: { value } }) => {
    debounceChangeNcm({ search: value });
  };

  const fetchCst = (params) => {
    dispatch(CstActions.fetchAvailableCstWithParams(emitterId, params));
  };

  const { page: pageCST, setPage: setPageCST, handleFilter: handleFilterCST } = useParamsHooks({
    callback: fetchCst,
    defaultOrderBy: "id",
  });

  const debounceChangeCST = useDebounce(handleFilterCST, 700);

  const handleSearchCst = ({ target: { value } }) => {
    debounceChangeCST({ csosn: value });
  };

  const fetchUnitMeasure = (params) => {
    dispatch(NcmGlobalsActions.getUnitMeasure(params));
  };

  const {
    page: pageMeasure,
    setPage: setPageMeasure,
    handleFilter: handleFilterMeasure,
  } = useParamsHooks({
    callback: fetchUnitMeasure,
    defaultOrderBy: "id",
  });

  const debounceChangeMeasure = useDebounce(handleFilterMeasure, 700);

  const handleSearchUnitMeasure = ({ target: { value } }) => {
    debounceChangeMeasure({ search: value });
  };

  const handleConfirm = ({ name, ncmName, csosName, ...data }) => {
    const payload = { ...data };
    payload.name = name;
    dispatch(EmitterActions.addProductsToEmitter(emitterId, payload));
    setCreatedProduct(name);
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans
              i18nKey="productAdd"
              values={{ name: createdProduct }}
              components={{ bold: <strong /> }}
            />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(EmitterActions.cleanRequest());
    history.push(LINKS.productsList(emitterId));
  };

  useRequest({
    request: statusOfRequests.ADD_PRODUCTS_TO_EMITTER,
    onResolved,
  });

  useEffect(() => {
    const newAvailableNcm = availableNcm?.items
      ?.map(({ code, name, id }) => ({
        id,
        code,
        name: `${code} - ${name}`,
      }))
      .filter((x) => {
        return x.code !== undefined;
      });

    if (pageNCM === 1) {
      setNewNcmArray(newAvailableNcm);
    } else if (availableNcm?.items?.length) {
      const exist = newNcmArray.find((newNCM) =>
        newAvailableNcm.find((item) => item.code === newNCM.code),
      );
      if (!exist) setNewNcmArray((prevItems) => [...new Set([...prevItems, ...newAvailableNcm])]);
      else {
        const format = newNcmArray.map((newNCM) => {
          const x = newAvailableNcm.find((item) => item.code === newNCM.code);

          if (!x) return newNCM;
          return x;
        });

        setNewNcmArray(() => [...new Set([...format])]);
      }
    }
  }, [availableNcm?.items, pageNCM]);

  useEffect(() => {
    const csosnArray = availableCst?.items?.map(({ csosn }) => ({
      ...csosn,
    }));
    const newAvaliableCSOSN = csosnArray
      ?.map(({ code, id, name }) => ({
        id,
        code,
        name: `${code} - ${name}`,
      }))
      .filter((x) => {
        return x.code !== undefined;
      });

    if (pageCST === 1) {
      setNewCsosnArray(newAvaliableCSOSN);
    } else if (availableCst?.items?.length) {
      const exist = newCsosnArray.find((newCSO) =>
        newAvaliableCSOSN.find((item) => item.code === newCSO.code),
      );
      if (!exist)
        setNewCsosnArray((prevItems) => [...new Set([...prevItems, ...newAvaliableCSOSN])]);
      else {
        const format = newCsosnArray.map((newCSO) => {
          const x = newAvaliableCSOSN.find((item) => item.code === newCSO.code);

          if (!x) return newCSO;
          return x;
        });

        setNewCsosnArray(() => [...new Set([...format])]);
      }
    }
  }, [availableCst?.items, pageCST]);

  const lastItemRefNCM = useInfinitePagination(
    NcmRequests.FETCH_AVAILABLE_NCM_WITH_PARAMS,
    pageNCM < availableNcm?.totalPages,
    setPageNCM,
  );

  const lastItemRefCTS = useInfinitePagination(
    CstRequests.FETCH_AVAILABLE_CST_WITH_PARAMS,
    pageCST < availableCst?.totalPages,
    setPageCST,
  );

  const lastItemRefMeasure = useInfinitePagination(
    NcmGlobalsRequests.getUnitMeasure,
    pageMeasure < unitMeasure?.totalPages,
    setPageMeasure,
  );

  useEffect(() => {
    if (pageMeasure === 1) {
      setNewUnitArray(unitMeasure?.items);
    } else if (unitMeasure?.items?.length) {
      const exist = newUnitArray.find((newUnit) =>
        unitMeasure?.items?.find((item) => item.code === newUnit.code),
      );
      if (!exist)
        setNewUnitArray((prevItems) => [...new Set([...prevItems, ...unitMeasure?.items])]);
      else {
        const format = newUnitArray.map((newUnit) => {
          const x = unitMeasure?.items?.find((item) => item.code === newUnit.code);

          if (!x) return newUnit;
          return x;
        });

        setNewUnitArray(() => [...new Set([...format])]);
      }
    }
  }, [pageMeasure, unitMeasure?.items]);

  return (
    <>
      <NavBar title={translate("productRegister")}>
        <Button url={`${LINKS.productsList(emitterId)}`} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("add")}</Button>
      </NavBar>
      <ProductRegisterWithSpinner
        handleConfirm={handleConfirm}
        handleSearchNcm={handleSearchNcm}
        handleSearchCst={handleSearchCst}
        ncmItems={newNcmArray || []}
        isLoadingNcmItems={isPending(NcmRequests.FETCH_AVAILABLE_NCM)}
        cstItems={newCsosnArray || []}
        isLoadingCstItems={isPending(CstRequests.FETCH_AVAILABLE_CST)}
        submitRef={submitRef}
        validationSchema={validationSchema}
        unitMeasure={newUnitArray}
        handleSearchUnitMeasure={handleSearchUnitMeasure}
        isLoadingUnitMeasureItems={isPending(NcmGlobalsRequests.getUnitMeasure)}
        lastItemRefNCM={lastItemRefNCM}
        lastItemRefCTS={lastItemRefCTS}
        lastItemRefMeasure={lastItemRefMeasure}
      />
    </>
  );
};

export default ProductRegisterContainer;
