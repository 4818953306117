import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(-20deg, var(--color-primary), var(--color-secondary));
  display: flex;
  justify-content: flex-end;
  height: 100%;
  min-height: 100vh;
  position: relative;
`;

export const PortalWrapper = styled.div`
  background-color: var(--color-white-1);
  border-top-left-radius: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  position: relative;
  padding: 8.8rem 0 0;
  max-height: 100vh;
  width: calc(100% - var(--sidebar-width));
  transition: filter 0.3s;

  ${({ activeBlur }) => activeBlur && "filter: brightness(0.65)"}
  ${({ activeBlur, isChatPage }) =>
    activeBlur && isChatPage && "padding: 0; overflow: hidden;filter: brightness(0.65);"}
  ${({ isChatPage }) => isChatPage && "padding: 0; overflow: hidden;"}
`;
