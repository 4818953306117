import React from "react";
import PropTypes from "prop-types";
import * as S from "./styled";

const Public = ({ routes: Routes }) => (
  <S.Container>
    <S.LogoWrapper>
      <S.Logo />
    </S.LogoWrapper>
    <S.FormWrapper>
      <Routes />
    </S.FormWrapper>
  </S.Container>
);

Public.propTypes = {
  routes: PropTypes.func.isRequired,
};

export default Public;
