import styled from "styled-components";
import media from "styled-media-query";

export const SVGWrapper = styled.div`
  text-align: center;
  height: auto;
  margin-bottom: 4rem;
  width: 100%;

  ${media.lessThan("small")`
    margin-bottom: 2.4rem;

    &>svg {
      height: auto;
      width: 7.2rem;
    }
  `}
`;
