import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useFinancialSubscription, useRequest } from "hooks";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import SubscriptionsNextBillings from "components/presentation/Financial/Subscriptions/NextBillings";
import { ReactComponent as IconExport } from "assets/icons/icon-download.svg";
import WithSuspense from "components/core/WithSuspense";
import Button from "components/core/Button";
import { REQUEST_NOT_STARTED } from "utils/constants";
import moment from "moment";
import SelectForm from "components/core/SelectForm";
import { mapToSelectOptions } from "helpers";
import { RowFlex } from "components/core/Grid";

const Filter = React.lazy(() => import("containers/Financial/Subscriptions/NextBillings/filter"));

const SubscriptionsNextBillingsWithSpinner = WithSpinner(SubscriptionsNextBillings);
const FilterWithSuspense = WithSuspense(Filter);

const SubscriptionsNextBillingsContainer = () => {
  const [optionsDates, setOptionsDates] = useState([]);
  const [billingDate, setBillingDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));
  const [planCode, setPlanCode] = useState("all");
  const [showFilters, setShowFilters] = useState(false);
  const { t: translate } = useTranslation();
  const {
    getNextSubscriptionBillings,
    nextSubscriptionBillings: { items, total, totalPages },
    exportSubscriptions,
    requestStatus,
    selectedItems,
    handleOnResolved,
    handleOnRejected,
    hasOneItemSelected,
    hasSelectedItems,
    clearSelection,
    validationErrors,
  } = useFinancialSubscription();
  const [currentRequestStatus, setCurrentRequestStatus] = useState(
    requestStatus.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS,
  );
  const defaultParams = {
    order: "asc",
    page: 1,
    perPage: 10,
    sort: "nextBillingDate",
    billingDate,
  };
  const fetchNextSubscriptionBillings = (params) => {
    getNextSubscriptionBillings({
      ...defaultParams,
      ...params,
    });
  };
  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    handleChange,
    toggleAll,
    listChecked,
    everyoneIsChecked,
    SmartFilterButton,
    setFilterParams,
  } = useParams({
    callback: fetchNextSubscriptionBillings,
    defaultOrderBy: "nextBillingDate",
    defaultOrder: "asc",
  });

  useEffect(() => {
    const dates = [];
    for (let i = 1; i < 31; i++) {
      dates.push({
        value: moment().add(i, "days").format("YYYY-MM-DD"),
        text: moment().add(i, "days").format("DD/MM/YYYY"),
      });
    }
    setOptionsDates(dates);
  }, []);

  useEffect(() => {
    if (planCode === "all") {
      const params = {
        ...defaultParams,
        order,
        sort: orderBy,
        billingDate,
      };
      setFilterParams(params);
    } else {
      const params = {
        ...defaultParams,
        order,
        sort: orderBy,
        billingDate,
        planCode,
      };
      setFilterParams(params);
    }
  }, [billingDate, planCode, orderBy, order]);

  const handleExportSubscriptions = (all = true) => {
    if (all) {
      exportSubscriptions();
    } else {
      exportSubscriptions({ subscriptions: selectedItems.map(({ id }) => id) });
    }
  };

  useEffect(() => {
    if (requestStatus.EXPORT_SUBSCRIPTIONS !== REQUEST_NOT_STARTED) {
      setCurrentRequestStatus(requestStatus.EXPORT_SUBSCRIPTIONS);
    }
  }, [requestStatus.EXPORT_SUBSCRIPTIONS]);

  useRequest({
    request: requestStatus.EXPORT_SUBSCRIPTIONS,
    onResolved: () => handleOnResolved("dataExported"),
    onRejected: () => handleOnRejected("dataExportedError", validationErrors()),
  });

  useEffect(() => {
    setCurrentRequestStatus(requestStatus.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS);
  }, [requestStatus.GET_FINANCIAL_NEXT_SUBSCRIPTION_BILLINGS]);

  const refresh = () => {
    const params = { order, page, perPage: 10, sort: orderBy, billingDate };
    if (planCode !== "all") {
      params.planCode = planCode;
    }
    fetchNextSubscriptionBillings(params);
  };

  return (
    <>
      <NavBar
        title={`${translate("Subscriptions")} - ${translate("Next Billings")}`}
        actions={
          <RowFlex>
            <SelectForm
              name="billingDate"
              options={mapToSelectOptions(optionsDates, "value", "text")}
              marginRight={"2.4rem"}
              marginBottom={"0"}
              handleChange={({ target: { value } }) => setBillingDate(value)}
            />
            <SelectForm
              name="planCode"
              options={mapToSelectOptions(
                [
                  { value: "all", text: "Todos os planos" },
                  { value: "mensal", text: "Plano Mensal" },
                  { value: "anual", text: "Plano Anual" },
                  { value: "bienal", text: "Plano Bienal" },
                ],
                "value",
                "text",
              )}
              marginRight={"2.4rem"}
              marginBottom={"0"}
              handleChange={({ target: { value } }) => setPlanCode(value)}
            />
          </RowFlex>
        }
        paddingBottom={"1rem"}
      >
        <Button handleClick={refresh}>{translate("Refresh")}</Button>
        {(hasOneItemSelected || hasSelectedItems) && (
          <>
            <Button handleClick={clearSelection}>
              <span>{translate("Clear selection")}</span>
            </Button>
            {/* <Button variant="outline" handleClick={() => handleExportSubscriptions(false)}>
              <IconExport />
              <span>{translate("Export selected")}</span>
            </Button> */}
          </>
        )}
        {/* <Button handleClick={() => handleExportSubscriptions(true)}>
          <IconExport />
          <span>{translate("Export all")}</span>
        </Button> */}
        {/* {!hasOneItemSelected && !hasSelectedItems && (
          <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
        )} */}
      </NavBar>
      <SubscriptionsNextBillingsWithSpinner
        requestStatus={currentRequestStatus}
        subscriptions={listChecked || items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        handleChange={handleChange}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
          setFilterParams,
          billingDate,
          planCode,
        }}
        order={order}
        orderBy={orderBy}
        toggleAll={toggleAll}
        everyoneIsChecked={everyoneIsChecked}
      />
      {/* <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      /> */}
    </>
  );
};

export default SubscriptionsNextBillingsContainer;
