import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NavBar from "components/core/NavBar";
import Button from "components/core/Button";
import EditMEI from "components/presentation/Taxes/Edit/MEI";
import { TaxesActions } from "store/ducks/taxes";
import Modal from "components/core/Modal";
import { Span } from "components/core/Typography";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import { urls, taxesEnum, REQUEST_RESOLVED } from "utils/constants";
import { parseMoneyToFloat, formatNumberToReal } from "helpers";
import useTaxes from "hooks/useTaxes";
import { LayoutActions } from "store/ducks/layout";

const EditMEIWithSpinner = WithSpinner(EditMEI);

const EditMEIContainer = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [newTaxes, setNewTaxes] = useState(null);
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const { TaxesRequests } = useSelector(({ taxes }) => taxes);
  const getTaxByEnum = useTaxes()[1];
  const history = useHistory();

  const handleSubmit = ({
    meiOpeningTax,
    meiTaxCalc,
    meiMinimumCalcPrice,
    meiMaximumMonthBilling,
    meiAnnualPrice,
    meiBiennialPrice,
    allowInstallments,
    applyInterest,
    maxInstallments,
    minInstallmentAmount,
  }) => {
    setShowConfirm(true);
    const data = {
      meiOpeningTax: parseMoneyToFloat(meiOpeningTax),
      meiMinimumCalcPrice: parseMoneyToFloat(meiMinimumCalcPrice),
      meiMaximumMonthBilling: parseMoneyToFloat(meiMaximumMonthBilling),
      meiAnnualPrice: parseMoneyToFloat(meiAnnualPrice),
      meiBiennialPrice: parseMoneyToFloat(meiBiennialPrice),
      meiTaxCalc: parseMoneyToFloat(meiTaxCalc) / 100,
      applyInterest: applyInterest === "true",
      allowInstallments: allowInstallments === "true",
      maxInstallments: Number(maxInstallments),
      minInstallmentAmount: Number.isNaN(parseMoneyToFloat(minInstallmentAmount))
        ? 0
        : parseMoneyToFloat(minInstallmentAmount),
    };
    setNewTaxes(data);
  };

  const dispatchNewTaxes = () => {
    dispatch(TaxesActions.editMEITaxes(newTaxes));
  };

  useEffect(() => {
    if (TaxesRequests.editMEITaxes === REQUEST_RESOLVED) {
      dispatch(
        LayoutActions.showConfirmationModal({
          content: <Span>Alterações salvas com sucesso.</Span>,
          type: "success",
        }),
      );
      dispatch(TaxesActions.cleanState());
      history.push(urls.ROUTES.taxes.path);
    }
  }, [TaxesRequests.editMEITaxes]);

  return (
    <>
      <NavBar title="Editar Planos">
        <Button url={urls.ROUTES.taxes.path} variant="outline">
          Cancelar
        </Button>
        <Button handleClick={() => submitRef.current.click()}>Salvar</Button>
      </NavBar>
      <EditMEIWithSpinner
        isLoading={isPending(TaxesRequests.editMEITaxes)}
        requestStatus={TaxesRequests.fetchTaxes}
        submitRef={submitRef}
        onSubmit={handleSubmit}
        defaultValues={{
          applyInterest: getTaxByEnum(taxesEnum.meiOpeningTax)?.applyInterest || false,
          allowInstallments: getTaxByEnum(taxesEnum.meiOpeningTax)?.allowInstallments || true,
          maxInstallments: getTaxByEnum(taxesEnum.meiOpeningTax)?.maxInstallments || 12,
          minInstallmentAmount: formatNumberToReal(
            getTaxByEnum(taxesEnum.meiOpeningTax)?.minInstallmentAmount || 5,
          ),
          meiOpeningTax: formatNumberToReal(getTaxByEnum(taxesEnum.meiOpeningTax)?.price),
          meiMaximumMonthBilling: formatNumberToReal(
            getTaxByEnum(taxesEnum.meiMaximumMonthBilling)?.price,
          ),
          meiMinimumCalcPrice: formatNumberToReal(
            getTaxByEnum(taxesEnum.meiMinimumCalcPrice)?.price,
          ),
          meiAnnualPrice: formatNumberToReal(
            getTaxByEnum(taxesEnum.meiMinimumCalcPrice)?.annualPrice,
          ),
          meiBiennialPrice: formatNumberToReal(
            getTaxByEnum(taxesEnum.meiMinimumCalcPrice)?.biennialPrice,
          ),
          meiTaxCalc: (getTaxByEnum(taxesEnum.meiTaxCalc)?.value * 100)
            .toString()
            .replace(".", ","),
        }}
      />
      <Modal
        showModal={showConfirm}
        onCloseModal={() => setShowConfirm(false)}
        onConfirmModal={dispatchNewTaxes}
        title="Editar taxas"
        width="49rem"
      >
        <Span>
          <strong>Atenção</strong>: As alterações nas taxas serão aplicadas à todos os planos.
        </Span>
      </Modal>
    </>
  );
};

export default EditMEIContainer;
