import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { useRequest, useInfinitePagination, useParams as useParamsHooks, useDebounce } from "hooks";
import { NcmActions } from "store/ducks/ncm";
import { NcmGlobalsActions } from "store/ducks/ncmGlobals";
import { LayoutActions } from "store/ducks/layout";
import { formatDateToParam } from "helpers/format";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import NcmGlobalsRegister from "components/presentation/NcmGlobals/Register/NewNcm";
import { Span } from "components/core/Typography";
import { ncmGlobalsSchema, ncmGlobalsInitialValues } from "utils/schemas/ncmGlobals";
import { LINKS } from "utils/constants";

const NcmGlobalsRegisterWithSpinner = WithSpinner(NcmGlobalsRegister);

const NcmGlobalsRegisterContainer = () => {
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [createdNcmGlobalsname, setCreatedNcmGlobalsname] = useState();
  const [newNcmArray, setNewNcmArray] = useState([]);
  const [newUnitArray, setNewUnitArray] = useState([]);
  const { availableNcm, NcmRequests } = useSelector(({ ncm }) => ncm);
  const { unitMeasure, NcmGlobalsRequests } = useSelector(({ ncmGlobals }) => ncmGlobals);

  const handleSubmit = ({ name, fieldname, ...rest }) => {
    const payload = { ...rest };
    const newDate = formatDateToParam(payload?.startTerm);
    payload.startTerm = newDate;
    payload.name = name;
    dispatch(NcmGlobalsActions.createNcmGlobals(payload));
    setCreatedNcmGlobalsname(name);
  };

  const fetchNcm = (params) => {
    dispatch(NcmActions.fetchAvailableNcm(params));
  };

  const { page: pageNCM, setPage: setPageNCM, handleFilter } = useParamsHooks({
    callback: fetchNcm,
    defaultOrderBy: "id",
  });

  const debounceChangeNcm = useDebounce(handleFilter, 700);

  const handleSearchNcm = ({ target: { value } }) => {
    debounceChangeNcm({ search: value });
  };

  const lastItemRefNCM = useInfinitePagination(
    NcmRequests.FETCH_AVAILABLE_NCM_WITH_PARAMS,
    pageNCM < availableNcm?.totalPages,
    setPageNCM,
  );

  const fetchUnitMeasure = (params) => {
    dispatch(NcmGlobalsActions.getUnitMeasure(params));
  };

  const {
    page: pageMeasure,
    setPage: setPageMeasure,
    handleFilter: handleFilterMeasure,
  } = useParamsHooks({
    callback: fetchUnitMeasure,
    defaultOrderBy: "id",
  });

  const debounceChangeMeasure = useDebounce(handleFilterMeasure, 700);

  const handleSearchUnitMeasure = ({ target: { value } }) => {
    debounceChangeMeasure({ search: value });
  };

  const lastItemRefMeasure = useInfinitePagination(
    NcmGlobalsRequests.getUnitMeasure,
    pageMeasure < unitMeasure?.totalPages,
    setPageMeasure,
  );

  useEffect(() => {
    if (pageMeasure === 1) {
      setNewUnitArray(unitMeasure?.items);
    } else if (unitMeasure?.items?.length) {
      const exist = newUnitArray.find((newUnit) =>
        unitMeasure?.items?.find((item) => item.code === newUnit.code),
      );
      if (!exist)
        setNewUnitArray((prevItems) => [...new Set([...prevItems, ...unitMeasure?.items])]);
      else {
        const format = newUnitArray.map((newUnit) => {
          const x = unitMeasure?.items?.find((item) => item.code === newUnit.code);

          if (!x) return newUnit;
          return x;
        });

        setNewUnitArray(() => [...new Set([...format])]);
      }
    }
  }, [pageMeasure, unitMeasure?.items]);

  useEffect(() => {
    const newAvailableNcm = availableNcm?.items
      ?.map(({ code, name, id: idN }) => ({
        id: idN,
        code,
        name: `${code} - ${name}`,
      }))
      .filter((x) => {
        return x.code !== undefined;
      });

    if (pageNCM === 1) {
      setNewNcmArray(newAvailableNcm);
    } else if (availableNcm?.items?.length) {
      const exist = newNcmArray.find((newNCM) =>
        newAvailableNcm.find((item) => item.code === newNCM.code),
      );
      if (!exist) setNewNcmArray((prevItems) => [...new Set([...prevItems, ...newAvailableNcm])]);
      else {
        const format = newNcmArray.map((newNCM) => {
          const x = newAvailableNcm.find((item) => item.code === newNCM.code);

          if (!x) return newNCM;
          return x;
        });

        setNewNcmArray(() => [...new Set([...format])]);
      }
    }
  }, [availableNcm?.items, pageNCM]);

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans
              i18nKey="ncmGlobalsCreated"
              values={{ name: createdNcmGlobalsname }}
              components={{ bold: <strong /> }}
            />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(NcmGlobalsActions.cleanState());

    history.push(LINKS.ncmGlobalsList);
  };

  useRequest({
    request: NcmGlobalsRequests.createNcmGlobals,
    onResolved,
  });
  return (
    <NcmGlobalsRegisterWithSpinner
      isLoading={isPending(NcmGlobalsRequests.createNcmGlobals)}
      handleClick={() => submitRef.current.click()}
      handleNcmGlobalsRegister={handleSubmit}
      defaultValues={ncmGlobalsInitialValues}
      validationSchema={ncmGlobalsSchema}
      isLoadingNcmItems={isPending(NcmRequests.FETCH_AVAILABLE_NCM)}
      ncmItems={newNcmArray || []}
      UnitMeasure={newUnitArray}
      handleSearch={handleSearchNcm}
      submitRef={submitRef}
      lastItemRefMeasure={lastItemRefMeasure}
      lastItemRefNCM={lastItemRefNCM}
      handleSearchUnitMeasure={handleSearchUnitMeasure}
      isLoadingUnitMeasureItems={isPending(NcmGlobalsRequests.getUnitMeasure)}
    />
  );
};

export default NcmGlobalsRegisterContainer;
