import React from "react";
import PropTypes from "prop-types";
import { Subtitle } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import Button from "components/core/Button";
import * as S from "./styled";

export const ConstitutedEnterprise = ({ onBack, setIsConstituted, setStep }) => {
  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="22%" />
        </Container>
        <Subtitle mb="48px">Selecione uma das opções abaixo:</Subtitle>
        <Row horizontalAlign="center">
          <Button
            width="344px"
            height="80px"
            variant="outline"
            handleClick={() => {
              setIsConstituted(true);
              setStep((oldStep) => oldStep + 1);
            }}
          >
            <p>
              Possuo uma empresa <S.SlimText>e gostaria de </S.SlimText>contratar
              <S.SlimText> os serviços do Obvia</S.SlimText>
            </p>
          </Button>
        </Row>
        <Row horizontalAlign="center">
          <Button
            width="344px"
            height="80px"
            variant="outline"
            handleClick={() => {
              setIsConstituted(false);
              setStep((oldStep) => oldStep + 1);
            }}
          >
            <p>
              Possuo uma empresa,<S.SlimText> mas gostaria de </S.SlimText>criar uma nova
              <S.SlimText> à partir do Obvia</S.SlimText>
            </p>
          </Button>
        </Row>
        <Row horizontalAlign="center">
          <Button
            width="344px"
            height="80px"
            variant="outline"
            handleClick={() => {
              setIsConstituted(false);
              setStep((oldStep) => oldStep + 1);
            }}
          >
            <p>
              Ainda não possuo <S.SlimText>empresa e gostaria de</S.SlimText> criar uma
              <S.SlimText> à partir do Obvia</S.SlimText>
            </p>
          </Button>
        </Row>
        <Button handleClick={onBack} variant="outline">
          Voltar
        </Button>
      </Flex>
    </S.RegisterContainer>
  );
};

ConstitutedEnterprise.propTypes = {
  onBack: PropTypes.func.isRequired,
  setIsConstituted: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default ConstitutedEnterprise;
