import styled from "styled-components";
import { ReactComponent as DefaultIcon } from "assets/icons/icon-pin.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/icon-download-thin.svg";

export const IconDelete = styled(DeleteIcon)`
  height: 22px;
  width: 22px;
`;

export const IconDefault = styled(DefaultIcon)`
  height: 22px;
  margin-right: 10px;
  width: 22px;
  color: #007bff;
`;

export const IconDownload = styled(DownloadIcon)`
  height: 22px;
  width: 22px;
  margin-right: 10px;
  color: #007bff;
`;
