import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: CfopTypes, Creators: CfopActions } = createActions({
  fetchCfopDetails: ["id"],
  fetchCfopDetailsSuccess: ["payload"],
  fetchAvailableCfop: ["params"],
  fetchAvailableCfopSuccess: ["payload"],
  editCfop: ["id", "payload"],
  editCfopSuccess: ["payload"],
  deleteCfop: ["payload"],
  deleteCfopSuccess: ["payload"],
  cfopRequestFailure: ["error", "originalType"],
  cleanCfop: null,
});

const INITIAL_STATE = {
  cfopDetails: {},
  availableCfop: [],
  CfopRequests: {
    FETCH_CFOP_DETAILS: REQUEST_NOT_STARTED,
    FETCH_AVAILABLE_CFOP: REQUEST_NOT_STARTED,
    ADD_CFOP_TO_EMITTER: REQUEST_NOT_STARTED,
    DELETE_CFOP: REQUEST_NOT_STARTED,
  },
};

const fetchCfopDetails = (state) => ({
  ...state,
  CfopRequests: {
    ...state.CfopRequests,
    FETCH_CFOP_DETAILS: REQUEST_PENDING,
  },
});

const fetchCfopDetailsSuccess = (state, { payload = {} }) => ({
  ...state,
  cfopDetails: payload,
  CfopRequests: {
    ...state.CfopRequests,
    FETCH_CFOP_DETAILS: REQUEST_RESOLVED,
  },
});

const fetchAvailableCfop = (state) => ({
  ...state,
  CfopRequests: {
    ...state.CfopRequests,
    [CfopTypes.FETCH_AVAILABLE_CFOP]: REQUEST_PENDING,
  },
});

const fetchAvailableCfopSuccess = (state, { payload = [] }) => ({
  ...state,
  availableCfop: payload,
  CfopRequests: {
    ...state.CfopRequests,
    [CfopTypes.FETCH_AVAILABLE_CFOP]: REQUEST_RESOLVED,
  },
});

const editCfop = (state) => ({
  ...state,
  CfopRequests: {
    ...state.CfopRequests,
    [CfopTypes.EDIT_CFOP]: REQUEST_PENDING,
  },
});

const editCfopSuccess = (state, { payload = {} }) => ({
  ...state,
  cfopDetails: payload,
  CfopRequests: {
    ...state.CfopRequests,
    [CfopTypes.EDIT_CFOP]: REQUEST_RESOLVED,
  },
});

const deleteCfop = (state) => ({
  ...state,
  CfopRequests: {
    ...state.CfopRequests,
    [CfopTypes.DELETE_CFOP]: REQUEST_PENDING,
  },
});

const deleteCfopSuccess = (state, { payload = {} }) => ({
  ...state,
  cfopDetails: payload,
  CfopRequests: {
    ...state.CfopRequests,
    [CfopTypes.DELETE_CFOP]: REQUEST_RESOLVED,
  },
});

const cfopRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  CfopRequests: {
    ...state.CfopRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanCfop = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [CfopTypes.FETCH_CFOP_DETAILS]: fetchCfopDetails,
  [CfopTypes.FETCH_CFOP_DETAILS_SUCCESS]: fetchCfopDetailsSuccess,
  [CfopTypes.FETCH_AVAILABLE_CFOP]: fetchAvailableCfop,
  [CfopTypes.FETCH_AVAILABLE_CFOP_SUCCESS]: fetchAvailableCfopSuccess,
  [CfopTypes.EDIT_CFOP]: editCfop,
  [CfopTypes.EDIT_CFOP_SUCCESS]: editCfopSuccess,
  [CfopTypes.DELETE_CFOP]: deleteCfop,
  [CfopTypes.DELETE_CFOP_SUCCESS]: deleteCfopSuccess,
  [CfopTypes.CFOP_REQUEST_FAILURE]: cfopRequestFailure,
  [CfopTypes.CLEAN_CFOP]: cleanCfop,
});
