import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CouponsActions } from "store/ducks/coupons";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import CouponsDetails from "components/presentation/Coupons/Details";
import { urls } from "utils/constants";

const CouponsDetailsWithSpinner = WithSpinner(CouponsDetails);

const CouponsDetailsContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const { couponsDetails, CouponsRequests } = useSelector(({ coupons }) => coupons);
  const [status, setStatus] = useState();
  const dispatchGetCoupons = useCallback(() => dispatch(CouponsActions.getCouponsById(id)), [
    dispatch,
    id,
  ]);

  useEffect(() => {
    dispatchGetCoupons();
  }, [dispatchGetCoupons]);

  useEffect(() => {
    if (couponsDetails) {
      setStatus(couponsDetails.status);
    }
  }, [couponsDetails]);

  return (
    <>
      <NavBar title={translate("coupons")}>
        {status?.name === "Agendado" ? (
          <Button url={urls.LINKS.couponsEdit(id)}>{translate("edit")}</Button>
        ) : null}
      </NavBar>
      <CouponsDetailsWithSpinner
        requestStatus={CouponsRequests.getCouponsById}
        coupons={couponsDetails}
      />
    </>
  );
};

export default CouponsDetailsContainer;
