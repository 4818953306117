import { useEffect, useRef, useState } from "react";
import { REQUEST_RESOLVED, REQUEST_REJECTED } from "utils/constants/request";

const useRequest = ({ request, onResolved, onRejected }) => {
  const [requestStatus, setRequestStatus] = useState(request);
  const onResolvedRef = useRef(onResolved);
  const onRejectedRef = useRef(onRejected);

  useEffect(() => {
    setRequestStatus(request);
  }, [request]);

  useEffect(() => {
    onResolvedRef.current = onResolved;
  }, [onResolved]);

  useEffect(() => {
    onRejectedRef.current = onRejected;
  }, [onRejected]);

  useEffect(() => {
    if (requestStatus === REQUEST_RESOLVED && onResolvedRef.current) {
      onResolvedRef.current();
    }
  }, [requestStatus]);

  useEffect(() => {
    if (requestStatus === REQUEST_REJECTED && onRejectedRef.current) {
      onRejectedRef.current();
    }
  }, [requestStatus]);
};

export default useRequest;
