import React from "react";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Subtitle } from "components/core/Typography";
import { Container, Columns } from "components/core/Grid";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import useRoleLabel from "hooks/useRoleLabel";

const UserDetails = ({ user: { name, active, cpf, email, idRole, phoneNumber, city, crc } }) => {
  const [getRoleLabel] = useRoleLabel();

  return (
    <Container>
      <Subtitle>Perfil</Subtitle>
      <Columns columns="2">
        <CardInfo label="Nome" info={name} />
        <CardInfo
          label="Status"
          info={
            <>
              <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
              {active ? "Ativo" : "Inativo"}
            </>
          }
        />
      </Columns>
      <CardInfo label="CPF" info={cpf} />
      <CardInfo label="E-mail" info={email} />
      <CardInfo label="Perfil" info={getRoleLabel(idRole)} />
      <CardInfo label="Telefone" info={phoneNumber} />
      <CardInfo label="Cidade" info={city} />
      {getRoleLabel(idRole) !== "Marketing" && getRoleLabel(idRole) !== "Administrador" && (
        <CardInfo label="CRC" info={crc} />
      )}
    </Container>
  );
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserDetails;
