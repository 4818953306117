import styled, { css } from "styled-components";
import media from "styled-media-query";
import { ReactComponent as IconEye } from "assets/icons/icon-eye.svg";
import { ReactComponent as Error } from "assets/svg/error.svg";

export const IconEyes = styled(IconEye)`
  position: absolute;
  top: 3.2rem;
  right: 0.8rem;
  cursor: pointer;
  color: var(--color-black-2);
  ${({ color }) =>
    color &&
    css`
      color: var(--color-secondary);
    `};
`;

export const IconCheck = styled(Error)`
  color: var(--color-white-1);
`;

export const SpinnerSmall = styled.span`
  background: var(--color-white-1);
  border: 3px solid var(--color-secondary);
  border-radius: 50%;
  border-top-color: var(--color-white-1);
  content: "";
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  animation: spin 1s ease-in-out infinite;
`;

export const RegisterContainer = styled.div.attrs(({ large }) => ({
  widthHuge: large ? "106.6rem" : "48rem",
  widthLarge: large ? "72.0rem" : "48rem",
  maxWidth: large ? "unset" : "48rem",
}))`
  width: ${({ widthHuge }) => widthHuge};
  max-width: ${({ maxWidth }) => maxWidth};
  padding: var(--space-lg);
  margin: var(--space-xlg) auto;
  border: 1px solid var(--color-white-3);
  border-radius: var(--space-xs);
  ${media.lessThan("large")`
    width: ${({ widthLarge }) => widthLarge};
    max-width: ${({ maxWidth }) => maxWidth};
    padding: var(--space);
  `}
  ${media.lessThan("medium")`
    width: auto;
    max-width: 48rem;
  `}
  ${media.lessThan("small")`
    margin: 0;
    border: none;
  `}
`;

export const BgRegisterComplete = styled.div`
  min-height: calc(100vh - 5.7rem);
`;

export const RegisterComplete = styled.div.attrs(({ large }) => ({
  widthHuge: large ? "106.6rem" : "48rem",
  widthLarge: large ? "72.0rem" : "48rem",
  maxWidth: large ? "unset" : "48rem",
}))`
  width: ${({ widthHuge }) => widthHuge};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 17.8rem auto 0;
  ${media.lessThan("large")`
    width: ${({ widthLarge }) => widthLarge};
    max-width: ${({ maxWidth }) => maxWidth};
  `}
  ${media.lessThan("medium")`
    width: auto;
    max-width: 48rem;
  `}
  ${media.lessThan("small")`
    margin: 0;
    border: none;
  `}
`;

export const RegisterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`;

export const CardPlans = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: var(--space-md) var(--space);
  max-width: 32rem;
  margin: var(--space-xlg) var(--space-md);
  border-radius: var(--space-xs);
  box-shadow: 0 0 24px rgb(0 0 0 /0.16);
  overflow: hidden;
`;

export const DiscountRange = styled.span`
  position: absolute;
  top: var(--space);
  right: -2.4rem;
  background-color: var(--color-danger);
  color: var(--color-white-1);
  transform: rotate(45deg);
  width: 12rem;
  padding: var(--space-xxs) 0;
  text-align: center;
`;

export const StatusBar = styled.span.attrs(({ porcent }) => ({
  porcent: porcent || "0",
}))`
  width: ${({ porcent }) => porcent};
  height: var(--space-xs);
  margin: auto;
  border-radius: var(--space-xs);
  background-image: linear-gradient(to right, var(--color-secondary), var(--color-primary));
`;

export const SlimText = styled.span`
  font-weight: normal;
`;

export const ButtonRemoveCupon = styled.button`
  cursor: pointer;
  color: red;
`;
