import { takeLatest, put } from "redux-saga/effects";
import { FinancialTypes, FinancialActions } from "../ducks/financial";
import { GET, POST } from "utils/constants/verbs";
import api from "services/api";

function* getGlobalSetting({ id, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/financial/global-settings/${id}`,
    });
    yield put(FinancialActions.getGlobalSettingSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getGlobalSettings({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/financial/global-settings`,
      params,
    });
    yield put(FinancialActions.getGlobalSettingsSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getInvoices({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/financial/invoices`,
      params,
    });
    yield put(FinancialActions.getInvoicesSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getFinancialInvoicesToCharge({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/invoices/to-charge`,
      params,
    });
    yield put(FinancialActions.getFinancialInvoicesToChargeSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getFinancialInvoiceItems({ invoiceId, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/invoices/${invoiceId}/items/`,
    });
    yield put(FinancialActions.getFinancialInvoiceItemsSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialInvoices({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/invoices/`,
      params,
    });
    yield put(FinancialActions.getFinancialInvoicesSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error?.response?.data || error, type));
  }
}

function* getPlanInvoice({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/financial/invoice-by-new-plan`,
      data: params,
    });
    yield put(FinancialActions.getPlanInvoiceSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getInvoiceStatuses({ type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/financial/invoices/statuses`,
    });
    yield put(FinancialActions.getInvoiceStatusesSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getPaymentGatewayInfo({ id, infoType, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/financial/payment-gateway/${id}/info/${infoType}/`,
    });
    yield put(FinancialActions.getPaymentGatewayInfoSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* prepareInvoicesDownload({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/financial/invoices/prepare-download`,
      params,
    });
    yield put(FinancialActions.prepareInvoicesDownloadSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* renewInvoice({ enterpriseId, invoiceId, token, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/enterprises/${enterpriseId}/financial/invoices/${invoiceId}/renew/${token}`,
    });
    yield put(FinancialActions.renewInvoiceSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* setGlobalSetting({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/financial/global-settings/`,
      data: payload,
    });
    yield put(FinancialActions.setGlobalSettingSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getReportBilling({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/financial/report-billing`,
      data: params,
    });
    yield put(FinancialActions.getReportBillingSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error, type));
  }
}

function* getFinancialInvoiceCharges({ invoiceId, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/invoices/${invoiceId}/charges/`,
    });
    yield put(FinancialActions.getFinancialInvoiceChargesSuccess(data));
  } catch (error) {
    yield put(FinancialActions.requestFailure(error?.response?.data || error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(FinancialTypes.GET_GLOBAL_SETTING, getGlobalSetting);
  yield takeLatest(FinancialTypes.GET_GLOBAL_SETTINGS, getGlobalSettings);
  yield takeLatest(FinancialTypes.GET_INVOICES, getInvoices);
  yield takeLatest(FinancialTypes.GET_FINANCIAL_INVOICE_ITEMS, getFinancialInvoiceItems);
  yield takeLatest(FinancialTypes.GET_FINANCIAL_INVOICES, getFinancialInvoices);
  yield takeLatest(FinancialTypes.GET_FINANCIAL_INVOICES_TO_CHARGE, getFinancialInvoicesToCharge);
  yield takeLatest(FinancialTypes.GET_INVOICE_STATUSES, getInvoiceStatuses);
  yield takeLatest(FinancialTypes.GET_PAYMENT_GATEWAY_INFO, getPaymentGatewayInfo);
  yield takeLatest(FinancialTypes.PREPARE_INVOICES_DOWNLOAD, prepareInvoicesDownload);
  yield takeLatest(FinancialTypes.RENEW_INVOICE, renewInvoice);
  yield takeLatest(FinancialTypes.SET_GLOBAL_SETTING, setGlobalSetting);
  yield takeLatest(FinancialTypes.GET_PLAN_INVOICE, getPlanInvoice);
  yield takeLatest(FinancialTypes.GET_REPORT_BILLING, getReportBilling);
  yield takeLatest(FinancialTypes.GET_FINANCIAL_INVOICE_CHARGES, getFinancialInvoiceCharges);
}
