import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "components/core/Pagination";
import { Table, TableHeader, TableBody, TableItem, RenderHeaders } from "components/core/Table";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import { LINKS } from "utils/constants";
import { formatDate } from "helpers";

const ClientsList = ({
  items,
  params,
  total,
  totalPages,
  page,
  perPage,
  order,
  orderBy,
  emitterId,
}) => {
  const { clientDetails } = LINKS;
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "clientName", title: translate("client") },
    { slug: "enterpriseName", title: translate("enterpriseName") },
    { slug: "cpf", title: translate("cpf") },
    { slug: "cnpj", title: translate("cnpj") },
    { slug: "createdAt", title: translate("registrationDate"), textAlign: "center" },
  ];

  const linkToDetails = (invoiceId) => clientDetails(emitterId, invoiceId);

  return (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(({ id, clientName, enterpriseName, cpf, cnpj, createdAt }) => (
            <tr key={id}>
              <TableItem>
                <Link to={linkToDetails(id)}>{id}</Link>
              </TableItem>
              <TableItem>
                <Link to={linkToDetails(id)}>{clientName}</Link>
              </TableItem>
              <TableItem>
                <Link to={linkToDetails(id)}>{enterpriseName}</Link>
              </TableItem>
              <TableItem>
                <Link to={linkToDetails(id)}>{cpf}</Link>
              </TableItem>
              <TableItem>
                <Link to={linkToDetails(id)}>{cnpj}</Link>
              </TableItem>
              <TableItem textAlign="center">
                <Link to={linkToDetails(id)}>{createdAt && formatDate(createdAt)}</Link>
              </TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

ClientsList.propTypes = {
  items: PropTypes.array,
  emitterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

ClientsList.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 0,
};

export default WithEmptyFeedback(ClientsList);
