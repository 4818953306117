export default {
  billingRequired: "O valor do faturamento é necessário",
  documentRequired: "O documento é obrigatório",
  documentTypeRequired: "O tipo de documento é necessário",
  emitterStatusRequired: "O status do emissor é necessário",
  incorrectValue: "Valor Incorreto",
  invalid: {
    email: "O email não é válido",
    billingEmail: "O email de cobrança não é válido",
    password: "A senha é muito curta",
    confirmPassword: "As senhas devem ser iguais",
    coupon: "Cupom Inválido",
  },
  maxBillingMEI: "O valor máximo para faturamento do MEI é de {{value}}",
  maxValueRequired: "O valor máximo é necessário",
  minValueRequired: "O valor mínimo é necessário",
  noResults: "Nenhum resultado encontrado.",
  planValueRequired: "O valor do plano é necessário",
  referenceMonthRequired: "O mês de referência é necessário",
  referenceYearRequired: "O ano de referência é necessário",
  spreadRequired: "A planilha é ncessaria.",
  min: {
    maxInstallmentsNumber: "O Nº Máximo de Parcelas deve ser maior que 0",
    interestValue: "O Percentual de Juros deve ser maior que 0",
  },
  max: {
    maxInstallmentsNumber: "O Nº Máximo de Parcelas deve ser menor ou igual à 12",
  },
  required: {
    amount: "Quantidade de usos é necessário",
    activity: "O tipo de atividade é necessário",
    certificate: "O certificado é necessário",
    city: "A Cidade é necessária",
    cityRegistration: "É necessário informar a Inscrição Munícipal",
    cityOrStateRegistration: "É necessário informar a Inscrição Estadual ou Munícipal",
    cnpjAlreadyRegistered: "O CNPJ já foi cadastrado em nosso sistema",
    cnpj: "O CNPJ é necessário",
    csllAliquot: "Alíquota CSLL é necessária",
    cofinsAliquot: "Alíquota COFINS é necessária",
    issAliquot: "Alíquota ISS é necessária",
    cpf: "O CPF é necessário",
    cpfNotValid: "Digite um CPF válido",
    crc: "O CRC é necessário",
    culturalPromoter: "É necessário informar se empresa incetiva a cultura",
    code: "É necessário informar o código",
    discount: "É necessário informar o valor do desconto",
    description: "Campo de descrição obrigatório",
    email: "O email é necessário",
    emailAlreadyRegistered: "O email já foi cadastrado em nosso sistema",
    endDate: "A data do final é necessária",
    emitter: "O emissor é necessário",
    emitterStatus: "O status do emissor é necessário",
    enterpriseName: "A Razão Social é necessária",
    enterpriseTypes: "O tipo de empresa é necessário",
    fantasyName: "O Nome Fantasia é necessário",
    irAliquot: "Alíquota IRRJ é necessária",
    inssAliquot: "Alíquota INSS é necessária",
    justification: "Ao alterar o valor é necessário inserir uma justificativa",
    localServiceProvision: "O Local da Prestação de Serviço é necessário",
    message: "A mensagem é necessária",
    name: "O nome é necessário",
    ncm: "Campo NCM é obrigatório",
    neighborhood: "O bairro é necessário",
    number: "O número é necessário",
    numberEmployees: "O número de funcionários é necessário",
    passphrase: "A frase secreta é necessária",
    password: "A senha é necessária",
    pisAliquot: "Alíquota PIS é necessário",
    passwordCertificate: "A senha do certificado é necessária",
    phone: "O telefone é necessário",
    profileType: "O tipo de perfil é necessário",
    serialNumber: "O número de série é necessário",
    stateRegistration: "É necessário informar a Inscrição Estadual",
    series: "A série da Nota Fiscal é necessária",
    serviceLocation: "O Local de Prestação de Serviço é necessário",
    specialTaxRegime: "O Regime Especial de Tributação é necessário",
    startDate: "A data de inicio é necessária",
    state: "O Estado é necessário",
    street: "O logradouro é necessário",
    subscriptions: "Seleione o tipo de plano",
    token: "O token é necessário",
    unifiedMunicipalServices: "O SMU é necessário",
    username: "O usuário é necessário",
    userStatus: "O status do usuário é necessário",
    zipCode: "O CEP é necessário",
    enterpriseType: "O Tipo de Empresa é necessário",
    allowInstallments: "O campo Permite Parcelamento é necessário",
    allowInstallmentsFirstSubscription: "O campo Permite Parcelamento na Assinatura é necessário",
    allowedPlans: "O campo Planos Permitidos é necessário",
    maxInstallmentsNumber: "O Nº Máximo de Parcelas é necessário",
    interestType: "O Tipo de Juros é necessário",
    interestValue: "O Percentual de Juros é necessário",
    isNationalMEI: "O campo MEI Nacional é necessário",
    nationalMEIPassword: "O campo Senha é necessário, quando o emissor é MEI Nacional",
    nationalMEIUser: "O campo Usuário é necessário, quando o emissor é MEI Nacional",
  },
  oneOf: {
    allowedPlans: "O campo Planos Permitidos deve ser Anual ou Mensal",
  },
  short: {
    cpf: "O CPF é muito curto",
    crc: "O CRC é muito curto",
    phone: "O telefone é muito curto",
    billingPhone: "O telefone de cobrança é muito curto",
    zipCode: "O CEP é muito curto",
    code: "O código é muito curto",
    number: "O número está fora do limite",
    complement: "O complemento está fora do limite de caractere",
    city: "A cidade está fora do limite de caractere",
    neighborhood: "O bairro está fora do limite de caractere",
    street: "A rua está fora do limite de caractere",
    stateRegistration: "A inscrição está fora do limite de caractere",
    cityRegistration: "A inscrição está fora do limite de caractere",
    name: "O nome é muito longo",
  },
  taxRequired: "A taxa de abertura é necessária",
};
