import { useEffect, useState } from "react";

const useScript = (url) => {
  const [loaded, setLoaded] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    const onScriptLoad = () => {
      setLoaded(true);
    };

    const onScriptError = () => {
      script.remove();

      setLoaded(false);
    };

    script.addEventListener("load", onScriptLoad);
    script.addEventListener("error", onScriptError);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      script.removeEventListener("load", onScriptLoad);
      script.removeEventListener("error", onScriptError);
    };
  }, [url]);

  return [loaded];
};

export default useScript;
