import React, { useState } from "react";
import NavBar from "components/core/NavBar";
import Button from "components/core/Button";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import { useParams, useRequest } from "hooks";
import { InterActions } from "store/ducks/interbank";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core";
import LeadsInterList from "../../../components/presentation/Comercial/LeadsInter";

const Filter = React.lazy(() => import("containers/Comercial/LeadsInter/Filter"));
const Edit = React.lazy(() => import("containers/Comercial/LeadsInter/Edit"));

const LeadsInterListWithSpinner = WithSpinner(LeadsInterList);
const FilterWithSuspense = WithSuspense(Filter);
const EditWithSuspense = WithSuspense(Edit);

const LeadsInter = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dataSendEmail, setDataSendEmail] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [dataLead, setDataLead] = useState();

  const {
    interRequests,
    interLeads: { items, total, totalPages, itemsPaginated },
  } = useSelector(({ inter }) => inter);

  const fetchAllLeads = (newParams) => {
    dispatch(InterActions.fetchLeads(newParams));
  };

  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchAllLeads,
    defaultOrderBy: "id",
    defaultOrder: "desc",
  });

  // const showModalEmail = (params) => {
  //   setDataSendEmail(params);
  //   setShowConfirmModal(true);
  // };

  const requestApiInter = ({
    id,
    action,
    createdAt,
    status,
    amount,
    currency,
    interClickRef,
    orderRef,
    productDescription,
  }) => {
    const actionSend = {
      action,
      async: false,
      dateTime: createdAt,
      status,
      amount,
      currency,
      clickRef: interClickRef,
      orderDateTime: createdAt,
      orderRef: orderRef.toString(),
      productDescription,
      unityValue: amount,
    };
    dispatch(InterActions.requestCheckoutInter({ actionSend, id }));
  };

  const sendEmail = () => {
    dispatch(InterActions.sendEmailLead(dataSendEmail));
    setShowConfirmModal(false);
  };

  const refresh = () => {
    window.location.reload();
  };

  // const onResolved = () => {
  //   dispatch(
  //     LayoutActions.showConfirmationModal({
  //       content: <Span>{`Email enviado com Successo para ${dataSendEmail.email}!`}</Span>,
  //       type: "success",
  //     }),
  //   );
  // };

  // useRequest({
  //   request: interRequests.SEND_EMAIL_LEAD,
  //   onResolved,
  // });

  const dispatchConfirmation = (text, type) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
      }),
    );

  useRequest({
    request: interRequests.REQUEST_CHECKOUT_INTER,
    onResolved: () => {
      dispatchConfirmation("Ação enviada ao Banco Inter com successo!", "success");
      setTimeout(() => {
        refresh();
      }, 1600);
    },
    onRejected: () => {
      dispatchConfirmation("Erro ao enviar ação ao Banco Inter!", "error");
    },
  });

  return (
    <>
      <NavBar title="Leads Inter" route={null}>
        <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
        <Button handleClick={refresh}>{translate("Refresh")}</Button>
      </NavBar>
      <LeadsInterListWithSpinner
        statusRequest={interRequests.FETCH_LEADS}
        items={itemsPaginated}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
        // showModalEmail={showModalEmail}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        setShowEditForm={setShowEditForm}
        setDataLead={setDataLead}
        sendEmail={sendEmail}
        requestApiInter={requestApiInter}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
      <EditWithSuspense
        loadComponent={showEditForm}
        dataLead={dataLead}
        showEditForm={showEditForm}
        setShowEditForm={setShowEditForm}
      />
    </>
  );
};
export default LeadsInter;
