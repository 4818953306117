import React, { useRef, useEffect, lazy, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useRequest } from "hooks";
import { CouponsActions } from "store/ducks/coupons";
import { EnterpriseActions } from "store/ducks/enterprise";
import { LayoutActions } from "store/ducks/layout";
import NavBar from "components/core/NavBar";
import Button from "components/core/Button";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import CouponsRegister from "components/presentation/Coupons/Register";
import { Span } from "components/core/Typography";
import { LINKS } from "utils/constants";
import { formatDateToParam } from "helpers/format";
import { couponsSchema } from "utils/schemas/coupons";

const Types = lazy(() => import("containers/Coupons/Register/Types"));
const States = lazy(() => import("containers/Coupons/Register/States"));
const Cities = lazy(() => import("containers/Coupons/Register/Cities"));

const CouponsRegisterWithSpinner = WithSpinner(CouponsRegister);
const TypesWithSuspense = WithSuspense(Types);
const StatesWithSuspense = WithSuspense(States);
const CitiesWithSuspense = WithSuspense(Cities);

const CouponsRegisterContainer = () => {
  const submitRef = useRef(null);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [createdCouponsname, setCreatedCouponsname] = useState();
  const [showEnterpriseTypesModal, setEnterpriseTypesModal] = useState(false);
  const [showStatesModal, setStatesModal] = useState(false);
  const [showCitiesModal, setCitiesModal] = useState(false);
  const [selectedUF, setSelectedUF] = useState("AC");
  const { subscriptions, CouponsRequests } = useSelector(({ coupons }) => coupons);
  const { types } = useSelector(({ enterprise }) => enterprise);

  useEffect(() => {
    dispatch(CouponsActions.getSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(EnterpriseActions.getTypes());
  }, [dispatch]);

  const handleChangeStates = ({ target: { value: idState } }) => {
    setSelectedUF(idState);
  };

  const handleConfirm = ({ code, startDate, endDate, ...data }) => {
    const payload = { ...data };
    const newStartDate = formatDateToParam(startDate, "00:00:00", true);
    const newEndDate = formatDateToParam(endDate, "00:00:00", true);
    payload.code = code;
    payload.startDate = newStartDate;
    if (endDate) {
      payload.endDate = newEndDate;
    }
    console.log("🚀 ~ file: index.js:61 ~ handleConfirm ~ payload:", payload);
    dispatch(CouponsActions.createCoupons(payload));
    setCreatedCouponsname(code);
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans
              i18nKey="couponsCreated"
              values={{ name: createdCouponsname }}
              components={{ bold: <strong /> }}
            />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(CouponsActions.cleanState());

    history.push(LINKS.couponsList);
  };

  useRequest({
    request: CouponsRequests.CREATE_COUPONS,
    onResolved,
  });

  return (
    <>
      <NavBar title={translate("newCoupons")}>
        <Button url={`${LINKS.couponsList}`} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("save")}</Button>
      </NavBar>
      <CouponsRegisterWithSpinner
        isLoading={isPending(CouponsRequests.CREATE_COUPONS)}
        handleConfirm={handleConfirm}
        subscriptions={subscriptions}
        avalableEnterpriseTypes={types}
        setEnterpriseTypesModal={setEnterpriseTypesModal}
        setStatesModal={setStatesModal}
        setCitiesModal={setCitiesModal}
        handleChangeStateToselectCities={handleChangeStates}
        StateToselectCities={selectedUF}
        validationSchema={couponsSchema}
        submitRef={submitRef}
      />
      <TypesWithSuspense
        loadComponent={showEnterpriseTypesModal}
        showModal={showEnterpriseTypesModal}
        setShowModal={setEnterpriseTypesModal}
      />
      <StatesWithSuspense
        loadComponent={showStatesModal}
        showModal={showStatesModal}
        setShowModal={setStatesModal}
      />
      <CitiesWithSuspense
        loadComponent={showCitiesModal}
        showModal={showCitiesModal}
        setShowModal={setCitiesModal}
        selectedUF={selectedUF}
      />
    </>
  );
};

export default CouponsRegisterContainer;
