import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { RowFlex, Container } from "components/core/Grid";
import { Span, Divider } from "components/core/Typography";
import { formatDate, formatNumberToReal, mapToSelectOptions } from "helpers";
import { isPending } from "components/core/WithSpinner";
import SelectForm from "components/core/SelectForm";
import Modal from "components/core/Modal";
import { ModalActions } from "components/core/Modal/styled";
import { useFinancialSubscription } from "hooks";
import { RefreshArrow, Chrevon } from "./icons";
import { InputGroup } from "components/core/Form";
import InputForm from "components/core/InputForm";
import { MASK_TYPES, format } from "utils/masks";
import { RenderHeaders, Table, TableHeader, TableBody, TableItem } from "components/core/Table";

const Charge = () => {
  const {
    availablePaymentForms,
    invoicesToCharge,
    getAvailableInvoicesToCharge,
    getAvailablePaymentForms,
    getSubscription,
    getSubscriptionInvoiceItems,
    subscriptionInvoiceItems: { items },
    chargeInvoice,
    resetChargeStates,
    subscription: {
      accountInfo,
      id,
      globalInstallmentSettings,
      installments,
      installmentSettings,
      planCode,
    },
    requestStatus: {
      GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE: invoicesStatus,
      GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS: paymentFormsStatus,
    },
  } = useFinancialSubscription();
  const { t: translate } = useTranslation();

  const defaultInvoiceItem = { value: "", text: "Selecione uma fatura" };
  const defaultPaymentFormItem = { value: "", text: "Selecione um cartão de crédito" };

  const headers = [
    { slug: "description", title: `${translate("description")}` },
    { slug: "price", title: `${translate("Value")}` },
    { slug: "quantity", title: `${translate("Quantity")}` },
    { slug: "total", title: `${translate("Total")}` },
  ];

  const [installmentsNumber, setInstallmentsNumber] = useState(null);
  const [invoices, setInvoices] = useState([defaultInvoiceItem]);
  const [maxInstallmentsNumber, setMaxInstallmentsNumber] = useState(1);
  const [password, setPassword] = useState("");
  const [paymentForms, setPaymentForms] = useState([defaultPaymentFormItem]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
  const [showModalAuth, setShowModalAuth] = useState(false);
  const [showInvoiceItems, setShowInvoiceItems] = useState(false);

  const plans = {
    mensal: 1,
    anual: 2,
    bienal: 3,
  };

  const itemPadding = "0.75rem 1rem";

  useEffect(() => {
    if (id) {
      getSubscription(id);
    }
  }, [id]);

  useEffect(() => {
    setInvoices([defaultInvoiceItem]);
    if (invoicesToCharge?.length) {
      const invoices = invoicesToCharge?.map((i) => ({
        value: i.id,
        text: `ID: ${i.id} - C: ${getCompetence(
          i?.billingPeriod ?? i?.subscriptionExpiresAt,
          planCode === "mensal" ? "MM/YYYY" : "YYYY",
        )} - VE: ${formatDate(i.dueDate, 0, false)} - VA: ${formatNumberToReal(
          i.total.toFixed(2),
        )}`,
      }));
      if (invoices?.length === 1) {
        setInvoices(invoices);
        setInvoice({ target: { value: invoices[0].value } });
      } else {
        setInvoices([defaultInvoiceItem, ...invoices]);
      }
    }
  }, [invoicesToCharge]);

  useEffect(() => {
    setPaymentForms([defaultPaymentFormItem]);
    if (availablePaymentForms?.length) {
      const paymentForms = availablePaymentForms?.map((p) => ({
        value: p.id,
        text: `${p.isDefault ? "(Padrão)" : ""} - ${p.description}`,
      }));
      if (paymentForms?.length === 1) {
        setPaymentForms(paymentForms);
        setPaymentForm({ target: { value: paymentForms[0].value } });
      } else {
        setPaymentForms([defaultPaymentFormItem, ...paymentForms]);
      }
    }
  }, [availablePaymentForms]);

  useEffect(() => {
    if (selectedInvoice) {
      const {
        enterpriseId,
        gatewayId,
        gatewayInvoiceId,
        id: selectedInvoiceId,
        paymentGatewayClientId,
        paymentGatewayClientSubscriptionId: subscriptionId,
      } = selectedInvoice;
      getAvailablePaymentForms({
        enterpriseId,
        gatewayId,
        gatewayInvoiceId,
        selectedInvoiceId,
        paymentGatewayClientId,
        subscriptionId,
      });
    }
  }, [selectedInvoice]);

  useEffect(() => {
    let maxInstallments = 1;
    if (installmentSettings && globalInstallmentSettings) {
      if (
        !installmentSettings?.inheritSettings &&
        installmentSettings?.allowedInstallmentsPlans?.includes(plans[planCode])
      )
        maxInstallments = Math.max(installmentSettings?.maxInstallmentsNumber, installments);
      if (
        installmentSettings?.inheritSettings &&
        globalInstallmentSettings?.allowedInstallmentsPlans?.includes(plans[planCode])
      )
        maxInstallments = Math.max(installmentSettings?.maxInstallmentsNumber, installments);
      if (
        accountInfo &&
        accountInfo?.data?.configuration?.credit_card?.max_installments < maxInstallments
      )
        maxInstallments = accountInfo?.data?.configuration?.credit_card?.max_installments;
      if (maxInstallments === 1) setInstallmentsNumber(1);
    }
    setMaxInstallmentsNumber(maxInstallments);
  }, [
    installmentSettings,
    globalInstallmentSettings,
    accountInfo,
    maxInstallmentsNumber,
    planCode,
    plans,
  ]);

  useEffect(() => {
    if (selectedInvoice && selectedInvoice?.id) {
      const { id: invoiceId, paymentGatewayClientSubscriptionId: subscriptionId } = selectedInvoice;
      getSubscriptionInvoiceItems({
        sort: "description",
        perPage: 10,
        invoiceId,
        subscriptionId,
        attributes: ["id", "description", "quantity", "price"],
      });
    }
  }, [selectedInvoice]);

  const closeModal = () => {
    setMaxInstallmentsNumber(1);
    resetChargeStates();
  };

  const getCompetence = (date, mask) => {
    if (!date) return "";
    return formatDate(date, 0, false, false, null, mask);
  };

  const handleChargeInvoice = () => {
    if (isValidForm() && password) {
      chargeInvoice({
        invoiceId: selectedInvoice.id,
        paymentFormId: selectedPaymentForm.id,
        installmentsNumber,
        password,
        enterpriseId: selectedInvoice.enterpriseId,
        gatewayId: selectedInvoice.gatewayId,
        gatewayInvoiceId: selectedInvoice.gatewayInvoiceId,
        subscriptionId: selectedInvoice.paymentGatewayClientSubscriptionId,
      });
    }
  };

  const hasLoading = () => {
    return isPending(invoicesStatus) || isPending(paymentFormsStatus);
  };

  const isValidForm = () => {
    const isValid =
      selectedInvoice?.id > 0 && selectedPaymentForm?.id > 0 && installmentsNumber > 0;
    return isValid;
  };

  const nextToChargeInvoice = () => {
    if (isValidForm()) {
      setShowModalAuth(true);
    }
  };

  const onCloseModalAuth = () => {
    setPassword("");
    setShowModalAuth(false);
  };

  const optionsNumber = (min, max) => {
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push({ text: i, value: i });
    }
    return options;
  };

  const refreshInvoices = () => {
    setInvoices([defaultInvoiceItem]);
    setPaymentForms([defaultPaymentFormItem]);
    getAvailableInvoicesToCharge(id);
  };

  const setInvoice = ({ target: { value } }) => {
    if (!value || Number.isNaN(Number(value))) return setSelectedInvoice(null);
    const invoice = invoicesToCharge?.find((i) => i.id === Number(value));
    setSelectedInvoice(invoice);
  };

  const setPaymentForm = ({ target: { value } }) => {
    if (!value || Number.isNaN(Number(value))) return setSelectedPaymentForm(null);
    const paymentForm = availablePaymentForms?.find((i) => i.id === Number(value));
    setSelectedPaymentForm(paymentForm);
  };

  const toggleShowInvoiceItems = () => setShowInvoiceItems(!showInvoiceItems);

  const handleSelectInstallments = ({ target: { value } }) => {
    setInstallmentsNumber(Number(value));
  };

  return (
    <>
      <RowFlex marginBottom={0}>
        <Span>
          <strong>C</strong>: Competência | <strong>VE</strong>: Data de Vencimento |{" "}
          <strong>VA</strong>: Valor da Fatura
        </Span>
      </RowFlex>
      <Divider />
      <RowFlex verticalAlign={"flex-end"} marginBottom={0}>
        <SelectForm
          name="invoiceId"
          label="Selecione a fatura"
          options={invoices}
          isLoading={isPending(invoicesStatus)}
          disabled={isPending(invoicesStatus)}
          handleChange={setInvoice}
          marginBottom={"1rem"}
        />
        <Button
          type="button"
          handleClick={refreshInvoices}
          style={{
            padding: "0 1rem",
            marginBottom: "1rem",
            marginLeft: "1rem",
          }}
        >
          <RefreshArrow />
        </Button>
      </RowFlex>
      <Button
        variant={"text"}
        handleClick={toggleShowInvoiceItems}
        title={showInvoiceItems ? "Ocultar itens" : "Exibir itens"}
      >
        Items da fatura <Chrevon />
      </Button>
      {showInvoiceItems && (
        <Container
          horizontalAlign="center"
          horizontalPadding={0}
          verticalPadding={0}
          marginBottom={"1rem"}
        >
          <Table>
            <TableHeader>
              <RenderHeaders headers={headers} />
            </TableHeader>
            <TableBody>
              {items?.map(({ id, description, quantity, price }) => (
                <tr key={id}>
                  <TableItem padding={itemPadding}>{description}</TableItem>
                  <TableItem padding={itemPadding}>
                    {format(
                      Number(price).toFixed(2),
                      price < 0 ? MASK_TYPES.negativeRealWithName : MASK_TYPES.realWithName,
                    )}
                  </TableItem>
                  <TableItem padding={itemPadding}>{quantity}</TableItem>
                  <TableItem padding={itemPadding}>
                    {format(
                      Number(price * quantity).toFixed(2),
                      price < 0 ? MASK_TYPES.negativeRealWithName : MASK_TYPES.realWithName,
                    )}
                  </TableItem>
                </tr>
              ))}
            </TableBody>
          </Table>
        </Container>
      )}
      <InputGroup paddingLeft={0}>
        <RowFlex verticalAlign={"flex-start"}>
          <InputGroup paddingLeft={0}>
            <RowFlex direction={"column"} horizontalAlign={"flex-start"}>
              <SelectForm
                name="paymentFormId"
                label="Selecione o cartão de crédito"
                options={paymentForms}
                isLoading={isPending(paymentFormsStatus)}
                disabled={!selectedInvoice || !invoicesToCharge || isPending(paymentFormsStatus)}
                handleChange={setPaymentForm}
                marginBottom={0}
              />
              {selectedPaymentForm && (
                <Span>
                  Válido até: {getCompetence(selectedPaymentForm?.cardExpiration, "MM/YYYY")}
                </Span>
              )}
            </RowFlex>
          </InputGroup>
          <InputGroup>
            <SelectForm
              name="installments"
              label="Selecione o número de parcelas"
              options={mapToSelectOptions(optionsNumber(1, maxInstallmentsNumber), "value", "text")}
              handleChange={handleSelectInstallments}
            />
          </InputGroup>
        </RowFlex>
      </InputGroup>
      <ModalActions>
        <Button
          type="button"
          disabled={hasLoading() || !isValidForm()}
          handleClick={nextToChargeInvoice}
        >
          Cobrar
        </Button>
        <Button type="button" variant="outline" handleClick={closeModal}>
          Cancelar
        </Button>
      </ModalActions>
      <Modal
        title={`Informe a sua senha`}
        showModal={showModalAuth}
        onCloseModal={onCloseModalAuth}
        showActions={true}
        width="40rem"
        minHeight="unset"
        padding="2rem 4rem"
        zIndex={999999}
        disableConfirm={!isValidForm() || !password}
        onConfirmModal={handleChargeInvoice}
      >
        <InputForm
          type="password"
          name="password"
          autocomplete="new-password"
          label={`Senha`}
          onChange={({ target: { value } }) => setPassword(value)}
          value={password}
        />
      </Modal>
    </>
  );
};

export default Charge;
