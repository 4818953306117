import React, { lazy, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams as useRouterParams } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import { ServicesActions } from "store/ducks/services";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import TableFactory from "components/core/Table/TableFactory";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import { useItems, useParams, useRequest } from "hooks";
import { LINKS } from "utils/constants";

const SMUSelect = lazy(() => import("containers/MunicipalServices/Register/SMU"));
const SMULPSelect = lazy(() => import("containers/MunicipalServices/Register/SMULP"));
const CNAESelect = lazy(() => import("containers/MunicipalServices/Register/CNAE"));
const CNAELPSelect = lazy(() => import("containers/MunicipalServices/Register/CNAELP"));
const DeleteServicesConfirm = lazy(() => import("components/presentation/Documents/Delete"));

const TableFactoryWithSpinner = WithSpinner(TableFactory);
const SMUSelectWithSuspense = WithSuspense(SMUSelect);
const SMULPSelectWithSuspense = WithSuspense(SMULPSelect);
const CNAESelectWithSuspense = WithSuspense(CNAESelect);
const CNAELPSelectWithSuspense = WithSuspense(CNAELPSelect);
const DeleteServicesConfirmWithSuspense = WithSuspense(DeleteServicesConfirm);

const MunicipalServicesList = () => {
  const dispatch = useDispatch();
  const { emitterId } = useRouterParams();
  const { t: translate } = useTranslation();
  const [showModalSMU, setShowModalSMU] = useState(false);
  const [showModalSMULP, setShowModalSMULP] = useState(false);
  const [showModalCNAE, setShowModalCNAE] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [allowedKeys, setAllowedKeys] = useState([]);
  const [showModalCNAELP, setShowModalCNAELP] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const {
    emitterServices: { items, total, totalPages },
    statusOfRequests,
    emitterDetails,
  } = useSelector(({ emitter }) => emitter);

  useEffect(() => {
    if (emitterDetails?.id !== Number(emitterId)) {
      dispatch(EmitterActions.fetchEmitterById(emitterId));
    }
  }, [dispatch, emitterDetails?.id, emitterId]);

  const emitterDetailsSmu = emitterDetails.unifiedMunicipalService;
  const emitterDetailsType = emitterDetails.type;

  const { ServicesRequests } = useSelector(({ services }) => services);
  const {
    everyoneIsChecked,
    handleChange,
    toggleAll,
    listChecked: itemsChecked,
    selectedItems,
    hasSelectedItems,
  } = useItems(items);

  const fetchServices = (queryParams) =>
    dispatch(EmitterActions.fetchEmitterServices(emitterId, { ...queryParams }));

  const { params, orderBy, order, page, perPage, setPage, setPerPage, setOrderBy, setOrder } =
    useParams({
      callback: fetchServices,
      defaultOrderBy: "createdAt",
      defaultOrder: "desc",
    });

  const dispatchDelete = () => {
    dispatch(ServicesActions.deleteServices({ idList: selectedItems.map(({ id }) => id) }));
    setShowDeleteConfirm(false);
  };

  useRequest({
    request: ServicesRequests.DELETE_SERVICES,
    onResolved: () => fetchServices(params),
  });

  useEffect(
    () =>
      emitterDetails?.unifiedMunicipalService && emitterDetails?.type?.id !== 2
        ? (setHeaders([
            { slug: "code", i18nKey: "code" },
            { slug: "name", i18nKey: "name" },
          ]),
          setAllowedKeys(["code", "name"]))
        : (setHeaders([
            { slug: "code", i18nKey: "code" },
            { slug: "name", i18nKey: "description" },
            { slug: "location", i18nKey: "serviceLocation" },
          ]),
          setAllowedKeys([
            "code",
            "name",
            `${emitterDetails?.type?.id === 2 ? "serviceLocation.name" : "serviceListItem"}`,
          ])),
    [emitterDetails, itemsChecked],
  );

  useEffect(() => {
    if (emitterDetails?.unifiedMunicipalService === false) {
      setHeaders([
        { slug: "cnae", i18nKey: "CNAE" },
        { slug: "name", i18nKey: "name" },
        {
          slug: "descriptionServiceCode",
          i18nKey: "serviceDescription",
        },
        {
          slug: "municipalServiceCode",
          i18nKey: "municipalServiceCode",
        },
        {
          slug: `${emitterDetails?.type?.id === 2 ? "serviceLocation" : "serviceListItem"}`,
          i18nKey: `${emitterDetails?.type?.id === 2 ? "serviceLocation" : "serviceListItem"}`,
        },
      ]);
      setAllowedKeys([
        "cnae",
        "name",
        "descriptionServiceCode",
        "municipalServiceCode",
        `${emitterDetails?.type?.id === 2 ? "serviceLocation.name" : "serviceListItem"}`,
      ]);
    }
  }, [emitterDetails, itemsChecked]);

  useEffect(() => {
    setShowModalSMU(false);
    setShowModalSMULP(false);
    setShowModalCNAE(false);
    setShowModalCNAELP(false);
  }, []);

  return (
    <>
      <NavBar title={translate("municipalServices")}>
        <Button
          variant="outline"
          color="danger"
          handleClick={() => setShowDeleteConfirm(true)}
          disabled={!hasSelectedItems}
        >
          {translate("delete")}
        </Button>
        {emitterDetails.id && emitterDetailsSmu && emitterDetailsType.id !== 3 && (
          <Button handleClick={() => setShowModalSMU(true)}>
            <span>{translate("addServices")}</span>
          </Button>
        )}
        {emitterDetails.id && emitterDetailsSmu && emitterDetailsType.id === 3 && (
          <Button handleClick={() => setShowModalSMULP(true)}>
            <span>{translate("addServices")}</span>
          </Button>
        )}
        {emitterDetails.id && !emitterDetailsSmu && emitterDetailsType.id !== 3 && (
          <Button handleClick={() => setShowModalCNAE(true)}>
            <span>{translate("addServices")}</span>
          </Button>
        )}
        {emitterDetails.id && !emitterDetailsSmu && emitterDetailsType.id === 3 && (
          <Button handleClick={() => setShowModalCNAELP(true)}>
            <span>{translate("addServices")}</span>
          </Button>
        )}
      </NavBar>
      <TableFactoryWithSpinner
        requestStatus={statusOfRequests.FETCH_EMITTER_SERVICES}
        headers={headers}
        allowedKeys={allowedKeys}
        items={itemsChecked}
        total={total}
        totalPages={totalPages}
        orderBy={orderBy}
        order={order}
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        everyoneIsChecked={everyoneIsChecked}
        handleChange={handleChange}
        toggleAll={toggleAll}
        urlDetails={(id) => LINKS.municipalServicesDetails(emitterId, id)}
      />
      <SMUSelectWithSuspense
        loadComponent={showModalSMU}
        showModal={showModalSMU}
        setShowModal={setShowModalSMU}
        emitterDetailsType={emitterDetailsType}
      />
      <SMULPSelectWithSuspense
        loadComponent={showModalSMULP}
        showModal={showModalSMULP}
        setShowModal={setShowModalSMULP}
      />
      <CNAESelectWithSuspense
        loadComponent={showModalCNAE}
        showModal={showModalCNAE}
        setShowModal={setShowModalCNAE}
        emitterDetails={emitterDetails}
      />
      <CNAELPSelectWithSuspense
        loadComponent={showModalCNAELP}
        showModal={showModalCNAELP}
        setShowModal={setShowModalCNAELP}
        emitterDetails={emitterDetails}
      />
      <DeleteServicesConfirmWithSuspense
        loadComponent={showDeleteConfirm}
        showConfirm={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={dispatchDelete}
        titleTranslateKey="deleteServices"
        messageTranslateKey="deleteServicesConfirm"
      />
    </>
  );
};

export default MunicipalServicesList;
