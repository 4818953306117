import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams as urlParams } from "react-router-dom";
import { useParams, useFinancialSubscription, useRequest } from "hooks";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import SubscriptionsList from "components/presentation/Financial/Subscriptions/List";
import { ReactComponent as IconExport } from "assets/icons/icon-download.svg";
import WithSuspense from "components/core/WithSuspense";
import Button from "components/core/Button";
import { REQUEST_NOT_STARTED } from "utils/constants";

const Filter = React.lazy(() => import("containers/Financial/Subscriptions/Filter"));
const Add = React.lazy(() => import("containers/Financial/Subscriptions/Add"));

const SubscriptionsListWithSpinner = WithSpinner(SubscriptionsList);
const FilterWithSuspense = WithSuspense(Filter);
const AddWithSuspense = WithSuspense(Add);

const SubscriptionsListContainer = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const { emitterId } = urlParams();
  const { t: translate } = useTranslation();
  const {
    getSubscriptions,
    subscriptions: { items, total, totalPages },
    requestStatus,
    subscriptionsUseItems: {
      handleChange,
      listChecked,
      hasOneItemSelected,
      hasSelectedItems,
      selectedItems,
      toggleAll,
      everyoneIsChecked,
      clearSelection,
    },
    exportSubscriptions,
    handleOnRejected,
    handleOnResolved,
    validationErrors,
    cleanState,
  } = useFinancialSubscription();
  const [currentRequestStatus, setCurrentRequestStatus] = useState(
    requestStatus.GET_FINANCIAL_SUBSCRIPTIONS,
  );
  const fetchSubscriptions = (params) => {
    getSubscriptions({
      ...params,
      attributes: [
        "id",
        "enterpriseName",
        "userName",
        "enterpriseTypeName",
        "active",
        "planName",
        "price",
        "expiresAt",
        "billingDate",
        "installments",
      ],
    });
  };
  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchSubscriptions,
    defaultOrderBy: "id",
    defaultOrder: "desc",
  });

  const handleExportSubscriptions = (all = true) => {
    if (all) {
      exportSubscriptions();
    } else {
      exportSubscriptions({ subscriptions: selectedItems.map(({ id }) => id) });
    }
  };

  useEffect(() => {
    if (requestStatus.EXPORT_SUBSCRIPTIONS !== REQUEST_NOT_STARTED) {
      setCurrentRequestStatus(requestStatus.EXPORT_SUBSCRIPTIONS);
    }
  }, [requestStatus.EXPORT_SUBSCRIPTIONS]);

  useRequest({
    request: requestStatus.CREATE_FINANCIAL_SUBSCRIPTION,
    onResolved: () =>
      handleOnResolved("subscriptionCreated", () => {
        setShowAdd(false);
        cleanState();
        refresh();
      }),
    onRejected: () => handleOnRejected("subscriptionCreateError", validationErrors()),
  });

  useRequest({
    request: requestStatus.EXPORT_SUBSCRIPTIONS,
    onResolved: () => handleOnResolved("dataExported"),
    onRejected: () => handleOnRejected("dataExportedError", validationErrors()),
  });

  useEffect(() => {
    setCurrentRequestStatus(requestStatus.GET_FINANCIAL_SUBSCRIPTIONS);
  }, [requestStatus.GET_FINANCIAL_SUBSCRIPTIONS]);

  const refresh = () => {
    fetchSubscriptions({ order: "desc", page, perPage: 10, sort: "id" });
  };
  const newSubscription = () => {
    setShowAdd(true);
  };

  return (
    <>
      <NavBar title={translate("Subscriptions")}>
        <Button variant="outline" handleClick={newSubscription}>
          Nova
        </Button>
        <Button handleClick={refresh}>{translate("Refresh")}</Button>
        {(hasOneItemSelected || hasSelectedItems) && (
          <>
            <Button handleClick={clearSelection}>
              <span>{translate("Clear selection")}</span>
            </Button>
            <Button variant="outline" handleClick={() => handleExportSubscriptions(false)}>
              <IconExport />
              <span>{translate("Export selected")}</span>
            </Button>
          </>
        )}
        <Button handleClick={() => handleExportSubscriptions(true)}>
          <IconExport />
          <span>{translate("Export all")}</span>
        </Button>
        {!hasOneItemSelected && !hasSelectedItems && (
          <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
        )}
      </NavBar>
      <SubscriptionsListWithSpinner
        requestStatus={currentRequestStatus}
        subscriptions={listChecked || items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        handleChange={handleChange}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
        toggleAll={toggleAll}
        everyoneIsChecked={everyoneIsChecked}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
      <AddWithSuspense loadComponent={showAdd} showAdd={showAdd} setShowAdd={setShowAdd} />
    </>
  );
};

export default SubscriptionsListContainer;
