import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: PaymentGatewaysTypes, Creators: PaymentGatewaysActions } = createActions({
  getPaymentGateways: ["params"],
  getPaymentGatewaysSuccess: ["payload"],
  getPaymentGatewayById: ["params"],
  getPaymentGatewayByIdSuccess: ["payload"],
  editPaymentGateway: ["id", "payload"],
  editPaymentGatewaySuccess: ["payload"],
  processPayment: ["payload"],
  processPaymentSuccess: ["payload"],
  changePaymentMethod: ["payload"],
  changePaymentMethodSuccess: ["payload"],
  changePaymentMethodFailure: ["error", "originalType"],
  paymentGatewaysRequestFailure: ["error", "originalType"],
  cleanState: null,
});

const INITIAL_STATE = {
  paymentGateways: {
    items: [],
  },
  paymentGateway: {},
  paymentForm: {},
  paymentMethod: {},
  PaymentGatewaysRequestsStatus: {
    [PaymentGatewaysTypes.GET_PAYMENT_GATEWAYS]: REQUEST_NOT_STARTED,
    [PaymentGatewaysTypes.EDIT_PAYMENT_GATEWAY]: REQUEST_NOT_STARTED,
    [PaymentGatewaysTypes.PROCESS_PAYMENT]: REQUEST_NOT_STARTED,
    [PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD]: REQUEST_NOT_STARTED,
    [PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD_FAILURE]: REQUEST_NOT_STARTED,
  },
};

const getPaymentGateways = (state) => ({
  ...state,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.GET_PAYMENT_GATEWAYS]: REQUEST_PENDING,
  },
});

const getPaymentGatewaysSuccess = (state, { payload }) => ({
  ...state,
  paymentGateways: payload,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.GET_PAYMENT_GATEWAYS]: REQUEST_RESOLVED,
  },
});

const getPaymentGatewayById = (state) => ({
  ...state,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.GET_PAYMENT_GATEWAY_BY_ID]: REQUEST_PENDING,
  },
});

const getPaymentGatewayByIdSuccess = (state, { payload }) => ({
  ...state,
  paymentGateway: payload,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.GET_PAYMENT_GATEWAY_BY_ID]: REQUEST_RESOLVED,
  },
});

const editPaymentGateway = (state) => ({
  ...state,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.EDIT_PAYMENT_GATEWAY]: REQUEST_PENDING,
  },
});

const editPaymentGatewaySuccess = (state, { payload = {} }) => ({
  ...state,
  paymentGateway: payload,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.EDIT_PAYMENT_GATEWAY]: REQUEST_RESOLVED,
  },
});

const processPayment = (state) => ({
  ...state,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.PROCESS_PAYMENT]: REQUEST_PENDING,
  },
});

const processPaymentSuccess = (state, { payload = {} }) => ({
  ...state,
  paymentForm: payload,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.PROCESS_PAYMENT]: REQUEST_RESOLVED,
  },
});

const changePaymentMethod = (state) => ({
  ...state,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD]: REQUEST_PENDING,
  },
});

const changePaymentMethodSuccess = (state, { payload = {} }) => ({
  ...state,
  paymentMethod: payload,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD]: REQUEST_RESOLVED,
  },
});

const changePaymentMethodFailure = (state, { error, originalType }) => ({
  ...state,
  paymentError: error,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [originalType]: REQUEST_REJECTED,
  },
});

const paymentGatewaysRequestFailure = (state, { error, originalType }) => ({
  ...state,
  paymentError: error,
  PaymentGatewaysRequestsStatus: {
    ...state.PaymentGatewaysRequestsStatus,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [PaymentGatewaysTypes.GET_PAYMENT_GATEWAYS]: getPaymentGateways,
  [PaymentGatewaysTypes.GET_PAYMENT_GATEWAYS_SUCCESS]: getPaymentGatewaysSuccess,
  [PaymentGatewaysTypes.GET_PAYMENT_GATEWAY_BY_ID]: getPaymentGatewayById,
  [PaymentGatewaysTypes.GET_PAYMENT_GATEWAY_BY_ID_SUCCESS]: getPaymentGatewayByIdSuccess,
  [PaymentGatewaysTypes.EDIT_PAYMENT_GATEWAY]: editPaymentGateway,
  [PaymentGatewaysTypes.EDIT_PAYMENT_GATEWAY_SUCCESS]: editPaymentGatewaySuccess,
  [PaymentGatewaysTypes.PROCESS_PAYMENT]: processPayment,
  [PaymentGatewaysTypes.PROCESS_PAYMENT_SUCCESS]: processPaymentSuccess,
  [PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD]: changePaymentMethod,
  [PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD_SUCCESS]: changePaymentMethodSuccess,
  [PaymentGatewaysTypes.CHANGE_PAYMENT_METHOD_FAILURE]: changePaymentMethodFailure,
  [PaymentGatewaysTypes.PAYMENT_GATEWAYS_REQUEST_FAILURE]: paymentGatewaysRequestFailure,
  [PaymentGatewaysTypes.CLEAN_STATE]: cleanState,
});
