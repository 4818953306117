import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    letter-spacing: -0.01em;
  }

  :root {
    --color-primary: #04aef6;
    --color-secondary: #0071ee;
    --color-danger: #ea3328;
    --color-danger-2: #d20c03;
    --color-alert: #ffb238;
    --color-success: #04e762;
    --color-white-1: #ffffff;
    --color-white-2: #f3f3f3;
    --color-white-3: #c4c4c4;
    --color-white-4: #fafafa;
    --color-white-5: #f8fafb;
    --color-white-6: #e5e5e5;
    --color-white-7: #f3f9fc;
    --color-black-1: #000000;
    --color-black-2: #333333;
    --color-black-3: #1d2328;
    --color-black-4: #575757;
    --color-black-5: #191d23;
    --color-blue-1: #4A90E2;
    --color-gray-1: #404350;
    --color-gray-2: #2671b5;
    --color-gray-3: #2f3A44;
    --color-gray-4: #353846;
    --color-gray-5: #bdbdbd;
    --color-gray-6: #7b8d9C;
    --color-purple-1: #6045AF;
    --font-size-default: 1.6rem;
    --font-size-smaller: 1.4rem;
    --font-size-title: 2.6rem;
    --font-family-default: 'Work Sans', -apple-system, sans-serif;
    --sidebar-width: 30rem;

    --space-xxs: .4rem;
    --space-xs: .8rem;
    --space-sm: 1.6rem;
    --space: 2.4rem;
    --space-md: 3.2rem;
    --space-lg: 4.8rem;
    --space-xlg: 6.4rem;

    --z-index-100: 100;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  body {
    /* background: linear-gradient(156deg, rgba(0, 113, 238, 0.9) 0%, rgba(4, 174, 246, 0.9) 100%); */
    font-family: var(--font-family-default);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    color: inherit;
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: normal;
  }

  a {
    color: inherit;
    text-decoration: none;
    outline: none;
  }
`;

export default GlobalStyle;
