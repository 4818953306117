import React from "react";
import styled, { css } from "styled-components";
import { withNumberStyle } from "components/core/SideBar/styled";
import { ReactComponent as Filter } from "assets/icons/icon-filter.svg";

export const scrollbarStyle = css`
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--color-secondary);
  }
`;

const filterCountStyle = (count) => css`
  ${withNumberStyle(count)}

  &::after {
    top: -0.3rem;
    left: 1.1rem;
  }
`;

export const IconFilter = styled.span.attrs({
  children: <Filter />,
})`
  margin-right: 1rem;
  ${({ count }) => count > 0 && filterCountStyle(count)}
`;
