import { put, takeLatest } from "redux-saga/effects";
import { PasswordTypes, PasswordActions } from "store/ducks/password";
import api from "services/api";
import { PATCH, POST } from "utils/constants/verbs";
import { FORGOT_PASSWORD, CHANGE_PASSWORD_WITH_TOKEN } from "utils/constants/endpoints";

function* sendTokenByEmail({ payload }) {
  try {
    const response = yield api({
      method: POST,
      url: FORGOT_PASSWORD,
      data: payload,
    });
    yield put(PasswordActions.requestSuccess(response));
  } catch ({ response }) {
    yield put(PasswordActions.requestFailure(response));
  }
}

function* changePasswordWithToken({ payload }) {
  try {
    const response = yield api({
      method: PATCH,
      url: CHANGE_PASSWORD_WITH_TOKEN,
      data: payload,
    });
    yield put(PasswordActions.requestSuccess(response));
  } catch ({ response }) {
    yield put(PasswordActions.requestFailure(response));
  }
}

export function* watchSagas() {
  yield takeLatest(PasswordTypes.SEND_TOKEN_BY_EMAIL, sendTokenByEmail);
  yield takeLatest(PasswordTypes.CHANGE_PASSWORD_WITH_TOKEN, changePasswordWithToken);
}
