import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";

export const globalSettingSchema = Yup.object().shape({
  active: Yup.boolean().required(<Trans i18nKey="required.active" />),
  syncSettings: Yup.boolean().nullable(),
  enterpriseTypeId: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(<Trans i18nKey="required.enterpriseType" />)
    .typeError(<Trans i18nKey="required.enterpriseType" />),
  allowInstallments: Yup.boolean().required(<Trans i18nKey="required.allowInstallments" />),
  allowInstallmentsFirstSubscription: Yup.boolean().required(
    <Trans i18nKey="required.allowInstallmentsFirstSubscription" />,
  ),
  allowedInstallmentsPlanMonthly: Yup.boolean().typeError(
    <Trans i18nKey="required.allowedPlans" />,
  ),
  allowedInstallmentsPlansAnnual: Yup.boolean().typeError(
    <Trans i18nKey="required.allowedPlans" />,
  ),
  maxInstallmentsNumber: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, <Trans i18nKey="min.maxInstallmentsNumber" />)
    .max(12, <Trans i18nKey="max.maxInstallmentsNumber" />)
    .required(<Trans i18nKey="required.maxInstallmentsNumber" />),
  interestType: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(<Trans i18nKey="required.interestType" />),
  interestValue: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required()
    .typeError(<Trans i18nKey="required.interestValue" />)
    .min(0, <Trans i18nKey="min.interestValue" />),
});

export const globalSettingInitialValues = {
  active: true,
  enterpriseTypeId: null,
  allowInstallments: true,
  allowInstallmentsFirstSubscription: true,
  allowedInstallmentsPlansAnnual: true,
  allowedInstallmentsPlansMonthly: false,
  maxInstallmentsNumber: 12,
  interestType: 1,
  interestValue: 1,
  syncSettings: false,
};
