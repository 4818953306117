import React, { lazy, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatActions } from "store/ducks/chat";
import EmptyChat from "components/core/EmptyChat";
import { Grid } from "components/core/Grid";
import WithSuspense from "components/core/WithSuspense";
import ChatRoom from "containers/CustomerService/ChatRoom";
import ChatsContainer from "containers/CustomerService/Chats";

const NewConversation = lazy(() => import("containers/CustomerService/NewChatRoom"));

const NewConversationWithSuspense = WithSuspense(NewConversation);

const CustomerServiceContainer = () => {
  const dispatch = useDispatch();
  const [showNewConversation, setShowNewConversation] = useState(false);
  const { chatRoom } = useSelector(({ chat }) => chat);

  useEffect(() => () => dispatch(ChatActions.cleanupChats()), [dispatch]);

  return (
    <>
      <Grid>
        <ChatsContainer handleNewConversation={() => setShowNewConversation(true)} />
        {chatRoom?.idUser ? (
          <ChatRoom />
        ) : (
          <EmptyChat handleClick={() => setShowNewConversation(true)} />
        )}
      </Grid>
      <NewConversationWithSuspense
        loadComponent={showNewConversation}
        showModal={showNewConversation}
        setShowModal={setShowNewConversation}
        handleCancel={() => setShowNewConversation(false)}
      />
    </>
  );
};

export default CustomerServiceContainer;
