import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { CheckFieldsTypes, CheckFieldsActions } from "store/ducks/checkFields";
import { GET } from "utils/constants";
import { CHECK_FIELD } from "utils/constants/endpoints";

function* checkFields({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: CHECK_FIELD,
      params,
    });
    yield put(CheckFieldsActions.checkFieldsSuccess(data));
  } catch (error) {
    yield put(CheckFieldsActions.checkFieldsFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(CheckFieldsTypes.CHECK_FIELDS, checkFields);
}
