import React, { useEffect, useCallback, useRef, useState } from "react";
import { useParams as urlParams, useHistory } from "react-router-dom";
import { useFinancialSubscription, useRequest } from "hooks";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import SubscriptionEdit from "components/presentation/Financial/Subscriptions/Edit";
import { LINKS } from "utils/constants";
import moment from "moment";

const SubscriptionEditWithSpinner = WithSpinner(SubscriptionEdit);

const SubscriptionEditContainer = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const submitRef = useRef(null);
  const { id } = urlParams();
  const {
    getSubscription,
    handleOnRejected,
    handleOnResolved,
    requestStatus,
    saveSubscription,
    subscription,
    validationErrors,
    cleanState,
  } = useFinancialSubscription();
  const [statusRequest, setStatusRequestStatus] = useState(null);

  const title = `Editar assinatura #${id}`;
  const linkDetails = LINKS.financial.subscriptions.details(id);

  const fetchSubscription = useCallback(() => getSubscription(id), [id]);

  useEffect(() => {
    setStatusRequestStatus(requestStatus.GET_FINANCIAL_SUBSCRIPTION);
  }, [requestStatus.GET_FINANCIAL_SUBSCRIPTION]);

  useEffect(() => {
    setStatusRequestStatus(requestStatus.SAVE_FINANCIAL_SUBSCRIPTION);
  }, [requestStatus.SAVE_FINANCIAL_SUBSCRIPTION]);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  const requestsIsLoading = () => isPending(statusRequest);

  useRequest({
    request: requestStatus.SAVE_FINANCIAL_SUBSCRIPTION,
    onResolved: () =>
      handleOnResolved("saved", () => {
        cleanState();
        history.push(linkDetails);
      }),
    onRejected: () => handleOnRejected("saveError", validationErrors()),
  });

  const handleSubmit = (data) => {
    const formattedData = data;
    formattedData.id = +id;
    formattedData.billed = !formattedData.billed;
    formattedData.expiresAt = moment(data.expiresAt, "DD/MM/YYYY");
    formattedData.interestValue = Number(data.interestValue.replace(",", "."));
    saveSubscription(formattedData);
  };

  return (
    <>
      <NavBar title={title}>
        <Button url={linkDetails} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()} disabled={requestsIsLoading()}>
          {translate("save")}
        </Button>
      </NavBar>
      {subscription && (
        <SubscriptionEditWithSpinner
          requestStatus={statusRequest}
          translate={translate}
          subscription={subscription}
          submitRef={submitRef}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default SubscriptionEditContainer;
