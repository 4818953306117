import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Error } from "assets/svg/error.svg";
import { ReactComponent as Success } from "assets/svg/success.svg";

import * as S from "./styled";

const FeedbackIcon = ({ isSuccess }) => (
  <S.SVGWrapper>{isSuccess ? <Success /> : <Error />}</S.SVGWrapper>
);

FeedbackIcon.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
};

export default FeedbackIcon;
