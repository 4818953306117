import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const Portal = ({ children }) => {
  const [container] = useState(document?.createElement("div"));
  const [portalRoot] = useState(document?.getElementById("portal"));

  useEffect(() => {
    portalRoot.appendChild(container);

    return () => portalRoot.removeChild(container);
  }, [portalRoot, container]);

  return container ? ReactDOM.createPortal(children, container) : null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Portal;
