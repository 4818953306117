import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams, useFinancial, useItems } from "hooks";
import NavBar from "components/core/NavBar";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import InvoicesNextChargesList from "components/presentation/Financial/Invoices/NextCharges";
import Button from "components/core/Button";
import { RowFlex } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import { MASK_TYPES } from "utils/masks";
import { Span } from "components/core";

const InvoicesNextChargesListWithSpinner = WithSpinner(InvoicesNextChargesList);

const InvoicesNextChargesListContainer = () => {
  const [selected, setSelected] = useState(null);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [chargeDateBegin, setChargeDateBegin] = useState(moment().format("YYYY-MM-DD"));
  const [chargeDateEnd, setChargeDateEnd] = useState(moment().add(1, "days").format("YYYY-MM-DD"));
  const [chargeDateBeginField, setChargeDateBeginField] = useState(moment().format("DD/MM/YYYY"));
  const [chargeDateEndField, setChargeDateEndField] = useState(
    moment().add(1, "days").format("DD/MM/YYYY"),
  );
  const { t: translate } = useTranslation();
  const {
    getFinancialInvoicesToCharge,
    invoicesToCharge: { items, total, totalPages },
    requestStatus,
    clearSelection,
    invoiceItems,
    getFinancialInvoiceItems,
    invoiceCharges: { items: invoiceChargesItems },
    getFinancialInvoiceCharges,
  } = useFinancial();
  const {
    everyoneIsChecked,
    selectedItems,
    setSelectedItems,
    handleChange,
    toggleAll,
    listChecked,
    hasOneItemSelected,
    hasSelectedItems,
    removeItemById,
  } = useItems(items);
  const [currentRequestStatus, setCurrentRequestStatus] = useState(
    requestStatus.GET_FINANCIAL_INVOICES_TO_CHARGE,
  );
  const defaultParams = {
    order: "asc",
    page: 1,
    perPage: 10,
    sort: "dueDate",
    chargeDateBegin,
    chargeDateEnd,
  };
  const fetchInvoicesToCharge = (params) => {
    getFinancialInvoicesToCharge({
      ...defaultParams,
      ...params,
    });
  };
  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    setFilterParams,
  } = useParams({
    callback: fetchInvoicesToCharge,
    defaultOrderBy: "dueDate",
    defaultOrder: "asc",
  });

  useEffect(() => {
    if (
      chargeDateBeginField &&
      chargeDateBeginField.length === 10 &&
      chargeDateEndField &&
      chargeDateEndField.length === 10
    ) {
      let dateBegin = moment(chargeDateBeginField, "DD/MM/YYYY");
      let dateEnd = moment(chargeDateEndField, "DD/MM/YYYY");
      if (dateEnd.isBefore(dateBegin)) {
        dateEnd = dateBegin;
        setChargeDateEndField(dateBegin.format("DD/MM/YYYY"));
      }
      setChargeDateBegin(dateBegin.format("YYYY-MM-DD"));
      setChargeDateEnd(dateEnd.format("YYYY-MM-DD"));
    }
  }, [chargeDateBeginField, chargeDateEndField]);

  useEffect(() => {
    const params = {
      ...defaultParams,
      order,
      sort: orderBy,
      chargeDateBegin,
      chargeDateEnd,
    };
    setFilterParams(params);
  }, [chargeDateBegin, chargeDateEnd, orderBy, order]);

  useEffect(() => {
    setCurrentRequestStatus(requestStatus.GET_FINANCIAL_INVOICES_TO_CHARGE);
  }, [requestStatus.GET_FINANCIAL_INVOICES_TO_CHARGE]);

  const refresh = () => {
    const params = { order, page, perPage: 10, sort: orderBy, chargeDateBegin, chargeDateEnd };
    fetchInvoicesToCharge(params);
  };

  const handleDetails = (id) => {
    const invoice = items.find((item) => item.id === id);
    setSelected(invoice);
    getFinancialInvoiceItems(invoice.id);
    getFinancialInvoiceCharges(invoice.id);
    setShowModalDetails(true);
  };

  return (
    <>
      <NavBar
        title={`${translate("Invoices")} - ${translate("Next Charges")}`}
        actions={
          <RowFlex verticalAlign={"center"}>
            <Span>De:</Span>
            <InputForm
              type="text"
              name="chargeDateBegin"
              mask={MASK_TYPES.date}
              marginRight={"2.4rem"}
              marginBottom={"0"}
              value={chargeDateBeginField}
              onChange={(e) => setChargeDateBeginField(e.target.value)}
            />
            <Span>Até:</Span>
            <InputForm
              type="text"
              name="chargeDateEnd"
              mask={MASK_TYPES.date}
              marginRight={"2.4rem"}
              marginBottom={"0"}
              value={chargeDateEndField}
              onChange={(e) => setChargeDateEndField(e.target.value)}
            />
          </RowFlex>
        }
        paddingBottom={"1rem"}
      >
        <Button handleClick={refresh}>{translate("Refresh")}</Button>
        {(hasOneItemSelected || hasSelectedItems) && (
          <>
            <Button handleClick={clearSelection}>
              <span>{translate("Clear selection")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <InvoicesNextChargesListWithSpinner
        requestStatus={currentRequestStatus}
        invoices={listChecked || items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        handleChange={handleChange}
        setSelectedItems={setSelectedItems}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
          setFilterParams,
          chargeDateBegin,
          chargeDateEnd,
        }}
        order={order}
        orderBy={orderBy}
        toggleAll={toggleAll}
        everyoneIsChecked={everyoneIsChecked}
        handleDetails={handleDetails}
        invoiceItems={invoiceItems}
        showModalDetails={showModalDetails}
        setShowModalDetails={setShowModalDetails}
        selected={selected}
        setSelected={setSelected}
        invoiceCharges={invoiceChargesItems}
        invoiceChargesLoaging={isPending(requestStatus.GET_FINANCIAL_INVOICE_CHARGES)}
      />
    </>
  );
};

export default InvoicesNextChargesListContainer;
