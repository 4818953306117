import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: ClientTypes, Creators: ClientActions } = createActions({
  fetchClients: ["params"],
  fetchClientsSuccess: ["payload"],
  selectedClients: ["payload"],
  fetchClientsList: ["params"],
  fetchClientsListSuccess: ["payload"],
  fetchClientById: ["id"],
  fetchClientByIdSuccess: ["payload"],
  clientRequestFailure: ["error", "originalType"],
  cleanState: null,
  selectedClientsClean: null,
});

const INITIAL_STATE = {
  clients: {
    items: [],
  },
  clientsList: {
    items: [],
  },
  clientDetails: {},
  ClientRequests: {
    [ClientTypes.FETCH_CLIENTS]: REQUEST_NOT_STARTED,
    [ClientTypes.FETCH_CLIENTS_LIST]: REQUEST_NOT_STARTED,
    [ClientTypes.FETCH_CLIENT_BY_ID]: REQUEST_NOT_STARTED,
  },
  clientList: [],
};

const fetchClients = (state) => ({
  ...state,
  ClientRequests: {
    ...state.ClientRequests,
    [ClientTypes.FETCH_CLIENTS]: REQUEST_PENDING,
  },
});

const fetchClientsSuccess = (state, { payload }) => ({
  ...state,
  clients: payload,
  ClientRequests: {
    ...state.ClientRequests,
    [ClientTypes.FETCH_CLIENTS]: REQUEST_RESOLVED,
  },
});

const selectedClients = (state, { payload }) => ({
  ...state,
  clientList: payload,
});

const selectedClientsClean = (state) => ({
  ...state,
  clientList: [],
});

const fetchClientsList = (state) => ({
  ...state,
  ClientRequests: {
    ...state.ClientRequests,
    [ClientTypes.FETCH_CLIENTS_LIST]: REQUEST_PENDING,
  },
});

const fetchClientsListSuccess = (state, { payload }) => ({
  ...state,
  clientsList: payload,
  ClientRequests: {
    ...state.ClientRequests,
    [ClientTypes.FETCH_CLIENTS_LIST]: REQUEST_RESOLVED,
  },
});

const fetchClientById = (state) => ({
  ...state,
  ClientRequests: {
    ...state.ClientRequests,
    [ClientTypes.FETCH_CLIENT_BY_ID]: REQUEST_PENDING,
  },
});

const fetchClientByIdSuccess = (state, { payload }) => ({
  ...state,
  clientDetails: payload,
  ClientRequests: {
    ...state.ClientRequests,
    [ClientTypes.FETCH_CLIENT_BY_ID]: REQUEST_RESOLVED,
  },
});

const clientRequestFailure = (state, { error: { response }, originalType }) => ({
  ...state,
  error: response,
  ClientRequests: {
    ...state.ClientRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ClientTypes.FETCH_CLIENTS]: fetchClients,
  [ClientTypes.FETCH_CLIENTS_SUCCESS]: fetchClientsSuccess,
  [ClientTypes.FETCH_CLIENTS_LIST]: fetchClientsList,
  [ClientTypes.FETCH_CLIENTS_LIST_SUCCESS]: fetchClientsListSuccess,
  [ClientTypes.FETCH_CLIENT_BY_ID]: fetchClientById,
  [ClientTypes.FETCH_CLIENT_BY_ID_SUCCESS]: fetchClientByIdSuccess,
  [ClientTypes.CLIENT_REQUEST_FAILURE]: clientRequestFailure,
  [ClientTypes.SELECTED_CLIENTS]: selectedClients,
  [ClientTypes.SELECTED_CLIENTS_CLEAN]: selectedClientsClean,
});
