import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaxesActions } from "store/ducks/taxes";
import PropTypes from "prop-types";
import { Subtitle, Span } from "components/core/Typography";
import { Flex, Row, Columns, Container } from "components/core/Grid";
import { Form } from "components/core/Form";
import { useForm } from "react-hook-form";
import { ItemToSelect } from "components/core/MultiSelect/styled";
import InputForm from "components/core/InputForm";
import RadioButton from "components/core/RadioButton";
import Button from "components/core/Button";
import { MASK_TYPES } from "utils/masks";
import { billingSchema } from "utils/schemas/billing";
import { Trans } from "react-i18next";
import { formatRealToNumber, formatNumberToReal } from "helpers/format";
import * as S from "./styled";

export const Billing = ({ onBack, onContinue, billingRanges }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { meiTaxes } = useSelector(({ taxes }) => taxes);
  const [billingValidation, setBillingValidation] = useState("");
  const [errorBilling, setErrorBilling] = useState(false);
  const maxBillingMeiValue = meiTaxes?.price;

  const validation = billingRanges.length === 0 && billingSchema;
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: billingRanges.length > 0 && validation,
    mode: "onBlur",
    submitFocusError: true,
  });
  const handleBilling = ({ target: { value } }) => {
    setBillingValidation(formatRealToNumber(value));
  };

  useEffect(() => {
    dispatch(TaxesActions.getMEITaxes());
  }, [dispatch]);

  useEffect(() => {
    if (isChecked) {
      setValue("billing", 0);
      setErrorBilling(false);
    }
  }, [isChecked, setValue]);

  useEffect(() => {
    if (Number(billingValidation) > Number(maxBillingMeiValue)) {
      setErrorBilling(true);
    } else {
      setErrorBilling(false);
    }
  }, [maxBillingMeiValue, billingValidation, setErrorBilling]);

  const erroBilling = (value) => {
    if (!value) return errors.billing?.message;
    if (value)
      return (
        <Trans i18nKey="maxBillingMEI" values={{ value: formatNumberToReal(maxBillingMeiValue) }} />
      );
  };

  const formatBillingRange = (billing, billingMax, billingMin) => {
    let text = "";
    if (billingMax === null || billingMax === undefined) {
      text = `Acima de ${Number(billingMin)?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })}`;
    }
    if (billingMax && billingMin) {
      text = `Entre ${Number(billingMin)?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })} e ${Number(billingMax)?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })}`;
    }
    if (billing === "Não sei") {
      text = "Não sei";
    }
    return text;
  };

  const handleNextStep = (data) => {
    const event = "EtapaFaturamento";
    data.oldIdBillingRange = data.idBillingRange;
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="64%" />
        </Container>
        <Subtitle mb="64px">Qual o faturamento médio mensal da sua empresa?</Subtitle>
        <Form onSubmit={handleSubmit(handleNextStep)}>
          <Flex marginBottom="4rem">
            <Columns>
              {billingRanges.length > 0 ? (
                billingRanges?.map(({ billing, billingMax, billingMin, id }) => (
                  <ItemToSelect key={id}>
                    <RadioButton
                      register={register}
                      name="idBillingRange"
                      label={formatBillingRange(billing, billingMax, billingMin)}
                      id={`${id}`}
                      value={id}
                      handleChange={() => setIsChecked(true)}
                    />
                    {billing === "Não sei" && (
                      <Span>
                        Será apresentado um plano básico. Não se preocupe, você poderá contatar a
                        Obvia e alterar a qualquer momento.
                      </Span>
                    )}
                  </ItemToSelect>
                ))
              ) : (
                <>
                  <InputForm
                    register={register({ required: <Trans i18nKey="billingRequired" /> })}
                    type="text"
                    name="billing"
                    label="Faturamento Mensal"
                    placeholder="Digite o faturamento mensal"
                    onBlur={handleBilling}
                    errorMsg={erroBilling(errorBilling)}
                    mask={MASK_TYPES.real}
                    maxLength="12"
                    marginBottom="2rem"
                    disabled={isChecked}
                  />
                  <RadioButton
                    register={register}
                    name="billing"
                    label="Não sei."
                    id="isBilling"
                    value="0"
                    checked={isChecked}
                    handleChange={() => setIsChecked(true)}
                    onClick={() => setIsChecked(false)}
                  />
                </>
              )}
            </Columns>
          </Flex>
          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
            <Button
              type="submit"
              disabled={errorBilling || (billingRanges.length > 0 && !isChecked)}
            >
              Continuar
            </Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

Billing.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  billingRanges: PropTypes.array,
};
Billing.defaultProps = {
  billingRanges: [],
};

export default Billing;
