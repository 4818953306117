import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: ProductsTypes, Creators: ProductsActions } = createActions({
  fetchProductsDetails: ["id"],
  fetchProductsDetailsSuccess: ["payload"],
  fetchProductsLocations: null,
  fetchProductsLocationsSuccess: ["payload"],
  fetchAvailableProducts: ["params"],
  fetchAvailableProductsSuccess: ["payload"],
  editProducts: ["id", "payload"],
  editProductsSuccess: ["payload"],
  deleteProducts: ["payload"],
  deleteProductsSuccess: ["payload"],
  productsRequestFailure: ["error", "originalType"],
  cleanProducts: null,
});

const INITIAL_STATE = {
  productsDetails: {},
  productsLocations: [],
  availableProducts: [],
  ProductsRequests: {
    FETCH_PRODUCTS_DETAILS: REQUEST_NOT_STARTED,
    FETCH_PRODUCTS_LOCATIONS: REQUEST_NOT_STARTED,
    FETCH_AVAILABLE_PRODUCTS: REQUEST_NOT_STARTED,
    ADD_PRODUCTS_TO_EMITTER: REQUEST_NOT_STARTED,
    DELETE_PRODUCTS: REQUEST_NOT_STARTED,
  },
};

const fetchProductsDetails = (state) => ({
  ...state,
  ProductsRequests: {
    ...state.ProductsRequests,
    FETCH_PRODUCTS_DETAILS: REQUEST_PENDING,
  },
});

const fetchProductsDetailsSuccess = (state, { payload = {} }) => ({
  ...state,
  productsDetails: payload,
  ProductsRequests: {
    ...state.ProductsRequests,
    FETCH_PRODUCTS_DETAILS: REQUEST_RESOLVED,
  },
});

const fetchProductsLocations = (state) => ({
  ...state,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.FETCH_PRODUCTS_LOCATIONS]: REQUEST_PENDING,
  },
});

const fetchProductsLocationsSuccess = (state, { payload = {} }) => ({
  ...state,
  productsLocations: payload,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.FETCH_PRODUCTS_LOCATIONS]: REQUEST_RESOLVED,
  },
});

const fetchAvailableProducts = (state) => ({
  ...state,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.FETCH_AVAILABLE_PRODUCTS]: REQUEST_PENDING,
  },
});

const fetchAvailableProductsSuccess = (state, { payload = [] }) => ({
  ...state,
  availableProducts: payload,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.FETCH_AVAILABLE_PRODUCTS]: REQUEST_RESOLVED,
  },
});

const editProducts = (state) => ({
  ...state,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.EDIT_PRODUCTS]: REQUEST_PENDING,
  },
});

const editProductsSuccess = (state, { payload = {} }) => ({
  ...state,
  productsDetails: payload,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.EDIT_PRODUCTS]: REQUEST_RESOLVED,
  },
});

const deleteProducts = (state) => ({
  ...state,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.DELETE_PRODUCTS]: REQUEST_PENDING,
  },
});

const deleteProductsSuccess = (state, { payload = {} }) => ({
  ...state,
  productsDetails: payload,
  ProductsRequests: {
    ...state.ProductsRequests,
    [ProductsTypes.DELETE_PRODUCTS]: REQUEST_RESOLVED,
  },
});

const productsRequestFailure = (state, { response }, requestName) => ({
  ...state,
  error: response,
  ProductsRequests: {
    ...state.ProductsRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanProducts = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [ProductsTypes.FETCH_PRODUCTS_DETAILS]: fetchProductsDetails,
  [ProductsTypes.FETCH_PRODUCTS_DETAILS_SUCCESS]: fetchProductsDetailsSuccess,
  [ProductsTypes.FETCH_PRODUCTS_LOCATIONS]: fetchProductsLocations,
  [ProductsTypes.FETCH_PRODUCTS_LOCATIONS_SUCCESS]: fetchProductsLocationsSuccess,
  [ProductsTypes.FETCH_AVAILABLE_PRODUCTS]: fetchAvailableProducts,
  [ProductsTypes.FETCH_AVAILABLE_PRODUCTS_SUCCESS]: fetchAvailableProductsSuccess,
  [ProductsTypes.EDIT_PRODUCTS]: editProducts,
  [ProductsTypes.EDIT_PRODUCTS_SUCCESS]: editProductsSuccess,
  [ProductsTypes.DELETE_PRODUCTS]: deleteProducts,
  [ProductsTypes.DELETE_PRODUCTS_SUCCESS]: deleteProductsSuccess,
  [ProductsTypes.PRODUCTS_REQUEST_FAILURE]: productsRequestFailure,
  [ProductsTypes.CLEAN_PRODUCTS]: cleanProducts,
});
