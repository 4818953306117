import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as S from "./styled";

const ButtonLink = ({ url, children, ...props }) => (
  <S.Button as={Link} to={url} {...props}>
    {children}
  </S.Button>
);

const Button = ({
  type,
  children,
  handleClick,
  variant,
  url,
  disabled,
  width,
  color,
  shape,
  size,
  ...otherProps
}) => {
  if (url) {
    return (
      <ButtonLink
        url={url.toString()}
        type={type}
        onClick={handleClick}
        variant={variant}
        disabled={disabled}
        width={width}
        size={size}
        {...otherProps}
      >
        {children}
      </ButtonLink>
    );
  }

  return (
    <S.Button
      type={type}
      onClick={handleClick}
      variant={variant}
      disabled={disabled}
      width={width}
      color={color}
      shape={shape}
      size={size}
      {...otherProps}
    >
      {children}
    </S.Button>
  );
};

ButtonLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
  url: PropTypes.string.isRequired,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
    PropTypes.node,
  ]),
  handleClick: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "outline", "text"]),
  url: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.string,
  size: PropTypes.string,
};

Button.defaultProps = {
  handleClick: null,
  variant: "primary",
  url: "",
  type: "button",
  disabled: false,
  width: null,
  color: "default",
  shape: "default",
  size: "default",
};

export default Button;
