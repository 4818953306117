import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Columns, Container } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import Checkbox from "components/core/Checkbox";
import SelectForm from "components/core/SelectForm";
import { Subtitle } from "components/core/Typography";
import { Form, InputHidden } from "components/core/Form";
import { Label } from "components/core/InputForm/styled";
import { ErrorMsg } from "components/core/InputForm/styled";

const GlobalSettingEdit = ({
  validationSchema,
  handleSubmitForm,
  submitRef,
  defaultValues,
  errorPlans,
}) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });
  const selectOptions = () => {
    return [
      { text: translate("Yes"), value: true },
      { text: translate("No"), value: false },
    ];
  };
  const selectEnterpriseTypeOptions = () => {
    return [
      { text: "MEI", value: 1 },
      { text: "Simples", value: 2 },
      { text: "Lucro Presumido", value: 3 },
    ];
  };
  const selectInterestTypeOptions = () => {
    return [
      { text: "Ao mês", value: 1 },
      { text: "Ao ano", value: 2 },
    ];
  };
  const selectMaxInstallmentsOptions = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ text: i, value: i });
    }
    return options;
  };
  return (
    <>
      <Container maxWidth="md">
        <Subtitle>{translate("editInformations")}</Subtitle>
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
          <Columns columns="2">
            <div style={{ marginRight: "1rem" }}>
              <SelectForm
                register={register}
                name="active"
                label={`${translate("Active")}?`}
                options={selectOptions()}
                errorMsg={errors.active?.message}
              />
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <SelectForm
                register={register}
                name="enterpriseTypeId"
                label={`${translate("Enterprise Type")}?`}
                options={selectEnterpriseTypeOptions()}
                errorMsg={errors.enterpriseTypeId?.message}
              />
            </div>
          </Columns>
          <Columns columns="2">
            <div style={{ marginRight: "1rem" }}>
              <SelectForm
                register={register}
                name="allowInstallments"
                label={`${translate("Allow Installments")}?`}
                options={selectOptions()}
                errorMsg={errors.allowInstallments?.message}
              />
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <SelectForm
                register={register}
                name="allowInstallmentsFirstSubscription"
                label={`${translate("Allow Installments First Subscription")}?`}
                options={selectOptions()}
                errorMsg={errors.allowInstallmentsFirstSubscription?.message}
              />
            </div>
          </Columns>
          <Columns columns="2">
            <div style={{ marginBottom: "1.5rem" }}>
              <Label>{translate("Allowed Plans")}</Label>
              <Columns columns="3">
                <div style={{ marginRight: "1rem" }}>
                  <Checkbox
                    register={register}
                    name="allowedInstallmentsPlanMonthly"
                    label={`${translate("Monthly")}`}
                  />
                </div>
                <div style={{ marginRight: "1rem" }}>
                  <Checkbox
                    register={register}
                    name="allowedInstallmentsPlanAnnual"
                    label={`${translate("Annual")}`}
                  />
                </div>
                <div>
                  <Checkbox
                    register={register}
                    name="allowedInstallmentsPlanBiennial"
                    label={`${translate("bienal")}`}
                  />
                </div>
              </Columns>
              {errorPlans && <ErrorMsg>{translate("required.allowedPlans")}</ErrorMsg>}
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <SelectForm
                register={register}
                name="maxInstallmentsNumber"
                label={`${translate("Maximum Installments Number")}`}
                options={selectMaxInstallmentsOptions()}
              />
            </div>
          </Columns>
          <Columns columns="2">
            <div style={{ marginRight: "1rem" }}>
              <SelectForm
                register={register}
                name="interestType"
                label={`${translate("Interest Type")}`}
                options={selectInterestTypeOptions()}
              />
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <InputForm
                register={register}
                name="interestValue"
                label={`${translate("Interest Value")}`}
                errorMsg={errors.interestValue?.message}
              />
            </div>
          </Columns>
          {/* <Columns>
            <div style={{ marginRight: "1rem" }}>
              <Checkbox
                register={register}
                name="syncSettings"
                label={`${translate("Update emitters settings")}`}
              />
            </div>
          </Columns> */}
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
      </Container>
    </>
  );
};

GlobalSettingEdit.propTypes = {
  submitRef: PropTypes.object.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default GlobalSettingEdit;
