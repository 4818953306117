import React from "react";
import ErrorBoundary from "components/core/ErrorBoundary";
import {
  REQUEST_PENDING,
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants/request";
import { Spinner, SpinnerOverlay } from "./styled";
import { Span } from "components/core/Typography";
import { ColumnFlex } from "components/core/Grid";

export const isPending = (status) => status === REQUEST_PENDING;

export const isNotInit = (status) => status === REQUEST_NOT_STARTED;

export const isResolved = (status) => status === REQUEST_RESOLVED;

export const isRejected = (status) => status === REQUEST_REJECTED;

export const isInProgress = (status) => isPending(status) || isNotInit(status);

const anyInProgress = (requests) =>
  Object.keys(requests ?? {}).some((key) => isPending(requests[key]));

const WithSpinner =
  (WrappedComponent) =>
  ({
    isLoading,
    requestStatus,
    multipleRequests,
    spinnerHeight,
    setShowModal,
    message,
    ...otherProps
  }) => {
    if (isLoading || isInProgress(requestStatus) || anyInProgress(multipleRequests)) {
      return (
        <SpinnerOverlay minHeight={spinnerHeight}>
          <ColumnFlex>
            <Spinner />
            {message && <Span style={{ marginTop: "0.5rem" }}>{message}</Span>}
          </ColumnFlex>
        </SpinnerOverlay>
      );
    }

    return (
      <ErrorBoundary setShowModal={setShowModal}>
        <WrappedComponent setShowModal={setShowModal} {...otherProps} />
      </ErrorBoundary>
    );
  };

export default WithSpinner;
