import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { useRequest } from "hooks";
import { NcmGlobalsActions } from "store/ducks/ncmGlobals";
import { LayoutActions } from "store/ducks/layout";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import NcmCategoryRegister from "components/presentation/NcmGlobals/Register/NewCategory";
import { Span } from "components/core/Typography";
import { ncmCategorySchema, ncmCategoryInitialValues } from "utils/schemas/ncmCategory";
import { LINKS } from "utils/constants";

const NcmCategoryRegisterWithSpinner = WithSpinner(NcmCategoryRegister);

const NcmCategoryRegisterContainer = () => {
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [createdNcmCategoryname, setCreatedNcmCategoryname] = useState();
  const { NcmGlobalsRequests } = useSelector(({ ncmGlobals }) => ncmGlobals);

  const handleSubmit = ({ name, ...rest }) => {
    const payload = { ...rest };
    payload.name = name;
    dispatch(NcmGlobalsActions.createNcmCategory(payload));
    setCreatedNcmCategoryname(name);
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans
              i18nKey="ncmCategoryCreated"
              values={{ name: createdNcmCategoryname }}
              components={{ bold: <strong /> }}
            />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(NcmGlobalsActions.cleanState());

    history.push(LINKS.ncmGlobalsList);
  };

  useRequest({
    request: NcmGlobalsRequests.createNcmCategory,
    onResolved,
  });

  return (
    <NcmCategoryRegisterWithSpinner
      isLoading={isPending(NcmGlobalsRequests.createNcmCategory)}
      handleClick={() => submitRef.current.click()}
      handleNcmCategoryRegister={handleSubmit}
      defaultValues={ncmCategoryInitialValues}
      validationSchema={ncmCategorySchema}
      submitRef={submitRef}
    />
  );
};

export default NcmCategoryRegisterContainer;
