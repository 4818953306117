import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { POST } from "utils/constants/verbs";
import { InterTypes, InterActions } from "store/ducks/interbank";

export function* fetchLeads({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/interBank/inter-leads",
      data: payload,
    });

    yield put(InterActions.fetchLeadsSuccess(data));
  } catch (error) {
    yield put(InterActions.requestFailure(error, type));
  }
}

export function* requestCheckoutInter({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/interBank/inter-checkout",
      data: payload,
    });

    yield put(InterActions.requestCheckoutInterSuccess(data));
  } catch (error) {
    yield put(InterActions.requestFailure(error, type));
  }
}

export function* editLead({ payload, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/interBank/edit-lead",
      data: payload,
    });

    yield put(InterActions.editLeadSuccess(data));
  } catch (error) {
    yield put(InterActions.requestFailure(error, type));
  }
}

export function* sendEmailLead({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: "/interBank/send-email",
      data: params,
    });

    yield put(InterActions.sendEmailLeadSuccess(data));
  } catch (error) {
    yield put(InterActions.requestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(InterTypes.FETCH_LEADS, fetchLeads);
  yield takeLatest(InterTypes.REQUEST_CHECKOUT_INTER, requestCheckoutInter);
  yield takeLatest(InterTypes.SEND_EMAIL_LEAD, sendEmailLead);
  yield takeLatest(InterTypes.EDIT_LEAD, editLead);
}
