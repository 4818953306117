export const taxesEnum = {
  payrollPrice: 1,
  additionalBilletPrice: 2,
  openingTaxSimple: 4,
  openingTaxPresumed: 5,
  anualDiscount: 6,
  meiId: 1,
  simpleId: 2,
  presumedId: 3,
  meiOpeningTax: 3,
  meiTaxCalc: 7,
  meiMinimumCalcPrice: 8,
  meiMaximumMonthBilling: 9,
};

export const authenticationTypeEnum = {
  idUsernamePassword: 2,
};

export const getTaxByEnum = (taxes) => (idTaxEnum) =>
  taxes?.find(({ idTax }) => idTax === idTaxEnum);
