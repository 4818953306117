import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import Autocomplete from "components/core/Autocomplete";
import NavBar from "components/core/NavBar";
import { Subtitle } from "components/core/Typography";
import { Container } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import { MASK_TYPES } from "utils/masks";
import { LINKS } from "utils/constants";

const NcmGlobalsRegister = ({
  handleClick,
  handleNcmGlobalsRegister,
  defaultValues,
  validationSchema,
  handleSearch,
  submitRef,
  ncmItems,
  isLoadingNcmItems,
  UnitMeasure,
  lastItemRefMeasure,
  handleSearchUnitMeasure,
  isLoadingUnitMeasureItems,
  lastItemRefNCM,
}) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema,
    defaultValues: { ...defaultValues },
    mode: "onBlur",
    submitFocusError: true,
  });

  const handleChange = (item) => {
    setValue("idNcmCategory", item.id);
  };

  const handleChangeUnitMeasure = (item) => {
    setValue("idUnitMeasure", item.id);
  };

  return (
    <>
      <NavBar title={translate("newNcmGlobals")}>
        <Button url={`${LINKS.ncmGlobalsList}`} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={handleClick}>{translate("save")}</Button>
      </NavBar>
      <Container maxWidth="md">
        <Subtitle>{translate("addInformations")}</Subtitle>
        <Form onSubmit={handleSubmit(handleNcmGlobalsRegister)}>
          <InputForm
            register={register}
            type="text"
            name="code"
            label={`${translate("ncmCode")} *`}
            errorMsg={errors.code?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="name"
            label={`${translate("description")} *`}
            errorMsg={errors.name?.message}
          />
          <Autocomplete
            register={register}
            type="text"
            nameValue="ncm"
            label={`${translate("categoryLinked")} *`}
            handleSearch={handleSearch}
            handleChange={handleChange}
            items={ncmItems}
            isLoadingItems={isLoadingNcmItems}
            setValue={setValue}
            lastItemRef={lastItemRefNCM}
          />
          <InputHidden ref={register} name="idNcmCategory" />
          <Autocomplete
            register={register}
            type="text"
            label={`${translate("unitMeasure")} *`}
            nameValue="unitMeasure"
            handleSearch={handleSearchUnitMeasure}
            handleChange={handleChangeUnitMeasure}
            isLoadingItems={isLoadingUnitMeasureItems}
            items={UnitMeasure}
            setValue={setValue}
            lastItemRef={lastItemRefMeasure}
          />
          <InputHidden ref={register} name="idUnitMeasure" />
          <SelectForm
            register={register}
            name="icms"
            label="ICMS *"
            options={[
              {
                value: "Normal",
                text: "Normal",
              },
              {
                value: "ICMSST",
                text: "ICMSST",
              },
            ]}
            errorMsg={errors.icms?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="startTerm"
            label={`${translate("startTerm")} *`}
            mask={MASK_TYPES.date}
            errorMsg={errors.startTerm?.message}
          />
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
      </Container>
    </>
  );
};

NcmGlobalsRegister.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleNcmGlobalsRegister: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  ncmItems: PropTypes.array,
  isLoadingNcmItems: PropTypes.bool,
  UnitMeasure: PropTypes.array.isRequired,
  lastItemRefMeasure: PropTypes.func.isRequired,
  handleSearchUnitMeasure: PropTypes.func.isRequired,
  isLoadingUnitMeasureItems: PropTypes.func.isRequired,
  lastItemRefNCM: PropTypes.func.isRequired,
};
NcmGlobalsRegister.defaultProps = {
  ncmItems: [],
  isLoadingNcmItems: true,
};

export default NcmGlobalsRegister;
