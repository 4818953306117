import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Chat from "containers/CustomerService";
import ClientsList from "containers/Client/List";
import ClientDetails from "containers/Client/Details";
import DocumentsReceivedList from "containers/DocumentsReceived/List";
import DocumentsSentList from "containers/DocumentsSent/List";
import EmittersDetails from "containers/Emitter/Details";
import EmitterEdit from "containers/Emitter/Edit";
import SubscriptionEdit from "containers/Emitter/Edit/Subscription";
import EmittersList from "containers/Emitter/List";
import Home from "containers/Home";
import InvoiceDetails from "containers/Invoice/Details";
import InvoiceList from "containers/Invoice/List";
import InvoiceRPS from "containers/Invoice/RPS";
import Notifications from "containers/Notifications";
import ProfileEdit from "containers/Profile/Edit";
import Profile from "containers/Profile/MyProfile";
import TaxesDetails from "containers/Taxes/Details";
import EditMEITaxes from "containers/Taxes/Edit/MEI";
import EditOtherTypesTaxes from "containers/Taxes/Edit/OtherTypesTaxes";
import GeneralTaxEdit from "containers/Taxes/General";
import UserDetails from "containers/User/Details";
import UserEdit from "containers/User/Edit";
import UserList from "containers/User/List";
import UserRegister from "containers/User/Register";
import DigitalAuthorization from "containers/DigitalAuthorization";
import MunicipalServices from "containers/MunicipalServices/List";
import MunicipalServicesDetails from "containers/MunicipalServices/Details";
import MunicipalServicesEdit from "containers/MunicipalServices/Edit";
import Products from "containers/Products/List";
import ProductsRegister from "containers/Products/Register";
import ProductsDetails from "containers/Products/Details";
import ProductsEdit from "containers/Products/Edit";
import Ncm from "containers/Ncm/List";
import Cfop from "containers/Cfop/List";
import Cst from "containers/Cst/List";
import NcmGlobals from "containers/NcmGlobals/List";
import NcmGlobalsRegister from "containers/NcmGlobals/Register/NewNcm";
import NcmGlobalsEdit from "containers/NcmGlobals/Edit";
import NcmCategoryRegister from "containers/NcmGlobals/Register/NewCategory";
import Cities from "containers/Cities/List";
import Coupons from "containers/Coupons/List";
import CouponsDetails from "containers/Coupons/Details";
import CouponsEdit from "containers/Coupons/Edit";
import CouponsRegister from "containers/Coupons/Register";
import Batches from "containers/Batches/List";
import BatchesConfirm from "containers/Batches/Confirm";
import BatchesConfirmEditEnterprise from "containers/Batches/Confirm/EditEnterprise";
import BatchesCompany from "containers/Batches/Company";
import BatchesDetails from "containers/Batches/Details";
import Xml from "containers/Xml";
import { ROUTES } from "utils/constants";
import ShieldAction from "components/core/ShieldAction";
import { ROLE_MASTER } from "utils/constants/roles";

import FinancialInvoicesList from "containers/Financial/Invoices/List";
import FinancialInvoiceDetails from "containers/Financial/Invoices/Details";
import FinancialSubscriptionsList from "containers/Financial/Subscriptions/List";
import FinancialSubscriptionsDetails from "containers/Financial/Subscriptions/Details";
import FinancialSubscriptionsEdit from "containers/Financial/Subscriptions/Edit";
import FinancialSubscriptionsNextBillings from "containers/Financial/Subscriptions/NextBillings";

import FinancialInvoicesNextCharges from "containers/Financial/Invoices/NextCharges";

import GlobalInstallmentsSettings from "containers/Financial/GlobalSettings/List";
import GlobalInstallmentsSettingsAdd from "containers/Financial/GlobalSettings/Add";
import GlobalInstallmentsSettingsDetails from "containers/Financial/GlobalSettings/Details";
import GlobalInstallmentsSettingsEdit from "containers/Financial/GlobalSettings/Edit";

import PaymentGateways from "containers/PaymentGateways/List";
import PaymentGatewaysDetails from "containers/PaymentGateways/Details";
import PaymentGatewaysEdit from "containers/PaymentGateways/Edit";

import FileList from "containers/Reports/File/List";
import ReportsPayments from "containers/Reports/payments";
import LeadsInter from "containers/Comercial/LeadsInter";
import EmitterParams from "containers/Params/Emitters";
import Partners from "containers/Params/Emitters/Partners";
import ActivityGroup from "containers/Params/Emitters/activityGroup";
import ResponsibleSale from "containers/Params/Emitters/responsibleSale";
import Sources from "containers/Params/Emitters/sources";
import AccountantCertificates from "containers/Params/AccountantCertificates";

const Private = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route exact path={ROUTES.users.path} component={UserList} />
      <Route exact path={ROUTES.users.children.register.path} component={UserRegister} />
      <Route exact path={ROUTES.users.children.details.path} component={UserDetails} />
      <Route exact path={ROUTES.users.children.edit.path} component={UserEdit} />
      <Route exact path={ROUTES.clients.path} component={ClientsList} />
      <Route exact path={ROUTES.clients.children.details.path} component={ClientDetails} />
      <Route exact path={ROUTES.emitters.path} component={EmittersList} />
      <Route exact path={ROUTES.emitters.children.details.path} component={EmittersDetails} />
      <Route exact path={ROUTES.emitters.children.edit.path} component={EmitterEdit} />
      <Route exact path={ROUTES.emitters.children.subscription.path} component={SubscriptionEdit} />
      <Route exact path={ROUTES.profile.path} component={Profile} />
      <Route exact path={ROUTES.profile.children.edit.path} component={ProfileEdit} />
      <Route exact path={ROUTES.invoices.path} component={InvoiceList} />
      <Route exact path={ROUTES.invoices.children.details.path} component={InvoiceDetails} />
      <Route exact path={ROUTES.invoices.children.rps.path} component={InvoiceRPS} />

      <Route
        exact
        path={ROUTES.documents.children.received.path}
        component={DocumentsReceivedList}
      />
      <Route exact path={ROUTES.documents.children.sent.path} component={DocumentsSentList} />
      <Route exact path={ROUTES.notifications.path} component={Notifications} />
      <Route exact path={ROUTES.customerService.path} component={Chat} />
      <Route exact path={ROUTES.digitalAuthorization.path} component={DigitalAuthorization} />
      <Route exact path={ROUTES.municipalServices.path} component={MunicipalServices} />
      <Route
        exact
        path={ROUTES.municipalServices.children.details.path}
        component={MunicipalServicesDetails}
      />
      <Route
        exact
        path={ROUTES.municipalServices.children.edit.path}
        component={MunicipalServicesEdit}
      />
      <Route exact path={ROUTES.products.children.productsList.path} component={Products} />
      <Route
        exact
        path={ROUTES.products.children.productsList.children.register.path}
        component={ProductsRegister}
      />
      <Route
        exact
        path={ROUTES.products.children.productsList.children.edit.path}
        component={ProductsEdit}
      />
      <Route
        exact
        path={ROUTES.products.children.productsList.children.details.path}
        component={ProductsDetails}
      />
      <Route exact path={ROUTES.products.children.ncm.path} component={Ncm} />
      <Route exact path={ROUTES.products.children.cfop.path} component={Cfop} />
      <Route exact path={ROUTES.products.children.cst.path} component={Cst} />

      <Route exact path={ROUTES.batches.path} component={Batches} />
      <Route exact path={ROUTES.batches.children.confirm.path} component={BatchesConfirm} />
      <Route
        exact
        path={ROUTES.batches.children.confirm.children.editEnterprise.path}
        component={BatchesConfirmEditEnterprise}
      />
      <Route exact path={ROUTES.batches.children.company.path} component={BatchesCompany} />
      <Route exact path={ROUTES.batches.children.details.path} component={BatchesDetails} />
      <Route exact path={ROUTES.coupons.path} component={Coupons} />
      <Route exact path={ROUTES.coupons.children.details.path} component={CouponsDetails} />
      <Route exact path={ROUTES.coupons.children.edit.path} component={CouponsEdit} />
      <Route exact path={ROUTES.coupons.children.register.path} component={CouponsRegister} />

      <ShieldAction roles={[ROLE_MASTER]}>
        <Route exact path={ROUTES.taxes.path} component={TaxesDetails} />
        <Route exact path={ROUTES.taxes.children.general.path} component={GeneralTaxEdit} />
        <Route exact path={ROUTES.taxes.children.mei.path} component={EditMEITaxes} />
        <Route exact path={ROUTES.taxes.children.others.path} component={EditOtherTypesTaxes} />
        <Route exact path={ROUTES.cities.path} component={Cities} />

        <Route exact path={ROUTES.ncmGlobals.path} component={NcmGlobals} />
        <Route exact path={ROUTES.ncmGlobals.children.edit.path} component={NcmGlobalsEdit} />
        <Route
          exact
          path={ROUTES.ncmGlobals.children.register.children.ncmGlobals.path}
          component={NcmGlobalsRegister}
        />
        <Route
          exact
          path={ROUTES.ncmGlobals.children.register.children.ncmCategory.path}
          component={NcmCategoryRegister}
        />
        <Route exact path={ROUTES.xml.path} component={Xml} />

        <Route exact path={ROUTES.paymentGateways.path} component={PaymentGateways} />
        <Route
          exact
          path={ROUTES.financial.children.subscriptions.path}
          component={FinancialSubscriptionsList}
        />
        <Route
          exact
          path={ROUTES.financial.children.subscriptions.children.details.path}
          component={FinancialSubscriptionsDetails}
        />
        <Route
          exact
          path={ROUTES.financial.children.subscriptions.children.edit.path}
          component={FinancialSubscriptionsEdit}
        />
        <Route
          exact
          path={ROUTES.financial.children.nextBillings.path}
          component={FinancialSubscriptionsNextBillings}
        />
        <Route
          exact
          path={ROUTES.financial.children.nextBillings.children.details.path}
          component={FinancialSubscriptionsNextBillings}
        />
        <Route
          exact
          path={ROUTES.financial.children.nextCharges.path}
          component={FinancialInvoicesNextCharges}
        />
        <Route
          exact
          path={ROUTES.financial.children.emitterSubscriptions.path}
          component={FinancialSubscriptionsList}
        />
        <Route
          exact
          path={ROUTES.financial.children.installments.path}
          component={GlobalInstallmentsSettings}
        />
        <Route
          exact
          path={ROUTES.financial.children.installments.children.add.path}
          component={GlobalInstallmentsSettingsAdd}
        />
        <Route
          exact
          path={ROUTES.financial.children.installments.children.details.path}
          component={GlobalInstallmentsSettingsDetails}
        />
        <Route
          exact
          path={ROUTES.financial.children.installments.children.edit.path}
          component={GlobalInstallmentsSettingsEdit}
        />
        <Route
          exact
          path={ROUTES.financial.children.invoices.path}
          component={FinancialInvoicesList}
        />
        <Route
          exact
          path={ROUTES.financial.children.invoices.children.details.path}
          component={FinancialInvoiceDetails}
        />
        <Route exact path={ROUTES.reports.children.reportsfiles.path} component={FileList} />
        <Route
          exact
          path={ROUTES.reports.children.reportPayments.path}
          component={ReportsPayments}
        />
        <Route
          exact
          path={ROUTES.paymentGateways.children.details.path}
          component={PaymentGatewaysDetails}
        />
        <Route
          exact
          path={ROUTES.paymentGateways.children.edit.path}
          component={PaymentGatewaysEdit}
        />
        <Route exact path={ROUTES.Comercial.children.usersBankInter.path} component={LeadsInter} />
        <Route exact path={ROUTES.params.children.paramsEmitters.path} component={EmitterParams} />
        <Route
          exact
          path={ROUTES.params.children.lists.children.activityGroups.path}
          component={ActivityGroup}
        />
        <Route
          exact
          path={ROUTES.params.children.lists.children.responsibleSale.path}
          component={ResponsibleSale}
        />
        <Route
          exact
          path={ROUTES.params.children.lists.children.sources.path}
          component={Sources}
        />
        <Route
          exact
          path={ROUTES.params.children.lists.children.partners.path}
          component={Partners}
        />
        <Route
          exact
          path={ROUTES.params.children.accountantCertificates.path}
          component={AccountantCertificates}
        />
      </ShieldAction>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Private;
