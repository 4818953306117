import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableBody,
  TableItem,
  RenderHeaders,
  TableCheckbox,
  TableHeaderItem,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { urls } from "utils/constants";
import { MASK_TYPES, format } from "utils/masks";
import { REQUEST_PENDING, REQUEST_NOT_STARTED } from "utils/constants/request";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { formatDate } from "helpers";

const List = ({
  handleChange,
  toggleAll,
  everyoneIsChecked,
  subscriptions,
  requestStatus,
  params,
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();
  const {
    LINKS: {
      financial: {
        subscriptions: { details },
      },
    },
  } = urls;
  const headers = [
    { slug: "id", title: translate("ID") },
    { slug: "enterpriseName", title: translate("emitter") },
    { slug: "enterpriseTypeName", title: translate("Emitter Type") },
    { slug: "active", title: `${translate("Active")}?` },
    { slug: "planName", title: `${translate("Plan Type")}` },
    { slug: "price", title: `${translate("Value")}` },
    { slug: "expiresAt", title: `${translate("Due Date")}` },
    { slug: "billingDate", title: `${translate("Next Billing Date")}` },
  ];

  const requestPending = (status) => {
    return status === REQUEST_PENDING || status === REQUEST_NOT_STARTED;
  };

  return requestPending(requestStatus) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={everyoneIsChecked} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {subscriptions.map(
            ({
              enterpriseName,
              userName,
              active,
              id,
              price,
              planName,
              billingDate,
              expiresAt,
              enterpriseTypeName,
              checked,
            }) => (
              <tr key={id}>
                <TableItem>
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => handleChange(id)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="id">
                    {id}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="enterpriseName">
                    {enterpriseName || userName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="enterpriseTypeName">
                    {enterpriseTypeName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="active">
                    <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                    {active ? "Sim" : "Não"}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="planName">
                    {planName}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="price">
                    {format(Number(price).toFixed(2), MASK_TYPES.realWithName)}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="expiresAt">
                    {expiresAt && formatDate(expiresAt, 0, false)}
                  </Link>
                </TableItem>
                <TableItem>
                  <Link to={details(id)} data-cy="billingDate">
                    {billingDate && formatDate(billingDate, 0, false)}
                  </Link>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  subscriptions: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  subscriptions: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
