import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import NavBar from "components/core/NavBar";
import { Subtitle, Small } from "components/core/Typography";
import { Container } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import { getStates, getCitiesByState } from "utils/mocks/brazilCitiesStates";
import { LINKS } from "utils/constants";
import { getRandomString, getLabelHelper } from "helpers";

const UserRegister = ({
  handleClick,
  handleUserRegister,
  defaultValues,
  validationSchema,
  roles,
  submitRef,
}) => {
  const { t: translate } = useTranslation();
  const [cities, setCities] = useState([]);
  const [getRole, setGetRole] = useState(1);
  const { register, handleSubmit, errors, watch } = useForm({
    validationSchema,
    defaultValues: { ...defaultValues, active: true, password: getRandomString() },
    mode: "onBlur",
    submitFocusError: true,
  });
  const watchState = watch("state");
  const watchRole = watch("idRole");

  const states = getStates();

  useEffect(() => {
    setCities(getCitiesByState(watchState));
  }, [watchState]);

  useEffect(() => {
    setGetRole(Number(watchRole));
  }, [watchRole]);

  return (
    <>
      <NavBar title={translate("newUser")}>
        <Button url={`${LINKS.userList}`} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={handleClick}>{translate("save")}</Button>
      </NavBar>
      <Container maxWidth="md">
        <Subtitle>{translate("addInformations")}</Subtitle>
        <Form onSubmit={handleSubmit(handleUserRegister)}>
          <InputForm
            register={register}
            type="text"
            name="name"
            label={translate("name")}
            errorMsg={errors.name?.message}
          />
          <InputForm
            register={register}
            type="email"
            name="email"
            label={translate("email")}
            errorMsg={errors.email?.message}
          >
            <Small>{translate("accessInformationSentByEmail")}</Small>
          </InputForm>
          <InputForm
            register={register}
            type="text"
            name="cpf"
            label={translate("cpf")}
            mask="cpf"
            errorMsg={errors.cpf?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="phoneNumber"
            label={translate("phone")}
            mask="phone"
            errorMsg={errors.phoneNumber?.message}
          />
          <SelectForm
            register={register}
            name="state"
            label={translate("state")}
            options={states.map((state) => ({ text: state, value: state }))}
            errorMsg={errors.state?.message}
          />
          <SelectForm
            register={register}
            name="city"
            label={translate("city")}
            options={cities.map((city) => ({ text: city, value: city }))}
            errorMsg={errors.city?.message}
          />
          <SelectForm
            register={register}
            name="idRole"
            label={translate("profileType")}
            options={roles.map(({ id, roles: label }) => ({
              text: getLabelHelper(label),
              value: id,
            }))}
          />
          {getRole !== 4 && getRole !== 1 && (
            <InputForm
              register={register}
              type="text"
              name="crc"
              label={translate("crc")}
              errorMsg={errors.crc?.message}
            />
          )}
          <InputHidden ref={register} name="active" readOnly />
          <InputHidden ref={register} name="password" readOnly />
          <InputHidden ref={submitRef} type="submit" value="Submit" />
        </Form>
      </Container>
    </>
  );
};

UserRegister.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleUserRegister: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  submitRef: PropTypes.object.isRequired,
};

export default UserRegister;
