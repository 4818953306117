import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ChatActions } from "store/ducks/chat";
import { ReactComponent as IconPlus } from "assets/icons/icon-plus.svg";
import Button from "components/core/Button";
import WithSpinner from "components/core/WithSpinner";
import Chats from "components/presentation/CustomerService/Chats";
import { useParams, useInfinitePagination } from "hooks";
import {
  SearchChat as SearchChatStyled,
  ChatNavbar,
  SearchWrapper,
  ChatsWrapper,
} from "components/presentation/CustomerService/Chats/styled";
import { Title, Actions } from "components/core/NavBar/styled";

const ChatsWithSpinner = WithSpinner(Chats);

const SearchChat = ({ handleChange, placeholder, value }) => (
  <SearchWrapper>
    <SearchChatStyled
      type="search"
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
      autoFocus
    />
  </SearchWrapper>
);

const ChatsContainer = ({ handleNewConversation }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const {
    chats: items,
    ChatRequests,
    chatRoom,
    totalChatsUnread,
  } = useSelector(({ chat }) => chat);
  const [searchName, setSearchName] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [chats, setChats] = useState([]);
  const { page, setCustomParams, setPage } = useParams({
    name: searchName,
    defaultPerPage: 15,
    callback: (params) => dispatch(ChatActions.fetchChats(params)),
  });
  const lastChatRef = useInfinitePagination(ChatRequests.FETCH_CHATS, hasMore, setPage);

  const handleSearch = ({ target: { value } }) => {
    setSearchName(value);
    setCustomParams((values) => ({ ...values, name: value }));
  };

  const handleNewRoom = (chat) => dispatch(ChatActions.startChatRoom(chat));

  useEffect(() => {
    if (page === 1) {
      setChats(items);
    } else if (items?.length) {
      const exist = chats.find((chat) => items.find((item) => item.idUser === chat.idUser));
      if (!exist) setChats((prevItems) => [...new Set([...prevItems, ...items])]);
      else {
        const format = chats.map((chat) => {
          const x = items.find((item) => item.idUser === chat.idUser);

          if (!x) return chat;
          return x;
        });

        setChats(() => [...new Set([...format])]);
      }
    } else if (totalChatsUnread > 0) {
      setChats(items);
    }
    setHasMore(items.length === 15);
  }, [items, page, totalChatsUnread]);

  return (
    <ChatsWrapper>
      <ChatNavbar>
        <Title>{translate("attendance")}</Title>
        <Actions>
          <Button variant="text" handleClick={handleNewConversation}>
            <IconPlus />
          </Button>
        </Actions>
        <SearchChat
          handleChange={handleSearch}
          placeholder={translate("searchConversation")}
          value={searchName}
        />
      </ChatNavbar>
      <ChatsWithSpinner
        chats={chats}
        handleClick={handleNewConversation}
        handleNewRoom={handleNewRoom}
        idUserActive={chatRoom?.idUser}
        lastChatRef={lastChatRef}
      />
    </ChatsWrapper>
  );
};

SearchChat.propTypes = {
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ChatsContainer.propTypes = {
  handleNewConversation: PropTypes.func.isRequired,
};

export default ChatsContainer;
