import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useRequest } from "hooks";
import { CouponsActions } from "store/ducks/coupons";
import { LayoutActions } from "store/ducks/layout";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import CouponsEdit from "components/presentation/Coupons/Edit";

import { LINKS } from "utils/constants";
import { couponsSchema } from "utils/schemas/coupons";
import { formatDateToParam } from "helpers/format";

const CouponsEditWithSpinner = WithSpinner(CouponsEdit);

const CouponsEditContainer = () => {
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { couponsDetails, CouponsRequests } = useSelector(({ coupons }) => coupons);

  useEffect(() => {
    dispatch(CouponsActions.getCouponsById(id));
  }, [id, dispatch]);

  const handleSubmit = (values) => {
    const startDate = formatDateToParam(values?.startDate, "00:00:00", true);
    const endDate = formatDateToParam(values?.endDate, "23:59:00", true);
    const data = { ...values, startDate, endDate };
    dispatch(CouponsActions.editCoupons(id, data));
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{translate("couponsEdited")}</Span>,
        type: "success",
      }),
    );
    dispatch(CouponsActions.cleanState());
    history.push(LINKS.couponsList);
  };

  useRequest({
    request: CouponsRequests.EDIT_COUPONS,
    onResolved,
  });
  return (
    <>
      <NavBar title={translate("editCoupons")}>
        <Button url={LINKS.couponsList} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>
          <span>{translate("save")}</span>
        </Button>
      </NavBar>
      <CouponsEditWithSpinner
        requestStatus={CouponsRequests.getCouponsById}
        handleCouponsEdit={handleSubmit}
        defaultValues={couponsDetails}
        validationSchema={couponsSchema}
        submitRef={submitRef}
      />
    </>
  );
};

export default CouponsEditContainer;
