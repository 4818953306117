import { put, takeLatest, cancelled } from "redux-saga/effects";
import axios from "axios";
import api from "services/api";
import { NcmTypes, NcmActions } from "store/ducks/ncm";
import { GET, PATCH, DELETE, NCM_BY_ID, NCM, ENTERPRISE_NCM } from "utils/constants";

const { CancelToken } = axios;

function* fetchNcmDetails({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: NCM_BY_ID(id),
      });
      yield put(NcmActions.fetchNcmDetailsSuccess(data));
    }
  } catch (error) {
    yield put(NcmActions.ncmRequestFailure(error, type));
  }
}

function* fetchAvailableNcm({ params, type }) {
  const source = CancelToken.source();

  try {
    const { data } = yield api({
      method: GET,
      url: NCM,
      params,
      cancelToken: source.token,
    });
    yield put(NcmActions.fetchAvailableNcmSuccess(data));
  } catch (error) {
    yield put(NcmActions.ncmRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}
function* fetchAvailableNcmWithParams({ id, params, type }) {
  const source = CancelToken.source();

  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: ENTERPRISE_NCM(id),
        params,
        cancelToken: source.token,
      });
      yield put(NcmActions.fetchAvailableNcmWithParamsSuccess(data));
    }
  } catch (error) {
    yield put(NcmActions.ncmRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

function* editNcm({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PATCH,
        url: NCM_BY_ID(id),
        data: payload,
      });
      yield put(NcmActions.editNcmSuccess(data));
    }
  } catch (error) {
    yield put(NcmActions.ncmRequestFailure(error, type));
  }
}

function* deleteNcm({ payload, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: NCM,
      data: payload,
    });
    yield put(NcmActions.deleteNcmSuccess(data));
  } catch (error) {
    yield put(NcmActions.ncmRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(NcmTypes.FETCH_NCM_DETAILS, fetchNcmDetails);
  yield takeLatest(NcmTypes.FETCH_AVAILABLE_NCM, fetchAvailableNcm);
  yield takeLatest(NcmTypes.FETCH_AVAILABLE_NCM_WITH_PARAMS, fetchAvailableNcmWithParams);
  yield takeLatest(NcmTypes.EDIT_NCM, editNcm);
  yield takeLatest(NcmTypes.DELETE_NCM, deleteNcm);
}
