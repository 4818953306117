import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NavBar from "components/core/NavBar";
import Button from "components/core/Button";
import GeneralTaxEdit from "components/presentation/Taxes/GeneralTax";
import { TaxesActions } from "store/ducks/taxes";
import Modal from "components/core/Modal";
import { Span } from "components/core/Typography";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import { urls, REQUEST_RESOLVED } from "utils/constants";
import { parseMoneyToFloat } from "helpers";
import { LayoutActions } from "store/ducks/layout";

const GeneralTaxEditWithSpinner = WithSpinner(GeneralTaxEdit);

const GeneralTaxEditContainer = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [newTaxes, setNewTaxes] = useState(null);
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const { TaxesRequests, generalTaxes } = useSelector(({ taxes }) => taxes);
  const history = useHistory();

  useEffect(() => {
    if (!generalTaxes.length) {
      dispatch(TaxesActions.fetchTaxes());
    }
  }, [dispatch]);

  const handleSubmit = ({ anualDiscount, payrollPrice, additionalBilletPrice, annualPrice }) => {
    setShowConfirm(true);
    setNewTaxes({
      payrollPrice: parseMoneyToFloat(payrollPrice),
      additionalBilletPrice: parseMoneyToFloat(additionalBilletPrice),
      anualDiscount: (parseMoneyToFloat(anualDiscount) / 100 - 1) * -1,
      annualPrice: parseMoneyToFloat(annualPrice),
    });
  };

  const dispatchNewTaxes = () => dispatch(TaxesActions.editGeneralTaxes(newTaxes));

  useEffect(() => {
    if (TaxesRequests.editGeneralTaxes === REQUEST_RESOLVED) {
      dispatch(
        LayoutActions.showConfirmationModal({
          content: <Span>Alterações salvas com sucesso.</Span>,
          type: "success",
        }),
      );
      dispatch(TaxesActions.cleanState());
      history.push(urls.ROUTES.taxes.path);
    }
  }, [TaxesRequests.editGeneralTaxes]);

  return (
    <>
      <NavBar title="Editar Taxas">
        <Button url={urls.ROUTES.taxes.path} variant="outline">
          Cancelar
        </Button>
        <Button handleClick={() => submitRef.current.click()}>Salvar</Button>
      </NavBar>
      <GeneralTaxEditWithSpinner
        isLoading={isPending(TaxesRequests.editGeneralTaxes)}
        requestStatus={TaxesRequests.fetchTaxes}
        submitRef={submitRef}
        onSubmit={handleSubmit}
      />
      <Modal
        showModal={showConfirm}
        onCloseModal={() => setShowConfirm(false)}
        onConfirmModal={dispatchNewTaxes}
        title="Editar taxas"
        width="49rem"
      >
        <Span>
          <strong>Atenção</strong>: As alterações nas taxas serão aplicadas à todos os planos.
        </Span>
      </Modal>
    </>
  );
};

export default GeneralTaxEditContainer;
