import { useEffect, memo } from "react";
import { useFormContext } from "react-hook-form";
import { EnterpriseActions } from "store/ducks/enterprise";
import { useDispatch } from "react-redux";
import { parseMoneyToFloat } from "helpers/sanitize";

const FormPlansParamsObserver = ({
  isMEI,
  idEnterpriseType,
  billingRanges,
  activeSubscriptionId,
}) => {
  const { watch } = useFormContext();
  const watchFields = watch([
    "newEnterprise",
    "numberEmployees",
    "idBillingRange",
    "billing",
    "discountType",
    "discountValue",
  ]);
  const {
    newEnterprise,
    numberEmployees,
    idBillingRange,
    billing,
    discountType,
    discountValue,
  } = watchFields;
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      newEnterprise,
      numberEmployees,
      idEnterpriseType: Number(idEnterpriseType),
      activeSubscriptionId,
      discountType,
      discountValue,
    };

    if (isMEI && billing && Number(idEnterpriseType) === 1) {
      params.billing = parseMoneyToFloat(billing);
    } else {
      params.idBillingRange = +idBillingRange;
    }

    if (idEnterpriseType && numberEmployees && (idBillingRange || billing)) {
      if (
        Number(idEnterpriseType) === 2 ||
        Number(idEnterpriseType) === 3 ||
        Number(idEnterpriseType) === 4
      ) {
        if (
          Number(idBillingRange) !== 0 &&
          !Number.isNaN(Number(idBillingRange)) &&
          idBillingRange
        ) {
          const exits = billingRanges[idEnterpriseType]?.find(
            (item) => item?.id === Number(idBillingRange),
          );
          if (exits) {
            dispatch(EnterpriseActions.getSubscriptionPlans(params));
          }
        }
      } else if (Number(idEnterpriseType) === 1) {
        if (billing) {
          dispatch(EnterpriseActions.getSubscriptionPlans(params));
        }
      }
    }
  }, [
    billing,
    billingRanges,
    dispatch,
    idBillingRange,
    idEnterpriseType,
    isMEI,
    newEnterprise,
    numberEmployees,
    discountType,
    discountValue,
  ]);

  return null;
};

export default memo(FormPlansParamsObserver);
