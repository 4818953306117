import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { ClientActions } from "store/ducks/client";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import ClientDetails from "components/presentation/Client/Details";

const ClientDetailsWithSpinner = WithSpinner(ClientDetails);

const ClientDetailsContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { clientDetails, ClientRequests } = useSelector(({ client }) => client);

  const dispatchFetchClient = useCallback(() => dispatch(ClientActions.fetchClientById(id)), [id]);

  useEffect(() => {
    dispatchFetchClient();
  }, [dispatchFetchClient]);

  return (
    <>
      <NavBar title={translate("clientDetails")}>
        <Button handleClick={() => history.goBack()} variant="outline">
          {translate("back")}
        </Button>
      </NavBar>
      <ClientDetailsWithSpinner
        requestStatus={ClientRequests.FETCH_CLIENT_BY_ID}
        client={clientDetails}
      />
    </>
  );
};

export default ClientDetailsContainer;
