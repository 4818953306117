import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "components/core/NavBar";
import XmlPresentation from "components/presentation/Xml";
import { EnterpriseActions } from "store/ducks/enterprise";
import WithSpinner from "components/core/WithSpinner";

const XMLWithSpinner = WithSpinner(XmlPresentation);

const Xml = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { loadingXml, files } = useSelector(({ enterprise }) => enterprise);

  useEffect(() => {
    if (files) {
      const link = document.createElement("a");
      link.href = files;
      link.setAttribute("download", "arquivos.zip");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      dispatch(EnterpriseActions.resetFiles());
    }
  }, [dispatch, files]);

  const handleXmlExport = () => {
    dispatch(EnterpriseActions.getEnterpriseXml());
  };

  return (
    <>
      <NavBar title={translate("xmlScreen")} />
      <XMLWithSpinner isLoading={loadingXml} handleXmlExport={handleXmlExport} />
    </>
  );
};

export default Xml;
