import { useEffect, useRef, useState } from "react";
import { isPending } from "components/core/WithSpinner";

const useInfiniteScrollTop = (requestStatus, hasMore, setPage) => {
  const [node, setNode] = useState(null);
  const observer = useRef(
    new window.IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        threshold: 0.5,
      },
    ),
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();
    if (!hasMore) {
      setPage(1);
      return () => currentObserver.disconnect();
    }
    if (node) currentObserver.observe(node);
    return () => currentObserver.disconnect();
  }, [node, hasMore, requestStatus, setPage]);

  if (isPending(requestStatus)) return setNode;
  return setNode;
};

export default useInfiniteScrollTop;
