import styled, { css } from "styled-components";
import { scrollbarStyle } from "components/core/Layout";

const asButtonStyle = css`
  display: flex;
  justify-content: space-between;
`;

const withContentStyle = (content) => css`
  position: relative;

  &::after {
    content: "${content}";
    top: 50%;
    right: 1rem;
    position: absolute;
    font-size: var(--font-size-default);
  }
`;

const loadingStyle = css`
  position: relative;

  &::after {
    background: var(--color-white-1);
    border: 3px solid var(--color-secondary);
    border-radius: 50%;
    border-top-color: var(--color-white-1);
    content: "";
    top: 50%;
    right: 20px;
    position: absolute;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Label = styled.label`
  color: ${({ hasError }) => (hasError ? "var(--color-danger)" : "var(--color-black-2)")};
  font-size: 1.2rem;
  display: block;
  margin-bottom: 0.4rem;
  margin-right: 0.4rem;
  text-transform: uppercase;
  transition: all 0.3s linear;
`;

export const Field = styled.input.attrs(
  ({ as, hasError, disabled, isLoading, borderRadius, border }) => ({
    asButton: as === "button" && asButtonStyle,
    borderBottomColor: hasError ? "var(--color-danger)" : "var(--color-black-2)",
    disabled: disabled || isLoading,
    borderRadius: borderRadius ?? "0.8rem 0.8rem 0 0",
    border: border ?? "none",
  }),
)`
  appearance: none;
  background-color: var(--color-white-2);
  border: ${({ border }) => border};
  border-bottom: 1px solid;
  border-bottom-color: ${({ borderBottomColor }) => borderBottomColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  color: var(--color-back-2);
  padding: 0.8rem 1.2rem;
  font-family: inherit;
  font-size: var(--font-size-default);
  font-weight: 300;
  height: 4rem;
  width: 100%;
  overflow: hidden;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }

  &:disabled {
    background-color: var(--color-white-4);
  }

  ${({ asButton }) => asButton};

  &:focus {
    border-bottom-color: var(--color-secondary);
  }
`;

const inputWrapperRowStyle = css`
  flex-wrap: wrap;

  ${Field} {
    flex: 1 0 80%;
  }
`;

export const InputWrapper = styled.div.attrs(
  ({ direction, horizontalAlign, verticalAlign, flexWidth, marginBottom, marginRight }) => ({
    flexDirection: direction ?? "column",
    justifyContent: horizontalAlign ?? "flex-end",
    alignItems: verticalAlign ?? "unset",
    flexWidth: flexWidth ?? "100%",
    marginBottom: marginBottom ?? "2.4rem",
    marginRight: marginRight ?? "unset",
  }),
)`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ verticalAlign }) => verticalAlign};
  position: relative;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-right: ${({ marginRight }) => marginRight};
  width: 100%;
  flex: ${({ flexWidth }) => flexWidth} !important;
  text-align: left;

  ${({ flexDirection }) => flexDirection === "row" && inputWrapperRowStyle}
  ${({ content }) => content && withContentStyle(content)}
  ${({ isLoading }) => isLoading && loadingStyle}

  &:focus-within {
    ${Label} {
      color: var(--color-secondary);
    }
  }
`;

export const ErrorMsg = styled.span`
  color: var(--color-danger);
  font-size: 1.6rem;
  line-height: 2rem;
  margin-top: 0.45rem;
  flex: 1;
`;

export const InputWithTextArea = styled.textarea.attrs(({ height }) => ({
  height: height ?? "7.2rem",
}))`
  background-color: var(--color-white-2);
  border: none;
  border-bottom: 1px solid var(--color-secondary);
  height: ${({ height }) => height};
  overflow-x: auto;
  width: 100%;
  resize: none;
  padding: 0.5rem;
  font-size: 1.6rem;
  font-family: inherit;
  ${scrollbarStyle};
`;
