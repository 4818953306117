import styled from "styled-components";
import { HidePassword as HidePasswordIcon, ShowPassword as ShowPasswordIcon } from "./icons";

export const IconPasswordShow = styled(ShowPasswordIcon)`
  height: 24px;
  width: 24px;
  position: absolute;
  padding: 4px;
  right: 10px;
  top: 40%;
`;

export const IconPasswordHide = styled(HidePasswordIcon)`
  height: 24px;
  width: 24px;
  position: absolute;
  padding: 4px;
  right: 10px;
  top: 40%;
`;
