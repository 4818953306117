import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconAdd } from "assets/icons/icon-plus.svg";
import ParamsEmitterLists from "components/presentation/Params/Emitters/Lists";
import WithSpinner from "components/core/WithSpinner";
import { useParams, useRequest } from "hooks";
import { ParamsEmitterActions } from "store/ducks/paramsEmitter";
import { useDispatch, useSelector } from "react-redux";
import WithSuspense from "components/core/WithSuspense";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core";

const Register = React.lazy(() => import("containers/Params/Emitters/Register"));
const Edit = React.lazy(() => import("containers/Params/Emitters/Edit"));

const RegisterWithSuspense = WithSuspense(Register);
const EditWithSuspense = WithSuspense(Edit);

const ActivityGroupListWithSpinner = WithSpinner(ParamsEmitterLists);

const ActivityGroup = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [dataParam, setDataParam] = useState({});
  const contextRequest = "activityGroup";

  const {
    ParamsEmitterRequests,
    paramsEmitter: { items, total, totalPages },
  } = useSelector(({ paramsEmiiter }) => paramsEmiiter);

  const fetchAllActivity = (newParams) => {
    newParams.nameAction = contextRequest;
    dispatch(ParamsEmitterActions.fetchParams(newParams));
  };

  const { orderBy, order, page, perPage, setPage, setOrderBy, setOrder } = useParams({
    callback: fetchAllActivity,
    defaultOrderBy: "id",
    defaultOrder: "ASC",
  });

  const deleteActivity = (item) => {
    item.nameAction = contextRequest;
    dispatch(ParamsEmitterActions.deleteParam(item));
  };

  const dispatchConfirmationEdit = (text, type) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
      }),
    );

  useRequest({
    request: ParamsEmitterRequests.DELETE_PARAM,
    onResolved: () => {
      dispatchConfirmationEdit("Parâmetro excluído com Sucesso!", "success");
      setTimeout(() => {
        window.location.reload();
      }, 800);
    },
    onRejected: () => {
      dispatchConfirmationEdit("Erro ao excluir!", "error");
    },
  });

  return (
    <>
      <NavBar title={translate("activityGroup")} route={null}>
        <Button handleClick={() => setShowModal(true)}>
          <IconAdd />
          <span>{translate("register")}</span>
        </Button>
      </NavBar>
      <ActivityGroupListWithSpinner
        items={items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
        setShowModalUpdate={setShowModalUpdate}
        setDataParam={setDataParam}
        appearDelete
        deleteActivity={deleteActivity}
      />
      <RegisterWithSuspense
        loadComponent={showModal}
        contextRequest={contextRequest}
        showForm={showModal}
        setShowForm={setShowModal}
      />
      <EditWithSuspense
        loadComponent={showModalUpdate}
        contextRequest={contextRequest}
        dataParam={dataParam}
        showModalUpdate={showModalUpdate}
        setShowModalUpdate={setShowModalUpdate}
      />
    </>
  );
};

export default ActivityGroup;
