import React from "react";
import PropTypes from "prop-types";
import { InputGroup } from "components/core/Form";
import InputForm from "components/core/InputForm";
import { Flex, ColumnFlex, RowFlex } from "components/core/Grid";
import Button from "components/core/Button";
import { ModalActions } from "components/core/Modal/styled";
import { Span } from "components/core/Typography";
import { isPending } from "components/core/WithSpinner";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import Modal from "components/core/Modal";
import HtmlPreview from "components/core/HtmlPreview";
import { Label, InputWithTextArea } from "components/core/InputForm/styled";

const ModalEmail = ({
  invoiceId,
  EmailTemplate,
  emails,
  handleCancel,
  handleConfirm,
  showModalMail,
  setShowModalMail,
  setEmails,
  setMailSubject,
  mailMessage,
  setMailMessage,
  invoiceUrl,
  requestStatus,
  copied,
  handleClipboard,
}) => {
  return (
    <Modal
      title={`Envio de e-mail da fatura ${invoiceId}`}
      showModal={showModalMail}
      textAlign="text-left"
      showActions={false}
      onCloseModal={() => setShowModalMail(false)}
    >
      {isPending(requestStatus) ? (
        <SpinnerOverlay>
          <ColumnFlex>
            <Spinner />
            <Span style={{ marginTop: "0.5rem" }}>Aguarde...</Span>
          </ColumnFlex>
        </SpinnerOverlay>
      ) : (
        <Flex verticalAlign="center">
          <InputGroup paddingLeft={0}>
            <RowFlex paddingLeft={0} marginBottom={0} verticalAlign="flex-end">
              <InputForm
                type="text"
                name="url"
                label="Link da fatura"
                readonly={true}
                value={invoiceUrl}
                marginBottom="0"
                marginRight="1rem"
                onChange={() => {}}
              />
              <Button type="button" handleClick={() => handleClipboard(invoiceUrl)}>
                Copiar
              </Button>
            </RowFlex>
            {copied && (
              <RowFlex horizontalAlign={"center"}>
                <Label color="blue">Link copiado com sucesso</Label>
              </RowFlex>
            )}
          </InputGroup>
          <InputGroup paddingLeft={0}>
            {/* <InputForm
              type="text"
              name="email"
              label="E-mail do emissor"
              readonly={true}
              value={email}
              marginBottom="0"
            /> */}
            <Label htmlFor="email">Destinatários</Label>
            <InputWithTextArea
              type="text"
              name="email"
              style={{ resize: "vertical" }}
              value={emails}
              height={"5rem"}
              onChange={(e) => setEmails(e.target.value?.trim())}
            />
          </InputGroup>
          <InputGroup paddingLeft={0}>
            <InputForm
              type="text"
              name="subject"
              label="Assunto do e-mail"
              placeholder="OBVIA - Boleto disponível"
              marginBottom="0"
              onChange={(e) => setMailSubject(e.target.value?.trim())}
            />
          </InputGroup>
          {/* <InputGroup paddingLeft={0}>
            <InputForm
              type="text"
              name="greetings"
              label="Saudação"
              marginBottom="0"
              onChange={(e) => setMailGreetings(e.target.value?.trim())}
            />
          </InputGroup> */}
          <InputGroup paddingLeft={0}>
            <Label htmlFor="mailMessage">Mensagem do e-mail</Label>
            <InputWithTextArea
              type="text"
              name="mailMessage"
              style={{ resize: "vertical" }}
              onChange={(e) => setMailMessage(e.target.value?.trim())}
            />
          </InputGroup>
          <ModalActions>
            <Button type="button" variant="outline" handleClick={handleCancel}>
              Cancelar
            </Button>
            <Button type="button" handleClick={handleConfirm} disabled={!mailMessage}>
              Enviar
            </Button>
          </ModalActions>
          <HtmlPreview htmlCode={EmailTemplate} />
        </Flex>
      )}
    </Modal>
  );
};

ModalEmail.propTypes = {
  EmailTemplate: PropTypes.elementType,
  emails: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  showModalMail: PropTypes.bool,
  setShowModalMail: PropTypes.func,
  setMailSubject: PropTypes.func,
  mailMessage: PropTypes.string,
  setEmails: PropTypes.func,
  setMailMessage: PropTypes.func,
  setMailGreetings: PropTypes.func,
  requestStatus: PropTypes.any,
};

export default ModalEmail;
