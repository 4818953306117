import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableCheckbox,
  TableBody,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import { formatDate } from "helpers";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

const List = ({
  batches,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  toggleAll,
  checkedAll,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "id", title: translate("batche") },
    { slug: "batchDate", title: translate("startDate") },
    { slug: "total", title: translate("amountInvoices") },
    { slug: "status", title: translate("amountAuthorizedInvoices") },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {batches.map(({ id, batchAlias, batchDate, status, total, checked }) => (
            <tr key={id}>
              <TableItem textAlign="center">
                <TableCheckbox
                  name={`toggle_${id}`}
                  value={id}
                  handleChange={() => handleChange(id)}
                  checked={checked}
                />
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/company/${id}`}>{batchAlias || " - "}</Link>
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/company/${id}`}>
                  {batchDate ? formatDate(batchDate, false, false) : " - "}
                </Link>
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/company/${id}`}>{total || " - "}</Link>
              </TableItem>
              <TableItem>
                <Link to={`${ROUTES.batches.path}/company/${id}`}>
                  {status ? status?.autorizada : " - "}
                </Link>
              </TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  batches: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  batches: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
