import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { GET, POST } from "utils/constants/verbs";
import { CITIES, CITIES_UPLOAD } from "utils/constants/endpoints";
import { CitiesTypes, CitiesActions } from "store/ducks/cities";

export function* getCities({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: CITIES,
      params,
    });
    yield put(CitiesActions.getCitiesSuccess(data));
  } catch (error) {
    yield put(CitiesActions.citiesRequestFailure(error, type));
  }
}

export function* uploadCities({ formData, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: CITIES_UPLOAD,
      data: formData,
    });
    yield put(CitiesActions.uploadCitiesSuccess(data));
  } catch (error) {
    yield put(CitiesActions.citiesRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(CitiesTypes.GET_CITIES, getCities);
  yield takeLatest(CitiesTypes.UPLOAD_CITIES, uploadCities);
}
