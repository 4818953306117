import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocumentsActions } from "store/ducks/documents";

const useDocumentsTypes = () => {
  const dispatch = useDispatch();
  const { documentsTypes } = useSelector(({ documents }) => documents);

  useEffect(() => {
    if (!documentsTypes.length) {
      dispatch(DocumentsActions.fetchDocumentsTypes());
    }
  }, [dispatch]);

  const getDocumentTypeLabel = (idTypeDocument) =>
    documentsTypes.find(({ id }) => id === idTypeDocument)?.name;

  return { documentsTypes, getDocumentTypeLabel };
};

export default useDocumentsTypes;
