import React, { useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import GlobalSettingAdd from "components/presentation/Financial/GlobalSettings/Add";
import { useRequest, useFinancial } from "hooks";
import { LayoutActions } from "store/ducks/layout";
import { LINKS, REQUEST_RESOLVED } from "utils/constants";
import { globalSettingSchema, globalSettingInitialValues } from "utils/schemas/globalSetting";

const GlobalSettingAddWithSpinner = WithSpinner(GlobalSettingAdd);

const GlobalSettingAddContainer = () => {
  const history = useHistory();
  const submitRef = useRef(null);
  const {
    cleanState,
    dispatch,
    getPaymentGatewayInfo,
    globalSetting,
    paymentGatewayInfo,
    requestStatus,
    setGlobalSetting,
  } = useFinancial();
  const { t: translate } = useTranslation();
  const [errorPlans, setErrorPlans] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    getPaymentGatewayInfo(1, "credit_card");
  }, []);

  useEffect(() => {
    if (requestStatus.GET_PAYMENT_GATEWAY_INFO === REQUEST_RESOLVED) {
      const values = {
        ...globalSettingInitialValues,
        maxInstallmentsNumber: paymentGatewayInfo?.credit_card?.max_installments
          ? Number(paymentGatewayInfo?.credit_card?.max_installments)
          : globalSettingInitialValues.maxInstallmentsNumber,
      };
      setDefaultValues(values);
    }
  }, [paymentGatewayInfo, globalSettingInitialValues, requestStatus]);

  const dispatchConfirmation = (text, type, title) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
        title,
      }),
    );

  useRequest({
    request: requestStatus.SET_GLOBAL_SETTING,
    onResolved: () => {
      dispatchConfirmation(
        <Trans
          i18nKey="Global Setting added successfully"
          values={{ name: globalSetting?.enterpriseType?.name }}
          components={{ bold: <strong /> }}
        />,
        "success",
      );
      history.push(LINKS.financial.root);
      cleanState();
    },
  });

  const handleSubmit = (values) => {
    const data = { ...values };
    setErrorPlans(
      data.allowedInstallmentsPlanMonthly === false && data.allowedInstallmentsPlanAnnual === false,
    );
    if (!errorPlans) {
      setGlobalSetting(data);
    }
  };

  return (
    <>
      <NavBar title={translate("Global Installments Settings")} route={null}>
        <Button url={LINKS.financial.installments.root} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("save")}</Button>
      </NavBar>
      {defaultValues && (
        <GlobalSettingAddWithSpinner
          isLoading={
            isPending(requestStatus.SET_GLOBAL_SETTING) ||
            isPending(requestStatus.GET_PAYMENT_GATEWAY_INFO)
          }
          submitRef={submitRef}
          handleSubmitForm={handleSubmit}
          defaultValues={defaultValues}
          validationSchema={globalSettingSchema}
          errorPlans={errorPlans}
        />
      )}
    </>
  );
};

export default GlobalSettingAddContainer;
