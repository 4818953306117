import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithSpinner from "components/core/WithSpinner";
import { useOutsideEvent } from "hooks";
import * as S from "./styled";

const ItemsToSelectWrapperWithSpinner = WithSpinner(S.ItemToSelectWrapper);

const Autocomplete = ({
  errorMsg,
  label,
  itemName,
  nameValue,
  children,
  direction,
  horizontalAlign,
  verticalAlign,
  register,
  marginBottom,
  content,
  isLoading,
  handleSearch,
  handleChange,
  items,
  setValue,
  lastItemRef,
}) => {
  const { t: translate } = useTranslation();
  const [focus, setfocus] = useState(false);
  const InputRef = useRef(null);

  const activityItem = (item) => {
    if (itemName && item) {
      const loadname = item[itemName];
      return loadname.name;
    }
    return item.name;
  };

  const handleInputFocus = () => {
    setTimeout(() => {
      setfocus(true);
    }, 100);
  };

  const handleInputOut = () => {
    setfocus(false);
  };

  useOutsideEvent(InputRef, handleInputOut);

  const handleClick = (item) => {
    handleChange(item);
    setValue(nameValue, activityItem(item));
    setfocus(false);
  };

  return (
    <S.InputWrapper
      direction={direction}
      horizontalAlign={horizontalAlign}
      verticalAlign={verticalAlign}
      marginBottom={marginBottom}
      content={content}
      isLoading={isLoading}
      ref={InputRef}
    >
      <S.Label htmlFor={nameValue}>{label}</S.Label>
      <S.Field
        ref={register}
        name={nameValue}
        autoComplete="off"
        hasError={!!errorMsg}
        isLoading={isLoading}
        onFocus={handleInputFocus}
        onChange={handleSearch}
      />
      <ItemsToSelectWrapperWithSpinner open={focus}>
        {items?.length ? (
          items.map((item, index) => (
            <S.ItemList
              onClick={() => handleClick(item)}
              key={activityItem(item)}
              ref={index === items.length - 1 ? lastItemRef : null}
            >
              {activityItem(item)}
            </S.ItemList>
          ))
        ) : (
          <S.EmptyList>{translate("itemsNotFound")}</S.EmptyList>
        )}
      </ItemsToSelectWrapperWithSpinner>
      {errorMsg && <S.ErrorMsg data-testid="errorMsg">{errorMsg}</S.ErrorMsg>}
      {children}
    </S.InputWrapper>
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  items: PropTypes.array.isRequired,
  itemName: PropTypes.string,
  nameValue: PropTypes.string,
  direction: PropTypes.string,
  horizontalAlign: PropTypes.string,
  verticalAlign: PropTypes.string,
  register: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  marginBottom: PropTypes.string,
  content: PropTypes.string,
  isLoading: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  lastItemRef: PropTypes.object,
};

Autocomplete.defaultProps = {
  errorMsg: null,
  itemName: null,
  nameValue: "nameValue",
  children: null,
  direction: null,
  horizontalAlign: null,
  verticalAlign: null,
  register: null,
  marginBottom: null,
  content: null,
  isLoading: false,
  lastItemRef: null,
};

export default Autocomplete;
