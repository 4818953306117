import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "store/ducks";
import sagas from "store/sagas";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    collapsed: true,
    timestamp: false,
  });
  middlewares.push(logger);
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["layout"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
