import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useInfinitePagination, useParams as useParamsHooks, useDebounce } from "hooks";
import { NcmGlobalsActions } from "store/ducks/ncmGlobals";
import { LayoutActions } from "store/ducks/layout";
import { NcmActions } from "store/ducks/ncm";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner, { isPending } from "components/core/WithSpinner";
import NcmGlobalsEdit from "components/presentation/NcmGlobals/Edit";

import { LINKS } from "utils/constants";
import { ncmGlobalsSchema } from "utils/schemas/ncmGlobals";
import { formatDateToParam } from "helpers/format";

const NcmGlobalsEditWithSpinner = WithSpinner(NcmGlobalsEdit);

const NcmGlobalsEditContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const submitRef = useRef(null);
  const { id } = useParams();
  const { ncmGlobalsDetails, NcmGlobalsRequests } = useSelector(({ ncmGlobals }) => ncmGlobals);
  const { availableNcm, NcmRequests } = useSelector(({ ncm }) => ncm);
  const [newNcmArray, setNewNcmArray] = useState([]);
  const [newUnitArray, setNewUnitArray] = useState([]);
  const [prevUnitName, setPrevUnitName] = useState("");
  const { unitMeasure } = useSelector(({ ncmGlobals }) => ncmGlobals);

  useEffect(() => {
    if (ncmGlobalsDetails?.id !== Number(id)) {
      dispatch(NcmGlobalsActions.getNcmGlobalsById(id));
    }
  }, [id, ncmGlobalsDetails?.id, dispatch]);

  const handleSubmit = ({ fieldname, ...values }) => {
    const startTerm = formatDateToParam(values.startTerm);
    const endTerm = values.endTerm ? formatDateToParam(values.endTerm) : null;
    const data = {
      ...values,
      startTerm,
      endTerm,
    };
    dispatch(NcmGlobalsActions.editNcmGlobals(id, data));
  };

  const fetchNcm = (params) => {
    dispatch(NcmActions.fetchAvailableNcm(params));
  };

  const { page: pageNCM, setPage: setPageNCM, handleFilter } = useParamsHooks({
    callback: fetchNcm,
    defaultOrderBy: "id",
  });

  const debounceChangeNcm = useDebounce(handleFilter, 700);

  const handleSearchNcm = ({ target: { value } }) => {
    debounceChangeNcm({ search: value });
  };

  const fetchUnitMeasure = (params) => {
    dispatch(NcmGlobalsActions.getUnitMeasure(params));
  };

  const lastItemRefNCM = useInfinitePagination(
    NcmRequests.FETCH_AVAILABLE_NCM_WITH_PARAMS,
    pageNCM < availableNcm?.totalPages,
    setPageNCM,
  );

  const {
    page: pageMeasure,
    setPage: setPageMeasure,
    handleFilter: handleFilterMeasure,
  } = useParamsHooks({
    callback: fetchUnitMeasure,
    defaultOrderBy: "id",
  });

  const debounceChangeMeasure = useDebounce(handleFilterMeasure, 700);

  const handleSearchUnitMeasure = ({ target: { value } }) => {
    debounceChangeMeasure({ search: value });
  };

  const lastItemRefMeasure = useInfinitePagination(
    NcmGlobalsRequests.getUnitMeasure,
    pageMeasure < unitMeasure?.totalPages,
    setPageMeasure,
  );

  useEffect(() => {
    if (pageMeasure === 1) {
      setNewUnitArray(unitMeasure?.items);
    } else if (unitMeasure?.items?.length) {
      const exist = newUnitArray.find((newUnit) =>
        unitMeasure?.items?.find((item) => item.code === newUnit.code),
      );
      if (!exist)
        setNewUnitArray((prevItems) => [...new Set([...prevItems, ...unitMeasure?.items])]);
      else {
        const format = newUnitArray.map((newUnit) => {
          const x = unitMeasure?.items?.find((item) => item.code === newUnit.code);

          if (!x) return newUnit;
          return x;
        });

        setNewUnitArray(() => [...new Set([...format])]);
      }
    }
  }, [pageMeasure, unitMeasure?.items]);

  useEffect(() => {
    const newAvailableNcm = availableNcm?.items
      ?.map(({ code, name, id: idN }) => ({
        id: idN,
        code,
        name: `${code} - ${name}`,
      }))
      .filter((x) => {
        return x.code !== undefined;
      });

    if (pageNCM === 1) {
      setNewNcmArray(newAvailableNcm);
    } else if (availableNcm?.items?.length) {
      const exist = newNcmArray.find((newNCM) =>
        newAvailableNcm.find((item) => item.code === newNCM.code),
      );
      if (!exist) setNewNcmArray((prevItems) => [...new Set([...prevItems, ...newAvailableNcm])]);
      else {
        const format = newNcmArray.map((newNCM) => {
          const x = newAvailableNcm.find((item) => item.code === newNCM.code);

          if (!x) return newNCM;
          return x;
        });

        setNewNcmArray(() => [...new Set([...format])]);
      }
    }
  }, [availableNcm?.items, pageNCM]);

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{translate("ncmGlobalsEdited")}</Span>,
        type: "success",
      }),
    );
    dispatch(NcmGlobalsActions.cleanState());
    history.push(LINKS.ncmGlobalsList);
  };

  useRequest({
    request: NcmGlobalsRequests.editNcmGlobals,
    onResolved,
  });

  useEffect(() => {
    if (newUnitArray?.length && ncmGlobalsDetails?.idUnitMeasure) {
      const filterArray = newUnitArray?.find(
        (item) => item?.id === ncmGlobalsDetails?.idUnitMeasure,
      );
      setPrevUnitName(filterArray?.name || "");
    }
  }, [ncmGlobalsDetails?.idUnitMeasure, newUnitArray?.length]);

  return (
    <>
      <NavBar title={translate("editNcmGlobals")}>
        <Button url={LINKS.ncmGlobalsList} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("save")}</Button>
      </NavBar>
      <NcmGlobalsEditWithSpinner
        requestStatus={NcmGlobalsRequests.getNcmGlobalsById}
        handleNcmGlobalsEdit={handleSubmit}
        handleSearch={handleSearchNcm}
        ncmItems={newNcmArray || []}
        isLoadingNcmItems={isPending(NcmRequests.FETCH_AVAILABLE_NCM)}
        defaultValues={{
          name: ncmGlobalsDetails?.name,
          code: ncmGlobalsDetails?.code,
          ncm: `${ncmGlobalsDetails?.ncmCategory?.code} - ${ncmGlobalsDetails?.ncmCategory?.name}`,
          idUnitMeasure: ncmGlobalsDetails?.idUnitMeasure,
          startTerm: ncmGlobalsDetails?.startTerm,
          endTerm: ncmGlobalsDetails?.endTerm,
          idNcmCategory: ncmGlobalsDetails?.idNcmCategory,
        }}
        validationSchema={ncmGlobalsSchema}
        submitRef={submitRef}
        UnitMeasure={newUnitArray}
        lastItemRefMeasure={lastItemRefMeasure}
        handleSearchUnitMeasure={handleSearchUnitMeasure}
        isLoadingUnitMeasureItems={isPending(NcmGlobalsRequests.getUnitMeasure)}
        prevUnitName={prevUnitName}
        lastItemRefNCM={lastItemRefNCM}
      />
    </>
  );
};

export default NcmGlobalsEditContainer;
