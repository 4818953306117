import { createActions, createReducer } from "reduxsauce";
import { CONFIRMATIONS } from "components/core/ConfirmationCard";
import { REHYDRATE } from "redux-persist/lib/constants";

export const { Types: LayoutTypes, Creators: LayoutActions } = createActions({
  pinEmitter: ["emitter"],
  toggleManageMenu: ["isDisabled"],
  toggleEmitterMenu: ["isDisabled"],
  toggleEmitterSearch: null,
  showConfirmationModal: ["payload"],
  clearConfirmation: null,
  setFeedback: ["feedback"],
  changeMultiSelectItems: ["items"],
  onError: ["error"],
  pinEmitterClear: null,
});

const INITIAL_STATE = {
  emitterSelected: null,
  isManageMenuOpen: false,
  isEmitterMenuOpen: false,
  isEmitterSearchOpen: false,
  feedback: {
    show: false,
  },
  confirmationCard: {
    show: false,
    content: "",
    confirmation: CONFIRMATIONS.success,
  },
  multiSelectItems: {
    secondaryActivities: [],
    invoiceTypes: [],
    subscriptions: [],
    enterpriseTypes: [],
    states: [],
    cities: [],
  },
  messageErrors: "",
};

const pinEmitter = (state, { emitter }) => ({
  ...state,
  emitterSelected: emitter,
  isManageMenuOpen: false,
  isEmitterMenuOpen: false,
  isEmitterSearchOpen: false,
});

const pinEmitterClear = (state) => ({
  ...state,
  emitterSelected: null,
});

const toggleManageMenu = (state, { isDisabled }) => {
  return {
    ...state,
    isEmitterSearchOpen: false,
    isEmitterMenuOpen: false,
    isManageMenuOpen: isDisabled,
  };
};

const toggleEmitterMenu = (state, { isDisabled }) => {
  return {
    ...state,
    isManageMenuOpen: false,
    isEmitterSearchOpen: false,
    isEmitterMenuOpen: isDisabled,
  };
};

const toggleEmitterSearch = (state) => ({
  ...state,
  isManageMenuOpen: false,
  isEmitterMenuOpen: false,
  isEmitterSearchOpen: !state.isEmitterSearchOpen,
});

const showConfirmationModal = (state, { payload: { content, type, title } }) => ({
  ...state,
  confirmationCard: {
    show: true,
    content,
    confirmation: {
      ...(CONFIRMATIONS[type] ?? CONFIRMATIONS.success),
      title: title ?? CONFIRMATIONS[type].title,
    },
  },
});

const clearConfirmation = (state) => ({
  ...state,
  confirmationCard: {
    ...INITIAL_STATE.confirmationCard,
    confirmation: {
      ...state.confirmationCard.confirmation,
    },
  },
});

const setFeedback = (state, { feedback }) => ({
  ...state,
  feedback,
});

const changeMultiSelectItems = (state, { items }) => ({
  ...state,
  multiSelectItems: items,
});

const onError = (
  state,
  {
    error: {
      response: { data },
    },
  },
) => ({
  ...state,
  confirmationCard: {
    show: true,
    content: data?.message ?? data.errors ?? "something-went-wrong",
    useTranslate: true,
    validationsFail: data?.validation,
    confirmation: {
      ...CONFIRMATIONS.error,
    },
  },
  messageErrors: {
    message: data?.message ?? data.errors ?? "something-went-wrong",
  },
});

const rehydrate = (state, { payload }) => ({
  ...state,
  emitterSelected: payload?.layout?.emitterSelected,
});

export default createReducer(INITIAL_STATE, {
  [LayoutTypes.PIN_EMITTER]: pinEmitter,
  [LayoutTypes.TOGGLE_MANAGE_MENU]: toggleManageMenu,
  [LayoutTypes.TOGGLE_EMITTER_MENU]: toggleEmitterMenu,
  [LayoutTypes.TOGGLE_EMITTER_SEARCH]: toggleEmitterSearch,
  [LayoutTypes.SHOW_CONFIRMATION_MODAL]: showConfirmationModal,
  [LayoutTypes.CLEAR_CONFIRMATION]: clearConfirmation,
  [LayoutTypes.SET_FEEDBACK]: setFeedback,
  [LayoutTypes.CHANGE_MULTI_SELECT_ITEMS]: changeMultiSelectItems,
  [LayoutTypes.ON_ERROR]: onError,
  [LayoutTypes.PIN_EMITTER_CLEAR]: pinEmitterClear,
  [REHYDRATE]: rehydrate,
});
