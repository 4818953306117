import { put, takeLatest } from "redux-saga/effects";
import { LayoutActions as GlobalActions } from "store/ducks/layout";
import { Creators as AuthActions } from "store/ducks/auth";
import { AddressTypes } from "store/ducks/address";
import { ClientTypes } from "store/ducks/client";
import { CheckFieldsTypes } from "store/ducks/checkFields";
import { ServicesTypes } from "store/ducks/services";
import { DocumentsTypes } from "store/ducks/documents";
import { NcmGlobalsTypes } from "store/ducks/ncmGlobals";
import { EmitterTypes } from "store/ducks/emitter";
import { EnterpriseTypes } from "store/ducks/enterprise";
import { InvoiceTypes } from "store/ducks/invoice";
import { CitiesTypes } from "store/ducks/cities";
import { CouponsTypes } from "store/ducks/coupons";
import { NotificationTypes } from "store/ducks/notification";
import { Types as RolesTypes } from "store/ducks/roles";
import { TaxesTypes } from "store/ducks/taxes";
import { UsersTypes } from "store/ducks/users";
import { CODE_UNAUTHORIZED } from "utils/constants";

function* onError({ error }) {
  if (error?.response?.status === CODE_UNAUTHORIZED) {
    yield put(AuthActions.handleUnauthorized());
  } else {
    yield put(GlobalActions.onError(error));
  }
}

export function* watchSagas() {
  yield takeLatest(AddressTypes.GET_ADDRESS_BY_CEP_FAILURE, onError);
  yield takeLatest(ClientTypes.CLIENT_REQUEST_FAILURE, onError);
  yield takeLatest(CheckFieldsTypes.CHECK_FIELDS_FAILURE, onError);
  yield takeLatest(CouponsTypes.COUPONS_REQUEST_FAILURE, onError);
  yield takeLatest(CitiesTypes.CITIES_REQUEST_FAILURE, onError);
  yield takeLatest(DocumentsTypes.DOCUMENTS_REQUEST_FAILURE, onError);
  yield takeLatest(EmitterTypes.EMITTERS_REQUEST_FAILURE, onError);
  yield takeLatest(EnterpriseTypes.ENTERPRISE_REQUEST_FAILURE, onError);
  yield takeLatest(InvoiceTypes.INVOICE_REQUEST_FAILURE, onError);
  yield takeLatest(NotificationTypes.NOTIFICATION_REQUEST_FAILURE, onError);
  yield takeLatest(NcmGlobalsTypes.NCM_GLOBALS_REQUEST_FAILURE, onError);
  yield takeLatest(RolesTypes.GET_ROLES_ERROR, onError);
  yield takeLatest(TaxesTypes.TAXES_REQUEST_FAILURE, onError);
  yield takeLatest(UsersTypes.USERS_REQUEST_FAILURE, onError);
  yield takeLatest(ServicesTypes.SERVICES_REQUEST_FAILURE, onError);
}
