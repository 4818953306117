import React, { useState } from "react";
import WithSpinner from "components/core/WithSpinner";
import AuthorizationPresentation from "components/presentation/Authorization";

const AuthorizationWithSpinner = WithSpinner(AuthorizationPresentation);

const Authorization = () => {
  const [feedback] = useState({
    title: "Assinatura finalizada!",
    isSuccess: true,
    description: "Parabéns, você finalizou seu cadastro na Obvia.",
    showDeeplink: true,
    btnText: "Voltar para home",
    emphasis: "Fale com um contador",
    paymentFlag: true,
  });
  return <AuthorizationWithSpinner feedback={feedback} />;
};

export default Authorization;
