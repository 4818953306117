import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: ParamsEmitterTypes, Creators: ParamsEmitterActions } = createActions({
  fetchParams: ["payload"],
  fetchParamsSuccess: ["payload"],
  editStatusActive: ["payload"],
  editStatusActiveSuccess: ["payload"],
  registerParamEmitter: ["payload"],
  registerParamEmitterSuccess: ["payload"],
  editParams: ["payload"],
  editParamsSuccess: ["payload"],
  deleteParam: ["payload"],
  deleteParamSuccess: ["payload"],
  cleanState: null,
  requestFailure: ["error", "originalType"],
});

const INITIAL_STATE = {
  payload: null,
  error: null,
  paramsEmitter: {
    items: [],
  },
  ParamsEmitterRequests: {
    [ParamsEmitterTypes.FETCH_PARAMS]: REQUEST_NOT_STARTED,
    [ParamsEmitterTypes.EDIT_STATUS_ACTIVE]: REQUEST_NOT_STARTED,
    [ParamsEmitterTypes.REGISTER_PARAM_EMITTER]: REQUEST_NOT_STARTED,
    [ParamsEmitterTypes.EDIT_PARAMS]: REQUEST_NOT_STARTED,
    [ParamsEmitterTypes.DELETE_PARAM]: REQUEST_NOT_STARTED,
  },
};

const fetchParams = (state) => ({
  ...state,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.FETCH_PARAMS]: REQUEST_PENDING,
  },
});

const fetchParamsSuccess = (state, { payload }) => ({
  ...state,
  paramsEmitter: payload,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.FETCH_PARAMS]: REQUEST_RESOLVED,
  },
});

const editStatusActive = (state) => ({
  ...state,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.EDIT_STATUS_ACTIVE]: REQUEST_PENDING,
  },
});

const editStatusActiveSuccess = (state, { payload }) => ({
  ...state,
  paramsEmitter: payload,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.EDIT_STATUS_ACTIVE]: REQUEST_RESOLVED,
  },
});

const registerParamEmitter = (state) => ({
  ...state,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.REGISTER_PARAM_EMITTER]: REQUEST_PENDING,
  },
});

const registerParamEmitterSuccess = (state, { payload }) => ({
  ...state,
  paramsEmitter: payload,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.REGISTER_PARAM_EMITTER]: REQUEST_RESOLVED,
  },
});

const editParams = (state) => ({
  ...state,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.EDIT_PARAMS]: REQUEST_PENDING,
  },
});

const editParamsSuccess = (state, { payload }) => ({
  ...state,
  paramsEmitter: payload,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.EDIT_PARAMS]: REQUEST_RESOLVED,
  },
});

const deleteParam = (state) => ({
  ...state,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.DELETE_PARAM]: REQUEST_PENDING,
  },
});

const deleteParamSuccess = (state) => ({
  ...state,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [ParamsEmitterTypes.DELETE_PARAM]: REQUEST_RESOLVED,
  },
});

const requestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  ParamsEmitterRequests: {
    ...state.ParamsEmitterRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [ParamsEmitterTypes.FETCH_PARAMS]: fetchParams,
  [ParamsEmitterTypes.FETCH_PARAMS_SUCCESS]: fetchParamsSuccess,
  [ParamsEmitterTypes.EDIT_STATUS_ACTIVE]: editStatusActive,
  [ParamsEmitterTypes.EDIT_STATUS_ACTIVE_SUCCESS]: editStatusActiveSuccess,
  [ParamsEmitterTypes.REGISTER_PARAM_EMITTER]: registerParamEmitter,
  [ParamsEmitterTypes.REGISTER_PARAM_EMITTER_SUCCESS]: registerParamEmitterSuccess,
  [ParamsEmitterTypes.EDIT_PARAMS]: editParams,
  [ParamsEmitterTypes.EDIT_PARAMS_SUCCESS]: editParamsSuccess,
  [ParamsEmitterTypes.DELETE_PARAM]: deleteParam,
  [ParamsEmitterTypes.DELETE_PARAM_SUCCESS]: deleteParamSuccess,
  [ParamsEmitterTypes.CLEAN_STATE]: cleanState,
  [ParamsEmitterTypes.REQUEST_FAILURE]: requestFailure,
});
