import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaxesActions } from "store/ducks/taxes";
import { getTaxByEnum } from "utils/constants/enums";

const useTaxes = () => {
  const dispatch = useDispatch();
  const [hasTaxes, setHasTaxes] = useState(false);
  const { generalTaxes } = useSelector(({ taxes: allTaxes }) => allTaxes);

  useEffect(() => {
    if (!generalTaxes.length) {
      dispatch(TaxesActions.fetchTaxes());
    } else {
      setHasTaxes(true);
    }
  }, [dispatch, generalTaxes]);

  return [hasTaxes, getTaxByEnum(generalTaxes)];
};

export default useTaxes;
