import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableCheckbox,
  TableBody,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import useRoleLabel from "hooks/useRoleLabel";
import { urls } from "utils/constants";
import { formatDate } from "helpers";

const List = ({
  ncmGlobals,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  toggleAll,
  checkedAll,
  orderBy,
  order,
}) => {
  const { t: translate } = useTranslation();
  const [getRoleLabel] = useRoleLabel();
  const {
    LINKS: { ncmGlobalsDetails },
  } = urls;
  const headers = [
    { slug: "code", title: translate("code") },
    { slug: "name", title: translate("Nome") },
    { slug: "ncmCategory", title: translate("category") },
    { slug: "createdAt", title: translate("registrationDate") },
  ];

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {ncmGlobals.map(({ id, code, name, ncmCategory, createdAt, checked }) => (
            <tr key={id}>
              <TableItem textAlign="center">
                <TableCheckbox
                  name={`toggle_${id}`}
                  value={id}
                  handleChange={() => handleChange(id)}
                  checked={checked}
                />
              </TableItem>
              <TableItem>{code}</TableItem>
              <TableItem>{name}</TableItem>
              <TableItem>{ncmCategory.name}</TableItem>
              <TableItem>{formatDate(createdAt)}</TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

List.propTypes = {
  ncmGlobals: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  ncmGlobals: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(List);
