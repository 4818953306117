import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams as useRouterParams } from "react-router-dom";
import { ClientActions } from "store/ducks/client";
import Navbar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import ListPresentation from "components/presentation/Client/List";
import { useEmitter, useItems, useParams } from "hooks";

const Filter = React.lazy(() => import("containers/Client/List/Filter"));

const ListPresentationWithSpinner = WithSpinner(ListPresentation);
const FilterWithSuspense = WithSuspense(Filter);

const ClientListContainer = () => {
  const emitter = useEmitter();
  const dispatch = useDispatch();
  const { emitterId } = useRouterParams();
  const { t: translate } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const {
    clients: { items, total, totalPages },
    ClientRequests,
  } = useSelector(({ client }) => client);

  const { everyoneIsChecked, handleChange, toggleAll, listChecked: itemsChecked } = useItems(items);

  const fetchClients = (params) =>
    dispatch(ClientActions.fetchClients({ ...params, idEnterprise: emitter?.id }));

  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({ callback: fetchClients, defaultOrderBy: "createdAt", defaultOrder: "desc" });

  return (
    <>
      <Navbar title={translate("clients")}>
        <SmartFilterButton handleShowFilter={() => setShowFilter(true)} />
      </Navbar>
      <ListPresentationWithSpinner
        requestStatus={ClientRequests.FETCH_CLIENTS}
        items={itemsChecked ?? items}
        emitterId={emitterId}
        handleChange={handleChange}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        toggleAll={toggleAll}
        everyoneIsChecked={everyoneIsChecked}
      />
      <FilterWithSuspense
        loadComponent={showFilter}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        dispatchWithParams={handleFilter}
      />
    </>
  );
};

export default ClientListContainer;
