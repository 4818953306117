import React from "react";
import styled, { css } from "styled-components";
import { scrollbarStyle } from "components/core/Layout";
import { RemoveFileButton } from "components/core/InputFile/styled";
import { ReactComponent as IconAttach } from "assets/icons/icon-attach.svg";
import { ReactComponent as IconDown } from "assets/icons/icon-down.svg";

const messageMenuOpenStyle = css`
  visibility: visible;
  opacity: 1;
`;

export const MessageMenu = styled.div`
  border-radius: 0.4rem;
  position: absolute;
  top: 1rem;
  right: 0;
  visibility: hidden;
  opacity: 0;

  ${({ open }) => open && messageMenuOpenStyle}
`;

export const OpenMessageMenu = styled.button.attrs(() => ({
  children: <IconDown />,
}))`
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0.4rem;
  height: 1rem;
  width: 1rem;
  outline: none;
  visibility: hidden;

  &:hover,
  &:focus {
    opacity: 1;
  }

  & > svg {
    height: 100%;
    width: 100%;
  }

  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const Date = styled.span`
  color: var(--color-gray-6);
  font-size: 1rem;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  text-align: right;
  width: max-content;
  min-width: 100%;
`;

const messageSentStyle = css`
  background-color: rgba(102, 175, 255, 0.3);
  ${Date} {
    left: unset;
    right: 0;
  }

  ${OpenMessageMenu} {
    visibility: visible;
  }

  &:hover {
    ${OpenMessageMenu} {
      opacity: 1;
    }
  }
`;

export const Delete = styled.button`
  background-color: var(--color-white-1);
  color: var(--color-danger);
  cursor: pointer;
  padding: 0.8rem 1.6rem;
`;

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 50rem;
  max-width: 100%;
  max-height: 100vh;
  position: relative;
  height: 100vh;
`;

export const MessagesOverlay = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  ${scrollbarStyle}
`;

export const Messages = styled.div`
  background-color: var(--color-white-5);
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding: var(--space) var(--space) 0;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
`;

export const Message = styled.div.attrs(({ received }) => ({
  alignSelf: received ? "flex-start" : "flex-end",
  arrowChat: received
    ? "left: -7px; transform: rotateZ(45deg);"
    : "right: -7px; transform: rotateZ(-135deg);",
}))`
  background-color: var(--color-white-1);
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: var(--color-gray-3);
  padding: 1.2rem 1.8rem;
  align-self: ${({ alignSelf }) => alignSelf};
  position: relative;
  margin-bottom: var(--space);
  max-width: 45%;
  word-wrap: break-word;

  ${({ received }) => css`
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: 15px;
      ${({ arrowChat }) => arrowChat}
      background: ${received
        ? "#fff"
        : "linear-gradient(to left, rgba(102, 175, 255,0), rgba(102, 175, 255,0.3));"};
      border-left: 1px solid;
      border-bottom: 1px solid;
      border-color: #e0e0e0;
    }
  `}

  ${({ received }) => !received && messageSentStyle}
  ${({ hasFile }) =>
    hasFile &&
    css`
      border-radius: 0.8rem;
      box-shadow: 0 0 1rem rgba(57, 57, 57, 0.1);
      width: 100%;
    `}
`;

export const MessageFile = styled.div`
  color: var(--color-secondary);
  font-weight: 5000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChatActions = styled.div`
  min-height: 6rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 2.4rem;
`;

export const NewMessageWrapper = styled.div`
  position: relative;
  flex: 1;
  margin: 0 1.6rem;
  max-height: 8rem;
`;

export const NewMessage = styled.div`
  font-size: 1.4rem;
  height: 100%;
  width: 100%;
  word-break: break-word;
  overflow-y: auto;
  ${scrollbarStyle}

  &:empty + span {
    font-size: var(--font-size-default);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;
    pointer-events: none;
  }

  &:not(:empty) + span {
    opacity: 0;
  }

  &:focus,
  &:active {
    border-radius: 0.5rem;
    outline: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

export const ChatHeader = styled.div`
  box-shadow: 0.5rem 0 0.7rem 0.1rem var(--color-white-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.6rem 2.4rem;
  z-index: 99;
`;

export const EnterpriseName = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
`;

export const Subtext = styled.h3`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const ButtonAttach = styled.label.attrs(() => ({
  children: <IconAttach />,
}))`
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
`;

export const AttachDisplay = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ButtonRemoveAttach = styled(RemoveFileButton)`
  margin-left: 1rem;
`;

export const DateBar = styled.div`
  align-self: center;
  color: var(--color-gray-6);
  font-size: 1rem;
  position: relative;
  margin: 3rem 0;
  font-weight: 600;

  &::after,
  &::before {
    background-color: var(--color-gray-6);
    content: "";
    height: 0.1rem;
    position: absolute;
    top: 50%;
    width: 28rem;
    opacity: 0.5;
  }

  &::before {
    right: 8rem;
  }

  &::after {
    left: 8rem;
  }
`;
