import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
`;

export const Header = styled.div`
  width: 100%;
  padding: var(--space-md) var(--space-lg);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  ${media.lessThan("small")`
    justify-content: center;
  `}
`;

export const LogoBox = styled.div`
  svg {
    height: var(--space-md);
    width: auto;
  }
`;

export const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(to top, var(--color-primary) 0%, var(--color-secondary) 100%);

  ${media.lessThan("large")`
    max-width: 100%;
    width: 100%;
  `}
`;
