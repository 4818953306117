import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Paragraph } from "components/core/Typography";
import { ReactComponent as EmptyScreen } from "assets/images/empty.svg";
import { ErrorWrapper } from "components/core/ErrorBoundary/styled";

const EmptyFeedback = ({ t: translate, feedbackTextOnly = false }) => (
  <ErrorWrapper>
    {!feedbackTextOnly && <EmptyScreen />}
    <Paragraph>{translate("emptyList")}</Paragraph>
  </ErrorWrapper>
);

EmptyFeedback.propTypes = {
  t: PropTypes.func.isRequired,
  feedbackTextOnly: PropTypes.bool,
};

export default withTranslation()(EmptyFeedback);
