import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { ReactComponent as IconPlus } from "assets/icons/icon-plus.svg";
import { Paragraph } from "components/core/Typography";
import * as S from "./styled";

const EmptyChat = ({ handleClick }) => {
  const { t: translation } = useTranslation();

  return (
    <S.EmptyChatWrapper>
      <S.NewConversation />
      <Paragraph>{translation("selectOrStartNewService")}</Paragraph>
      <Button variant="text" handleClick={handleClick} data-testid="button">
        <IconPlus />
        <span>{translation("newService")}</span>
      </Button>
    </S.EmptyChatWrapper>
  );
};

EmptyChat.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default EmptyChat;
