import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";

export const batchesSchema = Yup.object().shape({
  justification: Yup.string().when("price", {
    is: (value) => !!value,
    then: Yup.string().required(<Trans i18nKey="required.justification" />),
  }),
});

export const batchesInitialValues = {
  justification: " ",
};
