import styled from "styled-components";
import media from "styled-media-query";

export const Form = styled.form`
  height: auto;
  width: 100%;
`;

export const InputHidden = styled.input`
  display: none;
`;

export const InputGroup = styled.div.attrs(
  ({ columns, paddingLeft, marginBottom, paddingTop, paddingRight, horizontalPadding = null }) => ({
    columns: columns ?? "1",
    paddingTop: paddingTop ?? "1rem",
    paddingLeft: paddingLeft ?? horizontalPadding ?? "2.8rem",
    paddingRight: paddingRight ?? horizontalPadding ?? "0",
    marginBottom: marginBottom ?? "1.2rem",
  }),
)`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 0 1.6rem;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-right: ${({ paddingRight }) => paddingRight};
  width: 100%;
  ${media.lessThan("medium")`
    padding-left: ${({ paddingLeft, horizontalPadding }) =>
      horizontalPadding ? "2rem" : paddingLeft};
    padding-right: ${({ paddingRight, horizontalPadding }) =>
      horizontalPadding ? "2rem" : paddingRight};
  `}
  ${media.lessThan("small")`
    padding-left: ${({ paddingLeft, horizontalPadding }) =>
      horizontalPadding ? "0" : paddingLeft};
        padding-right: ${({ paddingRight, horizontalPadding }) =>
          horizontalPadding ? "0" : paddingRight};
  `}
`;

export const InputGroupFlex = styled.div.attrs(({ paddingLeft, marginBottom, paddingTop }) => ({
  paddingTop: paddingTop ?? "0",
  paddingLeft: paddingLeft ?? "0",
  marginBottom: marginBottom ?? "1.2rem",
}))`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-top: ${({ paddingTop }) => paddingTop};
  width: 100%;

  & > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
`;

export const HiddenAhref = styled.a`
  display: none;
`;
