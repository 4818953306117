import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as IconDoc } from "assets/icons/icon-doc.svg";
import { ReactComponent as IconUpload } from "assets/icons/icon-upload.svg";
import * as S from "./styled";

const InputFile = ({
  id,
  name,
  register,
  label,
  callback,
  onCancel,
  isHidden,
  margin,
  ...otherProps
}) => {
  const [file, setFile] = useState({});
  const [Icon, setIcon] = useState(IconUpload);
  const [labelText, setLabelText] = useState(label);

  const handleOnChange = ({ target: { files } }) => {
    const newFile = files[0];
    setFile(newFile);
  };

  const handleClick = () => {
    setFile({});

    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    setLabelText(file.name ?? label);
    setIcon(file.name ? IconDoc : IconUpload);

    if (callback) {
      callback(file);
    }
  }, [callback, file, label]);

  return (
    <S.InputFile htmlFor={name} margin={margin} isHidden={isHidden}>
      <S.InputLabel>
        <input
          ref={register}
          id={id ?? name}
          name={name}
          type="file"
          onChange={handleOnChange}
          {...otherProps}
        />
        <Icon />
        <span>{labelText}</span>
      </S.InputLabel>
      {file.name && <S.RemoveFileButton onClick={handleClick} />}
    </S.InputFile>
  );
};

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  callback: PropTypes.func,
  onCancel: PropTypes.func,
  isHidden: PropTypes.bool,
};

InputFile.defaultProps = {
  register: null,
  label: "Selecionar arquivo",
  id: null,
  callback: null,
  onCancel: null,
  isHidden: false,
};

export default InputFile;
