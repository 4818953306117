import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Subtitle } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import RadioButton from "components/core/RadioButton";
import WithSpinner from "components/core/WithSpinner";
import { EnterpriseActions } from "store/ducks/enterprise";
import { Form, InputHidden } from "components/core/Form";
import { useForm } from "react-hook-form";
import { ItemsToSelectWrapper, EmptyList, ItemToSelect } from "components/core/MultiSelect/styled";
import InputForm from "components/core/InputForm";
import { useInfinitePagination, useParams, useDebounce } from "hooks";
import Button from "components/core/Button";
import { ErrorMsg } from "components/core/InputForm/styled";
import * as S from "./styled";

const ItemsToSelectWrapperWithSpinner = WithSpinner(ItemsToSelectWrapper);

const ActivityType = ({ onBack, onContinue }) => {
  const dispatch = useDispatch();
  const {
    activities: { items, totalPages },
    EnterpriseRequests,
  } = useSelector(({ enterprise }) => enterprise);

  const [filteredActivities, setFilteredActivities] = useState([]);

  const validation = yup.object().shape({
    enterpriseActivity: yup.string().required(<Trans i18nKey="required.activity" />),
  });

  const fetchActivities = (params) =>
    dispatch(EnterpriseActions.getActivitiesWithPagination(params));

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: validation,
    mode: "onBlur",
    submitFocusError: true,
  });

  const { page, setPage, setCustomParams } = useParams({
    callback: fetchActivities,
    search: "",
    defaultOrder: "asc",
  });
  const debounceChange = useDebounce(setCustomParams, 700);
  const lastItemRef = useInfinitePagination(
    EnterpriseRequests.getActivities,
    page < totalPages,
    setPage,
  );

  const handleSearch = ({ target: { value } }) => {
    debounceChange(() => ({
      search: value,
    }));
    setPage(1);
  };

  const handleChange = (value) => {
    setValue("idEnterpriseActivity", value.id);
    setValue("textEnterpriseActivity", value.name);
  };

  useEffect(() => {
    if (page === 1) {
      setFilteredActivities(items);
    } else if (items?.length) {
      setFilteredActivities((prevItems) => [...new Set([...prevItems, ...items])]);
    }
  }, [items, page]);

  const handleNextStep = (data) => {
    const event = "EtapaTipoAtividade";
    onContinue(data, event);
  };

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="34%" />
        </Container>
        <Subtitle mb="64px">Qual seria o tipo de atividade?</Subtitle>
        <Form onSubmit={handleSubmit(handleNextStep)}>
          <InputForm
            label=""
            type="text"
            name="search"
            placeholder="Busque o seu tipo de atividade"
            onChange={handleSearch}
            marginBottom="0"
          />
          <ItemsToSelectWrapperWithSpinner spinnerHeight="19.8rem" mb="5rem" he="40rem">
            {filteredActivities?.length ? (
              filteredActivities?.map((item, index) => (
                <ItemToSelect
                  key={item.id}
                  ref={index === filteredActivities.length - 1 ? lastItemRef : null}
                >
                  <RadioButton
                    register={register}
                    id={item.id}
                    name="enterpriseActivity"
                    label={item.name}
                    value={item}
                    handleChange={() => handleChange(item)}
                  />
                </ItemToSelect>
              ))
            ) : (
              <EmptyList>Atividade não encontrada</EmptyList>
            )}
          </ItemsToSelectWrapperWithSpinner>
          <ErrorMsg>{errors?.enterpriseActivity?.message}</ErrorMsg>
          <InputHidden ref={register} name="idEnterpriseActivity" />
          <InputHidden ref={register} name="textEnterpriseActivity" />

          <Row horizontalAlign="center">
            <Button handleClick={onBack} variant="outline">
              Voltar
            </Button>
            <Button type="submit">Continuar</Button>
          </Row>
        </Form>
      </Flex>
    </S.RegisterContainer>
  );
};

ActivityType.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default ActivityType;
