const defaultRegex = /(\d{1,4})/g;
const defaultValueRegex = "$1 ";
const defaultMask = "9999 9999 9999 9999";
const defaultLength = 16;
const defaultMaskedLength = 19;

const defaultOptions = {
  maskRegex: defaultRegex,
  valueRegex: defaultValueRegex,
  mask: defaultMask,
  length: defaultLength,
  maskedLength: defaultMaskedLength,
};

export const BRANDS = {
  AMEX: {
    brandName: "American Express",
    maskRegex: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    valueRegex: "$1 $2 $3",
    mask: "9999 999999 99999",
    length: 15,
    maskedLength: 17,
  },
  DINERSCLUB: {
    brandName: "Diners Club",
    maskRegex: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    valueRegex: "$1 $2 $3",
    mask: "9999 999999 9999",
    length: 14,
    maskedLength: 16,
  },
  DISCOVER: {
    brandName: "Discover",
    ...defaultOptions,
  },
  HIPERCARD: {
    brandName: "Hipercard",
    ...defaultOptions,
  },
  JCB: {
    brandName: "JCB",
    ...defaultOptions,
  },
  MAESTRO: {
    brandName: "Maestro",
    ...defaultOptions,
  },
  MASTERCARD: {
    brandName: "Mastercard",
    ...defaultOptions,
  },
  UNIONPAY: {
    brandName: "Union Pay",
    ...defaultOptions,
  },
  VISA: {
    brandName: "Visa",
    ...defaultOptions,
  },
};

export const BRAND_NUMBERS = {
  34: BRANDS.AMEX,
  35: BRANDS.JCB,
  36: BRANDS.DINERSCLUB,
  37: BRANDS.AMEX,
  38: BRANDS.DINERSCLUB,
  4: BRANDS.VISA,
  50: BRANDS.MAESTRO,
  51: BRANDS.MASTERCARD,
  52: BRANDS.MASTERCARD,
  53: BRANDS.MASTERCARD,
  54: BRANDS.MASTERCARD,
  55: BRANDS.MASTERCARD,
  56: BRANDS.MAESTRO,
  57: BRANDS.MAESTRO,
  58: BRANDS.MAESTRO,
  59: BRANDS.MAESTRO,
  60: BRANDS.HIPERCARD,
  62: BRANDS.UNIONPAY,
  65: BRANDS.DISCOVER,
};
