import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavBar from "components/core/NavBar";
import Button from "components/core/Button";
import OtherTypesTaxes from "components/presentation/Taxes/Edit/OtherTypesTaxes";
import { TaxesActions } from "store/ducks/taxes";
import { EnterpriseActions } from "store/ducks/enterprise";
import Modal from "components/core/Modal";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import { urls, taxesEnum, REQUEST_RESOLVED } from "utils/constants";
import useTaxes from "hooks/useTaxes";
import { formatNumberToReal, parseMoneyToFloat } from "helpers";
import { LayoutActions } from "store/ducks/layout";

const OtherTypesTaxesWithSpinner = WithSpinner(OtherTypesTaxes);

const OtherTypesTaxesContainer = () => {
  const { t: translate } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSimple, setIsSimple] = useState(false);
  const [newTaxes, setNewTaxes] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const { TaxesRequests } = useSelector(({ taxes }) => taxes);
  const { EnterpriseRequests, billingRanges } = useSelector(({ enterprise }) => enterprise);
  const [hasTaxes, getTaxByEnum] = useTaxes();
  const history = useHistory();
  const { id: enterpriseTypeID } = useParams();

  useEffect(() => {
    setIsSimple(Number(enterpriseTypeID) === taxesEnum.simpleId);
  }, [enterpriseTypeID]);

  useEffect(() => {
    if (billingRanges && hasTaxes) {
      const openingTax = isSimple
        ? getTaxByEnum(taxesEnum.openingTaxSimple)
        : getTaxByEnum(taxesEnum.openingTaxPresumed);

      const defaultTaxes = {
        allowInstallments: openingTax?.allowInstallments || true,
        maxInstallments: openingTax?.maxInstallments || 12,
        minInstallmentAmount: formatNumberToReal(openingTax?.minInstallmentAmount || 5),
        idTypeEnterprise: enterpriseTypeID,
        openingTax: formatNumberToReal(openingTax?.price),
        ranges: billingRanges[enterpriseTypeID]
          ?.map(({ billingMin, billingMax, price, annualPrice, biennialPrice }, index) => ({
            idRange: index + 1,
            billingMin: formatNumberToReal(billingMin),
            billingMax: formatNumberToReal(billingMax),
            price: formatNumberToReal(price),
            annualPrice: formatNumberToReal(annualPrice),
            biennialPrice: formatNumberToReal(biennialPrice),
          }))
          .slice(0, billingRanges[enterpriseTypeID].length - 1),
      };

      setDefaultValues(defaultTaxes);
    }
  }, [isSimple, billingRanges, hasTaxes]);

  const handleSubmit = ({
    idTypeEnterprise,
    openingTax,
    ranges,
    allowInstallments,
    maxInstallments,
    minInstallmentAmount,
  }) => {
    setShowConfirm(true);
    const valuesHandledForRequest = {
      idTypeEnterprise: Number(idTypeEnterprise),
      openingTax: parseMoneyToFloat(openingTax),
      allowInstallments: Boolean(allowInstallments),
      maxInstallments: Number(maxInstallments),
      minInstallmentAmount: Number.isNaN(parseMoneyToFloat(minInstallmentAmount))
        ? 0
        : parseMoneyToFloat(minInstallmentAmount),
      openingTax: parseMoneyToFloat(openingTax),
      ranges: ranges.map(
        ({ idRange, billingMin, billingMax, price, annualPrice, biennialPrice }) => ({
          idRange: Number(idRange),
          price: parseMoneyToFloat(price),
          annualPrice: parseMoneyToFloat(annualPrice),
          biennialPrice: parseMoneyToFloat(biennialPrice),
          billingMin: parseMoneyToFloat(billingMin),
          billingMax: parseMoneyToFloat(billingMax),
        }),
      ),
    };
    if (!isSimple) {
      delete valuesHandledForRequest.ranges[ranges.length - 1].billingMax;
    }

    setNewTaxes(valuesHandledForRequest);
  };

  const dispatchNewTaxes = () => {
    dispatch(TaxesActions.editOtherTypeTaxes(newTaxes));
  };

  useEffect(() => {
    if (!billingRanges[enterpriseTypeID]) {
      dispatch(EnterpriseActions.getBillingRanges(enterpriseTypeID));
    }
  }, [dispatch, enterpriseTypeID]);

  useEffect(() => {
    if (TaxesRequests.editOtherTypeTaxes === REQUEST_RESOLVED) {
      dispatch(
        LayoutActions.showConfirmationModal({
          content: <Span>{translate("updateSaveWithSuccess")}</Span>,
          type: "success",
        }),
      );
      dispatch(TaxesActions.cleanState());
      history.push(urls.ROUTES.taxes.path);
    }
  }, [TaxesRequests.editOtherTypeTaxes, dispatch, history, translate]);

  return (
    <>
      <NavBar title={translate("editPlans")}>
        <Button url={urls.ROUTES.taxes.path} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>{translate("save")}</Button>
      </NavBar>
      <OtherTypesTaxesWithSpinner
        isLoading={!defaultValues?.ranges?.length}
        multipleRequests={{ ...TaxesRequests, ...EnterpriseRequests }}
        submitRef={submitRef}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        isSimple={isSimple}
      />
      <Modal
        showModal={showConfirm}
        onCloseModal={() => setShowConfirm(false)}
        onConfirmModal={dispatchNewTaxes}
        title={translate("editTaxes")}
        width="49rem"
      >
        <Span>
          <strong>{translate("attention")}</strong>: {translate("changesWillAffectAllPlans")}
        </Span>
      </Modal>
    </>
  );
};

export default OtherTypesTaxesContainer;
