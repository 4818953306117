import styled from "styled-components";
import { Copy as CopyIcon, View as ViewIcon, Details as DetailsIcon } from "./icons";

export const IconCopy = styled(CopyIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const IconView = styled(ViewIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const IconItems = styled(DetailsIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;
