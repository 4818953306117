import { useEffect, useState } from "react";

const useItems = (items) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [everyoneIsChecked, setEveryoneIsChecked] = useState(false);
  const [hasSelectedItems, setHasSelectedItems] = useState(false);
  const [hasOneItemSelected, setHasOneItemSelected] = useState(false);
  const allowedItemsSelection = items
    ? items.filter(({ allowCheck }) => allowCheck !== false)
    : items;

  const removeItemById = (itemId) => {
    setSelectedItems(selectedItems.filter(({ id }) => id !== itemId));
  };

  const handleChange = (itemId) => {
    const item = selectedItems.find(({ id }) => id === itemId);
    if (!item) {
      const itemToAdd = allowedItemsSelection.find(({ id }) => id === itemId);
      setSelectedItems([...selectedItems, itemToAdd]);
      return;
    }

    removeItemById(itemId);
  };

  const toggleAll = ({ target: { checked } }) =>
    setSelectedItems(checked ? allowedItemsSelection : []);

  const clearSelection = () => setSelectedItems([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [items]);

  useEffect(() => {
    setHasSelectedItems(selectedItems?.length >= 1);
    setHasOneItemSelected(selectedItems?.length === 1);
    setEveryoneIsChecked(
      allowedItemsSelection?.length > 0 && selectedItems?.length === allowedItemsSelection?.length,
    );
  }, [allowedItemsSelection?.length, selectedItems]);

  useEffect(() => {
    setListChecked(
      items?.map((item) => {
        const checkedIndex = selectedItems.findIndex((selectedUser) => selectedUser.id === item.id);

        return { ...item, checked: checkedIndex > -1 };
      }),
    );
  }, [items, selectedItems]);

  return {
    handleChange,
    hasOneItemSelected,
    hasSelectedItems,
    listChecked,
    selectedItems,
    setSelectedItems,
    removeItemById,
    toggleAll,
    everyoneIsChecked,
    clearSelection,
  };
};

export default useItems;
