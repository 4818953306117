import React from "react";
import PropTypes from "prop-types";
import { Flex } from "components/core/Grid";
import { useSelector } from "react-redux";
import Portal from "components/core/Portal";
import Breadcrumb from "components/core/Breadcrumb";
import * as S from "./styled";

const NavBar = ({ title, children, description, breadcrumb, route, paddingBottom, actions }) => {
  const { isEmitterMenuOpen, isEmitterSearchOpen, isManageMenuOpen } = useSelector(
    ({ layout }) => layout,
  );

  return (
    <Portal>
      <S.Nav
        activeBlur={isEmitterMenuOpen || isEmitterSearchOpen || isManageMenuOpen}
        style={{ paddingBottom }}
      >
        <S.BoxTitle>
          <Flex direction="row" marginBottom="0" horizontalAlign="center">
            {breadcrumb && <Breadcrumb route={route} />}
            <S.Title>{title}</S.Title>
          </Flex>
          <S.Description>{description}</S.Description>
          {actions && <S.Actions>{actions}</S.Actions>}
        </S.BoxTitle>
        <S.Actions>{children}</S.Actions>
      </S.Nav>
    </Portal>
  );
};

NavBar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  breadcrumb: PropTypes.bool,
  route: PropTypes.array,
};

NavBar.defaultProps = {
  children: null,
  description: "",
  breadcrumb: false,
  route: [],
};

export default NavBar;
