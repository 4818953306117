import React, { useEffect, useState, lazy, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams as useParamsRouter } from "react-router-dom";
import { DocumentsActions } from "store/ducks/documents";
import { LayoutActions } from "store/ducks/layout";
import Button from "components/core/Button";
import { HiddenAhref } from "components/core/Form";
import { Span } from "components/core/Typography";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import DocumentsList from "components/presentation/DocumentsList";
import { useItems, useParams, useRequest } from "hooks";
import { ReactComponent as IconUpload } from "assets/icons/icon-upload.svg";

const DocumentsFilter = lazy(() => import("containers/DocumentsFilter"));
const UploadFileModal = lazy(() => import("containers/DocumentsSent/List/UploadFile"));
const DocumentPreview = lazy(() => import("components/core/DocumentPreview"));
const DocumentDelete = lazy(() => import("components/presentation/Documents/Delete"));

const DocumentsListWithSpinner = WithSpinner(DocumentsList);
const UploadFileModalWithSuspense = WithSuspense(UploadFileModal);
const DocumentsFilterWithSuspense = WithSuspense(DocumentsFilter);
const DocumentPreviewWithSuspense = WithSuspense(DocumentPreview);
const DocumentDeleteWithSuspense = WithSuspense(DocumentDelete);

const DocumentsSentListContainer = () => {
  const dispatch = useDispatch();
  const downloadRef = useRef(null);
  const { t: translate } = useTranslation();
  const { userId, documentId } = useParamsRouter();
  const [documentLink, setDocumentLink] = useState(null);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [documentToPreview, setDocumentToPreview] = useState(null);
  const {
    DocumentsRequests,
    documents: { items, total, totalPages },
    exportDocuments,
    document,
  } = useSelector(({ documents }) => documents);
  const { selectedItems, handleChange, toggleAll, listChecked, hasSelectedItems } = useItems(items);
  const {
    user: { roles },
  } = useSelector((state) => state.auth.claims);

  const fetchDocuments = (params) => {
    dispatch(DocumentsActions.fetchDocuments({ ...params, userId }));
    setShowUploadFile(false);
  };

  const {
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    dispatchWithParams,
    handleFilter,
    SmartFilterButton,
  } = useParams({ callback: fetchDocuments, onlySended: true });

  const handleShowDocument = (documentToPreviewId) => {
    setDocumentToPreview(items.find(({ id }) => documentToPreviewId === id));
  };

  const handleCloseShowDocument = () => {
    setDocumentToPreview(null);
    setShowDocumentPreview(false);
  };

  const dispatchExportDocuments = () => {
    dispatch(DocumentsActions.exportDocuments({ documents: selectedItems.map(({ id }) => id) }));
  };

  const dispatchDelete = () => {
    if (roles === "client") {
      const noDocumentsDownloads = [];

      selectedItems.map((item) => {
        if (item.userCreated.id !== parseInt(userId)) {
          noDocumentsDownloads.push(item);
        }
      });

      if (noDocumentsDownloads.length === 0) {
        dispatch(
          DocumentsActions.deleteDocuments({
            idList: documentToPreview?.id
              ? [documentToPreview.id]
              : selectedItems.map(({ id }) => id),
          }),
        );
      } else {
        dispatch(
          LayoutActions.showConfirmationModal({
            content: (
              <Span textAlign="center">
                Você tentou excluir um arquivo que não foi enviado por você, verifique os arquivos.
              </Span>
            ),
            type: "error",
          }),
        );
      }
    } else {
      dispatch(
        DocumentsActions.deleteDocuments({
          idList: documentToPreview?.id
            ? [documentToPreview.id]
            : selectedItems.map(({ id }) => id),
        }),
      );
      setShowDeleteConfirm(false);
    }
  };

  const onResolved = () => {
    if (exportDocuments.link) {
      setDocumentLink(exportDocuments.link);
    } else {
      dispatch(
        LayoutActions.showConfirmationModal({
          content: <Span>{translate(exportDocuments.message)}</Span>,
          type: "success",
        }),
      );
    }
  };

  useEffect(() => {
    setShowDocumentPreview(!!documentToPreview);
  }, [documentToPreview]);

  useEffect(() => {
    if (documentLink) {
      downloadRef.current.click();
      setDocumentLink(null);
      dispatch(DocumentsActions.cleanExportDocument());
    }
  }, [documentLink, dispatch]);

  useEffect(() => {
    if (documentId) {
      dispatch(DocumentsActions.fetchDocumentById(documentId));
    }
  }, [dispatch, documentId]);

  useRequest({
    request: DocumentsRequests.EXPORT_DOCUMENTS,
    onResolved,
  });

  useRequest({
    request: DocumentsRequests.FETCH_DOCUMENT_BY_ID,
    onResolved: () => document && setDocumentToPreview(document),
  });

  useRequest({
    request: DocumentsRequests.DELETE_DOCUMENTS,
    onResolved: () => {
      dispatchWithParams();
      setShowDocumentPreview(false);
    },
  });

  useRequest({
    request: DocumentsRequests.UPLOAD_DOCUMENT,
    onResolved: () => {
      dispatch(DocumentsActions.cleanUploadDocument());
      setShowUploadFile(false);
    },
  });

  useEffect(() => () => DocumentsActions.cleanState(), []);

  return (
    <>
      <NavBar title={translate("uploadDocuments")}>
        {hasSelectedItems ? (
          <>
            <Button variant="outline" color="danger" handleClick={() => setShowDeleteConfirm(true)}>
              {translate("delete")}
            </Button>
            <Button variant="outline" handleClick={dispatchExportDocuments}>
              {translate("makeDownload")}
            </Button>
          </>
        ) : (
          <>
            <SmartFilterButton handleShowFilter={() => setShowFilter(true)} />
            <Button handleClick={() => setShowUploadFile(true)}>
              <IconUpload />
              <span>{translate("newUpload")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <DocumentsListWithSpinner
        requestStatus={DocumentsRequests.FETCH_DOCUMENTS}
        handleChange={handleChange}
        handleShowDocument={handleShowDocument}
        documents={listChecked}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        toggleAll={toggleAll}
        checkedAll={selectedItems?.length === listChecked?.length}
      />
      <DocumentsFilterWithSuspense
        loadComponent={showFilter}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        dispatchWithParams={handleFilter}
      />
      <UploadFileModalWithSuspense
        loadComponent={showUploadFile}
        showModal={showUploadFile}
        setShowModal={setShowUploadFile}
        fetchDocuments={dispatchWithParams}
      />
      <DocumentPreviewWithSuspense
        loadComponent={showDocumentPreview}
        open={showDocumentPreview}
        document={documentToPreview}
        handleClose={handleCloseShowDocument}
        handleDelete={() => setShowDeleteConfirm(true)}
      />
      <DocumentDeleteWithSuspense
        loadComponent={showDeleteConfirm}
        showConfirm={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={dispatchDelete}
      />
      <HiddenAhref ref={downloadRef} href={documentLink} download>
        {translate("download")}
      </HiddenAhref>
    </>
  );
};

export default DocumentsSentListContainer;
