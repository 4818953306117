import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CouponsActions } from "store/ducks/coupons";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import CouponsList from "components/presentation/Coupons/List";
import { useItems, useParams, useRequest } from "hooks";
import { urls } from "utils/constants";
import { ReactComponent as IconAddUser } from "assets/icons/icon-add-user.svg";
import { formatDate } from "helpers";

const Filter = React.lazy(() => import("containers/Coupons/List/Filter"));
const DeleteCouponsConfirm = React.lazy(() => import("components/presentation/Documents/Delete"));

const CouponsListWithSpinner = WithSpinner(CouponsList);
const FilterWithSuspense = WithSuspense(Filter);
const DeleteCouponsConfirmWithSuspense = WithSuspense(DeleteCouponsConfirm);

const CouponsListContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDisableConfirm, setShowDisableConfirm] = useState(false);
  const fetchCoupons = (newParams) => {
    dispatch(CouponsActions.getCoupons(newParams));
  };
  const {
    CouponsRequests,
    coupons: { items, total, totalPages },
  } = useSelector(({ coupons }) => coupons);
  const {
    handleChange,
    hasOneItemSelected,
    hasSelectedItems,
    listChecked: itemsChecked,
    selectedItems,
    toggleAll,
  } = useItems(items);
  const {
    params,
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchCoupons,
    defaultOrderBy: "code",
  });

  const selectItems = () => selectedItems?.map(({ id }) => id);

  const dispatchDelete = () => {
    dispatch(CouponsActions.deleteCoupons({ ids: selectItems() }));
    setShowDeleteConfirm(false);
  };

  const dispatchDisable = () => {
    dispatch(CouponsActions.disableCoupons({ ids: selectItems() }));
    setShowDisableConfirm(false);
  };

  useRequest({
    request: CouponsRequests.DELETE_COUPONS,
    onResolved: () => fetchCoupons(params),
  });

  useRequest({
    request: CouponsRequests.DISABLE_COUPONS,
    onResolved: () => fetchCoupons(params),
  });

  const newItemsChecked = itemsChecked
    ? itemsChecked.map((item) => ({ ...item, createdAt: formatDate(item.createdAt) }))
    : [];

  return (
    <>
      <NavBar title={translate("coupons")}>
        {hasSelectedItems && (
          <Span>{translate("couponsSelected", { count: selectedItems.length })}</Span>
        )}
        {hasSelectedItems && (
          <>
            <Button variant="outline" color="danger" handleClick={() => setShowDeleteConfirm(true)}>
              {translate("delete")}
            </Button>
            <Button
              variant="outline"
              color="danger"
              handleClick={() => setShowDisableConfirm(true)}
            >
              {translate("disable")}
            </Button>
          </>
        )}
        {!hasOneItemSelected && !hasSelectedItems && (
          <>
            <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
            <Button url={urls.LINKS.couponsRegister}>
              <IconAddUser />
              <span>{translate("addCoupons")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <CouponsListWithSpinner
        requestStatus={CouponsRequests.GET_COUPONS}
        coupons={newItemsChecked}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        handleChange={handleChange}
        toggleAll={toggleAll}
        checkedAll={selectedItems?.length === itemsChecked?.length}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
      <DeleteCouponsConfirmWithSuspense
        titleTranslateKey="deleteCoupons"
        messageTranslateKey="deleteCouponsConfirm"
        loadComponent={showDeleteConfirm}
        showConfirm={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={dispatchDelete}
      />
      <DeleteCouponsConfirmWithSuspense
        titleTranslateKey="disableCoupons"
        messageTranslateKey="disableCouponsConfirm"
        loadComponent={showDisableConfirm}
        showConfirm={showDisableConfirm}
        onCancel={() => setShowDisableConfirm(false)}
        onConfirm={dispatchDisable}
      />
    </>
  );
};

export default CouponsListContainer;
