import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PaymentGatewaysActions } from "store/ducks/paymentGateways";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import PaymentGatewayDetails from "components/presentation/PaymentGateways/Details";
import { urls } from "utils/constants";

const PaymentGatewaysDetailsWithSpinner = WithSpinner(PaymentGatewayDetails);

const PaymentGatewaysDetailsContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const { paymentGateway, PaymentGatewaysRequestsStatus } = useSelector(
    ({ paymentGateways }) => paymentGateways,
  );
  const dispatchGetPaymentGateway = useCallback(
    () => dispatch(PaymentGatewaysActions.getPaymentGatewayById(id)),
    [dispatch, id],
  );
  useEffect(() => {
    dispatchGetPaymentGateway();
  }, [dispatchGetPaymentGateway]);
  return (
    <>
      <NavBar title={translate("Payment Gateways")}>
        <Button url={urls.LINKS.paymentGatewaysEdit(id)}>{translate("edit")}</Button>
      </NavBar>
      <PaymentGatewaysDetailsWithSpinner
        requestStatus={PaymentGatewaysRequestsStatus.getPaymentGatewaysById}
        paymentGateway={paymentGateway}
      />
    </>
  );
};

export default PaymentGatewaysDetailsContainer;
