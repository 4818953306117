import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useFinancial, useItems } from "hooks";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import GlobalSettingsList from "components/presentation/Financial/GlobalSettings/List";
import Button from "components/core/Button";
import { LINKS } from "utils/constants";

const GlobalSettingListWithSpinner = WithSpinner(GlobalSettingsList);

const GlobalFinancialSettingListContainer = () => {
  const {
    globalSettings: { items, total, totalPages },
    getGlobalSettings,
    requestStatus,
  } = useFinancial();
  const {
    everyoneIsChecked,
    selectedItems,
    handleChange,
    toggleAll,
    listChecked,
    hasOneItemSelected,
  } = useItems(items);
  const { t: translate } = useTranslation();
  const fetchSettings = (params) => {
    getGlobalSettings({
      ...params,
      active: true,
    });
  };

  const { orderBy, order, page, perPage, setPage, setPerPage, setOrderBy, setOrder } = useParams({
    callback: fetchSettings,
    defaultOrderBy: "createdAt",
    defaultOrder: "desc",
  });

  return (
    <>
      <NavBar title={translate("Global Installments Settings")} route={null}>
        <Button url={LINKS.financial.installments.add} variant="outline">
          {translate("add")}
        </Button>
        {hasOneItemSelected && (
          <Button url={LINKS.financial.installments.details(selectedItems[0].id)}>
            {translate("edit")}
          </Button>
        )}
      </NavBar>
      <GlobalSettingListWithSpinner
        requestStatus={requestStatus.GET_GLOBAL_SETTINGS}
        globalSettings={listChecked ?? items}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
        everyoneIsChecked={everyoneIsChecked}
        toggleAll={toggleAll}
        handleChange={handleChange}
        selectedGlobalSettings={selectedItems}
      />
    </>
  );
};

export default GlobalFinancialSettingListContainer;
