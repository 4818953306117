import styled from "styled-components";
import { ReactComponent as EmailIcon } from "assets/icons/icon-attach.svg";
import { ReactComponent as CheckoutIcon } from "assets/icons/icon-check-blue.svg";
import { ReactComponent as CancelIcon } from "assets/icons/icon-not-check.svg";
import { ReactComponent as CopyIcon } from "assets/icons/icon-copy.svg";
import { ReactComponent as PendingIcon } from "assets/icons/icon-alert.svg";
import { ReactComponent as EditIcon } from "assets/icons/icon-edit.svg";

export const IconEmail = styled(EmailIcon)`
  height: 24px;
  width: 24px;
`;
export const IconCheckout = styled(CheckoutIcon)`
  height: 24px;
  width: 24px;
`;
export const IconCancel = styled(CancelIcon)`
  height: 24px;
  width: 24px;
`;
export const IconCopy = styled(CopyIcon)`
  height: 24px;
  width: 24px;
`;
export const IconPending = styled(PendingIcon)`
  height: 24px;
  width: 24px;
`;
export const IconEdit = styled(EditIcon)`
  height: 24px;
  width: 24px;
`;
export const ActionDiv = styled.div`
  display: flex;
  gap: 3px;
`;
