import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InvoicePaymentActions } from "store/ducks/invoicePayment";
import { PaymentActions } from "store/ducks/payment";
import useScript from "hooks/useScript";
import WithSpinner, { isPending, isResolved, isRejected } from "components/core/WithSpinner";
import Payment from "components/presentation/Financial/Payment";
import { MASK_TYPES, format } from "utils/masks";
import { ModalCreditCard, Span } from "components/core";
import { Modal } from "components/core/Modal";
import { useFinancial } from "hooks";

const PaymentWithSpinner = WithSpinner(Payment);

const PaymentContainer = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const {
    invoicePayment: { invoice, invoiceCharge, requestStatus },
  } = useSelector((invoicePayment) => invoicePayment);
  const {
    payment: { requestStatus: paymentRequestStatus },
  } = useSelector((payment) => payment);
  const [scriptLoaded] = useScript(invoice?.paymentGateway?.urlCheckout);
  const { getPlanInvoice, planInvoice, requestStatus: financialRequestStatus } = useFinancial();
  const [feedback, setFeedback] = useState(null);
  const [showModalCard, setShowModalCard] = useState(false);
  const [resetCreditCardData, setResetCreditCardData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showPixForm, setShowPixForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [installments, setInstallments] = useState(null);
  const [showBilletForm, setShowBilletForm] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmPaymentCaption, setConfirmPaymentCaption] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [hasItemBillet, setHasItemBillet] = useState(null);
  const [maxInstallments, setMaxInstallments] = useState(null);
  const { enterprise, accountInfo, invoiceItems, planValues, subscription } = invoice;

  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

  useEffect(() => {
    setLoading(
      isPending(requestStatus.GET_INVOICE_BY_TOKEN) || isPending(requestStatus.CHARGE_INVOICE),
    );
    if (isPending(requestStatus.CHARGE_INVOICE)) {
      setShowModalCard(false);
    }
    if (isRejected(requestStatus.GET_INVOICE_BY_TOKEN)) {
      dispatch(InvoicePaymentActions.cleanState());
      setFeedback({
        title: "",
        isSuccess: false,
        description: "Não encontramos as informações da fatura!",
        showDeeplink: true,
        emphasis: "",
        paymentFlag: false,
      });
    }
  }, [requestStatus.GET_INVOICE_BY_TOKEN, requestStatus.CHARGE_INVOICE]);

  useEffect(() => {
    if (isResolved(paymentRequestStatus.DOWNLOAD_GATEWAY_INVOICE)) {
      setIsDownloading(false);
      setIsDownloaded(true);
      setTimeout(() => {
        setIsDownloaded(false);
      }, 3000);
    }
  }, [paymentRequestStatus]);

  useEffect(() => {
    dispatch(InvoicePaymentActions.getInvoiceByToken(token));
  }, [token, dispatch]);

  useEffect(() => {
    if (subscription && planValues) {
      setSelectedPlan({
        code: subscription.currentPlan,
        ...planValues?.[subscription.currentPlan],
      });
    }
  }, [subscription, planValues]);

  useEffect(() => {
    if (invoiceItems && invoiceItems.length) {
      const hasBillet = invoiceItems.filter(
        ({ description }) => description?.indexOf("Boleto") !== -1,
      )?.length;
      setHasItemBillet(hasBillet);
    }
  }, [invoiceItems]);

  useEffect(() => {
    setLoading(isPending(financialRequestStatus.GET_PLAN_INVOICE));
    if (isResolved(financialRequestStatus.GET_PLAN_INVOICE)) {
      if (paymentMethod === "1") setShowBilletForm(true);
      if (paymentMethod === "3") setShowPixForm(true);
    }
  }, [financialRequestStatus]);

  useEffect(() => {
    if ((isNewPlan() && planInvoice) || (!isNewPlan() && !hasItemBillet)) {
      setPaymentData(planInvoice);
    } else {
      if (!invoice?.id) {
        setPaymentData(null);
      } else {
        setPaymentData({
          invoiceUrl: invoice.invoiceUrl,
          ...invoice.paymentData.pix,
          ...invoice.paymentData.bankSlip,
        });
      }
    }
  }, [planInvoice, selectedPlan, hasItemBillet, invoice]);

  useEffect(() => {
    if (selectedPlan && invoice) {
      let installments = invoice?.installments;
      if (selectedPlan?.code) {
        installments = invoice?.planValues?.[selectedPlan?.code]?.installments;
      }
      setInstallments(installments);
    }
  }, [selectedPlan, invoice]);

  const formatDate = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const isNewPlan = () => selectedPlan?.code !== subscription?.currentPlan;

  const totalPrice = () => {
    if (selectedPlan) {
      return invoice?.planValues?.[selectedPlan?.code]?.price;
    } else {
      return hasItemBillet ? invoice?.total - hasItemBillet?.price : invoice?.total;
    }
  };

  const handleClipboard = (text) => {
    if (!text) return;
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleDownload = () => {
    setIsDownloading(true);
    dispatch(
      PaymentActions.downloadGatewayInvoice({
        enterpriseId: invoice?.enterpriseId,
        invoiceId: paymentData?.gatewayInvoiceId,
      }),
    );
  };

  const handleNext = () => {
    if (paymentMethod === "1") {
      if (!isNewPlan() && hasItemBillet) {
        setPaymentData({
          ...invoice?.paymentData?.bankSlip,
        });
        setShowBilletForm(true);
      } else {
        getPlanInvoice({
          newPlan: {
            code: selectedPlan.code,
            ...invoice?.planValues?.[selectedPlan.code],
          },
          currentPlan: subscription?.currentPlan,
          enterpriseId: invoice?.enterpriseId,
          invoiceId: invoice?.id,
          subscriptionId: subscription?.id,
          paymentMethodId: Number(paymentMethod),
        });
      }
    } else if (paymentMethod === "3") {
      if (!isNewPlan()) {
        setPaymentData({
          ...invoice?.paymentData?.pix,
        });
        setShowPixForm(true);
      } else {
        getPlanInvoice({
          newPlan: {
            code: selectedPlan.code,
            ...invoice?.planValues?.[selectedPlan.code],
          },
          currentPlan: subscription?.currentPlan,
          enterpriseId: invoice?.enterpriseId,
          invoiceId: invoice?.id,
          subscriptionId: subscription?.id,
          paymentMethodId: Number(paymentMethod),
        });
      }
    } else {
      if (!isNewPlan()) {
        const price = hasItemBillet
          ? Number(invoice?.total) - Number(invoice?.billetCostTax?.price)
          : Number(invoice?.total);
        setConfirmPaymentCaption(`Pagar ${format(price.toFixed(2), MASK_TYPES.realWithName)}`);
        setShowModalCard(true);
      } else {
        const price = totalPrice();
        setConfirmPaymentCaption(`Pagar ${format(price.toFixed(2), MASK_TYPES.realWithName)}`);
        setShowModalCard(true);
      }
    }
  };

  useEffect(() => {
    setMaxInstallments(accountInfo?.data?.configuration?.credit_card?.max_installments || 1);
  }, [accountInfo]);

  useEffect(() => {
    if (invoiceCharge) {
      if (invoiceCharge.success) {
        dispatch(InvoicePaymentActions.cleanState());
        setResetCreditCardData(true);
        setFeedback({
          title: "",
          isSuccess: true,
          description: `Seu pagamento está sendo processado, e assim que houver a compensação bancária, constará no aplicativo!`,
          showDeeplink: false,
          paymentFlag: true,
          buttons:
            process.env.REACT_APP_URL_APPS && process.env.REACT_APP_ALLOW_OPEN_MOBILE == "true"
              ? [
                  {
                    text: "Voltar",
                    handleClick: () => {
                      window.open(process.env.REACT_APP_URL_APPS, "_self");
                    },
                  },
                ]
              : [],
        });
        setShowModalCard(false);
      } else {
        setFeedback({
          title: "Falha ao processar o pagamento!",
          isSuccess: false,
          description: invoiceCharge.errors,
          showDeeplink: false,
          paymentFlag: true,
          buttons: [
            {
              text: "Tentar novamente",
              handleClick: () => {
                setFeedback(null);
                setShowModalCard(true);
                // window.location.reload();
              },
            },
          ],
        });
      }
    }
  }, [invoiceCharge, setFeedback, setShowModalCard, setResetCreditCardData]);

  const handlePayment = async ({
    form,
    ccNumber,
    subscriptionInstallments,
    openingTaxInstallments,
  }) => {
    window.Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID);
    if (process.env.REACT_APP_IUGU_TESTE_MODE == "true") {
      window.Iugu.setTestMode(true);
    }
    window.Iugu.createPaymentToken(form, function (response) {
      if (response.errors) {
        if (response.errors.adblock) {
          setErrorMessage(response.errors.adblock);
        }
        setShowMessageModal(true);
      } else {
        const brand = window.Iugu.utils.getBrandByCreditCardNumber(ccNumber);
        const paymentFormIdentifier = ccNumber.slice(-4);
        const data = {
          paymentFormIdentifier: `${brand} - ${paymentFormIdentifier}`,
          paymentToken: token,
          token: response.id,
          paymentMethodId: Number(paymentMethod),
          invoiceInstallments: subscriptionInstallments,
          openingTaxInvoiceInstallments: openingTaxInstallments,
          currentPlan: subscription?.currentPlan,
          newPlan: {
            code: selectedPlan.code,
            ...invoice?.planValues?.[selectedPlan.code],
          },
        };
        dispatch(InvoicePaymentActions.chargeInvoice(data));
      }
    });
  };

  return (
    <>
      <PaymentWithSpinner
        copied={copied}
        feedback={feedback}
        formatDate={formatDate}
        invoice={invoice}
        isDownloaded={isDownloaded}
        isDownloading={isDownloading}
        isLoading={loading}
        isMobile={isMobile}
        isNewPlan={isNewPlan}
        handleDownload={handleDownload}
        handleClipboard={handleClipboard}
        handleNext={handleNext}
        hasItemBillet={hasItemBillet}
        paymentData={paymentData}
        paymentMethod={paymentMethod}
        planInvoice={planInvoice}
        scriptLoaded={scriptLoaded}
        setPaymentMethod={setPaymentMethod}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        setShowBilletForm={setShowBilletForm}
        setShowPixForm={setShowPixForm}
        showBilletForm={showBilletForm}
        showPixForm={showPixForm}
      />
      <ModalCreditCard
        confirmLabel={confirmPaymentCaption}
        onConfirmModal={handlePayment}
        onCloseModal={() => setShowModalCard(false)}
        showModal={showModalCard && scriptLoaded}
        width="43.5rem"
        enterprise={enterprise}
        resetForm={resetCreditCardData}
        paymentMethod={paymentMethod}
        maxInstallments={maxInstallments}
        totalPrice={totalPrice}
        selectedPlan={selectedPlan}
        installments={installments}
      />
      <Modal
        title="Falha na validação do cartão"
        hideConfirm
        cancelLabel="Fechar"
        width="43.5rem"
        onCloseModal={() => setShowMessageModal(false)}
        showModal={showMessageModal}
      >
        {errorMessage ? (
          <Span>{errorMessage}</Span>
        ) : (
          <Span>
            Não foi possável validar o seu cartão de crédito. <br />
            Por favor, verifique os dados digitados ou tente novamente com um novo cartão de
            crédito.
          </Span>
        )}
      </Modal>
    </>
  );
};

export default PaymentContainer;
