import React, { useState, useEffect, useCallback, lazy } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BatchesActions } from "store/ducks/batches";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import DetailsInformation from "components/presentation/Batches/Details";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import { ROUTES, urls } from "utils/constants";

const Reissue = lazy(() => import("./Reissue"));
const Canceled = lazy(() => import("./Canceled"));

const CanceledModal = WithSuspense(Canceled);
const ReissueModal = WithSuspense(Reissue);
const BatchesDetailsWithSpinner = WithSpinner(DetailsInformation);

const Details = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { Identify, id } = useParams();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showCanceledModal, setShowCanceledModal] = useState(false);
  const [typeDownload, setTypeDownload] = useState("");

  const { BatchesRequests, batches, downloadLink, canceledRequest, reissueRequest } = useSelector(
    (state) => state.batches,
  );

  const dispatchGetDetails = useCallback(
    () => dispatch(BatchesActions.getBatchesDetails(id)),
    [dispatch, id],
  );

  useEffect(() => {
    if (downloadLink && typeDownload.length) {
      fetch(downloadLink, {
        method: "GET",
        headers: {
          "Content-Type": `${
            typeDownload === "pdf" ? "application/pdf" : "text/xml;charset=UTF-8"
          }`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `nota.${typeDownload}`);

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
          setTypeDownload("");
          dispatch(BatchesActions.cleanDownloadLink());
        });
    }
  }, [dispatch, downloadLink, history, typeDownload]);

  useEffect(() => {
    if (reissueRequest) {
      setShowModal(true);
      dispatch(BatchesActions.cleanReissueBatches());
    }
  }, [dispatch, reissueRequest]);

  useEffect(() => {
    if (canceledRequest) {
      setShowCanceledModal(true);
      dispatch(BatchesActions.cleanCancelBatches());
    }
  }, [canceledRequest, dispatch]);

  useEffect(() => {
    dispatchGetDetails();
  }, [dispatchGetDetails]);

  // const handleReissue = () => {
  //   const data = {
  //     ids: [id],
  //   };

  //   dispatch(BatchesActions.patchBatchesReissue(Identify, data));
  // };

  const handleCloseModal = () => {
    history.goBack();
  };

  // const handleExport = (type) => {
  //   setTypeDownload(type);
  //   const data = {
  //     fileType: type,
  //     invoices: [id],
  //   };
  //   dispatch(BatchesActions.exportBatches(data));
  // };

  const handleCancel = () => {
    dispatch(BatchesActions.cancelBatches(id));
  };

  const {
    LINKS: { batchesCompany },
  } = urls;

  const routeToList = {
    i18nKey: ROUTES.batches.children.company.i18nKey,
    path: batchesCompany(Identify),
  };

  return (
    <>
      <NavBar title={translate("batchesDetailsTitle")} breadcrumb route={routeToList}>
        {batches?.status === "Autorizada" && (
          <>
            <Button handleClick={() => handleCancel()}>
              <span>{translate("cancel")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <BatchesDetailsWithSpinner
        total={Object.entries(batches).length}
        requestStatus={BatchesRequests.GET_BATCHES}
        batche={batches}
      />

      <CanceledModal
        handleCloseModal={handleCloseModal}
        loadComponent={showCanceledModal}
        setShowModal={setShowCanceledModal}
        showModal={showCanceledModal}
      />

      <ReissueModal
        handleCloseModal={handleCloseModal}
        loadComponent={showModal}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </>
  );
};

export default Details;
