import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { GET, PUT, POST } from "utils/constants/verbs";
import { INVOICE_BY_TOKEN, CHARGE_INVOICE } from "utils/constants/endpoints";
import { InvoicePaymentTypes, InvoicePaymentActions } from "store/ducks/invoicePayment";

export function* getInvoiceByToken({ token, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: INVOICE_BY_TOKEN(token),
    });
    yield put(InvoicePaymentActions.getInvoiceByTokenSuccess(data));
  } catch (error) {
    yield put(InvoicePaymentActions.requestFailure(error, type));
  }
}

export function* chargeInvoice({ type, payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: CHARGE_INVOICE,
      data: payload,
    });
    yield put(InvoicePaymentActions.chargeInvoiceSuccess(data));
  } catch (error) {
    yield put(InvoicePaymentActions.requestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(InvoicePaymentTypes.GET_INVOICE_BY_TOKEN, getInvoiceByToken);
  yield takeLatest(InvoicePaymentTypes.CHARGE_INVOICE, chargeInvoice);
}
