import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UsersActions } from "store/ducks/users";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import UserDetails from "components/presentation/User/Details";
import { urls } from "utils/constants";

const UserDetailsWithSpinner = WithSpinner(UserDetails);

const UserDetailsContainer = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const { userDetails, UsersRequests } = useSelector(({ users }) => users);

  const dispatchGetUser = useCallback(() => dispatch(UsersActions.getUserById(id)), [dispatch, id]);

  useEffect(() => {
    dispatchGetUser();
  }, [dispatchGetUser]);

  return (
    <>
      <NavBar title={translate("user")}>
        <Button url={urls.LINKS.userEdit(id)}>{translate("edit")}</Button>
      </NavBar>
      <UserDetailsWithSpinner requestStatus={UsersRequests.getUserById} user={userDetails} />
    </>
  );
};

export default UserDetailsContainer;
