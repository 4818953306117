import * as Yup from "yup";

export const passwordStrength = {
  hasMinLength: /^(?=.{6,})/,
  hasNumber: /\d/g,
  hasSymbol: /\W/g,
  hasCapitalLetter: /[A-Z]/g,
  hasLowercaseLetter: /[a-z]/g,
};

export const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "A senha é muito curta")
    .required("A senha é necessária")
    .matches(passwordStrength.hasMinLength, "6 caracteres")
    .matches(passwordStrength.hasNumber, "1 número")
    .matches(passwordStrength.hasSymbol, "1 símbolo (+-#*)")
    .matches(passwordStrength.hasCapitalLetter, "1 letra maiúscula")
    .matches(passwordStrength.hasLowercaseLetter, "1 letra minúscula"),
  confirmPassword: Yup.string()
    .required("Confirme a sua nova senha")
    .oneOf([Yup.ref("password"), null], "As senhas não correspondem."),
});

export const initialValues = {
  password: "",
  confirmPassword: "",
};

export const validatePassword = (password, callback) => {
  Yup.string()
    .matches(passwordStrength.hasMinLength, "6 caracteres")
    .matches(passwordStrength.hasNumber, "1 número")
    .matches(passwordStrength.hasSymbol, "1 símbolo (+-#*)")
    .matches(passwordStrength.hasCapitalLetter, "1 letra maiúscula")
    .matches(passwordStrength.hasLowercaseLetter, "1 letra minúscula")
    .validate(password, { abortEarly: false })
    .then(() => callback([]))
    .catch(({ errors }) => callback(errors));
};
