import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as S from "./styled";

const Breadcrumb = ({ route }) => {
  const { t: translate } = useTranslation();
  return (
    <S.Wrapper>
      {route?.i18nKey?.length && (
        <Link to={route.path}>
          <S.Title>{`${translate(route.i18nKey)} > `}</S.Title>
        </Link>
      )}
    </S.Wrapper>
  );
};

Breadcrumb.propTypes = {
  route: PropTypes.array,
};

Breadcrumb.defaultProps = {
  route: {},
};

export default Breadcrumb;
