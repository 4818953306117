import styled from "styled-components";
import { Link } from "react-router-dom";
import { HidePassword as HidePasswordIcon, ShowPassword as ShowPasswordIcon } from "./icons";

export const BtnLink = styled(Link)`
  color: var(--color-black-2);
  font-size: var(--font-size-default);
  margin-bottom: 5rem;
`;

export const IconPasswordShow = styled(ShowPasswordIcon)`
  height: 24px;
  width: 24px;
  position: absolute;
  padding: 4px;
  right: 10px;
  top: 40%;
`;

export const IconPasswordHide = styled(HidePasswordIcon)`
  height: 24px;
  width: 24px;
  position: absolute;
  padding: 4px;
  right: 10px;
  top: 40%;
`;
