import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants/request";

export const { Types: InvoiceTypes, Creators: InvoiceActions } = createActions({
  invoiceListError: ["payload"],
  invoiceNewList: ["payload"],
  invoiceIssue: ["payload"],
  invoiceIssueSuccess: ["payload"],
  invoiceIssueFailure: ["error", "originalType"],
  fetchInvoices: ["id", "params"],
  fetchInvoicesSuccess: ["payload"],
  fetchInvoiceDetails: ["id"],
  fetchInvoiceDetailsSuccess: ["payload"],
  exportInvoices: ["payload"],
  exportInvoicesSuccess: ["payload"],
  getAllInvoicesTypes: null,
  getAllInvoicesTypesSuccess: ["payload"],
  resetExportInvoices: null,
  invoiceRequestFailure: ["error", "originalType"],
  cleanState: null,
  newListClientClean: null,
  invoiceListErrorClean: null,
});

export const INITIAL_STATE = {
  rejectedInvoices: [],
  newListClient: [],
  createInvoice: null,
  invoices: {},
  details: null,
  types: [],
  invoiceFiles: null,
  invoiceIssusError: "",
  InvoicesRequests: {
    invoiceListError: REQUEST_NOT_STARTED,
    createInvoice: REQUEST_NOT_STARTED,
    getAllInvoicesTypes: REQUEST_NOT_STARTED,
    [InvoiceTypes.FETCH_INVOICES]: REQUEST_NOT_STARTED,
    [InvoiceTypes.FETCH_INVOICE_DETAILS]: REQUEST_NOT_STARTED,
    [InvoiceTypes.EXPORT_INVOICES]: REQUEST_NOT_STARTED,
  },
};

const invoiceListError = (state, { payload }) => ({
  ...state,
  rejectedInvoices: payload,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.INVOICE_LIST_ERROR]: REQUEST_RESOLVED,
  },
});

const invoiceListErrorClean = (state) => ({
  ...state,
  rejectedInvoices: [],
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.INVOICE_ISSUE]: REQUEST_NOT_STARTED,
  },
});

const invoiceNewList = (state, { payload }) => ({
  ...state,
  newListClient: payload,
});

const invoiceIssue = (state) => ({
  ...state,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.INVOICE_ISSUE]: REQUEST_PENDING,
  },
});

const invoiceIssueSuccess = (state) => ({
  ...state,
  createInvoice: state,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.INVOICE_ISSUE]: REQUEST_RESOLVED,
  },
});

const invoiceIssueFailure = (
  state,
  {
    error: {
      response: { data },
    },
  },
) => ({
  ...state,
  invoiceIssusError: {
    message: data?.message ?? data.errors ?? "something-went-wrong",
  },
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.INVOICE_ISSUE]: REQUEST_REJECTED,
  },
});

const fetchInvoices = (state) => ({
  ...state,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.FETCH_INVOICES]: REQUEST_PENDING,
  },
});

const fetchInvoicesSuccess = (state, { payload }) => ({
  ...state,
  invoices: payload,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.FETCH_INVOICES]: REQUEST_RESOLVED,
  },
});

const fetchInvoiceDetails = (state) => ({
  ...state,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.FETCH_INVOICE_DETAILS]: REQUEST_PENDING,
  },
});

const fetchInvoiceDetailsSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.FETCH_INVOICE_DETAILS]: REQUEST_RESOLVED,
  },
});

const exportInvoices = (state) => ({
  ...state,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.EXPORT_INVOICES]: REQUEST_PENDING,
  },
});

const exportInvoicesSuccess = (state, { payload }) => ({
  ...state,
  invoiceFiles: payload,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.EXPORT_INVOICES]: REQUEST_RESOLVED,
  },
});

const getAllInvoicesTypes = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllInvoicesTypes: REQUEST_PENDING,
  },
});

const getAllInvoicesTypesSuccess = (state, { payload }) => ({
  ...state,
  types: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getAllInvoicesTypes: REQUEST_RESOLVED,
  },
});

const resetExportInvoices = (state) => ({
  ...state,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [InvoiceTypes.EXPORT_INVOICES]: REQUEST_NOT_STARTED,
  },
});

const invoiceRequestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  InvoicesRequests: {
    ...state.InvoicesRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

const newListClientClean = (state) => ({
  ...state,
  newListClient: [],
});

export default createReducer(INITIAL_STATE, {
  [InvoiceTypes.INVOICE_LIST_ERROR]: invoiceListError,
  [InvoiceTypes.INVOICE_ISSUE]: invoiceIssue,
  [InvoiceTypes.INVOICE_ISSUE_SUCCESS]: invoiceIssueSuccess,
  [InvoiceTypes.INVOICE_ISSUE_FAILURE]: invoiceIssueFailure,
  [InvoiceTypes.FETCH_INVOICES]: fetchInvoices,
  [InvoiceTypes.FETCH_INVOICES_SUCCESS]: fetchInvoicesSuccess,
  [InvoiceTypes.FETCH_INVOICE_DETAILS]: fetchInvoiceDetails,
  [InvoiceTypes.FETCH_INVOICE_DETAILS_SUCCESS]: fetchInvoiceDetailsSuccess,
  [InvoiceTypes.EXPORT_INVOICES]: exportInvoices,
  [InvoiceTypes.EXPORT_INVOICES_SUCCESS]: exportInvoicesSuccess,
  [InvoiceTypes.GET_ALL_INVOICES_TYPES]: getAllInvoicesTypes,
  [InvoiceTypes.GET_ALL_INVOICES_TYPES_SUCCESS]: getAllInvoicesTypesSuccess,
  [InvoiceTypes.RESET_EXPORT_INVOICES]: resetExportInvoices,
  [InvoiceTypes.INVOICE_REQUEST_FAILURE]: invoiceRequestFailure,
  [InvoiceTypes.CLEAN_STATE]: cleanState,
  [InvoiceTypes.INVOICE_NEW_LIST]: invoiceNewList,
  [InvoiceTypes.NEW_LIST_CLIENT_CLEAN]: newListClientClean,
  [InvoiceTypes.INVOICE_LIST_ERROR_CLEAN]: invoiceListErrorClean,
});
