import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
} from "utils/constants/request";

export const { Types: NcmGlobalsTypes, Creators: NcmGlobalsActions } = createActions({
  getNcmGlobals: ["params"],
  getNcmGlobalsSuccess: ["payload"],
  getNcmGlobalsById: ["id"],
  getNcmGlobalsByIdSuccess: ["payload"],
  getUnitMeasure: ["params"],
  getUnitMeasureSuccess: ["payload"],
  createNcmGlobals: ["payload"],
  createNcmGlobalsSuccess: ["payload"],
  createNcmCategory: ["payload"],
  createNcmCategorySuccess: ["payload"],
  editNcmGlobals: ["id", "payload"],
  editNcmGlobalsSuccess: ["payload"],
  ncmGlobalsRequestFailure: ["error", "requestName"],
  cleanState: null,
});

const INITIAL_STATE = {
  ncmGlobalsDetails: null,
  ncmGlobals: {},
  unitMeasure: [],
  created: false,
  NcmGlobalsRequests: {
    getNcmGlobals: REQUEST_NOT_STARTED,
    getNcmGlobalsById: REQUEST_NOT_STARTED,
    getUnitMeasure: REQUEST_NOT_STARTED,
    createNcmGlobals: REQUEST_NOT_STARTED,
    createNcmCategory: REQUEST_NOT_STARTED,
    editNcmGlobals: REQUEST_NOT_STARTED,
  },
};

const getNcmGlobals = (state) => ({
  ...state,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    getNcmGlobals: REQUEST_PENDING,
  },
});

const getNcmGlobalsSuccess = (state, { payload }) => ({
  ...state,
  ncmGlobals: payload,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    getNcmGlobals: REQUEST_RESOLVED,
  },
});

const getNcmGlobalsById = (state) => ({
  ...state,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    getNcmGlobalsById: REQUEST_PENDING,
  },
});

const getNcmGlobalsByIdSuccess = (state, { payload }) => ({
  ...state,
  ncmGlobalsDetails: payload,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    getNcmGlobalsById: REQUEST_RESOLVED,
  },
});

const getUnitMeasure = (state) => ({
  ...state,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    getUnitMeasure: REQUEST_PENDING,
  },
});

const getUnitMeasureSuccess = (state, { payload }) => ({
  ...state,
  unitMeasure: payload,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    getUnitMeasure: REQUEST_RESOLVED,
  },
});

const createNcmGlobals = (state) => ({
  ...state,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    createNcmGlobals: REQUEST_PENDING,
  },
});

const createNcmGlobalsSuccess = (state) => ({
  ...state,
  created: true,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    createNcmGlobals: REQUEST_RESOLVED,
  },
});

const createNcmCategory = (state) => ({
  ...state,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    createNcmCategory: REQUEST_PENDING,
  },
});

const createNcmCategorySuccess = (state) => ({
  ...state,
  created: true,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    createNcmCategory: REQUEST_RESOLVED,
  },
});

const editNcmGlobals = (state) => ({
  ...state,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    editNcmGlobals: REQUEST_PENDING,
  },
});

const editNcmGlobalsSuccess = (state) => ({
  ...state,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    editNcmGlobals: REQUEST_RESOLVED,
  },
});

const ncmGlobalsRequestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  NcmGlobalsRequests: {
    ...state.NcmGlobalsRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [NcmGlobalsTypes.GET_NCM_GLOBALS]: getNcmGlobals,
  [NcmGlobalsTypes.GET_NCM_GLOBALS_SUCCESS]: getNcmGlobalsSuccess,
  [NcmGlobalsTypes.GET_NCM_GLOBALS_BY_ID]: getNcmGlobalsById,
  [NcmGlobalsTypes.GET_NCM_GLOBALS_BY_ID_SUCCESS]: getNcmGlobalsByIdSuccess,
  [NcmGlobalsTypes.GET_UNIT_MEASURE]: getUnitMeasure,
  [NcmGlobalsTypes.GET_UNIT_MEASURE_SUCCESS]: getUnitMeasureSuccess,
  [NcmGlobalsTypes.CREATE_NCM_GLOBALS]: createNcmGlobals,
  [NcmGlobalsTypes.CREATE_NCM_GLOBALS_SUCCESS]: createNcmGlobalsSuccess,
  [NcmGlobalsTypes.CREATE_NCM_CATEGORY]: createNcmCategory,
  [NcmGlobalsTypes.CREATE_NCM_CATEGORY_SUCCESS]: createNcmCategorySuccess,
  [NcmGlobalsTypes.EDIT_NCM_GLOBALS]: editNcmGlobals,
  [NcmGlobalsTypes.EDIT_NCM_GLOBALS_SUCCESS]: editNcmGlobalsSuccess,
  [NcmGlobalsTypes.NCM_GLOBALS_REQUEST_FAILURE]: ncmGlobalsRequestFailure,
  [NcmGlobalsTypes.CLEAN_STATE]: cleanState,
});
