{
  /* <p
  style="
          width: 95%;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #2f3a44;
          margin: 30px 0 0 10px;
        "
>
  ${greetings}
</p>;
 <p style="width: 95%; margin: 30px 100px 0 20px">
        <a
          href="${invoiceUrl}"
          style="
            font-size: 14px;
            line-height: 24px;
            text-decoration-line: underline;
            color: #0071ee;
          "
          target="_blank"
        >
          ${invoiceUrl}
        </a>
      </p>
      <p
        style="
          width: 95%;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #2f3a44;
          margin: 20px 100px 0 20px;
        "
      >
        Em caso de dúvida entre em contato com a OBVIA.
      </p>
            <p
        style="
          width: 95%;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #2f3a44;
          margin: 25px 100px 0 10px;
        "
      >
        Atenciosamente,<br />Equipe OBVIA.
      </p>
       */
}
export default ({ message = "", greetings = "", invoiceUrl = "", contactName = "" }) => {
  return `
  <!DOCTYPE html>
<html>
<body>
<div
  style="
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: 'Work Sans';
  "
>
  <div
    style="
      background-color: #0071ee;
      height: 90px;
      display: table;
      width: 100%;
    "
  >
    <div style="max-width: 600px !important; margin: 0 auto">
      <div style="display: table; margin: 20px 0 0 10px; width: 100%">
        <img
          src="https://appicenet-obvia-backend-storage.s3.amazonaws.com/image/1590000282509.png"
          style="height: 50px"
        />
      </div>
    </div>
  </div>

  <div style="display: table; width: 100%">
    <div style="max-width: 600px !important; margin: 0 auto">
      <p
        style="
          width: 95%;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #2f3a44;
          margin: 20px 100px 0 20px;
          white-space: pre-wrap;
          text-align: left;
        "
      >${message ?? ""}</p>
      <p
        style="
          width: 95%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #2f3a44;
          margin: 30px 0 0 20px;
        "
      >
        Este é um e-mail automático, por favor não responder.
      </p>
    </div>
    <div style="display: table; margin: 40px 0 25px 0; width: 100%">
      <div style="max-width: 600px !important; margin: 0 auto">
        <div style="display: block; float: left; margin: 0 30px 10px 10px">
          <img
            src="https://appicenet-obvia-backend-storage.s3.amazonaws.com/image/1590000783820.png"
            style="height: 40px"
          />
        </div>
        <div style="display: block; float: left; margin-left: 10px">
          <ul
            style="
              list-style: none;
              padding: 0;
              margin: 0;
              color: #0071ee;
              text-decoration: none;
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              line-height: 23px;
            "
          >
            <li>
              <a
                href="mailto:contato@obviacontabil.com"
                style="
                  color: #0071ee;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 20px;
                  line-height: 23px;
                  text-decoration: none;
                "
                target="_blank"
              >
                contato@obviacontabilidade.com.br
              </a>
            </li>
            <li>
              <a
                href="https://obviacontabilidade.com.br"
                style="
                  color: #0071ee;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 20px;
                  line-height: 23px;
                  text-decoration: none;
                "
                target="_blank"
              >
                obviacontabilidade.com.br
              </a>
            </li>
            <li>(31) 3274.1283 - Ramal 200</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</body>
</html>
`;
};
