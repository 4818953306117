import styled, { css } from "styled-components";

const dangerStyle = css`
  color: var(--color-danger);
  font-weight: 500;
`;

export const LargeTitle = styled.h1.attrs(({ mb, color }) => ({
  marginBottom: mb ?? "2.4rem",
  color: color ?? "var(--color-black-3)",
}))`
  font-size: var(--space-lg);
  color: ${({ color }) => color};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const Title = styled.h1`
  color: var(--color-black-3);
  font-size: var(--font-size-title);

  ${({ customStyle }) => customStyle}
`;

export const Subtitle = styled.h2.attrs(({ mb }) => ({
  marginBottom: mb ?? "2.4rem",
}))`
  color: var(--color-black-3);
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: ${({ marginBottom }) => marginBottom};

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TertiaryTitle = styled.h3.attrs(
  ({ fontWeight, textAlign, marginBottom, marginTop, color }) => ({
    fontWeight: fontWeight ?? "500",
    textAlign: textAlign ?? "left",
    marginBottom: marginBottom ?? "2.4rem",
    marginTop: marginTop ?? "0.5rem",
    color: color ?? "var(--color-black-3)",
  }),
)`
  color: ${({ color }) => color};
  font-size: 2rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Paragraph = styled.p.attrs(
  ({ fontWeight, textAlign, marginBottom, marginLeft, color, fontSize }) => ({
    fontWeight: fontWeight ?? "normal",
    textAlign: textAlign ?? "left",
    marginBottom: marginBottom ?? "1.6rem",
    marginLeft: marginLeft ?? "0",
    color: color ?? "var(--color-gray-3)",
    fontSize: fontSize ?? "1.8rem",
  }),
)`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ customStyle }) => customStyle}
`;

export const Span = styled.span.attrs(({ mb, color, textAlign }) => ({
  marginBottom: mb ?? "0",
  color: color ?? "var(--color-black-3)",
  textAlign: textAlign ?? "left",
}))`
  color: ${({ color }) => color};
  font-size: var(--font-size-default);
  margin-bottom: ${({ marginBottom }) => marginBottom};
  text-align: ${({ textAlign }) => textAlign};
  ${({ danger }) => danger && dangerStyle};
  ${({ customStyle }) => customStyle};
`;

export const Small = styled.span`
  color: var(--color-black-3);
  font-size: 1.4rem;

  ${({ customStyle }) => customStyle}
`;

export const Divider = styled.hr.attrs(({ marginBottom }) => ({
  marginBottom: marginBottom ?? "3.2rem",
}))`
  border: 1px solid var(--color-white-3);
  opacity: 0.5;

  &:not(:last-child) {
    margin-bottom: ${({ marginBottom }) => marginBottom};
  }
`;

export const PriceTag = styled.strong.attrs(({ fontSize }) => ({
  fontSize: fontSize ?? "24px",
}))`
  color: var(--color-blue-1);
  font-size: ${({ fontSize }) => fontSize};
`;

export const List = styled.ul`
  align-self: flex-start;
  color: var(--color-black-4);
  font-size: var(--font-size-default);
  list-style: none;
  margin-bottom: 3.2rem;
`;
