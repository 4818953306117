import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_PENDING,
} from "utils/constants/request";

export const { Types: UsersTypes, Creators: UsersActions } = createActions({
  getUsers: ["params"],
  getUsersSuccess: ["payload"],
  getUserById: ["id"],
  getUserByIdSuccess: ["payload"],
  createUser: ["payload"],
  createUserSuccess: ["payload"],
  editUser: ["id", "payload"],
  editUserSuccess: ["payload"],
  editUsersStatus: ["payload"],
  editUsersStatusSuccess: ["payload"],
  usersRequestFailure: ["error", "requestName"],
  cleanState: null,
});

const INITIAL_STATE = {
  userDetails: null,
  users: {},
  created: false,
  UsersRequests: {
    getUsers: REQUEST_NOT_STARTED,
    getUserById: REQUEST_NOT_STARTED,
    createUser: REQUEST_NOT_STARTED,
    editUser: REQUEST_NOT_STARTED,
    editUsersStatus: REQUEST_NOT_STARTED,
  },
};

const getUsers = (state) => ({
  ...state,
  UsersRequests: {
    ...state.UsersRequests,
    getUsers: REQUEST_PENDING,
  },
});

const getUsersSuccess = (state, { payload }) => ({
  ...state,
  users: payload,
  UsersRequests: {
    ...state.UsersRequests,
    getUsers: REQUEST_RESOLVED,
  },
});

const getUserById = (state) => ({
  ...state,
  UsersRequests: {
    ...state.UsersRequests,
    getUserById: REQUEST_PENDING,
  },
});

const getUserByIdSuccess = (state, { payload }) => ({
  ...state,
  userDetails: payload,
  UsersRequests: {
    ...state.UsersRequests,
    getUserById: REQUEST_RESOLVED,
  },
});

const createUser = (state) => ({
  ...state,
  UsersRequests: {
    ...state.UsersRequests,
    createUser: REQUEST_PENDING,
  },
});

const createUserSuccess = (state) => ({
  ...state,
  created: true,
  UsersRequests: {
    ...state.UsersRequests,
    createUser: REQUEST_RESOLVED,
  },
});

const editUser = (state) => ({
  ...state,
  UsersRequests: {
    ...state.UsersRequests,
    editUser: REQUEST_PENDING,
  },
});

const editUserSuccess = (state) => ({
  ...state,
  UsersRequests: {
    ...state.UsersRequests,
    editUser: REQUEST_RESOLVED,
  },
});

const editUsersStatus = (state) => ({ ...state, requestStatus: REQUEST_PENDING });

const editUsersStatusSuccess = (state) => ({
  ...state,
  UsersRequests: {
    ...state.UsersRequests,
    editUsersStatus: REQUEST_RESOLVED,
  },
});

const usersRequestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  UsersRequests: {
    ...state.UsersRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
  [UsersTypes.GET_USERS]: getUsers,
  [UsersTypes.GET_USERS_SUCCESS]: getUsersSuccess,
  [UsersTypes.GET_USER_BY_ID]: getUserById,
  [UsersTypes.GET_USER_BY_ID_SUCCESS]: getUserByIdSuccess,
  [UsersTypes.CREATE_USER]: createUser,
  [UsersTypes.CREATE_USER_SUCCESS]: createUserSuccess,
  [UsersTypes.EDIT_USER]: editUser,
  [UsersTypes.EDIT_USER_SUCCESS]: editUserSuccess,
  [UsersTypes.EDIT_USERS_STATUS]: editUsersStatus,
  [UsersTypes.EDIT_USERS_STATUS_SUCCESS]: editUsersStatusSuccess,
  [UsersTypes.USERS_REQUEST_FAILURE]: usersRequestFailure,
  [UsersTypes.CLEAN_STATE]: cleanState,
});
