import styled from "styled-components";
import { Label, Input as InputCheckbox, Checkbox } from "components/core/Checkbox/styled";

export const RadioButton = styled(Checkbox)`
  border-radius: 50%;

  &::after {
    background: none;
    background-color: var(--color-secondary);
    border-radius: 50%;
    height: 0.9rem;
    width: 0.9rem;
  }
`;

export const Input = styled(InputCheckbox)`
  &:checked + ${/* sc-sel */ Label} > ${/* sc-sel */ RadioButton} {
    background-color: var(--color-white-1);

    &::after {
      opacity: 1;
    }
  }
`;
