import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import GlobalSettingDetails from "components/presentation/Financial/GlobalSettings/Details";
import { LINKS } from "utils/constants";
import { useFinancial } from "hooks";

const GlobalSettingDetailsWithSpinner = WithSpinner(GlobalSettingDetails);

const GlobalSettingDetailsContainer = () => {
  const { getGlobalSetting, globalSetting, requestStatus } = useFinancial();
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const [plans, setPlans] = useState(null);
  const dispatchGetGlobalSetting = useCallback(() => getGlobalSetting(id), [id]);
  useEffect(() => {
    dispatchGetGlobalSetting();
  }, [dispatchGetGlobalSetting]);
  useEffect(() => {
    const plans = [];
    if (globalSetting?.allowedInstallmentsPlans) {
      if (globalSetting?.allowedInstallmentsPlans.includes(1)) {
        plans.push("Mensal");
      }
      if (globalSetting?.allowedInstallmentsPlans.includes(2)) {
        plans.push("Anual");
      }
      setPlans(plans.join(", "));
    }
  }, [globalSetting]);
  return (
    <>
      <NavBar title={translate("Global Installments Settings")} route={null}>
        <Button url={LINKS.financial.installments.root} variant="outline">
          {translate("back")}
        </Button>
        <Button url={LINKS.financial.installments.edit(id)}>{translate("edit")}</Button>
      </NavBar>
      <GlobalSettingDetailsWithSpinner
        requestStatus={requestStatus.GET_GLOBAL_SETTING}
        globalSetting={globalSetting}
        plans={plans}
        translate={translate}
      />
    </>
  );
};

export default GlobalSettingDetailsContainer;
