/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Subtitle } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import { ROUTES, PDFSURLS } from "utils/constants";
import Button from "components/core/Button";
import * as S from "../NewUserForm/styled";

const UseTerms = () => (
  <S.RegisterContainer large style={{ height: "800px" }}>
    <Flex verticalAlign="center" horizontalAlign="center">
      <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2rem 0">
        <S.StatusBar porcent="16%" />{" "}
      </Container>
      <Subtitle mb="48px">Termo de Consentimento</Subtitle>
      <object data={PDFSURLS.consentTerms} type="application/pdf" width="100%" height="100%" />
      <Row horizontalAlign="center">
        <Button style={{ marginTop: "15px" }} variant="outline" url={ROUTES.newUser.path}>
          Voltar
        </Button>
      </Row>
    </Flex>
  </S.RegisterContainer>
);

export default UseTerms;
