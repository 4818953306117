import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { GET } from "utils/constants/verbs";
import { SUBSCRIPTIONS } from "utils/constants/endpoints";
import { SubscriptionsTypes, SubscriptionsActions } from "store/ducks/subscriptions";

export function* fetchSubscriptions({ emitterId, params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: SUBSCRIPTIONS,
      params: { emitterId, ...params },
    });
    yield put(SubscriptionsActions.fetchSubscriptionsSuccess(data));
  } catch (error) {
    yield put(
      SubscriptionsActions.subscriptionsRequestFailure(
        error,
        SubscriptionsTypes.FETCH_SUBSCRIPTIONS,
      ),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(SubscriptionsTypes.FETCH_SUBSCRIPTIONS, fetchSubscriptions);
}
