import React from "react";
import { Switch, Route } from "react-router-dom";
import LandingContainer from "containers/LandingNewUser";
import { ROUTES } from "utils/constants";

const Landing = () => (
  <Switch>
    <Route exact path={ROUTES.landingNewUser.path} component={LandingContainer} />
  </Switch>
);

export default Landing;
