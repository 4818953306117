import { put, takeLatest, cancelled } from "redux-saga/effects";
import axios from "axios";
import api from "services/api";
import { ServicesTypes, ServicesActions } from "store/ducks/services";
import {
  GET,
  PATCH,
  DELETE,
  SERVICE_BY_ID,
  SERVICES,
  SERVICES_LOCATIONS,
  SERVICES_AVAILABLE,
} from "utils/constants";

const { CancelToken } = axios;

function* fetchServicesDetails({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: SERVICE_BY_ID(id),
      });
      yield put(ServicesActions.fetchServicesDetailsSuccess(data));
    }
  } catch (error) {
    yield put(ServicesActions.servicesRequestFailure(error, type));
  }
}

function* fetchServicesLocations({ type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: SERVICES_LOCATIONS,
    });
    yield put(ServicesActions.fetchServicesLocationsSuccess(data));
  } catch (error) {
    yield put(ServicesActions.servicesRequestFailure(error, type));
  }
}

function* fetchAvailableServices({ params, type }) {
  const source = CancelToken.source();

  try {
    const { data } = yield api({
      method: GET,
      url: SERVICES_AVAILABLE,
      params,
      cancelToken: source.token,
    });
    yield put(ServicesActions.fetchAvailableServicesSuccess(data));
  } catch (error) {
    yield put(ServicesActions.servicesRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

function* editServices({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PATCH,
        url: SERVICE_BY_ID(id),
        data: payload,
      });
      yield put(ServicesActions.editServicesSuccess(data));
    }
  } catch (error) {
    yield put(ServicesActions.servicesRequestFailure(error, type));
  }
}

function* deleteServices({ payload, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: SERVICES,
      data: payload,
    });
    yield put(ServicesActions.deleteServicesSuccess(data));
  } catch (error) {
    yield put(ServicesActions.servicesRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ServicesTypes.FETCH_SERVICES_DETAILS, fetchServicesDetails);
  yield takeLatest(ServicesTypes.FETCH_SERVICES_LOCATIONS, fetchServicesLocations);
  yield takeLatest(ServicesTypes.FETCH_AVAILABLE_SERVICES, fetchAvailableServices);
  yield takeLatest(ServicesTypes.EDIT_SERVICES, editServices);
  yield takeLatest(ServicesTypes.DELETE_SERVICES, deleteServices);
}
