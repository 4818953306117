import { put, takeLatest } from "redux-saga/effects";
import api from "services/api";
import { GET } from "utils/constants/verbs";
import { ROLES } from "utils/constants/endpoints";
import { Types as RolesTypes, Creators as RolesActions } from "store/ducks/roles";

export function* getRoles() {
  try {
    const { data } = yield api({
      method: GET,
      url: ROLES,
    });
    yield put(RolesActions.getRolesSuccess(data));
  } catch (error) {
    yield put(RolesActions.getRolesError(error));
  }
}

export function* watchSagas() {
  yield takeLatest(RolesTypes.GET_ROLES_START, getRoles);
}
