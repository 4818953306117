import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ROUTES } from "utils/constants";
import Home from "containers/Home";
import Batches from "containers/Batches/List";
import BatchesConfirm from "containers/Batches/Confirm";
import BatchesConfirmEditEnterprise from "containers/Batches/Confirm/EditEnterprise";
import BatchesCompany from "containers/Batches/Company";
import BatchesDetails from "containers/Batches/Details";
import DocumentsReceivedList from "containers/DocumentsReceived/List";
import DocumentsSentList from "containers/DocumentsSent/List";
import ClientsList from "containers/Client/List";
import ClientDetails from "containers/Client/Details";
import InvoiceDetails from "containers/Invoice/Details";
import InvoiceList from "containers/Invoice/List";
import InvoiceRPS from "containers/Invoice/RPS";
import DigitalAuthorization from "containers/DigitalAuthorization";
import EmittersDetails from "containers/Emitter/Details";

const PrivateBatches = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route exact path={ROUTES.batches.path} component={Batches} />
      <Route exact path={ROUTES.batches.children.confirm.path} component={BatchesConfirm} />
      <Route
        exact
        path={ROUTES.batches.children.confirm.children.editEnterprise.path}
        component={BatchesConfirmEditEnterprise}
      />
      <Route exact path={ROUTES.batches.children.company.path} component={BatchesCompany} />
      <Route exact path={ROUTES.batches.children.details.path} component={BatchesDetails} />
      <Route
        exact
        path={ROUTES.documents.children.received.path}
        component={DocumentsReceivedList}
      />
      <Route exact path={ROUTES.documents.children.sent.path} component={DocumentsSentList} />

      <Route exact path={ROUTES.clients.path} component={ClientsList} />
      <Route exact path={ROUTES.clients.children.details.path} component={ClientDetails} />

      <Route exact path={ROUTES.invoices.path} component={InvoiceList} />
      <Route exact path={ROUTES.invoices.children.details.path} component={InvoiceDetails} />
      <Route exact path={ROUTES.invoices.children.rps.path} component={InvoiceRPS} />
      <Route exact path={ROUTES.digitalAuthorization.path} component={DigitalAuthorization} />

      <Route exact path={ROUTES.emitters.children.details.path} component={EmittersDetails} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default PrivateBatches;
