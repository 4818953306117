import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LayoutActions } from "store/ducks/layout";
import { Subtitle, Span } from "components/core/Typography";
import { ROUTES, LINKS } from "utils/constants";
import AccordionMenu from "components/core/AccordionMenu";
import * as S from "./styled";

const Menu = ({
  menu: { path, i18nKey, Icon, municipalServicesMenu },
  handleClick,
  emitterId,
  userId,
  serviceItem,
  ...others
}) => {
  const { t: translate } = useTranslation();

  if (!path) return null;
  if (municipalServicesMenu && serviceItem === false) return null;

  const correctPath = path
    .replace(":emitterId", emitterId)
    .replace(":userId", userId)
    .replace("/:documentId", "")
    .replace(":id", "");

  return (
    <S.CustomMenu to={correctPath} key={path} onClick={handleClick} {...others}>
      {Icon && <Icon />}
      {translate(i18nKey)}
    </S.CustomMenu>
  );
};

const EmitterSidebar = ({
  isOpen,
  emitter: { id, enterpriseName, cnpj, user, enterpriseInvoiceType },
}) => {
  const {
    user: { roles: rolesPermission },
  } = useSelector((state) => state.auth.claims);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(LayoutActions.toggleEmitterMenu(!isOpen));
  const [productItem, setProductItem] = useState(false);
  const [serviceItem, setServiceItem] = useState(false);
  const emitterMenuKeys = Object.keys(ROUTES).filter(
    (key) => ROUTES[key].emitterMenu && !ROUTES[key].adminMenu,
  );
  const accordionMenuKeys = Object.keys(ROUTES).filter(
    (key) => ROUTES[key].accordionMenu && !ROUTES[key].adminMenu,
  );

  const clientMenuKeys = Object.keys(ROUTES).filter(
    (key) => ROUTES[key].clientMenu && !ROUTES[key].adminMenu,
  );

  useEffect(() => {
    const hasInvoiceTypeById = (value) => {
      const values = enterpriseInvoiceType?.some(
        (obj) =>
          Object.prototype.hasOwnProperty.call(obj, "idInvoiceType") && obj.idInvoiceType === value,
      );
      return values;
    };
    setProductItem(hasInvoiceTypeById(2) || hasInvoiceTypeById(3));
    setServiceItem(hasInvoiceTypeById(1));
  }, [enterpriseInvoiceType]);

  return (
    <S.SidebarContainer isOpen={isOpen}>
      <S.Sidebar>
        <S.Header>
          <Link to={LINKS.emitterDetails(id)} onClick={handleClick}>
            <Subtitle mb="0" data-testid="enterpriseName">
              {enterpriseName}
            </Subtitle>
          </Link>
          <Span data-testid="cnpj">{cnpj}</Span>
        </S.Header>
        {rolesPermission === "client" ? (
          <S.ItemsWrapper>
            {accordionMenuKeys.map(
              (key) =>
                !ROUTES[key].productMenu && (
                  <AccordionMenu key={key} i18nKey={ROUTES[key].i18nKey} Icon={ROUTES[key].Icon}>
                    {Object.keys(ROUTES[key].children).map((childrenKey) => {
                      return (
                        <>
                          {ROUTES[key].children[childrenKey].clientMenu && (
                            <Menu
                              key={childrenKey}
                              menu={ROUTES[key].children[childrenKey]}
                              handleClick={handleClick}
                              emitterId={id}
                              userId={user?.id}
                              serviceItem={serviceItem}
                              style={{ marginLeft: "1rem" }}
                            />
                          )}
                        </>
                      );
                    })}
                  </AccordionMenu>
                ),
            )}
            {clientMenuKeys.map((key) =>
              ROUTES[key].path ? (
                <Menu
                  key={key}
                  menu={ROUTES[key]}
                  handleClick={handleClick}
                  emitterId={id}
                  userId={user?.id}
                />
              ) : (
                Object.keys(ROUTES[key].children)?.map((childrenKey) => (
                  <Menu
                    key={childrenKey}
                    menu={ROUTES[key].children[childrenKey]}
                    handleClick={handleClick}
                    emitterId={id}
                    userId={user?.id}
                  />
                ))
              ),
            )}
          </S.ItemsWrapper>
        ) : (
          <S.ItemsWrapper>
            {accordionMenuKeys.map((key) =>
              !ROUTES[key].productMenu ? (
                <AccordionMenu key={key} i18nKey={ROUTES[key].i18nKey} Icon={ROUTES[key].Icon}>
                  {Object.keys(ROUTES[key].children).map((childrenKey) => {
                    return (
                      <>
                        {!ROUTES[key].children[childrenKey].adminMenu && (
                          <Menu
                            key={childrenKey}
                            menu={ROUTES[key].children[childrenKey]}
                            handleClick={handleClick}
                            emitterId={id}
                            userId={user?.id}
                            serviceItem={serviceItem}
                            style={{ marginLeft: "1rem" }}
                          />
                        )}
                      </>
                    );
                  })}
                </AccordionMenu>
              ) : (
                productItem && (
                  <AccordionMenu key={key} i18nKey={ROUTES[key].i18nKey} Icon={ROUTES[key].Icon}>
                    {Object.keys(ROUTES[key].children).map((childrenKey) => (
                      <Menu
                        key={childrenKey}
                        menu={ROUTES[key].children[childrenKey]}
                        handleClick={handleClick}
                        emitterId={id}
                        userId={user?.id}
                      />
                    ))}
                  </AccordionMenu>
                )
              ),
            )}

            {emitterMenuKeys.map((key) =>
              ROUTES[key].path ? (
                <Menu
                  key={key}
                  menu={ROUTES[key]}
                  handleClick={handleClick}
                  emitterId={id}
                  userId={user?.id}
                />
              ) : (
                Object.keys(ROUTES[key].children)?.map((childrenKey) => (
                  <Menu
                    key={childrenKey}
                    menu={ROUTES[key].children[childrenKey]}
                    handleClick={handleClick}
                    emitterId={id}
                    userId={user?.id}
                  />
                ))
              ),
            )}
          </S.ItemsWrapper>
        )}
      </S.Sidebar>
    </S.SidebarContainer>
  );
};

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  emitterId: PropTypes.number,
  userId: PropTypes.number,
  serviceItem: PropTypes.bool,
};

Menu.defaultProps = {
  serviceItem: true,
};

EmitterSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  emitter: PropTypes.object.isRequired,
};

export default EmitterSidebar;
