import React, { useEffect, lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams as useRouterParams } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import { ProductsActions } from "store/ducks/products";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import TableFactory from "components/core/TableWithIcons/TableFactory";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import { useItems, useParams, useRequest } from "hooks";
import { LINKS } from "utils/constants";

const SMUSelect = lazy(() => import("containers/Products/Register"));
const DeleteProductsConfirm = lazy(() => import("components/presentation/Documents/Delete"));

const TableFactoryWithSpinner = WithSpinner(TableFactory);
const SMUSelectWithSuspense = WithSuspense(SMUSelect);
const DeleteProductsConfirmWithSuspense = WithSuspense(DeleteProductsConfirm);
const DeleteProductConfirmWithSuspense = WithSuspense(DeleteProductsConfirm);

const ProductsList = () => {
  const dispatch = useDispatch();
  const { emitterId } = useRouterParams();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showSingleDeleteConfirm, setShowSingleDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const {
    emitterProducts: { items, total, totalPages },
    statusOfRequests,
  } = useSelector(({ emitter }) => emitter);
  const { ProductsRequests } = useSelector(({ products }) => products);

  const {
    everyoneIsChecked,
    handleChange,
    toggleAll,
    listChecked: itemsChecked,
    selectedItems,
  } = useItems(items);

  const fetchProducts = (params) => {
    dispatch(EmitterActions.fetchEmitterProducts(emitterId, params));
  };

  const {
    params,
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
  } = useParams({
    callback: fetchProducts,
    defaultOrderBy: "createdAt",
    defaultOrder: "desc",
  });

  const selectItems = () => selectedItems?.map(({ id }) => id);

  const singleDelete = (item) => {
    setItemToDelete([item.id]);
  };

  useEffect(() => {
    if (itemToDelete !== null) {
      setShowSingleDeleteConfirm(true);
    }
  }, [itemToDelete]);

  const dispatchDelete = () => {
    if (selectedItems.length) {
      dispatch(ProductsActions.deleteProducts({ ids: selectItems() }));
      setPage(1);
    } else {
      dispatch(ProductsActions.deleteProducts({ ids: itemToDelete }));
      setPage(1);
    }
    setShowSingleDeleteConfirm(false);
  };

  useRequest({
    request: ProductsRequests.DELETE_PRODUCTS,
    onResolved: () => fetchProducts(params),
  });
  return (
    <>
      <NavBar title={translate("productsList")}>
        <Button
          variant="outline"
          color="danger"
          handleClick={() => setShowDeleteConfirm(true)}
          disabled={selectedItems.length < 2}
        >
          {translate("delete")}
        </Button>

        <Button url={LINKS.productsRegister(emitterId)}>
          <span>{translate("addProducts")}</span>
        </Button>
      </NavBar>
      <TableFactoryWithSpinner
        requestStatus={statusOfRequests.FETCH_EMITTER_PRODUCTS}
        headers={[
          { slug: "code", i18nKey: "code" },
          { slug: "name", i18nKey: "productDescription" },
          { slug: "ncm", i18nKey: "ncm" },
          { slug: "csosn", i18nKey: "csosn" },
        ]}
        allowedKeys={["code", "name", "ncm.code", "csosn.code"]}
        items={itemsChecked}
        total={total}
        totalPages={totalPages}
        orderBy={orderBy}
        order={order}
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        everyoneIsChecked={everyoneIsChecked}
        handleChange={handleChange}
        toggleAll={toggleAll}
        urlDetails={(id) => LINKS.productsDetails(emitterId, id)}
        handleClickDelete={(id) => singleDelete(id)}
        handleClickEdit={(id) => LINKS.productsEdit(emitterId, id)}
      />
      <SMUSelectWithSuspense
        loadComponent={showModal}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <DeleteProductsConfirmWithSuspense
        titleTranslateKey="deleteProducts"
        messageTranslateKey="deleteProductsConfirm"
        loadComponent={showDeleteConfirm}
        showConfirm={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={dispatchDelete}
      />
      <DeleteProductConfirmWithSuspense
        titleTranslateKey="deleteProducts"
        messageTranslateKey="deleteProductsConfirm"
        loadComponent={showSingleDeleteConfirm}
        showConfirm={showSingleDeleteConfirm}
        onCancel={() => setShowSingleDeleteConfirm(false)}
        onConfirm={dispatchDelete}
      />
    </>
  );
};

export default ProductsList;
