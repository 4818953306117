import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  RenderHeaders,
  Table,
  TableHeader,
  TableBody,
  TableItem,
  TableCheckbox,
  TableHeaderItem,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import { MASK_TYPES, format } from "utils/masks";
import * as S from "./../styled";
import { Span } from "components/core/Typography";

const List = ({
  items,
  total,
  totalPages,
  params,
  page,
  perPage,
  orderBy,
  order,
  translate,
  toggleAll,
  everyoneIsChecked,
  handleChange,
  handleEditItem,
  confirmDeleteItem,
}) => {
  const headers = [
    { slug: "description", title: translate("description") },
    { slug: "recurrent", title: `${translate("Recurrent")}?`, textAlign: "center" },
    { slug: "billingFrom", title: `${translate("At/Billing from")}`, textAlign: "center" },
    { slug: "price", title: translate("Value"), textAlign: "center" },
    { slug: "quantity", title: translate("Quantity"), textAlign: "center" },
    { slug: "total", title: `${translate("Total")}`, textAlign: "center" },
    { slug: "actions", title: `${translate("Actions")}?` },
  ];
  const colPadding = "0.75rem";

  const getRealMask = (type) =>
    type === "D" ? MASK_TYPES.negativeRealWithName : MASK_TYPES.realWithName;

  const getStyles = (type) => {
    if (type === "C") {
      return { color: "blue" };
    }
    if (type === "D") {
      return { color: "red" };
    }
  };

  const formatBillingFrom = (value) => {
    if (value) {
      return moment(value, "YYYY-MM-DD").format("MM/YYYY");
    }
    return "-";
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox
              name="toggleAllItems"
              handleChange={toggleAll}
              checked={everyoneIsChecked}
            />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(
            ({
              id,
              description,
              quantity,
              recurrent,
              price,
              discountType,
              discountValue,
              type,
              billingFrom,
              checked,
            }) => (
              <tr key={id}>
                <TableItem padding={colPadding}>
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => handleChange(id)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem padding={colPadding}>{description}</TableItem>
                <TableItem padding={colPadding} textAlign="center">
                  {recurrent ? "Sim" : "Não"}
                </TableItem>
                <TableItem padding={colPadding} textAlign="center">
                  {formatBillingFrom(billingFrom)}
                </TableItem>
                <TableItem padding={colPadding} textAlign="right">
                  <Span style={getStyles(type)} count={total}>
                    {discountType === "P"
                      ? format(discountValue, MASK_TYPES.negativePercentageWithSymbol)
                      : format(Number(price).toFixed(2), getRealMask(type))}
                  </Span>
                </TableItem>
                <TableItem padding={colPadding} textAlign="center">
                  <Span>{quantity}</Span>
                </TableItem>
                <TableItem padding={colPadding} textAlign="right">
                  <Span style={getStyles(type)}>
                    {discountType === "P"
                      ? format(discountValue, MASK_TYPES.negativePercentageWithSymbol)
                      : format(Number(Number(price) * quantity).toFixed(2), getRealMask(type))}
                  </Span>
                </TableItem>
                <TableItem padding={colPadding}>
                  <S.IconEdit
                    title="Editar item"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditItem(id)}
                  />
                  <S.IconDelete
                    style={{ cursor: "pointer" }}
                    onClick={() => confirmDeleteItem(id)}
                    title="Remover item"
                  />
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={() => {}}
      />
    </>
  );
};

List.propTypes = {
  items: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  feedbackTextOnly: PropTypes.bool,
};

List.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 5,
};

export default WithEmptyFeedback(List);
