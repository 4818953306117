import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";
import SuspenseFallback from "components/core/SuspenseFallback";
import { StateMachineProvider, createStore } from "little-state-machine";
import App from "./App";
import "./i18n";

createStore({
  companyRegister: {
    name: "",
    phoneNumber: "",
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<SuspenseFallback />} persistor={persistor}>
      <StateMachineProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StateMachineProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);
