import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import Button from "components/core/Button";
import { useDimensions } from "hooks";
import { useHistory } from "react-router-dom";
import * as S from "./styled";

const Landing = ({ routes: Routes }) => {
  window.dataLayer.push({
    event: "register-landing",
  });
  const { width } = useDimensions();
  const history = useHistory();

  const handleClick = () => {
    history.push("/cadastro");
    history.go(0);
  };
  return (
    <S.Container>
      <S.Header>
        <S.LogoBox>
          <Logo />
        </S.LogoBox>
        {width > 1024 && (
          <Button handleClick={handleClick} variant="outline-white">
            Cadastre-se
          </Button>
        )}
      </S.Header>
      <S.LandingWrapper>
        <Routes />
      </S.LandingWrapper>
    </S.Container>
  );
};

Landing.propTypes = {
  routes: PropTypes.func.isRequired,
};

export default Landing;
