import { put, takeLatest } from "redux-saga/effects";
import { EmailTypes, EmailActions } from "store/ducks/emails";
import api from "services/api";
import { POST } from "utils/constants/verbs";
import { SEND_REGISTER_EMAIL } from "utils/constants/endpoints";

export function* sendEmail(payload) {
  try {
    const { data } = yield api({
      method: POST,
      url: SEND_REGISTER_EMAIL,
      data: payload,
    });
    yield put(EmailActions.sendEmailSuccess(data));
  } catch (error) {
    yield put(EmailActions.sendEmailFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(EmailTypes.SEND_EMAIL, sendEmail);
}
