import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputForm from "components/core/InputForm";
import { Subtitle } from "components/core/Typography";
import { Container } from "components/core/Grid";
import { Form, InputHidden } from "components/core/Form";
import Autocomplete from "components/core/Autocomplete";

const ProductRegister = ({
  handleConfirm,
  submitRef,
  handleSearchNcm,
  ncmItems,
  isLoadingNcmItems,
  handleSearchCst,
  cstItems,
  isLoadingCstItems,
  validationSchema,
  unitMeasure,
  handleSearchUnitMeasure,
  isLoadingUnitMeasureItems,
  lastItemRefNCM,
  lastItemRefCTS,
  lastItemRefMeasure,
}) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors, setValue } = useForm({ validationSchema });

  const handleChangeNcm = (item) => {
    setValue("idNcm", item.id);
  };

  const handleChangeCst = (item) => {
    setValue("idCsosn", item.id);
  };
  const handleChangeUnitMeasure = (item) => {
    setValue("idUnitMeasure", item.id);
  };

  return (
    <Container maxWidth="md">
      <Subtitle>{translate("addInformations")}</Subtitle>
      <Form onSubmit={handleSubmit(handleConfirm)}>
        <InputForm
          register={register}
          type="number"
          name="code"
          label="Código do produto"
          errorMsg={errors.code?.message}
        />
        <InputForm
          register={register}
          type="text"
          name="name"
          label="Descrição"
          errorMsg={errors.name?.message}
        />
        <InputHidden ref={submitRef} type="submit" value="Submit" />

        <Autocomplete
          register={register}
          type="text"
          label={translate("unitMeasure")}
          nameValue="unitMeasure"
          handleSearch={handleSearchUnitMeasure}
          handleChange={handleChangeUnitMeasure}
          isLoadingItems={isLoadingUnitMeasureItems}
          items={unitMeasure}
          setValue={setValue}
          errorMsg={errors.unitMeasure?.message}
          lastItemRef={lastItemRefMeasure}
        />
        <InputHidden ref={register} name="idUnitMeasure" />

        <Autocomplete
          register={register}
          type="text"
          label={translate("ncm")}
          nameValue="ncm"
          handleSearch={handleSearchNcm}
          handleChange={handleChangeNcm}
          items={ncmItems}
          isLoadingItems={isLoadingNcmItems}
          setValue={setValue}
          errorMsg={errors.ncmName?.message}
          lastItemRef={lastItemRefNCM}
        />
        <InputHidden ref={register} name="idNcm" />
        <Autocomplete
          register={register}
          type="text"
          label={translate("csosn")}
          nameValue="csosn"
          handleSearch={handleSearchCst}
          handleChange={handleChangeCst}
          items={cstItems}
          isLoadingItems={isLoadingCstItems}
          setValue={setValue}
          errorMsg={errors.csosName?.message}
          lastItemRef={lastItemRefCTS}
        />
        <InputHidden ref={register} name="idCsosn" />
      </Form>
    </Container>
  );
};

ProductRegister.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  handleSearchNcm: PropTypes.func.isRequired,
  ncmItems: PropTypes.array,
  isLoadingNcmItems: PropTypes.bool,
  handleSearchCst: PropTypes.func.isRequired,
  cstItems: PropTypes.array,
  isLoadingCstItems: PropTypes.bool,
  validationSchema: PropTypes.object.isRequired,
  unitMeasure: PropTypes.array,
  handleSearchUnitMeasure: PropTypes.func.isRequired,
  isLoadingUnitMeasureItems: PropTypes.bool,
  lastItemRefNCM: PropTypes.object,
  lastItemRefCTS: PropTypes.object,
  lastItemRefMeasure: PropTypes.object,
};

ProductRegister.defaultProps = {
  ncmItems: [],
  cstItems: [],
  isLoadingNcmItems: true,
  isLoadingCstItems: true,
  isLoadingUnitMeasureItems: true,
  unitMeasure: [],
  lastItemRefNCM: null,
  lastItemRefCTS: null,
  lastItemRefMeasure: null,
};

export default ProductRegister;
