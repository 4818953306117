import React, { lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams as useRouterParams } from "react-router-dom";
import { EmitterActions } from "store/ducks/emitter";
import { CfopActions } from "store/ducks/cfop";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import CfopTableList from "components/presentation/Cfop/List";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import { useItems, useParams, useRequest } from "hooks";
import { formatDate } from "helpers";

const SMUSelect = lazy(() => import("containers/Cfop/Register"));
const Filter = React.lazy(() => import("containers/Cfop/Filter"));
const DeleteCfopConfirm = lazy(() => import("components/presentation/Documents/Delete"));

const CfopTableListWithSpinner = WithSpinner(CfopTableList);

const SMUSelectWithSuspense = WithSuspense(SMUSelect);
const FilterWithSuspense = WithSuspense(Filter);
const DeleteCfopConfirmWithSuspense = WithSuspense(DeleteCfopConfirm);

const CfopList = () => {
  const dispatch = useDispatch();
  const { emitterId } = useRouterParams();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const {
    emitterCfop: { items, total, totalPages },
    statusOfRequests,
  } = useSelector(({ emitter }) => emitter);
  const { CfopRequests } = useSelector(({ cfop }) => cfop);

  const {
    handleChange,
    toggleAll,
    listChecked: itemsChecked,
    selectedItems,
    hasOneItemSelected,
    hasSelectedItems,
  } = useItems(items);

  const fetchCfop = (newParams) => {
    dispatch(EmitterActions.fetchEmitterCfop(emitterId, { ...newParams }));
  };

  const {
    params,
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchCfop,
    // eslint-disable-next-line
    defaultOrderBy: "createdAt",
    defaultOrder: "desc",
  });

  const selectItems = () => selectedItems?.map(({ id }) => id);

  const dispatchDelete = () => {
    dispatch(CfopActions.deleteCfop({ ids: selectItems() }));
    setPage(1);
    setShowDeleteConfirm(false);
  };

  useRequest({
    request: CfopRequests.DELETE_CFOP,
    onResolved: () => fetchCfop(params),
  });

  const newItemsChecked = [];

  itemsChecked &&
    itemsChecked.map((item) => {
      newItemsChecked.push({ ...item, createdAt: formatDate(item.createdAt) });
      return item;
    });

  return (
    <>
      <NavBar title={translate("cfop")}>
        <Button
          variant="outline"
          color="danger"
          handleClick={() => setShowDeleteConfirm(true)}
          disabled={!hasSelectedItems}
        >
          {translate("delete")}
        </Button>
        {!hasOneItemSelected && !hasSelectedItems && (
          <SmartFilterButton handleShowFilter={() => setShowFilters(true)} />
        )}
        <Button handleClick={() => setShowModal(true)}>
          <span>{translate("addCfop")}</span>
        </Button>
      </NavBar>
      <CfopTableListWithSpinner
        requestStatus={statusOfRequests.FETCH_EMITTER_CFOP}
        handleChange={handleChange}
        items={newItemsChecked}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPerPage,
          setPage,
          setOrderBy,
          setOrder,
        }}
        orderBy={orderBy}
        order={order}
        toggleAll={toggleAll}
        checkedAll={selectedItems?.length === itemsChecked?.length}
      />
      <SMUSelectWithSuspense
        loadComponent={showModal}
        showModal={showModal}
        setShowModal={setShowModal}
        setPageList={setPage}
      />
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
        setShowModal={setShowFilters}
      />
      <DeleteCfopConfirmWithSuspense
        loadComponent={showDeleteConfirm}
        showConfirm={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={dispatchDelete}
        titleTranslateKey="deleteCfop"
        messageTranslateKey="deleteCfopConfirm"
      />
    </>
  );
};

export default CfopList;
