import { put, takeLatest, call } from "redux-saga/effects";
import api from "services/api";
import { GET, POST, PUT } from "utils/constants/verbs";
import { NCM, NCM_GLOBALS, NCM_GLOBALS_BY_ID, UNIT_MEASURE } from "utils/constants/endpoints";
import { NcmGlobalsTypes, NcmGlobalsActions } from "store/ducks/ncmGlobals";

export function* ncmGlobalsRequestFailure(error, requestName) {
  yield put(NcmGlobalsActions.ncmGlobalsRequestFailure(error, requestName));
}

export function* getNcmGlobals({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: NCM_GLOBALS,
      params,
    });
    yield put(NcmGlobalsActions.getNcmGlobalsSuccess(data));
  } catch (error) {
    yield call(ncmGlobalsRequestFailure, error, "getNcmGlobals");
  }
}

export function* getNcmGlobalsById({ id }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: NCM_GLOBALS_BY_ID(id),
      });
      yield put(NcmGlobalsActions.getNcmGlobalsByIdSuccess(data));
    }
  } catch (error) {
    yield call(ncmGlobalsRequestFailure, error, "getNcmGlobalsById");
  }
}

export function* getUnitMeasure({ params }) {
  try {
    const { data } = yield api({
      method: GET,
      url: UNIT_MEASURE,
      params,
    });
    yield put(NcmGlobalsActions.getUnitMeasureSuccess(data));
  } catch (error) {
    yield put(NcmGlobalsActions.ncmGlobalsRequestFailure(error, "getUnitMeasure"));
  }
}

export function* createNcmGlobals({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: NCM_GLOBALS,
      data: payload,
    });
    yield put(NcmGlobalsActions.createNcmGlobalsSuccess(data));
  } catch (error) {
    yield call(ncmGlobalsRequestFailure, error, "createNcmGlobals");
  }
}

export function* createNcmCategory({ payload }) {
  try {
    const { data } = yield api({
      method: POST,
      url: NCM,
      data: payload,
    });
    yield put(NcmGlobalsActions.createNcmCategorySuccess(data));
  } catch (error) {
    yield call(ncmGlobalsRequestFailure, error, "createNcmCategory");
  }
}

export function* editNcmGlobals({ id, payload }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PUT,
        url: NCM_GLOBALS_BY_ID(id),
        data: payload,
      });

      yield put(NcmGlobalsActions.editNcmGlobalsSuccess(data));
    }
  } catch (error) {
    yield call(ncmGlobalsRequestFailure, error, "editNcmGlobals");
  }
}

export function* watchSagas() {
  yield takeLatest(NcmGlobalsTypes.GET_NCM_GLOBALS, getNcmGlobals);
  yield takeLatest(NcmGlobalsTypes.GET_NCM_GLOBALS_BY_ID, getNcmGlobalsById);
  yield takeLatest(NcmGlobalsTypes.GET_UNIT_MEASURE, getUnitMeasure);
  yield takeLatest(NcmGlobalsTypes.CREATE_NCM_GLOBALS, createNcmGlobals);
  yield takeLatest(NcmGlobalsTypes.CREATE_NCM_CATEGORY, createNcmCategory);
  yield takeLatest(NcmGlobalsTypes.EDIT_NCM_GLOBALS, editNcmGlobals);
}
