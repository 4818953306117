import React from "react";
import * as Yup from "yup";
import { Trans } from "react-i18next";

const billingSchema = Yup.object().shape({
  billing: Yup.string().when("idBillingRange", {
    is: "0",
    then: Yup.string().nullable(),
    otherwise: Yup.string().required(<Trans i18nKey="billingRequired" />),
  }),
});

export { billingSchema };
