import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  addSelectedItems: ["id"],
  removeSelectedItems: ["id"],
  cleanState: [],
});

const INITIAL_STATE = [];

const addItem = (state, action) => [...state, { id: action.id }];

const removeItem = (state, action) => state.filter((item) => item.id !== action.id);

const cleanState = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [Types.ADD_SELECTED_ITEMS]: addItem,
  [Types.REMOVE_SELECTED_ITEMS]: removeItem,
  [Types.CLEAN_STATE]: cleanState,
});
