import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as ArrowSVG } from "assets/icons/icon-chrevon-white.svg";
import { Span } from "components/core/Typography";

export const Arrow = styled(ArrowSVG)`
  transform-origin: center;
  transition: transform 0.3s linear;
  height: 1.3rem !important;
  width: auto !important;
  fill: none !important;
  margin: 0 1rem !important;
`;

const openStyle = css`
  background-color: var(--color-white-1);
  color: var(--color-gray-3);

  ${Arrow} {
    transform: rotate(180deg);
    stroke: currentColor;
  }
`;

const withArrowStyle = css`
  border-radius: 3.2rem 0 0 3.2rem;
  position: relative;
  transition: all 0.3s linear;
  width: calc(100% + 1rem);
  min-height: 4.8rem;
  height: auto;

  & > svg {
    fill: currentColor;
  }

  ${Arrow} {
    stroke: currentColor;
  }

  ${({ isOpen }) => isOpen && openStyle}
`;

export const withNumberStyle = (count) => css`
  position: relative;

  &::after {
    background-color: var(--color-danger);
    border-radius: 3.2rem;
    content: "${count}";
    color: var(--color-white-1);
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4;
    display: flex;
    justify-content: center;
    align-self: center;
    top: 0.8rem;
    left: 2.7rem;
    position: absolute;
    height: 1.6rem;
    min-width: 1.6rem;
    padding: 0 0.4rem;
  }
`;

export const Navigation = styled.nav`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 1rem;
  width: 100%;
  max-width: var(--sidebar-width);
  position: fixed;
  top: 0;
  left: 0;

  ${({ changeLayout }) =>
    changeLayout &&
    css`
      padding: 4rem 2rem;
    `}
`;

export const MenuItem = styled(NavLink)`
  color: var(--color-white-1);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: var(--font-size-default);
  font-weight: 600;
  height: 4.8rem;
  width: 100%;
  padding: 0 1.2rem;
  outline: none;

  &:not(:last-child) {
    margin-bottom: 1.4rem;
  }

  & > svg {
    height: 3.2rem;
    width: 3.2rem;
    min-width: 3.2rem;
    margin-right: 1.5rem;

    ${({ changeLayout }) =>
      changeLayout &&
      css`
        height: 22px;
        width: 19px;
        margin-right: 12px;
        min-width: auto;
      `}
  }

  & > span {
    flex: 1 1 90%;
    text-align: left;
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.05);
    position: relative;

    ${({ changeLayout }) =>
      changeLayout &&
      css`
        background-color: #fff;
        color: #0c0d0d;
        width: 108%;
        border-radius: 32px 0px 0px 32px;
      `};

    b:nth-child(1) {
      position: absolute;
      top: -20px;
      background: #fff;
      height: 20px;
      width: 100%;
      display: none;
    }

    b:nth-child(1):before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 95.6%;
      height: 100%;
      background: #017ef0;
      border-bottom-right-radius: 20px;
    }

    b:nth-child(2) {
      position: absolute;
      bottom: -20px;
      background: #fff;
      height: 20px;
      width: 100%;
      display: none;
    }

    b:nth-child(2):before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 95.6%;
      height: 100%;
      background: #017ef0;
      border-top-right-radius: 20px;
    }

    ${({ changeLayout }) =>
      changeLayout &&
      css`
        b:nth-child(1) {
          display: block;
        }

        b:nth-child(2) {
          display: block;
        }
      `}
  }

  ${({ withArrow }) =>
    withArrow
      ? withArrowStyle
      : css`
          &:active {
            background-color: rgba(0, 0, 0, 0.05);
          }
        `}
  ${({ changeLayout }) =>
    changeLayout &&
    css`
      &:active {
        background-color: #fff;
      }
    `}
  ${({ count }) => count > 0 && withNumberStyle(count)}
`;

export const ItemsWrapper = styled.div`
  margin-bottom: 4rem;
  margin-top: 2rem;
  width: 100%;
`;

export const ButtonSearch = styled.button`
  background-color: var(--color-gray-2);
  border: none;
  border-radius: 3.2rem 0 0 3.2rem;
  color: var(--color-white-1);
  font-size: var(--font-size-default);
  font-family: var(--font-family-default);
  font-weight: 600;
  margin-bottom: 3.2rem;
  outline: none;
  padding: 1.6rem 2rem;
  transition: all 0.2 linear;
  width: calc(100% + 1rem);
  display: flex;
  justify-content: flex-start;

  ${({ isOpen }) => isOpen && openStyle}

  &>svg {
    margin-right: 1.6rem;
  }
`;

export const LogoLink = styled(Link)`
  height: auto;
  margin: 0 auto 4rem;
  max-width: 18rem;
  width: 100%;

  & > svg {
    height: auto;
    width: 100%;
  }
`;

export const ProfileName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.6rem;
  height: 5rem;
  width: 100%;

  ${/* sc-sel */ Span} {
    color: var(--color-white-1);
  }

  strong {
    font-weight: 600;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 12px auto;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ffffff;

    svg {
      width: 11px;
      height: 15px;
    }

    span {
      font-size: 16px;
      font-family: "Work Sans";
      margin-left: 6px;
    }
  }
`;
