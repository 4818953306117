import { put, takeLatest, cancelled } from "redux-saga/effects";
import axios from "axios";
import api from "services/api";
import { CfopTypes, CfopActions } from "store/ducks/cfop";
import { GET, PATCH, DELETE, CFOP_BY_ID, CFOP } from "utils/constants";

const { CancelToken } = axios;

function* fetchCfopDetails({ id, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: GET,
        url: CFOP_BY_ID(id),
      });
      yield put(CfopActions.fetchCfopDetailsSuccess(data));
    }
  } catch (error) {
    yield put(CfopActions.cfopRequestFailure(error, type));
  }
}

function* fetchAvailableCfop({ params, type }) {
  const source = CancelToken.source();

  try {
    const { data } = yield api({
      method: GET,
      url: CFOP,
      params,
      cancelToken: source.token,
    });
    yield put(CfopActions.fetchAvailableCfopSuccess(data));
  } catch (error) {
    yield put(CfopActions.cstRequestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

function* editCfop({ id, payload, type }) {
  try {
    if (Number(id)) {
      const { data } = yield api({
        method: PATCH,
        url: CFOP_BY_ID(id),
        data: payload,
      });
      yield put(CfopActions.editCfopSuccess(data));
    }
  } catch (error) {
    yield put(CfopActions.cfopRequestFailure(error, type));
  }
}

function* deleteCfop({ payload, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: CFOP,
      data: payload,
    });
    yield put(CfopActions.deleteCfopSuccess(data));
  } catch (error) {
    yield put(CfopActions.cfopRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(CfopTypes.FETCH_CFOP_DETAILS, fetchCfopDetails);
  yield takeLatest(CfopTypes.EDIT_CFOP, editCfop);
  yield takeLatest(CfopTypes.FETCH_AVAILABLE_CFOP, fetchAvailableCfop);
  yield takeLatest(CfopTypes.DELETE_CFOP, deleteCfop);
}
