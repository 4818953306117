import styled, { css } from "styled-components";
import { Span } from "components/core/Typography";
import { Close as CloseIcon } from "./icons";

export const IconClose = styled(CloseIcon)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
  border: 1px solid black;
  border-radius: 50%;
  padding: 0.2rem;
  cursor: pointer;
`;

const activePopup = css`
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s linear, visibility 0s 0s;
`;

export const PopupWrapper = styled.div.attrs(({ zIndex }) => ({
  zIndex: zIndex ?? "999999",
}))`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ zIndex }) => zIndex};
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s linear, visibility 0s 0.2s;
  ${({ active }) => active && activePopup}
`;

export const Popup = styled.div.attrs(({ width, verticalAlign }) => ({
  justifyContent: verticalAlign ?? "space-between",
  maxWidth: width ?? "30rem",
}))`
  background-color: var(--color-white-1);
  border-radius: 0.6rem;
  max-width: ${({ maxWidth }) => maxWidth};
  /* width: 80%; */
  height: auto;
  min-height: 10rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  position: relative;
`;

export const PopupHeader = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
`;

export const PopupTitle = styled.div`
  font-size: 2rem;
  padding: 0 1.5rem;

  /* padding: 0.5rem 0;
  display: flex;
  align-items: space-between; */
`;

export const PopupContent = styled.div.attrs(({ textAlign }) => ({
  textAlign: textAlign ?? "justify",
}))`
  height: 100%;
  width: 100%;
  padding: 3rem 1.5rem;
  text-align: ${({ textAlign }) => textAlign};

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  & > ${/* sc-sel */ Span}:not(:last-child) {
    display: inline-block;
    margin-bottom: var(--space-sm);
  }
`;

export const PopupContainer = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;
