import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Span } from "components/core/Typography";
import { formatDate } from "helpers";
import { EMITTER_COLORS_DICTIONARY } from "utils/constants";
import { ReactComponent as IconEmpty } from "assets/svg/empty-chat.svg";
import * as S from "./styled";

const EmptyChats = () => {
  const { t: translate } = useTranslation();

  return (
    <S.IconEmptyWrapper>
      <IconEmpty />
      <Span>{translate("noResultsFound")}</Span>
    </S.IconEmptyWrapper>
  );
};

const Chats = ({ chats, handleNewRoom, idUserActive, lastChatRef }) => (
  <S.ConversationsList>
    {chats.length ? (
      chats
        .sort((a, b) => {
          if (new Date(a.createdAt).valueOf() > new Date(b.createdAt).valueOf()) return -1;
          if (new Date(b.createdAt).valueOf() > new Date(a.createdAt).valueOf()) return 1;
          return 0;
        })
        .map(({ idUser, enterpriseName, name, createdAt, message, unread, status }, index) => (
          <S.Conversation
            key={idUser}
            active={idUser === idUserActive}
            onClick={() => handleNewRoom(chats[index])}
            ref={chats.length - 1 === index ? lastChatRef : null}
          >
            <S.Status color={EMITTER_COLORS_DICTIONARY[status]} />
            <S.Username>{enterpriseName ?? name}</S.Username>
            <S.Date>{formatDate(createdAt, 2)}</S.Date>
            <S.LastMessage>{message}</S.LastMessage>
            <S.Count data-testid="count">{unread !== "0" && <strong>{unread}</strong>}</S.Count>
          </S.Conversation>
        ))
    ) : (
      <EmptyChats />
    )}
  </S.ConversationsList>
);

Chats.propTypes = {
  chats: PropTypes.arrayOf(
    PropTypes.shape({
      idUser: PropTypes.number,
      enterpriseName: PropTypes.string,
      name: PropTypes.string,
      createdAt: PropTypes.string,
      message: PropTypes.string,
      unread: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      status: PropTypes.string,
    }),
  ),
  handleNewRoom: PropTypes.func.isRequired,
  idUserActive: PropTypes.number,
  lastChatRef: PropTypes.func.isRequired,
};

Chats.defaultProps = {
  chats: [],
  idUserActive: 0,
};

export default Chats;
