import React from "react";
import PropTypes from "prop-types";
import Portal from "components/core/Portal";
import * as S from "./styled";

const Popup = ({ children, component, title, isPopupVisible, dismissPopupAction }) => {
  return (
    <S.PopupContainer>
      {component}
      <Portal>
        <S.PopupWrapper active={isPopupVisible}>
          <S.Popup>
            <S.PopupHeader>
              <S.PopupTitle>{title}</S.PopupTitle>
              <S.IconClose onClick={dismissPopupAction} />
            </S.PopupHeader>
            <S.PopupContent>{children}</S.PopupContent>
          </S.Popup>
        </S.PopupWrapper>
      </Portal>
    </S.PopupContainer>
  );
};

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isPopupVisible: PropTypes.bool.isRequired,
  dismissPopupAction: PropTypes.func.isRequired,
};

Popup.defaultProps = {
  title: "",
  isPopupVisible: false,
  dismissPopupAction: () => {},
  children: null,
  component: null,
};

export default Popup;
