import React, { useEffect, useState, lazy } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InvoiceActions } from "store/ducks/invoice";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import InvoiceDetails from "components/presentation/Invoice/Details";
import { ReactComponent as IconExport } from "assets/icons/icon-export.svg";

const InvoiceExport = lazy(() => import("containers/Invoice/List/Export"));

const InvoiceDetailsWithSpinner = WithSpinner(InvoiceDetails);
const InvoiceExportWithSuspense = WithSuspense(InvoiceExport);

const InvoiceDetailsContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showExportModal, setShowExportModal] = useState(false);
  const { InvoicesRequests, details } = useSelector(({ invoice }) => invoice);

  useEffect(() => {
    dispatch(InvoiceActions.fetchInvoiceDetails(id));
  }, [id, dispatch]);

  const dispatchExportInvoices = (fileType) => {
    const payload = { fileType, invoices: [id] };
    dispatch(InvoiceActions.exportInvoices(payload));
  };
  return (
    <>
      <NavBar title={translate("invoiceDetails")}>
        <Button handleClick={() => setShowExportModal(true)}>
          <IconExport />
          {translate("export")}
        </Button>
      </NavBar>
      <InvoiceDetailsWithSpinner
        requestStatus={InvoicesRequests.FETCH_INVOICE_DETAILS}
        invoice={details}
      />
      <InvoiceExportWithSuspense
        loadComponent={showExportModal}
        showExport={showExportModal}
        setShowExport={setShowExportModal}
        dispatchExportInvoices={dispatchExportInvoices}
        count={null}
      />
    </>
  );
};

export default InvoiceDetailsContainer;
