import styled from "styled-components";

export const SpinnerOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight ?? "auto"};
`;

export const Spinner = styled.div.attrs(
  ({ height, width, margin, marginLeft, marginRight, marginTop, marginBottom }) => ({
    height: height ?? "6rem",
    width: width ?? "6rem",
    margin: margin ?? "0",
    marginLeft: marginLeft ?? "0",
    marginRight: marginRight ?? "0",
    marginTop: marginTop ?? "0",
    marginBottom: marginBottom ?? "0",
  }),
)`
  display: inline-block;
  border: 5px solid var(--color-secondary);
  border-radius: 50%;
  border-top-color: var(--color-white-1);
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  animation: spin 1s ease-in-out infinite;
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
