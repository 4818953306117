import { Span } from "components/core";
import { RenderHeaders, Table, TableBody, TableHeader, TableItem } from "components/core/Table";
import React from "react";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import PropTypes from "prop-types";
import Pagination from "components/core/Pagination";
import moment from "moment";
import { REQUEST_PENDING } from "utils/constants";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import * as S from "./styled";

const AccountantCertificatesList = ({
  items,
  total,
  totalPages,
  requestStatus,
  statusUpdate,
  deleteCertificate,
  updateDefaultCertificate,
  params,
  page,
  perPage,
  orderBy,
  order,
}) => {
  const headers = [
    { slug: "id", title: "ID" },
    { slug: "nameAccountant", title: "Nome do Contador" },
    { slug: "crc", title: "CRC" },
    { slug: "createdAt", title: "Data de Cadastro" },
    { slug: "default", title: "Padrão para Clientes" },
    { slug: "", title: "Ações" },
  ];

  const requestPending = (statusGet, statusUpdated) => {
    return statusGet || statusUpdated === REQUEST_PENDING;
  };
  return requestPending(requestStatus, statusUpdate) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(({ id, nameAccountant, location, key, createdAt, crc, default: def }) => {
            return (
              <tr key={id}>
                <TableItem>
                  <Span>{id}</Span>
                </TableItem>
                <TableItem>
                  <Span>{nameAccountant}</Span>
                </TableItem>
                <TableItem>
                  <Span>{crc}</Span>
                </TableItem>
                <TableItem>
                  <Span>{moment(createdAt).format("DD/MM/YYYY")}</Span>
                </TableItem>

                <TableItem>
                  {def ? (
                    <Span>
                      <StatusDot type={StatusTypes.SUCCESS} />
                      Sim
                    </Span>
                  ) : (
                    <Span>
                      <StatusDot type={StatusTypes.ERROR} />
                      Não
                    </Span>
                  )}
                </TableItem>
                <TableItem>
                  {!def && (
                    <S.IconDefault
                      title="Tornar Padrão"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        updateDefaultCertificate({ id });
                      }}
                    />
                  )}
                  <S.IconDownload
                    title="Baixar Certificado"
                    onClick={() => {
                      window.open(location);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <S.IconDelete
                    title="Excluir"
                    onClick={() => {
                      deleteCertificate({ id, key });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </TableItem>
              </tr>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

AccountantCertificatesList.propTypes = {
  items: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

AccountantCertificatesList.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(AccountantCertificatesList);
