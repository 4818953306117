import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as S from "./styled";

const AccordionMenu = ({ i18nKey, Icon, children }) => {
  const { t: translate } = useTranslation();
  const [openCnt1, setOpenCnt1] = useState(false);
  return (
    <S.MenuAccordion>
      <S.ItemMenuAccordion active={openCnt1}>
        <S.BtAccordion onClick={() => setOpenCnt1(!openCnt1)}>
          {Icon && <Icon />}
          {translate(i18nKey)}
          <S.Arrow className="arrowAcc" />
        </S.BtAccordion>
        <ul>{children}</ul>
      </S.ItemMenuAccordion>
    </S.MenuAccordion>
  );
};

AccordionMenu.propTypes = {
  i18nKey: PropTypes.string.isRequired,
  Icon: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
};

export default AccordionMenu;
