import React from "react";
import styled from "styled-components";
import { ReactComponent as IconTimes } from "assets/icons/icon-close.svg";

export const InputFile = styled.div`
  box-shadow: 0px 0px 1rem rgba(57, 57, 57, 0.2);
  border-radius: 0.8rem;
  color: var(--color-secondary);
  font-size: var(--font-size-default);
  min-height: 4rem;
  min-width: 30rem;
  max-width: 70rem;
  margin: ${(props) => props.margin|| "0 auto"};
  padding: 1rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s linear;

  &:hover {
    box-shadow: 0px 0px 1.1rem rgba(57, 57, 57, 0.3);
  }
  ${({ isHidden }) => isHidden && "display: none;"}
`;

export const InputLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > svg {
    margin-right: 1rem;
  }

  input {
    display: none;
  }

  span {
    margin-right: 1rem;
  }
`;

const CloseIcon = styled(IconTimes)`
  height: 1rem;
  width: 1rem;
`;

export const RemoveFileButton = styled.button.attrs({
  type: "button",
  children: <CloseIcon />,
})`
  background: #eef1f2;
  border-radius: 50%;
  cursor: pointer;
  margin-left: auto;
  height: 2.4rem;
  width: 2.4rem;
`;
