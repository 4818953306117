import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { Flex } from "components/core/Grid";
import InputFile from "components/core/InputFile";
import Modal from "components/core/Modal";
import { ModalActions } from "components/core/Modal/styled";
import { Form } from "components/core/Form";
import { ErrorMsg } from "components/core/InputForm/styled";

const UploadCertificate = ({ onSubmit, showModal, setShowModal }) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors, reset } = useForm({ mode: "onBlur" });

  return (
    <Modal
      title={translate("newCertificate")}
      showModal={showModal}
      showActions={false}
      width="60rem"
    >
      <Flex verticalAlign="center">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Flex>
            <InputFile
              register={register({
                required: { value: true, message: translate("required.certificate") },
              })}
              name="document"
              onCancel={reset}
            />
            <ErrorMsg>{errors?.document?.message}</ErrorMsg>
          </Flex>
          <ModalActions>
            <Button variant="outline" handleClick={() => setShowModal(false)}>
              {translate("cancel")}
            </Button>
            <Button type="submit">{translate("send")}</Button>
          </ModalActions>
        </Form>
      </Flex>
    </Modal>
  );
};

UploadCertificate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default UploadCertificate;
