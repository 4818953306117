import React, { lazy, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BatchesActions } from "store/ducks/batches";
import { useItems, useParams, useRequest } from "hooks";
import { useParams as useParam } from "react-router-dom";
import BatchesCompany from "components/presentation/Batches/Company";
import { formatDate } from "helpers";
import { ErrorWrapper } from "components/core/ErrorBoundary/styled";
import { ReactComponent as EmptyScreen } from "assets/images/empty-batch.svg";
import { Paragraph } from "components/core/Typography";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { ReactComponent as IconAdd } from "assets/icons/icon-plus.svg";
import { ROUTES } from "utils/constants";
import { REQUEST_RESOLVED } from "utils/constants/request";

import WithSuspense from "components/core/WithSuspense";
import WithSpinner from "components/core/WithSpinner";

const Filter = React.lazy(() => import("containers/Batches/Company/Filter"));
const NewBatchModal = lazy(() => import("containers/Batches/Register"));

const FilterWithSuspense = WithSuspense(Filter);
const NewBatchModalWithSuspense = WithSuspense(NewBatchModal);
const BatchesListWithSpinner = WithSpinner(BatchesCompany);

const Company = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const { id } = useParam();

  const [showModal, setShowModal] = useState(false);

  const {
    BatchesRequests,
    batchesCompany: { items, total, totalPages },
  } = useSelector(({ batches }) => batches);

  const { enterprises } = useSelector((state) => state.batches);

  const fetchBatches = (newParams) => {
    dispatch(BatchesActions.getBatchesCompany(id, newParams));
  };

  const { handleChange, listChecked: itemsChecked, selectedItems, toggleAll } = useItems(items);

  const {
    params,
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
    handleFilter,
    SmartFilterButton,
  } = useParams({
    callback: fetchBatches,
    defaultOrderBy: "dateIssue",
    batchId: id,
  });

  useRequest({
    request: BatchesRequests.DELETE_BATCHES,
    onResolved: () => fetchBatches(params),
  });

  const newItemsChecked = itemsChecked
    ? itemsChecked.map((item) => ({ ...item, createdAt: formatDate(item.createdAt) }))
    : [];

  return (
    <>
      <NavBar
        title="Lote"
        description={items?.length ? items[0].description : ""}
        breadcrumb
        route={ROUTES.batches}
      />
      {BatchesRequests.getBatchesCompany === REQUEST_RESOLVED && items?.length === 0 ? (
        <ErrorWrapper>
          <EmptyScreen />
          <Paragraph>{translate("multipleInvoicesEmptyError")}</Paragraph>
          <Button variant="text" handleClick={() => setShowModal(true)}>
            <IconAdd />
            <span>{translate("addBatches")}</span>
          </Button>
        </ErrorWrapper>
      ) : (
        <BatchesListWithSpinner
          requestStatus={BatchesRequests.getBatchesCompany}
          batches={newItemsChecked}
          total={total}
          totalPages={totalPages}
          page={page}
          perPage={perPage}
          params={{
            setPerPage,
            setPage,
            setOrderBy,
            setOrder,
          }}
          orderBy={orderBy}
          order={order}
          handleChange={handleChange}
          toggleAll={toggleAll}
          checkedAll={selectedItems?.length === itemsChecked?.length}
          batchIdentify={id}
        />
      )}
      <FilterWithSuspense
        loadComponent={showFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dispatchWithParams={handleFilter}
      />
      <NewBatchModalWithSuspense
        loadComponent={showModal}
        showModal={showModal}
        setShowModal={setShowModal}
        enterprisesRetrive={enterprises}
        description={items?.length ? items[0].description : ""}
      />
    </>
  );
};

export default Company;
