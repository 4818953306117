import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useRequest } from "hooks";
import { PaymentGatewaysActions } from "store/ducks/paymentGateways";
import { LayoutActions } from "store/ducks/layout";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import PaymentGatewaysEdit from "components/presentation/PaymentGateways/Edit";
import { LINKS } from "utils/constants";
import { paymentGatewaySchema } from "utils/schemas/paymentGateway";

const PaymentGatewaysEditWithSpinner = WithSpinner(PaymentGatewaysEdit);

const PaymentGatewaysEditContainer = () => {
  const submitRef = useRef(null);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { paymentGateway, PaymentGatewaysRequestsStatus } = useSelector(
    ({ paymentGateways }) => paymentGateways,
  );

  useEffect(() => {
    dispatch(PaymentGatewaysActions.getPaymentGatewayById(id));
  }, [id, dispatch]);

  const handleSubmit = (values) => {
    const data = { ...values };
    dispatch(PaymentGatewaysActions.editPaymentGateway(id, data));
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{translate("Payment Gateway Edited")}</Span>,
        type: "success",
      }),
    );
    dispatch(PaymentGatewaysActions.cleanState());
    history.push(LINKS.paymentGatewaysList);
  };

  useRequest({
    request: PaymentGatewaysRequestsStatus.EDIT_PAYMENT_GATEWAY,
    onResolved,
  });

  return (
    <>
      <NavBar title={translate("Edit Payment Gateway")}>
        <Button url={LINKS.paymentGatewaysList} variant="outline">
          {translate("cancel")}
        </Button>
        <Button handleClick={() => submitRef.current.click()}>
          <span>{translate("save")}</span>
        </Button>
      </NavBar>
      <PaymentGatewaysEditWithSpinner
        requestStatus={PaymentGatewaysRequestsStatus.getPaymentGatewayById}
        handlePaymentGatewayEdit={handleSubmit}
        defaultValues={paymentGateway}
        validationSchema={paymentGatewaySchema}
        submitRef={submitRef}
      />
    </>
  );
};

export default PaymentGatewaysEditContainer;
