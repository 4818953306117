import React, { useEffect } from "react";
import NavBar from "components/core/NavBar";
import { useTranslation } from "react-i18next";
import { useParams, useRequest } from "hooks";
import { ReportsActions } from "store/ducks/reports";
import { useDispatch, useSelector } from "react-redux";
import WithSpinner from "components/core/WithSpinner";
import Button from "components/core/Button";
import { Span } from "components/core";
import { ReactComponent as IconExport } from "assets/icons/icon-download.svg";
import { LayoutActions } from "store/ducks/layout";
import { ContainerOptions } from "./styled";
import ReportPaymentsList from "../../../components/presentation/Reports/payments";

const ReportsPaymentstWithSpinner = WithSpinner(ReportPaymentsList);

const ReportsPayments = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const {
    reportsRequests,
    statusOfRequests,
    exportFiles,
    reportsPayments: { items, total, totalPages, itemsPaginated },
  } = useSelector(({ reports }) => reports);

  const fetchReportsP = (newParams) => {
    dispatch(ReportsActions.fetchReportsPayments(newParams));
  };
  const { orderBy, order, page, perPage, setPage, setOrderBy, setOrder } = useParams({
    callback: fetchReportsP,
  });

  useEffect(() => {
    fetchReportsP(page);
  }, [page]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleExportReports = () => {
    dispatch(ReportsActions.exportFilesPayments({ reportsP: items }));
  };

  const onResolved = () =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{translate(exportFiles.message)}</Span>,
        type: "success",
      }),
    );

  useRequest({
    request: statusOfRequests.EXPORT_FILES_PAYMENTS_SUCCESS,
    onResolved,
  });

  return (
    <>
      <NavBar title={translate("reportsPayments")} />
      <ContainerOptions>
        {items && (
          <Button variant="outline" handleClick={handleExportReports}>
            <IconExport />
            <span>{translate("Download to excel")}</span>
          </Button>
        )}
        <Button handleClick={handleRefresh}>{translate("Refresh")}</Button>
      </ContainerOptions>

      <ReportsPaymentstWithSpinner
        requestStatus={reportsRequests.FETCH_REPORTS_PAYMENTS}
        reports={itemsPaginated}
        total={total}
        totalPages={totalPages}
        page={page}
        perPage={perPage}
        params={{
          setPage,
          setOrderBy,
          setOrder,
        }}
        order={order}
        orderBy={orderBy}
      />
    </>
  );
};
export default ReportsPayments;
