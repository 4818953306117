import styled from "styled-components";
import {
  Close as CloseIcon,
  Copy as CopyIcon,
  Delete as DeleteIcon,
  Details as DetailsIcon,
  Edit as EditIcon,
  View as ViewIcon,
  Mail as MailIcon,
  Question as QuestionIcon,
} from "./icons";

export const IconDelete = styled(DeleteIcon)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

export const IconEdit = styled(EditIcon)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

export const IconCopy = styled(CopyIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const IconView = styled(ViewIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const IconItems = styled(DetailsIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const IconClose = styled(CloseIcon)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
`;

export const IconMail = styled(MailIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const IconQuestion = styled(QuestionIcon)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;
