import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { InputWrapper, Label, ErrorMsg } from "components/core/InputForm/styled";
import * as S from "./styled";

export const SelectDotWithContext = ({ label, options, id, name, mb, errorMsg, register, cy }) => {
  const [dotColor, setDotColor] = useState(options?.[0]?.color);
  const { watch } = useFormContext();
  const watchValue = watch(name);

  useEffect(() => {
    const selectedOption = options.find(({ value }) => String(value) === watchValue);
    setDotColor(selectedOption?.color);
  }, [options, watchValue]);

  return (
    <SelectWithDot
      register={register}
      label={label}
      id={id}
      name={name}
      errorMsg={errorMsg}
      options={options}
      color={dotColor}
      mb={mb}
      cy={cy}
    />
  );
};

const FieldSelect = ({
  id,
  name,
  register,
  errorMsg,
  options,
  disabled,
  handleChange,
  isLoading,
  cy,
}) => (
  <S.FieldSelect
    id={id ?? name}
    name={name}
    as="select"
    ref={register}
    hasError={!!errorMsg}
    disabled={disabled}
    onChange={handleChange}
    isLoading={isLoading}
    data-cy={cy}
  >
    {options?.map(({ text, value, selected, disabled: disabledOption }, index) => (
      <option value={value} key={`${index}-${text}`} selected={selected} disabled={disabledOption}>
        {text}
      </option>
    ))}
  </S.FieldSelect>
);

export const SelectWithDot = ({
  label,
  options,
  id,
  name,
  hasError,
  errorMsg,
  callbackOnChange,
  mb,
  register,
  color,
  isLoading,
  cy,
}) => {
  const [dotColor, setDotColor] = useState(options?.[0]?.color);

  const handleChange = ({ target: { value: selectedValue } }) => {
    if (!color) {
      const selectedOption = options.find(({ value }) => String(value) === selectedValue);
      setDotColor(selectedOption?.color);

      if (callbackOnChange) {
        callbackOnChange(selectedValue);
      }
    }
  };

  return (
    <S.SelectWithDot dotColor={color ?? dotColor} mb={mb} isLoading={isLoading}>
      <Label htmlFor={id ?? name} hasError={hasError}>
        {label}
      </Label>
      <FieldSelect
        register={register}
        id={id ?? name}
        name={name}
        handleChange={handleChange}
        isLoading={isLoading}
        options={options}
        cy={cy}
      />
      {hasError && <ErrorMsg>{errorMsg}</ErrorMsg>}
    </S.SelectWithDot>
  );
};

const SelectForm = ({
  label,
  options,
  id,
  name,
  disabled,
  flexWidth,
  register,
  errorMsg,
  handleChange,
  isLoading,
  cy,
  marginBottom,
  marginRight,
}) => (
  <InputWrapper
    flexWidth={flexWidth}
    isLoading={isLoading}
    marginBottom={marginBottom}
    marginRight={marginRight}
  >
    <Label htmlFor={id ?? name} hasError={!!errorMsg}>
      {label}
    </Label>
    <FieldSelect
      register={register}
      id={id ?? name}
      name={name}
      disabled={disabled}
      isLoading={isLoading}
      options={options}
      handleChange={handleChange}
      cy={cy}
    />
    {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
  </InputWrapper>
);

SelectDotWithContext.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  mb: PropTypes.string,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  cy: PropTypes.string,
};

SelectDotWithContext.defaultProps = {
  mb: "",
  errorMsg: null,
  cy: "",
};

SelectWithDot.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string,
  hasError: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  callbackOnChange: PropTypes.func,
  mb: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  cy: PropTypes.string,
};

SelectWithDot.defaultProps = {
  id: null,
  hasError: false,
  errorMsg: null,
  callbackOnChange: null,
  mb: null,
  register: null,
  color: null,
  isLoading: false,
  cy: "",
};

FieldSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  isLoading: PropTypes.bool,
  cy: PropTypes.string,
};

FieldSelect.defaultProps = {
  errorMsg: null,
  disabled: false,
  handleChange: null,
  register: null,
  isLoading: false,
  cy: "",
};

SelectForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  flexWidth: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func,
  isLoading: PropTypes.bool,
  cy: PropTypes.string,
};

SelectForm.defaultProps = {
  id: null,
  disabled: false,
  flexWidth: null,
  register: null,
  errorMsg: null,
  handleChange: null,
  isLoading: false,
  cy: "",
};

export default SelectForm;
