import React from "react";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Subtitle, Divider } from "components/core/Typography";
import { formatNumberToReal } from "helpers/format";
import { Columns } from "components/core/Grid";
import { InputGroup } from "components/core/Form";

const OtherTypesTaxes = ({
  billings,
  openingTax,
  allowInstallments,
  minInstallmentAmount,
  maxInstallments,
}) => (
  <>
    <Subtitle>Valores</Subtitle>
    <Columns columns="2">
      <CardInfo label="Taxa de Abertura de Empresa" info={formatNumberToReal(openingTax)} />
      <CardInfo label="Parcelamento?" info={allowInstallments ? "Sim" : "Não"} />
    </Columns>
    <Columns columns="2">
      <CardInfo
        label="Valor Mínimo Parcela"
        info={formatNumberToReal(minInstallmentAmount)}
        marginBottom={0}
      />
      <CardInfo label="Nº de parcelas" info={maxInstallments} marginBottom="0.5rem" />
    </Columns>
    {billings
      .filter(({ billing }) => billing !== "Não sei")
      .map(({ billing, price, annualPrice, biennialPrice }, index) => (
        <React.Fragment key={billing}>
          <Divider />
          <CardInfo label={`Faixa ${index + 1}`} info={billing} />
          <InputGroup paddingLeft="0" columns="2">
            <CardInfo label="Valor do Plano (Mensal)" info={formatNumberToReal(price)} />
            <CardInfo label="Valor do Plano (Anual)" info={formatNumberToReal(annualPrice)} />
          </InputGroup>
          <InputGroup paddingLeft="0" columns="2">
            <CardInfo label="Valor do Plano (Bienal)" info={formatNumberToReal(biennialPrice)} />
          </InputGroup>
        </React.Fragment>
      ))}
  </>
);

OtherTypesTaxes.propTypes = {
  billings: PropTypes.array.isRequired,
  openingTax: PropTypes.string.isRequired,
};

export default OtherTypesTaxes;
